import React from "react";

import API from '../services/api';

import fuzzytime from 'fuzzy-time';

import "./activitiesContainer.css";

export default class OneClickComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fill: props.fill,
        value: props.value,
        stroke: props.stroke,
        title: props.title
      };

      this.open = props.open;
    }

    render() {
      let x = -8;
      let y = 0;
      let width = 48;
      let height = 32;

      // HTML
        return ( 
            <div style={{display:'inline-block'}} onClick={this.open}>
              <svg width={width} height={height}>
                <title>{this.state.title}</title> 
                <circle cx="16" cy="16" r="14" fill={this.state.fill}  style={{cursor:'pointer'}}/>
                <text x={x + width / 2} y={y + height / 2} dominant-baseline="middle" text-anchor="middle" fill={this.state.stroke} style={{fontSize:'12pt', cursor:'pointer'}}>{this.state.value}</text>
              </svg>
            </div>
        );
      }
}