import React, { useState, useEffect, useRef, useCallback  } from "react";
import { slateToHtml, payloadSlateToHtmlConfig, htmlToSlate, payloadHtmlToSlateConfig  } from '@slate-serializers/html';
import API from '../services/api';
import '@toast-ui/editor/dist/toastui-editor.css';
import { toast } from "react-toastify";
import { AiOutlineLoading } from 'react-icons/ai';
import isHotkey from 'is-hotkey'
import SUNEDITOR from 'suneditor';
import { HTML_EDITOR_CONFIG } from "../HTMLEDITOR_CONFIG";

export default function EmailSignatureComponent(){
  const [loading, setLoading] = useState(false);
  const [editorVisibility, setEditorVisibility] = useState(false);

  const editor = useRef<any>(null);

  return (
      <>
        { editorVisibility && <div className="actionPopUp">
          <div>
            <h4>Set your email signature to be used in emails to candidates and leads</h4>
            <textarea id="email_signature_container"></textarea>
            <div>
              <button onClick={async ()=>{
                if(!editor.current){
                  toast.error("Editor Invalid");
                  return;
                }
                let html = editor.current.getContents();
                let api_key = localStorage.getItem('api_key');
                let result = await API.user.setEmailSignature(api_key, html);  
                setEditorVisibility(false); 
                toast("Email Signature Saved");
              }} className="blueBTN" style={{width:'100%', display:'block', margin:'8px 0px'}}>Save</button>
              <button onClick={()=>{
                setEditorVisibility(false); 
              }} className="blueBTN" style={{width:'100%', display:'block', margin:'8px 0px'}}>Back</button>
            </div>
          </div>
        </div> }
        <div className="automanageContainer">
            <h1>Email Signature</h1>
            <h4>This allows you to add a personlised email signature at the end of emails sent to candidates and leads</h4>
            <button onClick={async ()=>{
              editor.current = null;                
              let api_key = localStorage.getItem('api_key');
              const result = await API.user.getEmailSignature(api_key); 
              setEditorVisibility(true); 
              const data = result['data'];
              editor.current = SUNEDITOR.create((document.getElementById('email_signature_container') || 'email_signature_container'), HTML_EDITOR_CONFIG);              
              if(!editor.current){
                toast.error("Editor Invalid");
                setEditorVisibility(false);
                return;
              }
              editor.current.setContents(data);
            }}>Manage</button> 
        </div>
        { 
                loading &&
                <div className="loader">                        
                    <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>This will take a several minutes, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                </div>                
            }
        </>
  );
}