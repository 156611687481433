import React, { createRef } from "react";

import { toast, ToastContainer } from 'react-toastify';

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import API from  './../services/api';

import './upgrade.css';

export default class UpgradePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 

      this.getAccountText = this.getAccountText.bind(this);
      this.openTab = this.openTab.bind(this);
      this.startPaymentFlow = this.startPaymentFlow.bind(this);
      this.getSelectedPackage = this.getSelectedPackage.bind(this);
      this.gotoSubscriptionAPI = this.gotoSubscriptionAPI.bind(this);

      this.tab1Ref = createRef();
      this.tab2Ref = createRef();
      this.tab3Ref = createRef();
      this.tab4Ref = createRef();
      this.tab5Ref = createRef();

      this.holderRef = createRef();
      this.soloRef = createRef();
      this.liteRef = createRef();
      this.liteBusinessRef = createRef();
      this.businessRef = createRef();
      this.enterpriseRef = createRef();

      this.startPaymentFlowDialogRef = createRef();

      this.packageDurationRef = createRef();

    }

    async componentDidMount(){
        this.startPaymentFlowDialogRef.current.style.display = "none";
        this.holderRef.current.style.display = "none";
        this.liteRef.current.style.display = "none";
        this.liteBusinessRef.current.style.display = "none";
        this.businessRef.current.style.display = "none";
        this.enterpriseRef.current.style.display = "none";
        let api_key = localStorage.getItem('api_key');
        if(!api_key){
            window.location.href = '/login';
            return;
        }
        let result = await API.user.isDisabled(api_key);
        let isDisabled = result['disabled'];
        if(isDisabled){
            localStorage.setItem('api_key', "");
            window.location.href = '/disabled';
        }

        result = await API.user.getUser(api_key);
        let user = result['user'];
        // TODO: remove
        //user['package_number'] = 0;


        let keys = [
            // ZA PRCING
            'za_solo_monthly',
            'za_solo_monthly_without_marketing',
            'za_solo_yearly',
            'za_solo_yearly_without_marketing',            
            'za_lite_monthly',
            'za_lite_monthly_without_marketing',
            'za_lite_yearly',
            'za_lite_yearly_without_marketing',
            'za_business_lite_monthly',
            'za_business_lite_monthly_without_marketing',
            'za_business_lite_yearly',
            'za_business_lite_yearly_without_marketing',            
            'za_business_pro_monthly',
            'za_business_pro_monthly_without_marketing',
            'za_business_pro_yearly',
            'za_business_pro_yearly_without_marketing', 
            // US PRICING
            'us_solo_monthly',
            'us_solo_monthly_without_marketing',
            'us_solo_yearly',
            'us_solo_yearly_without_marketing',            
            'us_lite_monthly',
            'us_lite_monthly_without_marketing',
            'us_lite_yearly',
            'us_lite_yearly_without_marketing',
            'us_business_lite_monthly',
            'us_business_lite_monthly_without_marketing',
            'us_business_lite_yearly',
            'us_business_lite_yearly_without_marketing',            
            'us_business_pro_monthly',
            'us_business_pro_monthly_without_marketing',
            'us_business_pro_yearly',
            'us_business_pro_yearly_without_marketing',  
            // US PRICING PLAN IDS            
            'us_solo_monthly_plan_id',
            'us_solo_monthly_without_marketing_plan_id',
            'us_solo_yearly_plan_id',
            'us_solo_yearly_without_marketing_plan_id',            
            'us_lite_monthly_plan_id',
            'us_lite_monthly_without_marketing_plan_id',
            'us_lite_yearly_plan_id',
            'us_lite_yearly_without_marketing_plan_id',
            'us_business_lite_monthly_plan_id',
            'us_business_lite_monthly_without_marketing_plan_id',
            'us_business_lite_yearly_plan_id',
            'us_business_lite_yearly_without_marketing_plan_id',            
            'us_business_pro_monthly_plan_id',
            'us_business_pro_monthly_without_marketing_plan_id',
            'us_business_pro_yearly_plan_id',
            'us_business_pro_yearly_without_marketing_plan_id',             
        ];
        result = await API.pricing.getBulk(keys, 0);
        let pairs = result['pairs'];
        let new_pairs = [];
        pairs.forEach(pair => {
            new_pairs[pair.key] = pair.value;
        });
        this.setState({
            ...this.state,
            user: user,
            pricing: new_pairs
        });        
    }

    getAccountText(){
        let i = this.state.user ? this.state.user.package_number : 6;
        if(i==0){
            return "a Free";
        } else if(i==1){
            return "a Solo";
        } else if(i==2){
            return "a Lite";
        } else if(i==3){
            return "a Business Lite";
        } else if(i==4){
            return "a Business Pro";
        } else if(i==5){
            return "an Enterprise";
        } else {
            return "Loading";
        }
    }

    openTab(e){
        this.holderRef.current.style.display = "none";
        this.soloRef.current.style.display = "none";
        this.liteRef.current.style.display = "none";
        this.liteBusinessRef.current.style.display = "none";
        this.businessRef.current.style.display = "none";
        this.enterpriseRef.current.style.display = "none";
        if(this.state.user.package_number < 1){
            this.tab1Ref.current.classList.remove('active');
        }
        if(this.state.user.package_number < 2){
            this.tab2Ref.current.classList.remove('active');
        }
        if(this.state.user.package_number < 3){
            this.tab3Ref.current.classList.remove('active');
        }
        if(this.state.user.package_number < 4){
            this.tab4Ref.current.classList.remove('active');
        }
        if(this.state.user.package_number < 5){
            this.tab5Ref.current.classList.remove('active');
        }
        let index = parseInt(e.target.getAttribute('data-index-id'));
        switch(index){
            case 1:
                this.holderRef.current.style.display = "block";
                this.soloRef.current.style.display = "block";
                this.tab1Ref.current.classList.add('active');
                break;
            case 2:
                this.holderRef.current.style.display = "block";
                this.liteRef.current.style.display = "block";
                this.tab2Ref.current.classList.add('active');
                break;
            case 3:
                this.holderRef.current.style.display = "block";
                this.liteBusinessRef.current.style.display = "block";
                this.tab3Ref.current.classList.add('active');
                break;
            case 4:
                this.holderRef.current.style.display = "block";
                this.businessRef.current.style.display = "block";
                this.tab4Ref.current.classList.add('active');
                break;
            case 5:
                this.holderRef.current.style.display = "block";
                this.enterpriseRef.current.style.display = "block";
                this.tab5Ref.current.classList.add('active');
                break;
            default:
                //DO NOTHING
        }
    }

    startPaymentFlow(e){        
        toast("Feature in development, this will be updated soon");
        return;
        this.startPaymentFlowDialogRef.current.style.display = "flex";
        let index = parseInt(e.target.getAttribute('data-index-id'));
        this.setState({
            ...this.state,
            upgrade_package: index,
        });
    }

    getSelectedPackage(){
        let i = this.state.upgrade_package;
        if(i==0){
            return "Free Package";
        } else if(i==1){
            return "Solo Package";
        } else if(i==2){
            return "Lite Package";
        } else if(i==3){
            return "Business Lite Package";
        } else if(i==4){
            return "Business Pro Package";
        } else if(i==5){
            return "Enterprise Package";
        } else {
            return "Loading";
        }

    }

    wait = (ms)=>{
        return new Promise((resolve, reject)=>{setTimeout(resolve(), ms)});
    }

    async gotoSubscriptionAPI(){
        let new_package_upgrade_number = this.state.upgrade_package;
        let duration = this.packageDurationRef.current.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.payment.getNewPaymentLink(api_key, new_package_upgrade_number, duration);
        let link = result['link'];
        if(link != false){
            toast("You will now be redirected to process payment");
            this.wait(2000);
            window.location.href = link;
        } else {
            toast("Couldn't obtain payment link");
        }
    }


    render() {
        // HTML

        // pricing
        let isUS = this.state.user?.payment_gateway;
        // monthly
        let soloMonthly = isUS ? "$" + (this.state.pricing?.us_solo_monthly / 100).toFixed(2) : "R" + (this.state.pricing?.za_solo_monthly / 100).toFixed(2) ;
        let liteMonthly = isUS ? "$" + (this.state.pricing?.us_lite_monthly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_lite_monthly / 100).toFixed(2) ;
        let businessLiteMonthly = isUS ? "$" + (this.state.pricing?.us_business_lite_monthly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_business_lite_monthly / 100).toFixed(2) ;
        let businessProMonthly = isUS ? "$" + (this.state.pricing?.us_business_pro_monthly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_business_pro_monthly / 100).toFixed(2) ;
        let enterpriseMonthly = isUS ? "$0.00" : "R0.00";
        // annully
        let soloYearly = isUS ? "$" + (this.state.pricing?.us_solo_yearly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_solo_yearly / 100).toFixed(2) ;
        let liteYearly = isUS ? "$" + (this.state.pricing?.us_lite_yearly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_lite_yearly / 100).toFixed(2) ;
        let businessLiteYearly = isUS ? "$" + (this.state.pricing?.us_business_lite_yearly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_business_lite_yearly / 100).toFixed(2) ;
        let businessProYearly = isUS ? "$" + (this.state.pricing?.us_business_pro_yearly / 100).toFixed(2)  : "R" + (this.state.pricing?.za_business_pro_yearly / 100).toFixed(2) ;
        let enterpriseYearly = isUS ? "$0.00" : "R0.00";

        return (<>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Upgrade you account</span>
                    </div>
                    <div className="fullwidth">
                        <span>You have { this.getAccountText() } Account</span>
                    </div>
                    <div className="fullwidth2">
                        { this.state.user && this.state.user.package_number != 5 ? 
                        <span>In order to access certain features you will need to upgrade your account to one of the below paid subscriptions, select a subscription package to continue</span>
                        : <span>As you have an Enterprise account, you have the highest and best subscription package avaliable there is no upgrade avaliable</span>
                        }
                    </div>
                    { this.state.user ?
                        <div className="fullwidth2" style={{paddingTop: '32px'}}>
                            <div className="upgrade-tabs">
                                { this.state.user.package_number < 1 ? <div onClick={this.openTab} ref={this.tab1Ref} data-index-id="1">Solo</div> : <></> }
                                { this.state.user.package_number < 2 ? <div onClick={this.openTab} ref={this.tab2Ref} data-index-id="2">Lite</div> : <></> }
                                { this.state.user.package_number < 2 ? <div onClick={this.openTab} ref={this.tab3Ref} data-index-id="3">Business Lite</div> : <></> }
                                { this.state.user.package_number < 3 ? <div onClick={this.openTab} ref={this.tab4Ref} data-index-id="4">Business Pro<span>Most Popular</span></div> : <></> }
                                { this.state.user.package_number < 4 ? <div onClick={this.openTab} ref={this.tab5Ref} data-index-id="5">Enterprise</div> : <></> }
                            </div> 
                        </div>
                    : <></> }
                    <div className="fullwidth2" ref={this.holderRef}>
                        <div ref={this.soloRef}>
                            <h4>Solo Package</h4>
                            <ul>
                                <li>Recording Time: 20 Hours</li>
                                <li>Vacancies: Unlimited</li>
                                <li>Seats: 1-2</li>
                                <li>Text Questions: Yes</li>
                                <li>API Access: Yes</li>
                                <li>Data Management: Yes</li>
                                <li>Video Sharing: Yes</li>
                            </ul>
                            <div>
                                <h4>Pricing Options</h4>
                                <ul>
                                    <li>Monthly at { soloMonthly }</li>
                                    <li style={{listStyle:'none'}}>or</li>
                                    <li>Annually at { soloYearly }</li>
                                </ul>
                            </div>
                            <button className="purchase-btn" onClick={this.startPaymentFlow} data-index-id="1">Purchase Upgrade</button>
                        </div>
                        <div ref={this.liteRef}>
                            <h4>Lite Package</h4>
                            <ul>
                                <li>Recording Time: 20 Hours</li>
                                <li>Vacancies: Unlimited</li>
                                <li>Seats: 1-2</li>
                                <li>Text Questions: Yes</li>
                                <li>API Access: Yes</li>
                                <li>Data Management: Yes</li>
                                <li>Video Sharing: Yes</li>
                            </ul>
                            <div>
                                <h4>Pricing Options</h4>
                                <ul>
                                    <li>Monthly at { liteMonthly }</li>
                                    <li style={{listStyle:'none'}}>or</li>
                                    <li>Annually at {liteYearly  }</li>
                                </ul>
                            </div>
                            <button className="purchase-btn" onClick={this.startPaymentFlow} data-index-id="2">Purchase Upgrade</button>
                        </div>
                        <div ref={this.liteBusinessRef}>                            
                        <h4>Lite Business Package</h4>
                            <ul>
                                <li>Recording Time: 100 Hours / 50GBs</li>
                                <li>Vacancies: Unlimited</li>
                                <li>Seats: 5</li>
                                <li>Text Questions: Yes</li>
                                <li>Video Questions: Yes</li>
                                <li>API Access: Yes</li>
                                <li>Data Management: Yes</li>
                                <li>Video Sharing: Yes</li>
                                <li>Rating: Yes</li>
                                <li>Collaboration: Yes</li>
                                <li>LinkedIn Marketing: Yes</li>
                                <li>Bulk Campaign Marketing: Yes</li>
                                <li>Video on Application: Yes</li>
                            </ul>
                            <div>
                                <h4>Pricing Options</h4>
                                <ul>
                                    <li>Monthly at { businessLiteMonthly }</li>
                                    <li style={{listStyle:'none'}}>or</li>
                                    <li>Annually at { businessLiteYearly }</li>
                                </ul>
                            </div>
                            <button className="purchase-btn" onClick={this.startPaymentFlow} data-index-id="3">Purchase Upgrade</button>
                        </div>
                        <div ref={this.businessRef}>                          
                        <h4>Business Package</h4>
                            <ul>
                                <li>Recording Time: 200 Hours</li>
                                <li>Vacancies: Unlimited</li>
                                <li>Seats: 10</li>
                                <li>Text Questions: Yes</li>
                                <li>Video Questions: Yes</li>
                                <li>API Access: Yes</li>
                                <li>Data Management: Yes</li>
                                <li>Video Sharing: Yes</li>
                                <li>Rating: Yes</li>
                                <li>Collaboration: Yes</li>
                                <li>LinkedIn Marketing: Yes</li>
                                <li>Bulk Campaign Marketing: Yes</li>
                                <li>Video on Application: Yes</li>
                            </ul>
                            <div>
                                <h4>Pricing Options</h4>
                                <ul>
                                    <li>Monthly at { businessProMonthly }</li>
                                    <li style={{listStyle:'none'}}>or</li>
                                    <li>Annually at { businessProYearly }</li>
                                </ul>
                            </div>
                            <button className="purchase-btn" onClick={this.startPaymentFlow} data-index-id="4">Purchase Upgrade</button>
                        </div>
                        <div ref={this.enterpriseRef}>            
                        <h4>Enterprise Package</h4>
                            <ul>
                                <li>Recording Time: 1000 Hours</li>
                                <li>Vacancies: Unlimited</li>
                                <li>Seats: 25</li>
                                <li>Text Questions: Yes</li>
                                <li>Video Questions: Yes</li>
                                <li>API Access: Yes</li>
                                <li>Data Management: Yes</li>
                                <li>Video Sharing: Yes</li>
                                <li>Rating: Yes</li>
                                <li>Collaboration: Yes</li>
                                <li>LinkedIn Marketing: Yes</li>
                                <li>Bulk Campaign Marketing: Yes</li>
                                <li>Video on Application: Yes</li>
                            </ul>
                            <div>
                                <h4>Pricing Options</h4>
                                <ul>
                                    <li>Monthly at { enterpriseMonthly }</li>
                                    <li style={{listStyle:'none'}}>or</li>
                                    <li>Annually at { enterpriseYearly }</li>
                                </ul>
                            </div>
                            <button className="purchase-btn" onClick={this.startPaymentFlow} data-index-id="5">Purchase Upgrade</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="dialogNewCreate" ref={this.startPaymentFlowDialogRef}>        
                        <div>
                            <h4>Upgrade Flow</h4>
                            <div>
                                <span>Selected Package: { this.getSelectedPackage() }</span>
                            </div><br/>              
                            <span style={{display: 'block'}}>Select Subscription Payment Duration</span>
                            <select ref={this.packageDurationRef} style={{width: '100%', padding: '12px'}}>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                            </select><br/><br/>   
                            <div>
                                <button onClick={this.gotoSubscriptionAPI}>Make Payment</button>
                                <button onClick={()=>{this.startPaymentFlowDialogRef.current.style.display = "none";}}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
      }
}