/* eslint-disable react/jsx-no-comment-textnodes */
// Style
import './App.css';
import './pages/main.css';

// Logic
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Pages
import LoginPage from './pages/login';
import Login2Page from './pages/login2';
import TFAPage from './pages/tfa';
import RegisterPage from './pages/register';
import ForgotPage from './pages/forgot';
import Register2Page from './pages/register2';
import Register3Page from './pages/register3';
import Register4Page from './pages/register4';

import DashbaordPage from './pages/dashboard';
import DisabledPage from './pages/disabled';
import IntegrationsPage from './pages/integrations';
import LogsPage from './pages/logs';
import ProfilePage from './pages/profile';
import MetricsPage from './pages/metrics';
import SeatsPage from './pages/seats';
import SettingsPage from './pages/settings';
import UpgradePage from './pages/upgrade';
import VacanciesPage from './pages/vacancies';
import TemplatesPage from './pages/templates';
import TemplatePage from './pages/template';
import VacanciesSeatedPage from './pages/vacanciesseated';
import VacanciesArchivedPage from './pages/vacanciesarchived';
import VacancyPage from './pages/vacancy';
import OrgPage from './pages/org';
import TutorialPage from './pages/tutorial';
import ChangePasswordPage from './pages/changePassword';
import LogoutPage from './pages/logout';
import PayPalReturnPage from './pages/PayPalReturnPage';
import PayPalCancelPage from './pages/PayPalCancelPage';

import MarketingPage from './pages/marketing';

import MarketingViewPage from './pages/marketingview';

import PayPalPage from './pages/paypal';
import PricingPage from './pages/pricing';
import PromoCodeUsagePage from './pages/promocodeusage';
import { ToastContainer } from 'react-toastify';
import NewOrgPage from './pages/org-new';
import SurveyListPage from './pages/survey';
import SurveyViewPage from './pages/surveyview';
import IntroVideoManager from './pages/introvideomanager';
import ClearManager from './pages/ClearManager';

import VacancyPageV2 from './pages/vacancypagev2';
import TestPage from './pages/testPage';
import MarketingViewPageV2 from './pages/marketingviewpagev2';
import ClearInternal from './pages/clear-internal';
import { IntegrationInterviewITJs } from './pages/integration/IntegrationInterviewITJs';
import { IntegrationInterviewQuestions } from './pages/integration/IntegrationInterviewQuestions';
import TakeMeBack from './pages/take-me-back';
import VacanciesUsersPage from './pages/vacanciesusers';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={() => <LoginPage />} />
          {/* Registeration Pages (Disabled for now)*/}
          {/*<Route path="/register" exact component={() => <Register3Page />} />*/}
          {/*<Route path="/register/step/2" exact component={() => <RegisterPage />} />*/}
          {/*<Route path="/register/step/3" exact component={() => <Register4Page />} />*/}
          {/* Login Pages */}
          <Route path="/login" exact component={() => <LoginPage />} />
          <Route path="/login2" exact component={() => <Login2Page />} />
          <Route path="/2fa" exact component={() => <TFAPage />} />
          <Route path="/forgot" exact component={() => <ForgotPage />} />
          <Route path="/logout" exact component={() => <LogoutPage />} />
          <Route path="/disabled" exact component={() => <DisabledPage />} />
          {/* Dashboard Pages */}
          <Route path="/dashboard" exact component={() => <DashbaordPage />} />
          <Route path="/integration" exact component={() => <IntegrationsPage />} />
          <Route path="/logs" exact component={() => <LogsPage />} />
          <Route path="/profile" exact component={() => <ProfilePage />} />
          <Route path="/metrics" exact component={() => <MetricsPage />} />
          <Route path="/seats" exact component={() => <SeatsPage />} />
          <Route path="/settings" exact component={() => <SettingsPage />} />
          <Route path="/pricing" exact component={() => <PricingPage />} />
          <Route path="/promocodeusage" exact component={() => <PromoCodeUsagePage />} />
          <Route path="/upgrade" exact component={() => <UpgradePage />} />
          <Route path="/vacancies" exact component={() => <VacanciesPage />} />
          <Route path="/vacancies/users" exact component={() => <VacanciesUsersPage />} />
          <Route path="/vacancies/seated" exact component={() => <VacanciesSeatedPage />} />
          <Route path="/vacancies/archived" exact component={() => <VacanciesArchivedPage />} />
          {/*<Route path="/vacancy/:id" exact component={() => <VacancyPage />} />*/}
          <Route path="/vacancy/:id" exact component={() => <VacancyPageV2 />} />
          <Route path="/templates" exact component={() => <TemplatesPage />} />
          <Route path="/template/:id" exact component={() => <TemplatePage />} />
          <Route path="/org" exact component={() => <NewOrgPage />} />
          <Route path="/tutorial" exact component={() => <TutorialPage />} />
          <Route path="/change" exact component={() => <ChangePasswordPage />} />
          <Route path="/marketing" exact component={() => <MarketingPage />} />
          {/*<Route path="/marketing/:id" exact component={() => <MarketingViewPage />} />*/}
          <Route path="/marketing/:id" exact component={() => <MarketingViewPageV2 />} />
          {/* Payment */}
          <Route path="/payment/paypal/return" exact component={() => <PayPalReturnPage />} />
          <Route path="/payment/paypal/cancel" exact component={() => <PayPalCancelPage />} />
          {/* PayPal Management Page */}
          <Route path="/admin/paypal" exact component={() => <PayPalPage />} />
          {/* Survey Pages */}
          <Route path="/assessment" exact component={() => <SurveyListPage />} />
          <Route path="/assessment/view/:id" exact component={() => <SurveyViewPage />} />
          {/* Intro Video Manager Pages */}
          <Route path="/introvideomanager" exact component={() => <IntroVideoManager />} />
          {/* Clear Local Storage Cache */}
          <Route path="/clear" exact component={() => <ClearManager />} />
          <Route path="/clear-internal" exact component={() => <ClearInternal />} />
          <Route path="/test" exact component={() => <TestPage />} />
          {/* Custom Integration*/}
          <Route path="/integration/interview/:id/:api/itjs" exact component={() => <IntegrationInterviewITJs />} />
          <Route path="/integration/interview/:id/:api/questions" exact component={() => <IntegrationInterviewQuestions />} />
          <Route path="/take-me-back" exact component={() => <TakeMeBack />} />
        </Switch>
      </Router>      
      <ToastContainer></ToastContainer>
    </div>
  );
}

export default App;