import React, { useState, useEffect, useRef } from 'react';
import './openrate.css';

function generateRandomString(length) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomString = "";
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomString += charset.charAt(randomIndex);
    }
  
    return randomString;
  }

export default function OpenRate(props:any){
    const { data, url, text } = props;
    const { total, value, opens } = data;
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ randomID, setRandomID ] = useState("random-" + generateRandomString(12));
    const canvasRef = useRef<any>();
    
    const degreesToRadians = (degrees) => {
        return degrees * (Math.PI / 180);
    }

    const getAngles = (totalX, valueX) => {
        const totalAngle = (valueX / totalX) * 360;
        const valueAngle = 360 - totalAngle;
        return {
            totalAngle,
            valueAngle
        }
    }

    const loadData = () => {
        //const canvas = document.getElementById(randomID) as any;
        const canvas = canvasRef.current;
        // Colors
        const ValueColor = "#4EC49E";
        const TotalColor = "#0188FE";
        // Setup Canvas to display correctly
        let width = 75;
        let height = 75;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        canvas.width = width;
        canvas.height = height;
        // Calculations
        let x = width / 2;
        let y = height / 2;
        // Drawing
        const context:any = canvas?.getContext("2d");
        // => Clear
        context.clearRect(0, 0, width, height);
        // => Draw White Cirlce
        context.fillStyle = "#A1A1A1"; // Fill color
        context.beginPath();
        context.arc(x, y, 36, 0, Math.PI * 2);
        context.fill(); // Fill the circle
        context.closePath();
        // => Load Angles
        const { totalAngle, valueAngle } = getAngles(total, value);
        let lastAngle = 0;
        // => Draw Overlap - Value
        context.fillStyle = ValueColor; // Fill color
        context.beginPath();
        context.moveTo(x, y);
        context.arc(x, y, 36, degreesToRadians(lastAngle), degreesToRadians(lastAngle + valueAngle));
        context.fill(); // Fill the circle
        context.closePath();
        lastAngle += valueAngle;
        // => Draw Overlap - Total
        context.fillStyle = TotalColor; // Fill color
        context.beginPath();
        context.moveTo(x, y);
        context.arc(x, y, 36, degreesToRadians(lastAngle), degreesToRadians(lastAngle + totalAngle));
        context.fill(); // Fill the circle
        context.closePath();
        lastAngle += totalAngle;
        // => Draw Overlap - White Circle
        context.fillStyle = "#FFF"; // Fill color
        context.beginPath();
        context.moveTo(x, y);
        context.arc(x, y, 16, 0, Math.PI * 2);
        context.fill(); // Fill the circle
        context.closePath();
    }

    useEffect(()=>{
        if(!canvasRef.current) return;
        loadData(); 
    }, [canvasRef, data, total, value]);
    return <div className='open-rate-container' onClick={ e => {
        window.location.href = url;
    }} onMouseEnter={ e => {
        setShowPopUp(true);
    }} onMouseLeave={ e => {
        setShowPopUp(false);
    }}>
        <canvas ref={canvasRef} id={randomID}></canvas>
        <span>{ text }</span>
        {
            showPopUp && <div className='open-rate-popup'>
                <h4>{ text }</h4>
                <ul>
                    <li><strong>Viewed:</strong>&nbsp;{ value }</li>
                    <li><strong>Invites:</strong>&nbsp;{ total }</li>
                </ul>
            </div>
        }
    </div>
}