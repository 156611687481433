import React, { useState, useEffect } from "react";

import API from '../services/api';

import fuzzytime from 'fuzzy-time';

import "./activitiesContainer.css";

export default function Activities() {
    const [activities, setActivities] = useState([]);
    const opts = {
        setMinDays: 5,
        days: 'd',
        hours: 'h',
        minutes: 'm',
        on: 'on',
        setMonthArray: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        dateFormat: 'simple'
    }
    async function fetchData(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.activities.getActivities(api_key);
        setActivities(result['activities'].reverse().slice(0, 10));
    }

    useEffect(()=>{
        fetchData();
        const interval=setInterval(()=>{
            fetchData();
        },30000)
        return ()=>clearInterval(interval);
    }, []);

    return (
        <div className="activities-container">
            <h1>Recent Activities</h1>
            <h4>{ activities ? (activities.length === 1 ? "Activity" : "Activities") : "Activity" }</h4>
            <div className="activities-container-items">
                {
                    activities.map((item) => {
                        return (
                            <div key={item.id} className="activities-container-items-item">
                                <div>{ fuzzytime(item['created_at'], opts) }</div>
                                <div>{ item['title'] }</div>
                            </div>
                        )                            
                    })
                }
            </div>
        </div>
    );
}