import React, { createRef, useEffect, useState } from "react";
import axios from 'axios';

import { DateTime } from "luxon";

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { AiFillStar, AiFillCamera, AiOutlineLoading } from 'react-icons/ai';
import { FiFilter } from 'react-icons/fi';


import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import { Tooltip as ReactTooltip } from "react-tooltip";

import fuzzytime from 'fuzzy-time';

import "./vacancies.css";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";


import TutorialComponent from "../components/tutorial";

import { ToastContainer, toast } from 'react-toastify';
import { fixDateWithTime } from "../services/utils";

const PAGE_SIZE = 10;

export default function SurveyListPage(props:any){
    const [ surveys, setSurveys] = useState<any[]>([]);
    const [ showCreateSurvey, setShowCreateSurvey] = useState<boolean>(false);
    const [ createSurveyTitle, setCreateSurveyTitle] = useState<string>("");

    const getSurveys = async () => {
        toast("Loading");
        let response = await axios.post(API.baseURL + "/api/v1/surveys/list", {
            'api_key': localStorage.getItem("api_key")
        });
        let data = response.data;
        setSurveys(data.results);
        toast("Loaded");
    }

    useEffect(()=>{
        (document.querySelector('body') as any).style.backgroundColor = "#EEF0F8"; // quick hack 
        getSurveys();
    }, []);

    return (
        <>
        <div className="dashboardContainer">
            <SideBarComponent></SideBarComponent>
            <div className="dashboarContent">
                <UpperBarComponent></UpperBarComponent>
                <div className="pageNameInfo">
                    <span>Assessments</span>
                </div>
                <div className="vacancy-table">
                    <table>
                        <tr className="noHover">
                            <th style={{minWidth:'150px', textAlign:'left'}}>                                    
                                <span className="dropdown" style={{display:'inline-block'}}>
                                    <button id="vacancies_actions">Actions</button>
                                    <label>
                                        <input type="checkbox" />
                                        <ul>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={()=>{setShowCreateSurvey(true);}}>Add Assessment</li>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={()=>{}}>Delete</li>
                                        </ul>
                                    </label>
                                </span>
                            </th>
                            <th style={{ width:"360px" }}>Title</th>
                            <th style={{ width:"360px" }}>Date</th>
                        </tr>     
                        {
                                surveys.map((survey, index)=>{
                                    return (
                                    <tr key={"interview-" + survey.id}  className="table-body">
                                        <td style={{minWidth:'150px'}}>
                                            <input type="checkbox" data-vacancy-checkbox={true} survey-id={survey.id} style={{display:'inline-block', transform:"scale(1.5)", marginRight:'8px'}}/>
                                            <span className="dropdown" style={{display:'inline-block'}}>
                                                <button>Actions</button>
                                                <label>
                                                    <input type="checkbox" />
                                                    <ul>
                                                        <li data-survey-id={survey.id} onClick={ () => {
                                                            window.location.href = `/assessment/view/${survey.id}`;
                                                        }}>Open</li>
                                                        <li data-survey-id={survey.id} onClick={()=>{

                                                        }}>Delete</li>
                                                    </ul>
                                                </label>
                                            </span>
                                        </td>
                                        <td style={{textAlign:'center', maxWidth:'360px'}}>{ survey.title }</td>
                                        <td style={{padding:'32px', textAlign:'center'}}>{ fixDateWithTime(survey.created_at) }</td>
                                    </tr>
                                )})
                            }
                    </table>
                </div>
            </div>
        </div>
        { showCreateSurvey && <div>
            <div className="dialogNewCreate">        
                <div>
                    <h4>Add a new Assessment</h4>
                    <div>
                        <input type="text" className="createVacancyInput" placeholder="Assessment Name" onChange={ e => setCreateSurveyTitle( e.target.value)}/>
                    </div>
                    <div>
                        <button onClick={async ()=>{
                            toast("Loading");
                            let response = await axios.post(API.baseURL + "/api/v1/surveys/add", {
                                'api_key': localStorage.getItem("api_key"),
                                'name': createSurveyTitle
                            });
                            toast("Survey Added");
                            await getSurveys();
                            setShowCreateSurvey(false);
                        }}>Create</button>
                        <button onClick={()=>{
                            setShowCreateSurvey(false);
                        }}>Close</button>
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
}