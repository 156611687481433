import React, { createRef } from "react";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import API from './../services/api';

import './tutorialPage.css';

export default class TutorialPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          pages: [],
          tutorial_items: [],
          current_page: 0,
          current_index: 0,
          current_sub_index: 0,
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 

      this.pagesRef = createRef();
      this.pageSelectionDialogRef = createRef();
      this.pageTutorialsDialogRef = createRef();
      this.ItemViewerListRef = createRef();

      this.createTutorialPageItemDialogRef = createRef();

      this.createTutorialPageItemDialogRefTitle = createRef();
      this.createTutorialPageItemDialogRefDescription = createRef();
      this.createTutorialPageItemDialogRefElement = createRef();

      this.createTutorialPageItemItemDialogRef = createRef();
      this.createTutorialPageItemItemDialogTypeRef = createRef();
      this.createTutorialPageItemItemDialogTextRef = createRef();
      this.createTutorialPageItemItemDialogElementSelectorRef = createRef();
      this.createTutorialPageItemItemDialogImageURLRef = createRef();
      this.createTutorialPageItemItemDialogAutoClickRef = createRef();
      
      this.viewcreateTutorialPageItemItemDialogRef = createRef();
      this.viewcreateTutorialPageItemItemDialogIDRef = createRef();
      this.viewcreateTutorialPageItemItemDialogTypeRef = createRef();
      this.viewcreateTutorialPageItemItemDialogTextRef = createRef();
      this.viewcreateTutorialPageItemItemDialogElementSelectorRef = createRef();
      this.viewcreateTutorialPageItemItemDialogImageURLRef = createRef();
      this.viewcreateTutorialPageItemItemDialogAutoClickRef = createRef();
      


      this.ItemViewerListRefTitle = createRef();
      this.ItemViewerListRefDescription = createRef();
      this.ItemViewerListRefElement = createRef();
      this.ItemViewerListRefAccountType = createRef();

      this.updateTutorialItem = this.updateTutorialItem.bind(this);

      this.openPage = this.openPage.bind(this);
      this.closeDialog = this.closeDialog.bind(this);
      this.createPageItem = this.createPageItem.bind(this);
      this.newItem = this.newItem.bind(this);
      this.createPageItemItem = this.createPageItemItem.bind(this);

      this.addNewTutorial = this.addNewTutorial.bind(this);
      
      this.openTutorialPageItem = this.openTutorialPageItem.bind(this);

      this.reloadPageTutorials = this.reloadPageTutorials.bind(this);
      this.reloadPageTutorialsItems = this.reloadPageTutorialsItems.bind(this);
      
      this.deleteTutorialPage = this.deleteTutorialPage.bind(this);

      this.closeItemDialog = this.closeItemDialog.bind(this);
      this.closeTutorialItem = this.closeTutorialItem.bind(this);

      this.openSubItem = this.openSubItem.bind(this);

      this.deleteItem = this.deleteItem.bind(this);

      this.closeItem = this.closeItem.bind(this);

      this.saveItem = this.saveItem.bind(this);
    }

    async updateTutorialItem(){
        let title = this.ItemViewerListRefTitle.current.value;
        let description = this.ItemViewerListRefDescription.current.value;
        let element_selector = this.ItemViewerListRefElement.current.value;
        let account_type = parseInt(this.ItemViewerListRefAccountType.current.value);  
        let id = this.state.tutorial_items[this.state.current_index]['info']['id'];
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.updatePageTutorial(api_key, id, title, description, element_selector);
        this.ItemViewerListRef.current.style.display = 'none';
        this.reloadPageTutorials();
    }

    async saveItem(){
        let api_key = localStorage.getItem('api_key');
        let id = parseInt(this.viewcreateTutorialPageItemItemDialogIDRef.current.value);
        let tutorial_type = parseInt(this.viewcreateTutorialPageItemItemDialogTypeRef.current.value);
        let element_selector = this.viewcreateTutorialPageItemItemDialogElementSelectorRef.current.value;
        let text = this.viewcreateTutorialPageItemItemDialogTextRef.current.value;
        let image_url = this.viewcreateTutorialPageItemItemDialogImageURLRef.current.value;
        let auto_click = this.viewcreateTutorialPageItemItemDialogAutoClickRef.current.checked;
        console.log(auto_click);
        let result = await API.tutorial.updatePageTutorialItem(api_key, id, tutorial_type, element_selector, text, image_url, auto_click);
        let success = result['success'];
    }

    async componentDidMount(){
        this.setState({
            ...this.state,
            pages: API.tutorial.enum_extended
        });
      
        this.pageSelectionDialogRef.current.style.display = "block";
        this.pageTutorialsDialogRef.current.style.display = "none";
        this.createTutorialPageItemDialogRef.current.style.display = "none";

        this.ItemViewerListRef.current.style.display = "none";
        this.createTutorialPageItemItemDialogRef.current.style.display = "none";   
        this.viewcreateTutorialPageItemItemDialogRef.current.style.display = "none";                
    }

    async openPage(e){
        let pid = parseInt(e.target.getAttribute('page-id'));
        this.pageSelectionDialogRef.current.style.display = "none";
        this.pageTutorialsDialogRef.current.style.display = "block";
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.getPage(api_key, pid);
        let tutorial_items = result['tutorials'];
        this.setState({
            ...this.state,
            current_page: pid,
            tutorial_items: tutorial_items
        });
    }

    async reloadPageTutorials(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.getPage(api_key, this.state.current_page);
        let tutorial_items = result['tutorials'];    
        this.setState({
            ...this.state,
            tutorial_items: tutorial_items
        });
    }
    async reloadPageTutorialsItems(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.getPage(api_key, this.state.current_page);
        let tutorial_items = result['tutorials'];    
        this.setState({
            ...this.state,
            tutorial_items: tutorial_items
        });
    }
    closeDialog(){
        this.createTutorialPageItemDialogRef.current.style.display = "none";
    }
    closeItemDialog(){
        this.createTutorialPageItemItemDialogRef.current.style.display = "none";
    }
    async createPageItem(){
        let title = this.createTutorialPageItemDialogRefTitle.current.value;
        let description = this.createTutorialPageItemDialogRefDescription.current.value;
        let element = this.createTutorialPageItemDialogRefElement.current.value;
        if(!title || !description || !element){
            alert("Enter valid details");
            return;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.createPageTutorial(api_key, this.state.current_page, title, description, element);
        this.createTutorialPageItemDialogRef.current.style.display = "none";
        this.reloadPageTutorials();
    }

    async createPageItemItem(){        
        let type = parseInt(this.createTutorialPageItemItemDialogTypeRef.current.value);
        let text = this.createTutorialPageItemItemDialogTextRef.current.value;
        let elementor_selector = this.createTutorialPageItemItemDialogElementSelectorRef.current.value;
        let image_url = this.createTutorialPageItemItemDialogImageURLRef.current.value;
        let auto_click = this.createTutorialPageItemItemDialogAutoClickRef.current.checked;
        if(!text || !elementor_selector){
            alert("Enter valid details");
            return;
        }
        let parent_id = this.state.tutorial_items[this.state.current_index]['info']['id'];
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.createPageTutorialItem(api_key, parent_id, type, elementor_selector, text, image_url, auto_click);
        this.createTutorialPageItemItemDialogRef.current.style.display = "none";
        this.reloadPageTutorialsItems();
    }
    addNewTutorial(){
        this.createTutorialPageItemDialogRef.current.style.display = "flex";
        this.createTutorialPageItemDialogRefTitle.current.value = "";
        this.createTutorialPageItemDialogRefDescription.current.value = "";
    }

    newItem(){        
        this.createTutorialPageItemItemDialogRef.current.style.display = "flex";
        this.createTutorialPageItemItemDialogRef.current.value = "";       
    }

    openTutorialPageItem(e){
        this.ItemViewerListRef.current.style.display = 'none';
        let index_id = parseInt(e.target.getAttribute('index-id'));
        let item_id = parseInt(e.target.getAttribute('item-id'));      
        this.setState({
            ...this.state,
            current_index: index_id,
        });
        this.ItemViewerListRef.current.style.display = 'block';
        let info = this.state.tutorial_items[index_id]['info'];
        this.ItemViewerListRefTitle.current.value = info['title'];
        this.ItemViewerListRefDescription.current.value = info['description'];
        this.ItemViewerListRefElement.current.value = info['element_selector'];
        this.ItemViewerListRefAccountType.current.value = info['account_type'].toString();
    }

    async openSubItem(e){
        let item_id = parseInt(e.target.getAttribute('item-id')); 
        this.viewcreateTutorialPageItemItemDialogRef.current.style.display = "none";
        let api_key = localStorage.getItem('api_key');     
        let result = await API.tutorial.getItem(api_key, item_id);
        let item = result['item'];
        this.viewcreateTutorialPageItemItemDialogRef.current.style.display = "block";
        this.viewcreateTutorialPageItemItemDialogIDRef.current.value = item['id'];
        this.viewcreateTutorialPageItemItemDialogTypeRef.current.value = item['tutorial_type'].toString();
        this.viewcreateTutorialPageItemItemDialogTextRef.current.value = item['text'];
        this.viewcreateTutorialPageItemItemDialogElementSelectorRef.current.value = item['element_selector'];
        this.viewcreateTutorialPageItemItemDialogImageURLRef.current.value = item['image_url'];
        this.viewcreateTutorialPageItemItemDialogAutoClickRef.current.value = item['auto_click'] ? 'on' : 'off';
        console.log(item);
    }

    async deleteTutorialPage(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.deletePageTutorial(api_key, parseInt(this.state.tutorial_items[this.state.current_index]['info']['id']));
        this.ItemViewerListRef.current.style.display = 'none';
        this.reloadPageTutorials();        
    }

    closeTutorialItem(){
        this.ItemViewerListRef.current.style.display = "none";
    }

    async deleteItem(){
        let item_id = this.viewcreateTutorialPageItemItemDialogIDRef.current.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.deletePageTutorialItem(api_key, item_id);
        this.viewcreateTutorialPageItemItemDialogRef.current.style.display = "none";
        this.reloadPageTutorialsItems();
    }
    closeItem(){
        this.viewcreateTutorialPageItemItemDialogRef.current.style.display = "none";
    }
    render() {
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Tutorial - Designer</span> 
                    </div>     
                    <div ref={this.pageSelectionDialogRef}>
                        <h4 className="longText" style={{fontWeight:'500', borderRadius: '8px'}}>Select a Page&nbsp;&nbsp;&gt;&nbsp;&nbsp;In order to manage the tutorial options for each specific page</h4>
                        <div ref={this.pagesRef} className="page-name-buttons-container">
                            {
                                this.state.pages && this.state.pages.map((page, index)=>{
                                    return <button onClick={this.openPage} key={index} page-id={page['id']}>{page['name']}</button>
                                })
                            }
                        </div>
                    </div>
                    <div ref={this.pageTutorialsDialogRef}>
                        <h4 className="longText" style={{fontWeight:'500'}}>Manage Tutorials for the <span style={{fontWeight: 'bold'}}>{API.tutorial.enum[this.state.current_page.toString()]}</span> Page</h4>
                        <div className="tutorialPageListContainer">
                            <div className="tutorialRecords">
                                <div className="title"><span>Tutorials List</span>&nbsp;<button onClick={this.addNewTutorial}>Add New Tutorial</button></div>
                                <div className="records">
                                    {
                                        this.state.tutorial_items.map((item, index)=>{
                                         return <button key={index} index-id={index} item-id={item.info.id} onClick={this.openTutorialPageItem}>{item.info.title}</button>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tutorialItemViewer" ref={this.ItemViewerListRef}>
                            <h4 style={{fontWeight: 500}}>Tutorial Item (#1)</h4>
                            <div className="label">Title</div>
                            <input type="text" ref={this.ItemViewerListRefTitle} placeholder="Tutorial Title"/>
                            <div className="label">Description</div>
                            <input type="text" ref={this.ItemViewerListRefDescription} placeholder="Tutorial Description"/>
                            <div className="label">Element Selector</div>
                            <input type="text" ref={this.ItemViewerListRefElement} placeholder="Tutorial Title"/>
                            <div className="label">Account Type</div>
                            <select ref={this.ItemViewerListRefAccountType}>
                                <option value="-1">All</option>
                                <option value="0">Free</option>
                                <option value="1">Lite</option>
                                <option value="2">Lite Business</option>
                                <option value="3">Business</option>
                                <option value="4">Enterprise</option>
                            </select>
                            <div className="recordButtons">
                                <button style={{height:'64px'}} className="red" onClick={this.deleteTutorialPage}>Delete</button>
                                <button style={{height:'64px'}} onClick={this.closeTutorialItem}>Close</button>
                                <button style={{height:'64px'}} className="green" onClick={this.updateTutorialItem}>Save Changes</button>
                            </div>
                            <h5>Tutorial Steps</h5>
                            <div className="tutorialItemContainer" style={{width: '100%'}}>                                  
                                <button className="blue" onClick={this.newItem}>Add New Item</button>                              
                                {
                                      this.state.tutorial_items[this.state.current_index] && this.state.tutorial_items[this.state.current_index]['items'] && this.state.tutorial_items[this.state.current_index]['items'].map((item, index)=>{
                                        return <button key={index} index-id={index} item-id={item['id']} onClick={this.openSubItem}>{item['text']}</button>
                                     })
                                }
                            </div>
                        </div>
                        <div className="tutorialItemViewerContainer" ref={this.viewcreateTutorialPageItemItemDialogRef}>
                            <h4 style={{fontWeight: 500}}>Tutorial Item (#1)</h4>
                            <div className="tutorialItemViewerContainerData">
                                <input type="hidden" ref={this.viewcreateTutorialPageItemItemDialogIDRef}/>
                                <div className="label">Type</div>
                                <select ref={this.viewcreateTutorialPageItemItemDialogTypeRef}>
                                    <option value="0">Info</option>
                                    <option value="1">Info With Picture</option>
                                    <option value="2">Video</option>
                                </select>  
                                <div className="label">Text</div>
                                <input type="text" placeholder="Text Here" ref={this.viewcreateTutorialPageItemItemDialogTextRef}/> 
                                <div className="label">Element Selector</div>
                                <input type="text" placeholder="Element Selector" ref={this.viewcreateTutorialPageItemItemDialogElementSelectorRef}/> 
                                <div className="label">Image URL</div>  
                                <input type="text" placeholder="Image URL (only used if enabled above)" ref={this.viewcreateTutorialPageItemItemDialogImageURLRef}/>   
                                <label style={{padding:"16px 0px", display:'block'}}>Auto Click: <input type="checkbox" placeholder="" style={{transform:"scale(1.5)", marginLeft: '16px'}} ref={this.viewcreateTutorialPageItemItemDialogAutoClickRef}/></label>
                            </div>                            
                            <div className="recordButtons">
                                <button style={{height:'64px'}} className="red" onClick={this.deleteItem}>Delete</button>
                                <button style={{height:'64px'}} onClick={this.closeItem}>Close</button>
                                <button style={{height:'64px'}} className="green" onClick={this.saveItem}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dialogNewCreate" ref={this.createTutorialPageItemDialogRef}>        
                <div>
                    <h4>Add a new tutorial</h4>
                    <div style={{textAlign: 'left'}}>
                        <div className="label">Tutorial Title</div>
                        <input style={{width: 'calc(100% - 32px)', padding: '12px', margin: '4px 0px'}} type="text" ref={this.createTutorialPageItemDialogRefTitle} placeholder="Tutorial Title"/>                        
                        <div className="label">Tutorial Description</div>
                        <input style={{width: 'calc(100% - 32px)', padding: '12px', margin: '4px 0px'}} type="text" ref={this.createTutorialPageItemDialogRefDescription} placeholder="Tutorial Description"/>
                        <div className="label">Tutorial Element Selector</div>
                        <input style={{width: 'calc(100% - 32px)', padding: '12px', margin: '4px 0px'}} type="text" ref={this.createTutorialPageItemDialogRefElement} placeholder="Tutorial Element Selector"/>                        
                    </div>
                    <div>
                        <button onClick={this.createPageItem}>Create</button>
                        <button onClick={this.closeDialog}>Close</button>
                    </div>
                </div>
            </div><div className="dialogNewCreate" ref={this.createTutorialPageItemItemDialogRef}>        
                <div>
                    <h4>Add a new tutorial</h4>
                    <div style={{textAlign: 'left'}} className="tutorialItemViewerContainerData">
                        <div className="label">Type</div>
                        <select ref={this.createTutorialPageItemItemDialogTypeRef}>
                            <option value="0">Info</option>
                            <option value="1">Info With Picture</option>
                            <option value="2">Video</option>
                        </select>  
                        <div className="label">Text</div>
                        <input type="text" placeholder="Text Here" ref={this.createTutorialPageItemItemDialogTextRef}/> 
                        <div className="label">Element Selector</div>
                        <input type="text" placeholder="Element Selector" ref={this.createTutorialPageItemItemDialogElementSelectorRef}/> 
                        <div className="label">Image/Video URL</div>  
                        <input type="text" placeholder="Image URL (only used if enabled above)" ref={this.createTutorialPageItemItemDialogImageURLRef}/>   
                        <label style={{padding:"16px 0px", display:'block'}}>Auto Click: <input type="checkbox" placeholder="" style={{transform:"scale(1.5)", marginLeft: '16px'}} ref={this.createTutorialPageItemItemDialogAutoClickRef}/></label>
                    </div>
                    <div>
                        <button onClick={this.createPageItemItem}>Create</button>
                        <button onClick={this.closeItemDialog}>Close</button>
                    </div>
                </div>
            </div>
            </>
        );
      }
}