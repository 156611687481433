import React from "react";

export default class LogoutPage extends React.Component {
    render() {
        // HTML
        return (
            <div className="outer">
                <div className="middle">
                    <div className="container">
                        <div className="loginFormLogo"></div><br /><br />
                        <div className="form">
                            <span>You have been logged out.</span>
                            <a href="/login" id="gotoLoginLink">Click here to login</a>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}