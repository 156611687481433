import React from "react";

import API from './../services/api';
// TSX
import Activities from "../components/activities";
import MyStorageComponent from "../components/mystorage";
import UsageTotalComponent from "../components/usagetotals";

// JSX
import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import AutoManageComponent from "../components/automanage";
import ExtraSpaceComponent from '../components/extraspace';
import CustomWatermarkComponent from "../components/customWatermark";

import TutorialComponent from "../components/tutorial";

import PayFlowComponent from '../components/payflow';
import { createRef } from "react";


import MasterCard from './../media/logos/mastercard.png';
import MasterCard2 from './../media/logos/mastercard2.png';
import Visa from './../media/logos/visa.png';
import Visa2 from './../media/logos/visa2.png';
import PayGate from './../media/logos/paygate.png';
import EmailSignatureComponent from "../components/email_signature";

import './dashboard.css';
import { AiOutlineLoading } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

export default class DashboardPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          message: "loading",
          user: false,
          storageNotice: false,
          expired: false,
          shownPrice: 0.00,
          vatMultiplier: 1.15,
          freePriceMonthly: "$0.00", // set default as dollar value
          soloPriceMonthly: "$0.00", // set default as dollar value
          litePriceMonthly: "$0.00", // set default as dollar value
          liteBusinessPriceMonthly: "$0.00", // set default as dollar value
          businessPriceMonthly: "$0.00", // set default as dollar value
          enterprisePriceMonthly: "$0.00", // set default as dollar value
          pricing: {},
          loading: false
      };
      this.package_duration = createRef();
      this.package_selected = 1;
      this.package_duration.current = 1;
      this.hasMarketing = 1;
      this.price = 0;

      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack
      this.payflowRef = createRef();
      this.marketing_selectorref = createRef();
      this.subscription_duration_selector = createRef();
      
      this.paygateProcessFormPayRequestFormRef = createRef();
      this.paygateProcessFormPayRequestIDRef = createRef();
      this.paygateProcessFormChecksumRef = createRef();
      
      this.subtotalref = createRef();
      this.vatref = createRef();
      this.totalwithvat = createRef();



      this.setPricing = this.setPricing.bind(this);

    }

    setPricing(){
        let duration =  this.package_duration.current;
        let country = localStorage.getItem('country');
        let marketing = this.hasMarketing;
        let package_selected = this.package_selected;
        if(country == "ZA"){ // South Africa in Rands
            if(duration == 0){ // monthly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>R { this.state.pricing.za_solo_monthly } /m</span>,
                        litePriceMonthly: <span>R { this.state.pricing.za_lite_monthly } /m</span>,
                        liteBusinessPriceMonthly: <span>R { this.state.pricing.za_business_lite_monthly } /m</span>,
                        businessPriceMonthly: <span>R { this.state.pricing.za_business_pro_monthly } /m</span>,
                        enterprisePriceMonthly: "R0/m",
                    });
                    let price = [
                        this.state.pricing.za_solo_monthly,
                        this.state.pricing.za_solo_monthly,
                        this.state.pricing.za_lite_monthly,
                        this.state.pricing.za_business_lite_monthly,
                        this.state.pricing.za_business_pro_monthly
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>R { this.state.pricing.za_solo_monthly_without_marketing } /m</span>,
                        litePriceMonthly: <span>R { this.state.pricing.za_lite_monthly_without_marketing } /m</span>,
                        liteBusinessPriceMonthly: <span>R { this.state.pricing.za_business_lite_monthly_without_marketing } /m</span>,
                        businessPriceMonthly: <span>R { this.state.pricing.za_business_pro_monthly_without_marketing } /m</span>,
                        enterprisePriceMonthly: "R0/m",
                    });
                    
                    let price = [
                        this.state.pricing.za_solo_monthly_without_marketing,
                        this.state.pricing.za_solo_monthly_without_marketing,
                        this.state.pricing.za_lite_monthly_without_marketing,
                        this.state.pricing.za_business_lite_monthly_without_marketing,
                        this.state.pricing.za_business_pro_monthly_without_marketing
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                }
            } else if(duration == 1){ // yearly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>R { (this.state.pricing.za_solo_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_solo_yearly).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>R { (this.state.pricing.za_lite_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_lite_yearly).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>R { (this.state.pricing.za_business_lite_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_business_lite_yearly).toFixed(2) }</span></>,
                        businessPriceMonthly: <><span>R { (this.state.pricing.za_business_pro_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_business_pro_yearly).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "R0.00 p/m billed annually",
                    });
                    
                    let price = [
                        this.state.pricing.za_solo_yearly,
                        this.state.pricing.za_solo_yearly,
                        this.state.pricing.za_lite_yearly,
                        this.state.pricing.za_business_lite_yearly,
                        this.state.pricing.za_business_pro_yearly
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                } else {                    
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>R { (this.state.pricing.za_solo_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_solo_yearly_without_marketing).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>R { (this.state.pricing.za_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>R { (this.state.pricing.za_business_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_business_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        businessPriceMonthly: <><span>R { (this.state.pricing.za_business_pro_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader3">annual amount: R { (this.state.pricing.za_business_pro_yearly_without_marketing).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "R0.00 p/m billed annually",
                    });                    
                    let price = [
                        this.state.pricing.za_solo_yearly_without_marketing,
                        this.state.pricing.za_solo_yearly_without_marketing,
                        this.state.pricing.za_lite_yearly_without_marketing,
                        this.state.pricing.za_business_lite_yearly_without_marketing,
                        this.state.pricing.za_business_pro_yearly_without_marketing
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                }
            }
        } else { // International in USD
            if(duration == 1){ // monthly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>$ { this.state.pricing.us_solo_monthly } /m</span>,
                        litePriceMonthly: <span>$ { this.state.pricing.us_lite_monthly } /m</span>,
                        liteBusinessPriceMonthly: <span>$ { this.state.pricing.us_business_lite_monthly } /m</span>,
                        businessPriceMonthly: <span>$ { this.state.pricing.us_business_pro_monthly } /m</span>,
                        enterprisePriceMonthly: "$0/m",
                    });
                    let price = [
                        this.state.pricing.us_solo_monthly,
                        this.state.pricing.us_solo_monthly,
                        this.state.pricing.us_lite_monthly,
                        this.state.pricing.us_business_lite_monthly,
                        this.state.pricing.us_business_pro_monthly
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>$ { this.state.pricing.us_solo_monthly_without_marketing } /m</span>,
                        litePriceMonthly: <span>$ { this.state.pricing.us_lite_monthly_without_marketing } /m</span>,
                        liteBusinessPriceMonthly: <span>$ { this.state.pricing.us_business_lite_monthly_without_marketing } /m</span>,
                        businessPriceMonthly: <span>$ { this.state.pricing.us_business_pro_monthly_without_marketing } /m</span>,
                        enterprisePriceMonthly: "$0/m",
                    });
                    let price = [
                        this.state.pricing.us_solo_monthly_without_marketing,
                        this.state.pricing.us_solo_monthly_without_marketing,
                        this.state.pricing.us_lite_monthly_without_marketing,
                        this.state.pricing.us_business_lite_monthly_without_marketing,
                        this.state.pricing.us_business_pro_monthly_without_marketing
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                }
            } else if(duration == 2){ // yearly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>$ { (this.state.pricing.us_solo_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_solo_yearly).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>$ { (this.state.pricing.us_lite_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_lite_yearly).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>$ { (this.state.pricing.us_business_lite_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_business_lite_yearly).toFixed(2) }</span></>,
                        businessPriceMonthly: <><span>$ { (this.state.pricing.us_business_pro_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_business_pro_yearly).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "$0 p/m billed annually",
                    });
                    let price = [
                        this.state.pricing.us_solo_yearly,
                        this.state.pricing.us_solo_yearly,
                        this.state.pricing.us_lite_yearly,
                        this.state.pricing.us_business_lite_yearly,
                        this.state.pricing.us_business_pro_yearly
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                } else {                    
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>$ { (this.state.pricing.us_solo_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_solo_yearly_without_marketing).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>$ { (this.state.pricing.us_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>$ { (this.state.pricing.us_business_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_business_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        businessPriceMonthly:<><span>$ { (this.state.pricing.us_business_pro_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader3">annual amount: $ { (this.state.pricing.us_business_pro_yearly_without_marketing).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "$0 p/m billed annually",
                    });
                    let price = [
                        this.state.pricing.us_solo_yearly_without_marketing,
                        this.state.pricing.us_solo_yearly_without_marketing,
                        this.state.pricing.us_lite_yearly_without_marketing,
                        this.state.pricing.us_business_lite_yearly_without_marketing,
                        this.state.pricing.us_business_pro_yearly_without_marketing
                    ][this.package_selected];
                    try {
                        this.subtotalref.current.innerHTML = this.currency + (price).toFixed(2);
                        this.vatref.current.innerHTML = this.currency + (price * 0.15).toFixed(2);
                        this.totalwithvat.current.innerHTML = this.currency + (price *  1.15).toFixed(2);
                    } catch(e){}
                }
            }
        }
    }

    async componentDidMount(){
        //check if user is disabled.
        let api_key = localStorage.getItem('api_key');
        if(!api_key){
            window.location.href = '/login';
            return;
        }
        let result = await API.user.isDisabled(api_key);
        let isDisabled = result['disabled'];
        if(isDisabled){
            localStorage.setItem('api_key', "");
            window.location.href = '/disabled';
        }

        result = await API.user.getUser(api_key);
        let user = result['user'];
        
        result = await API.storage.getMyStorage(api_key);
        let total = result['total'];
        let used = result['used'];
        let assigned = result['assigned'];        
        let leftOver = total - used;
        leftOver = leftOver < 0 ? 0 : leftOver;
        if(leftOver <= 0){
            document.getElementById("storage-notice-no-space").style.display = ((leftOver) <= 0) ? "flex" : "none";
        } else if(leftOver <= 1){
            document.getElementById("storage-notice").style.display = ((leftOver) <= 0) ? "flex" : "none";
        }
        let message = "";
        let adminMessage = "As a site administrator you are not required to purchase a subscription or extra storage space.";
        let testerMessage = "As a site tester you are not required to purchase a subscription or storage space. however you do have some limitations";
        let freeAccount = "You have a Free account which has some limitations. Upgrade your account to remove these limitations";
        let seatUser = "As a Seat account, you are bound by your parent account, and have the same limitations";
        if(user.isAdmin){
            this.setState({...this.state, message: adminMessage, user:user});
        } else if(user.isTester){
            this.setState({...this.state, message: testerMessage, user:user});
        } else if(user.package_number == 0 || user.package_number == null){
            this.setState({...this.state, message: freeAccount, user:user});
        }else if(user.seat_user == 1){
            this.setState({...this.state, message: seatUser, user:user});
        } else {
            message = "You have a " + [
                "Free Subscription Package",
                "Lite Subscription Package",
                "Lite Business Subscription Package",
                "Business Subscription Package",
                "Enterprise Subscription Package",
            ][ user.package_number ?? 0];
        }
        let expired = false;
        //if(user.package_number === 0 && !user.trial_expiry_flag){
        if(user.trial_expiry_flag){
            // show trial expire - popup
            let expiry_date = new Date(user.trial_expiry);
            let current_date = new Date();
            let t = expiry_date.getTime() - current_date.getTime();
            expired = t <= 0; 
        }
        let url = "https://api.ipify.org/?format=json";
        let response = await fetch(url);
        let json = await response.json();
        let ip = json['ip'];
        result = await API.pricing.getLocation("", ip);
        let country_code2 = result['location'];
        localStorage.setItem('country', country_code2 == "ZA" ? "ZA" : "US");
        let keys = [
            'za_solo_monthly',
            'za_solo_monthly_without_marketing',
            'za_solo_yearly',
            'za_solo_yearly_without_marketing',            
            'za_lite_monthly',
            'za_lite_monthly_without_marketing',
            'za_lite_yearly',
            'za_lite_yearly_without_marketing',
            'za_business_lite_monthly',
            'za_business_lite_monthly_without_marketing',
            'za_business_lite_yearly',
            'za_business_lite_yearly_without_marketing',            
            'za_business_pro_monthly',
            'za_business_pro_monthly_without_marketing',
            'za_business_pro_yearly',
            'za_business_pro_yearly_without_marketing', 
            
            'us_solo_monthly',
            'us_solo_monthly_without_marketing',
            'us_solo_yearly',
            'us_solo_yearly_without_marketing',            
            'us_lite_monthly',
            'us_lite_monthly_without_marketing',
            'us_lite_yearly',
            'us_lite_yearly_without_marketing',
            'us_business_lite_monthly',
            'us_business_lite_monthly_without_marketing',
            'us_business_lite_yearly',
            'us_business_lite_yearly_without_marketing',            
            'us_business_pro_monthly',
            'us_business_pro_monthly_without_marketing',
            'us_business_pro_yearly',
            'us_business_pro_yearly_without_marketing',            
        ];
        result = await API.pricing.getBulk( keys, 0);
        let pairs = result['pairs'];
        let pricing = [];
        pairs.forEach((pair)=>{
            pricing[pair.key] = parseInt(pair.value ?? "0") / 100; // convert from cents to whole value
        });
        this.setState({...this.state, pricing: pricing, message: message, user:user, expired:expired});
             
        // hide all other
        try {
            document.getElementById("package_tabs").style.display = "none";
            document.getElementById("package_tabs_tab_solo").style.display = "none";
            document.getElementById("package_tabs_tab_lite").style.display = "none";
            document.getElementById("package_tabs_tab_business_lite").style.display = "none";
            document.getElementById("package_tabs_tab_business_pro").style.display = "none";
        } catch(e){}
        
        // select solo.
        
        try {
            document.getElementById("package_tabs").style.display = "block";
            document.getElementById("package_tabs_tab_solo").style.display = "block";
            document.getElementById("package_tabs_tab_lite").style.display = "none";
            document.getElementById("package_tabs_tab_business_lite").style.display = "none";
            document.getElementById("package_tabs_tab_business_pro").style.display = "none";
            document.getElementById("package-tab-button-solo").classList.add('active');
            document.getElementById("package-tab-button-lite").classList.remove('active');
            document.getElementById("package-tab-button-business-lite").classList.remove('active');
            document.getElementById("package-tab-button-business-pro").classList.remove('active');
        } catch(e){}

        this.currency = (this.state.user.payment_gateway == 1 || this.state.user.payment_gateway == "1") ? "R" : "$";

        setTimeout(()=>{
            this.package_selected = 1;
            this.setPricing();    
        }, 1000);
    }
    

    getPackageName(package_number){
        return [
            "Free",
            "Lite",
            "Lite Business",
            "Business",
            "Enterprise",
        ][package_number];
    }

    getDurationName(package_duration){
        return [
            "a Montlhy",
            "an Annually",
        ][package_duration];
    }
    
    getPriceValue(user){
        let gatewaytype = user.payment_gateway, package_number = user.package_number, package_duration = user.package_duration;
        if(gatewaytype == "0" || gatewaytype == 0){
            if(package_duration == "0" || package_duration == 0){
                if(package_number == 0 || package_number == "0"){
                    return "$0.00" + " Once Off";
                } else if(package_number == 1 || package_number == "1"){
                    return "$150.00" + " Per Month";
                } else if(package_number == 2 || package_number == "2"){
                    return "$350.00" + " Per Month";
                } else if(package_number == 3 || package_number == "3"){
                    return "$500.00" + " Per Month";
                } else if(package_number == 4 || package_number == "4"){
                    return "$1000.00" + " Per Month";
                } else if(package_number == 0 || package_number == "0"){
                    return "$0.00" + " Per Month"; // Free Account
                }
            } else if(package_duration == "1" || package_duration == 1){                
                if(package_number == 0 || package_number == "0"){
                    return "$0.00" + " Once Off";
                } else if(package_number == 1 || package_number == "1"){
                    return "$1650.00" + " Per Year";
                } else if(package_number == 2 || package_number == "2"){
                    return "$3840.00" + " Per Year";
                } else if(package_number == 3 || package_number == "3"){
                    return "$5496.00" + " Per Year";
                } else if(package_number == 4 || package_number == "4"){
                    return "$10956.00" + " Per Year";
                } else if(package_number == 0 || package_number == "0") {
                    return "$0.00" + " Per Year"; // Free Account
                }
            }
        } else if(gatewaytype == "1" || gatewaytype == 1){
            if(package_duration == "0" || package_duration == 0){
                return "R" + user.real_monthly + " Per Month";
            } else if(package_duration == "1" || package_duration == 1){
                return "R" + user.real_yearly + " Per Year";
            }
        }
    }

    render() {
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Dashboard</span>
                        <span>{ this.state.message }</span>
                    </div>
                    <div className="blocked">
                            <Activities/>
                            <MyStorageComponent></MyStorageComponent>
                            { !this.state.user.seat_user && <ExtraSpaceComponent></ExtraSpaceComponent> }
                            <CustomWatermarkComponent></CustomWatermarkComponent>
                            <UsageTotalComponent></UsageTotalComponent>
                            <AutoManageComponent></AutoManageComponent>
                            <EmailSignatureComponent user={this.state.user}></EmailSignatureComponent>
                    </div>
                </div>
            </div>
            <TutorialComponent page_number="0"></TutorialComponent>
            <PayFlowComponent ref={this.payflowRef}></PayFlowComponent>
            {   
                this.state.expired && <div className="dialogNewCreate">
                    <div style={{minWidth:'720px', padding:'16px'}} className="package_selection">
                        <h4>Your Trial has ended</h4>
                        <span>you will need to purchase a subscription in order to continue</span>
                        <div className="package_tab_buttons">
                            <div id="package-tab-button-solo" onClick={()=>{
                                document.getElementById("package_tabs").style.display = "block";
                                document.getElementById("package_tabs_tab_solo").style.display = "block";
                                document.getElementById("package_tabs_tab_lite").style.display = "none";
                                document.getElementById("package_tabs_tab_business_lite").style.display = "none";
                                document.getElementById("package_tabs_tab_business_pro").style.display = "none";
                                document.getElementById("package-tab-button-solo").classList.add('active');
                                document.getElementById("package-tab-button-lite").classList.remove('active');
                                document.getElementById("package-tab-button-business-lite").classList.remove('active');
                                document.getElementById("package-tab-button-business-pro").classList.remove('active');
                                this.package_selected = 1;
                                this.setPricing();
                            }}>Solo</div>
                            <div id="package-tab-button-lite" onClick={()=>{
                                document.getElementById("package_tabs").style.display = "block";
                                document.getElementById("package_tabs_tab_solo").style.display = "none";
                                document.getElementById("package_tabs_tab_lite").style.display = "block";
                                document.getElementById("package_tabs_tab_business_lite").style.display = "none";
                                document.getElementById("package_tabs_tab_business_pro").style.display = "none";
                                document.getElementById("package-tab-button-solo").classList.remove('active');
                                document.getElementById("package-tab-button-lite").classList.add('active');
                                document.getElementById("package-tab-button-business-lite").classList.remove('active');
                                document.getElementById("package-tab-button-business-pro").classList.remove('active');
                                this.package_selected = 2;
                                this.setPricing();
                            }}>Lite</div>
                            <div id="package-tab-button-business-lite" onClick={()=>{
                                document.getElementById("package_tabs").style.display = "block";
                                document.getElementById("package_tabs_tab_solo").style.display = "none";
                                document.getElementById("package_tabs_tab_lite").style.display = "none";
                                document.getElementById("package_tabs_tab_business_lite").style.display = "block";
                                document.getElementById("package_tabs_tab_business_pro").style.display = "none";
                                document.getElementById("package-tab-button-solo").classList.remove('active');
                                document.getElementById("package-tab-button-lite").classList.remove('active');
                                document.getElementById("package-tab-button-business-lite").classList.add('active');
                                document.getElementById("package-tab-button-business-pro").classList.remove('active');
                                this.package_selected = 3;
                                this.setPricing();
                            }}>Business Lite</div>
                            <div id="package-tab-button-business-pro" onClick={()=>{
                                document.getElementById("package_tabs").style.display = "block";
                                document.getElementById("package_tabs_tab_solo").style.display = "none";
                                document.getElementById("package_tabs_tab_lite").style.display = "none";
                                document.getElementById("package_tabs_tab_business_lite").style.display = "none";
                                document.getElementById("package_tabs_tab_business_pro").style.display = "block";
                                document.getElementById("package-tab-button-solo").classList.remove('active');
                                document.getElementById("package-tab-button-lite").classList.remove('active');
                                document.getElementById("package-tab-button-business-lite").classList.remove('active');
                                document.getElementById("package-tab-button-business-pro").classList.add('active');
                                this.package_selected = 4;
                                this.setPricing();
                            }}>Business Pro</div>
                        </div>
                        <div className="package_tabs" id="package_tabs">
                            <div id="package_tabs_tab_solo">
                                <h4>Solo package -  1 User</h4><span>&nbsp;{ this.state.soloPriceMonthly }</span>
                                <p><strong>Storage:</strong>&nbsp;10 Gig (10 - 15 hours)</p>
                                <span><strong>Features</strong></span>
                                <ul>
                                    <li>* Unlimited: Vacancies Interview Share</li>
                                    <li>Video Interview</li>
                                    <li>Candidate out-reach</li>
                                    <li>Vlog</li>
                                    <li>Interview-Sharing</li>
                                    <li>Marketing</li>
                                    <li>Email video</li>
                                    <li>Video to email</li>
                                    <li>Info videos</li>
                                    <li>Collaboration</li>
                                </ul>
                            </div>
                            <div id="package_tabs_tab_lite">
                                <h4>Lite package - 2 Users</h4><span>&nbsp;{ this.state.litePriceMonthly }</span>
                                <p><strong>Storage:</strong>&nbsp;20 Gig (20 - 30 hours)</p>
                                <span><strong>Features</strong></span>
                                <ul>
                                    <li>* Unlimited: Vacancies Interview Share</li>
                                    <li>Video Interview</li>
                                    <li>Candidate out-reach</li>
                                    <li>Vlog</li>
                                    <li>Interview-Sharing</li>
                                    <li>Marketing</li>
                                    <li>Email video</li>
                                    <li>Video to email</li>
                                    <li>Info videos</li>
                                    <li>Collaboration</li>
                                </ul>
                            </div>
                            <div id="package_tabs_tab_business_lite">
                                <h4>Business Lite package - 1 to 5 Users</h4><span>&nbsp;{ this.state.liteBusinessPriceMonthly }</span>
                                <p><strong>Storage:</strong>&nbsp;50 Gig (50 - 75 hours)</p>
                                <span><strong>Features</strong></span>
                                <ul>
                                    <li>* Unlimited: Vacancies Interview Share</li>
                                    <li>Video Interview</li>
                                    <li>Candidate out-reach</li>
                                    <li>Vlog</li>
                                    <li>Interview-Sharing</li>
                                    <li>Marketing</li>
                                    <li>Email video</li>
                                    <li>Video to email</li>
                                    <li>Info videos</li>
                                    <li>Collaboration</li>
                                </ul>
                            </div>
                            <div id="package_tabs_tab_business_pro">
                                <h4>Business Pro package - 1 to 10 Users</h4><span>&nbsp;{ this.state.businessPriceMonthly }</span>
                                <p><strong>Storage:</strong>&nbsp;100 Gig (100 - 150 hours)</p>
                                <span><strong>Features</strong></span>
                                <ul>
                                    <li>* Unlimited: Vacancies Interview Share</li>
                                    <li>Video Interview</li>
                                    <li>Candidate out-reach</li>
                                    <li>Vlog</li>
                                    <li>Interview-Sharing</li>
                                    <li>Marketing</li>
                                    <li>Email video</li>
                                    <li>Video to email</li>
                                    <li>Info videos</li>
                                    <li>Collaboration</li>
                                </ul>
                            </div>
                            <span style={{fontSize:'10pt',color:'red', textAlign:'left', width:'100%', display:'block'}}>* Unlimited use within provided storage space, Pricing excludes Taxes</span>
                        </div>
                        <div>
                            <h4 style={{fontSize:'10pt', height:'12px'}}>Select subscription duration</h4>
                            <select name="" ref={this.subscription_duration_selector} id="subscription_duration_selector" defaultValue={this.package_duration.current} onChange={(e)=>{
                                this.package_duration.current = parseInt(e.target.value);                                
                                this.setPricing();
                            }}>
                                <option value="0">Monthly</option>
                                <option value="1">Yearly</option>
                            </select>
                        </div>
                        <div>
                            <h4 style={{fontSize:'10pt', height:'12px'}}>Include Marketing Package</h4>
                            <select name="" ref={this.marketing_selectorref} id="marketing_selector" defaultValue={this.hasMarketing} onChange={(e)=>{
                                this.hasMarketing = parseInt(e.target.value);
                                this.setPricing();
                            }}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div className="vat_totals">
                            <span><strong style={{width:'100px', display:"inline-block"}}>Sub Total: </strong><span ref={this.subtotalref}></span></span>
                            <span><strong style={{width:'100px', display:"inline-block"}}>VAT: </strong><span ref={this.vatref}></span></span>
                            <span><strong style={{width:'100px', display:"inline-block"}}>Total: </strong><span ref={this.totalwithvat}></span></span>
                        </div>
                        <div className="pruchase_subscription_button_container">
                            <div className="pruchase_subscription_button" onClick={async ()=>{
                                this.setState({...this.state, loading: true});
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.user.onTrialEnd(api_key, this.package_selected, this.package_duration.current, this.hasMarketing);
                                if(result['success']){
                                    window.location.href = "/dashboard";
                                } else {                                    
                                    this.setState({...this.state, loading: false});
                                    toast("An error happened, please contact clipdrop tech support");
                                }
                            }}>Purchase Subscription</div>
                        </div>
                        <form ref={this.paygateProcessFormPayRequestFormRef} action="https://secure.paygate.co.za/payweb3/process.trans" method="POST" >
                            <input type="hidden" name="PAY_REQUEST_ID" ref={this.paygateProcessFormPayRequestIDRef} value=""/>
                            <input type="hidden" name="CHECKSUM" ref={this.paygateProcessFormChecksumRef} value=""/>
                        </form>
                        <div className="pay-logos" style={{transform:'scale(0.65)'}}>
                            <img src={MasterCard} alt="" />
                            <img src={MasterCard2} alt="" />
                            <img src={Visa} alt="" />
                            <img src={Visa2} alt="" />
                            <img src={PayGate} alt="" />
                        </div>
                        <a className="termsandcondidationnewlink" href="https://api.clipdrop.io/terms_and_conditions.pdf" target="_blank" style={{fontSize:'10pt', paddingTop:'16px'}}>Terms and Conditions</a>
                    </div>
                </div>
            }
                <div className="dialogNewCreate" id="storage-notice" style={{display:'none'}}>
                    <div style={{minWidth:'720px'}}>
                        <h4>Your are running out of storage space</h4>
                        <p>You have a less than 1GB of storage left</p>
                        <div>
                            <button onClick={()=>{        
                                document.getElementById("storage-notice").style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>                
                <div className="dialogNewCreate" id="storage-notice-no-space" style={{display:'none'}}>
                    <div style={{minWidth:'720px'}}>
                        <h4>You have run out of storage space</h4>
                        <div>
                            <button onClick={()=>{
                                    document.getElementById("storage-notice-no-space").style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                { 
                    this.state.loading ? (
                        <div className="loader">                        
                            <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                        </div>
                    ) : ""
                }
            </>
        );
      }
}