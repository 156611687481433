import React, { createRef } from "react";

import API from './../services/api';

import { AiOutlineCloudUpload } from 'react-icons/ai';

import { BsPlayFill } from 'react-icons/bs';

import "./marketing.css";

export default class MarketingComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          user: props.user,
          interview: props.interview
      };
    }


    render(){
        // HTML
        return ( 
            <>
              { this.state.interview != null ?
              <>
              <div className="m-container">
                <h4>Bespoke OutReach</h4>
                <div>
                  </div>
                  <div>
                      <ul className="clean">
                        <li><strong>Leads:</strong> {this.state.interview.leads.bespoke}</li>
                        <li><strong>Open Rate:</strong> {this.state.interview.open_rate.bespoke}</li>
                      </ul>
                  </div>
              </div>
              <div className="m-container">
                <h4>OutReach Camapign</h4>
                <div>
                  <video src=""></video>
                  <div className="controls">
                      <button><BsPlayFill fill="#1976D1" fontSize="22pt"/></button>
                      <button><AiOutlineCloudUpload fill="#1976D1" fontSize="22pt"/></button>
                  </div>
                </div>
                <div>
                      <ul className="clean">
                        <li><strong>Leads:</strong> {this.state.interview.leads.outreach}</li>
                        <li><strong>Open Rate:</strong> {this.state.interview.open_rate.outreach}</li>
                      </ul>              
                </div>
              </div>
              <div className="m-container">
                <h4>Social Post</h4>
                <div>
                  <video src=""></video>
                  <div className="controls">
                      <button><BsPlayFill fill="#1976D1" fontSize="22pt"/></button>
                      <button><AiOutlineCloudUpload fill="#1976D1" fontSize="22pt"/></button>
                  </div>
                </div>
                <div>
                      <ul className="clean">
                        <li><strong>Open Rate:</strong> {this.state.interview.open_rate.social}</li>
                      </ul>         
                </div>
              </div>
              </>
              : <></> }
            </>
        );
      }
}