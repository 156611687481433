import React, { useEffect, useState, useRef } from "react";

export default function VideoRecordComponent2(props) {
  const {
    interviewOrMarketing,
    selectedFolder,
    selectedID,
    watermarkURL,
    onClose,
    onNext,
  } = props;

  const [hasCamera, setHasCamera] = useState(false);
  const [isOutDated, setIsOutDated] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const wsConnectionString = "wss://sapi7.clipdrop.io:8089";
  const streamingOptions = {
    audio: true,
    video: {
      facingMode: "user",
      width: { exact: 640 },
      height: { exact: 480 },
    },
  };
  const [streamMimeType, setStreamMimeType] = useState("video/webm;codecs=vp9");

  const recordVideoElementRef = useRef(null);
  const countDownElementRef = useRef(null);

  const startRecordBtnRef = useRef(null);
  const restartRecordBtnRef = useRef(null);
  const stopAndSaveRecordingBtnRef = useRef(null);
  const closeBtnRef = useRef(null);

  const videoStreamRef = useRef(null);
  const streamMimeTypeRef = useRef(null);
  const webSocketConnection = useRef(null);
  const recorderRef = useRef(null);
  const stopRecordingRef = useRef(null);

  useEffect(() => {
    streamMimeTypeRef.current = streamMimeType;
  }, [streamMimeType]);

  const detectWebcam = async () => {
    try {
      var md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) return false;
      const devices = await md.enumerateDevices();
      if (devices.length == 0) return false;
      return devices.some((elm) => {
        if (elm.kind === "videoinput") return elm;
      });
    } catch (error) {
      return false;
    }
  };

  const sayswho = () => {
    var ua = navigator.userAgent;
    var tem;
    var M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return "IE " + (tem[1] || "");
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(" ");
  };
  const version = () => {
    return sayswho().indexOf("Safari") != -1 ||
      sayswho().indexOf("safari") != -1
      ? parseInt(sayswho().split(" ")[1])
      : false;
  };

  const handleMessage = (event) => {
    // TODO: COMPLETE EVENT HANDLING HERE
    let serverResponseJsonObject = JSON.parse(event.data);
    let serverState = serverResponseJsonObject["status"];
    if (serverState === 2) {
      closeStreamingHandler();
      setIsProcessing(true);
      setTimeout(() => {
        onNext();
      }, 2500);
    }
  };

  const wait = (seconds) => {
    return new Promise((resolve, reject) => {
      let ms = seconds * 1000;
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };

  const closeStreamingHandler = () => {
    if (videoStreamRef.current) {
      let tracks = videoStreamRef.current.getTracks();
      tracks.forEach(function (track) {
        try {
          track.stop();
        } catch (e) {
          // Handle Error
        }
      });
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const handleClosed = () => {
    closeStreamingHandler();
    onClose();
  };

  const handleRecorderData = async (e) => {
    let payload = e.data;
    if (payload && payload.size > 0) {
      await blobToBase64(payload).then((res) => {
        let json_data = JSON.stringify({
          status: 1,
          encoded_data: res.split("base64,")[1],
          timestamp: new Date().getTime(),
        });
        if (stopRecordingRef.current === false) {
          webSocketConnection.current.send(json_data);
        }
      });
    }
  };
  async function handleInititaion() {
    let hasWebCamera = await detectWebcam();
    setHasCamera(hasWebCamera);
    if (hasWebCamera) {
      navigator.getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia ||
        navigator.oGetUserMedia;
      videoStreamRef.current = await navigator.mediaDevices.getUserMedia(
        streamingOptions
      );
      if (recordVideoElementRef.current) {
        recordVideoElementRef.current.setAttribute("autoplay", true);
        recordVideoElementRef.current.setAttribute("mute", true);
        recordVideoElementRef.current.setAttribute("playsinline", false);
        if ("srcObject" in recordVideoElementRef.current) {
          recordVideoElementRef.current.srcObject = videoStreamRef.current;
        } else {
          recordVideoElementRef.current.src = window.URL
            ? window.URL.createObjectURL(videoStreamRef.current)
            : videoStreamRef.current;
        }
      }
    }
    let s = sayswho();
    let v = version();
    let isIOS = v != false;
    if (isIOS && v != false) {
      if (v <= 13) {
        setIsOutDated(true);
        return;
      }
      setStreamMimeType("video/mp4");
    }
  }
  useEffect(() => {
    handleInititaion();
  }, []);

  const connection = async (socket, timeout = 10000) => {
    if (!socket) return false;

    const isOpened = () => socket?.readyState === WebSocket.OPEN;

    if (socket?.readyState !== WebSocket.CONNECTING) {
      return isOpened();
    } else {
      const intrasleep = 100;
      const ttl = timeout / intrasleep; // time to loop
      let loop = 0;
      while (socket?.readyState === WebSocket.CONNECTING && loop < ttl) {
        await new Promise((resolve) => setTimeout(resolve, intrasleep));
        loop++;
      }
      return isOpened();
    }
  };

  if (!hasCamera) {
    return (
      <div className="videoRecordpopUp" style={{ transform: "scale(0.9)" }}>
        <div>
          <span>No Camera Detected</span>
          <br />
          <br />
          <span>please check your camera and reload this web page</span>
          <br />
          <br />
          <button
            className="blueBTN"
            style={{ width: "100%" }}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload Page
          </button>
          <button
            className="blueBTN"
            style={{ width: "100%" }}
            onClick={() => {
              handleClosed();
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (isOutDated) {
    return (
      <div className="videoRecordpopUp" style={{ transform: "scale(0.9)" }}>
        <div>
          <h4>Out-Dated Safari Browser Version</h4>
          <span>You currently have Safari version {this.version()}</span>
          <span>Version 14 or later is required, please upgrade</span>
          <span>Also MediaRecord Feature should be enabled</span>
          <a
            href="https://support.apple.com/en-za/HT204416"
            className="specialLink"
            target="_blank"
          >
            Official Apple Safari Upgrade Guide
          </a>
          <a
            href="https://www.educative.io/edpresso/how-to-enable-the-mediarecorder-api-for-safari"
            className="specialLink"
            target="_blank"
          >
            UnOfficial Apple Safari MediaRecorder Guide
          </a>
          <button
            className="blueBTN"
            style={{ width: "100%" }}
            onClick={() => {
              handleClosed();
            }}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (!isProcessing) {
    return (
      <div
        className="videoRecordpopUp"
        style={{
          transform: "scale(0.9) translateX(-16px)",
          width: "480px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            textAlign: "center",
            width: "432px",
            transform: "scale(0.9) translateX(-4px)",
          }}
        >
          <h4 style={{ transform: "translateX(16px)" }}>Record a video</h4>
          <div
            style={{
              textAlign: "center",
              width: "432px",
              padding: "none",
              transform: "none",
            }}
          >
            <video
              src={videoStreamRef.current}
              ref={recordVideoElementRef}
              controls={false}
              autoplay={true}
              muted={true}
              playsinline={true}
            ></video>
            <div className="videoRecordpopUpCounter" ref={countDownElementRef}>
              3
            </div>
          </div>
          <div
            className="rightAlign"
            style={{
              textAlign: "center",
              width: "480px",
              padding: "none",
              transform: "translateX(-2px)",
            }}
          >
            {!isRecording && (
              <button
                onClick={async () => {
                  startRecordBtnRef.current.style.display = "none";
                  let s = sayswho();
                  let v = version();
                  let isIOS = v != false;
                  if (isIOS && v != false) {
                    setStreamMimeType("video/mp4");
                  }
                  let setupData = {
                    status: 0,
                    interview_or_marketing: interviewOrMarketing,
                    selected_folder: selectedFolder,
                    selected_id: selectedID,
                    video_extension: "mp4",
                    include_watermark: true,
                    watermark_type: "custom",
                    watermark_image_url: watermarkURL,
                    format: isIOS ? "mp4" : "webm",
                    client_debug_mode: false,
                  };
                  webSocketConnection.current = new WebSocket(
                    wsConnectionString
                  );
                  webSocketConnection.current.onmessage = handleMessage;
                  let opened = await connection(
                    webSocketConnection.current,
                    5000
                  );
                  let lcount = 0;
                  while (!opened && lcount < 12) {
                    webSocketConnection.current = new WebSocket(
                      wsConnectionString
                    );
                    webSocketConnection.current.onmessage = handleMessage;
                    opened = await connection(
                      webSocketConnection.current,
                      5000
                    );
                    lcount += 1;
                  }
                  webSocketConnection.current.send(JSON.stringify(setupData));
                  countDownElementRef.current.style.display = "block";
                  countDownElementRef.current.innerHTML = "3";
                  await wait(1);
                  countDownElementRef.current.innerHTML = "2";
                  await wait(1);
                  countDownElementRef.current.innerHTML = "1";
                  await wait(1);
                  countDownElementRef.current.innerHTML = "3";
                  countDownElementRef.current.style.display = "none";
                  setIsRecording(true);
                  if (recorderRef.current) {
                    try {
                      recorderRef.current.stop();
                    } catch (e1) {}
                  }
                  recorderRef.current = new MediaRecorder(
                    videoStreamRef.current,
                    {
                      mimeType: streamMimeTypeRef.current,
                    }
                  );
                  recorderRef.current.ondataavailable = handleRecorderData;
                  recorderRef.current.start(isIOS ? 0 : 1000);
                  stopRecordingRef.current = false;
                }}
                ref={startRecordBtnRef}
                style={{ marginTop: "8px", width: "calc(100% - 32px)" }}
              >
                Start Recording
              </button>
            )}
            {isRecording && (
              <button
                onClick={async () => {
                  restartRecordBtnRef.current.style.display = "none";
                  closeStreamingHandler();
                  await handleInititaion();
                  let s = sayswho();
                  let v = version();
                  let isIOS = v != false;
                  if (isIOS && v != false) {
                    setStreamMimeType("video/mp4");
                  }
                  let setupData = {
                    status: 0,
                    interview_or_marketing: interviewOrMarketing,
                    selected_folder: selectedFolder,
                    selected_id: selectedID,
                    video_extension: "mp4",
                    include_watermark: true,
                    watermark_type: "custom",
                    watermark_image_url: watermarkURL,
                    format: isIOS ? "mp4" : "webm",
                    client_debug_mode: false,
                  };
                  webSocketConnection.current = new WebSocket(
                    wsConnectionString
                  );
                  webSocketConnection.current.onmessage = handleMessage;
                  let opened = await connection(
                    webSocketConnection.current,
                    5000
                  );
                  let lcount = 0;
                  while (!opened && lcount < 12) {
                    webSocketConnection.current = new WebSocket(
                      wsConnectionString
                    );
                    webSocketConnection.current.onmessage = handleMessage;
                    opened = await connection(
                      webSocketConnection.current,
                      5000
                    );
                    lcount += 1;
                  }
                  webSocketConnection.current.send(JSON.stringify(setupData));
                  countDownElementRef.current.style.display = "block";
                  countDownElementRef.current.innerHTML = "3";
                  await wait(1);
                  countDownElementRef.current.innerHTML = "2";
                  await wait(1);
                  countDownElementRef.current.innerHTML = "1";
                  await wait(1);
                  countDownElementRef.current.innerHTML = "3";
                  countDownElementRef.current.style.display = "none";
                  setIsRecording(true);
                  if (recorderRef.current) {
                    try {
                      recorderRef.current.stop();
                    } catch (e1) {}
                  }
                  recorderRef.current = new MediaRecorder(
                    videoStreamRef.current,
                    {
                      mimeType: streamMimeTypeRef.current,
                    }
                  );
                  recorderRef.current.ondataavailable = handleRecorderData;
                  recorderRef.current.start(isIOS ? 0 : 1000);
                  stopRecordingRef.current = false;
                  restartRecordBtnRef.current.style.display = "initial";
                }}
                ref={restartRecordBtnRef}
                style={{ marginTop: "8px", width: "calc(100% - 32px)" }}
              >
                Restart Recording
              </button>
            )}
            {isRecording && (
              <button
                onClick={async () => {
                  restartRecordBtnRef.current.style.display = "none";
                  stopAndSaveRecordingBtnRef.current.style.display = "none";
                  // Handle Stopping
                  await wait(1.5);
                  stopRecordingRef.current = true;
                  webSocketConnection.current.send(
                    JSON.stringify({ status: 2 })
                  );
                  if (recorderRef.current) {
                    try {
                      recorderRef.current.stop();
                    } catch (e1) {}
                  }
                }}
                ref={stopAndSaveRecordingBtnRef}
                style={{ marginTop: "8px", width: "calc(100% - 32px)" }}
              >
                Stop and Save Recording
              </button>
            )}
            <button
              style={{ marginTop: "8px", width: "calc(100% - 32px)" }}
              onClick={() => {
                handleClosed();
              }}
              ref={closeBtnRef}
              className="close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  } else if (isProcessing) {
    return (
      <div className="videoRecordpopUp" style={{ transform: "scale(0.9)" }}>
        <div style={{ textAlign: "center", width: "432px" }}>
          <span
            style={{
              "padding-top": "48px",
              "font-family": "Poppins, Helvetica, sans-serif",
            }}
          >
            This video will be proccessed in the background
          </span>{" "}
          :
        </div>
      </div>
    );
  }
}
