import React, { createRef } from "react";
import { ToastContainer, toast } from 'react-toastify';

import API from './../services/api';

import "./videoupload.css";

class VideoUploadComponent extends React.Component {
    constructor(props) {
      super(props);

      this.onreject = props.onreject;
      this.onclose = props.onclose;
      this.onaccept = props.onaccept;
      this.watermark_url = props.watermark_url;

      this.intervalTracker = 0;
      this.intervalIndex = 0;
      this.interval = null;

      this.popupRef = createRef();
      
      this.step1ref = createRef();
      this.step2ref = createRef();
      this.step3ref = createRef();

      this.fileInputRef = createRef();

      this.videoPreviewRef = createRef();

      this.progressRef = createRef();

      this.fileSizeRef = createRef();

      this.resetUI = this.resetUI.bind(this);
      
      this.initUpload = this.initUpload.bind(this);
      this.getFileData = this.getFileData.bind(this);
      this.doUpload = this.doUpload.bind(this);

      this.getSizeFromBytes = this.getSizeFromBytes.bind(this);

      // states
      this.data = [];
    }

    async componentDidMount(){
        this.resetUI();
    }

    
    
    resetUI(){
        this.popupRef.current.style.display = "block";
        this.step1ref.current.style.display = "block";
        this.step2ref.current.style.display = "none";
        this.step3ref.current.style.display = "none";
    }

    wait = (seconds)=>{
        return new Promise((resolve, reject)=>{
            let ms = seconds * 1000;
            setTimeout(()=>{
                resolve();
            }, ms);
        });
    }
    
    async initUpload(){
        let files = this.fileInputRef.current.files;
        if(files.length == 0){
            toast("Please select a file");
            return;
        }
        if(files.length > 1){
            toast("Please pnly select 1 file");
            return;
        }

        this.step1ref.current.style.display = "none";
        this.step2ref.current.style.display = "block";

        let file = files[0];
        this.fileSizeRef.current.innerHTML = this.getSizeFromBytes(file.size);
        /*let file_size = file.size;
        let ratio = (file_size / (navigator.connection.downlink * 1024 * 1024)) / 3;
        this.progressRef.current.value = 0;
        this.interval = setInterval(()=>{
            this.progressRef.current.value += ratio;
            if(this.progressRef.current.value >= 100){
                clearInterval(this.interval);
            }
        }, 4000)*/
        this.doUpload(file);        
    }

    getSizeFromBytes(Num=0, dec=2){
        if (Num<1000) return Num+" Bytes";
        Num =("0".repeat((Num+="").length*2%3)+Num).match(/.{3}/g);
        return Number(Num[0])+"."+Num[1].substring(0,dec)+" "+"  kMGTPEZY"[Num.length]+"B";
    }

    async doUpload(file){
        //let data  = await this.getFileData(file);
        let api_key = localStorage.getItem('api_key');
        //let filedata = data.split('base64,')[1];
        let result1 = await API.file.uploadFileAsFileWithProgress(api_key, file.name, file, (p) => {
            this.progressRef.current.value = p;
        });
        let uploadedFileName = result1;
        clearInterval(this.interval);
        this.step2ref.current.style.display = "none";
        this.step3ref.current.style.display = "block";
        this.videoPreviewRef.current.src = uploadedFileName;
    }

    async getFileData(file){
        return new Promise((resolve)=>{
            let reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.readAsDataURL(file);
        });
    }

    render() {
        return (
            <>
                <div className="xpopUp" ref={this.popupRef}>
                    <div ref={this.step1ref}>
                        <span>Select a video file to use</span><br/><br/>
                        <input ref={this.fileInputRef} type="file" accept="video/mp4"/><br/><br/>
                        <button className="blueBTN" style={{width:'100%'}} onClick={this.initUpload}>Upload</button><br/><br/>
                        <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                            this.popupRef.current.style.display = "none";
                            this.step1ref.current.style.display = "none";
                            this.step2ref.current.style.display = "none";
                            this.step3ref.current.style.display = "none";
                            this.onclose();
                        }}>Cancel</button>
                    </div>
                    <div ref={this.step2ref}>
                        <span style={{fontWeight:'bold'}}>File is uploading</span><br/><br/> 
                        <span>Please wait this may take a few minutes as the file size is <span style={{fontWeight:'bold'}} ref={this.fileSizeRef}></span></span><br/><br/>     
                        <progress style={{width:'100%', height:'64px'}} ref={this.progressRef} value="0" max="100"> 32% </progress>               
                    </div>
                    <div ref={this.step3ref}>
                        <span>Video Preview</span><br/><br/>
                        <video  controls autoplay ref={this.videoPreviewRef} style={{width:'100%', maxHeight:'340px'}}></video>
                        <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                            this.popupRef.current.style.display = "none";
                            this.step1ref.current.style.display = "none";
                            this.step2ref.current.style.display = "none";
                            this.step3ref.current.style.display = "none";
                            if(this.videoPreviewRef.current){
                                try {
                                    this.videoPreviewRef.current.pause();
                                } catch(e){
                                    
                                }
                            }
                            this.onaccept(this.videoPreviewRef.current.src);
                        }}>Accept</button><br/><br/>
                        <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                            this.popupRef.current.style.display = "none";
                            this.step1ref.current.style.display = "none";
                            this.step2ref.current.style.display = "none";
                            this.step3ref.current.style.display = "none";
                            if(this.videoPreviewRef.current){
                                try {
                                    this.videoPreviewRef.current.pause();
                                } catch(e){
                                    
                                }
                            }
                            this.onreject();
                        }}>Reject</button>
                    </div>
                </div>
            </>
        );
    }
}

export default VideoUploadComponent;