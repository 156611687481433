import React, { createRef } from "react";

import API from './../services/api';
import UTILS from './../services/utils';

import fuzzytime from 'fuzzy-time';

import MasterCard from './../media/logos/mastercard.png';
import MasterCard2 from './../media/logos/mastercard2.png';
import Visa from './../media/logos/visa.png';
import Visa2 from './../media/logos/visa2.png';
import PayGate from './../media/logos/paygate.png';


import { toast, ToastContainer } from "react-toastify";

import { AiOutlineLoading } from 'react-icons/ai';

import "./payflow.css";

export default class PayFlowComponent extends React.Component {
    constructor(props) {
      super(props);      
      this.state = {
            loading: true,
            needsToPay: false,
            user: props.user,
            message: "Please Wait while we verify your subscription."
      };

      this.paygateDialogAnnuallyRef = createRef();

      this.IDTypeRef = createRef();
      this.PromoCodeRef = createRef();
      this.SAIDNoRef=createRef();
      this.NameRef = createRef();
      this.CompanyNameRef = createRef();
      this.VatNumberRef = createRef();
      this.PhoneNumberRef=createRef();
      this.TelPhoneNumberRef=createRef();
      this.EmailAddressRef=createRef();
      this.BankCodeRef=createRef();
      this.BranchCodeRef=createRef();
      this.AccountTypeRef=createRef();
      this.AccountNumberRef=createRef();
      this.AccountHolderNameRef=createRef();

      
      this.paygateProcessFormPayRequestFormRef=createRef();
      this.paygateProcessFormPayRequestIDRef=createRef();
      this.paygateProcessFormChecksumRef=createRef();

      this.payWithPayGate = this.payWithPayGate.bind(this);
      this.payWithPaddle = this.payWithPaddle.bind(this);

      this.hidePaymentFlow = this.hidePaymentFlow.bind(this);
      
      this.updateDimensions.bind(this);
    }

    async componentDidMount(){
        this.paygateDialogAnnuallyRef.current.style.display = "none"; 
        // UI Fix for big dialogs, shrink to fit screen height
        let elm = document.getElementById("resizeThis");
        elm.style.transform = "scale(" + (window.innerHeight / 929 % 929 * 0.65) + ")";
        window.addEventListener('resize', this.updateDimensions);   

        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];

        result = await API.payflow.needsToPay(api_key);
        let success = result['success'];
        if(success !== true){
            window.location.href = "/login";
            return;
        }
        let needsToPay = result['needsToPay'] && !(user['isTester'] || user['isAdmin']);
        this.setState({
            ...this.state,
            loading: false,
            needsToPay: needsToPay,
            user: user            
        });        
    }

    updateDimensions(){
        let elm = document.getElementById("resizeThis");
        elm.style.transform = "scale(" + (window.innerHeight / 929 % 929 * 0.65) + ")";
    }

    hidePaymentFlow(){
        this.paygateDialogAnnuallyRef.current.style.display = "none";          
    }
    
    async payWithPayGate(){
        return;       
       this.setState({
           ...this.state,
           message: "Transfering you to Payment Portal",
           loading: true,
           needsToPay: false
       });
       let api_key = localStorage.getItem('api_key');
       let result = await API.subscription.paygate.purchase(api_key);
       if(result['success']){    
        this.paygateProcessFormPayRequestIDRef.current.value = result['PAY_REQUEST_ID'];
        this.paygateProcessFormChecksumRef.current.value = result['CHECKSUM'];
        this.paygateProcessFormPayRequestFormRef.current.submit();
       } else {
           alert(result['message']);
       }
    }

    

    getPackageName(package_number){
        return [
            "Free",
            "Solo",
            "Lite",
            "Business Lite",
            "Business Pro",
            "Enterprise",
        ][package_number];
    }

    getDurationName(package_duration){
        return [
            "a Montlhy",
            "an Annually",
        ][package_duration];
    }    

    async payWithPaddle(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.subscription.paddle.purchase(api_key);
        if(!result['success']){
            toast("Error, Generating Payment Link");
            return;
        }
        window.location.href = result['link'];
    }

    render() {
        // HTML
        return ( 
            <>
            {/*<form ref={this.paygateProcessFormPayRequestFormRef} action="https://secure.paygate.co.za/payweb3/process.trans" method="POST" >
                <input type="hidden" name="PAY_REQUEST_ID" ref={this.paygateProcessFormPayRequestIDRef} value=""/>
                <input type="hidden" name="CHECKSUM" ref={this.paygateProcessFormChecksumRef} value=""/>
            </form>*/}
            { this.state.needsToPay ? (
                <div className="dialogNewCreate">
                    <div style={{minWidth:'720px'}}>
                        <h4>Your Subscription has either Ended, been cancelled or a payment has been rejected</h4>
                        <p>You have a {this.getPackageName(this.state.user.package_number)} subscription with {this.getDurationName(this.state.user.package_duration)} Cost of { (this.state.user.payment_gateway == 1 || this.state.user.payment_gateway == "1") ? "R" : "$"}{this.state.user.price.toFixed(2)}</p>
                        <div>
                            { this.state.user.payment_gateway == 0 ?<button onClick={this.payWithPaddle}>Pay with Paddle (International)</button> : <></> }
                            {/*this.state.user.payment_gateway == 1 ? <button onClick={this.payWithPayGate}>Pay with PayGate (South Africa)</button> : <></> */}
                        </div>
                        <div className="pay-logos">
                            <img src={MasterCard} alt="" />
                            <img src={MasterCard2} alt="" />
                            <img src={Visa} alt="" />
                            <img src={Visa2} alt="" />
                            <img src={PayGate} alt="" />
                        </div><br/>
                        <a className="specialButtonSmall" href="https://api.clipdrop.io/terms_and_conditions.pdf" target="_blank">Terms and Conditions</a>
                    </div>
                </div>
                ) : <></>            
            }
            <div className="dialogNewCreate" ref={this.paygateDialogAnnuallyRef}>
                <div id="resizeThis" style={{transform:'scale(0.65)', minWidth:'720px'}}>
                    <h4>Pay once a off annual subscription</h4>
                    <button className="specialButtonSmall" onClick={this.hidePaymentFlow}>Cancel</button>
                </div>
            </div>
            { 
                this.state.loading ? (
                    <div className="loader">                        
                        <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>{ this.state.message }</span> <AiOutlineLoading className="loading-spinner"/></div>
                    </div>
                ) : ""
            }
            </>
        );
      }
}