import React from "react";

import API from "./../services/api";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { createRef } from 'react';

export default class Register3Page extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          errorMessage: "",
        };
      // bind
      this.handleRegister4 = this.handleRegister4.bind(this);
      this.handleResend = this.handleResend.bind(this);

      this.codeRef = createRef();
    }

    // handle login
    async handleRegister4(e){
        toast("Loading");
        let code = this.codeRef.current.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.registerUserStep4(api_key, code);
        if(result['success']){
            localStorage.setItem('code', code);
            window.location.href = '/dashboard';
        } else {
            toast("Error");
        }
    }

    async handleResend(e){
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.resendConfirmationCode(api_key);
        toast("Code Resent");
    }

    render() {
        // HTML
        return (
            <>
            <div className="outer">
                <div className="middle">
                    <div className="container" style={{'width':'500px'}}>
                        <div className="loginFormLogo"></div>
                        <div className="form">
                            <div className="info">
                                <span>You will receive an email with your OTP (One Time Password).</span>
                                <span>Please check your email's spam folder if you do not find it in your inbox.</span>
                                <span>Optionally if you didnt receive you can request it be resent with the button below.</span>
                            </div> 
                            <input ref={this.codeRef} type="text" id="code" name="code" placeholder="Enter Verification Code"/>
                            <span className="error"></span>                                                               
                            <button onClick={this.handleRegister4} id="next">Confirm</button>      
                            <button onClick={this.handleResend} id="resend">Resend Verification Email</button>
                        </div>
                    </div>
                </div>
            </div>            
            </>
        );
      }
}