import React, { createRef } from "react";

import { DateTime } from "luxon";

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { AiFillStar, AiFillCamera, AiOutlineLoading } from 'react-icons/ai';
import { FiFilter } from 'react-icons/fi';


import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import { Tooltip as ReactTooltip } from "react-tooltip";

import fuzzytime from 'fuzzy-time';

import "./vacancies.css";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";


import TutorialComponent from "../components/tutorial";

import { ToastContainer, toast } from 'react-toastify';
import MultiOpenRate from "../components/multiopenrates";

const PAGE_SIZE = 10;

export default class VacanciesUsersPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          user_id: null,
          loadingUsers: false,
          loadedUsers: null,
          loading: false,
          interviews: [],
          paged_interviews: [],
          page_id: 0,
          pages: [],
          notices: "",
          user: false,
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 


      this.useAsTemplateDialogRef = createRef();
      this.useAsTemplateDialogTitleRef = createRef();

      this.createInterviewDialogRef = createRef();
      this.createInterviewDialogTitleRef = createRef();
      this.jobTitleFilterDialogSearchRef = createRef();
      this.dateFilterDialogSearchRef = createRef();
      this.jobTitleSearchTextInputRef = createRef();
      this.jobTitleOrderInputRef = createRef();

      this.editInterviewDialogRef = createRef();
      this.jobTitleOrderInputRef = createRef();
      
      this.dateFromSearchTextInputRef = createRef();
      this.editInterviewDialogTitleRef = createRef();

      this.allAnwserLinks = createRef();
      this.allAnwserLinksText = createRef();

      this.getDeg = this.getDeg.bind(this);
      //this.getApplicationCircle = this.getApplicationCircle.bind(this);
      
      this.openInterview = this.openInterview.bind(this);
      this.editInterview = this.editInterview.bind(this);
      this.deleteInterview = this.deleteInterview.bind(this);
      this.openCreateInterview = this.openCreateInterview.bind(this);
      this.addToLibrary = this.addToLibrary.bind(this);
      this.useAsTemplate = this.useAsTemplate.bind(this);

      this.createInterview = this.createInterview.bind(this);
      
      this.closeDialog = this.closeDialog.bind(this);
      
      this.loadUsers = this.loadUsers.bind(this);
      this.reloadPage = this.reloadPage.bind(this);
      this.getTime = this.getTime.bind();

      this.archiveSelected = this.archiveSelected.bind(this);
      this.archiveOne = this.archiveOne.bind(this);
      this.deleteSelected = this.deleteSelected.bind(this);
      this.gotoPage = this.gotoPage.bind(this);
      this.openJobSearch = this.openJobSearch.bind(this);
      this.applyJobTitleSearch = this.applyJobTitleSearch.bind(this);
      this.applyJobTitleClear = this.applyJobTitleClear.bind(this);
      
      this.applyDateRangeSearch = this.applyDateRangeSearch.bind(this);
      this.applyDateRangeClear = this.applyDateRangeClear.bind(this);
      
      this.openDateRange = this.openDateRange.bind(this);
    }

    getTime(dt){
        return DateTime.fromISO(dt).toFormat("dd-MM-yyyy");
        //return fuzzytime(dt);
    }

    async componentDidMount(){
        function myFunction() {
            document.getElementById("myDropdown").classList.toggle("show");
        }
        
        // Close the dropdown menu if the user clicks outside of it
        window.onclick = function(event) {
            if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
                }
            }
            }
        }
        this.createInterviewDialogRef.current.style.display = "none";
        this.jobTitleFilterDialogSearchRef.current.style.display = "none";
        this.dateFilterDialogSearchRef.current.style.display = "none";        
        this.allAnwserLinks.current.style.display = "none";
        this.editInterviewDialogRef.current.style.display = "none";
        this.useAsTemplateDialogRef.current.style.display = "none";
        this.loadUsers();
        this.reloadPage();
    }

    async loadUsers(){
        this.setState({
            ...this.state,
            loadingUsers: true,
        });
        let api_key = localStorage.getItem('api_key');
        let responsex = await API.user.getAllOtherUsers(api_key);
        this.setState({
            ...this.state,
            loadedUsers: responsex['results'],
            loadingUsers: false,
        });
        console.log("x", this.state);
    }

    async reloadPage(user_id=null){
        this.setState({
            ...this.state,
            loading: true,
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.listUsers(api_key, user_id);
        let interviews = result['interviews'];
        result = await API.interview.getNotice(api_key);
        let notices = result['notice'];
        let total_pages = Math.ceil(interviews.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        result = await API.user.getUser(api_key);
        let user = result['user'];
        if(user.package_number === 0 && user.trial_expiry_flag){
            // show trial expire - popup
            let expiry_date = new Date(user.trial_expiry);
            let current_date = new Date();
            let t = expiry_date.getTime() - current_date.getTime();
            let expired = t <= 0; 
            if(expired){
                window.location.href = "/dashboard";
            }
        }
        this.setState({
            ...this.state,
            interviews: interviews,
            paged_interviews: interviews.slice(0, PAGE_SIZE),
            notices: notices,
            loading: false,
            pages: pages,
            user: user,
        });
    }

    gotoPage(e){
        let page_id = parseInt(e.target.getAttribute('data-page-id'));
        let paged_interviews = this.state.interviews.slice(page_id * PAGE_SIZE, (page_id * PAGE_SIZE) + PAGE_SIZE);
        this.setState({
            paged_interviews: paged_interviews,
            page_id: page_id
        });
        window.scrollTo(0,0);
    }
    
    polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    };
    
    describeSlice = (x, y, radius, startAngle, endAngle) => {
    
        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);
    
        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    
        const d = [
            "M", 0, 0, start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
    
        return d;
    };
    
    path = (degrees = 90, radius = 10) => {
        return this.describeSlice(0, 0, radius, 0, degrees) + 'Z';
    };

    getDeg(u, t){
        let x = u/t;
        
        if(x === 1 || (this.state.used === 0&& this.state.total === 0)){
            return 359.9;
        }
        return x * 360;
    }

    /*getApplicationCircle(applications){
        let total = applications.total;
        let invited = applications.invited;
        let totalCstr = total + " Candidates";
        let invitedCstr = invited + " Candidates";
        let deg = this.getDeg(invited, total);
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{width: "64px", height:"64px"}}>
                <circle cx="16" cy="16" r="8" fill="#32CD32">
                <title>{ totalCstr }</title>
                </circle>
                <path d={this.path(deg, 8)} fill="#1E90FF" style={{transform: "translateX(16px) translateY(16px) rotateZ(180deg)"}}>
                    <title>{ invitedCstr }</title>
                </path>
                <circle cx="16" cy="16" r="4" fill="#FFFFFF" />  
                <text x="14" y="18" style={{"font-size": "3pt"}}>{ total }</text>              
            </svg>
        );
    }*/

    openCreateInterview(){
        this.createInterviewDialogRef.current.style.display = "flex";
    }

    closeDialog(){
        this.createInterviewDialogRef.current.style.display = "none";
    }

    async createInterview(){
        let title = this.createInterviewDialogTitleRef.current.value;
        if(title == "" || title == null){
            alert("enter a valid vacancy title");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.create(api_key, title);
        if(result['success']){ 
            this.setState({
                ...this.state,
                loading: false
            });
            this.createInterviewDialogRef.current.style.display = "none";
            toast(result['message']);
            this.reloadPage();
        } else {
            this.setState({
                ...this.state,
                loading: false
            });
            toast(result['message']);
        }
    }

    openInterview(e){
        let iid = e.target.getAttribute('data-interview-id');
        window.location.href = "/vacancy/" + iid;
    }
    
    async addToLibrary(e){
        toast("Adding to library");
        let id = e.target.getAttribute('data-interview-id');
        this.setState({
            ...this.state,
            loading: true
        });        
        let api_key = localStorage.getItem('api_key');
        let result = await API.templates.save(api_key, id);
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Added to library");
    }
    
    async useAsTemplate(e){
        let id = e.target.getAttribute('data-interview-id');
        let title = e.target.getAttribute('data-interview-title');
        this.setState({
            ...this.state,
            loading: true
        });                
        this.useAsTemplateDialogRef.current.setAttribute('data-interview-id', id);
        this.useAsTemplateDialogTitleRef.current.value = title;
        this.useAsTemplateDialogRef.current.style.display = "flex";
        
        this.setState({
            ...this.state,
            loading: false
        });
    }

    editInterview(e){
        let iid = e.target.getAttribute('data-interview-id');
        let title = e.target.getAttribute('data-interview-title');
        this.editInterviewDialogRef.current.setAttribute('data-interview-id', iid);
        this.editInterviewDialogRef.current.style.display = "flex";
        this.editInterviewDialogTitleRef.current.value = title;
    }

    async deleteInterview(e){
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let iid = e.target.getAttribute('data-interview-id');
        let result = await API.interview.delete(api_key, iid);
        this.reloadPage();
        this.setState({
            ...this.state,
            loading: false
        });
    }

    async archiveSelected(){        

        let vacancy_ids = Array.from(document.querySelectorAll('input[data-vacancy-checkbox=true]:checked')).map((input)=>{
            return parseInt(input.getAttribute('vacancy-id'));
        });
        if(vacancy_ids.length == 0){
            toast.error("No vacancies selected, select a vacancy first");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.archive(api_key, vacancy_ids);
        this.setState({
            ...this.state,
            loading: false
        });
        this.reloadPage();
    }

    async archiveOne(e){     
        let vacancy_ids = [parseInt(e.target.getAttribute('data-interview-id'))];
        if(vacancy_ids.length == 0){
            toast.error("No vacancies selected, select a vacancy first");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.archive(api_key, vacancy_ids);
        this.setState({
            ...this.state,
            loading: false
        });
        this.reloadPage();
    }

    async deleteSelected(){        

        let vacancy_ids = Array.from(document.querySelectorAll('input[data-vacancy-checkbox=true]:checked')).map((input)=>{
            return parseInt(input.getAttribute('vacancy-id'));
        });
        if(vacancy_ids.length == 0){
            toast.error("No vacancies selected, select a vacancy first");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.deleteAll(api_key, vacancy_ids);
        this.setState({
            ...this.state,
            loading: false
        });
        this.reloadPage();
    }

    openJobSearch(){
        if(this.jobTitleFilterDialogSearchRef.current.style.display == "block"){            
            this.jobTitleFilterDialogSearchRef.current.style.display = "none";
        } else {            
            this.jobTitleFilterDialogSearchRef.current.style.display = "block";
        }
    }
    
    openDateRange(){
        if(this.dateFilterDialogSearchRef.current.style.display == "block"){            
            this.dateFilterDialogSearchRef.current.style.display = "none";
        } else {            
            this.dateFilterDialogSearchRef.current.style.display = "block";
        }
    }

    applyJobTitleClear(){
        this.jobTitleFilterDialogSearchRef.current.style.display = "none";
        this.jobTitleSearchTextInputRef.current.value = "";
        this.reloadPage();
    }

    async applyJobTitleSearch(){        
        this.setState({
            ...this.state,
            loading: true
        });  
        let text = this.jobTitleSearchTextInputRef.current.value;
        let sort = this.jobTitleOrderInputRef.current.value;
        this.jobTitleFilterDialogSearchRef.current.style.display = "none";   
        let api_key = localStorage.getItem('api_key');     
        let result = await API.interview.list(api_key);
        let interviews = result['interviews'];
        interviews = text != "" && text != null ? this.state.interviews.filter((interview)=>{
            return  (new RegExp(text.toLowerCase())).test(interview.title.toLowerCase());
        }) : this.state.interviews;
        interviews = interviews.sort(function(a,b){
            let x = a.title.toLowerCase();
            let y = b.title.toLowerCase();
            if(sort == 'az'){                
                if(x > y) return 1;
                if(x < y) return -1;
                return 0;    
            } else if(sort == 'za'){
                if(x > y) return -1;
                if(x < y) return 1;
                return 0;    
            }
        });
        
        let total_pages = Math.ceil(interviews.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        this.setState({
            ...this.state,
            interviews: interviews,
            paged_interviews: interviews.slice(0, PAGE_SIZE),
            loading: false,
            pages: pages,
        });
    }
    
    applyDateRangeClear(){
        this.dateFilterDialogSearchRef.current.style.display = "none";
        this.dateFromSearchTextInputRef.current.value = "";
        this.dateToSearchTextInputRef.current.value = "";
        this.reloadPage();
    }
    
    async applyDateRangeSearch(){        
        this.setState({
            ...this.state,
            loading: true
        });  
        let from = new Date(this.dateFromSearchTextInputRef.current.value);
        let to = new Date(this.dateToSearchTextInputRef.current.value);
        let temp = null;
        if(from > to){
            temp = from;
            from = to;
            to = from;
        }
        this.dateFilterDialogSearchRef.current.style.display = "none";   
        let api_key = localStorage.getItem('api_key');     
        let result = await API.interview.list(api_key);
        let interviews = result['interviews'];
        interviews = this.state.interviews.filter((interview)=>{
            let x = new Date(interview.created);
            return x > from && x < to; 
        });
        let total_pages = Math.ceil(interviews.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        this.setState({
            ...this.state,
            interviews: interviews,
            paged_interviews: interviews.slice(0, PAGE_SIZE),
            loading: false,
            pages: pages,
        });
    }

    render() {
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo" key={"buttons-vacancies"}>
                        <span>Vacancies</span>
                        <button className="blueBTN" style={{position:'relative'}} onClick={()=>{
                            window.location.href = "/vacancies";
                        }}>Normal Vacancies</button>&nbsp;
                        <button className="blueBTN" style={{position:'relative'}} onClick={()=>{
                            window.location.href = "/vacancies/archived";
                        }}>Archived Vacancies</button>&nbsp;
                        <button className="blueBTN" style={{position:'relative'}} onClick={()=>{
                            window.location.href = "/vacancies/seated";
                        }}>Seated Vacancies</button>
                    </div>
                    { this.state.user && <div key={"loaded-users"} className="pageNameInfo">
                            <h4 style={{display:'inline-block', marginRight:'12px'}}>Select User</h4>
                            <select style={{display: 'inline-block', padding: '8px 12px', width: '450px'}} id="loaded_users" name="loaded_users" defaultValue={this.state.user.id.toString()} onChange={ async e => {
                                toast.info("Loading");
                                this.setState({
                                    ...this.state,
                                    user_id: parseInt(e.target.value),
                                });
                                this.reloadPage(parseInt(e.target.value));
                            }}>
                                {
                                    this.state.loadedUsers && this.state.loadedUsers.map((userx, index23)=>{
                                        return (<option value={userx.id}>{ userx.fullname } ({userx.email}) [{userx.id}]</option>)
                                    })
                                }
                            </select>                        
                    </div>}
                    <div className="vacancy-table">
                        <table>
                            <tr className="noHover">
                                <th style={{minWidth:'150px', textAlign:'left'}}>                                    
                                    <span class="dropdown" style={{display:'inline-block'}}>
                                        <button id="vacancies_actions">Actions</button>
                                        <label>
                                            <input type="checkbox" />
                                            <ul>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={this.openCreateInterview}>Add Vacancy</li>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={async ()=>{
                                                let vacancy_ids = Array.from(document.querySelectorAll('input[data-vacancy-checkbox=true]:checked')).map((input)=>{
                                                    return parseInt(input.getAttribute('vacancy-id'));
                                                });
                                                if(vacancy_ids.length == 0){
                                                    toast.error("No vacancies selected, select a vacancy first");
                                                    return;
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    loading: true
                                                });
                                                let api_key = localStorage.getItem('api_key');
                                                for(let i = 0; i < vacancy_ids.length; i++){
                                                    let result = await API.templates.save(api_key, vacancy_ids[i]);
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    loading: false
                                                });
                                            }}>+ Template</li>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={this.archiveSelected}>Archive</li>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={this.deleteSelected}>Delete</li>
                                            { this.state.user.canExtract && <li style={{fontWeight:400, color:'#000'}} onClick={async()=>{
                                                let vacancy_ids = Array.from(document.querySelectorAll('input[data-vacancy-checkbox=true]:checked')).map((input)=>{
                                                    return parseInt(input.getAttribute('vacancy-id'));
                                                });
                                                if(vacancy_ids.length == 0){
                                                    toast.error("No vacancies selected, select a vacancy first");
                                                    return;
                                                }
                                                this.setState({
                                                    ...this.state,
                                                    loading: true
                                                });
                                                let api_key = localStorage.getItem('api_key');
                                                let all_links = [];
                                                for(let i = 0; i < vacancy_ids.length; i++){
                                                    let result = await API.answers.getAll(api_key, vacancy_ids[i]);
                                                    let links = result['links'];
                                                    links.forEach(a => all_links.push(a));
                                                }
                                                this.allAnwserLinks.current.style.display = "flex";
                                                this.allAnwserLinksText.current.value = all_links.join('\n');
                                                this.setState({
                                                    ...this.state,
                                                    loading: false
                                                });
                                            }}>Extract Answers</li> }
                                            </ul>
                                        </label>
                                    </span>
                                </th>
                                <th style={{position:'relative'}}>Job Title <FiFilter onClick={this.openJobSearch} className="invert_hover" style={{position:'absolute', right:'12px', fontSize:'18pt', cursor:'pointer'}}/>
                                    <div className="jobTitleFilterDialog" ref={this.jobTitleFilterDialogSearchRef}>
                                        <span>Search by text</span><br/>
                                        <input type="text" style={{width:'calc(100% - 20px)', padding:'8px'}} placeholder="Type here" ref={this.jobTitleSearchTextInputRef}/><br/>
                                        <br/>
                                        <span>Sort By</span><br/>
                                        <select style={{width:'100%', padding:'8px'}} ref={this.jobTitleOrderInputRef}>
                                            <option value="az">A to Z</option>
                                            <option value="za">Z to A</option>
                                        </select><br/><br/>
                                        <button onClick={this.applyJobTitleSearch}>Apply</button>
                                        <button onClick={this.applyJobTitleClear} style={{marginTop:'8px'}}>Clear Filter</button>
                                    </div>
                                </th>
                                <th>Open Rate</th>
                                <th style={{position:'relative'}}>Date  <FiFilter onClick={this.openDateRange} className="invert_hover" style={{position:'absolute', right:'12px', fontSize:'18pt', cursor:'pointer'}}/>
                                    <div className="jobTitleFilterDialog" ref={this.dateFilterDialogSearchRef}>
                                    <span style={{fontSize:'9pt'}}>From:</span><br/>
                                    <input type="date" style={{width:'calc(100% - 20px)', padding:'8px'}} ref={this.dateFromSearchTextInputRef}/><br/>                                        
                                    <span style={{fontSize:'9pt'}}>To:</span><br/>
                                    <input type="date" style={{width:'calc(100% - 20px)', padding:'8px'}} ref={this.dateToSearchTextInputRef}/><br/>                                        
                                    <button style={{marginTop:'8px'}} onClick={this.applyDateRangeSearch}>Apply</button>
                                    <button style={{marginTop:'8px'}} onClick={this.applyDateRangeClear}>Clear Filter</button>
                                </div>
                                </th>
                            </tr>     
                            { this.state.notices ? <tr>
                                <td>
                                    <td style={{width: "64px"}}>{ this.state.notices }</td>
                                </td>
                                
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>    : <></> }                     
                            { this.state.interviews.length == 0 &&
                                <tr>
                                    <td style={{width:'calc(100vw / 6)'}}></td>
                                    <td style={{width:'calc(100vw / 6)'}}></td>
                                    <td style={{width:'calc(100vw / 6)'}}></td>
                                    <td style={{width:'calc(100vw / 6)'}}></td>
                                </tr>
                            }
                            {
                                   this.state.paged_interviews.map((interview)=>{
                                        let r_id = interview.id;
                                        
                                        return (
                                        <tr key={"interview-" + interview.id}  className="table-body">
                                            <td style={{minWidth:'150px'}}>
                                                <input type="checkbox" data-vacancy-checkbox={true} vacancy-id={interview.id} style={{display:'inline-block', transform:"scale(1.5)", marginRight:'8px'}}/>
                                                <span class="dropdown" style={{display:'inline-block'}}>
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul>
                                                        <li data-interview-id={interview.id} onClick={this.openInterview}>Open</li>
                                                        <li data-interview-id={interview.id} data-interview-title={interview.title} onClick={this.editInterview}>Edit</li>
                                                        <li data-interview-id={interview.id} onClick={this.deleteInterview}>Delete</li>
                                                        <li data-interview-id={interview.id} onClick={this.archiveOne}>Archive</li>
                                                        <li data-interview-id={interview.id} onClick={this.addToLibrary}>+ Template</li>
                                                        <li data-interview-id={interview.id} data-interview-title={interview.title} onClick={this.useAsTemplate}>Use as Template</li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </td>
                                            <td style={{textAlign:'center', maxWidth:'360px'}}>{ interview.title }</td>
                                            <td className="openRateVacancy2" style={{textAlign:'center'}}>
                                                <MultiOpenRate interview={interview}/>
                                            </td>
                                            <td style={{padding:'32px', textAlign:'center'}}>{ this.getTime(interview.created) }</td>
                                        </tr>
                                    )})
                                }
                        </table>
                        <div className="pagination_table">
                            <span>Pagination</span>
                            <div>
                                {
                                    this.state.pages.map((page)=>{
                                        return (
                                            this.state.page_id == page - 1 ? 
                                            <button data-page-id={page - 1} onClick={this.gotoPage} className="active">{page}</button> :
                                            <button data-page-id={page - 1} onClick={this.gotoPage}>{page}</button>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="dialogNewCreate" ref={this.createInterviewDialogRef}>        
                        <div>
                            <h4>Add a new vacancy</h4>
                            <div>
                                <input type="text"  ref={this.createInterviewDialogTitleRef} className="createVacancyInput" placeholder="Vacancy Name"/>
                            </div>
                            <div>
                                <button onClick={this.createInterview}>Create</button>
                                <button onClick={this.closeDialog}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="dialogNewCreate" ref={this.editInterviewDialogRef}>        
                        <div>
                            <h4>Edit Vacancy Title</h4>
                            <div>
                                <input type="text"  ref={this.editInterviewDialogTitleRef} className="createVacancyInput" placeholder="Vacancy Name"/>
                            </div>
                            <div>
                                <button onClick={async ()=>{
                                    this.setState({...this.state, loading: true});
                                    let api_key = localStorage.getItem("api_key");
                                    let result = await API.interview.update(api_key, parseInt(this.editInterviewDialogRef.current.getAttribute("data-interview-id")), this.editInterviewDialogTitleRef.current.value);
                                    this.setState({...this.state, loading: false});
                                    this.editInterviewDialogTitleRef.current.value = "";
                                    this.editInterviewDialogRef.current.style.display = "none";
                                    toast("interview Updated");
                                    this.reloadPage();
                                }}>Update</button>
                                <button onClick={()=>{
                                    this.editInterviewDialogTitleRef.current.value = "";
                                    this.editInterviewDialogRef.current.style.display = "none";
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="dialogNewCreate" ref={this.useAsTemplateDialogRef}>        
                        <div>
                            <h4>Use Vacancy As Template</h4>
                            <div>
                                <input type="text"  ref={this.useAsTemplateDialogTitleRef} className="createVacancyInput" placeholder="Vacancy Name"/>
                            </div>
                            <div>
                                <button onClick={async ()=>{
                                    this.setState({...this.state, loading: true});
                                    let api_key = localStorage.getItem("api_key");
                                    let result = await API.templates.useastemplate(api_key, this.useAsTemplateDialogRef.current.getAttribute('data-interview-id'), this.useAsTemplateDialogTitleRef.current.value);
                                    this.setState({...this.state, loading: false});
                                    this.useAsTemplateDialogTitleRef.current.value = "";
                                    this.useAsTemplateDialogRef.current.style.display = "none";
                                    toast("interview used as template");
                                    this.reloadPage();
                                }}>Update</button>
                                <button onClick={()=>{
                                    this.useAsTemplateDialogTitleRef.current.value = "";
                                    this.useAsTemplateDialogRef.current.style.display = "none";
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="dialogNewCreate" ref={this.allAnwserLinks}>        
                        <div style={{width:'720px'}}>
                            <h4>All Answer Videos</h4>
                            <div>
                                <textarea ref={this.allAnwserLinksText} style={{width:'680px', height:'193px'}}></textarea>
                            </div>
                            <div>
                                <button onClick={()=>{
                                    this.allAnwserLinks.current.style.display = "none";
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
                { 
                    this.state.loading ? (
                        <div className="loader">                        
                            <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                        </div>
                    ) : ""
                }
                
            <TutorialComponent page_number="5"></TutorialComponent>
                </>
        );
      }
}