import { FFmpeg } from "@ffmpeg/ffmpeg";
import React, { useEffect, useRef, useState } from "react";
import { base64ToBlob, blobToBase64 } from 'base64-blob';
import IndexedDBFileStorage from "../inBrowserRecorderAndProcessingComponent/IndexedDBFileStorage";
import API from "../../services/api";
import { fetchFile } from '@ffmpeg/util';

import './InBrowserGifFromVideoComponent.css';

interface InBrowserGifFromVideoComponentProps {
    apiKey: string;
    inputVideoURL: string;
    text: string;
    onClose: () => void;
    onCompletion: (gif_url:string|null) => void;
}

export default function InBrowserGifFromVideoComponent(props:InBrowserGifFromVideoComponentProps){
    // State
    const debug = true;
    const [ currentState, setCurrentState ] = useState<string>("loading");
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ uploadedURL, setUploadedURL ] = useState<string>("");
    const ffmpegRef = useRef(new FFmpeg());
    const dbRef = useRef(new IndexedDBFileStorage("recorded_file_data", 1));
    const inputVideoFileNameRef = useRef<string>("");
    const playIconFileNameRef = useRef<string>("");
    const playIconURL = "https://storage.googleapis.com/clipdrop-prod/secure/play.png?avoidCache=1";
    const copyrightImageURL = "https://storage.googleapis.com/clipdrop-prod/secure/clipdrop.png?avoidCache=1";
    const copyrightImageFileNameRef = useRef<string>("");
    // Functions
    const fitText = (ctx:any, text:string, maxWidth:number, maxHeight:number)=>{
        let fontSize = 32; // Initial font size
        ctx.font = `${fontSize}px "Open Sans", sans-serif`;
        let textWidth = ctx.measureText(text).width;
        let textHeight = parseInt(ctx.font, 10);

        while ((textWidth > maxWidth || textHeight > maxHeight) && fontSize > 1) {
            fontSize--;
            ctx.font = `${fontSize}px "Open Sans", sans-serif`;
            textWidth = ctx.measureText(text).width;
            textHeight = parseInt(ctx.font, 10);
        }
        return fontSize;
    }
    const drawFrame = async (frame:HTMLImageElement, playIcon:HTMLImageElement, text:string):Promise<Blob> => {
        return new Promise((resolve)=>{
            try {
                // Resize Icon to 48x48
                if(debug) console.log("Draw Frame: Step 1");
                playIcon.width = 48;
                playIcon.height = 48;
                // Icon Placement
                if(debug) console.log("Draw Frame: Step 2");
                const px = (frame.width / 2) - (playIcon.width / 2);
                const py = (frame.height / 2) - (playIcon.height / 2);
                const pw = playIcon.width;
                const ph = playIcon.height;
                // Text Placement
                if(debug) console.log("Draw Frame: Step 3");
                const tx = 0;
                const ty = frame.height - 64;
                const maxWidth = 320;
                // Canvas
                if(debug) console.log("Draw Frame: Step 4");
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = frame.width;
                canvas.height = frame.height;
                if(!ctx){
                    console.log("2D Context Empty");
                    return;
                };
                // Fill Image with Default Color
                ctx.fillStyle = "#1E1E1E";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                // Draw Frame
                ctx.drawImage(frame, 0, 0, frame.width, frame.height);
                // Draw Icon
                ctx.drawImage(playIcon, px, py, pw, ph);
                // Draw Text
                ctx.fillStyle = "#FFFFFF";
                ctx.font = '32px "Open Sans", sans-serif';
                ctx.textAlign = 'center';
                // Measure Text
                if(debug) console.log("Draw Frame: Step 5");
                const textWidth = ctx.measureText(text).width;
                const textHeight = parseInt(ctx.font, 10);
                const tx2 = tx + textWidth;
                const ty2 = ty + textHeight;
                const finalFontSize = fitText(ctx, text, 792, 64);
                ctx.font = `${finalFontSize}px "Open Sans", sans-serif`;
                ctx.fillText(text, canvas.width / 2, ty, tx2 - tx);
                if(debug) console.log("Draw Frame: Step 6");
                canvas.toBlob( blob => {
                    if(!blob){
                        resolve(new Blob());
                        return;
                    }
                    resolve(blob);
                }, "image/png")
            } catch (e){
                console.log("Error Drawing Frame", e);
            }     
        });
    }
    const getImageFromBlob = async (blob: Blob): Promise<HTMLImageElement> => {
        return new Promise((resolve) => {
            const image = new Image();
            image.onload = () => {
                if(debug) console.log("Image Loaded");
                if(debug) console.log("W", image.width);
                resolve(image);
            };
            image.src = URL.createObjectURL(blob);
        });
    }
    const getImageAsImageObject = async (image_url: string): Promise<HTMLImageElement> => {
        return new Promise((resolve) => {
            fetch(image_url)
                .then(response => {
                    if (!response.ok) {
                        console.error('There was a problem fetching the image');
                        resolve(new Image());
                    }
                    return response.blob();
                })
                .then(blob => {
                    const image = new Image();
                    image.src = URL.createObjectURL(blob);
                    image.onload = () => {
                        resolve(image);
                    };
                })
                .catch(error => {
                    console.error('There was a problem fetching the image:', error);
                    resolve(new Image());
                });
        });
    }
    const getFileNameFromURL = (video_url) => {
        if (!video_url || typeof video_url !== 'string') return "";
        let file_name = video_url.trim();
        const queryIndex = file_name.indexOf("?");
        if (queryIndex !== -1) {
            file_name = file_name.slice(0, queryIndex);
        }
        file_name = file_name.substring(file_name.lastIndexOf("/") + 1);    
        return file_name;
    }
    const getImageAsBlob = async (image_url:string):Promise<Blob> => {
        return new Promise((resolve)=>{
            fetch(image_url)
            .then(response => {
                if (!response.ok) {
                    console.error('There was a problem fetching the image');
                    resolve(new Blob());
                }
                return response.blob();
            })
            .then(blob => {
                resolve(blob);
            })
            .catch(error => {
                console.error('There was a problem fetching the image:', error);
                resolve(new Blob());
            });
        });
    }
    const blobToImage = async (blob:Blob) => {
        let imageAsURL = URL.createObjectURL(blob);
        let loadedImage = await (new Promise((resolve)=>{
            const image = new Image();
            image.onload = () => {
                resolve(image);
            }
            image.src = imageAsURL;
        }));
        return loadedImage;
    }
    const fromBlobToURL = (blob:any) => {
        return URL.createObjectURL(blob);
    }
    const fromURLToBlob = async (url: string):Promise<any> => {
        try {
          const response = await fetch(url, {
          });  
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
          }  
          const blob = await response.blob();
          const base64String = await blobToBase64(blob);
          return base64String;
        } catch (error) {
          console.error('Download failed:', error);
          throw error;
        }
    }
    const toBase64 = (file:any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const loadFFMPEG = async () => {
        let openned = await dbRef.current.openDatabase();
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        const coreURL = `${baseURL}/ffmpeg-core.js`;
        const wasmURL = `${baseURL}/ffmpeg-core.wasm`;
        let core:any = null;
        try{
            core = await dbRef.current.getFile("core");
        } catch (e) {
            console.log("XE1", e);  
        }
        let coreData = null;
        if(core){
            coreData = (await base64ToBlob(core.data)) as any;
        }
        if(!coreData){
            coreData = await fromURLToBlob(coreURL);
            let savedCore = await dbRef.current.addFile({
                id: "core",
                data: coreData,
                mimeType: "text/javascript"
            }); 
        } 
        let wasm:any = null; 
        try{
            wasm = await dbRef.current.getFile("wasm");
        } catch (e) {
            console.log("XE2", e);  
        }
        let wasmData:any = null;    
        if(wasm){
            wasmData = (await base64ToBlob(wasm.data)) as any;
        }
        if(!wasmData){            
            wasmData = await fromURLToBlob(wasmURL);
            let savedWasm = await dbRef.current.addFile({
                id: "wasm",
                data: wasmData,
                mimeType: "application/wasm"
            }); 
        }
        if(!coreData){
            console.log("coreData empty");
            return;
        }
        if(!wasmData){
            console.log("wasmData empty");
            return;
        }
        const coreBlob = new Blob([coreData], { type: "text/javascript"});
        const wasmBlob = new Blob([wasmData], { type: "application/wasm"});
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.load({
            coreURL: fromBlobToURL(coreBlob),
            wasmURL: fromBlobToURL(wasmBlob),
        });
        if(debug) ffmpeg.on("log", console.log);
        setCurrentState("ready");
        return true;
    };
    const uploadFile = async (fileData:any, setPercentage:(val:number)=>void, extension="mp4") => {
        let uploadFileURL = "";     
        while (!uploadFileURL) {
            uploadFileURL = await API.file.uploadFileAsFileWithProgress(props.apiKey, extension, fileData, (percentage:any)=>{
                console.log(`UPLOAD ${percentage}%`);
                setPercentage(percentage);
            });
            uploadFileURL = uploadFileURL ?? "";
        }
        return uploadFileURL;
    }
    const handleMp4ToGif  = async () => {
        //try {
            setCurrentState("processing-icon");
            if(debug) console.log("props.playIcon", playIconURL);
            playIconFileNameRef.current = getFileNameFromURL(playIconURL);
            try{
                await ffmpegRef.current.deleteFile(playIconFileNameRef.current);
            } catch(e){}
            const playIconBlob = await fetchFile(await fromURLToBlob(playIconURL));
            let fileWritten = await ffmpegRef.current.writeFile(
                playIconFileNameRef.current, playIconBlob
            );
            const playIcon = await getImageAsImageObject(playIconURL);
            /*copyrightImageFileNameRef.current = getFileNameFromURL(copyrightImageURL);
            try{
                await ffmpegRef.current.deleteFile(copyrightImageFileNameRef.current);
            } catch(e){}
            const copyrightImageBlob = await fetchFile(await fromURLToBlob(copyrightImageURL));
            let fileWritten3 = await ffmpegRef.current.writeFile(
                copyrightImageFileNameRef.current, copyrightImageBlob
            );
            const copyrightImage = await getImageAsImageObject(copyrightImageURL);*/
            if(debug) console.log("props.inputVideoURL", props.inputVideoURL);
            inputVideoFileNameRef.current = getFileNameFromURL(props.inputVideoURL);
            try{
                await ffmpegRef.current.deleteFile(inputVideoFileNameRef.current);
            } catch(e){}
            for(let i=1;i<=30;i++){
                try {
                    await ffmpegRef.current.deleteFile(`frame_${i}.png`);
                } catch (e){
                }
            }
            setCurrentState("processing-video");
            let fileWritten2 = await ffmpegRef.current.writeFile(
                inputVideoFileNameRef.current, await fetchFile(await fromURLToBlob(props.inputVideoURL))
            );
            const ffmpeg_arguments5 = [
                '-i', inputVideoFileNameRef.current, // Input File
                '-frames:v', '60',
                '-vf', "scale=800:800:force_original_aspect_ratio=decrease,pad=800:800:(ow-iw)/2:(oh-ih)/2",
                `${inputVideoFileNameRef.current}.fixed.mp4`
            ];
            if(debug) console.log("ffmpeg_arguments", ffmpeg_arguments5);
            let converted4 = await ffmpegRef.current.exec(ffmpeg_arguments5);
            if(debug) console.log("X1");
            const ffmpeg_arguments = [
                '-i', `${inputVideoFileNameRef.current}.fixed.mp4`, // Input File
                '-ss', '0.5', // Skip the first 0.5 seconds
                '-c:v', 'png',
                '-preset', 'ultrafast',
                '-vf', "fps=15,scale=320:-1,select='lt(n\,60)'", // Filter -> Select First 30 Frames and Scale image to 320px width, maintaining aspect ratio
                '-q:v', '2',
                'frame_%d.png' // Save Each frame as frame_X.png where X is the frame number starting from 1, 2, 3... to 30
            ];

            if(debug) console.log("ffmpeg_arguments", ffmpeg_arguments);
            let converted = await ffmpegRef.current.exec(ffmpeg_arguments);
            try {
                await ffmpegRef.current.deleteFile(`${inputVideoFileNameRef.current}.fixed.mp4`);
            } catch (e){
            }
            let avaliableFilesData:{[key:number]:Blob} = {};
            for(let i=1;i<=30;i++){
                try {
                    let fileData = await ffmpegRef.current.readFile(`frame_${i}.png`);
                    if(fileData){
                        avaliableFilesData[i] = new Blob([fileData], {type: "image/png"})
                    } else {
                        if(debug) console.log(`Frame file: ${i} doesn't exist or is empty`);
                    }
                } catch (e){
                    console.log(`Frame file: ${i} doesn't exist`);
                }
            }
            const totalFrameCount = Object.keys(avaliableFilesData).length;
            if(debug) console.log("totalFrameCount", totalFrameCount);
            /*if(totalFrameCount < 30){
                setCurrentState("less_than_30_frames");
                return;
            }*/
            // Loop through each frame (use the HTML5 Canvas)
            // => Apply the play button centered
            // => Render the Text In White with a strong black border
            // Create a Gif from the combined edited frames
            setCurrentState("processing-frames");
            const outputFilesData = {};
            const keys = Object.keys(avaliableFilesData);
            for(const key of keys){
                const blobAsImage = await getImageFromBlob(avaliableFilesData[key]);
                if(debug) console.log(`Draw Frame: ${key}`)
                outputFilesData[key] = await drawFrame(blobAsImage, playIcon, props.text);
            }
            // Clear Avaiable Files
            avaliableFilesData = {};
            for(let i=1;i<=30;i++){
                try {
                    await ffmpegRef.current.deleteFile(`frame_${i}.png`);
                } catch (e){
                }
            }
            // Save Output Files
            const keys2 = Object.keys(outputFilesData);
            for(const key of keys2){
                const filename = `output_${key}.png`;
                try {
                    await ffmpegRef.current.deleteFile(filename);
                } catch (e){
                }
                let fileWritten3 = await ffmpegRef.current.writeFile(
                    filename, await fetchFile(outputFilesData[key])
                );
            }
            // Call FFMPEG to make images into Gif
            setCurrentState("processing-gif");
            try {
                await ffmpegRef.current.deleteFile('output.gif');
            } catch (e){
            }
            console.log("dirs", await ffmpegRef.current.listDir("/"));
            const ffmpeg_arguments2:string[] = [
                "-i", "output_%d.png",
                '-q:v', '2',
                '-framerate', "15",
                '-vf', 'fps=15,scale=800:-1,split[s0][s1];[s0]palettegen[p];[s1][p]paletteuse',
                'output.gif'
            ];
            console.log("ffmpeg_arguments", ffmpeg_arguments2);
            let converted2 = await ffmpegRef.current.exec(ffmpeg_arguments2);
            // Upload Gif
            setCurrentState("processing-uploading");
            const data = await ffmpegRef.current.readFile('output.gif');
            const dataBlob = new Blob([data], { type: 'image/gif' });
            //let fileData = ((await toBase64(dataBlob)) as string);
            //const match = fileData.match(/base64,(\S+)/);
            //if(match){
            //    fileData = match[1];
            //}
            let fileURL = await uploadFile(dataBlob, setUploadProgress, "gif");
            setUploadedURL(fileURL);
            setCurrentState("preview");
        //} catch (e){
        //    setCurrentState("unknown_error");
        //    console.log("error", e);
        //}
    };

    // Effects
    useEffect(()=>{
        loadFFMPEG();
    }, []);
    
    useEffect(()=>{
        if(currentState !== "ready") return;
        handleMp4ToGif();
    }, [currentState]);

    if(currentState === "loading"){
        return <div className='in-browser-gif-component'>
            <h4>Loading</h4>
        </div>
    }

    if(currentState === "less_than_30_frames"){
        return <div className='in-browser-gif-component'>
            <h4>Error Your video is to sort, please try again with another video</h4>
        </div>
    }
    
    if(currentState === "ready"){
        return <div className='in-browser-gif-component'>
            <h4>Ready!</h4>
        </div>
    }
    
    if(currentState === "processing-icon"){
        return <div className='in-browser-gif-component'>
            <h4>Loading Play Icon</h4>
        </div>
    }
    
    if(currentState === "processing-video"){
        return <div className='in-browser-gif-component'>
            <h4>Extract Frames from video</h4>
        </div>
    }
    
    if(currentState === "processing-frames"){
        return <div className='in-browser-gif-component'>
            <h4>Drawing Text and Icon to Frames</h4>
        </div>
    }
    
    if(currentState === "processing-gif"){
        return <div className='in-browser-gif-component'>
            <h4>Creating Gif</h4>
        </div>
    }

    if(currentState === "processing-uploading"){
        return <div className='in-browser-gif-component'>
            <h4>Uploading Gif</h4>
            <div>
                <progress value={uploadProgress} max={100}/>
            </div>
        </div>
    }
    
    if(currentState === "preview"){
        return <div className='in-browser-gif-component'>
            <h4>Generated Gif - Preview</h4>
            <div>
                <img src={uploadedURL} alt="Generated Gif" />
                <div className="actions">
                    <button className='button-primary-blue block full' style={{margin:'8px 0px'}} onClick={ e => {
                        props.onCompletion(uploadedURL);
                    }}>Continue</button>
                </div>
            </div>
        </div>
    }

    if(currentState === "unknown_error"){
        return <div className='in-browser-gif-component'>
            <h4>Unknown Error - Contact Support</h4>
        </div>
    }

    return <div className='in-browser-gif-component'>
        <h4>Unexpected State - Contact Support</h4>
    </div>
}