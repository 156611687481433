import React, { createRef, forwardRef } from "react";
import axios from 'axios';
import API from "./../services/api";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";

export default class LoginPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {isError: false, errorMessage: ""}; // isError -> errorMessage

      this.emailRef = createRef();
      this.passwordRef = createRef();
      
      this.showRef = createRef();
      this.hideRef = createRef();

      // bind
      this.handleLogin = this.handleLogin.bind(this);
      this.togglePassword = this.togglePassword.bind(this);
    }

    // handle login
    async handleLogin(){
        toast.info("Logging In");
        this.emailRef.current.value = this.emailRef.current.value.toLowerCase();
        let email =  this.emailRef.current.value.trim();
        let password =  this.passwordRef.current.value.trim();
        const url = API.baseURL + "/api/v1/user/login";
        const body = {
            email: email,
            password: password,
        };
        axios.post(url, body, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => {
            let result = response.data;
            let temp_key = result['temp_key'];
            if(temp_key){
                toast.info("Logged In");
                localStorage.setItem('temp_key', temp_key);
                localStorage.setItem('api_key', "");
                window.location.href = "/2fa";
            } else {
                toast.error("invalid login details");
                this.setState({...this.state, isError: true, errorMessage: "invalid login details"});
            }
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    componentDidMount(){
      this.hideRef.current.style.display = "block";
      this.showRef.current.style.display = "none";
    }

    togglePassword(){
        let type = this.passwordRef.current.type;
        switch(type){
            case "text":
                this.passwordRef.current.type = "password";
                this.showRef.current.style.display = "none";
                this.hideRef.current.style.display = "block";                
                break;
            case "password":
                this.passwordRef.current.type = "text";
                this.showRef.current.style.display = "block";
                this.hideRef.current.style.display = "none";
            break;
        }
    }

    render() {
        // HTML
        return (
            <div className="outer">
                <div className="middle">
                    <div className="container">
                        <div className="loginFormLogo"></div>
                        <div className="form">
                            <input id="email" placeholder="Email Address" defaultValue="" ref={this.emailRef}/>
                            <input id="password" type="password" placeholder="Password" defaultValue="" ref={this.passwordRef}/>
                            <button className="togglePassword" onClick={this.togglePassword}>
                                <div ref={this.showRef}><AiFillEye color="#1976D1" fontSize="22pt"/></div>
                                <div ref={this.hideRef}><AiFillEyeInvisible color="#1976D1" fontSize="22pt"/></div>
                            </button>
                            {   
                                this.state.isError ? (
                                    <span className="error">{this.state.errorMessage}</span>
                                ) : ''
                            }
                            <button id="login" onClick={this.handleLogin}>LOGIN</button>
                            {/*<a href="/register">Not a member? Register Here</a>*/}
                            <a href="/forgot">Forgot Password?</a>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}