import React, { createRef } from "react";

import './toggle.css';

export default class ToggleComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          state: props.defaultValue ?? 0
      };
      
      this.onChange = props.onChange;
      this.onLabel = props.onLabel ?? "ON";
      this.offLabel = props.offLabel ?? "OFF";
        
      this.toggleRef = createRef();
      this.toggleONRef = createRef();
      this.toggleOFFRef = createRef();

      this.setValue = this.setState.bind(this);
      this.toggle = this.toggle.bind(this);
      this.updateUI = this.updateUI.bind(this);
    }

    setValue(value){
        console.log("CURRENT: " + value);
        this.setState({
            ...this.state,
            state: value
        });
        this.updateUI();
        console.log("NEW: " + this.state.state);
    }

    toggle(){
        this.setState({
            ...this.state,
            state: this.state.state == 1 ? 0 : 1
        });    
        this.updateUI();
        this.onChange(this.state.state);
    }

    async componentDidMount(){
        this.updateUI();
    }

    updateUI(){
        if(this.state.state == 1){
            this.toggleRef.current.classList.add('on');
            this.toggleRef.current.classList.remove('off');
            this.toggleONRef.current.style.display = "block";
            this.toggleOFFRef.current.style.display = "none";
        } else {
            this.toggleRef.current.classList.add('off');
            this.toggleRef.current.classList.remove('on');
            this.toggleONRef.current.style.display = "none";
            this.toggleOFFRef.current.style.display = "block";
        }
    }

    render() {
        // HTML
        return (
            <div class="toggle" ref={this.toggleRef} onClick={this.toggle}>
                <div ref={this.toggleONRef}>{ this.onLabel }</div>
                <div ref={this.toggleOFFRef}>{ this.offLabel }</div>
            </div>
        )
    }
}