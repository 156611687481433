import React, { useState, useEffect } from "react";

import API from '../services/api';

import "./usagetotals.css";

export default function UsageTotalComponent(){
    const [totals, setTotals] = useState({
        interviews: 0,
        candidates: 0,
    });

    async function fetchData(){        
        let api_key = localStorage.getItem('api_key');
        if(!api_key){window.location.href = "/login"; return;}
        let result = await API.usage.getUsageTotal(api_key);
        setTotals({
            interviews: result['interviews'],
            candidates: result['candidates']
        });
    }

    useEffect(()=>{
        fetchData();
    }, []);

    return (
        <div className="usageTotalsContainer">
            <h1>Usage Totals</h1>
            <h4>System Usage Totals</h4>
            <div>Interviews:  { totals.interviews}</div>
            <div>Candidates: { totals.candidates }</div>
        </div>
    );
}