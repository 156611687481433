import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import './STTURLComponent.css';
import FontSelector from '../FontSelector/FontSelector';
import ColorSelector from '../ColorSelector/ColorSelector';

const STT_SERVER_URL = 'https://stt.clipdrop.io:9090/download'; // download accepts json config and file_url

export interface STTURLProps {
    videoURL:string;
    onCompletion: (newVideoURL:string) => void; // Call once subtitle-ing has been completed
    onReject: () => void; // call when user cancels this process or rejects the video that was subtitled
}

export default function STTURLComponent(props:STTURLProps){
    // States
    const [ currentState, setCurrentState ] = useState<string>("options");
    const [ formData, setFormData ] = useState<FormData>(new FormData());
    const [ fileURL, setFileURL ] = useState<string | null>(props.videoURL);
    const [ fontSizes, setFontSizes ] = useState<number[]>([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72]);
    const [ fontSize, setFontSize ] = useState<number>(16);
    const [ fontName, setFontName ] = useState<string>("Arial");
    const [ primaryColor, setPrimaryColor ] = useState<string>("#FFFFFF");
    const [ outlineColor, setOutlineColor ] = useState<string>("#000000");
    const [ outputVideoURL, setOutputVideoURL ] = useState<string>("");
    // Defaults
    const defaultStyles = {
        fontFamily: fontName,
        fontSize: `${fontSize}pt`,
        color: primaryColor,
        textShadow: `1px 1px 1px ${outlineColor}`,
    };
    
    // Refs
    const fontPreviewRef = useRef<HTMLSpanElement | null>(null);
    
    // Functions
    const hexColorToASS = (hexColor:string):string => {
        hexColor = hexColor.replace('#', '');
        const red = parseInt(hexColor.substr(0, 2), 16);
        const green = parseInt(hexColor.substr(2, 2), 16);
        const blue = parseInt(hexColor.substr(4, 2), 16);
        const assFormat = `&H${blue.toString(16).padStart(2, '0').toUpperCase()}${green.toString(16).padStart(2, '0').toUpperCase()}${red.toString(16).padStart(2, '0').toUpperCase()}&`;
        return assFormat;
    }
    
    // Effects

    useEffect(()=>{
        if(!fontPreviewRef) return;
        if(!fontPreviewRef.current) return;
        fontPreviewRef.current.style.fontFamily = fontName;
        fontPreviewRef.current.style.fontSize = `${fontSize}pt`;
        fontPreviewRef.current.style.color = primaryColor;
        fontPreviewRef.current.style.textShadow = `1px 1px 1px ${outlineColor}`;
    }, [fontName, fontSize, primaryColor, outlineColor, fontPreviewRef]);
    
    // Renders
    return <div className="sst-component-url">
        {
            (fileURL && currentState === "options") && <div className="options-2">
                <div>
                    <ul className='file-info-details'>
                        <li><strong>Actions</strong>&nbsp;&nbsp;
                            <button className='red' onClick={ e => {
                                props.onReject();                            
                            }}>Cancel</button>
                            <button onClick={ async e => {
                                const FormatData = {
                                    FontName: fontName.replace(" ", "-"), // minor fix for now
                                    FontSize: fontSize,
                                    PrimaryColour: hexColorToASS(primaryColor),
                                    OutlineColour: hexColorToASS(outlineColor),
                                };
                                formData.append('file_url', fileURL);
                                formData.append('json', JSON.stringify(FormatData));
                                const config = {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                };
                                
                                setCurrentState("processing");
                                axios.post(STT_SERVER_URL, formData, config)
                                .then(response => {
                                    if (response.status !== 200) {
                                        setCurrentState("error");
                                        return null;
                                    }
                                    return response.data;
                                })
                                .then(data => {
                                    if(!data){
                                        setCurrentState("error");
                                        return null;
                                    }
                                    setOutputVideoURL(data);
                                    setCurrentState("preview");
                                })
                                .catch(error => {
                                    setCurrentState("error");
                                    console.error('There was a problem with the fetch operation:', error);
                                });
                            }}>Confirm</button>
                        </li>
                    </ul>
                    <h4>Subtitle Options</h4>
                    <ul className="font-details-2">
                        <li><strong>Font Name</strong>&nbsp;<FontSelector isInline={true} onChange={setFontName} selected={fontName}/>
                        </li>
                        <li><strong>Font Size</strong>&nbsp;<select onChange={ e => setFontSize(Number(e.target.value))} value={fontSize}>
                            {
                                fontSizes.map((fontSizeVal:number, index:number)=>{
                                    return <option key={`font-size-${fontSizeVal}-${index}`} value={fontSizeVal}>{`${fontSizeVal}`}</option>
                                })
                            }
                        </select></li>
                        <li><strong>Primary Color</strong>&nbsp;<ColorSelector isInline={true} onChange={setPrimaryColor} value={primaryColor}/></li>
                        <li><strong>Outline Color</strong>&nbsp;<ColorSelector isInline={true} onChange={setOutlineColor} value={outlineColor}/></li>
                    </ul>
                </div>
                <div className='font-details-preview-container'>
                    <h4>Preview</h4>
                    <div className='font-details-preview'>
                        <span ref={fontPreviewRef} style={defaultStyles}>Hello and Welcome</span>
                    </div>
                </div>
            </div>
        }        
        {
            currentState === "processing" && <div className="processing">
                <h4>Processing, please wait</h4>
                <p>Do not close this browser tab</p>
            </div>
        }         
        {
            currentState === "error" && <div className="processing">
                <h4>An error occurred</h4>
                <p>Your video may not have any audio or speech</p>
                <div>
                    <button onClick={ e => {
                        props.onReject();
                    }}>Close</button>
                </div>
            </div>
        }   
        {
            currentState === "preview" && <div className="preview-2">
                <h4>Processing Completed</h4>
                <video controls={true} playsInline={true} autoPlay={true} src={outputVideoURL} id="player"></video>
                <div>
                    <button className="red" onClick={ e => {
                        setOutputVideoURL("");
                        setCurrentState("options");
                    }}>Reject Video</button>
                    <button onClick={ e => {
                        const player = document.getElementById("player");
                        if(player){
                            try { (player as HTMLVideoElement).pause(); } catch (e) {}
                        }
                        props.onCompletion(outputVideoURL);
                    }}>Accept Video</button>
                </div> 
            </div>
        }
    </div>
}