const DEBUG_MODE = false; // true ? test-api : api
const LOCAL_DEBUG_MODE = false; // localhost

const API = {
  baseURL: DEBUG_MODE
    ? (LOCAL_DEBUG_MODE ? "http://localhost:8000" : "https://test-api.clipdrop.io")
    : "https://api.clipdrop.io",
  sleep: (milliseconds) => {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  },
  validation: {
    verifyURL: (url) => {
      // no url is valid
      if (url == "" || url == undefined || url == null) return true;
      // check actuall url
      var pattern = new RegExp(
        "^(https?:\\/\\/)" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(url);
    },
  },
  email: async (api_key, email_address, subject, body2) => {
    // returns exists as int
    let url = API.baseURL + "/api/v1/email/send";
    let body = JSON.stringify({
      api_key: api_key,
      email_address: email_address,
      subject: subject,
      body: body2,
    });
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: body,
    });
    let jdata = response.json();
    return jdata;
  },
  surveys: {
    list: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/surveys/list";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  pricing: {
    getLocation: async (api_key, ip) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/pricing/location/get";
      let body = JSON.stringify({
        api_key: api_key,
        ip: ip,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    list: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/pricing/list";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    get: async (key, default_value) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/pricing/get";
      let body = JSON.stringify({
        key: key,
        default_value: default_value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getBulk: async (keys, default_value) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/pricing/get/bulk";
      let body = JSON.stringify({
        keys: keys,
        default_value: default_value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    update: async (api_key, key, value) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/pricing/update";
      let body = JSON.stringify({
        api_key: api_key,
        key: key,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateBulk: async (api_key, pairs) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/pricing/update/bulk";
      let body = JSON.stringify({
        api_key: api_key,
        pairs: pairs,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  subscription: {
    paygate: {
      purchase: async (api_key) => {
        // returns exists as int
        let url = API.baseURL + "/api/v1/pay/paygate/purchase";
        let body = JSON.stringify({
          api_key: api_key,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
    paddle: {
      purchase: async (api_key) => {
        // returns exists as int
        let url = API.baseURL + "/api/v1/pay/paddle/purchase";
        let body = JSON.stringify({
          api_key: api_key,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
  },
  payflow: {
    getPayPayURL: (api_key) => {
      return API.baseURL + "/api/v1/payflow/paypal/start?api_key=" + api_key;
    },
    // check if email is in use
    needsToPay: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/payflow/needs_to_pay";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  answers: {
    getAll: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/answers/get";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  user: {
    getReward: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/claim/reward";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    onTrialEnd: async (
      api_key,
      package_number,
      package_duration,
      hasMarketing
    ) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/upgrade/account/from/trial";
      let body = JSON.stringify({
        api_key: api_key,
        package_number: package_number,
        package_duration: package_duration,
        has_marketing: hasMarketing,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setAffiliateOption: async (api_key, prefersStorage, prefersRedeem) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/affilate/set/options";
      let body = JSON.stringify({
        api_key: api_key,
        prefersStorage: prefersStorage,
        prefersRedeem: prefersRedeem,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setEmailSignature: async (api_key, html) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/email/signature/set";
      let body = JSON.stringify({
        api_key: api_key,
        html: html,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getEmailSignature: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/email/signature/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    seats: {
      setPersonolisedStorage: async (api_key, user_id, space_value) => {
        // returns exists as int
        let url = API.baseURL + "/api/v1/user/seats/storage/set";
        let body = JSON.stringify({
          api_key: api_key,
          user_id: user_id,
          space_value: space_value,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
    // check if email is in use
    updateStorageUsage: async (api_key, value) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/storage/usage/set";
      let body = JSON.stringify({
        api_key: api_key,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    setPassword: async (api_key, password) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/password/set";
      let body = JSON.stringify({
        api_key: api_key,
        password: password,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    checkEmailExists: async (emailAddress) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/exists";
      let body = JSON.stringify({
        email: emailAddress,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getAllOtherUsers: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/get/others";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    checkPhoneNumberExists: async (phone_number) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/user/exists/phone";
      let body = JSON.stringify({
        phone_number: phone_number,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    registerUser: async (company, fullname, email, password, phone_number) => {
      // returns api_key as string or false if invalid
      let url = API.baseURL + "/api/v1/user/register";
      let body = JSON.stringify({
        company: company,
        name: fullname,
        email: email,
        password: password,
        phone_number: phone_number,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    registerUserStep2: async (api_key, promocode) => {
      // returns success as boolean or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/register/2";
      let body = JSON.stringify({
        api_key: api_key,
        promocode: promocode,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    registerUserStep3: async (
      api_key,
      packageNumber,
      duration,
      marketing,
      promocode
    ) => {
      // returns success as boolean also (sends verification code) or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/register/3";
      let body = JSON.stringify({
        api_key: api_key,
        package: packageNumber,
        duration: duration,
        marketing: marketing,
        promocode: promocode,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    resendConfirmationCode: async (api_key, packageNumber, duration) => {
      // returns success as boolean also (sends verification code) or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/register/resend";
      let body = JSON.stringify({
        api_key: api_key,
        package: packageNumber,
        duration: duration,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    registerUserStep4: async (api_key, code) => {
      // returns success as boolean or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/register/4";
      let body = JSON.stringify({
        api_key: api_key,
        code: code,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    loginUser: async (email, password) => {
      // returns api_key as string or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/login";
      let body = JSON.stringify({
        email: email,
        password: password,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    login2FA: async (temp_api, code) => {
      // returns api_key as string or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/2fa";
      let body = JSON.stringify({
        temp_api: temp_api,
        code: code,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    loginAsUser: async (api_key, user_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/impersonate";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    sendUserPasswordReminder: async (api_key, user_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/remind/password";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: parseInt(user_id),
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    sendUserPasswordReminderEmail: async (email) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/remind/password/email";
      let body = JSON.stringify({
        email: email,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    uploadUserWaterMark: async (api_key, user_id, watermark_url) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/update/watermark";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
        watermark_url: watermark_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    deleteUser: async (api_key, user_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/delete";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getUser: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getUserBalance: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/get/balance";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    createUser: async (api_key, user_data) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/create";
      let body = JSON.stringify({
        api_key: api_key,
        user_data: user_data,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    createUserFromOrg: async (api_key, user_data, org_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/create";
      let body = JSON.stringify({
        api_key: api_key,
        user_data: user_data,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateUserBasic: async (api_key, user_data) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/update";
      let body = JSON.stringify({
        api_key: api_key,
        user_data: user_data,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getUserByID: async (api_key, user_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/getByID";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    isDisabled: async (api_key) => {
      // returns disabled as int
      let url = API.baseURL + "/api/v1/user/disabled";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getUserWatermark: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/get/watermark";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setUserWatermark: async (api_key, watermark_url) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/set/watermark";
      let body = JSON.stringify({
        api_key: api_key,
        watermark_url: watermark_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setLinkedInAuth: async (
      api_key,
      linkedin_user_access_token,
      linkedin_user_id
    ) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/set/linkedin/auth";
      let body = JSON.stringify({
        api_key: api_key,
        linkedin_user_access_token: linkedin_user_access_token,
        linkedin_user_id: linkedin_user_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setFacebookAuth: async (
      api_key,
      user_id,
      access_token,
      data_access_expiration_time
    ) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/set/facebook/auth";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
        access_token: access_token,
        expiry_date: data_access_expiration_time,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getTotalUsers: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/user/count/all";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  linkedin: {
    uploadVideo: async (
      api_key,
      video_url,
      access_bearer_token,
      user_urn_id
    ) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/linkedin/uploadVideo";
      let body = JSON.stringify({
        api_key: api_key,
        video_url: video_url,
        access_bearer_token: access_bearer_token,
        user_urn_id: user_urn_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    makePost: async (
      api_key,
      title,
      media_id,
      linkedin_user_id,
      linkedin_user_access_token
    ) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/linkedin/createUGCPost";
      let body = JSON.stringify({
        api_key: api_key,
        title: title,
        media_id: media_id,
        linkedin_user_id: linkedin_user_id,
        linkedin_user_access_token: linkedin_user_access_token,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  org: {
    getAllOrgData: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/list/all";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },

    createOrg: async (api_key, org_name, parent_id, must_pay) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/create";
      let body = JSON.stringify({
        api_key: api_key,
        org_name: org_name,
        parent_id: parent_id,
        must_pay: must_pay,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getOrg: async (api_key, org_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/get";
      let body = JSON.stringify({
        api_key: api_key,
        org_id: org_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateOrg: async (api_key, org_id, org_name, must_pay) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/update";
      let body = JSON.stringify({
        api_key: api_key,
        org_id: org_id,
        org_name: org_name,
        must_pay: must_pay,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateOrgCustomCandidateIntroVideo: async (api_key, org_id, custom_candidate_intro_video_url) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/update/intro";
      let body = JSON.stringify({
        api_key: api_key,
        org_id: org_id,
        custom_candidate_intro_video_url: custom_candidate_intro_video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getAllOrgDataByQuery: async (api_key, q) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/search";
      let body = JSON.stringify({
        api_key: api_key,
        q: q,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    deleteOrg: async (api_key, org_id) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/org/delete";
      let body = JSON.stringify({
        api_key: api_key,
        org_id: org_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  activities: {
    listActivities: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/activities/list";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },

    getActivities: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/activities/list";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  storage: {
    getMyStorage: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/storage/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  usage: {
    getUsageTotal: async (api_key) => {
      // returns user as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/usage/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  automanage: {
    marketing: {
      remove: {
        byTime: async (api_key, hours, interview, bespoke, outreach) => {
          // returns user as object or false if invalid api_key
          let url = API.baseURL + "/api/v1/automanage/marketing/remove/by/time";
          let body = JSON.stringify({
            api_key: api_key,
            hours: hours,
            interview: interview,
            bespoke: bespoke,
            outreach: outreach,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
      },
    },
    vacancy: {
      remove: {
        byTime: async (api_key, hours, interview, bespoke, outreach) => {
          // returns user as object or false if invalid api_key
          let url = API.baseURL + "/api/v1/automanage/vacancy/remove/by/time";
          let body = JSON.stringify({
            api_key: api_key,
            hours: hours,
            interview: interview,
            bespoke: bespoke,
            outreach: outreach,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        byRating: async (api_key, rating) => {
          // returns user as object or false if invalid api_key
          let url = API.baseURL + "/api/v1/automanage/vacancy/remove/by/ratng";
          let body = JSON.stringify({
            api_key: api_key,
            rating: rating,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        byInvitation: async (api_key, hours) => {
          // returns user as object or false if invalid api_key
          let url =
            API.baseURL + "/api/v1/automanage/vacancy/remove/by/invitation";
          let body = JSON.stringify({
            api_key: api_key,
            hours: hours,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
      },
    },
  },
  file: {
    uploadFileAsFile: async (api_key, extension, file) => {
      const formData = new FormData();
      formData.append("api_key", api_key);
      formData.append("extension", extension);
      formData.append("file", file);
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/file/uploadAsFile";
      console.log("formData1", formData.entries());
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: formData,
      });
      let jdata = response.json();
      return jdata;
    },
    uploadFile: async (api_key, extension, data) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/file/upload";
      let body = JSON.stringify({
        api_key: api_key,
        extension: extension,
        data: data,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    uploadFileAsFileWithProgress: async (
      api_key,  
      extension,
      file,
      progressCallback,
    ) => {
      // returns file_url as object or false if invalid api_key
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("extension", extension);
        formData.append("api_key", api_key);
        let url = API.baseURL + "/api/v1/file/uploadAsFile";
        let request = new XMLHttpRequest();
        request.open("POST", url, true);
        request.upload.addEventListener("progress", (e) => {
          if (e.lengthComputable) {
            const progress = (e.loaded / e.total) * 100;
            progressCallback(progress);
          } else {
            // Handle non-computable progress if needed
            console.log("file-upload: Cannot compute progress");
          }
        });
        request.addEventListener("load", (e) => {
          if (request.status == 200 || request.status == "200") {
            let data_in = request.response;
            let file_url = data_in["file_url"];
            resolve(file_url);
          } else {
            resolve(false);
          }
        });
        request.addEventListener("error", () => {
          console.error("Network error occurred");
          resolve(false);
        });
        request.responseType = "json";
        request.send(formData);
      });
    },
    uploadFileWithProgress: async (
      api_key,
      extension,
      data,
      progressCallback
    ) => {
      // returns file_url as object or false if invalid api_key
      return new Promise((resolve, reject) => {
        let url = API.baseURL + "/api/v1/file/upload";
        let body = JSON.stringify({
          api_key: api_key,
          extension: extension,
          data: data,
        });
        let request = new XMLHttpRequest();
        request.open("POST", url);
        request.upload.addEventListener("progress", (e) => {
          progressCallback((e.loaded / e.total) * 100);
        });
        request.addEventListener("load", (e) => {
          if (request.status == 200 || request.status == "200") {
            let data_in = request.response;
            let file_url = data_in["file_url"];
            resolve(file_url);
          } else {
            resolve(false);
          }
        });
        request.setRequestHeader("Content-type", "application/json");
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.responseType = "json";
        request.send(body);
      });
    },
  },
  transaction: {
    chart: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/transaction/chart";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    overview: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/transaction/overview";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    misc: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/transaction/misc";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  templates: {
    list: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/list";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    get: async (api_key, template_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/get";
      let body = JSON.stringify({
        api_key: api_key,
        id: template_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    isTextOrVideo: async (api_key, template_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/template/questions/istextorvideo";
      let body = JSON.stringify({
        api_key: api_key,
        template_id: template_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    removeQuestion: async (api_key, template_id, index) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/question/remove";
      let body = JSON.stringify({
        api_key: api_key,
        id: template_id,
        index: index,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    addQuestion: async (api_key, template_id, uploaded_file_url, duration) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/template/question/add";
      let body = JSON.stringify({
        api_key: api_key,
        template_id: template_id,
        uploaded_file_url: uploaded_file_url,
        duration: duration,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    addQuestionText: async (api_key, template_id, text, duration) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/template/question/add/text";
      let body = JSON.stringify({
        api_key: api_key,
        template_id: template_id,
        text: text,
        duration: duration,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setITJ: async (api_key, template_id, url2, field) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/itj/set";
      let body = JSON.stringify({
        api_key: api_key,
        id: template_id,
        url: url2,
        field: field,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    save: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/save";
      let body = JSON.stringify({
        api_key: api_key,
        id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    delete: async (api_key, template_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/delete";
      let body = JSON.stringify({
        api_key: api_key,
        id: template_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    use: async (api_key, template_id, title) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/use";
      let body = JSON.stringify({
        api_key: api_key,
        id: template_id,
        title: title,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    useastemplate: async (api_key, interview_id, title) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/templating/useastemplate";
      let body = JSON.stringify({
        api_key: api_key,
        id: interview_id,
        title: title,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  interview: {
    markVideo: async (
      interview_or_marketing,
      selected_folder,
      selected_id,
      state = 1
    ) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/set/mark";
      let body = JSON.stringify({
        interview_or_marketing: interview_or_marketing,
        selected_folder: selected_folder,
        selected_id: selected_id,
        state: state,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    startInteractivity: async (api_key, interview_id, folder, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/start/interactivity";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        folder: folder,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSurveyEnabled: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/settings/has/assessment";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSurveyValue: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/settings/set/assessment";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setJobBoardDetails: async (
      api_key,
      id,
      logo,
      title,
      description,
      company,
      location,
      currency,
      min,
      max,
      ats,
      interview,
      video_url,
      enabled
    ) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/board/set";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
        logo: logo,
        title: title,
        description: description,
        company: company,
        location: location,
        currency: currency,
        min: min,
        max: max,
        ats: ats,
        interview: interview,
        video_url: video_url,
        enabled: enabled,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setQuestionOrder: async (api_key, interview_id, question_ids) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/question/set/question/order";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        question_ids: question_ids,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getHasCopied: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/hasCopied";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    AdvertiseCandidateLoad: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/setadvertiseload";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    list: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/list";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    listUsers: async (api_key, user_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/list/user";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    listOthers: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/list/others";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    listArchived: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/list/archived";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getSeats: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/users";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSeats: async (api_key, interview_id, shared_user_ids) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/users/set";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        shared_user_ids: shared_user_ids,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    delete: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/delete";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    update: async (api_key, interview_id, title) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/update";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        title: title,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    deleteAll: async (api_key, interview_ids) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/delete/ids";
      let body = JSON.stringify({
        api_key: api_key,
        interview_ids: interview_ids,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    archive: async (api_key, interview_ids) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/archive";
      let body = JSON.stringify({
        api_key: api_key,
        interview_ids: interview_ids,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    unarchive: async (api_key, interview_ids) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/unarchive";
      let body = JSON.stringify({
        api_key: api_key,
        interview_ids: interview_ids,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getSocialLink: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/social/get/link";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setBespokeThumbnail: async (api_key, interview_id, file_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/bespoke/set/thumbnail";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        url: file_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setOutReachVideo: async (api_key, interview_id, file_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/outreach/set/video";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        url: file_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setOutReachThumbnail: async (api_key, interview_id, file_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/outreach/set/thumbnail";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        url: file_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSocialThumbnail: async (api_key, interview_id, file_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/social/set/thumbnail";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        url: file_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setBespokeAllowCandidateInterivew: async (
      api_key,
      interview_id,
      type_value
    ) => {
      // returns file_url as object or false if invalid api_key
      let url =
        API.baseURL + "/api/v1/interview/bespoke/set/allowcandidateinterview";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        type_value: type_value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setOutReachAllowCandidateInterivew: async (
      api_key,
      interview_id,
      type_value
    ) => {
      // returns file_url as object or false if invalid api_key
      let url =
        API.baseURL + "/api/v1/interview/outreach/set/allowcandidateinterview";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        type_value: type_value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomThumbnailCustomText: async (api_key, interview_id, text) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/thumbnailtext";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        text: text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomThumbnailType: async (api_key, interview_id, type_value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/thumbnailtype";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        type_value: type_value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomThumbnailOutreachType: async (
      api_key,
      interview_id,
      type_value
    ) => {
      // returns file_url as object or false if invalid api_key
      let url =
        API.baseURL + "/api/v1/interview/custom/set/thumbnailoutreachtype";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        type_value: type_value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomMarketingText: async (api_key, interview_id, text) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/marketingtext";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        text: text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setBulkMarketingText: async (api_key, interview_id, text) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/bulktext";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        text: text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setBulkMarketingCaptionText: async (api_key, interview_id, text) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/bulkcaptiontext";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        text: text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setBulkMarketingVideo: async (api_key, interview_id, video_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/bulkvideo";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        video_url: video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSocialMarketingText: async (api_key, interview_id, text) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/socialtext";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        text: text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSocialMarketingSocial: async (api_key, interview_id, text) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/socialsocial";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        text: text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSocialMarketingVideo: async (api_key, interview_id, video_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/custom/set/socialvideo";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        video_url: video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateRedirectionLink: async (api_key, interview_id, urlx) => {
      // returns file_url as object or false if invalid api_key
      let url =
        API.baseURL + "/api/v1/interview/custom/set/socialredirectionlink";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        url: urlx,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getNotice: async (api_key) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/notice";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    create: async (api_key, title) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/create";
      let body = JSON.stringify({
        api_key: api_key,
        title: title,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    get: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/get";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    hasTextOrVideoQuestions: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/hastextorvideoquestions";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setITJVideo: async (api_key, interview_id, company, team, job, culture, evp) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/setitj";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        company: company,
        team: team,
        job: job,
        culture: culture,
        evp: evp,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },

    setITJBespokeAllow: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/bespoke/set/allow/itj";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setSelfSignUp: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/selfsignup/set";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setATSBespokeAllow: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/bespoke/set/allow/ats";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setATSBespokeText: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/bespoke/set/text/ats";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },

    setATSOutreachAllow: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/outreach/set/allow/ats";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setATSOutreachText: async (api_key, interview_id, value) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/interview/outreach/set/text/ats";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        value: value,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  questions: {
    getByID: async (api_key, question_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/question/getByID";
      let body = JSON.stringify({
        api_key: api_key,
        id: question_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateByID: async (api_key, question_id, value, time) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/question/updateByID";
      let body = JSON.stringify({
        api_key: api_key,
        id: question_id,
        value: value,
        time: time
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateVideoByID: async (api_key, question_id, value, time) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/question/updateVideoByID";
      let body = JSON.stringify({
        api_key: api_key,
        id: question_id,
        value: value,
        time: time
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    get: async (api_key, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/question/get";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    delete: async (api_key, question_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/question/delete";
      let body = JSON.stringify({
        api_key: api_key,
        question_id: question_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    create: async (api_key, duration, type, text, video_url, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/question/create";
      let body = JSON.stringify({
        api_key: api_key,
        duration: duration,
        type: type,
        text: text,
        video_url: video_url,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  labels: {
    library: {
      create: async (api_key, title, video_url, campaign_id) => {
        // returns file_url as object or false if invalid api_key
        let url = API.baseURL + "/api/v1/labels/library/create";
        let body = JSON.stringify({
          api_key: api_key,
          title: title,
          video_url: video_url,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      getAll: async (api_key, campaign_id) => {
        // returns file_url as object or false if invalid api_key
        let url = API.baseURL + "/api/v1/labels/library/getAll";
        let body = JSON.stringify({
          api_key: api_key,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
    create: async (api_key, title, video_url, campaign_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/labels/create";
      let body = JSON.stringify({
        api_key: api_key,
        title: title,
        video_url: video_url,
        campaign_id: campaign_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getAll: async (api_key, campaign_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/labels/getAll";
      let body = JSON.stringify({
        api_key: api_key,
        campaign_id: campaign_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    exists: async (api_key, title, campaign_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/labels/exists";
      let body = JSON.stringify({
        api_key: api_key,
        title: title,
        campaign_id: campaign_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    get: async (api_key, id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/labels/get";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    delete: async (api_key, id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/labels/delete";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    update: async (api_key, id, video_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/labels/update";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
        video_url: video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  candidate: {
    getAllAnswers: async (api_key, candidate_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/get/answers";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setProcessing: async (api_key, candidate_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/tag/progress/set";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    emailInvitationLink: async (api_key, candidate_id, subject) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/email/invitation/link";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        subject: subject,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    emailInvitationLink2: async (api_key, candidate_id, subject) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/email/invitation/link2";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        subject: subject,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    emailInvitationLink3: async (
      api_key,
      candidate_id,
      subject,
      custom_text
    ) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/email/invitation/link3";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        subject: subject,
        custom_text: custom_text,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomThumbnail: async (api_key, candidate_id, file_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/setcustomthumbnail";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        file_url: file_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getBespokeInvitation: async (api_key, candidate_id, wa = 0) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getbespokeinvitation";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        wa: wa,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getInviteLink: async (api_key, candidate_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getinvitelink";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    sendInviteLink: async (api_key, candidate_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/sendinvitelink";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getOutReachInviteLink: async (api_key, candidate_id, interview_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getoutreachinvitaion";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        interview_id: interview_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    create: async (
      api_key,
      interview_id,
      name,
      email,
      linkedin,
      phone,
      marketing_type,
      processing = 1,
      dont_queue = 0,
    ) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/create";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        name: name,
        email: email,
        linkedin_url: linkedin,
        phone: phone,
        marketing_type: marketing_type,
        processing: processing,
        dont_queue: dont_queue
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    update: async (api_key, candidate_id, name, email, linkedin, phone) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/update";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        name: name,
        email: email,
        linkedin_url: linkedin,
        phone: phone,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updateCV: async (api_key, candidate_id, cv_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/updatecv";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        cv_url: cv_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    delete: async (api_key, candidate_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/delete";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    deleteAll: async (api_key, candidate_ids) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/deleteall";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_ids: candidate_ids,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getAll: async (api_key, interview_id, page, hasRating, hasNoRating, hasRecordedVideos, hasNoRecording) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getall";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        page: parseInt(page),
        hasRating: hasRating ? true : false,
        hasNoRating: hasNoRating ? true : false,
        hasRecordedVideos: hasRecordedVideos ? true : false,
        hasNoRecording: hasNoRecording ? true : false
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    get: async (api_key, candidate_id) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/get";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getSearch: async (api_key, interview_id, q, page, hasRating, hasNoRating, hasRecordedVideos, hasNoRecording, hasAssessment, hasNoAssessment, sortByAssessment) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getsearch";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        q: q,
        page: parseInt(page),
        hasRating: hasRating ? true : false,
        hasNoRating: hasNoRating ? true : false,
        hasRecordedVideos: hasRecordedVideos ? true : false,
        hasNoRecording: hasNoRecording ? true : false,
        hasAssessment: hasAssessment ? true : false,
        hasNoAssessment: hasNoAssessment ? true : false,
        sortByAssessment: sortByAssessment ? true : false
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getBespoke: async (api_key, interview_id, page) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getbespoke";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        page: parseInt(page)
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getBespokeSearch: async (api_key, interview_id, q, page) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getbespokesearch";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        q: q,
        page: parseInt(page)
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getOutReach: async (api_key, interview_id, page) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getoutreach";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        page: parseInt(page)
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getOutReachSearch: async (api_key, interview_id, q, page) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/getoutreachsearch";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        q: q,
        page: parseInt(page)
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomVideo: async (api_key, candidate_id, video_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/setcustomvideo";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        video_url: video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomVideo2: async (api_key, candidate_id, video_url, gif_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/setcustomvideo2";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        video_url: video_url,
        marketing_thumbnail: gif_url
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    setCustomVideo3: async (api_key, candidate_id, gif_url) => {
      // returns file_url as object or false if invalid api_key
      let url = API.baseURL + "/api/v1/candidate/setcustomvideo3";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        marketing_thumbnail: gif_url
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  review: {
    // check if email is in use
    get: async (api_key, candidate_id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/review/get";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    createRating: async (api_key, question_id, candidate_id, ranking) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/review/rank";
      let body = JSON.stringify({
        api_key: api_key,
        question_id: question_id,
        candidate_id: candidate_id,
        ranking: ranking,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    clearAllRatings: async (api_key, interview_id, candidate_id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/review/clear";
      let body = JSON.stringify({
        api_key: api_key,
        interview_id: interview_id,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  seats: {
    // check if email is in use
    get: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/seats/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    getMetrics: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/seats/getmetrics";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    create: async (api_key, name, email, phone, password) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/seats/create";
      let body = JSON.stringify({
        api_key: api_key,
        name: name,
        email: email,
        phone: phone,
        password: password,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    impersonate: async (api_key, user_id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/seats/impersonate";
      let body = JSON.stringify({
        api_key: api_key,
        user_id: user_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  share: {
    // check if email is in use
    get: async (api_key, candidate_id, interview_id, name, email) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/share/get";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        interview_id: interview_id,
        name: name,
        email: email,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    email: async (api_key, token) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/share/email";
      let body = JSON.stringify({
        api_key: api_key,
        token: token,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  shareAndRate: {
    // check if email is in use
    get: async (api_key, candidate_id, interview_id, name, email) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/share/rate/get";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        interview_id: interview_id,
        name: name,
        email: email,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  comments: {
    // check if email is in use
    get: async (api_key, candidate_id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/comments/get";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  answer: {
    // check if email is in use
    get: async (api_key, candidate_id, question_id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/answer/get";
      let body = JSON.stringify({
        api_key: api_key,
        candidate_id: candidate_id,
        question_id: question_id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  promoters: {
    // check if email is in use
    get: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/promoters/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getResellers: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/promoters/resellers/get";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getResellersSearch: async (api_key, code) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/promoters/resellers/getsearch";
      let body = JSON.stringify({
        api_key: api_key,
        code: code,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getResellersSearch2: async (api_key, code) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/promoters/resellers/getsearch2";
      let body = JSON.stringify({
        api_key: api_key,
        code: code,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  payment: {
    // check if email is in use
    getNewPaymentLink: async (api_key, package_number, duration) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/payment/generate/payment/link";
      let body = JSON.stringify({
        api_key: api_key,
        package_number: package_number,
        duration: duration,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  library: {
    // check if email is in use
    add: async (api_key, itj_type, title, video_url) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/library/add";
      let body = JSON.stringify({
        api_key: api_key,
        itj_type: itj_type,
        title: title,
        video_url: video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getAll: async (api_key, itj_type, title, video_url) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/library/getall";
      let body = JSON.stringify({
        api_key: api_key,
        itj_type: itj_type,
        title: title,
        video_url: video_url,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    getSocial: async (api_key) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/library/getsocial";
      let body = JSON.stringify({
        api_key: api_key,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    get: async (api_key, id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/library/get";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    delete: async (api_key, id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/library/delete";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  tutorial: {
    enum: {
      0: "Dashboard",
      1: "Metrics",
      2: "My Profile",
      3: "Organisation",
      4: "Seats",
      5: "Vacancies",
      6: "Vacancy",
    },
    enum_extended: [
      {
        id: 0,
        name: "Dashboard",
      },
      {
        id: 1,
        name: "Metrics",
      },
      {
        id: 2,
        name: "My Profile",
      },
      {
        id: 3,
        name: "Organisation",
      },
      {
        id: 4,
        name: "Seats",
      },
      {
        id: 5,
        name: "Vacancies",
      },
      {
        id: 6,
        name: "Vacancy",
      },
      {
        id: 7,
        name: "Marketing",
      },
      {
        id: 8,
        name: "Marketing View",
      },
    ],
    // check if email is in use
    createPageTutorialItem: async (
      api_key,
      tutorial_parent_id,
      tutorial_type,
      element_selector,
      text,
      image_url,
      auto_click
    ) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/create/page/item";
      let body = JSON.stringify({
        api_key: api_key,
        tutorial_parent_id: tutorial_parent_id,
        tutorial_type: tutorial_type,
        element_selector: element_selector,
        text: text,
        image_url: image_url,
        auto_click: auto_click,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updatePageTutorialItem: async (
      api_key,
      id,
      tutorial_type,
      element_selector,
      text,
      image_url,
      auto_click
    ) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/update/page/item";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
        tutorial_type: tutorial_type,
        element_selector: element_selector,
        text: text,
        image_url: image_url,
        auto_click: auto_click,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    deletePageTutorialItem: async (api_key, id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/delete/page/item";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    createPageTutorial: async (
      api_key,
      page_number,
      title,
      description,
      element
    ) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/create/page";
      let body = JSON.stringify({
        api_key: api_key,
        page_number: page_number,
        title: title,
        description: description,
        element_selector: element,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    updatePageTutorial: async (api_key, id, title, description, element) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/update/page";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
        title: title,
        description: description,
        element_selector: element,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    deletePageTutorial: async (api_key, id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/delete/page";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    getPage: async (api_key, page_number) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/get/page";
      let body = JSON.stringify({
        api_key: api_key,
        page_number: page_number,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    getItems: async (api_key, id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/get/items";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    getItem: async (api_key, id) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/get/item";
      let body = JSON.stringify({
        api_key: api_key,
        id: id,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    getAllText: async (api_key, page_number) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/get/all/text";
      let body = JSON.stringify({
        api_key: api_key,
        page_number: page_number,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
    // check if email is in use
    getAllVideo: async (api_key, page_number) => {
      // returns exists as int
      let url = API.baseURL + "/api/v1/tutorial/get/all/video";
      let body = JSON.stringify({
        api_key: api_key,
        page_number: page_number,
      });
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: body,
      });
      let jdata = response.json();
      return jdata;
    },
  },
  marketing: {
    leads: {
      getBespokeLeadsFromIDs: async (api_key, ids) => {
        let url = API.baseURL + "/api/v1/marketing/lead/getFromIDs";
        let body = JSON.stringify({
          api_key: api_key,
          ids: ids,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      email: async (api_key, id, subject, text, link) => {
        let url = API.baseURL + "/api/v1/marketing/lead/email";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          subject: subject,
          text: text,
          link: link,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      delete: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/lead/delete";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      deleteAll: async (api_key, ids) => {
        let url = API.baseURL + "/api/v1/marketing/lead/deleteall";
        let body = JSON.stringify({
          api_key: api_key,
          ids: ids,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      search: {
        bespoke: async (api_key, id, q) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/lead/search/bespoke";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            q: q,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        campaign: async (api_key, id, q) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/lead/search/campaign";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            q: q,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
      },
      setCustomVideo: async (api_key, id, video_url) => {
        let url = API.baseURL + "/api/v1/marketing/lead/set/video";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          video_url: video_url,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      setCustomVideo2: async (api_key, id, video_url) => {
        let url = API.baseURL + "/api/v1/marketing/lead/set/video2";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          video_url: video_url,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      setCustomVideoThumbnail: async (api_key, id, marketing_thumbnail_url) => {
        let url = API.baseURL + "/api/v1/marketing/lead/set/thumbnail";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          marketing_thumbnail_url: marketing_thumbnail_url,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      create: async (
        api_key,
        name,
        email,
        phone,
        linkedin_url,
        marketing_id,
        marketingType_id
      ) => {
        let url = API.baseURL + "/api/v1/marketing/lead/create";
        let body = JSON.stringify({
          api_key: api_key,
          name: name,
          email: email,
          phone: phone,
          linkedin_url: linkedin_url,
          marketing_id: marketing_id,
          marketingType_id: marketingType_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      create2: async (
        api_key,
        name,
        email,
        phone,
        linkedin_url,
        marketing_id,
        marketingType_id
      ) => {
        let url = API.baseURL + "/api/v1/marketing/lead/create2";
        let body = JSON.stringify({
          api_key: api_key,
          name: name,
          email: email,
          phone: phone,
          linkedin_url: linkedin_url,
          marketing_id: marketing_id,
          marketingType_id: marketingType_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      list: async (api_key, marketing_id, type_id) => {
        let url = API.baseURL + "/api/v1/marketing/lead/list";
        let body = JSON.stringify({
          api_key: api_key,
          marketing_id: marketing_id,
          type_id: type_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      get: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/lead/get";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      getBespokeLink: async (api_key, campaign_id, lead_id) => {
        let url = API.baseURL + "/api/v1/marketing/lead/invite/get/bespoke";
        let body = JSON.stringify({
          api_key: api_key,
          campaign_id: campaign_id,
          lead_id: lead_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      getMarketingLink: async (api_key, campaign_id, lead_id) => {
        let url = API.baseURL + "/api/v1/marketing/lead/invite/get/marketing";
        let body = JSON.stringify({
          api_key: api_key,
          campaign_id: campaign_id,
          lead_id: lead_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      getSocialLink: async (api_key, campaign_id) => {
        let url = API.baseURL + "/api/v1/marketing/lead/invite/get/social";
        let body = JSON.stringify({
          api_key: api_key,
          campaign_id: campaign_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
    campaign: {
      getAnalytics: async (api_key, id, size) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/analytics";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          size: size,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      getAnalytics2: async (api_key, id, size) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/analytics2";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          size: size,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      addSendRate: async (api_key, id, folder_id) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/add/sent";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          folder_id: folder_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      addSetOpens: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/set/opens";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      addSendRateVacancy: async (api_key, id, folder_id) => {
        let url = API.baseURL + "/api/v1/vacancy/add/sent";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          folder_id: folder_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      addSetOpensVacancy: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/vacancy/set/opens";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      setMarketingVideo: async (api_key, id, video_url) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/set/video";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          video_url: video_url,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      setCustomThumbnailType: async (api_key, id, value) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/set/thumbnailtype";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          value: value,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      setCustomThumbnailText: async (api_key, id, text) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/set/thumbnailtext";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          text: text,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      social: {
        setVideo: async (api_key, id, video_url) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/social/marketing/video";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            video_url: video_url,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setThumbnail: async (api_key, id, image_url) => {
          let url =
            API.baseURL +
            "/api/v1/marketing/campaign/social/marketing/thumbnail";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            image_url: image_url,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setText: async (api_key, id, text) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/social/marketing/text";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            text: text,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setCaption: async (api_key, id, text) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/social/marketing/caption";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            text: text,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
      },
      campaign: {
        setVideo: async (api_key, id, video_url) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/campaign/marketing/video";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            video_url: video_url,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setVideo2: async (api_key, id, video_url) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/campaign/marketing/video2";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            video_url: video_url,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setText: async (api_key, id, text) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/campaign/marketing/text";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            text: text,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
      },
      bespoke: {
        setThubmanailOption: async (api_key, id, option) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/bespoke/thumbnail/option";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            option: option,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setThubmanailText: async (api_key, id, text) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/bespoke/thumbnail/text";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            text: text,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setMarketingText: async (api_key, id, text) => {
          let url =
            API.baseURL + "/api/v1/marketing/campaign/bespoke/marketing/text";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            text: text,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
        setMarketingOurWebsite: async (api_key, id, text) => {
          let url =
            API.baseURL +
            "/api/v1/marketing/campaign/bespoke/marketing/ourwebsite";
          let body = JSON.stringify({
            api_key: api_key,
            id: id,
            text: text,
          });
          const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: { "Content-Type": "application/json" },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: body,
          });
          let jdata = response.json();
          return jdata;
        },
      },
      getLeads: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/leads";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      create: async (api_key, title) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/create";
        let body = JSON.stringify({
          api_key: api_key,
          title: title,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      edit: async (api_key, id, title) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/edit";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
          title: title,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      delete: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/delete";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      get: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/get";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      list: async (api_key, id) => {
        let url = API.baseURL + "/api/v1/marketing/campaign/list";
        let body = JSON.stringify({
          api_key: api_key,
          id: id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
  },
  paypal: {
    plans: {
      list: async (api_key, page_number = 1) => {
        let url = API.baseURL + "/api/v1/paypal/plans/list";
        let body = JSON.stringify({
          api_key: api_key,
          page_number: page_number,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      get: async (api_key, plan_id) => {
        let url = API.baseURL + "/api/v1/paypal/plans/get";
        let body = JSON.stringify({
          api_key: api_key,
          plan_id: plan_id,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      create: async (api_key) => {
        let url = API.baseURL + "/api/v1/paypal/plans/create";
        let body = JSON.stringify({
          api_key: api_key,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      activate: async (api_key) => {
        let url = API.baseURL + "/api/v1/paypal/plans/activate";
        let body = JSON.stringify({
          api_key: api_key,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
      deactivate: async (api_key) => {
        let url = API.baseURL + "/api/v1/paypal/plans/deactivate";
        let body = JSON.stringify({
          api_key: api_key,
        });
        const response = await fetch(url, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "no-referrer",
          body: body,
        });
        let jdata = response.json();
        return jdata;
      },
    },
  },
};

export default API;
