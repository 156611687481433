import React, { useEffect, useRef, useState } from "react";
import API from './../services/api';
import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import './introvideomanager.css';
import { toast, ToastContainer } from "react-toastify";
import SimplePagination from "../components/simple-pagination/SimplePagination";
import { ImBin } from "react-icons/im";
import { AiFillPlayCircle, AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import useAbortableAxios from "../hooks/useAbortableAxios";
import axios from "axios";
import VideoUploadComponent from "../components/videoupload";
import VideoRecordComponent from "../components/videorecord";

export default function IntroVideoManager(){
    const [ showFileRecordComponent, setShowFileRecordComponent] = useState<boolean>(false);
    const [ showFileRecord, setShowFileRecord] = useState<boolean>(false);
    const [ showFileUpload, setShowFileUpload] = useState<boolean>(false);
    const [ showFileUploadTitle, setShowFileUploadTitle] = useState<boolean>(false);
    const [ showFileURL, setShowFileURL] = useState<string>("");
    const [ previewVideo, setPreviewVideo] = useState<string | false>(false);
    const [ addVideoType, setAddVideoType] = useState<number | false>(false);
    const [ addVideoTypeNumber, setAddVideoTypeNumber] = useState<number>(0);
    const [ addVideoTitle, setAddVideoTitle] = useState<string>("");
    const [ companyVideos, setCompanyVideos] = useState<any[]>([]);
    const [ companyPageNumber, setCompanyPageNumber] = useState<number>(0);
    const [ companyTotalPageNumber, setCompanyTotalPageNumber] = useState<number>(0);

    const [ teamVideos, setTeamVideos] = useState<any[]>([]);
    const [ teamPageNumber, setTeamPageNumber] = useState<number>(0);
    const [ teamTotalPageNumber, setTeamTotalPageNumber] = useState<number>(0);

    const [ jobVideos, setJobVideos] = useState<any[]>([]);
    const [ jobPageNumber, setJobPageNumber] = useState<number>(0);
    const [ jobTotalPageNumber, setJobTotalPageNumber] = useState<number>(0);

    const [ cultureVideos, setCultureVideos] = useState<any[]>([]);
    const [ culturePageNumber, setCulturePageNumber] = useState<number>(0);
    const [ cultureTotalPageNumber, setCultureTotalPageNumber] = useState<number>(0);

    const [ evpVideos, setEVPVideos] = useState<any[]>([]);
    const [ evpPageNumber, setEVPPageNumber] = useState<number>(0);
    const [ evpTotalPageNumber, setEVPTotalPageNumber] = useState<number>(0);

    
    const { loading:loadingCompany, data:dataCompany, error:errorCompany, fetchData: fetchDataCompany } = useAbortableAxios();
    const { loading:loadingTeam, data:dataTeam, error:errorTeam, fetchData: fetchDataTeam } = useAbortableAxios();
    const { loading:loadingJob, data:dataJob, error:errorJob, fetchData: fetchDataJob } = useAbortableAxios();
    const { loading:loadingCulture, data:dataCulture, error:errorCulture, fetchData: fetchDataCulture } = useAbortableAxios();
    const { loading:loadingEVP, data:dataEVP, error:errorEVP, fetchData: fetchDataEVP } = useAbortableAxios();

    const videoRecordRef = useRef<any>(null);

    
    const [ showIntroduction, setShowIntroduction] = useState<boolean>(false);
    const [ showTeam, setShowTeam] = useState<boolean>(false);
    const [ showJob, setShowJob] = useState<boolean>(false);
    const [ showCulture, setShowCulture] = useState<boolean>(false);
    const [ showEVP, setShowEVP] = useState<boolean>(false);

    const loadCompanyVideos = async () => {
        await fetchDataCompany(API.baseURL + "/api/v1/intro/manager/get", {}, {
            "api_key": localStorage.getItem("api_key"),
            "library_type": 0,
            "page": companyPageNumber,
        }, "post");
    }
    
    const loadTeamVideos = async () => {
        await fetchDataTeam(API.baseURL + "/api/v1/intro/manager/get", {}, {
            "api_key": localStorage.getItem("api_key"),
            "library_type": 1,
            "page": teamPageNumber,
        }, "post");
    }
    
    const loadJobVideos = async () => {
        await fetchDataJob(API.baseURL + "/api/v1/intro/manager/get", {}, {
            "api_key": localStorage.getItem("api_key"),
            "library_type": 2,
            "page": jobPageNumber,
        }, "post");
    }
    
    const loadCultureVideos = async () => {
        await fetchDataCulture(API.baseURL + "/api/v1/intro/manager/get", {}, {
            "api_key": localStorage.getItem("api_key"),
            "library_type": 3,
            "page": culturePageNumber,
        }, "post");
    }
    
    const loadEVPVideos = async () => {
        await fetchDataEVP(API.baseURL + "/api/v1/intro/manager/get", {}, {
            "api_key": localStorage.getItem("api_key"),
            "library_type": 4,
            "page": evpPageNumber,
        }, "post");
    }

    useEffect(()=>{
        if(!dataCompany) return;
        setCompanyVideos((dataCompany as any).results);
        setCompanyTotalPageNumber(parseInt((dataCompany as any).total_pages))
    }, [dataCompany]);
    
    useEffect(()=>{
        if(!dataTeam) return;
        setTeamVideos((dataTeam as any).results);
        setTeamTotalPageNumber(parseInt((dataTeam as any).total_pages))
    }, [dataTeam]);
    
    useEffect(()=>{
        if(!dataJob) return;
        setJobVideos((dataJob as any).results);
        setJobTotalPageNumber(parseInt((dataJob as any).total_pages))
    }, [dataJob]);
    
    useEffect(()=>{
        if(!dataCulture) return;
        setCultureVideos((dataCulture as any).results);
        setCultureTotalPageNumber(parseInt((dataCulture as any).total_pages))
    }, [dataCulture]);
    
    useEffect(()=>{
        if(!dataEVP) return;
        setEVPVideos((dataEVP as any).results);
        setEVPTotalPageNumber(parseInt((dataEVP as any).total_pages))
    }, [dataEVP]);

    useEffect(()=>{
        loadCompanyVideos();
        loadTeamVideos();
        loadJobVideos();
        loadCultureVideos();
        loadEVPVideos();
    }, []);

    return <>
        <div className="dashboardContainer">
            <SideBarComponent></SideBarComponent>
            <div className="dashboarContent">
                <UpperBarComponent></UpperBarComponent>
                <div className="pageNameInfo">
                    <span>Intro Video Manager</span>
                </div>
                <div className="intro-manager-container">
                    <div className="intro-manager-section">
                        <h4><button onClick={ e => setShowIntroduction( x => !x)}>{ showIntroduction ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/> }</button>&nbsp;Company Introduction Videos</h4>
                        { showIntroduction && <><div>
                            <SimplePagination onChange={(val)=>{
                                toast("Loading");
                                setCompanyPageNumber(val);
                                loadCompanyVideos();
                            }} totalPages={companyTotalPageNumber} currentPage={companyPageNumber}/>
                        </div><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width:'200px' }}>
                                    <span className="dropdown" style={{display:'inline-block'}}>
                                            <button id="vacancies_actions">Actions</button>
                                            <label>
                                                <input type="checkbox" />
                                                <ul>
                                                    <li style={{fontWeight:400, color:'#000'}} onClick={async ()=>{
                                                        setAddVideoType(1);
                                                    }}>+ Add Video to Library</li>
                                                </ul>
                                            </label>
                                        </span>&nbsp;&nbsp;
                                        Preview
                                    </th>
                                    <th>Title</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    companyVideos && companyVideos.map((v, i)=>{
                                        return <tr key={v.id}>
                                            <td style={{ width:'200px' }}>
                                                <video className="small-mini-video" src={v.video_url} autoPlay={false} playsInline controls={false}></video>
                                            </td>
                                            <td>{ v.title }</td>
                                            <td>
                                                <button title="Show Video Preview" onClick={ e => {
                                                    setPreviewVideo(v.video_url);
                                                }}><AiFillPlayCircle/></button>
                                                <button title="Delete This Entry" onClick={ async e => {
                                                    toast("Loading");
                                                    let api_key = localStorage.getItem("api_key") ?? "";
                                                    let response = await axios.post(API.baseURL + "/api/v1/intro/manager/delete", {
                                                        api_key: api_key,
                                                        id: v.id
                                                    });
                                                    loadCompanyVideos();
                                                }}><ImBin/></button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </>}
                    </div>
                    <div className="intro-manager-section">
                        <h4><button onClick={ e => setShowTeam( x => !x)}>{ showTeam ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/> }</button>&nbsp;Meet The Team Videos</h4>
                        { showTeam && <><div>
                            <SimplePagination onChange={(val)=>{
                                toast("Loading");
                                setTeamPageNumber(val);
                                loadTeamVideos();
                            }} totalPages={teamTotalPageNumber} currentPage={companyPageNumber}/>
                        </div><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width:'200px' }}>
                                    <span className="dropdown" style={{display:'inline-block'}}>
                                            <button id="vacancies_actions">Actions</button>
                                            <label>
                                                <input type="checkbox" />
                                                <ul>
                                                    <li style={{fontWeight:400, color:'#000'}} onClick={async ()=>{
                                                        setAddVideoType(1);
                                                    }}>+ Add Video to Library</li>
                                                </ul>
                                            </label>
                                        </span>&nbsp;&nbsp;
                                        Preview
                                    </th>
                                    <th>Title</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    teamVideos && teamVideos.map((v, i)=>{
                                        return <tr key={v.id}>
                                            <td style={{ width:'200px' }}>
                                                <video className="small-mini-video" src={v.video_url} autoPlay={false} playsInline controls={false}></video>
                                            </td>
                                            <td>{ v.title }</td>
                                            <td>
                                                <button title="Show Video Preview" onClick={ e => {
                                                    setPreviewVideo(v.video_url);
                                                }}><AiFillPlayCircle/></button>
                                                <button title="Delete This Entry" onClick={ async e => {
                                                    toast("Loading");
                                                    let api_key = localStorage.getItem("api_key") ?? "";
                                                    let response = await axios.post(API.baseURL + "/api/v1/intro/manager/delete", {
                                                        api_key: api_key,
                                                        id: v.id
                                                    });
                                                    loadTeamVideos();
                                                }}><ImBin/></button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </>}
                    </div>
                    <div className="intro-manager-section">
                        <h4><button onClick={ e => setShowJob( x => !x)}>{ showJob ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/> }</button>&nbsp;Job Overview Videos</h4>
                        { showJob && <><div>
                            <SimplePagination onChange={(val)=>{
                                toast("Loading");
                                setJobPageNumber(val);
                                loadJobVideos();
                            }} totalPages={jobTotalPageNumber} currentPage={jobPageNumber}/>
                        </div><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width:'200px' }}>
                                    <span className="dropdown" style={{display:'inline-block'}}>
                                            <button id="vacancies_actions">Actions</button>
                                            <label>
                                                <input type="checkbox" />
                                                <ul>
                                                    <li style={{fontWeight:400, color:'#000'}} onClick={async ()=>{
                                                        setAddVideoType(1);
                                                    }}>+ Add Video to Library</li>
                                                </ul>
                                            </label>
                                        </span>&nbsp;&nbsp;
                                        Preview
                                    </th>
                                    <th>Title</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    jobVideos && jobVideos.map((v, i)=>{
                                        return <tr key={v.id}>
                                            <td style={{ width:'200px' }}>
                                                <video className="small-mini-video" src={v.video_url} autoPlay={false} playsInline controls={false}></video>
                                            </td>
                                            <td>{ v.title }</td>
                                            <td>
                                                <button title="Show Video Preview" onClick={ e => {
                                                    setPreviewVideo(v.video_url);
                                                }}><AiFillPlayCircle/></button>
                                                <button title="Delete This Entry" onClick={ async e => {
                                                    toast("Loading");
                                                    let api_key = localStorage.getItem("api_key") ?? "";
                                                    let response = await axios.post(API.baseURL + "/api/v1/intro/manager/delete", {
                                                        api_key: api_key,
                                                        id: v.id
                                                    });
                                                    loadJobVideos();
                                                }}><ImBin/></button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </>}
                    </div>
                    <div className="intro-manager-section">
                        <h4><button onClick={ e => setShowCulture( x => !x)}>{ showCulture ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/> }</button>&nbsp;Culture Videos</h4>
                        { showCulture && <><div>
                            <SimplePagination onChange={(val)=>{
                                toast("Loading");
                                setCulturePageNumber(val);
                                loadCultureVideos();
                            }} totalPages={cultureTotalPageNumber} currentPage={culturePageNumber}/>
                        </div><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width:'200px' }}>
                                    <span className="dropdown" style={{display:'inline-block'}}>
                                            <button id="vacancies_actions">Actions</button>
                                            <label>
                                                <input type="checkbox" />
                                                <ul>
                                                    <li style={{fontWeight:400, color:'#000'}} onClick={async ()=>{
                                                        setAddVideoType(1);
                                                    }}>+ Add Video to Library</li>
                                                </ul>
                                            </label>
                                        </span>&nbsp;&nbsp;
                                        Preview
                                    </th>
                                    <th>Title</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    cultureVideos && cultureVideos.map((v, i)=>{
                                        return <tr key={v.id}>
                                            <td style={{ width:'200px' }}>
                                                <video className="small-mini-video" src={v.video_url} autoPlay={false} playsInline controls={false}></video>
                                            </td>
                                            <td>{ v.title }</td>
                                            <td>
                                                <button title="Show Video Preview" onClick={ e => {
                                                    setPreviewVideo(v.video_url);
                                                }}><AiFillPlayCircle/></button>
                                                <button title="Delete This Entry" onClick={ async e => {
                                                    toast("Loading");
                                                    let api_key = localStorage.getItem("api_key") ?? "";
                                                    let response = await axios.post(API.baseURL + "/api/v1/intro/manager/delete", {
                                                        api_key: api_key,
                                                        id: v.id
                                                    });
                                                    loadCultureVideos();
                                                }}><ImBin/></button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </>}
                    </div>
                    <div className="intro-manager-section">
                        <h4><button onClick={ e => setShowEVP( x => !x)}>{ showEVP ? <AiOutlineArrowUp/> : <AiOutlineArrowDown/> }</button>&nbsp;Employee Value Proposition Videos</h4>
                        { showEVP && <><div>
                            <SimplePagination onChange={(val)=>{
                                toast("Loading");
                                setEVPPageNumber(val);
                                loadEVPVideos();
                            }} totalPages={evpTotalPageNumber} currentPage={evpPageNumber}/>
                        </div><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width:'200px' }}>
                                    <span className="dropdown" style={{display:'inline-block'}}>
                                            <button id="vacancies_actions">Actions</button>
                                            <label>
                                                <input type="checkbox" />
                                                <ul>
                                                    <li style={{fontWeight:400, color:'#000'}} onClick={async ()=>{
                                                        setAddVideoType(1);
                                                    }}>+ Add Video to Library</li>
                                                </ul>
                                            </label>
                                        </span>&nbsp;&nbsp;
                                        Preview
                                    </th>
                                    <th>Title</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    evpVideos && evpVideos.map((v, i)=>{
                                        return <tr key={v.id}>
                                            <td style={{ width:'200px' }}>
                                                <video className="small-mini-video" src={v.video_url} autoPlay={false} playsInline controls={false}></video>
                                            </td>
                                            <td>{ v.title }</td>
                                            <td>
                                                <button title="Show Video Preview" onClick={ e => {
                                                    setPreviewVideo(v.video_url);
                                                }}><AiFillPlayCircle/></button>
                                                <button title="Delete This Entry" onClick={ async e => {
                                                    toast("Loading");
                                                    let api_key = localStorage.getItem("api_key") ?? "";
                                                    let response = await axios.post(API.baseURL + "/api/v1/intro/manager/delete", {
                                                        api_key: api_key,
                                                        id: v.id
                                                    });
                                                    loadEVPVideos();
                                                }}><ImBin/></button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        </>}
                    </div>
                </div>
            </div>
        </div>    
        { previewVideo && <div className="dialogNew extend intro-video-manager-popup">        
            <div>
                <h4>Video Preview</h4>
                <video src={previewVideo} playsInline controls autoPlay></video>
                <button onClick={()=>{
                    setPreviewVideo(false);
                }}>Close</button>
            </div>
        </div>}    
        { addVideoType && <div className="dialogNew extend intro-video-manager-popup">        
            <div>
                <h4>Add Video to Selected library</h4>
                <p>Select an option on how to add a video to this library</p>
                <button onClick={()=>{
                    setAddVideoTypeNumber(addVideoType);
                    setAddVideoType(false);
                    setShowFileUpload(true);
                }}>Upload</button>
                <button onClick={()=>{
                    setAddVideoTypeNumber(addVideoType);
                    setAddVideoType(false);
                    setShowFileRecord(true);
                    setShowFileRecordComponent(true);
                    setTimeout(()=>{        
                        if(!videoRecordRef.current) return;           
                        videoRecordRef.current.resetUI();
                        videoRecordRef.current.openVideo();
                    }, 1000);
                }}>Record</button>
                <br/>
                <button onClick={()=>{
                    setAddVideoType(false);
                }}>Close</button>
            </div>
        </div>}
        {
            showFileUpload && <div className="dialogNew extend intro-video-manager-popup">        
            <div>
                <h4>Upload Video to library</h4>
                <p>Select an file to be added this library</p>
                <VideoUploadComponent onreject={ ()=>{

                }} onclose={()=>{
                    setShowFileURL("");
                    setShowFileUploadTitle(false);
                    setShowFileUpload(false);
                }} onaccept={(file_url)=>{
                    setShowFileURL(file_url);
                    setShowFileUploadTitle(true);
                }} watermark_url={""}/>
                {
                    showFileUploadTitle && <>
                        <h4>Title</h4>
                        <input onChange={ e => setAddVideoTitle(e.target.value)} style={{width:'calc(100% - 28px)', padding:'12px'}} type="text" placeholder="Enter Title Here"/>
                        <button onClick={ async e => {
                            if(!addVideoTitle || addVideoTitle.length == 0 || addVideoTitle === ""){
                                toast("Title is required");
                                return;
                            }
                            toast("Loading");                            
                            setShowFileURL("");
                            setShowFileUploadTitle(false);
                            setShowFileUpload(false);
                            let api_key = localStorage.getItem("api_key") ?? "";
                            let response = await axios.post(API.baseURL + "/api/v1/intro/manager/add", {
                                api_key: api_key,
                                video_url: showFileURL,
                                title: addVideoTitle,
                                video_type: addVideoTypeNumber - 1
                            });
                            toast("Loading");
                            loadCompanyVideos();
                            loadTeamVideos();
                            loadJobVideos();
                            loadCultureVideos();
                            loadEVPVideos();
                        }}>Add to library</button>
                        <button onClick={ e =>{
                            setShowFileURL("");
                            setShowFileUploadTitle(false);
                            setShowFileUpload(false);
                        }}>Cancel</button>
                    </>
                }
            </div>
        </div>
        }
        {
            showFileRecord && <div className="dialogNew extend intro-video-manager-popup">        
            <div>
                <h4>Record Video for the library</h4>
                { showFileRecordComponent && <VideoRecordComponent ref={videoRecordRef} fixWidth={true} onclose={()=>{
                    setShowFileURL("");
                    setShowFileUploadTitle(false);
                    setShowFileRecord(false);
                }} oncompletion={(file_url)=>{
                    setShowFileURL(file_url);
                    setShowFileUploadTitle(true);
                    setShowFileRecordComponent(false);
                }}></VideoRecordComponent>}
                {
                    showFileUploadTitle && <>
                        <h4>Title</h4>
                        <input onChange={ e => setAddVideoTitle(e.target.value)} style={{width:'calc(100% - 28px)', padding:'12px'}} type="text" placeholder="Enter Title Here"/>
                        <button onClick={ async e => {
                            if(!addVideoTitle || addVideoTitle.length == 0 || addVideoTitle === ""){
                                toast("Title is required");
                                return;
                            }
                            toast("Loading");                            
                            setShowFileURL("");
                            setShowFileUploadTitle(false);
                            setShowFileRecord(false);
                            let api_key = localStorage.getItem("api_key") ?? "";
                            let response = await axios.post(API.baseURL + "/api/v1/intro/manager/add", {
                                api_key: api_key,
                                video_url: showFileURL,
                                title: addVideoTitle,
                                video_type: addVideoTypeNumber - 1
                            });
                            toast("Loading");
                            loadCompanyVideos();
                            loadTeamVideos();
                            loadJobVideos();
                            loadCultureVideos();
                            loadEVPVideos();
                        }}>Add to library</button>
                        <button onClick={ e =>{
                            setShowFileURL("");
                            setShowFileUploadTitle(false);
                            setShowFileUpload(false);
                        }}>Cancel</button>
                    </>
                }
            </div>
        </div>
        }
    </>
}