import React, { forwardRef, useImperativeHandle, useRef, useState  } from "react";

import './videoUploadOrLibraryOrRecord.css';
import VideoUploadComponent from "../../components/videoupload";
import VideoRecordComponent from "../../components/videorecord";
import InBrowserRecorderAndProcessingComponent from "../../components/inBrowserRecorderAndProcessingComponent/InBrowserRecorderAndProcessingComponent";
import InBrowserRecorderAndProcessingComponentV2 from "../../components/inBrowserRecorderAndProcessingComponentV2/InBrowserRecorderAndProcessingComponentV2";

interface VideoUploadOrLibraryOrRecordProps {
    apiKey: string;
    user:any;
    onChange: (video_url: any) => void;
    libraryVideos: any[];
}

const VideoUploadOrLibraryOrRecord = forwardRef((props:VideoUploadOrLibraryOrRecordProps, ref) => {
    // Props
    const { onChange, libraryVideos, apiKey } = props;
    // States
    const [ showComponent, setShowComponent ] = useState<boolean>(false);
    const [ stateOption, setStateOption ] = useState<string>("menu");
    const [ stateOptionData, setStateOptionData ] = useState<any>(null);
    // Refs
    const videoRecordElementRef = useRef<any>(null);
    // Functions to expose
    const open = () => {
        setStateOption("menu");
        setShowComponent(true);
    };
    const close = () => {
        // TODO: RELEASE ALL RESOURCES AND CLOSE THIS COMPONENT
        setStateOption("menu");
        setShowComponent(false);
    };
    const handleCloseStateChange = () => {
        if(stateOption === "menu"){ close(); return;}
        if(stateOption === "upload"){ setStateOption("menu"); return;}
        if(stateOption === "record"){ setStateOption("menu"); return;}
        if(stateOption === "record-2"){ setStateOption("menu"); return;}
        if(stateOption === "library"){ setStateOption("menu"); return;}
        if(stateOption === "library_preview"){ setStateOptionData(null); setStateOption("library"); return;}
        close(); // else close, default case
    }
    // Bind Exposed Functions to forwardedRef Parent component's ref
    useImperativeHandle(ref, () => ({
        open,
        close,
    }));
    
    // Return Render
    // => if disabled, just hide the entire component
    if(!showComponent) return <></>
    // => else render the entire component (this should be treated as a popup)
    return <div className="custom-popup-dialog">
        <div>
            <div className="header">
                <h4>Add/Edit Video</h4>
                <button onClick={ e => {
                    handleCloseStateChange();
                }}>X</button>
            </div>
            <div className="content">
                <div className="video-upload-or-library-or-record-component">
                    { stateOption === "menu" && <div className="buttons">
                        <button onClick={ e => {setStateOption("upload");}}>Upload File</button>
                        <button onClick={ e => {
                            console.log("new recorder");
                            setStateOption("record-2");
                        }}>Record</button>
                        <button onClick={ e => {setStateOption("library");}}>Select From Library</button>
                    </div>}
                    { stateOption === "upload" && <VideoUploadComponent onreject={handleCloseStateChange} onclose={handleCloseStateChange} onaccept={(video_url:string)=>{onChange(video_url)}} watermark_url={""} />}
                    { stateOption === "record-2" && <InBrowserRecorderAndProcessingComponentV2 apiKey={apiKey} clientWatermarkImage={props.user ? props.user.watermark_url : ""} onClose={handleCloseStateChange} onCompletion={(video_url:string)=>{onChange(video_url);}}/>
                    }
                    { stateOption === "library" && <div className="library-videos">
                        { (libraryVideos && libraryVideos.length >= 1) && libraryVideos.map((libraryVideo:any, index:number)=>{
                            return <div onClick={ e => {
                                setStateOptionData({title: libraryVideo.title, video_url: libraryVideo.video_url});
                                setStateOption("library_preview");
                            }} key={index}>
                                <video src={libraryVideo.video_url}></video>
                                <h4>{libraryVideo.title}</h4>
                            </div>
                        })}
                    </div>}
                    { (stateOption === "library_preview" && stateOptionData) && <div className="library-video-preview">
                        <h4>{stateOptionData?.title}</h4>
                        <video src={stateOptionData?.video_url} autoPlay={true} loop={false} controls={true} width={"480px"} height={"480px"}></video>
                        <button onClick={ e => {
                            onChange(stateOptionData?.video_url);
                            close();
                        }}>Use this video</button>
                        <button onClick={ e => {
                            handleCloseStateChange();
                        }}>Go Back</button>
                    </div>}
                </div>
            </div>
        </div>
    </div>
});
export default VideoUploadOrLibraryOrRecord;