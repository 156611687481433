import React, { useEffect, useRef, useState } from 'react';
import { base64ToBlob, blobToBase64 } from 'base64-blob';
import { ReactMediaRecorder } from "react-media-recorder";
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

import './InBrowserRecorderAndProcessingComponentV2.css';
import API from '../../services/api';
import IndexedDBFileStorage from '../inBrowserRecorderAndProcessingComponent/IndexedDBFileStorage';
import Permissions from '../inBrowserRecorderAndProcessingComponent/Permissions';

interface InBrowserRecorderAndProcessingComponentV2Props {
    apiKey: string;
    clientWatermarkImage: string;
    onClose: () => void;
    onCompletion: (video_url:string) => void;
}

const VideoPreview = ({ stream, setRef }:any) => {
    const videoRef = useRef(null);
    useEffect(() => {
      if (videoRef.current && stream) {
        (videoRef.current as any).src = stream;
        (videoRef.current as any).srcObject = stream;
      }
    }, [stream as any]);
    if (!stream) {
      return (
          <>Loading</>
      );
    }
    return <video ref={videoRef} style={{width:"100%", height:'300px', borderRadius:'8px'}} autoPlay={true} playsInline={true} controlsList="nodownload" muted={true}/>;
  };

export default function InBrowserRecorderAndProcessingComponentV2(props:InBrowserRecorderAndProcessingComponentV2Props){
    // State
    const debug = false;
    const [ currentState, setCurrentState ] = useState<string>("loading");
    const [ currentSubState, setCurrentSubState ] = useState<string>("");
    const [ stream, setStream ] = useState<MediaStream | null>(null);
    const [ isReady, setIsReady ] = useState<boolean>(false);
    const [ randomFileName, setRandomFileName ] = useState<string>(IndexedDBFileStorage.getRandomFileName(12));
    const [ uploadedURL, setUploadedURL ] = useState<string>("");
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const mimetype = useRef<any>(null);
    const startTimeRef = useRef<Date>(new Date());
    const counterRef = useRef<any>();
    const startRecordingRef = useRef<any>(()=>{});
    const stopRecordingRef = useRef<any>(()=>{});
    const startButton = useRef<any>(null);
    const stopButton = useRef<any>(null);
    const restartButton = useRef<any>(null);
    const ffmpegRef = useRef(new FFmpeg());
    const dbRef = useRef(new IndexedDBFileStorage("recorded_file_data", 1));
    const backgroundImageRef = useRef<[number, number, string]>([0, 0, ""]);
    const clipdropWatermarkImageRef = useRef<[number, number, string]>([0, 0, ""]);
    const clientWatermarkImageRef = useRef<[number, number, string]>([0, 0, ""]);
    const clipdropWatermarkImageURL = "https://storage.googleapis.com/clipdrop-prod/secure/powered-by-clipdrop-io.png?avoidCache=1";
    const backgroundImageURL = "https://storage.googleapis.com/clipdrop-prod/secure/background.png";
    // Functions
    const getImageAsBlob = async (image_url:string):Promise<Blob |  null> => {
        return new Promise((resolve)=>{
            fetch(image_url)
            .then(response => {
                if (!response.ok) {
                    console.error('There was a problem fetching the image');
                    resolve(null);
                }
                return response.blob();
            })
            .then(blob => {
                resolve(blob);
            })
            .catch(error => {
                console.error('There was a problem fetching the image:', error);
                resolve(null);
            });
        });
    }
    const getImageAndNameSize = async (image_url:string):Promise<[number, number, string]> => {
        let filename = "";
        let width = 0;
        let height = 0;
        if(image_url && image_url != "" && image_url.length >= 1){
            let result = await (new Promise((resolve)=>{
                var img = new Image();
                img.onload = function() {
                    width = img.naturalWidth;
                    height = img.naturalHeight;
                    resolve(true);
                };
                img.src = image_url;
            }))
            console.log("Result: ", result);
        }
        if (image_url !== "" && image_url.length > 1) {
            if (image_url.includes("?")) {
                let url_parts = image_url.split("?");
                image_url = url_parts[0];
            }
        }
        filename = image_url.substring(image_url.lastIndexOf("/") + 1);
        return [width, height, filename];
    }
    const loadRecord = async () => {
        await Permissions.wait(1);
        setCurrentState("record");
    }
    const loadStream = async () => {
        try{
            const stream_ = await Permissions.getRecordingStream({
                audio: true,
                video: {
                    width: { ideal: 320 },
                    height: { ideal: 320 },
                    frameRate: { ideal: 15 },
                    facingMode: "user"
                }
            });
            setStream(stream_);
        } catch(e){}
    }
    const getAllSupportedMimeTypes = (...mediaTypes:any[]) => {
        if (!mediaTypes.length) mediaTypes.push(...['video', 'audio']);
        const FILE_EXTENSIONS = ['webm', 'mp4'];
        const CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'h265', 'h.265', 'h264', 'h.264', 'opus'];        
        let x:string[] = [];
        for(let a=0;a<mediaTypes.length;a++){
            let mediaType = mediaTypes[a];
            for(let b=0;b<FILE_EXTENSIONS.length;b++){
                let ext = FILE_EXTENSIONS[b];
                for(let c=0;c<CODECS.length;c++){        
                    let codec = CODECS[c];
                    x.push(`${mediaType}/${ext};codecs:${codec}`);
                    x.push(`${mediaType}/${ext};codecs=${codec}`);
                    x.push(`${mediaType}/${ext};codecs:${codec.toUpperCase()}`);
                    x.push(`${mediaType}/${ext};codecs=${codec.toUpperCase()}`);
                    x.push(`${mediaType}/${ext}`);
                }
            }
        }
        x = x.filter(variation => MediaRecorder.isTypeSupported(variation))
        return x;
    }
    const getBaseMimeType = () => {
        let m = getAllSupportedMimeTypes('video')[0];
        if(m.includes(";")){
            m = m.split(";")[0];
            return m;
        } else {
            return m;
        }
    }
    const stopStream = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        }
    }
    const startVideoRecording = async () => {   
        if(startButton.current) startButton.current.style.display = "none";
        if(counterRef.current) counterRef.current.style.display = "block";
        if(counterRef.current) counterRef.current.innerHTML = 3;
        await Permissions.wait(1);
        if(counterRef.current) counterRef.current.innerHTML = 2;
        await Permissions.wait(1);
        if(counterRef.current) counterRef.current.innerHTML = 1;
        await Permissions.wait(1);
        if(counterRef.current) counterRef.current.innerHTML = 0;
        if(counterRef.current) counterRef.current.style.display = "none";     
        if(restartButton.current) restartButton.current.style.display = "inline-block";
        if(stopButton.current) stopButton.current.style.display = "inline-block";
        startTimeRef.current = new Date();
        if(startRecordingRef.current) startRecordingRef.current();
    }
    const saveVideoRecordingFromBlob = async (blob:Blob) => {
        if(restartButton.current) restartButton.current.style.display = "none";
        if(stopButton.current) stopButton.current.style.display = "none";
        let data = await blobToBase64(blob);
        if(!dbRef.current){
            alert("Cannot access database");
            return;
        }
        try {
            let deleteFile = await dbRef.current.removeFile(randomFileName);
        } catch(e){
            console.log("ERROR DELETING FILE");
        }
        let saved = false;
        let elapsedTimeInSeconds:number = ((new Date()).getTime() - startTimeRef.current.getTime()) / 1000;
        while(saved === false){
            try {
                saved = await dbRef.current.addFile({
                    id: randomFileName,
                    data: data,
                    mimeType: getBaseMimeType(),
                    duration: elapsedTimeInSeconds
                });    
                console.log("saved", saved);
            } catch (e){
                console.log("SAVING VIDEO ERROR", e);
            }
        }
        if(stream){
            try {
                Permissions.stopRecordingStream(stream);
            } catch (e){}
        }
        setCurrentState("compress-and-upload");
    }
    const fromBlobToURL = (blob:any) => {
        return URL.createObjectURL(blob);
    }
    const fromURLToBlob = async (url: string):Promise<any> => {
        try {
          const response = await fetch(url);  
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
          }  
          const blob = await response.blob();
          const base64String = await blobToBase64(blob);
          return base64String;
        } catch (error) {
          console.error('Download failed:', error);
          throw error;
        }
    }
    const toBase64 = (file:any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const loadFFMPEG = async () => {
        let openned = await dbRef.current.openDatabase();
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        const coreURL = `${baseURL}/ffmpeg-core.js`;
        const wasmURL = `${baseURL}/ffmpeg-core.wasm`;
        let core:any = null;
        try{
            core = await dbRef.current.getFile("core");
        } catch (e) {
            console.log("XE1", e);  
        }
        let coreData = null;
        if(core){
            coreData = (await base64ToBlob(core.data)) as any;
        }
        if(!coreData){
            coreData = await fromURLToBlob(coreURL);
            let savedCore = await dbRef.current.addFile({
                id: "core",
                data: coreData,
                mimeType: "text/javascript"
            }); 
        } 
        try{
            core = await dbRef.current.getFile("core");
        } catch (e) {
            console.log("XE1", e);  
        }
        if(core){
            coreData = (await base64ToBlob(core.data)) as any;
        }
        let wasm:any = null; 
        try{
            wasm = await dbRef.current.getFile("wasm");
        } catch (e) {
            console.log("XE2", e);  
        }
        let wasmData:any = null;    
        if(wasm){
            wasmData = (await base64ToBlob(wasm.data)) as any;
        }
        if(!wasmData){            
            wasmData = await fromURLToBlob(wasmURL);
            let savedWasm = await dbRef.current.addFile({
                id: "wasm",
                data: wasmData,
                mimeType: "application/wasm"
            }); 
        }
        try{
            wasm = await dbRef.current.getFile("wasm");
        } catch (e) {
            console.log("XE2", e);  
        }  
        if(wasm){
            wasmData = (await base64ToBlob(wasm.data)) as any;
        }
        if(!coreData){
            console.log("coreData empty");
            return;
        }
        if(!wasmData){
            console.log("wasmData empty");
            return;
        }
        const coreBlob = new Blob([coreData], { type: "text/javascript"});
        const wasmBlob = new Blob([wasmData], { type: "application/wasm"});
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.load({
            coreURL: fromBlobToURL(coreBlob),
            wasmURL: fromBlobToURL(wasmBlob),
        });
        if(debug) ffmpeg.on("log", console.log);
        // Download Images
        if(backgroundImageURL && backgroundImageURL.length >= 1){
            backgroundImageRef.current = await getImageAndNameSize(backgroundImageURL);
            let backgroundImageBlob = await getImageAsBlob(backgroundImageURL);
            if(backgroundImageBlob){
                try{
                    await ffmpegRef.current.deleteFile(backgroundImageRef.current[2]);
                } catch(e){}
                let fileWritten = await ffmpegRef.current.writeFile(
                    backgroundImageRef.current[2], await fetchFile(backgroundImageBlob)
                );
            }
            backgroundImageBlob = null;
        }
        if(clipdropWatermarkImageURL && clipdropWatermarkImageURL.length >= 1){
            clipdropWatermarkImageRef.current = await getImageAndNameSize(clipdropWatermarkImageURL);
            let clipdropWatermarkImageBlob = await getImageAsBlob(clipdropWatermarkImageURL);
            if(clipdropWatermarkImageBlob){
                try{
                    await ffmpegRef.current.deleteFile(clipdropWatermarkImageRef.current[2]);
                } catch(e){}
                let fileWritten = await ffmpegRef.current.writeFile(
                    clipdropWatermarkImageRef.current[2], await fetchFile(clipdropWatermarkImageBlob)
                );
            }
            clipdropWatermarkImageBlob = null;
        }
        if(props.clientWatermarkImage && props.clientWatermarkImage != "" && props.clientWatermarkImage.length >= 1){
            clientWatermarkImageRef.current = await getImageAndNameSize(props.clientWatermarkImage);
            let clientWatermarkImageBlob = await getImageAsBlob(props.clientWatermarkImage);
            if(clientWatermarkImageBlob){
                try{
                    await ffmpegRef.current.deleteFile(clientWatermarkImageRef.current[2]);
                } catch(e){}
                let fileWritten = await ffmpegRef.current.writeFile(
                    clientWatermarkImageRef.current[2], await fetchFile(clientWatermarkImageBlob)
                );
            }
            clientWatermarkImageBlob = null;
        }
        await loadStream();
        setCurrentState("record");
        return true;
    };
    const uploadFile = async (fileData:any, setPercentage:(val:number)=>void) => {
        let uploadFileURL = "";     
        while (!uploadFileURL) {
            uploadFileURL = await API.file.uploadFileAsFileWithProgress(props.apiKey, "mp4", fileData, (percentage:any)=>{
                console.log(`UPLOAD ${percentage}%`);
                setPercentage(percentage);
            });
            uploadFileURL = uploadFileURL ?? "";
        }
        return uploadFileURL;
    }
    const handleCompressionAndUpload = async () => {
        // Load Database and file info/data
        const fdata = await dbRef.current.getFile(randomFileName);
        if(fdata === false){
            console.log("ERROR: Unable to read file from IndexedDatabase");
            setCurrentState("error");
            setCurrentSubState("ERROR: Unable to read file from IndexedDatabase");
            return;
        }
        const mimeTypeXX = fdata.mimeType;
        // Define Local Files
        let inputFile = `${randomFileName}.${mimeTypeXX.split("video/")[1]}`;
        let outputFile = `${randomFileName}-compressed.mp4`;
        // Clear if files are existing
        try{
            await ffmpegRef.current.deleteFile(inputFile);
        } catch(e){}
        try{
            await ffmpegRef.current.deleteFile(outputFile);
        } catch(e){}
        // Handle Compression
        const blob = await base64ToBlob(fdata.data);
        let fileWritten = await ffmpegRef.current.writeFile(
            inputFile, await fetchFile(blob)
        );
        let converted = -1; 
        let counter = 1;
        console.log("CONVERTING FILE");
        // LOOP TILL A SUSCCESSFUL CONVERTION
        try{
        if(
            backgroundImageURL && backgroundImageURL.length >= 1 &&
            props.clientWatermarkImage && props.clientWatermarkImage != "" && props.clientWatermarkImage.length >= 1 && 
            clipdropWatermarkImageURL && clipdropWatermarkImageURL.length >= 1
        ){
            let clientWatermark_w = clientWatermarkImageRef.current[0];
            let clientWatermark_h = clientWatermarkImageRef.current[1];
            let clientWatermark_ratio = clientWatermark_h / clientWatermark_w;
            let clientWatermark_n_h = 76;
            let clientWatermark_n_w = Math.floor((clientWatermark_w * clientWatermark_n_h) / clientWatermark_h);
            let half_point = Math.floor((920 / 2) - (clientWatermark_n_w / 2));
            let clipdropWatermark_w = clipdropWatermarkImageRef.current[0];
            let clipdropWatermark_h = clipdropWatermarkImageRef.current[1];
            let clipdropWatermark_n_h = 64;
            let clipdropWatermark_n_w = Math.floor((clipdropWatermark_w * clipdropWatermark_n_h) / clipdropWatermark_h);
            let backgroundSizeW = backgroundImageRef.current[0];
            let backgroundSizeH = backgroundImageRef.current[1];
            let backgroundBottomBorderStart = backgroundSizeH - 116;
            let clipdropWatermark_x_offset = Math.floor(920 - clipdropWatermark_n_w);
            let clipdropWatermark_y_offset = Math.floor(backgroundBottomBorderStart - clipdropWatermark_n_h);
            console.log("A:", clientWatermarkImageRef.current);
            console.log("B:", clipdropWatermarkImageRef.current);
            console.log("C:", backgroundImageRef.current);
            console.log("X1");
            const ffmpeg_arguments = [
                '-i', `${backgroundImageRef.current[2]}`,
                '-i', inputFile, // Input File
                '-i', `${clipdropWatermarkImageRef.current[2]}`,
                '-i', `${clientWatermarkImageRef.current[2]}`,
                '-filter_complex', `[1:v]scale=920:692[inner];[0:v][inner]overlay=0:113[out];[2:v]scale=${clipdropWatermark_n_w}:${clipdropWatermark_n_h}[inner];[out][inner]overlay=${clipdropWatermark_x_offset}:${clipdropWatermark_y_offset}[out];[3:v]scale=${clientWatermark_n_w}:${clientWatermark_n_h}[inner];[out][inner]overlay=${half_point}:16[out]`,
                '-map', '[out]',
                '-map', '1:a',
                '-c:v', 'libx264',
                '-preset', 'ultrafast',
                '-vprofile', 'high',
                //'-pix_fmt', 'yuv420p',
                '-r', '15', // Frame Rate was 32
                '-c:a', 'copy',
                '-crf', '28',
                //'-vf', 'scale=320:-1', // Filter -> Scale image to 320px width, maintaining aspect ratio
                outputFile // Output File
            ]
            console.log("ffmpeg_arguments", ffmpeg_arguments);
            converted = await ffmpegRef.current.exec(ffmpeg_arguments);
            counter += 1;
        } else {
            console.log("X2");
            const ffmpeg_arguments = [
                '-i', `${backgroundImageRef.current[2]}`,
                '-i', inputFile, // Input File
                '-i', `${clipdropWatermarkImageRef.current[2]}`,
                '-filter_complex', `[1]scale=920:692[inner];[0][inner]overlay=0:113[out];[2]scale=255:34[inner];[out][inner]overlay=638:747[out]`,
                '-map', '[out]',
                '-map', '1:a',
                '-c:v', 'libx264',
                '-preset', 'ultrafast',
                '-vprofile', 'high',
                //'-pix_fmt', 'yuv420p',
                '-r', '15', // Frame Rate was 32
                '-c:a', 'copy',
                '-crf', '28',
                //'-vf', 'scale=320:-1', // Filter -> Scale image to 320px width, maintaining aspect ratio
                outputFile // Output File
            ]
            console.log("ffmpeg_arguments", ffmpeg_arguments);
            converted = await ffmpegRef.current.exec(ffmpeg_arguments);
            counter += 1;
        }} catch(e){
            console.log(e);
        }
        console.log("FILE CONVERTED");
        let data:any = false;
        counter = 1;
        while(data === false){
            try {
                data = await ffmpegRef.current.readFile(`${randomFileName}-compressed.mp4`);
            } catch (e){
                data = false;
            }
            counter += 1;
        }
        console.log("UPLOADING");
        setUploadProgress(0);
        setIsUploading(true);
        const compressedBlob = new Blob([data], { type: 'video/mp4' });
        const file = new File([compressedBlob], "my_video_file.mp4", { type: "video/mp4"});
        //let fileData = ((await toBase64(compressedBlob)) as string);
        //const match = fileData.match(/base64,(\S+)/);
        //if(match){
        //    fileData = match[1];
        //}
        let fileURL = await uploadFile(file, setUploadProgress);
        setIsUploading(false);
        setUploadedURL(fileURL);
        setCurrentState("preview");
    }
    // Effects
    useEffect(() => {
        if(!stream) return;
        setIsReady(true);
    }, [stream]);
    
    
    useEffect(()=>{
        if(currentState !== "record") return;
        if(!stream) return;
        if(!isReady) return;
        if(counterRef.current) counterRef.current.style.display = "block";
        if(counterRef.current) counterRef.current.innerHTML = 3;
        if(startButton.current) startButton.current.style.display = "inline-block";
        if(restartButton.current) restartButton.current.style.display = "none";
        if(stopButton.current) stopButton.current.style.display = "none";
    }, [currentState, stream, isReady]);

    useEffect(()=>{
        if(currentState !== "loading") return;
        loadFFMPEG();
    }, [currentState]);
    
    useEffect(()=>{
        if(isReady) return;
        if(currentState !== "record") return;
        loadRecord();        
    }, [isReady, currentState]);
    
    useEffect(()=>{
        if(currentState !== "compress-and-upload") return;
        handleCompressionAndUpload();
    }, [currentState]);

    useEffect(()=>{
        // Disable Stream When Unloading the component
        return () => {
            if(!stream) return;
            try { stream.getTracks().forEach( x => x.stop()); } catch (e){}
            try { stream.getAudioTracks().forEach( x => x.stop()); } catch (e){}
            try { stream.getVideoTracks().forEach( x => x.stop()); } catch (e){}
        }
    }, []);

    // Render
    if(currentState === "loading"){
        return <div className="in-browser-loading-component-v2">
            <h4>Loading - Accept Permissions if prompted</h4>
        </div>        
    }
    if(currentState === "error"){
        return <div>{ currentSubState }</div>
    }
    if(currentState === "record" && stream){
        return <div className="in-browser-recorder-component-v2">
            <h4>Record A Video</h4>
            <ReactMediaRecorder
                customMediaStream={stream}
                video={true}
                audio={true}
                askPermissionOnMount={true}
                stopStreamsOnStop={false}
                mediaRecorderOptions={{ mimeType: getBaseMimeType() }}
                onStop={async (blobUrl, blob) => {await saveVideoRecordingFromBlob(blob)}}
                onStart={async () =>{}}
                render={({status, startRecording:startRecordingX, stopRecording:stopRecordingX, clearBlobUrl, previewStream}) => {
                startRecordingRef.current = startRecordingX;
                stopRecordingRef.current = stopRecordingX;
                return <>
                    <div className='image-capture-preview'>
                        <VideoPreview stream={previewStream ?? stream} />
                        <div className="counter" ref={counterRef}>3</div>
                    </div>
                    </>
                }}
            />
            <button className='button-primary-blue' style={{marginBottom:'8px'}} onClick={ async e => {
                startVideoRecording();
            }} ref={startButton}>Start Recording</button>
            <button className='button-primary-blue' style={{marginBottom:'8px'}} onClick={ async e => {
                setUploadedURL("");
                setIsReady(false);
                setCurrentState("loading");
            }} ref={restartButton}>Restart Recording</button>
            <button className='button-primary-blue' style={{marginBottom:'8px'}} onClick={ async e => {
                stopRecordingRef.current();
                try { stream.getTracks().forEach( x => x.stop()); } catch (e){}
                try { stream.getAudioTracks().forEach( x => x.stop()); } catch (e){}
                try { stream.getVideoTracks().forEach( x => x.stop()); } catch (e){}
            }} ref={stopButton}>Stop Recording</button>
        </div>        
    }
    if(currentState === "compress-and-upload"){
        return <div className='in-browser-compress-component-v2'>
            <h4>{ !isUploading ? "Compressing Video" : "Uploading Video"}</h4>
            <p>Your video is being { !isUploading ? "compressed" : "uploaded"}.</p>
            { isUploading && <div><progress value={uploadProgress} max={100}/></div>}
            <p>This video will be previewed once done</p>
            <p>Please wait while we handle this</p>
        </div>        
    }
    if(currentState === "preview"){
        return <div className='in-browser-preview-component-v2'>
            <h4>Preview Video</h4>
            <p>You may now preview your recorded video</p>
            <video src={uploadedURL} autoPlay={true} playsInline={true} controls={true}></video>
            <div className="actions">
                <button className='button-primary-blue block full' style={{margin:'8px 0px'}} onClick={ e => {
                    stopStream();
                    props.onCompletion(uploadedURL);
                    setUploadedURL("");
                    setIsReady(false);
                }}>Accept Recording</button>
                <button className='button-primary-blue block full' style={{margin:'8px 0px'}} onClick={ e => {
                    stopStream();
                    setUploadedURL("");
                    setIsReady(false);
                    props.onClose();
                }}>Reject Recording</button>
                <button className='button-primary-blue block full' style={{margin:'8px 0px'}} onClick={ e => {
                    setUploadedURL("");
                    setIsReady(false);
                    setCurrentState("loading");
                }}>Rerecord</button>
            </div>
        </div>        
    }
    return <></>
}