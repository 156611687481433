import React from 'react';
import { toast } from 'react-toastify';

interface TakeMeBackProps {

}

export default function TakeMeBack(props:TakeMeBackProps){
    const hasOriginalAPIKey = localStorage.getItem('original_api_key') ?? "";
    return <div style={{padding:"20px"}}>
        <button className='button-primary-blue' onClick={ e => {
            if(hasOriginalAPIKey.trim() === ""){
                toast.info("You do not have an admin api key available, you will be redirected to the login screen");
                localStorage.setItem('api_key', "");
                localStorage.setItem('original_api_key', "");
                window.location.href="/login";    
                return;
            }
            localStorage.setItem('api_key', hasOriginalAPIKey);
            localStorage.setItem('original_api_key', "");
            toast.info("Redirecting");                   
            window.location.href="/dashboard";
        }}>Go Back To Admin Dashboard</button>
    </div>
}