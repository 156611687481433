import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import API from '../../../services/api';
import { BsFillCameraVideoFill } from 'react-icons/bs';

import Facebook from "./../../../services/facebook";
import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';

import './socialFolder.css';
import useAbortableAxios from '../../../hooks/useAbortableAxios';

interface SocialFolderProps {
    apiKey: string;
    marketing: any;
    userInfo: any;
    handleFacebookPageSubmission: (callback: (pages_data:any[]) => Promise<boolean>) => void;
    executeHandleFacebookPageSubmission: (pages_data:any[]) => void; 
    executeSocialFlow: () => void;
    executeHandleSocialVideoOptions: () => void;
    executeShowLinkedInShare: (video_url:string, access_token:string, user_id:string) => void;
    handleLinkedInShareCallback: (callback: () => Promise<void>) => void;

}

export default function SocialFolder(props:SocialFolderProps){
    // State
    const [pages, setPages] = useState<any[]>([]);
    const [ fbLoggedIn, setFbLoggedIn] = useState<boolean>(false);
    const [ isLoggedInLinked, setIsLoggedInLinked] = useState<boolean>(false);
    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    const { loading:apiMarketingTextLoading, data:apiMarketingTextData, error:apiMarketingTextError, fetchData:fetchAPIMarketingTextData, setLoading:setAPIMarketingTextLoading} = useAbortableAxios();
    const { loading:apiWebsiteLoading, data:apiWebsiteData, error:apiWebsiteError, fetchData:fetchWebsite, setLoading:setAPIWebsiteLoading} = useAbortableAxios();
    if (!(window as any).FB) {
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          (fjs as any).parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }
    // Functions
    const handleLinkedInAuth = () => {
        let loc = window.location.href;
        let client_id = "8663tuq8r4umo5"; // TODO: NOTE: Keep Updated
        let redirectURL = "https://api.clipdrop.io/api/v1/linkedin/redirect";
        let scope = "r_emailaddress w_member_social r_basicprofile r_liteprofile";
        let state = btoa(props.userInfo.id + "/./" + loc).replace("%3D", "");
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${state}&scope=${scope}&client_id=${client_id}&redirect_uri=${encodeURI(redirectURL)}`;
    }
    // Effect
    useEffect(()=>{
        (window as any).FB.init({
            appId: "1037968913418686",
            cookie: true,
            xfbml: true,
            version: "v13.0",
        });
        (window as any).FB.AppEvents.logPageView();
        (window as any).FB.getLoginStatus(function (response) {
            console.log(response);
            let status = response["status"];
            if (status != "connected") {
                setFbLoggedIn(false);
            } else {
                setFbLoggedIn(true);
                console.log("LoggedIn");
            }
        });
    }, []);
    useEffect(()=>{
        if(!props.userInfo) return;
        let isNotExpired = new Date().getTime() - new Date(props.userInfo.linkedin_user_access_token_expiry_date).getTime() < 0;
        if(props.userInfo.linkedin_user_access_token && props.userInfo.linkedin_user_id && isNotExpired){
            setIsLoggedInLinked(true);
        } else {
            setIsLoggedInLinked(false);
        }
    }, [props.userInfo]);
    // Render
    if(!props.marketing){
        return <div className='interview-folder-container'>Loading Folder Data</div>
    }
    return <>
        <div className='interview-folder-container'>
            <div className='nice-form-2'>
                <div>
                    <h4 style={{display:'block', marginRight:'8px'}}>Actions</h4>
                    <button style={{display:'inline-block', fontSize:'16pt'}} className={props.marketing.social_start_interactive === 1 ? 'button-primary-locked-out' : "button-primary-orange"} onClick={ e => { 
                        props.executeSocialFlow();
                    }}>Start</button>
                    <button style={{display:'inline-block', fontSize:'16pt'}}className='button-primary-blue' onClick={ e => setShowSettings(x => !x)}>Edit</button>
                </div>
                <p>Add a Social Video, Then you may share it via LinkedIn and/or Facebook</p>
                { showSettings && <>            
                    <h4>Settings - Social Post</h4>
                    <div className='greyed' style={{minHeight: '526px'}}>
                        <label htmlFor="outReach-campaign-video">
                            <span>Social Post Video</span>
                        </label>              
                        <div
                            className="tab2ActionContainerMaxWidthSplit"
                            style={{
                                minWidth: "299px",
                                width: "calc(23% - 8px)",
                                padding: "4px",
                                margin: "0px 4px",
                            }}
                        >
                            <video
                                className="tab2ActionContainerMaxWidthMiniImage"
                                src={props.marketing.social_video_url}
                                poster={
                                    props.marketing.social_video_url
                                    ? ""
                                    : "https://clipdrop.io/wp-content/assets/media/images/video.png"
                                }
                                playsInline={true}
                                controls={true}
                            />
                            <button
                                className="candidateMiniButton bigger"
                                onClick={()=>{
                                    props.executeHandleSocialVideoOptions();
                                }}
                                style={{transform:'translate(252px, -48px)'}}
                            >
                            <BsFillCameraVideoFill/>
                            </button>
                        </div>                       
                    </div>
                    <div className='greyed' style={{minHeight: '526px'}}>
                            <label htmlFor="outReach-campaign-video">
                                <span style={{ fontWeight: 400 }}>Social Caption <span style={{fontWeight:400}}>(Optional for FB Share Only)</span></span>
                            </label> 
                            <input
                                style={{ margin:'0px' }}
                                defaultValue={props.marketing.social_caption}
                                onChange={async (e:any)=>{
                                    const result = fetchAPIMarketingTextData(API.baseURL + "/api/v1/marketing/campaign/social/marketing/caption",
                                    {
                                        api_key: props.apiKey,
                                        id: props.marketing.id,
                                        text: e.target.value,
                                    }, {}, "POST");
                                }}
                                placeholder="Title"
                            />
                            <label htmlFor="outReach-campaign-video">
                                <span style={{ fontWeight: 400 }}>Social Post Text</span>
                            </label> 
                            <textarea
                                className="advertisemarketingText"
                                style={{ margin:'0px', width:'calc(100% - 31px)' }}
                                defaultValue={props.marketing.social_text}
                                onChange={async (e:any)=>{
                                    const result = fetchWebsite(API.baseURL + "/api/v1/marketing/campaign/social/marketing/text",
                                    {
                                        api_key: props.apiKey,
                                        id: props.marketing.id,
                                        text: e.target.value,
                                    }, {}, "POST");
                                }}
                            ></textarea>                                                   
                    </div>
                </>}
                <br/>
                <br/>
                <div className='col2-buttons'>
                    {
                        !props.marketing.social_video_url && <button className='button-primary-blue blocked2 full' onClick={ e => {
                            toast.warning(
                                "Warning! Before you can share, you must please click the start button and finish the recording process",
                            );
                            return;
                        }}><FaFacebook
                        style={{ transform: "translateY(3px)" }}
                        />&nbsp;&nbsp;Share on Facebook</button>
                    }
                    {
                        props.marketing.social_video_url && <>
                            {!fbLoggedIn && (
                                <button className='button-primary-blue blocked2 full' onClick={()=>{
                                    (window as any).FB.login(
                                        function (response) {
                                        if (response.status === "connected") {
                                            setFbLoggedIn(true);
                                        } else {
                                            setFbLoggedIn(false);
                                        }
                                        },
                                        {
                                        scope:
                                            "public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts",
                                        }
                                    );
                                }}><FaFacebook
                                style={{ transform: "translateY(3px)" }}
                                />&nbsp;&nbsp;Sign In with Facebook</button>
                            )}
                            {fbLoggedIn && (
                                <button className='button-primary-blue blocked2 full' onClick={ e => {
                                    toast.info("Loading Pages, Please wait");
                                    (window as any).FB.api("/me/accounts", function (response) {
                                        let pagesx = response["data"];
                                        pagesx = pagesx.map((page) => {
                                        return {
                                            access_token: page.access_token,
                                            id: page.id,
                                            name: page.name,
                                        };
                                        });
                                        toast.info("Pages, Loaded");
                                        setPages(pagesx);
                                        props.handleFacebookPageSubmission(async (pages_data:any[]):Promise<boolean>=>{
                                            toast.info("Posting to pages, please wait");
                                            for (let i = 0; i < pages_data.length; i++) {
                                                let activePage = pages_data[i];
                                                let title = props.marketing.social_caption;
                                                let description = `${props.marketing.social_text}\n\n${props.marketing.social_video_url}`;
                                                let page_id = activePage.id;
                                                let page_access_token = activePage.access_token;
                                                let file_url = props.marketing.social_video_url;
                                                let response = await new Promise((resolve, reject) => {
                                                        (window as any).FB.api(`/${page_id}/videos`,"POST", {
                                                        title: title,
                                                        description: description,
                                                        file_url: file_url,
                                                        access_token: page_access_token,
                                                    },
                                                        function (response) {
                                                            resolve(response);
                                                        }
                                                    );
                                                });
                                                console.log(response);
                                            }
                                            toast.success("Content Posted");
                                            return true;
                                        })
                                        props.executeHandleFacebookPageSubmission(pagesx);
                                    });
                                }}><FaFacebook
                                style={{ transform: "translateY(3px)" }}
                                />&nbsp;&nbsp;Share on Facebook</button>
                            )}
                        </>
                    }
                    {
                        isLoggedInLinked && <button className='button-primary-blue blocked2 full' onClick={ async e => {
                            if (!props.marketing.social_video_url) {
                                toast.warning(
                                    "Warning! Before you can share, you must please click the start button and finish the recording process",
                                );
                                return;
                            }
                            toast.info("Loading");
                            props.handleLinkedInShareCallback(async ()=>{
                                // toast.success("Video Posted To LinkedIn");
                            });
                            props.executeShowLinkedInShare(props.marketing.social_video_url, props.userInfo.linkedin_user_access_token, props.userInfo.linkedin_user_id);
                        }}><FaLinkedinIn
                        style={{ transform: "translateY(3px)" }}
                        />&nbsp;&nbsp;Share on LinkedIn</button>
                    }
                    {
                        !isLoggedInLinked && <button className='button-primary-blue blocked2 full' onClick={ e => {
                            handleLinkedInAuth();
                        }}>
                            <FaLinkedinIn
                            style={{ transform: "translateY(3px)" }}
                            />&nbsp;&nbsp;Sign In with LinkedIn
                        </button>
                    }
                </div>
            </div>
        </div>
    </>
}