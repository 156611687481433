import React, { useState, useEffect, useRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
import './bespokeFolder.css';
import API from '../../../services/api';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateTime } from 'luxon';
import { IoIosPaperPlane } from 'react-icons/io';
import { BsPencil, BsPersonPlusFill } from 'react-icons/bs';
import { RiShareForward2Fill } from 'react-icons/ri';
import { AiFillEye, AiFillStar } from 'react-icons/ai';
import { MdClear, MdDelete, MdOutlineAssessment } from 'react-icons/md';
import { ImBin2 } from 'react-icons/im';
import useAbortableAxios from '../../../hooks/useAbortableAxios';
import { MARKETING_PULLING_SECONDS } from '../MARKETING_PULLING_SECONDS';


import play_icon_grey from "./../../../media/play_icon_grey.png";
import play_icon_color from "./../../../media/play_icon_color.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
interface BespokeFolderProps {
    apiKey:string;
    marketing:any;
    createLeadCallback: (callback: (data:any) => Promise<null | {
      closed:boolean;
      copied:number;
      created_at: null | string | Date
      email: null | string
      custom_video_url: string | null;
      hasViewed:boolean;
      viewTime:string;
      id: number
      marketing_id:  null | number
      linkedin_url: null | string
      marketing_type: null | number
      marketingType_id: null | number;
      marketing_thumbnail: null | string;
      name: null | string;
      phone: null | string;
      processing_gif: null | number;
      processing_video: null | number; 
      processing_done: null | number;
      updated_at: null | string | Date;
  }>) => void;
  executeCreateLead: () => void;  
  executeSetLeadCustomVideo: (lead_id:number, lead_name:string) => void;
  previewLeadCustomVideo: (video_url:string) => void;
  reloadBespokeFolder: (callback: () => Promise<void>) => void;  
  onShowSendEmail: (subject:string, to:string, body:string) => void;
  reloadMarketing: () => void;  
  executeBespokeFlow: () => void;
}

interface CandidateEditInterface {
  id: number;
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}
interface CandidateAddInterface {
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}

const theme = createTheme({
    palette: {
      mode: 'light', // Force light mode
    },
} as any);

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        '&:hover': {
          color: '#1976D1'
        },
      },
    },
  }));

function BespokeFolderCandidateGrid({ loading, columns, rows}) {
    return (<ThemeProvider theme={theme}>
    <DataGrid
      //loading={loading}
      rows={rows}
      columns={columns}
      disableColumnMenu={true}
      disableRowSelectionOnClick
      getRowId={(row) => row.id}
      getRowHeight={() => 'auto'}
    />
  </ThemeProvider>)
}

function ActionDropDownMenuForList({handleEdit}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
        <MenuItem onClick={()=>{handleEdit(); handleClose();}} disableRipple>
          Edit
        </MenuItem>
        </StyledMenu>
      </div>
    );
  }


export default function BespokeFolder(props:BespokeFolderProps){
    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    const { loading:apiLoading, data:apiData, error:apiError, fetchData:fetchAPIData, setLoading:setAPILoading} = useAbortableAxios();
    const { loading:apiWebsiteLoading, data:apiWebsiteData, error:apiWebsiteError, fetchData:fetchWebsite, setLoading:setAPIWebsiteLoading} = useAbortableAxios();
    const { loading:apiMarketingTextLoading, data:apiMarketingTextData, error:apiMarketingTextError, fetchData:fetchAPIMarketingTextData, setLoading:setAPIMarketingTextLoading} = useAbortableAxios();
    const { loading:apiMarketingCustomTextLoading, data:apiMarketingCustomTextData, error:apiMarketingCustomTextError, fetchData:fetchAPIMarketingCustomTextData, setLoading:setAPIMarketingCustomTextLoading} = useAbortableAxios();
    const [ leads, setLeads ] = useState<any[]>([]);
    // Filters
    const [ filterTextSearch, setFilterTextSearch ] = useState<string>("");
    const [ filterCandidatesWithRecordedVideo, setFilterCandidatesWithRecordedVideo ] = useState<boolean>(false);
    const [ filterCandidatesWithNoRecordedVideo, setFilterCandidatesWithNoRecordedVideo ] = useState<boolean>(false);
    const [ filterCandidateReadyToSend, setFilterCandidateReadyToSend ] = useState<boolean>(false);
    const [ filterCandidatesHasBeenViewed, setFilterCandidatesHasBeenViewed ] = useState<boolean>(false);
    // Pulling
    const pullLeadsIntervalRef = useRef<any>(null);
    // State
    const [ ourWebsite, setOurWebsite ] = useState<string>("");
    const getProcessingTag = (lead:any) => {
      if(lead.hasViewed && lead.closed) return <><span style={{ display:'block', marginTop:'4px'}} data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagBlue">Viewed</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
      if(lead.hasViewed && !lead.closed) return <><span style={{ display:'block', marginTop:'4px'}} data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagGreen">New View</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
      if(lead.copied) return <div style={{ display:'block', marginTop:'4px'}} className="blueBTN7">Copied</div>;
      if(lead.custom_video_url && lead.processing_done) return <div style={{ display:'block', marginTop:'4px'}} className="blueBTN7">Ready to send</div>;
      if(lead.processing_gif) return <div style={{ display:'block', marginTop:'4px'}} className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
      if(lead.processing_video) return <div style={{ display:'block', marginTop:'4px'}} className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
    }
    const dataGridColumns = [
      {field: 'lead',  headerName: 'LEAD', width: 380, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>LEAD</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return <div>
            <div className='lead-picture'></div>
            <div className='lead-details'>
              <h4>{ row.name }</h4>
              <span>{ row.email }</span>
              <span>{ row.phone }</span>
            </div>
          </div>;
      }},
      {field: 'id',  headerName: 'ID', width: 180, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>ID</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return `${row.id}`;
      }},
      {field: 'actions',  headerName: 'ACTIONS', width: 420, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>ACTIONS</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return <div>
            <button title="Delete Lead" className='lead-action-button-red' onClick={ async e => {
              const response = await API.marketing.leads.delete(props.apiKey, row.id);
              if(!response['success']){
                toast.error("Unable to delete lead");
                return;
              } 
              loadLeads(props.apiKey, props.marketing.id);
              toast.error("Lead Deleted");
            }}><MdDelete /></button>
            <button title={(!!row.custom_video_url && row.processing_done === 1) ? "Preview Custom Video" : "Add Custom Video"} className='lead-action-button-blue' onClick={ e => {
              if((!!row.custom_video_url && row.processing_done === 1)){
                props.previewLeadCustomVideo(row.custom_video_url);
              } else {
                props.reloadBespokeFolder(async () => {
                  loadLeads(props.apiKey, props.marketing.id);
                });
                props.executeSetLeadCustomVideo(row.id, row.name);
              }
            }}><img src={ (!!row.custom_video_url && row.processing_done === 1) ? play_icon_color : play_icon_grey} alt=""  style={{width:'18px', height:'16px', pointerEvents: 'none'}} /></button>
            { (!!row.custom_video_url && row.processing_done === 1) && <button className='lead-action-button-blue' onClick={ async e => {
              toast.info("Loading");
              const result = await API.marketing.leads.getBespokeLink(props.apiKey, props.marketing.id, row.id);
              const link = result['link'];
              const to = row.email;
              const subject = "Marketing Invite";
              const body = props.marketing.marketing_text.replace("{lead}", row.name) + "\n\n" + link;
              props.onShowSendEmail(subject, to, body);
            }}><IoIosPaperPlane/></button>}
            { getProcessingTag(row) }
          </div>;
      }},
      {field: 'creation_date',  headerName: 'Creation Date', width: 180, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Creation Date</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return `${(new Date(row.created_at)).toDateString()}`;
      }},
  ];
    // Functions
    const loadLeads = async (api_key:string, marketing_id:string,
        xsearchQuery:string = "",
        xpageNumber:number = 0) => {
        fetchAPIData(API.baseURL + "/api/v1/marketing/lead/list", {}, {
          api_key: api_key,
          marketing_id: marketing_id,
          type_id: 0,
        }, "POST");
        if(pullLeadsIntervalRef.current){
          clearInterval(pullLeadsIntervalRef.current);
        }
        pullLeadsIntervalRef.current = setInterval(()=>{
          loadLeads(api_key, marketing_id);
        }, MARKETING_PULLING_SECONDS * 1000);
      };
    // Effects    
    useEffect(()=>{
      if(!apiData) return;
      // Filter Data
      let dataToFilter:any[] = apiData["leads"];
      if(filterCandidatesWithRecordedVideo){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (!!c.custom_video_url);
        });
      }
      if(filterCandidateReadyToSend){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (c.processing_done === 1) && (c.emailed === 0) && (c.copied === 0);
        });
      }
      if(filterCandidatesHasBeenViewed){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (c.hasViewed === 1);
        });
      }
      if(filterCandidatesWithNoRecordedVideo){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (!c.custom_video_url);
        });
      }
      if(!!filterTextSearch){
        const regex = new RegExp(filterTextSearch)
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return regex.test(c.name) || regex.test(c.email) || regex.test(c.id);
        });
      }
      setLeads(dataToFilter);
    }, [apiData, filterCandidatesWithRecordedVideo, filterCandidateReadyToSend, filterCandidatesHasBeenViewed, filterCandidatesWithNoRecordedVideo, filterTextSearch]);

    useEffect(()=>{
      if(!props.marketing) return;
      loadLeads(props.apiKey, props.marketing.id);
    }, [ props.marketing]);

    // Renders
    if(!props.marketing){
        return <div className='interview-folder-container'>Loading Folder Data</div>
    }

    return <><div className='interview-folder-container'>
        <div className='nice-form-2'>
            <div>
              <h4 style={{display:'block', marginRight:'8px'}}>Actions</h4>
              <button style={{display:'inline-block', fontSize:'16pt'}} className={ false ? 'button-primary-locked-out' : "button-primary-orange"} onClick={ e => { 
                  // TODO: START FLOW
                  props.executeBespokeFlow();
              }}>Start</button>
              <button style={{display:'inline-block', fontSize:'16pt'}}className='button-primary-blue' onClick={ e => setShowSettings(x => !x)}>Edit</button>
            </div>
            { showSettings && <>            
            <h4>Settings</h4>
                <div className='greyed' style={{minHeight:'275px'}}>
                    <label htmlFor="allow-candidate-participation">
                    <span>Thumbnail Text</span>
                    <select defaultValue={props.marketing.thumbnail_text_option} onChange={ async (e) => {
                        toast.info("Updating");
                        const result = await API.marketing.campaign.bespoke.setThubmanailOption(props.apiKey, props.marketing.id, e.target.value);
                        props.reloadMarketing();
                        toast.success("Updated");
                    }}>
                        <option value={"Hi {lead}"}>{"Hi {lead}"}</option>
                        <option value={"Hello {lead}"}>{"Hello {lead}"}</option>
                        <option value="custom" selected={props.marketing.thumbnail_text_option == "custom"}>Custom message</option>
                    </select>
                    {
                        props.marketing.thumbnail_text_option == "custom" && <>
                            <h4>Custom Message</h4>
                            <input onChange={ async (e) => {
                              await fetchAPIMarketingCustomTextData(`${API.baseURL}api/v1/marketing/campaign/bespoke/thumbnail/text`, {
                                api_key: props.apiKey,
                                id: props.marketing.id,
                                text: e.target.value,
                              }, {}, "POST");
                              props.reloadMarketing();
                            }} defaultValue={props.marketing.thumbnail_text_custom_text} type="text" placeholder="Welcome {Lead}"/>
                        </>
                    }
                    </label>
                </div>
                <div className='greyed' style={{minHeight:'275px'}}>
                    <label htmlFor="allow-candidate-participation">
                        <span>Marketing Text</span>
                        <textarea onChange={ async e => {
                          await fetchAPIMarketingTextData(`${API.baseURL}/api/v1/marketing/campaign/bespoke/marketing/text`, {
                            api_key: props.apiKey,
                            id: props.marketing.id,
                            text: e.target.value,
                          }, {}, "POST");
                          props.reloadMarketing();
                        }} style={{display:'block', width: 'calc(100% - 24px)', padding: '8px 12px', margin: '0px', height: '105px'}} defaultValue={props.marketing.marketing_text}></textarea>
                    </label>
                    <label htmlFor="allow-candidate-participation">
                        <span>Our Website</span>
                        <div style={{display:'inline-block', fontSize:'8pt'}}>Example URL <strong><span style={{backgroundColor:'yellow'}}>https://</span>www.example.com/</strong></div>
                        <input onChange={ async (e) => {
                          setOurWebsite(e.target.value);
                          await fetchWebsite(`${API.baseURL}/api/v1/marketing/campaign/bespoke/marketing/ourwebsite`, {
                            api_key: props.apiKey,
                            id: props.marketing.id,
                            text: e.target.value,
                          }, {}, "POST");
                          props.reloadMarketing();
                        }} defaultValue={props.marketing.our_website} type="text" placeholder="https://www.example.com"/>
                        <div style={{display:'block'}}>{ API.validation.verifyURL(ourWebsite) ? <div style={{fontSize:'8pt', color:'green'}}>Valid URL</div> : <div style={{fontSize:'8pt',color:'red'}}>Invalid URL</div> }</div>
                    </label>
                </div>
            </>}
            </div><br/>
            <div style={{marginBottom:'8px'}}>
                <div className="marketing-bespoke-input-group" style={{marginRight:'12px'}}>
                    <h4 style={{transform:'translateY(22px)'}}>Leads</h4>
                    <span style={{fontSize:'9pt'}}>{ 1 > 1 ? `${1} leads` : `${1} lead`}</span>
                </div>
                <div className="marketing-bespoke-input-group">
                    <input placeholder='Search here' onChange={ e => { setFilterTextSearch(e.target.value.trim()) }}/>
                    <button className='blueBTN' style={{marginLeft:'4px'}}>Search</button>
                    <div className="marketing-bespoke-buttons-group">
                        <button title="Clear Filters" onClick={ e => {
                          setFilterCandidatesWithRecordedVideo(false);
                          setFilterCandidateReadyToSend(false);
                          setFilterCandidatesHasBeenViewed(false);
                          setFilterCandidatesWithNoRecordedVideo(false);
                        }}><MdClear/></button>
                        <button className={ filterCandidatesWithRecordedVideo ? 'active' : ''} title="Leads with recorded videos" onClick={ e => { setFilterCandidatesWithRecordedVideo( x => {
                          if(!x === true){
                            setFilterCandidatesWithNoRecordedVideo(false);
                          }
                          return !x
                        })}}><img src={play_icon_color} alt=""  style={{width:'18px', height:'16px', pointerEvents: 'none'}} /></button>
                        <button className={ filterCandidateReadyToSend ? 'active' : ''} title="Leads ready to send" onClick={ e => { setFilterCandidateReadyToSend( x => !x)}}><IoIosPaperPlane style={{pointerEvents: 'none'}}/></button>
                        <button className={ filterCandidatesHasBeenViewed ? 'active' : ''} title="Leads that have been viewed" onClick={ e => { setFilterCandidatesHasBeenViewed( x => !x) }}><AiFillEye style={{pointerEvents: 'none'}}/></button>
                    </div>
                    <div className="marketing-bespoke-buttons-group">
                        <button className={ filterCandidatesWithNoRecordedVideo ? 'active' : ''} title="Leads with no recorded videos" onClick={ e => { setFilterCandidatesWithNoRecordedVideo( x => {
                          if(!x === true){
                            setFilterCandidatesWithRecordedVideo(false);
                          }
                          return !x;
                          })}}><img src={play_icon_grey} alt=""  style={{width:'18px', height:'16px', pointerEvents: 'none'}} /></button>
                    </div>
                    <button className='blueBTN' style={{marginLeft:'4px', width:'200px', transform:'translateY(2px)'}} onClick={ e => {
                      props.createLeadCallback(async (data:any)=>{
                        const response = await API.marketing.leads.create2(props.apiKey, data.name, data.email, data.phone, data.linkedin_url, props.marketing.id, 0);
                        if(!response['success']){
                          toast.error(`Unable to create lead - ${response['message']}`);
                          return null;
                        }
                        return response['lead'];
                      });
                      props.executeCreateLead();
                    }}>Add Lead</button>
                </div>
            </div>
            <BespokeFolderCandidateGrid loading={false} columns={dataGridColumns} rows={leads}/>
        </div>
    </>
}