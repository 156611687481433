import Permissions from "./Permissions";

export default class IndexedDBFileStorage {
    dbName:string;
    dbVersion:number;
    db:any;
    constructor(dbName:string, dbVersion:number) {
        this.dbName = dbName;
        this.dbVersion = dbVersion;
        this.db = null;
    }
    static getRandomFileName(length:number = 12):string {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }
    openDatabase():Promise<boolean> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, this.dbVersion);
            request.onupgradeneeded = (event: any) => {
                const db = event.target.result  ;

                if (!db.objectStoreNames.contains("files")) {
                    // const objectStore = db.createObjectStore("files", { keyPath: "id" });
                    const objectStore = db.createObjectStore("files");
                }
            };
            request.onsuccess = (event: any) => {
                this.db = event.target.result;
                resolve(true);
            };
            request.onerror = (event: any) => {
                console.log(event.target.error);
                resolve(false);
            };  
        });
    }

    addFile(fileData:any):Promise<boolean> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(["files"], "readwrite");
            const objectStore = transaction.objectStore("files");
            const addRequest = objectStore.add(fileData, fileData.id);
            addRequest.onsuccess = async (event:any)  => {
                await Permissions.wait(0.5);
                resolve(true);
            };
            addRequest.oncomplete = async (event:any)  => {
                await Permissions.wait(0.5);
                resolve(true);
            };
            addRequest.onerror = (event:any)  => {
                console.log(event.target.error);
                resolve(false);
            };
        });
    }

    getFile(id:any): Promise<any | false> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(["files"], "readonly");
            const objectStore = transaction.objectStore("files");
            const getRequest = objectStore.get(id);
            getRequest.onsuccess = (event:any) => {
                const fileData = event.target.result;
                if (fileData) {
                    resolve(fileData);
                } else {
                    console.log(`File ${id} not found in database`);
                    resolve(false);
                }
            };
            getRequest.onerror = (event:any) => {
                console.log(event.target.error);
                resolve(false);
            };
        });
    }

    removeFile(id:any): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(["files"], "readwrite");
            const objectStore = transaction.objectStore("files");
            const deleteRequest = objectStore.delete(id);

            deleteRequest.onsuccess = async (event:any) => {
                await Permissions.wait(0.5);
                resolve(true);
            };
            deleteRequest.onerror = (event:any) => {
                console.log(event.target.error);
                resolve(false);
            };
        });
    }

    clearAllFiles():Promise<boolean> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(["files"], "readwrite");
            const objectStore = transaction.objectStore("files");
            const clearRequest = objectStore.clear();

            clearRequest.onsuccess = async (event:any) => {
                await Permissions.wait(0.5);
                resolve(true);
            };
            clearRequest.onerror = (event:any) => {
                console.log(event.target.error);
                resolve(false);
            };
        });
    }
}