import React, { useRef, useEffect, useState } from "react";
import Interview from "../../classes/InterviewClass";
import API from "../../services/api";
export default function useMarketingHook(api_key:string, interview_id:number) {
    const [ apiKey, setAPIKey] = useState<string>(api_key);
    const [ marketingID, setMarketingID] = useState<number>(interview_id);
    const [ marketing, setInterview] = useState<any | Interview>(null);
    const reloadMarketing = async () => {
        let result = await API.marketing.campaign.get(apiKey, marketingID);
        setInterview(result["campaign"]);
    }
    const updateMarketingTitle = async (title:string) => {
        let result = await API.marketing.campaign.update(apiKey, marketingID, title)
    }
    const updateMarketing = async (marketing_data:any) => {
        // TODO: WRITE CUSTOM MARKETING UPDATE API
    }
    useEffect(()=>{
        reloadMarketing();
    }, [marketingID]);
    return { marketing, marketingID, reloadMarketing, updateMarketingTitle, updateMarketing }
}