export default class Permissions {
    static async wait(seconds:number){
        return new Promise((resolve)=>{
            setTimeout(()=>{
                resolve(true);
            }, seconds * 1000);
        });
    }
    static async hasRecordingSupport(){
        let hasCameraSupport = false;
        let hasMicrophoneSupport = false;
        let devices = await navigator.mediaDevices.enumerateDevices();
        for(let device of devices){
            if(device.kind === 'audioinput'){
                hasMicrophoneSupport = true;
            }
            if(device.kind === 'videoinput'){
                hasCameraSupport = true;
            }
            if(hasCameraSupport && hasMicrophoneSupport){
                break;
            }
        }
        return (hasCameraSupport && hasMicrophoneSupport);
    }

    static async hasStorageSupport(){
        return new Promise((resolve)=>{
            resolve('Blob' in window || 'indexedDB' in window);
        });
    }

    static async promptRecordingPermissions(){
        return new Promise((resolve)=>{
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Prompt the user for camera and microphone permissions
                navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                    .then(async function(stream) {
                        await Permissions.wait(1);
                        stream.getTracks().forEach(track => track.stop());
                        resolve(true); 
                   })
                    .catch(function(error) {
                        resolve(false);
                        console.error('Error accessing camera and microphone:', error);
                    });
            } else {
                resolve(false);
                console.log('getUserMedia is not supported in this browser.');
            }
        });
    }

    static async getRecordingStream(options:{} = { video: true, audio: true }):Promise<MediaStream | null>{
        return new Promise((resolve)=>{
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                // Prompt the user for camera and microphone permissions
                navigator.mediaDevices.getUserMedia(options)
                    .then(async function(stream) {
                        resolve(stream); 
                   })
                    .catch(function(error) {
                        resolve(null);
                        console.error('Error accessing camera and microphone:', error);
                    });
            } else {
                resolve(null);
                console.log('getUserMedia is not supported in this browser.');
            }
        });
    }

    static async stopRecordingStream(stream:MediaStream){
        stream.getTracks().forEach(track => track.stop());
        return true;
    }
}