import React, { createRef, useEffect, useState } from "react";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import ITJComponent from "./../components/itj";

import QuestionComponent from "./../components/questions";

import CandidatesComponent from "./../components/candidates";

import MarketingComponent from "./../components/marketing";

import { withRouter } from "react-router";
import API from "../services/api";

import TutorialComponent from "../components/tutorial";

import './vacancy.css';
import JobBoardComponent from "../components/jobboard";

/*export default */ function VacancyPage(props) {
    const [showITJ, setShowITJ] = useState(false);
    const [showMarketingOpenRate, setShowMarketingOpenRate] = useState(false);
    const [recruiterInviteDialog, setRecruiterInviteDialog] = useState(false);
      const [state, setState] = useState({
        vacancy_id: 0,
        interview: null,
        surveys: [],
        user: null,
        seat_users: [],
        selected: [],
        storageNotice: false
    }); 

    let qRef = createRef();
    let openRecruiterInviteDialogRef = createRef();
    let marketingOpenRate = createRef();
    document.querySelector('body').style.backgroundColor = "#EEF0F8";

    async function loadInterview(){
        setShowMarketingOpenRate(false);
        setRecruiterInviteDialog(false);
        let api_key = localStorage.getItem('api_key');
        let vid = parseInt(props.match.params.id);
        let result = await API.interview.get(api_key, vid);
        let interview = result['interview'];
        result = await API.user.getUser(api_key);
        let user = result['user'];
        if(user.package_number === 0 && user.trial_expiry_flag){
            // show trial expire - popup
            let expiry_date = new Date(user.trial_expiry);
            let current_date = new Date();
            let t = expiry_date.getTime() - current_date.getTime();
            let expired = t <= 0; 
            if(expired){
                window.location.href = "/dashboard";
            }
        }
        result = await API.storage.getMyStorage(api_key);
        let total = 12;//result['total'];
        let used = 1;//result['used'];
        let assigned = result['assigned'];
        let counts = interview.openDiff?.bespoke + interview.openDiff?.campaign + interview.openDiff?.social + interview.openDiff?.interview;
        if(counts > 0){
            setShowMarketingOpenRate(true);
        }
        let survey_result = await API.surveys.list(api_key);
        let survey_results = survey_result['results'];
        setState({...state, interview: interview, user: user, surveys: survey_results});
        setShowITJ(true);
        let leftOver = total - used;
        leftOver = leftOver < 0 ? 0 : leftOver;
        if(leftOver <= 0){
            document.getElementById("storage-notice-no-space").style.display = ((leftOver) <= 0) ? "flex" : "none";
        } else if(leftOver <= 1){
            document.getElementById("storage-notice").style.display = ((leftOver) <= 0) ? "flex" : "none";
        }
    }
    useEffect(()=>{
        loadInterview();
    }, []);

    async function openRecruiterInvite(){
        let api_key = localStorage.getItem('api_key');
        let vid = parseInt(props.match.params.id);
        let result = await API.interview.getSeats(api_key, vid);
        let users = result['users'];
        let selected = result['selected'];
        setState({...state, seat_users: users, selected:selected});
        console.log(state);
        setRecruiterInviteDialog(true);
    }

    async function saveRecruiterInvite(){
        let api_key = localStorage.getItem('api_key');
        let vid = parseInt(props.match.params.id);
        let ids = Array.from(document.querySelectorAll('.selectedIDs')).filter((elm)=>{
            return elm.checked;
        }).map((elm)=>{
            return parseInt(elm.getAttribute('user-id'));
        });
        let result = await API.interview.setSeats(api_key, vid, ids);
        setRecruiterInviteDialog(false);
    }

    function UpdateSiblings(interview){
        if(qRef){
            if(qRef.current){
                try{
                    qRef.current.setInterview(interview);
                } catch (e){

                }
            }
        }
    }

    
    // HTML
    let title = "loading";
    if(state.interview){
        title = (state.interview.title);
    }
    let id = "loading";
    if(state.interview){
        id = state.interview.id;
    }
    
    let itj = <ITJComponent user={state.user} interview={state.interview} reloadPage={
        () => {
            setShowITJ(false);
            setTimeout(async ()=>{
                await loadInterview();
            }, 2000);
        }
    }></ITJComponent>;

    let questions = state.user != null & state.interview != null ? <QuestionComponent user={state.user} ref={qRef} interview={state.interview}></QuestionComponent> : <></>;

    let candidate = state.user != null & state.interview != null ?  <CandidatesComponent user={state.user} callSiblingUpdate={UpdateSiblings} interview={state.interview} surveys={state.surveys}></CandidatesComponent> : <></>;
    return (
        <><div className="dashboardContainer">
            <SideBarComponent></SideBarComponent>
            <div className="dashboarContent dashboarContentExtend" >
                <UpperBarComponent></UpperBarComponent>
                <div className="pageNameInfo" style={{width:'calc(100vw - 280px)'}}>
                    <span>Vacancy &gt; <span style={{color: "#1976D1"}}>{ title } <div style={{display: "inline-block",fontSize: "9pt", color:"rgb(255, 132, 0)"}}>({ id })</div></span></span> <button title="Invite and share this vacancy with other users" className="blueBTN" onClick={openRecruiterInvite} id="allocateUsers">Allocate Users</button>
                </div>
                { showITJ && itj }
                { questions }
                { candidate }
            </div>
        </div>
        <div className="dialogNewCreate"  style={{display:recruiterInviteDialog? "flex" : "none"}}>        
            <div style={{width: 'auto'}}>
                <h4>Select users to have access to this vacancy</h4>
                <div className="seats_to_the_left">
                    {
                        state.seat_users.map((user)=>{
                            return (
                                <div>
                                    <input type="checkbox" className="selectedIDs" user-id={user.id} defaultChecked={state.selected.includes(user.id)} style={{transform: 'scale(1.5)', marginRight: '8px'}}/> <span>{ user.name } ({ user.id })</span> 
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    <button onClick={saveRecruiterInvite}>Save</button>
                    <button onClick={()=>{setRecruiterInviteDialog(false);}}>Close</button>
                </div>
            </div>
        </div>
        <div style={{display:showMarketingOpenRate? "flex" : "none"}} className="dialogNew extend">        
            <div>
                <h4>New Opens</h4>
                <span>You have the following new opens</span>
                <div>
                    <ul style={{textAlign:'left', listStyle:'none'}}>
                        <li>Interview: { state.interview?.openDiff?.interview }</li>
                        <li>Bespoke: { state.interview?.openDiff?.bespoke }</li>
                        <li>Campaign: { state.interview?.openDiff?.campaign }</li>
                        { /* <li>Vlog: { state.interview?.openDiff?.social }</li> */}
                    </ul>
                </div>
                <div>
                    <button onClick={async ()=>{
                        setState({
                            ...state,
                            loading: true
                        });
                        setShowMarketingOpenRate(false);
                        let api_key = localStorage.getItem('api_key');
                        let result = await API.marketing.campaign.addSetOpensVacancy(api_key, state.interview.id);
                        setState({
                            ...state,
                            loading: false
                        });
                    }}>Close</button>
                </div>
            </div>
        </div>
        <div className="dialogNewCreate" id="storage-notice" style={{display:'none'}}>
            <div style={{minWidth:'720px'}}>
                <h4>Your are running out of storage space</h4>
                <p>You have a less than 1GB of storage left</p>
                <div>
                    <button onClick={()=>{
                            document.getElementById("storage-notice").style.display = "none";
                    }}>Close</button>
                </div>
            </div>
        </div>
        <div className="dialogNewCreate" id="storage-notice-no-space" style={{display:'none'}}>
            <div style={{minWidth:'720px'}}>
                <h4>You have run out of storage space</h4>
                <div>
                    <button onClick={()=>{
                            document.getElementById("storage-notice-no-space").style.display = "none";
                    }}>Close</button>
                </div>
            </div>
        </div>  
        <TutorialComponent page_number="6"></TutorialComponent>
        </>
    );
}

export default withRouter(VacancyPage); 