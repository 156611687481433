import React, { useState, useEffect, useRef } from "react";

import './ColorSelector.css';

export interface ColorSelectorProps {
    value:string;
    isInline:boolean;
    onChange: (hex_color:string) => void;
}

export default function ColorSelector(props:ColorSelectorProps){
    const [ currentColor, setCurrentColor] = useState( props.value ?? "#000000");
    const currentOptionsRef = useRef<HTMLDivElement | null>(null);
    const selectionOptionsRef = useRef<HTMLDivElement | null>(null);
    const colors:string[][] = [
        ["#ff2019",
        "#ff9f32",
        "#f8ff4f",
        "#00ff4a",
        "#00fdfe",
        "#4400f6",
        "#ac00f6",
        "#ff00f7"],

        ["#f8cdcc",
        "#fde7cf",
        "#fff4cf",
        "#d6ebd5",
        "#cedfe3",
        "#cee1f2",
        "#dbd1e7",
        "#edd1db"],

        ["#f19b9a",
        "#fccea1",
        "#ffe9a1",
        "#afd9ac",
        "#9ec3c8",
        "#9dc2e5",
        "#b8a4d3",
        "#dba5bc"],

        ["#e96968",
        "#fab674",
        "#ffde75",
        "#87c684",
        "#70a4ae",
        "#6ca4d8",
        "#9477bf",
        "#c97a9e"],

        ["#d61712",
        "#eb9647",
        "#f2c74d",
        "#58ab59",
        "#3b808d",
        "#3c7fc2",
        "#6f45a2",
        "#ae4c77"],

        ["#a10f0a",
        "#ba631f",
        "#c1942b",
        "#1f782b",
        "#004e5b",
        "#124d90",
        "#3e0a71",
        "#7a1a46"],

        ["#6b0604",
        "#7c4213",
        "#806319",
        "#194f1c",
        "#03333c",
        "#0a3360",
        "#26084a",
        "#50102f"],
    ];
    const selectColor = (color:string) => {
        if(!props.onChange) return;
        setCurrentColor(color);
        props.onChange(color);           
        if(!selectionOptionsRef) return;
        if(!selectionOptionsRef.current) return;
        selectionOptionsRef.current.style.display = "none";
    }
    const mouseClick = (e:any) => {
        if(!selectionOptionsRef) return;
        if(!selectionOptionsRef.current) return;
        selectionOptionsRef.current.style.display = "block";
        if(!currentOptionsRef) return;
        if(!currentOptionsRef.current) return;
        currentOptionsRef.current.style.borderBottomLeftRadius = "0px";
        currentOptionsRef.current.style.borderBottomRightRadius = "0px";
    }
    const mouseLeave = (e:any) => {            
        if(!selectionOptionsRef) return;
        if(!selectionOptionsRef.current) return;
        selectionOptionsRef.current.style.display = "none";
        if(!currentOptionsRef) return;
        if(!currentOptionsRef.current) return;
        currentOptionsRef.current.style.borderBottomLeftRadius = "8px";
        currentOptionsRef.current.style.borderBottomRightRadius = "8px";
    }

    useEffect(()=>{
        if(!props.onChange) return;
        props.onChange(currentColor);
    }, [currentColor]);

    return <div className={!props.isInline ? "color-selector-component" : "color-selector-component inline"} onMouseLeave={mouseLeave}>
        <div className="current-color-selected" ref={currentOptionsRef} onClick={mouseClick} style={{backgroundColor: currentColor, color: "#FFFFFF", border:'1px solid #2f72b5'}}>{ currentColor }</div>
        <div className="color-selection-options" ref={selectionOptionsRef} onMouseLeave={mouseLeave}>
            {
                colors.map((color_sublist_1:string[], index:number)=>{
                    return <div key={`color-container-${index}`}>
                        {
                            color_sublist_1.map((color_sublist_2:string, index2:number)=>{
                                return <div key={color_sublist_2} style={{backgroundColor:`${color_sublist_2}`}} onClick={ e => selectColor(color_sublist_2)}></div>
                            })
                        }
                    </div>
                })
            }
        </div>
    </div>
}