import React, { useState, useRef, useEffect } from  'react';
import IndexedDBFileStorage from '../inBrowserRecorderAndProcessingComponent/IndexedDBFileStorage';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { base64ToBlob, blobToBase64 } from 'base64-blob';
import API from '../../services/api';
import { fetchFile } from '@ffmpeg/util';
import axios from 'axios';
import Permissions from '../inBrowserRecorderAndProcessingComponent/Permissions';

interface InBrowserLinkedInVideoProcessorProps {
    apiKey:string;
    USER_URN_ID:string;
    USER_ACCESS_TOKEN:string;
    videoUrl:string;
    text:string;
    onCompletion: (success:boolean ) => void;
}

interface LinkedInInitReturnTypeValueUploadInstructions {
    uploadUrl: string;
    lastByte: number;
    firstByte: number;
}

interface LinkedInInitReturnTypeValue {
    uploadUrlsExpireAt: number;
    video: string;
    uploadInstructions: LinkedInInitReturnTypeValueUploadInstructions[];
    uploadToken: string;
}

interface LinkedInInitReturnType {
    value: LinkedInInitReturnTypeValue;
}

interface LinkedInVerifyReturnType {
    duration: number;
    aspectRatioWidth: number;
    owner: string;
    thumbnail: string;
    downloadUrlExpiresAt: number;
    downloadUrl: string;
    id: string;
    aspectRatioHeight: number;
    captions: string;
    status: string
}
// https://www.linkedin.com/help/lms/answer/a424737/video-ads-advertising-specifications?lang=en
export default function InBrowserLinkedInVideoProcessor(props:InBrowserLinkedInVideoProcessorProps){
    // State
    const BASE_PROXY_ENDPOINT = "http://proxy.clipdrop.io:9770";
    const BASE_PROXY_ENDPOINT_INIT = `${BASE_PROXY_ENDPOINT}/init`;
    const BASE_PROXY_ENDPOINT_UPLOAD = `${BASE_PROXY_ENDPOINT}/upload`;
    const BASE_PROXY_ENDPOINT_FINILIZE = `${BASE_PROXY_ENDPOINT}/finilize`;
    const BASE_PROXY_ENDPOINT_VERIFY = `${BASE_PROXY_ENDPOINT}/verify`;
    const BASE_PROXY_ENDPOINT_POST = `${BASE_PROXY_ENDPOINT}/post`;
    const debug = false;
    const [ currentState, setCurrentState ] = useState<string>("loading");
    const [ currentSubState, setCurrentSubState ] = useState<string>("");
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ uploadedURL, setUploadedURL ] = useState<string>("");
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const ffmpegRef = useRef(new FFmpeg());
    const dbRef = useRef(new IndexedDBFileStorage("recorded_file_data", 1));
    const [ videoBlob, setVideoBlob ] = useState<Blob | null>(null);
    const [ randomFileName, setRandomFileName ] = useState<string>(IndexedDBFileStorage.getRandomFileName(12));
    const linkedInInitFileUpload = async (UserUrnId:string, FileSizeInBytes:number, UserAccessBearerToken:string, api_key:string): Promise<{etag:string; uploadInstructions:{uploadUrl:string;lastByte:number;firstByte:number;}[]; video:string}> => {
        const headers = {
            "Content-Type": "application/json",
            "x-authorize": api_key,
        };
        const data = {
            "UserUrnId": UserUrnId,
            "FileSizeInBytes": FileSizeInBytes,
            "UserAccessBearerToken": UserAccessBearerToken,
        };
        const response = await axios.post(BASE_PROXY_ENDPOINT_INIT, data, { headers });
        const responseData = response.data;
        return responseData;
    }

    const linkedInUploadFile =  async (FileDataBlob:Blob, VideoUploadURL:string, api_key:string): Promise<string | null> => {
        const formData = new FormData();
        formData.append("file", FileDataBlob, "file.bin");
        formData.append("uploadURL", VideoUploadURL);
        const response = await fetch(BASE_PROXY_ENDPOINT_UPLOAD, {
            method: 'POST',
            body: formData,
        });
        const json = await response.json();
        return json.etag;
    }
    const linkedInFinilize = async (VideoAssetUrnId:string, VideoUploadEtags:string[], UserAccessBearerToken:string, api_key:string):Promise<boolean> => {
        const headers = {
            "Content-Type": "application/json",
            "x-authorize": api_key,
        };     
        const data = {
            "VideoAssetUrnId": VideoAssetUrnId,
            "VideoUploadEtags": VideoUploadEtags,
            "UserAccessBearerToken": UserAccessBearerToken,
        };
        const response = await fetch(BASE_PROXY_ENDPOINT_FINILIZE, {
            body: JSON.stringify(data),
            headers: headers,
            method: "POST",              
        });
        return response.status === 200;
    }
    const linkedInVerify = async (VideoAssetUrnId:string, UserAccessBearerToken:string, api_key:string):Promise<LinkedInVerifyReturnType> => {
        const url = BASE_PROXY_ENDPOINT_VERIFY;
        const headers = {
            "Content-Type": "application/json",
            "x-authorize": api_key,
        }; 
        const data = {
            VideoAssetUrnId: VideoAssetUrnId,
            UserAccessBearerToken: UserAccessBearerToken,
        };
        const response = await fetch(url, {
            headers: headers,
            method: "POST",
            body:JSON.stringify(data),
        });
        const responseData = await response.json();
        return responseData;
    }
    const linkedInPostUGC = async (UserUrnId:string, VideoAssetUrnId:string, UserAccessBearerToken:string, api_key:string, text:string):Promise<LinkedInVerifyReturnType> => {
        const url = BASE_PROXY_ENDPOINT_POST;
        const headers = {
            "Content-Type": "application/json",
            "x-authorize": api_key,
        }; 
        const data = {
            UserUrnId: UserUrnId,
            VideoAssetUrnId: VideoAssetUrnId,
            UserAccessBearerToken: UserAccessBearerToken,
            text: text,
        };
        const response = await fetch(url, {
            headers: headers,
            method: "POST",    
            body:JSON.stringify(data),        
        })
        const responseData = await response.json();
        return responseData;
    }
    const fromBlobToURL = (blob:any) => {
        return URL.createObjectURL(blob);
    }
    const fromURLToBlob = async (url: string):Promise<any> => {
        try {
          const response = await fetch(url);  
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
          }  
          const blob = await response.blob();
          const base64String = await blobToBase64(blob);
          return base64String;
        } catch (error) {
          console.error('Download failed:', error);
          throw error;
        }
    }
    const toBase64 = (file:any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const loadFFMPEG = async () => {
        let openned = await dbRef.current.openDatabase();
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        const coreURL = `${baseURL}/ffmpeg-core.js`;
        const wasmURL = `${baseURL}/ffmpeg-core.wasm`;
        let core:any = null;
        try{
            core = await dbRef.current.getFile("core");
        } catch (e) {
            console.log("XE1", e);  
        }
        let coreData = null;
        if(core){
            coreData = (await base64ToBlob(core.data)) as any;
        }
        if(!coreData){
            coreData = await fromURLToBlob(coreURL);
            let savedCore = await dbRef.current.addFile({
                id: "core",
                data: coreData,
                mimeType: "text/javascript"
            }); 
        } 
        try{
            core = await dbRef.current.getFile("core");
        } catch (e) {
            console.log("XE1", e);  
        }
        if(core){
            coreData = (await base64ToBlob(core.data)) as any;
        }
        let wasm:any = null; 
        try{
            wasm = await dbRef.current.getFile("wasm");
        } catch (e) {
            console.log("XE2", e);  
        }
        let wasmData:any = null;    
        if(wasm){
            wasmData = (await base64ToBlob(wasm.data)) as any;
        }
        if(!wasmData){            
            wasmData = await fromURLToBlob(wasmURL);
            let savedWasm = await dbRef.current.addFile({
                id: "wasm",
                data: wasmData,
                mimeType: "application/wasm"
            }); 
        }
        try{
            wasm = await dbRef.current.getFile("wasm");
        } catch (e) {
            console.log("XE2", e);  
        }  
        if(wasm){
            wasmData = (await base64ToBlob(wasm.data)) as any;
        }
        if(!coreData){
            console.log("coreData empty");
            return;
        }
        if(!wasmData){
            console.log("wasmData empty");
            return;
        }
        const coreBlob = new Blob([coreData], { type: "text/javascript"});
        const wasmBlob = new Blob([wasmData], { type: "application/wasm"});
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.load({
            coreURL: fromBlobToURL(coreBlob),
            wasmURL: fromBlobToURL(wasmBlob),
        });
        if(debug) ffmpeg.on("log", console.log);
                 
        let videoData = await fromURLToBlob(props.videoUrl);
        let savedWasm = await dbRef.current.addFile({
            id: randomFileName,
            data: videoData,
            mimeType: "video/mp4"
        });
        setCurrentState("processing");
        return true;
    };
    const uploadFile = async (fileData:any, setPercentage:(val:number)=>void) => {
        let uploadFileURL = "";     
        while (!uploadFileURL) {
            uploadFileURL = await API.file.uploadFileWithProgress(props.apiKey, "mp4", fileData, (percentage:any)=>{
                console.log(`UPLOAD ${percentage}%`);
                setPercentage(percentage);
            });
            uploadFileURL = uploadFileURL ?? "";
        }
        return uploadFileURL;
    }
    const handleCompressionAndUpload = async () => {
        // Load Database and file info/data
        const fdata = await dbRef.current.getFile(randomFileName);
        if(fdata === false){
            console.log("ERROR: Unable to read file from IndexedDatabase");
            setCurrentState("error");
            setCurrentSubState("ERROR: Unable to read file from IndexedDatabase");
            return;
        }
        const mimeTypeXX = fdata.mimeType;
        // Define Local Files
        let inputFile = `${randomFileName}.${mimeTypeXX.split("video/")[1]}`;
        let outputFile = `${randomFileName}-compressed.mp4`;
        // Clear if files are existing
        try{
            await ffmpegRef.current.deleteFile(inputFile);
        } catch(e){}
        try{
            await ffmpegRef.current.deleteFile(outputFile);
        } catch(e){}
        // Handle Compression
        const blob = await base64ToBlob(fdata.data);
        let fileWritten = await ffmpegRef.current.writeFile(
            inputFile, await fetchFile(blob)
        );
        /*let fileWritten2 = await ffmpegRef.current.writeFile(
            outputFile, await fetchFile(blob)
        );*/
        let converted = -1; 
        let counter = 1;
        console.log("CONVERTING FILE");
        // LOOP TILL A SUSCCESSFUL CONVERTION
        try{
            console.log("X1");
            const ffmpeg_arguments = [
                '-i', inputFile, // Input File
                '-movflags', 'faststart',
                '-preset', 'ultrafast', // Faster encoding preset
                '-r', '24', // Frame rate set to 24 FPS
                //'-vf', 'scale=w=960:h=trunc(ow/a/2)*2,pad=960:540:(960-iw)/2:(540-ih)/2', // Scale and pad to fit 960x540
                '-vf', 'scale=trunc(iw/2)*2:trunc(ih/2)*2', // Scale and pad to fit 960x540
                '-c:v', 'libx264',
                '-c:a', 'aac',
                '-ar', '44100', // Audio sample rate
                '-b:v', '198k',
                '-bufsize', '396k', // Buffer size adjusted
                '-b:a', '62k',
                outputFile // Output File
            ]
            console.log("ffmpeg_arguments", ffmpeg_arguments);
            converted = await ffmpegRef.current.exec(ffmpeg_arguments);
            counter += 1;        
        } catch(e){
            console.log(e);
        }
        try {
            console.log("FILE CONVERTED");
            const data = await ffmpegRef.current.readFile(outputFile);
            counter += 1;
            //const compressedBlob = new Blob([data], { type: 'video/mp4' });
            setVideoBlob(new Blob([data]));
        } catch(e){

        }
        /*let fileData = ((await toBase64(compressedBlob)) as string);
        const match = fileData.match(/base64,(\S+)/);
        if(match){
            fileData = match[1];
        }*/
        /*setUploadProgress(0);
        setIsUploading(true);
        console.log("UPLOADING");
        let fileURL = await uploadFile(fileData, setUploadProgress);
        setIsUploading(false);
        setUploadedURL(fileURL);*/
        setCurrentState("posting");
    }

    const UploadToLinkedIn = async () => {
        if(!videoBlob){
            setCurrentState("error");
            setCurrentSubState("Video Blob was not set");
            return;
        }
        if(!props.text){
            setCurrentState("error");
            setCurrentSubState("Text Not Set");
            return;
        }
        const initData = await linkedInInitFileUpload(props.USER_URN_ID, videoBlob.size, props.USER_ACCESS_TOKEN, props.apiKey);
        const initDataUploadInstructions = initData.uploadInstructions;
        const initDataVideoURN = initData.video;
        const uploadDataEtags:string[] = [];
        for(const uploadInstructions of initDataUploadInstructions){
            const uploadDataEtag = await linkedInUploadFile(videoBlob.slice(uploadInstructions.firstByte, uploadInstructions.lastByte), uploadInstructions.uploadUrl, props.apiKey);
            if(!uploadDataEtag){
                setCurrentState("error");
                setCurrentSubState("Etag is Missing");
                return;
            }
            uploadDataEtags.push(uploadDataEtag);
        }
        const uploadFinilize = await linkedInFinilize(initDataVideoURN, uploadDataEtags, props.USER_ACCESS_TOKEN, props.apiKey);
        if(!uploadFinilize){
            setCurrentState("error");
            setCurrentSubState("Video unable to be finilized");
            return;
        }
        setCurrentState("verify-upload");
        while(true){
            try {
                await Permissions.wait(5);
                const data = await linkedInVerify(initDataVideoURN, props.USER_ACCESS_TOKEN, props.apiKey);
                if(!data){
                    continue;
                }
                if(data.status === "AVAILABLE") break;
                if(data.status === "PROCESSING_FAILED"){                    
                    setCurrentState("error");
                    setCurrentSubState("Video was corrupted");
                    return;
                };
            } catch (e){

            }
        }
        setCurrentState("posting-video");
        const data2 = await linkedInPostUGC(props.USER_URN_ID, initDataVideoURN, props.USER_ACCESS_TOKEN, props.apiKey, props.text);
        setCurrentState("notice");
    }

    useEffect(()=>{
        if(currentState !== "loading") return;
        loadFFMPEG();
    }, [currentState]);
    
    useEffect(()=>{
        if(currentState !== "processing") return;
        handleCompressionAndUpload();
    }, [currentState]);
    
    useEffect(()=>{
        if(currentState !== "posting") return;
        if(!videoBlob) return;
        UploadToLinkedIn();
    }, [currentState, videoBlob]);
    
    if(currentState === "loading"){
        return <div className='in-browser-linkedin-component'>
            <h4>Loading</h4>
            <p>Please wait</p>
        </div>        
    }
    
    if(currentState === "error"){
        return <div className='in-browser-linkedin-component'>
            <h4>Error Posting To LinkedIn</h4>
            <p>{ currentSubState }</p>
            <div className='actions'>
                <button className='button-primary-blue' onClick={ e => {
                    setCurrentState("loading");
                }}>Retry</button>
                <button className='button-primary-blue' onClick={ e => {
                    props.onCompletion(false);
                }}>Go Back</button>
            </div>
        </div>
    }

    if(currentState === "processing"){
        return <div className='in-browser-linkedin-component'>
            <h4>{ !isUploading ? "Compressing Video" : "Uploading Video"}</h4>
            <p>Your video is being { !isUploading ? "compressed" : "uploaded"}.</p>
            { isUploading && <div><progress value={uploadProgress} max={100}/></div>}
            <p>Please wait while we handle this</p>
        </div>
    }
    
    if(currentState === "posting"){
        return <div className='in-browser-linkedin-component'>
            <h4>Posting Video To LinkedIn</h4>
            <p>Your video is being posted to LinkedIn.</p>
            <p>Please wait while we handle this</p>
        </div>        
    }

    if(currentState === "verify-upload"){
        return <div className='in-browser-linkedin-component'>
            <h4>Video Is Being Verified</h4>
            <p>It may take a few minutes for the video to Verified</p>
        </div>        
    }

    if(currentState === "posting-video"){
        return <div className='in-browser-linkedin-component'>
            <h4>Posting To Your LinkedIn Activity Feed</h4>
            <p>This will take a moment to complete.</p>
        </div>        
    }

    if(currentState === "notice"){
        return <div className='in-browser-linkedin-component'>
            <h4>Video Posted To LinkedIn</h4>
            <p>Your video has been posted to LinkedIn.</p>
            <p>It may take a few minutes for the video to display on LinkedIn</p>
            <div className='actions'>
                <button className='button-primary-blue' onClick={ e => {
                    props.onCompletion(true);
                }}>Continue</button>
            </div>
        </div>        
    }    

    return <div className='in-browser-linkedin-component'>
        <h4>Unknown State</h4>
        <div className='actions'>
            <button className='button-primary-blue' onClick={ e => {
                props.onCompletion(false);
            }}>Continue</button>
        </div>
    </div>   
}