import React, { useRef, useEffect, useState } from "react";
import CustomVideoComponentForITJ from "./customVideoComponentForITJ";
import "./itjComponent.css";
import API from "../../services/api";
import { toast } from "react-toastify";
import STTURLComponent from "../../components/stt-component-url/STTURLComponent";
export default function ITJComponent({apiKey, user, interview, reloadInterview}:{apiKey:string, user:any, interview:any, reloadInterview:() => void}) {
    // State and stuff
    const [ showContainer, setShowContainer ] = useState<boolean>(false);
    const [ companyIntroLibrary, setCompanyIntroLibrary] = useState<any[]>([]);
    const [ meetTheTeamIntroLibrary, setMeetTheTeamIntroLibrary] = useState<any[]>([]);
    const [ jobOverviewIntroLibrary, setJobOverviewIntroLibrary] = useState<any[]>([]);
    const [ cultureIntroLibrary, setCultureIntroLibrary] = useState<any[]>([]);
    const [ employeeValuePropositionIntroLibrary, setEmployeeValuePropositionIntroLibrary] = useState<any[]>([]);
    const [ showPreviewPopUp, setShowPreviewPopUp ] = useState<boolean>(false);
    const [ showPreviewPopUpVideoURL, setShowPreviewPopUpVideoURL ] = useState<string>("");
    // Subtitles
    const [ showAddSubtitlePopUp, setShowAddSubtitlePopUp ] = useState<boolean>(false);
    const [ showAddSubtitlePopUpVideoURL, setShowAddSubtitlePopUpVideoURL ] = useState<string>("");
    const [ showAddSubtitlePopUpState, setShowAddSubtitlePopUpState ] = useState<string>("options");
    const showAddSubtitleFunc = useRef<((video_url:string) => Promise<void>) | null>(null);
    const showAddSubtitleHidePreviewFunc = useRef<(() => void) | null>(null);
    const previewVideoRef = useRef<HTMLVideoElement | null>(null);


    // Functions
    const showVideoPreview = (video_url:string) => {
        // TODO: SHOW VIDEO PREVIEW IN A POPUP
        if(!video_url) return;
        setShowPreviewPopUpVideoURL(video_url);
        setShowPreviewPopUp(true);
    }

    const loadAllLibraries = async () => {
        let result = await API.library.getAll(apiKey, 0);
        let success = result['success'];
        if(success){
            setCompanyIntroLibrary(result['company_intro_videos']);
            setMeetTheTeamIntroLibrary(result['meet_the_team_videos']);
            setJobOverviewIntroLibrary(result['job_overview_videos']);
            setCultureIntroLibrary(result['culture_videos']);
            setEmployeeValuePropositionIntroLibrary(result['evp_videos']);
        }
    }

    // Effects
    useEffect(()=>{
        if(!interview) return; // skip untill interview is loaded
        setShowContainer(!interview.intro_video_url && !interview.meet_team_video_url && !interview.job_overview_video_url && !interview.culture_video_url && !interview.evp_video_url ? true : false);
        loadAllLibraries();
    }, [interview]); // only run this if interview state changes

    // Renders

    if(!interview){
        return <div className="itj-component-container">
            <div className="itj-toggle">
                <div>
                    <span>Loading Intro Videos</span>
                </div>
            </div>
        </div>
    }
    return <div className="itj-component-container">
    <div className="itj-toggle">
        <div>
            <span>Intro Videos</span>
            <button onClick={ e => { setShowContainer( x => !x)}}>{ showContainer ? "Hide" : "Show" }</button>
        </div>
    </div>
    { showContainer && <div className="itj-items">
        <CustomVideoComponentForITJ apiKey={apiKey} user={user} title="Company Intro" description="Add a Company intro video to your candidate interview process" currentVideoURL={interview.intro_video_url} libraryVideos={companyIntroLibrary} onPreview={ video_url => {
            showVideoPreview(video_url);
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Company Intro Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, new_video_url, interview.meet_team_video_url, interview.job_overview_video_url, interview.culture_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            showAddSubtitleHidePreviewFunc.current = () => {
                setShowPreviewPopUpVideoURL("");
                setShowPreviewPopUp(false);
            }
        }} onChange={ async video_url => {
            //if(!video_url) return;
            toast.info("Updating Company Intro Video");
            let result = await API.interview.setITJVideo(apiKey, interview.id, video_url, interview.meet_team_video_url, interview.job_overview_video_url, interview.culture_video_url, interview.evp_video_url);
            if(!result['success']){
                toast.error("Error saving video");
                return;
            }
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                if(!new_video_url) return;
                toast.info("Updating Company Intro Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, new_video_url, interview.meet_team_video_url, interview.job_overview_video_url, interview.culture_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            setShowAddSubtitlePopUpVideoURL(video_url);
            setShowAddSubtitlePopUp(false);
            reloadInterview();
        }}/>
        <CustomVideoComponentForITJ apiKey={apiKey} user={user} title="Meet The Team" description="Add a Team intro video to your candidate interview process" currentVideoURL={interview.meet_team_video_url} libraryVideos={meetTheTeamIntroLibrary} onPreview={ video_url => {
            showVideoPreview(video_url);
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Meet The Team Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, new_video_url, interview.job_overview_video_url, interview.culture_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            showAddSubtitleHidePreviewFunc.current = () => {
                setShowPreviewPopUpVideoURL("");
                setShowPreviewPopUp(false);
            }
        }} onChange={ async video_url => {            
            //if(!video_url) return;
            toast.info("Updating Meet The Team Video");
            let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, video_url, interview.job_overview_video_url, interview.culture_video_url, interview.evp_video_url);
            if(!result['success']){
                toast.error("Error saving video");
                return;
            }
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Meet The Team Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, new_video_url, interview.job_overview_video_url, interview.culture_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            setShowAddSubtitlePopUpVideoURL(video_url);
            setShowAddSubtitlePopUp(false);
            reloadInterview();
        }}/>
        <CustomVideoComponentForITJ apiKey={apiKey} user={user} title="Job Overview" description="Add a Position overview video to your candidate interview process" currentVideoURL={interview.job_overview_video_url} libraryVideos={jobOverviewIntroLibrary} onPreview={ video_url => {
            showVideoPreview(video_url);
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Job Overview Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, new_video_url, interview.culture_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            showAddSubtitleHidePreviewFunc.current = () => {
                setShowPreviewPopUpVideoURL("");
                setShowPreviewPopUp(false);
            }
        }} onChange={ async video_url => {
            //if(!video_url) return;
            toast.info("Updating Job Overview Video");
            let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, video_url, interview.culture_video_url, interview.evp_video_url);
            if(!result['success']){
                toast.error("Error saving video");
                return;
            }
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Job Overview Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, new_video_url, interview.culture_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            setShowAddSubtitlePopUpVideoURL(video_url);
            setShowAddSubtitlePopUp(false);
            reloadInterview();
        }}/>
        <CustomVideoComponentForITJ apiKey={apiKey} user={user} title="Culture" description="Add a culture video to your candidate interview process" currentVideoURL={interview.culture_video_url} libraryVideos={cultureIntroLibrary} onPreview={ video_url => {
            showVideoPreview(video_url);
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Culture Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, interview.job_overview_video_url, new_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            showAddSubtitleHidePreviewFunc.current = () => {
                setShowPreviewPopUpVideoURL("");
                setShowPreviewPopUp(false);
            }
        }} onChange={ async video_url => {
            //if(!video_url) return;
            toast.info("Updating Culture Video");
            let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, interview.job_overview_video_url, video_url, interview.evp_video_url);
            if(!result['success']){
                toast.error("Error saving video");
                return;
            }
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Culture Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, interview.job_overview_video_url, new_video_url, interview.evp_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            setShowAddSubtitlePopUpVideoURL(video_url);
            setShowAddSubtitlePopUp(false);
            reloadInterview();
        }}/>
        <CustomVideoComponentForITJ apiKey={apiKey} user={user} title="Employee Value Proposition" description="Add a EVP video to your candidate interview process" currentVideoURL={interview.evp_video_url} libraryVideos={employeeValuePropositionIntroLibrary} onPreview={ video_url => {
            showVideoPreview(video_url);
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Employee Value Proposition Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, interview.job_overview_video_url, interview.culture_video_url, new_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            showAddSubtitleHidePreviewFunc.current = () => {
                setShowPreviewPopUpVideoURL("");
                setShowPreviewPopUp(false);
            }
        }} onChange={ async video_url => {
            //if(!video_url) return;
            toast.info("Updating Employee Value Proposition Video");
            let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, interview.job_overview_video_url, interview.culture_video_url, video_url);
            if(!result['success']){
                toast.error("Error saving video");
                return;
            }
            showAddSubtitleFunc.current = async (new_video_url:string) => {
                //if(!new_video_url) return;
                toast.info("Updating Employee Value Proposition Video");
                let result = await API.interview.setITJVideo(apiKey, interview.id, interview.intro_video_url, interview.meet_team_video_url, interview.job_overview_video_url, interview.culture_video_url, new_video_url);
                if(!result['success']){
                    toast.error("Error saving video");
                    return;
                }
            }
            setShowAddSubtitlePopUpVideoURL(video_url);
            setShowAddSubtitlePopUp(false);
            reloadInterview();
        }}/>
    </div>}
    { (showPreviewPopUp && showPreviewPopUpVideoURL) && <div className="custom-popup-dialog">
        <div>
            <div className="header">
                <h4>Video Preview</h4>
                <button onClick={ e => {
                    setShowPreviewPopUpVideoURL("");
                    setShowPreviewPopUp(false);
                }}>X</button>
            </div>
            <div className="content">
                <video ref={previewVideoRef} src={showPreviewPopUpVideoURL} autoPlay={true} loop={false} controls={true} width={"480px"} height={"480px"}></video><br/>
                <button className="button-primary-blue" style={{display:'blocked'}} onClick={ e => {
                    toast.info("This feature is not currently avaliable");
                    return;                    
                    setShowAddSubtitlePopUpVideoURL(showPreviewPopUpVideoURL);
                    setShowAddSubtitlePopUpState("show");
                    setShowAddSubtitlePopUp(false);
                }}>Add Subtitles</button>
            </div>
        </div>
    </div> }
    { (showAddSubtitlePopUp && showAddSubtitlePopUpVideoURL) && <div className="custom-popup-dialog">
        <div style={{width:'720px'}}>
            <div className="header">
                <h4>Add Subtitles to your video?</h4>
                <button onClick={ e => {
                    setShowAddSubtitlePopUpVideoURL("options");
                    setShowAddSubtitlePopUp(false);
                    toast.info("Reloading Interview");
                    reloadInterview();
                }}>X</button>
            </div>
            <div className="content">
                { showAddSubtitlePopUpState === "options" && <div>
                        <button className="button-primary-blue block full" onClick={ e => {
                            setShowAddSubtitlePopUpState("show");
                        }}>Yes</button><br/><br/>
                        <button className="button-primary-blue block full" onClick={ e => {
                            setShowAddSubtitlePopUpVideoURL("");
                            setShowAddSubtitlePopUp(false);
                            toast.info("Reloading Interview");
                            reloadInterview();
                        }}>No</button>
                    </div>
                }
                { showAddSubtitlePopUpState === "show" && <div>
                    <STTURLComponent
                        videoURL={showAddSubtitlePopUpVideoURL}
                        onCompletion={async (video_url:string)=>{
                            if(!showAddSubtitleFunc){ toast.error("SUBTITLE FUNCTION MISSING"); return};
                            if(!showAddSubtitleFunc.current){ toast.error("SUBTITLE FUNCTION MISSING"); return};
                            await showAddSubtitleFunc.current(video_url);                            
                            setShowAddSubtitlePopUpVideoURL("options");
                            setShowAddSubtitlePopUpVideoURL("");
                            setShowAddSubtitlePopUp(false);
                            if(showAddSubtitleHidePreviewFunc){
                                if(showAddSubtitleHidePreviewFunc.current){
                                    showAddSubtitleHidePreviewFunc.current();
                                }
                            }
                            toast.info("Reloading Interview");
                            reloadInterview();
                        }}
                        onReject={()=>{
                            setShowAddSubtitlePopUpVideoURL("options");
                            setShowAddSubtitlePopUpVideoURL("");
                            setShowAddSubtitlePopUp(false);
                            if(showAddSubtitleHidePreviewFunc){
                                if(showAddSubtitleHidePreviewFunc.current){
                                    showAddSubtitleHidePreviewFunc.current();
                                }
                            }
                            toast.info("Reloading Interview");
                            reloadInterview();
                        }}
                        />
                    </div>
                }
            </div>
        </div>
    </div> }
</div>
}