import React, { createRef } from "react";
import { toast } from "react-toastify";
import API from "../services/api";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import './payPalCancelPage.css';

export default class PayPalCancelPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>PayPal Payment Returned</span>
                    </div>                
                </div>
            </div>
        );
      }
}