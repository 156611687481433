import React from "react";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

export default class IntegrationsPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
    }


    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    INTEGRATIONS
                </div>
            </div>
        );
      }
}