import React from "react";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";

import { AiOutlineLoading } from 'react-icons/ai';

import './pricing.css';

export default class PricingPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          loading: true
      };
      
      this.saveChanges = this.saveChanges.bind(this);
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        if(user.isAdmin == false){
            window.location.href = "/dashboard";
        }

        let keys = [
            // ZA PRCING
            'za_solo_monthly',
            'za_solo_monthly_without_marketing',
            'za_solo_yearly',
            'za_solo_yearly_without_marketing',            
            'za_lite_monthly',
            'za_lite_monthly_without_marketing',
            'za_lite_yearly',
            'za_lite_yearly_without_marketing',
            'za_business_lite_monthly',
            'za_business_lite_monthly_without_marketing',
            'za_business_lite_yearly',
            'za_business_lite_yearly_without_marketing',            
            'za_business_pro_monthly',
            'za_business_pro_monthly_without_marketing',
            'za_business_pro_yearly',
            'za_business_pro_yearly_without_marketing', 
            // US PRICING
            'us_solo_monthly',
            'us_solo_monthly_without_marketing',
            'us_solo_yearly',
            'us_solo_yearly_without_marketing',            
            'us_lite_monthly',
            'us_lite_monthly_without_marketing',
            'us_lite_yearly',
            'us_lite_yearly_without_marketing',
            'us_business_lite_monthly',
            'us_business_lite_monthly_without_marketing',
            'us_business_lite_yearly',
            'us_business_lite_yearly_without_marketing',            
            'us_business_pro_monthly',
            'us_business_pro_monthly_without_marketing',
            'us_business_pro_yearly',
            'us_business_pro_yearly_without_marketing',  
            // US PRICING PLAN IDS            
            'us_solo_monthly_plan_id',
            'us_solo_monthly_without_marketing_plan_id',
            'us_solo_yearly_plan_id',
            'us_solo_yearly_without_marketing_plan_id',            
            'us_lite_monthly_plan_id',
            'us_lite_monthly_without_marketing_plan_id',
            'us_lite_yearly_plan_id',
            'us_lite_yearly_without_marketing_plan_id',
            'us_business_lite_monthly_plan_id',
            'us_business_lite_monthly_without_marketing_plan_id',
            'us_business_lite_yearly_plan_id',
            'us_business_lite_yearly_without_marketing_plan_id',            
            'us_business_pro_monthly_plan_id',
            'us_business_pro_monthly_without_marketing_plan_id',
            'us_business_pro_yearly_plan_id',
            'us_business_pro_yearly_without_marketing_plan_id',             
        ];
        result = await API.pricing.getBulk( keys, 0);
        let pairs = result['pairs'];
        pairs.forEach((pair)=>{
            document.getElementById(pair.key).value = pair.value;
        });
 
        this.setState({
            ...this.state,
            loading:false
        });
    }

    async saveChanges(){
        this.setState({
            ...this.state,
            loading:true
        });
        let keys = [
            // ZA PRCING
            'za_solo_monthly',
            'za_solo_monthly_without_marketing',
            'za_solo_yearly',
            'za_solo_yearly_without_marketing',            
            'za_lite_monthly',
            'za_lite_monthly_without_marketing',
            'za_lite_yearly',
            'za_lite_yearly_without_marketing',
            'za_business_lite_monthly',
            'za_business_lite_monthly_without_marketing',
            'za_business_lite_yearly',
            'za_business_lite_yearly_without_marketing',            
            'za_business_pro_monthly',
            'za_business_pro_monthly_without_marketing',
            'za_business_pro_yearly',
            'za_business_pro_yearly_without_marketing', 
            // US PRICING
            'us_solo_monthly',
            'us_solo_monthly_without_marketing',
            'us_solo_yearly',
            'us_solo_yearly_without_marketing',            
            'us_lite_monthly',
            'us_lite_monthly_without_marketing',
            'us_lite_yearly',
            'us_lite_yearly_without_marketing',
            'us_business_lite_monthly',
            'us_business_lite_monthly_without_marketing',
            'us_business_lite_yearly',
            'us_business_lite_yearly_without_marketing',            
            'us_business_pro_monthly',
            'us_business_pro_monthly_without_marketing',
            'us_business_pro_yearly',
            'us_business_pro_yearly_without_marketing',  
            // US PRICING PLAN IDS            
            'us_solo_monthly_plan_id',
            'us_solo_monthly_without_marketing_plan_id',
            'us_solo_yearly_plan_id',
            'us_solo_yearly_without_marketing_plan_id',            
            'us_lite_monthly_plan_id',
            'us_lite_monthly_without_marketing_plan_id',
            'us_lite_yearly_plan_id',
            'us_lite_yearly_without_marketing_plan_id',
            'us_business_lite_monthly_plan_id',
            'us_business_lite_monthly_without_marketing_plan_id',
            'us_business_lite_yearly_plan_id',
            'us_business_lite_yearly_without_marketing_plan_id',            
            'us_business_pro_monthly_plan_id',
            'us_business_pro_monthly_without_marketing_plan_id',
            'us_business_pro_yearly_plan_id',
            'us_business_pro_yearly_without_marketing_plan_id',  
        ];

        let pairs = [];

        let api_key = localStorage.getItem('api_key');
        keys.forEach((key)=>{
            let v = document.getElementById(key).value;
            let value = key.indexOf('_plan_id') ? v : parseInt(v ?? '0');            
            pairs.push({
                'key': key,
                'value': value
            });
        });
        let result = await API.pricing.updateBulk(api_key, pairs);

        this.setState({
            ...this.state,
            loading:false
        });
    }

    render() {
        // HTML
        return (
            <>
                <div className="dashboardContainer">
                    <SideBarComponent></SideBarComponent>
                    <div className="dashboarContent">
                        <UpperBarComponent></UpperBarComponent>
                        <div className="pageNameInfo">
                            <span>Pricing</span>
                        </div>
                    </div>
                    <div className="vacancy-table prcing-table-2" style={{marginLeft:'250px'}}>
                        <button className="blueBTN" style={{width:'100%'}} onClick={this.saveChanges}>Save Changes</button><br/><br/>
                        <table>
                            <thead>
                                <tr>
                                    <th >Type</th>
                                    <th>South Africa (ZAR) as Cents</th>
                                    <th>Internatioanl (USD) as Cents</th>
                                    <th>Internatioanl (USD) Plan IDs</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>Solo Monthly</td><td><input type="text" id="za_solo_monthly" placeholder="0"/></td><td><input type="text" id="us_solo_monthly" placeholder="0"/></td><td><input type="text" id="us_solo_monthly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Solo Monthly Without Marketing</td><td><input type="text" id="za_solo_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_solo_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_solo_monthly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Solo Yearly</td><td><input type="text" id="za_solo_yearly" placeholder="0"/></td><td><input type="text" id="us_solo_yearly" placeholder="0"/></td><td><input type="text" id="us_solo_yearly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Solo Yearly Without Marketing</td><td><input type="text" id="za_solo_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_solo_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_solo_yearly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                
                                <tr><td>Lite Monthly</td><td><input type="text" id="za_lite_monthly" placeholder="0"/></td><td><input type="text" id="us_lite_monthly" placeholder="0"/></td><td><input type="text" id="us_lite_monthly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Lite Monthly Without Marketing</td><td><input type="text" id="za_lite_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_lite_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_lite_monthly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Lite Yearly</td><td><input type="text" id="za_lite_yearly" placeholder="0"/></td><td><input type="text" id="us_lite_yearly" placeholder="0"/></td><td><input type="text" id="us_lite_yearly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Lite Yearly Without Marketing</td><td><input type="text" id="za_lite_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_lite_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_lite_yearly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                
                                <tr><td>Business Lite Monthly</td><td><input type="text" id="za_business_lite_monthly" placeholder="0"/></td><td><input type="text" id="us_business_lite_monthly" placeholder="0"/></td><td><input type="text" id="us_business_lite_monthly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Business Lite Monthly Without Marketing</td><td><input type="text" id="za_business_lite_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_lite_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_lite_monthly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Business Lite Yearly</td><td><input type="text" id="za_business_lite_yearly" placeholder="0"/></td><td><input type="text" id="us_business_lite_yearly" placeholder="0"/></td><td><input type="text" id="us_business_lite_yearly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Business Lite Yearly Without Marketing</td><td><input type="text" id="za_business_lite_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_lite_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_lite_yearly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                
                                <tr><td>Business Pro Monthly</td><td><input type="text" id="za_business_pro_monthly" placeholder="0"/></td><td><input type="text" id="us_business_pro_monthly" placeholder="0"/></td><td><input type="text" id="us_business_pro_monthly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Business Pro Monthly Without Marketing</td><td><input type="text" id="za_business_pro_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_pro_monthly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_pro_monthly_without_marketing_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Business Pro Yearly</td><td><input type="text" id="za_business_pro_yearly" placeholder="0"/></td><td><input type="text" id="us_business_pro_yearly" placeholder="0"/></td><td><input type="text" id="us_business_pro_yearly_plan_id" placeholder="0"/></td></tr>
                                <tr><td>Business Pro Yearly Without Marketing</td><td><input type="text" id="za_business_pro_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_pro_yearly_without_marketing" placeholder="0"/></td><td><input type="text" id="us_business_pro_yearly_without_marketing_plan_id" placeholder="0"/></td></tr>
                            </tbody>
                        </table><br/>
                        <button className="blueBTN" style={{width:'100%'}} onClick={this.saveChanges}>Save Changes</button>
                    </div>
                </div>
                       
                { 
                    this.state.loading ? (
                        <div className="loader">                        
                            <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                        </div>
                    ) : ""
                }
            </>
        );
      }
}