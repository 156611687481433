import React, { createRef } from "react";
import { withRouter } from "react-router";

import { DateTime } from "luxon";

import { Tooltip as ReactTooltip } from "react-tooltip";

import { toast } from 'react-toastify';

import { BsPersonPlusFill, BsPencil, BsCloudUpload, BsCameraVideoOffFill } from "react-icons/bs";
import { IoIosDocument, IoIosPaperPlane } from "react-icons/io";
import { FaArrowRight, FaRegCommentDots } from "react-icons/fa";
import { ImBin2 } from "react-icons/im";


import { MdClear } from "react-icons/md";

import { AiFillFilter, AiFillEye } from "react-icons/ai";

import { RiShareForward2Fill } from 'react-icons/ri';

import { FaLinkedinIn, FaFacebook } from 'react-icons/fa';

import { AiFillStar, AiFillCamera, AiOutlineLoading } from 'react-icons/ai';

import { BsCameraVideoFill, BsFillCameraVideoFill } from 'react-icons/bs';

import { BiVideoRecording } from 'react-icons/bi';

import { FiSend } from 'react-icons/fi';

import play_icon_grey from "./../media/play_icon_grey.png";
import play_icon_color from "./../media/play_icon_color.png";

import './marketingview.css';

import SideBarComponent from "../components/sidebar";
import UpperBarComponent from "../components/upperbar";
import API from "../services/api";

import Webcam from "react-webcam";

import TutorialComponent from "../components/tutorial";



import VideoRecordComponent from './../components/videorecord';

import VideoUploadComponent from './../components/videoupload';

import Facebook from '../services/facebook';

import { LineChart, AreaChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

if(!window.FB){
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

const FB = window.FB;

class MarketingViewPage extends React.Component {
    constructor(props) {
      super(props);
     
      this.state = {
        loading: true,
        user: false,
        campaign: {
            id: "loading",
            title: "loading",
        },
        bespoke_leads: [],
        marketing_leads: [],
        social_leads: [],
        labels: [],
        isVisible: false,
        webcam: false,
        pages: false,
        data: [],
        data2: [],
        storageNotice: false,
        library_videos: []
      }
      
      this.filter_recording = false;
       this.filter_norecording = false;
       this.filter_ready_to_send = false;
       this.filter_viewed = false;
      this.customLabelTitle = "";
       
       this.sort_ids = false;
       this.sort_views = false;

      this.tab2 = createRef();
      this.tab3 = createRef();
      this.tab4 = createRef();

      this.socialDialogStep1Text = createRef();

      this.thumbnailTextOutreachRef = createRef();
      this.bulkTextRef = createRef();
      
      this.tab2btn = createRef();
      this.tab3btn = createRef();
      this.tab4btn = createRef();
      
      this.customLabelVideoPreviewDialogRef = createRef();
      this.customLabelVideoPreviewDialogVideoRef = createRef();

      this.ourWebsiteLinkRef = createRef();

      this.customLabelsRef = createRef();
      this.toggleCustomLabels = this.toggleCustomLabels.bind(this);
      
      this.reloadLabels = this.reloadLabels.bind(this);
      
      this.showLabelPreview = this.showLabelPreview.bind(this);
      this.deleteLabel = this.deleteLabel.bind(this);      
      
      this.tab1Actions = createRef();
      this.showTab1Actions = this.showTab1Actions.bind(this);
      this.startTab1Flow = this.startTab1Flow.bind(this);

      this.editFlow1Ref = createRef();
      this.editFlow2Ref = createRef();
      this.specialLinkedInRef = createRef();
      this.specialFacebookRef = createRef();
      this.hasLinkedInAuthRef = createRef();
      this.hasFacebookAuthRef = createRef();

      this.resetTabs = this.resetTabs.bind(this);
      this.openTab = this.openTab.bind(this);
      this.openTabDirect = this.openTabDirect.bind(this);
      this.handleState = this.handleState.bind(this);

      this.toggleEditFlow1 = this.toggleEditFlow1.bind(this);
      this.toggleEditFlow2 = this.toggleEditFlow2.bind(this);


      this.loadLeads =  this.loadLeads.bind(this);

      document.querySelector('body').style.background = "#EEF0F8";

      this.loadCampaign = this.loadCampaign.bind(this);
      this.saveSocialFlowText = this.saveSocialFlowText.bind(this);

      
      this.createLead1DialogRef = createRef();
      this.createLead1DialogNameRef = createRef();
      this.createLead1DialogEmailRef = createRef();
      this.createLead1DialogPhoneNumberRef = createRef();
      this.createLead1DialogLinkedInRef = createRef();

      this.socialVideoUploadRef = createRef();
      
      
      this.createLead1DialogRef2 = createRef();
      this.createLead1DialogNameRef2 = createRef();
      this.createLead1DialogEmailRef2 = createRef();
      this.createLead1DialogPhoneNumberRef2 = createRef();
      this.createLead1DialogLinkedInRef2 = createRef();

      
      this.getLeadViewTag = this.getLeadViewTag.bind(this);

      this.openNewLead = this.openNewLead.bind(this);
      this.createNewLead = this.createNewLead.bind(this);
      
      this.openNewLead2 = this.openNewLead2.bind(this);
      this.createNewLead2 = this.createNewLead2.bind(this);

      this.bespokeThumbnailOptionRef = createRef();
      this.updateBespokeThumbnailOption = this.updateBespokeThumbnailOption.bind(this);

      this.bespokeThumbnailTextRef = createRef();
      this.updatebespokeThumbnailText = this.updatebespokeThumbnailText.bind(this);

      this.bespokeMarketingTextRef = createRef();
      this.updateBespokeMarketingText = this.updateBespokeMarketingText.bind(this);
      this.updateBespokeMarketingWebsite = this.updateBespokeMarketingWebsite.bind(this);

      this.openCustomVideoPreview = this.openCustomVideoPreview.bind(this);

      this.deletedSelectLeads = this.deletedSelectLeads.bind(this);
      this.deletedSelectLeads2 = this.deletedSelectLeads2.bind(this);

      this.thumbnailTextOutreachOnChange = this.thumbnailTextOutreachOnChange.bind(this);
      this.bulkTextOnChange = this.bulkTextOnChange.bind(this);

      
      this.customCandidateVideoRef = createRef();
      this.customCandidateVideoPreviewRef = createRef();
      this.customCandidateVideo = createRef();      
      this.customCandidateVideoPreviewVideoRef = createRef();

      
      this.openCustomVideoUploadRe = this.openCustomVideoUploadRe.bind(this);

      
      this.customCandidateVideoRef = createRef();
      this.customCandidateVideoPreviewRef = createRef();
      this.customCandidateVideo = createRef();
      
      this.customCandidateVideoRef = createRef();
      this.customCandidateVideoPreviewRef = createRef();

      this.openCustomVideoUpload = this.openCustomVideoUpload.bind(this);

      this.customCandidateVideoelementRef = createRef();

      this.setCandidateCustomVideo = this.setCandidateCustomVideo.bind(this);
      
      this.stepFlow1DialogRef = createRef();

      this.startFlow1 = this.startFlow1.bind(this);
      this.startFlow2 = this.startFlow2.bind(this);
      
      this.stepFlow1DialogRefStep1 = createRef();
      this.stepFlow1DialogRefStep2 = createRef();

      this.searchLeadsBespoke = this.searchLeadsBespoke.bind(this);
      this.searchLeadsCampaign = this.searchLeadsCampaign.bind(this);
      this.searchBespokeRef = createRef();
      this.searchCampaignRef = createRef();

      this.deleteLead = this.deleteLead.bind(this);

      this.changeMarketingText = this.changeMarketingText.bind(this);
      this.changeSocialMarketingText = this.changeSocialMarketingText.bind(this);
      this.changeSocialMarketingCaption = this.changeSocialMarketingCaption.bind(this);
      
      this.loadCustomLabelLibraryVideos = this.loadCustomLabelLibraryVideos.bind(this);

      this.marketingTextRef = createRef();

      
      this.campaignMarketingVideoDialogOptions = createRef();
      this.openCampaignMarketingRecord = this.openCampaignMarketingRecord.bind(this);
      this.campaignMarketingVideoDialogRecordRef = createRef();
      this.campaignMarketingVideoDialogRecordElementRef = createRef();
      this.setCmapaignMarketingVideo = this.setCmapaignMarketingVideo.bind(this);
      
      this.scoailMarketingVideoDialogOptions = createRef();
      this.openSocialMarketingRecord = this.openSocialMarketingRecord.bind(this);
      this.openSocialMarketingUpload = this.openSocialMarketingUpload.bind(this);
      this.socialMarketingVideoDialogRecordRef = createRef();
      this.socialMarketingVideoDialogUploadRef = createRef();
      this.socialMarketingVideoDialogRecordElementRef = createRef();
      this.socialMarketingVideoDialogRecordElementRef2 = createRef();
      this.setSocialMarketingVideo = this.setSocialMarketingVideo.bind(this);
      
      this.campaignMarketingVideoDialogPreviewRef = createRef();
      this.campaignMarketingVideoDialogPreviewRefVideo = createRef();
      this.stepFlow2DialogRef = createRef();
      this.stepFlow2DialogRefStep1 = createRef();
      this.stepFlow2DialogRefStep2 = createRef();
      this.stepFlow2DialogRefStep3 = createRef();

      this.campaignMarketingVideoDialogRecordElement2Ref = createRef();

      this.socialMarketingImageDialogOptions = createRef();
      this.socialMarketingVideoDialogOptions = createRef();
      this.socialMarketingTextRef = createRef();
      this.socialMarketingCaptionRef = createRef();

      this.socialMarketingImageDialogOptions = createRef();
      this.socialMarketingVideoDialogOptions = createRef();
      
      this.stepFlow3DialogRef = createRef();
      this.stepFlow3DialogRefStep1 = createRef();
      this.stepFlow3DialogRefStep2 = createRef();
      this.stepFlow3DialogRefStep3 = createRef();
      this.socialMarketingVideoDialogPreviewRef = createRef();
      this.socialMarketingVideoDialogPreviewRefVideo = createRef();
      
      this.socialMarketingImageDialogPreviewRef = createRef();
      this.socialMarketingImageDialogPreviewRefImage = createRef();

      this.openSocialMarketingImageCapture = this.openSocialMarketingImageCapture.bind(this);
      this.openSocialMarketingImageUpload = this.openSocialMarketingImageUpload.bind(this);

      this.baseThumbnailDialogCapture = createRef();

      this.cameraRef = createRef();
      this.cameraCounterRef = createRef();
      this.imgRef = createRef();
      this.savecaptureImageRef = createRef();
      this.captureImage = this.captureImage.bind(this);
      this.savecaptureImage = this.savecaptureImage.bind(this);
      
      this.cameraRef2 = createRef();
      this.cameraCounterRef2 = createRef();
      this.imgRef2 = createRef();
      this.savecaptureImageRef2 = createRef();
      this.captureImage2 = this.captureImage2.bind(this);
      this.savecaptureImage2 = this.savecaptureImage2.bind(this);

      this.generateBespokeMarketingLinkDialog = this.generateBespokeMarketingLinkDialog.bind(this);
      this.generateBespokeMarketingLinkDialog2 = this.generateBespokeMarketingLinkDialog2.bind(this);
      this.generateSocialMarketingLinkDialog = this.generateSocialMarketingLinkDialog.bind(this);
      this.marketingLinkCopy = createRef();
      this.marketingLinkCopyText = createRef();
      this.marketingDescriptionCopyText = createRef();

      this.shareOnFacebook = this.shareOnFacebook.bind(this);
      this.shareOnLinkedIn = this.shareOnLinkedIn.bind(this);
      
      this.shareToFacebook = this.shareToFacebook.bind(this);
      this.shareToLinkedIn = this.shareToLinkedIn.bind(this);
      
      this.updateLinkedIn = this.updateLinkedIn.bind(this);
      this.updateFacebook = this.updateFacebook.bind(this);

      this.authLinkedIn = this.authLinkedIn.bind(this);
      this.loginFacebook = this.loginFacebook.bind(this);

      this.uploadMarketingVlogVideoToLinkedin = this.uploadMarketingVlogVideoToLinkedin.bind(this);

      this.acceptSoicalVideo = this.acceptSoicalVideo.bind(this);
      this.showPages = this.showPages.bind(this);
      
      this.facebookPagesRef = createRef();

      this.postToSelectedPages = this.postToSelectedPages.bind(this);

      
      this.socialFlowDialogRef = createRef();
      this.socialDialogStep0 = createRef();
      this.socialDialogStep0Record = createRef();
      this.socialDialogStep0Upload = createRef();
      this.socialDialogStep0UploadElementRef = createRef();
      this.socialDialogStep0RecordElementRef = createRef();
      this.socialDialogStep1 = createRef();

      this.analyticsRef = createRef();
      this.toggleAnalytics = this.toggleAnalytics.bind(this);
      this.loadAnalytics = this.loadAnalytics.bind(this);

      this.marketingOpenRate = createRef();
      this.leadEmailSubjectLine = createRef();
      
      this.leads1SortByDefault = this.leads1SortByDefault.bind(this);
      this.leads1SortByViewedDate = this.leads1SortByViewedDate.bind(this);
      this.leads1SortByVideoCreationDate = this.leads1SortByVideoCreationDate.bind(this);

      
      this.filterBespokeLeadsClear = this.filterBespokeLeadsClear.bind(this);
      this.filterBespokeLeadsRecording = this.filterBespokeLeadsRecording.bind(this);
      this.filterBespokeLeadsNoRecording = this.filterBespokeLeadsNoRecording.bind(this);
      this.filterBespokeLeadsReadyToSend = this.filterBespokeLeadsReadyToSend.bind(this);
      this.filterBespokeLeadsViewed = this.filterBespokeLeadsViewed.bind(this);

      this.filter1Ref = createRef();
      this.filter2Ref = createRef();
      this.filter3Ref = createRef();
      this.filter4Ref = createRef();

      this.filterBespokeLeads = this.filterBespokeLeads.bind(this);
      this.getProcessingTag = this.getProcessingTag.bind(this);
      this.getProcessingTag2 = this.getProcessingTag2.bind(this);
      this.reloadAllTab = this.reloadAllTab.bind(this);
      this.reloadTab1 = this.reloadTab1.bind(this);
      this.openLabelCreationDialog = this.openLabelCreationDialog.bind(this);
      this.openLabelCreationDialogRef = createRef();
      this.openLabelCreationDialogVideoRef = createRef();
      this.openLabelCreationDialogOptionRef = createRef();
      this.openLabelCreationDialogVideoRecorderRef = createRef();
      this.openLabelCreationDialogUploadRef = createRef();
      this.openLabelCreationDialogLibraryRef = createRef();
      this.openLabelCreationDialogLibraryOptionsRef = createRef();
      this.customLabelInputRef = createRef();
      
      this.addVideoToLibraryCustomDialogRef = createRef();
      this.addVideoToLibraryCustomDialogRefTitle = createRef();
      this.addVideoToLibraryCustomDialogRefVideo = createRef();
      
      this.nextCustomLabelStep1 = this.nextCustomLabelStep1.bind(this);

      this.lead_pulling = setInterval(()=>{
        this.reloadAllTab();
      }, 20000);
    }


    async loadCustomLabelLibraryVideos(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.labels.library.getAll(api_key, this.state.campaign.id);
        let videos = result['videos'];
        let container = this.openLabelCreationDialogLibraryOptionsRef.current;
        this.setState({
            ...this.state,
            library_videos: videos
        });
    }

    async nextCustomLabelStep1(){
        this.setState({
            ...this.state,
            loading: true
        });
        this.customLabelTitle = this.customLabelInputRef.current.value;
        if(this.customLabelTitle == "" || this.customLabelTitle == null || !this.customLabelTitle){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("A title is required");
            return;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.labels.exists(api_key, this.customLabelTitle, this.state.campaign.id);
        let success = result['success'];
        if(!success){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("This title is already in use, please use another one");
            return;
        }
        
        this.customLabelInputRef.current.value = "";
        this.openLabelCreationDialogRef.current.style.display = "none";  
        this.openLabelCreationDialogOptionRef.current.style.display = "flex";        
        this.setState({
            ...this.state,
            loading: false
        });
    }
    
    reloadAllTab(){
        this.reloadTab1();
        this.loadLeads();
    }

    async reloadTab1(){
        let ids = this.state.bespoke_leads.map((lead)=>{
            return lead.id;
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.leads.getBespokeLeadsFromIDs(api_key, ids);        
        let a = this.filter_recording;
        let b = this.filter_ready_to_send;
        let c = this.filter_viewed;
        let d = this.filter_norecording;
        let leads = result['leads'].filter((lead)=>{
            let has_video = lead.custom_video_url != "" && lead.custom_video_url != null;
            let ready_to_send = lead.processing_done == 1 && lead.emailed == 0 && lead.copied == 0;
            let viewed = lead.hasViewed;
            //////////
            // singles
            //////////
            // if no filter active
            if(a == false && b == false && c == false && d == false) return true;
            // is have video
            else if((a && has_video) && b == false && c == false && d == false) return true;
            // is ready to send
            else if(a == false && (b && ready_to_send) && c == false && d == false) return true;
            // has been viewed
            else if(a == false && b == false && (c && viewed) && d == false) return true;
            // has no video
            else if(a == false && b == false && c == false && ( d && !has_video)) return true;
            ///////////
            // multiple
            ///////////
            // has video and can be viewed
            else if((a && has_video) && b == false && (c && viewed) && d == false) return true;
            // has video and can be viewed and ready to send
            else if((a && has_video) && (b && ready_to_send) && (c && viewed) && d == false) return true;
            // has video and ready to send
            else if((a && has_video) && (b && ready_to_send) && viewed == false &&c == false && d == false) return true;
            // ready to send and viewed
            else if(a == false && (b && ready_to_send) && (c && viewed) && d == false) return true;
            // no matches
            return false;
        });
        this.setState({
            ...this.state,
            bespoke_leads: leads
        });
    }
    
    getProcessingTag(lead){
        if(lead.hasViewed && lead.closed) return <><span data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagBlue">Viewed</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
        if(lead.hasViewed && !lead.closed) return <><span data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagGreen">New View</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
        if(lead.copied) return <div className="blueBTN7">Copied</div>;
        if(lead.custom_video_url && lead.processing_done) return <div className="blueBTN7">Ready to send</div>;
        if(lead.processing_gif) return <div className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
        if(lead.processing_video) return <div className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
      }
      
    getProcessingTag2(lead){
        if(lead.hasViewed && lead.closed) return <><span data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagBlue">Viewed</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
        if(lead.hasViewed && !lead.closed) return <><span data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagGreen">New View</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
        if(lead.copied) return <div className="blueBTN7">Copied</div>;
        if(lead.processing_done) return <div className="blueBTN7">Ready to send</div>;
        if(lead.processing_gif) return <div className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
        if(lead.processing_video) return <div className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
      }

      async filterBespokeLeadsClear(){
          
        this.filter_recording = false;
        this.filter_norecording = false;
        this.filter_ready_to_send = false;
        this.filter_viewed = false;
        this.filter1Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter1Ref.current.style.color = "#000000";
        this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter2Ref.current.style.color = "#000000";
        this.filter3Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter3Ref.current.style.color = "#000000";
        this.filter4Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter4Ref.current.style.color = "#000000";

        this.filterBespokeLeads();   
      }

    async filterBespokeLeadsRecording(e){
        this.filter_recording = !this.filter_recording;
        e.target.style.backgroundColor = this.filter_recording ?  "#1976D1" : "#FFFFFF";
        e.target.style.color = this.filter_recording ?  "#FFFFFF" : "#000000";
        
        this.filter_norecording = false;
        this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter2Ref.current.style.color = "#000000";

        this.filterBespokeLeads();        
    }
    
    async filterBespokeLeadsNoRecording(e){
        this.filter_norecording = !this.filter_norecording;
        e.target.style.backgroundColor = this.filter_norecording ?  "#1976D1" : "#FFFFFF";
        e.target.style.color = this.filter_norecording ?  "#FFFFFF" : "#000000";


        this.filter_recording = false;
        this.filter_ready_to_send = false;
        this.filter_viewed = false;
        this.filter1Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter1Ref.current.style.color = "#000000";
        this.filter3Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter3Ref.current.style.color = "#000000";
        this.filter4Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter4Ref.current.style.color = "#000000";

        this.filterBespokeLeads();
    }
    
    async filterBespokeLeadsReadyToSend(e){
        this.filter_ready_to_send = !this.filter_ready_to_send;
        e.target.style.backgroundColor = this.filter_ready_to_send ?  "#1976D1" : "#FFFFFF";
        e.target.style.color = this.filter_ready_to_send ?  "#FFFFFF" : "#000000";
        this.filter_norecording = false;
        this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter2Ref.current.style.color = "#000000";
        this.filterBespokeLeads();
    }
    
    
    async filterBespokeLeadsViewed(e){
        this.filter_viewed = !this.filter_viewed;
        e.target.style.backgroundColor = this.filter_viewed ?  "#1976D1" : "#FFFFFF";
        e.target.style.color = this.filter_viewed ?  "#FFFFFF" : "#000000";
        this.filter_norecording = false;
        this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
        this.filter2Ref.current.style.color = "#000000";
        this.filterBespokeLeads();
    }

    toggleCustomLabels(){
        this.customLabelsRef.current.style.display = this.customLabelsRef.current.style.display == "block" ? "none" : 'block'
    }

    async filterBespokeLeads(){
        this.setState({
            ...this.state,
            loading: true
        });
        let a = this.filter_recording;
        let b = this.filter_ready_to_send;
        let c = this.filter_viewed;
        let d = this.filter_norecording;
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.leads.list(api_key, this.state.campaign.id, 0);
        let leads = result['leads'];
        let filteredLeads = leads.filter((lead)=>{
            let has_video = lead.custom_video_url != "" && lead.custom_video_url != null;
            let ready_to_send = lead.processing_done == 1 && lead.copied == 0;
            let viewed = lead.hasViewed;
            //////////
            // singles
            //////////
            // if no filter active
            if(a == false && b == false && c == false && d == false) return true;
            // is have video
            else if((a && has_video) && b == false && c == false && d == false) return true;
            // is ready to send
            else if(a == false && (b && ready_to_send) && c == false && d == false) return true;
            // has been viewed
            else if(a == false && b == false && (c && viewed) && d == false) return true;
            // has no video
            else if(a == false && b == false && c == false && ( d && !has_video)) return true;
            ///////////
            // multiple
            ///////////
            // has video and can be viewed
            else if((a && has_video) && b == false && (c && viewed) && d == false) return true;
            // has video and can be viewed and ready to send
            else if((a && has_video) && (b && ready_to_send) && (c && viewed) && d == false) return true;
            // has video and ready to send
            else if((a && has_video) && (b && ready_to_send) && viewed == false &&c == false && d == false) return true;
            // ready to send and viewed
            else if(a == false && (b && ready_to_send) && (c && viewed) && d == false) return true;
            // no matches
            return false;
        });
        this.setState({
            ...this.state,
            bespoke_leads: filteredLeads,
            loading: false
        });
    }
    
    getLeadViewTag(id, hasViewed, viewTime, currentServerTime, closed){
        if(!hasViewed) return <></>;
        if(closed == 0) return <span data-tip data-for={`tooltip-${id}-bespoke`} className="tagGreen">New View</span>;
        return <span data-tip data-for={`tooltip-${id}-bespoke`} className="tagBlue">Viewed</span>;
    }

    toggleAnalytics(){
        if(this.analyticsRef.current.style.display == "none"){
            this.analyticsRef.current.style.display = "block";
        } else {
            this.analyticsRef.current.style.display = "none";
        }
    }

    startTab1Flow(){
        this.socialFlowDialogRef.current.style.display = "flex";
        this.socialDialogStep0.current.style.display = "block";
        this.socialDialogStep0Record.current.style.display = "none";
        this.socialDialogStep0Upload.current.style.display = "none";
        this.socialDialogStep1.current.style.display = "none";
    }

    async searchLeadsBespoke(){
        let q = this.searchBespokeRef.current.value;
        if(q == ""){
            clearInterval(this.lead_pulling);
            this.lead_pulling = setInterval(()=>{
                this.reloadAllTab();
            }, 20000);
        } else {
            clearInterval(this.lead_pulling);
        }
        let api_key = localStorage.getItem('api_key');
        let cid = parseInt(this.props.match.params.id);
        let result = await API.marketing.leads.search.bespoke(api_key, cid, q);
        let success = result['success'];
        if(!success){
            toast("Error Searching Leads")
            return;
        }
        let leads = result['leads'];
        this.setState({
            ...this.state,
            bespoke_leads: leads
        });
    }
    

    async searchLeadsCampaign(){
        let q = this.searchCampaignRef.current.value;        
        if(q == ""){
            clearInterval(this.lead_pulling);
            this.lead_pulling = setInterval(()=>{
                this.reloadAllTab();
            }, 20000);
        } else {
            clearInterval(this.lead_pulling);
        }
        let api_key = localStorage.getItem('api_key');
        let cid = parseInt(this.props.match.params.id);
        let result = await API.marketing.leads.search.campaign(api_key, cid, q);
        let success = result['success'];
        if(!success){
            toast("Error Searching Leads")
            return;
        }
        let leads = result['leads'];
        this.setState({
            ...this.state,
            marketing_leads: leads
        });
    }

    async componentDidMount(){
        if(!window.FB){
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
        this.addVideoToLibraryCustomDialogRef.current.style.display = "none";
        this.openLabelCreationDialogOptionRef.current.style.display = "none";
        this.openLabelCreationDialogUploadRef.current.style.display = "none";
        this.openLabelCreationDialogLibraryRef.current.style.display = "none";
        this.customLabelVideoPreviewDialogRef.current.style.display = "none";
        this.openLabelCreationDialogRef.current.style.display = "none";
        this.openLabelCreationDialogVideoRef.current.style.display = "none";
        this.marketingOpenRate.current.style.display = "none";
        this.customLabelsRef.current.style.display = "none";
        this.tab1Actions.current.style.display = "none";
        this.analyticsRef.current.style.display = "none";
        this.socialFlowDialogRef.current.style.display = "none";
        this.stepFlow1DialogRef.current.style.display = "none";
        this.customCandidateVideoRef.current.style.display = "none";
        this.customCandidateVideoPreviewRef.current.style.display = "none";
        this.editFlow1Ref.current.style.display = "none";
        this.editFlow2Ref.current.style.display = "none";
        this.createLead1DialogRef.current.style.display = "none";
        this.createLead1DialogRef2.current.style.display = "none";
        this.campaignMarketingVideoDialogOptions.current.style.display = "none";
        this.campaignMarketingVideoDialogRecordRef.current.style.display = "none";
        this.campaignMarketingVideoDialogPreviewRef.current.style.display = "none";
        this.socialMarketingVideoDialogRecordRef.current.style.display = "none";
        this.socialMarketingVideoDialogUploadRef.current.style.display = "none";
        this.socialMarketingVideoDialogPreviewRef.current.style.display = "none";
        this.stepFlow2DialogRef.current.style.display = "none";
        this.stepFlow2DialogRefStep1.current.style.display = "none";
        this.stepFlow2DialogRefStep2.current.style.display = "none";
        this.stepFlow2DialogRefStep3.current.style.display = "none";
        this.socialMarketingVideoDialogOptions.current.style.display = "none";
        this.socialMarketingVideoDialogPreviewRef.current.style.display = "none";
        this.socialMarketingImageDialogPreviewRef.current.style.display = "none";
        this.socialMarketingImageDialogOptions.current.style.display = "none";
        this.baseThumbnailDialogCapture.current.style.display = "none";
        this.marketingLinkCopy.current.style.display = "none";
        this.specialLinkedInRef.current.style.display = "none";
        this.specialFacebookRef.current.style.display = "none";
        this.hasLinkedInAuthRef.current.style.display = "none";
        this.hasFacebookAuthRef.current.style.display = "none";
        this.facebookPagesRef.current.style.display = "none";
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        this.loadCampaign();    
        if(this.state.campaign.our_website){
            this.ourWebsiteLinkRef.current.innerHTML = API.validation.verifyURL(this.state.campaign.our_website) ? "<div style='fontSize:8pt;color:green'>Valid</div>" : "<div style='fontSize:8pt;color:red'>Invalid</div>";
        } else {
            this.ourWebsiteLinkRef.current.innerHTML = "";
        }
        this.setState({...this.state, user:user});
        this.resetTabs();
        this.tab2.current.style.display = "block";
        this.tab2btn.current.classList.add('active');
        this.handleState();
        this.loadLeads();
        this.updateLinkedIn();
        this.updateFacebook();
        this.loadAnalytics(); 
    }

    async loadAnalytics(){
        this.setState({...this.state, loading:true});
        let api_key = localStorage.getItem('api_key');
        let cid = parseInt(this.props.match.params.id);
        let result = await API.marketing.campaign.getAnalytics(api_key, cid, 0);
        let data = result['data'];
        result = await API.marketing.campaign.getAnalytics2(api_key, cid, 0);
        let data2 = result['data'];
        this.setState({
            ...this.state,
            data: data,
            data2: data2,
            loading:false
        });
    }

    updateLinkedIn(){
        let isNotExpired = (new Date().getTime() - (new Date(this.state.user.linkedin_user_access_token_expiry_date)).getTime()) < 0;
        if(this.state.user.linkedin_user_access_token && this.state.user.linkedin_user_id && isNotExpired){            
            this.hasLinkedInAuthRef.current.style.display = "none";
            this.specialLinkedInRef.current.style.display = "inline-block";
        } else {
            this.hasLinkedInAuthRef.current.style.display = "block";
            this.specialLinkedInRef.current.style.display = "none";
        }
    }
    updateFacebook(){
        let self = this;        
        if(!window.FB){
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
  
        const FB = window.FB;
        FB.getLoginStatus(function(response) {
            console.log(response);
            let status = response['status'];
            if(status != "connected"){
                self.hasFacebookAuthRef.current.style.display = "block";
                self.specialFacebookRef.current.style.display = "none";
                return;
            }
            self.hasFacebookAuthRef.current.style.display = "none";
            self.specialFacebookRef.current.style.display = "inline-block";
        });
    }


    async loadCampaign(){        
        let api_key = localStorage.getItem('api_key');
        let vid = parseInt(this.props.match.params.id);
        let result = await API.marketing.campaign.get(api_key, vid);
        let campaign = result['campaign'];
        this.setState({...this.state, campaign: campaign});
        let counts = campaign.openDiff.bespoke + campaign.openDiff.campaign + campaign.openDiff.social;
        if(counts > 0){
            this.marketingOpenRate.current.style.display = "flex";
        }
        result = await API.storage.getMyStorage(api_key);
        let total = result['total'];
        let used = result['used'];
        let assigned = result['assigned'];
               
        this.reloadLabels();
        
        this.setState({
            ...this.state,
            storageNotice: ((total-(used + assigned))<= 1)
        });
    }
    async loadLeads(){
        let vid = parseInt(this.props.match.params.id);
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.getLeads(api_key, vid);
        let bespoke_leads = result['leads']['bespoke'];
        //bespoke_leads = bespoke_leads.sort((a, b)=>{
        //    // TODO: order correctly
        //});
        let marketing_leads = result['leads']['marketing'];
        let social_leads = result['leads']['social'];
        this.setState({...this.state, loading: false,
            bespoke_leads: bespoke_leads,
            marketing_leads: marketing_leads,
            social_leads: social_leads
        });
    }

    resetTabs(){
        this.tab2.current.style.display = "none";
        this.tab3.current.style.display = "none";
        this.tab4.current.style.display = "none";
        
        
        this.tab2btn.current.classList.remove('active');
        this.tab3btn.current.classList.remove('active');
        this.tab4btn.current.classList.remove('active');
      }


    openTab(e){
        this.resetTabs();
        let tabID = parseInt(e.target.getAttribute('tab-id'));
        if(tabID == 2){
          this.tab2.current.style.display = "block";
          this.tab2btn.current.classList.add('active');
          this.addStateToURL('marketing');
        } else if(tabID == 3){
          this.tab3.current.style.display = "block";
          this.tab3btn.current.classList.add('active');
          this.addStateToURL('campaign');
        } else if(tabID == 4){
          this.tab4.current.style.display = "block";
          this.tab4btn.current.classList.add('active');
          this.addStateToURL('social');
        }
      }
      
      openTabDirect(tabID){
        this.resetTabs();
        if(tabID == 2){
          this.tab2.current.style.display = "block";
          this.tab2btn.current.classList.add('active');
          this.addStateToURL('marketing');
        } else if(tabID == 3){
          this.tab3.current.style.display = "block";
          this.tab3btn.current.classList.add('active');
          this.addStateToURL('campaign');
        } else if(tabID == 4){
          this.tab4.current.style.display = "block";
          this.tab4btn.current.classList.add('active');
          this.addStateToURL('social');
        }
      }

      
    addStateToURL(state){
        window.history.replaceState(null, "", window.location.pathname + "?#" + state);
      }

      
    handleState(){
        let url = window.location.href;
        if(url.indexOf('#marketing') !== -1){
          this.openTabDirect(2);    
        } else if(url.indexOf('#campaign') !== -1){
          this.openTabDirect(3);     
        } else if(url.indexOf('#social') !== -1){
          this.openTabDirect(4);        
        } else {
            this.openTabDirect(2);   
        }
      }


      toggleEditFlow1(){
        this.editFlow1Ref.current.style.display = this.editFlow1Ref.current.style.display == "none" ? "block" : "none";
    }

    toggleEditFlow2(){
        this.editFlow2Ref.current.style.display = this.editFlow2Ref.current.style.display == "none" ? "block" : "none";
    }
    openNewLead(){
        this.createLead1DialogRef.current.style.display = "flex";
        this.createLead1DialogNameRef.current.value = "";
        this.createLead1DialogEmailRef.current.value = "";
        this.createLead1DialogPhoneNumberRef.current.value = "";
        this.createLead1DialogLinkedInRef.current.value = "";
    }

    
    openNewLead2(){
        if(!this.state.campaign.campaign_video_url || this.state.campaign.campaign_video_url == "" || this.state.campaign.campaign_video_url == null){
          toast.warning("Warning! Before you add a lead you must please click the start button and finish the recording process", "warning");
          return;
        }
        this.createLead1DialogRef2.current.style.display = "flex";
        this.createLead1DialogNameRef2.current.value = "";
        this.createLead1DialogEmailRef2.current.value = "";
        this.createLead1DialogPhoneNumberRef2.current.value = "";
        this.createLead1DialogLinkedInRef2.current.value = "";
    }

    async createNewLead(e){   
        e.target.disabled = true;     
        this.setState({
            ...this.state,
            loading: true
        });
        let name = this.createLead1DialogNameRef.current.value;
        let email = this.createLead1DialogEmailRef.current.value;
        let phonenumber = this.createLead1DialogPhoneNumberRef.current.value;
        let linkedin_url = this.createLead1DialogLinkedInRef.current.value;
        if(!name){        
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Enter a valid name for the lead");
            e.target.disabled = false;
            return;
        }
        if(!email){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Enter a valid email for the lead");
            e.target.disabled = false;
            return;
        }

        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.leads.create(api_key, name, email, phonenumber, linkedin_url, this.state.campaign.id, 0);
        
        if(!result['success']){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Error creating lead");
            e.target.disabled = false;
            return;
        }
        this.setState({
            ...this.state,
            loading: false
        });
        this.createLead1DialogRef.current.style.display = "none";
        toast("Lead created");
        e.target.disabled = false;
        this.loadLeads();
    }
    
    async createNewLead2(e){
        e.target.disabled = true;
        this.setState({
            ...this.state,
            loading: true
        });
        let name = this.createLead1DialogNameRef2.current.value;
        let email = this.createLead1DialogEmailRef2.current.value;
        let phonenumber = this.createLead1DialogPhoneNumberRef2.current.value;
        let linkedin_url = this.createLead1DialogLinkedInRef2.current.value;
        if(!name){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Enter a valid name for the lead");
            e.target.disabled = false;
            return;
        }
        if(!email){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Enter a valid email for the lead");
            e.target.disabled = false;
            return;
        }

        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.leads.create(api_key, name, email, phonenumber, linkedin_url, this.state.campaign.id, 1);
        
        if(!result['success']){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Error creating lead");
            e.target.disabled = false;
            return;
        }
        this.setState({
            ...this.state,
            loading: false
        });
        this.createLead1DialogRef2.current.style.display = "none";
        toast("Lead created");
        e.target.disabled = false;
        this.loadLeads();
    }
    
    async updateBespokeThumbnailOption(e){
        let option = e.target.value;
        console.log(option);
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.bespoke.setThubmanailOption(api_key, this.state.campaign.id, option);
        let success = result['success'];
        if(!success){
            toast("Error updating camapign");
            return;
        }
        toast("Bespoke Thumbnail Updated");
        this.loadCampaign();
    }

    async updatebespokeThumbnailText(e){
        let text = e.target.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.bespoke.setThubmanailText(api_key, this.state.campaign.id, text);
        this.loadCampaign();
    }

    async updateBespokeMarketingText(e){
        let text = e.target.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.bespoke.setMarketingText(api_key, this.state.campaign.id, text);
        this.loadCampaign();
    }
    
    async updateBespokeMarketingWebsite(e){
        let text = e.target.value;
        if(text){
            this.ourWebsiteLinkRef.current.innerHTML = API.validation.verifyURL(text) ? "<div style='fontSize:8pt;color:green'>Valid URL</div>" : "<div style='fontSize:8pt;color:red'>Invalid URL</div>"
        } else {
            this.ourWebsiteLinkRef.current.innerHTML = "";
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.bespoke.setMarketingOurWebsite(api_key, this.state.campaign.id, text);
        this.loadCampaign();
    }


    openCustomVideoPreview(e){
        let cid = parseInt(e.target.getAttribute('lead-id'));   
        let cvurl = e.target.getAttribute('lead-video');    
        this.customCandidateVideoPreviewRef.current.style.display = "flex"; 
        this.customCandidateVideoPreviewRef.current.setAttribute('lead-id', cid); 
        this.customCandidateVideoPreviewVideoRef.current.src = cvurl;   
        this.customCandidateVideoPreviewVideoRef.current.play();
      }  
      
      async deletedSelectLeads(){
        // get elements
        let ids = Array.from(document.querySelectorAll('.marketing_bespoke_lead:checked')).map((item)=>{return parseInt(item.getAttribute('lead-id'));});
        // check count -> display message else continue
        if(ids.length <= 0){
        toast("You need to select one or more leads inorder to delete them");
        return;
        }
        // make API call and show loading
        this.setState({
        ...this.state,
        loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.leads.deleteAll(api_key, ids);
        // reload tabs
        this.reloadAllTab();
        // hide loading
        this.setState({
        ...this.state,
        loading: false
        });
      }
      
      async deletedSelectLeads2(){
        // get elements
        let ids = Array.from(document.querySelectorAll('.marketing_campaign_lead:checked')).map((item)=>{return parseInt(item.getAttribute('lead-id'));});
        // check count -> display message else continue
        if(ids.length <= 0){
        toast("You need to select one or more leads inorder to delete them");
        return;
        }
        // make API call and show loading
        this.setState({
        ...this.state,
        loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.leads.deleteAll(api_key, ids);
        // reload tabs
        this.reloadAllTab();
        // hide loading
        this.setState({
        ...this.state,
        loading: false
        });
      }
  

      async thumbnailTextOutreachOnChange(e){
        let type_value = parseInt(e.target.value);
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.setCustomThumbnailType(api_key, this.state.campaign.id, type_value);                     
        let vid = parseInt(this.props.match.params.id);
        result = await API.marketing.campaign.get(api_key, vid);
        let campaign = result['campaign'];
        this.setState({...this.state, campaign: campaign});
      }
      
      async bulkTextOnChange(e){
        let text = e.target.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.setCustomThumbnailText(api_key, this.state.campaign.id, text);
        let vid = parseInt(this.props.match.params.id);
        result = await API.marketing.campaign.get(api_key, vid);
        let campaign = result['campaign'];
        this.setState({...this.state, campaign: campaign});
      }

  openCustomVideoUploadRe(e){
    let api_key = localStorage.getItem('api_key');
    this.customCandidateVideoPreviewVideoRef.current.pause();
    let cid = parseInt(this.customCandidateVideoPreviewRef.current.getAttribute('lead-id'));
    this.customCandidateVideoRef.current.style.display = "flex"; 
    this.customCandidateVideoRef.current.setAttribute('lead-id', cid);  
    this.customCandidateVideoPreviewRef.current.style.display = "none";  
    this.customCandidateVideoelementRef.current.resetUI();  
    this.customCandidateVideoelementRef.current.openVideo();
    this.customCandidateVideoelementRef.current.setPreviewData(2, 0, cid, api_key);
  }

  
  openCustomVideoUpload(e){
    let api_key = localStorage.getItem('api_key');
    let cid = parseInt(e.target.getAttribute('lead-id'));
    this.customCandidateVideoRef.current.style.display = "flex"; 
    this.customCandidateVideoRef.current.setAttribute('lead-id', cid); 
    this.customCandidateVideoelementRef.current.resetUI();     
    this.customCandidateVideoelementRef.current.openVideo();
    this.customCandidateVideoelementRef.current.setPreviewData(2, 0, cid, api_key);
  }

  async setCandidateCustomVideo(video_url){
    this.setState({
      ...this.state,
      loading: true
    });
    let lid = parseInt(this.customCandidateVideoRef.current.getAttribute('lead-id'));   
    let api_key = localStorage.getItem('api_key');
    let result = await API.marketing.leads.setCustomVideo(api_key, lid, video_url);
    let success = result['success'];
    if(success){
      toast("Video Saved");
      this.customCandidateVideoRef.current.style.display="none";
      this.loadLeads();
      this.setState({
        ...this.state,
        loading: false
      });
      } else {
        this.setState({
            ...this.state,
            loading: false
          });
        toast("Error Saving Video");
    }
  }
      
  startFlow1(){
    this.stepFlow1DialogRef.current.style.display = "flex";
    this.stepFlow1DialogRefStep1.current.style.display="block";
    this.stepFlow1DialogRefStep2.current.style.display="none";
  }
  
  startFlow2(){
    this.stepFlow2DialogRef.current.style.display = "flex";
    this.stepFlow2DialogRefStep1.current.style.display="block";
    this.stepFlow2DialogRefStep2.current.style.display="none";
    this.stepFlow2DialogRefStep3.current.style.display="none";
    this.campaignMarketingVideoDialogRecordElement2Ref.current.resetUI();
    this.campaignMarketingVideoDialogRecordElement2Ref.current.openVideo();
  }


  savecaptureImage2(){
    this.socialMarketingVideoDialogRecordElementRef.current.resetUI();
  }

  async deleteLead(e){
    let lid = parseInt(e.target.getAttribute('lead-id')); 
    this.setState({
        ...this.state,
        loading: true
    });

    let api_key = localStorage.getItem('api_key');
    let result = await API.marketing.leads.delete(api_key, lid);
    this.loadLeads();
    this.setState({
        ...this.state,
        loading: false
      });
  }

  
    
  async changeMarketingText(e){
    let text = e.target.value;
    let api_key = localStorage.getItem('api_key');
    let result = await API.marketing.campaign.campaign.setText(api_key, this.state.campaign.id, text);
}
  
    
async changeSocialMarketingText(e){
      let text = e.target.value;
      let api_key = localStorage.getItem('api_key');
      let result = await API.marketing.campaign.social.setText(api_key, this.state.campaign.id, text);
      this.loadCampaign();
  }
    
async changeSocialMarketingCaption(e){
      let text = e.target.value;
      let api_key = localStorage.getItem('api_key');
      let result = await API.marketing.campaign.social.setCaption(api_key, this.state.campaign.id, text);
      this.loadCampaign();
  }

    

    openCampaignMarketingRecord(){
        this.campaignMarketingVideoDialogOptions.current.style.display = "none";
        this.campaignMarketingVideoDialogRecordRef.current.style.display = "flex";
        this.campaignMarketingVideoDialogRecordElementRef.current.resetUI();
        this.campaignMarketingVideoDialogRecordElementRef.current.openVideo();

    }
    
    openSocialMarketingRecord(){
        this.setState({
            ...this.state,
            webcam: false
        });
        this.socialMarketingVideoDialogOptions.current.style.display = "none";
        this.socialMarketingVideoDialogRecordRef.current.style.display = "flex";
        this.socialMarketingVideoDialogRecordElementRef2.current.resetUI();
        this.socialMarketingVideoDialogRecordElementRef2.current.openVideo();
    }

    openSocialMarketingUpload(){
        this.socialMarketingVideoDialogOptions.current.style.display = "none";
        this.socialMarketingVideoDialogUploadRef.current.style.display = "flex";
        this.socialVideoUploadRef.current.resetUI();
    }

    async setCmapaignMarketingVideo(video_url){
        this.setState({
            ...this.state,
            loading: true
          });
        let api_key = localStorage.getItem('api_key');     
        let result = await API.marketing.campaign.campaign.setVideo(api_key, this.state.campaign.id, video_url);
        this.campaignMarketingVideoDialogRecordRef.current.style.display="none";
        if(result['success'] === false){
          toast("Maxed Storage Reached");
        setTimeout(()=>{
            window.location.href = "/dashboard";
        }, 5000);
        return;
        }
        this.loadCampaign();
        this.setState({
            ...this.state,
            loading: false
        });
    }

    async setSocialMarketingVideo(video_url){
        this.setState({
            ...this.state,
            loading: true
          });
        let api_key = localStorage.getItem('api_key');     
        let result = await API.marketing.campaign.social.setVideo(api_key, this.state.campaign.id, video_url);
        this.socialMarketingVideoDialogRecordRef.current.style.display="none";
        this.loadCampaign();
        this.setState({
            ...this.state,
            loading: false
        });
    }

    async generateBespokeMarketingLinkDialog(e){
        this.setState({
            ...this.state,
            loading: true
          });
        let lid = parseInt(e.target.getAttribute('lead-id')); 
        this.lead_id = lid;
        let api_key = localStorage.getItem('api_key');  
        let result = await API.marketing.leads.getBespokeLink(api_key, this.state.campaign.id, lid);      
        let link = result['link'];
        this.marketingLinkCopy.current.style.display = "flex";
        this.marketingLinkCopyText.current.value = link;
        this.marketingDescriptionCopyText.current.value = this.state.campaign.marketing_text;
        let result2 = await API.marketing.campaign.addSendRate(api_key, this.state.campaign.id, 0);
        this.loadLeads();
        this.setState({
            ...this.state,
            loading: false
        });
    }
    

    async generateBespokeMarketingLinkDialog2(e){
        this.setState({
            ...this.state,
            loading: true
          });
        let lid = parseInt(e.target.getAttribute('lead-id')); 
        this.lead_id = lid;
        let api_key = localStorage.getItem('api_key');  
        let result = await API.marketing.leads.getMarketingLink(api_key, this.state.campaign.id, lid);      
        let link = result['link'];
        this.marketingLinkCopy.current.style.display = "flex";
        this.marketingLinkCopyText.current.value = link;
        this.marketingDescriptionCopyText.current.value = this.state.campaign.campaign_text;
        let result2 = await API.marketing.campaign.addSendRate(api_key, this.state.campaign.id, 1);
        this.loadLeads();
        this.setState({
            ...this.state,
            loading: false
        });
    }
    
    async generateSocialMarketingLinkDialog(){
        this.setState({
            ...this.state,
            loading: true
          });
        let api_key = localStorage.getItem('api_key');  
        let result = await API.marketing.leads.getSocialLink(api_key, this.state.campaign.id);      
        let link = result['link'];
        this.marketingLinkCopy.current.style.display = "flex";
        this.marketingLinkCopyText.current.value = link;
        this.setState({
            ...this.state,
            loading: false
        });
    }

    openSocialMarketingImageCapture(){
        this.setState({...this.state, isVisible: true});
        this.socialMarketingImageDialogOptions.current.style.display = "none";
        this.baseThumbnailDialogCapture.current.style.display = "flex";
        this.cameraCounterRef.current.style.display = 'block';
        this.imgRef.current.style.display = "none";
        this.savecaptureImageRef.current.style.display = "none";
    }

    openSocialMarketingImageUpload(){
        this.socialMarketingImageDialogOptions.current.style.display = "none";        
    }

    
  async captureImage(){
    this.cameraRef.current.video.style.display = "block";
    this.cameraCounterRef.current.style.display = 'block';
    this.cameraCounterRef.current.innerHTML = '3';
    setTimeout(()=>{
      this.cameraCounterRef.current.innerHTML = '2';
      setTimeout(()=>{      
        this.cameraCounterRef.current.innerHTML = '1';
        setTimeout(()=>{
          this.cameraCounterRef.current.style.display = 'none';
          let image_data = this.cameraRef.current.getScreenshot({width: 1920, height: 1080});
          this.cameraRef.current.video.style.display = "none";
          this.imgRef.current.style.display = "block";
          this.imgRef.current.src = image_data;
          this.setState({
            image_data: image_data
          });
          this.savecaptureImageRef.current.style.display = "block";
        }, 1000);
      }, 1000);      
    }, 1000);    
  }

  
  shareOnFacebook = (app_id, href, redirect_url, quote) => {
    let params = "width=600,height=600,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,";
    window.open("https://www.facebook.com/dialog/share?app_id=" + app_id + "&display=popup&href=" + href + "&quote=" + quote, 'popup', params);
  }
  shareOnLinkedIn = (href, quote) => {
    let params = "width=600,height=600,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,";
    let w = window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + href, 'popup', params);
  }

  shareToFacebook(){
    let url = 'https://api.clipdrop.io/spms/' + btoa(this.state.campaign.id) + "?&t=" + Math.floor(Date.now() / 1000);
    let redirect = url;
    let quote = "";
    this.shareOnFacebook('940152470118793', url, redirect, quote);
  }

  shareToLinkedIn(){
    let url = 'https://api.clipdrop.io/spms/' + btoa(this.state.campaign.id) + "?&t=" + Math.floor(Date.now() / 1000);
    let quote = "";
    this.shareOnLinkedIn(url, quote);
  }
  
  async captureImage2(){
    this.cameraRef2.current.video.style.display = "block";
    this.cameraCounterRef2.current.style.display = 'block';
    this.cameraCounterRef2.current.innerHTML = '3';
    setTimeout(()=>{
      this.cameraCounterRef2.current.innerHTML = '2';
      setTimeout(()=>{      
        this.cameraCounterRef2.current.innerHTML = '1';
        setTimeout(()=>{
          this.cameraCounterRef2.current.style.display = 'none';
          let image_data = this.cameraRef2.current.getScreenshot({width: 1920, height: 1080});
          this.cameraRef2.current.video.style.display = "none";
          this.imgRef2.current.style.display = "block";
          this.imgRef2.current.src = image_data;
          this.setState({
            image_data: image_data
          });
          this.savecaptureImageRef2.current.style.display = "block";
        }, 1000);
      }, 1000);      
    }, 1000);    
  }

  async savecaptureImage(){
    this.savecaptureImageRef.current.style.display = "none";
    let image_data = this.state.image_data;
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem('api_key');
    let filedata = image_data.split('base64,')[1];
    let result1 = await API.file.uploadFile(api_key, 'image_file.webp', filedata);
    let uploadedFileName = result1['file_url'];
    let result = await API.marketing.campaign.social.setThumbnail(api_key, this.state.campaign.id, uploadedFileName);
    let success  = result['success'];
    if(success){
      this.baseThumbnailDialogCapture.current.style.display = "none";
      toast('Thumbnail has been updated');      
      this.loadCampaign();
      this.setState({
        ...this.state,
         loading: false,
         isVisible: false
      });
    } else {
      toast('Error updating bespoke base thumbnail');
    }
  }
  

  async savecaptureImage2(){
    this.savecaptureImageRef2.current.style.display = "none";
    let image_data = this.state.image_data;
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem('api_key');
    let filedata = image_data.split('base64,')[1];
    let result1 = await API.file.uploadFile(api_key, 'image_file.webp', filedata);
    let uploadedFileName = result1['file_url'];
    let result = await API.marketing.campaign.social.setThumbnail(api_key, this.state.campaign.id, uploadedFileName);
    let success  = result['success'];
    if(success){
        this.stepFlow3DialogRefStep1.current.style.display = "none";
        this.stepFlow3DialogRefStep2.current.style.display = "block";
      toast('Thumbnail has been updated');      
      this.loadCampaign();
      this.setState({
        ...this.state,
         loading: false,
         isVisible: false
      });
      this.setState({
          ...this.state,
          webcam: false
    });
      this.socialMarketingVideoDialogRecordElementRef.current.resetUI();
    } else {
      toast('Error updating bespoke base thumbnail');
    }
  }

    authLinkedIn(){
        let loc = window.location.href;
        let client_id = "8663tuq8r4umo5"; // TODO: NOTE: Keep Updated
        let redirectURL = "https://api.clipdrop.io/api/v1/linkedin/redirect";
        let scope = "r_emailaddress w_member_social r_basicprofile r_liteprofile";
        let state = btoa(this.state.user.id + "/./" + loc).replace("%3D", "");
        console.log(state);
        window.location.href = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=" + state + "&scope=" + scope + "&client_id=" + client_id + "&redirect_uri=" + encodeURI(redirectURL);
    }

    loginFacebook(){
        let self = this;
    
        if(!window.FB){
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
  
      const FB = window.FB;
        FB.init({
            appId      : '1037968913418686',
            cookie     : true,
            xfbml      : true,
            version    : 'v13.0'
        });                
        FB.AppEvents.logPageView(); 
        // check login response.status
        FB.login(function(response) {
            if (response.status === 'connected') {
              // Logged into your webpage and Facebook.
               console.log("LOGGED IN");         
                self.hasFacebookAuthRef.current.style.display = "none";
                self.specialFacebookRef.current.style.display = "block";
                self.showPages();
            } else {
              console.log("COULD NOT LOGIN");
              self.hasFacebookAuthRef.current.style.display = "block";
              self.specialFacebookRef.current.style.display = "none";
            }
          }, {scope: 'public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts'});
    }

    async uploadMarketingVlogVideoToLinkedin(){
        if(!this.state.campaign.social_video_url || this.state.campaign.social_video_url == "" || this.state.campaign.social_video_url == null){
            toast.warning("Warning! Before you can share, you must please click the start button and finish the recording process", "warning");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let text = this.state.campaign.social_text;
        let video_url = this.state.campaign.social_video_url;    
        let result = await API.linkedin.uploadVideo(localStorage.getItem('api_key'), video_url, this.state.user.linkedin_user_access_token, this.state.user.linkedin_user_id);
        let media_id = result['data']['id'].replace("urn:li:video:", "urn:li:digitalmediaAsset:");
        result = await API.linkedin.makePost(localStorage.getItem('api_key'), text, media_id, this.state.user.linkedin_user_id, this.state.user.linkedin_user_access_token);
        let api_key = localStorage.getItem('api_key');
        let result2 = await API.marketing.campaign.addSendRate(api_key, this.state.campaign.id, 2);
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Post Created and Published");
    }

    async acceptSoicalVideo(video_file_url){
        this.socialMarketingVideoDialogUploadRef.current.style.display = "none";
        let api_key = localStorage.getItem("api_key");
        let cid = parseInt(this.props.match.params.id);
        let result = await API.marketing.campaign.social.setVideo(api_key, cid, video_file_url);                                                                                                    
        result = await API.marketing.campaign.get(api_key, cid);
        let campaign = result['campaign'];
        this.setState({...this.state, campaign: campaign});
    }

    async showPages(){
        let self = this;
        if(!this.state.campaign.social_video_url || this.state.campaign.social_video_url == "" || this.state.campaign.social_video_url == null){
            toast.warning("Warning! Before you can share, you must please click the start button and finish the recording process", "warning");
            return;
        }
        
        if(!window.FB){
            (function(d, s, id){
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {return;}
                js = d.createElement(s); js.id = id;
                js.src = "https://connect.facebook.net/en_US/sdk.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
  
        const FB = window.FB;
        FB.api('/me/accounts', function(response){
            let pages = response['data'];
            pages = pages.map((page)=>{
                return {
                    "access_token": page.access_token,
                    "id": page.id,
                    "name": page.name
                };
            });
            self.setState({
                ...self.state,
                pages: pages
            });
            self.facebookPagesRef.current.style.display = "flex";
        })
    }

    async postToSelectedPages(){
        let elements = document.querySelectorAll('.page_item > input:checked');
        if(elements.length == 0){
            toast("Please select at least 1 Page");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });
        
        for(let i=0;i<elements.length;i++){
            let title = this.state.campaign.social_caption;
            let description = this.state.campaign.social_text;
            let page_id = elements[i].getAttribute('page-id');
            let page_access_token = elements[i].getAttribute('page-access-token');
            let file_url = this.state.campaign.social_video_url;
            let response = await (new Promise((resolve, reject)=>{                
                if(!window.FB){
                    (function(d, s, id){
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) {return;}
                        js = d.createElement(s); js.id = id;
                        js.src = "https://connect.facebook.net/en_US/sdk.js";
                        fjs.parentNode.insertBefore(js, fjs);
                    }(document, 'script', 'facebook-jssdk'));
                }
                const FB = window.FB;
                FB.api(
                    "/" + page_id + "/videos",
                    "POST",
                    {   
                        "title": title,
                        "description": description,
                        "file_url": file_url,
                        "access_token": page_access_token
                    },
                    function (response) {
                        resolve(response);                    
                    }
                );
            }));
            console.log(response);
        }            
        this.facebookPagesRef.current.style.display = "none";
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Posted to Page(s) successfully");
    }

    
    showTab1Actions(){
        if(this.tab1Actions.current.style.display == "block"){
        this.tab1Actions.current.style.display = "none";
        } else {
        this.tab1Actions.current.style.display = "block";
        }
    }
    
    async saveSocialFlowText(){
        let text = this.socialDialogStep1Text.current.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.social.setText(api_key, this.state.campaign.id, text);
        let success = result['success'];
        if(success){
          result = await API.marketing.campaign.get(api_key, this.state.campaign.id);
          let camapign = result['camapign'];
          this.setState({
            ...this.state,
            camapign: camapign,
          });
        } else {
        }
        this.socialFlowDialogRef.current.style.display = "none";
        toast("Flow Completed");
        this.loadCampaign();
    }
    
    getTime(dt){
        if(!dt) return "";
        let d = DateTime.fromISO(dt).toFormat("dd-MM-yyyy");
        if(d == "Invalid DateTime"){
        return DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat("dd-MM-yyyy");
        } else {
        return d;
        }
    }

    async leads1SortByDefault(){
        this.setState({
            ...this.state,
            loading: true
        });
        let leads = this.state.bespoke_leads.sort((a, b)=>{
            if(this.sort_ids == false) {
                if(a.id > b.id) return 1;
                if(a.id < b.id) return -1;
                return 0;
            } else {
                if(a.id > b.id) return -1;
                if(a.id < b.id) return 1;
                return 0;
            }
        });
        this.sort_ids = !this.sort_ids;
        this.setState({
            ...this.state,
            loading: false,
            bespoke_leads: leads
        });
    }
    
    async leads1SortByViewedDate(){
        this.setState({
            ...this.state,
            loading: true
        });
        let leads = this.state.bespoke_leads.sort((a, b)=>{
            if(this.sort_views) {
                if(a.id > b.id && a.viewTime) return 1;
                if(a.id < b.id && b.viewTime) return -1;
                return 0;
            } else {
                if(a.id > b.id && a.viewTime) return -1;
                if(a.id < b.id && b.viewTime) return 1;
                return 0;
            }
        });
        this.sort_views = !this.sort_views;
        this.setState({
            ...this.state,
            loading: false,
            bespoke_leads: leads
        });
    }

    async leads1SortByVideoCreationDate(){
        this.setState({
            ...this.state,
            loading: true
        });
        let leads = this.state.bespoke_leads.sort((a, b)=>{
            if(this.sort_ids == false) {
                if(a.created_at > b.created_at) return 1;
                if(a.created_at < b.created_at) return -1;
                return 0;
            } else {
                if(a.created_at > b.created_at) return -1;
                if(a.created_at < b.created_at) return 1;
                return 0;
            }
        });
        this.sort_ids = !this.sort_ids;
        this.setState({
            ...this.state,
            loading: false,
            bespoke_leads: leads
        });
    }

    async showLabelPreview(e){
        let url = e.target.getAttribute('data-label-video');
        this.customLabelVideoPreviewDialogRef.current.style.display = "flex";
        this.customLabelVideoPreviewDialogVideoRef.current.setAttribute('src', url);
        this.customLabelVideoPreviewDialogVideoRef.current.play();
    }
    async deleteLabel(e){
        let api_key = localStorage.getItem('api_key');        
        let id = e.target.getAttribute('data-label-id');
        let result = await API.labels.delete(api_key, id);
        this.reloadLabels();
    }

    async reloadLabels(){
        let api_key = localStorage.getItem('api_key');    
        let result = await API.labels.getAll(api_key, this.state.campaign.id);
        let labels = result['labels'];
        this.setState({
            ...this.state,
            labels: labels          
        });
    }

    // open creation
    // save creation (check if it exists)
    openLabelCreationDialog(){
        this.openLabelCreationDialogRef.current.style.display = "flex";
    }

    render() {
        // HTML
        return (
            <>
                <div className="dashboardContainer">
                    <SideBarComponent></SideBarComponent>
                    <div className="dashboarContent">
                        <UpperBarComponent></UpperBarComponent>
                        <div className="pageNameInfo">
                            <span>Marketing Campaign &gt; {this.state.campaign.title} ({this.state.campaign.id})</span>
                        </div>             
                        <div className="tab1TableContainer whiteBG2" style={{width:'calc(100% - 536px)', minWidth:'720px'}}> 
                            <button className="blueBTN" onClick={this.toggleAnalytics} id="analytics">Analytics</button>
                            <div ref={this.analyticsRef} style={{paddingLeft:'32px'}}>
                                <h4 style={{borderBottom: "1px solid #1976D1", paddingBottom:"8px", width:'calc(100% - 128px)'}}>Analytics</h4>
                                <div style={{width:'100%', transform:"translateY(-24px)", paddingLeft:"16px"}}>
                                    <div style={{width:'29%', display:'inline-block', verticalAlign:'top'}}>
                                        <h5>Bespoke</h5>
                                        <ul>
                                            <li>Total Clicks: { this.state.campaign?.open_rate?.bespoke }</li>
                                            <li>{ this.state.campaign.sent_count >= 2 ? "Messages" : "Message"} Sent: { this.state.campaign.sent_count }</li>
                                        </ul>
                                    </div>
                                    <div style={{width:'29%', display:'inline-block', verticalAlign:'top'}}>
                                        <h5>Campaign</h5>
                                        <ul>
                                            <li>Total Clicks: { this.state.campaign?.open_rate?.campaign }</li>                                            
                                            <li>{ this.state.campaign.sent_count_marketing >= 2 ? "Messages" : "Message"} Sent: { this.state.campaign.sent_count_marketing }</li>
                                        </ul>
                                    </div>
                                    <div style={{width:'29%', display:'inline-block', verticalAlign:'top'}}>
                                        <h5>VLog</h5>
                                        <ul>
                                            <li>{ this.state.campaign.sent_count_vlog >= 2 ? "Messages" : "Message"} Open: { this.state.campaign.sent_count_vlog }</li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h4>Timeline (Additive)</h4>
                                    <span>Views: </span>&nbsp;&nbsp;
                                    <button className="blueBTN" onClick={async ()=>{
                                        this.setState({
                                            ...this.state,
                                            loading: true
                                        });
                                        let api_key = localStorage.getItem('api_key');
                                        let result = await API.marketing.campaign.getAnalytics(api_key, this.state.campaign.id, 0);
                                        let data = result['data'];
                                        this.setState({
                                            ...this.state,
                                            data: data
                                        });
                                        this.setState({
                                            ...this.state,
                                            loading: false
                                        });
                                    }}>Day</button>&nbsp;&nbsp;
                                    <button className="blueBTN" onClick={async ()=>{
                                        this.setState({
                                            ...this.state,
                                            loading: true
                                        });
                                        let api_key = localStorage.getItem('api_key');
                                        let result = await API.marketing.campaign.getAnalytics(api_key, this.state.campaign.id, 1);
                                        let data = result['data'];
                                        this.setState({
                                            ...this.state,
                                            data: data
                                        });
                                        this.setState({
                                            ...this.state,
                                            loading: false
                                        });
                                    }}>Month</button>&nbsp;&nbsp;
                                    <button className="blueBTN" onClick={async ()=>{
                                        this.setState({
                                            ...this.state,
                                            loading: true
                                        });
                                        let api_key = localStorage.getItem('api_key');
                                        let result = await API.marketing.campaign.getAnalytics(api_key, this.state.campaign.id, 2);
                                        let data = result['data'];
                                        this.setState({
                                            ...this.state,
                                            data: data
                                        });
                                        this.setState({
                                            ...this.state,
                                            loading: false
                                        });
                                    }}>Year</button>
                                </div>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart
                                    width="100%"
                                    height={300}
                                    data={this.state.data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name"/>
                                    <YAxis allowDecimals={false}/>
                                    <Tooltip />
                                    <Legend />
                                    <Line name="Bespoke Clicks" type="monotone" dataKey="bespoke" stroke="#FFCEA9" strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                                    <Line name="Campaign Clicks" type="monotone" dataKey="campaign" stroke="#DDDDDD" strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                                    <Line name="Vlog Sends" type="monotone" dataKey="vlog" stroke="#3C97DA"  strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>                                
                                <div>
                                    <h4>Timeline (Counts)</h4>
                                    <span>Views: </span>&nbsp;&nbsp;
                                    <button className="blueBTN" onClick={async ()=>{
                                        this.setState({
                                            ...this.state,
                                            loading: true
                                        });
                                        let api_key = localStorage.getItem('api_key');
                                        let result = await API.marketing.campaign.getAnalytics2(api_key, this.state.campaign.id, 0);
                                        let data = result['data'];
                                        this.setState({
                                            ...this.state,
                                            data2: data
                                        });
                                        this.setState({
                                            ...this.state,
                                            loading: false
                                        });
                                    }}>Day</button>&nbsp;&nbsp;
                                    <button className="blueBTN" onClick={async ()=>{
                                        this.setState({
                                            ...this.state,
                                            loading: true
                                        });
                                        let api_key = localStorage.getItem('api_key');
                                        let result = await API.marketing.campaign.getAnalytics2(api_key, this.state.campaign.id, 1);
                                        let data = result['data'];
                                        this.setState({
                                            ...this.state,
                                            data2: data
                                        });
                                        this.setState({
                                            ...this.state,
                                            loading: false
                                        });
                                    }}>Month</button>&nbsp;&nbsp;
                                    <button className="blueBTN" onClick={async ()=>{
                                        this.setState({
                                            ...this.state,
                                            loading: true
                                        });
                                        let api_key = localStorage.getItem('api_key');
                                        let result = await API.marketing.campaign.getAnalytics2(api_key, this.state.campaign.id, 2);
                                        let data = result['data'];
                                        this.setState({
                                            ...this.state,
                                            data2: data
                                        });
                                        this.setState({
                                            ...this.state,
                                            loading: false
                                        });
                                    }}>Year</button>
                                </div>                                
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart
                                    width="100%"
                                    height={300}
                                    data={this.state.data2}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis allowDecimals={false}/>
                                    <Tooltip />
                                    <Area connectNulls name="Bespoke Views" type="monotone" dataKey="bespoke" stroke="#FFCEA9" strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        
                        <div className="tab1TableContainer whiteBG2" style={{marginBottom:'128px', width:'calc(100% - 536px)', minWidth:'720px'}}> 
                            <button className="blueBTN" onClick={this.toggleCustomLabels} id="intro_videos">Introduction Videos</button>
                            <div ref={this.customLabelsRef} style={{paddingLeft:'32px'}}>
                                    <h4>Introduction Videos</h4>
                                    <hr/>
                                    <table className="custom_labels">
                                        <tr>
                                            <th colspan="2">
                                                <span class="dropdown" style={{display:'inline-block'}}>
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul>
                                                        <li style={{fontWeight:400, color:'#000'}} onClick={this.openLabelCreationDialog}>Add Video</li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </th>
                                            <th colspan="6" style={{width:'100%'}}>Title</th>
                                        </tr>
                                        {
                                            this.state.labels.map((label)=>{
                                                return (
                                                    <tr>
                                                        <td colspan="2">
                                                            <span class="dropdown" style={{display:'inline-block'}}>
                                                                <button>Actions</button>
                                                                <label style={{zIndex:'1000'}}>
                                                                    <input type="checkbox" />
                                                                    <ul>
                                                                    <li style={{fontWeight:400, color:'#000'}} onClick={this.showLabelPreview} data-label-video={ label.video_link } data-label-id={ label.id }>Preview</li>
                                                                    <li style={{fontWeight:400, color:'#000'}} onClick={this.deleteLabel} data-label-id={ label.id }>Delete</li>
                                                                    </ul>
                                                                </label>
                                                            </span>
                                                        </td>
                                                        <td colspan="6" style={{width:'100%'}}>{ label.title }</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </table>
                            </div>
                        </div>
                        <div className="tab1TableContainer whiteBG2"> 
                            <div className="candidateContianer2">
                                <div className="candidateContianerDropDown" style={{transform:'translateY(0px)', pointerEvents: 'none'}}>   
                                    <button id="folder_bespoke"  ref={this.tab2btn} tab-id="2" onClick={this.openTab} className="folderbutton" style={{zIndex:12, position:'relative', transform:'translateX(-32px) translateY(39px)', pointerEvents: 'all'}}>
                                        <svg width="200" height="32">
                                            <polygon points="32,0 5,32 200,32, 168, 0" style={{fill:'#1976D1', stroke:'#EEF0F8', strokeWidth:'1px'}}/>
                                            <text x="36" y="20" fill="#FFF">Bespoke Marketing</text>
                                        </svg>
                                    </button>
                                    <button id="folder_campaign" ref={this.tab3btn} tab-id="3" onClick={this.openTab} className="folderbutton" style={{transform:'translateX(-69px) translateY(39px)',zIndex:'9',position:'relative', pointerEvents: 'all'}}>
                                        <svg width="225" height="32">
                                            <polygon points="32,0 5,32 225,32, 192, 0" style={{fill:"#1976D1", stroke:'#EEF0F8', strokeWidth:'1px'}}/>
                                            <text x="40" y="20" fill="#FFF">Campaign Marketing</text>
                                        </svg>
                                    </button>
                                    <button id="folder_social"  ref={this.tab4btn} tab-id="4" onClick={this.openTab}  class="folderbutton" style={{transform:'translateX(-100px) translateY(39px)',zIndex:'7',position:'relative', pointerEvents: 'all'}}>
                                        <svg width="200" height="32">
                                            <polygon points="32,0 5,32 200,32, 168, 0" style={{fill:'#1976D1', stroke:'#EEF0F8', strokeWidth:'1px'}} />
                                            <text x="64" y="20" fill="#FFF">Social Vlog</text>
                                        </svg>
                                    </button>
                                </div>
                            </div>            
                            <div className="candidateContianerTabs candidateContianerTabs2">
                                <div ref={this.tab2} className="tab1TableContainer">
                                    <div className="flowMenu">
                                        <h4>Actions</h4>
                                        <div>
                                            <button className="blueBTN2" onClick={this.startFlow1}>Start</button>&nbsp;&nbsp;
                                            <button className="blueBTN" style={{padding:'12px 16px'}} onClick={this.toggleEditFlow1} id="bespoke_edit">Edit</button>
                                        </div>
                                        <div ref={this.editFlow1Ref}>
                                            <div style={{display:'inline-block', width:'40%', verticalAlign:'top'}}>
                                                <h4>Thumbnail Text</h4>
                                                <select ref={this.bespokeThumbnailOptionRef} style={{width:'calc(100% - 64px)', padding:"8px 12px"}} defaultValue={this.state.campaign.thumbnail_text_option} onChange={this.updateBespokeThumbnailOption}>
                                                    <option value={"Hi {lead}"}>{"Hi {lead}"}</option>
                                                    <option value={"Hello {lead}"}>{"Hello {lead}"}</option>
                                                    <option value="custom" selected={this.state.campaign.thumbnail_text_option == "custom"}>Custom message</option>
                                                </select>
                                                { 
                                                    this.state.campaign.thumbnail_text_option == "custom" ? <>
                                                    <h4 style={{fontSize:'8pt'}}>Custom Messsage</h4>
                                                    <input ref={this.bespokeThumbnailTextRef} onChange={this.updatebespokeThumbnailText} defaultValue={this.state.campaign.thumbnail_text_custom_text} type="text" placeholder="Welcome {Lead}" style={{display: 'inline-block', width:'calc(100% - 94px)', padding:"8px 12px"}}/>
                                                    </> : <></>
                                                }
                                            </div>
                                            <div style={{display:'inline-block', width:'40%', verticalAlign:'top'}}>
                                                <h4>Marketing Text</h4>
                                                <textarea ref={this.bespokeMarketingTextRef} onChange={this.updateBespokeMarketingText} style={{width: '462px', padding: '8px 12px', margin: '0px', height: '105px'}} defaultValue={this.state.campaign.marketing_text}></textarea>
                                            </div>                                            
                                            <div style={{display:'inline-block', width:'40%', verticalAlign:'top'}}>
                                                <h4>Our Website</h4>
                                                <div style={{display:'inline-block', fontSize:'8pt'}}>Example URL <strong><span style={{backgroundColor:'yellow'}}>https://</span>www.example.com/</strong></div>
                                                <input type="text" style={{width:'calc(100% - 64px)', padding:"8px 12px"}} defaultValue={this.state.campaign.our_website} onChange={this.updateBespokeMarketingWebsite} placeholder="https://example.com"/>
                                                <div ref={this.ourWebsiteLinkRef} style={{display:'block'}}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{border:'1px solid #f3f6f9', marginTop:'12px'}}/>
                                    <div>                                        
                                        <div style={{padding:'12pt', fontFamily:'Poppins,Helvetica,sans-serif', display:'inline-block'}}>
                                            <div style={{fontWeight: '500', fontSize: '14pt', fontFamily:'Poppins,Helvetica,sans-serif'}}>Leads</div>
                                            <div style={{fontSize:'10pt', fontWeight: 'normal',fontFamily:'Poppins,Helvetica,sans-serif'}}>{ this.state.bespoke_leads.length } Leads</div>
                                        </div>
                                        <div style={{display:'inline-block', paddingLeft: '12px', marginBottom:'12px'}}>
                                            <input ref={this.searchBespokeRef} type="text" placeholder="Search here" style={{display: 'inline-block',border:'1px solid #1976D1',borderRadius:'8px', padding:'12px'}}/>&nbsp;
                                            <button className="blueBTN" data-lead-type="0" onClick={this.searchLeadsBespoke}>Search</button>
                                        </div>
                                        <div style={{display:'inline-block', textAlign:'right', border: '1px solid rgb(74, 134, 232)', borderRadius: '8px', padding: '5px 0px', transform: 'translate(5px, 3px)'}}>
                                            <div onClick={this.filterBespokeLeadsClear} className="filterbutton" style={{transform:'translateY(0px)', marginLeft:'4px'}} title="Clear Filters"><MdClear style={{transform:'translate(-4px, 2px)'}}/></div>&nbsp;
                                            <div ref={this.filter1Ref} onClick={this.filterBespokeLeadsRecording} className="filterbutton" style={{transform:'translateY(0px)', marginLeft:'4px'}} title="Leads with recorded videos"><img src={play_icon_color} alt=""  style={{transform:'translate(-3px, 2px)', width:'18px', height:'16px', pointerEvents: 'none'}} /></div>&nbsp;
                                            <div ref={this.filter3Ref} onClick={this.filterBespokeLeadsReadyToSend} className="filterbutton" style={{transform:'translateY(0px)'}} title="Leads ready to send"><IoIosPaperPlane style={{transform: 'translate(-4px, 0px)', pointerEvents: 'none'}}/></div>&nbsp;
                                            <div ref={this.filter4Ref} onClick={this.filterBespokeLeadsViewed} className="filterbutton" style={{transform:'translateY(0px)'}} title="Leads that have been viewed"><AiFillEye style={{transform: 'translate(-5px, 2px)', pointerEvents: 'none'}}/></div>&nbsp;
                                        </div>&nbsp;
                                        <div style={{display:'inline-block', textAlign:'right', border: '1px solid rgb(74, 134, 232)', borderRadius: '8px', padding: '5px 0px', transform: 'translate(5px, 3px)'}} id="bespoke_filters_item">
                                        &nbsp;<div ref={this.filter2Ref} onClick={this.filterBespokeLeadsNoRecording} className="filterbutton" style={{transform:'translateY(0px)'}} title="Leads with no recorded videos"><img src={play_icon_grey} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /></div>&nbsp;
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="dropdown">
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul>
                                                        <li style={{color:'#000', fontWeight:'normal'}} onClick={this.deletedSelectLeads}>Delete</li>
                                                        </ul>
                                                    </label>
                                                    </span>
                                                </th>
                                                <th style={{maxWidth: '275px', width: '100%'}}>
                                                    LEAD
                                                    <AiFillFilter onClick={this.leads1SortByDefault} style={{display:'inline-block', transform:'translate(220px, 2px)', cursor:'pointer'}}/>
                                                </th>
                                                <th style={{maxWidth: '100px', width: '100%'}}>ID</th>
                                                <th style={{maxWidth: '150px', width: '100%'}}>ACTIONS</th>
                                                <th style={{maxWidth: '150px', width: '100%'}}>
                                                    Creation Date
                                                    <AiFillFilter onClick={this.leads1SortByVideoCreationDate} style={{display:'inline-block', transform:'translate(104px, 2px)', cursor:'pointer'}}/>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td style={{maxWidth: '300px', width: '100%'}}>
                                                    <button className="blueBTN" data-lead-type="0" onClick={this.openNewLead} id="create_lead_bespoke">Add Lead</button>
                                                </td>
                                                <td style={{maxWidth: '100px', width: '100%'}}>

                                                </td>
                                                <td style={{maxWidth: '150px', width: '100%'}}>

                                                </td>
                                            </tr>                                            
                                            {
                                                this.state.bespoke_leads.map((lead)=>{
                                                    return (
                                                        <tr key={lead.id}>                           
                                                            <td>
                                                            <input type="checkbox" lead-id={lead.id} className="marketing_bespoke_lead" style={{transform: 'scale(1.5);'}} />
                                                            </td>
                                                            <td style={{maxWidth: '300px', width: '100%'}}>
                                                                <div className="candidateUserIcon"></div>
                                                                <div style={{display:'inline-block', verticalAlign:'top', paddingTop:'6px'}}>
                                                                    <strong style={{display:'block', fontSize: '10pt', paddingLeft:'16px'}}>{ lead.name }</strong>
                                                                    <span  style={{display:'block', fontSize: '10pt', paddingLeft:'16px', color:'#b8b8b8'}}>{ lead.email }</span>
                                                                </div>
                                                            </td>
                                                            <td style={{maxWidth: '135px', width: '100%'}}>
                                                                { lead.id }
                                                            </td>
                                                            <td style={{maxWidth: '166px', width: '100%'}}>
                                                                { /*<BsPencil className="candidateMiniButton" lead-id={lead.id} title="Edit Candidate" />&nbsp;*/ }
                                                                <ImBin2 className="candidateMiniButton" lead-id={lead.id} title="Delete Candidate" onClick={this.deleteLead}/>&nbsp;                                                                                                             
                                                                {
                                                                    lead.custom_video_url && lead.processing_done ? 
                                                                    <div className="candidateMiniButton special" lead-id={lead.id} lead-video={lead.custom_video_url} title="Preview Custom Video" onClick={this.openCustomVideoPreview}>
                                                                        <img src={play_icon_color} alt=""  style={{width:'18px', height:'16px', pointerEvents: 'none'}} />
                                                                    </div> : 
                                                                    <div className="candidateMiniButton special" lead-id={lead.id} title="Upload/Record Custom Video" onClick={this.openCustomVideoUpload}>
                                                                        <img src={play_icon_grey} alt=""  style={{width:'18px', height:'16px', pointerEvents: 'none'}} />
                                                                    </div>
                                                                }&nbsp;                                                            
                                                                { lead.custom_video_url && lead.processing_done ? 
                                                                    <IoIosPaperPlane className="candidateMiniButton" lead-id={lead.id} title="Generate Marketing Link" onClick={this.generateBespokeMarketingLinkDialog}/>                              
                                                                    : <></>
                                                                }
                                                                {
                                                                    this.getProcessingTag(lead)
                                                                }
                                                            </td>
                                                            <td style={{maxWidth: '188px', width: '100%'}}>
                                                                { this.getTime(lead.created_at) }
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div ref={this.tab3} className="tab1TableContainer">
                                    <div className="flowMenu">
                                        <h4>Actions</h4>
                                        <div>
                                            <button className="blueBTN2" onClick={this.startFlow2}>Start</button>&nbsp;&nbsp;
                                            <button className="blueBTN" style={{padding:'12px 16px'}} onClick={this.toggleEditFlow2}  id="campaign_edit">Edit</button>
                                        </div>
                                        <div ref={this.editFlow2Ref}>
                                            <div style={{verticalAlign:'top', display:'inline-block'}}>
                                                <h4>Marketing Video</h4>
                                                <div>
                                                    <video  src={this.state.campaign.campaign_video_url} style={{display: "block",borderRadius: "8px",width: "300px",height: "250px",background: "#212121"}} autoStart={false} autoPlay={false}></video>
                                                    <button className="blueBTN" style={{transform:'translate(245px, -61px)'}}  onClick={()=>{
                                                        this.campaignMarketingVideoDialogOptions.current.style.display = "flex";
                                                    }}><BsCameraVideoFill fontSize={"22pt"}/></button>
                                                </div>
                                            </div>
                                            <div style={{verticalAlign:'top', display:'inline-block', marginLeft:'32px'}}>
                                                <h4>Marketing Caption Text</h4>
                                                <textarea ref={this.marketingTextRef} style={{width:'400px', height:"226px", padding:"12px"}} defaultValue={this.state.campaign.campaign_text} onChange={this.changeMarketingText}></textarea>
                                                <h4 className="tab3ActionContainerMaxWidthMiniH4">Marketing Custom Gif Text</h4>
                                                <select style={{width:'100%', padding:'12px'}} onChange={this.thumbnailTextOutreachOnChange} ref={this.thumbnailTextOutreachRef} defaultValue={this.state.campaign.custom_thumbnail_outreach_type}>
                                                    <option value='0'>Hi {"{lead}"}</option>
                                                    <option value='1'>Hello {"{lead}"}</option>
                                                    <option value='2'>Hello {"{lead}"}</option>
                                                    <option value='3'>Custom Message</option>
                                                </select>
                                                {
                                                    parseInt(this.state.campaign.custom_thumbnail_outreach_type) === 3 ? <div className="customMessageContainer">
                                                    <span>Enter a custom message:</span>
                                                    <input style={{width:'calc(100% - 27px) !important',padding:'12px'}} type="text" onChange={this.bulkTextOnChange} ref={this.bulkTextRef} defaultValue={this.state.campaign.bulk_marketing_text}/>
                                                </div> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{border:'1px solid #f3f6f9', marginTop:'12px'}}/>
                                    <div>                                        
                                        <div style={{padding:'12pt', fontFamily:'Poppins,Helvetica,sans-serif', display:'inline-block'}}>
                                            <div style={{fontWeight: '500', fontSize: '14pt', fontFamily:'Poppins,Helvetica,sans-serif'}}>Leads</div>
                                            <div style={{fontSize:'10pt', fontWeight: 'normal',fontFamily:'Poppins,Helvetica,sans-serif'}}>{ this.state.marketing_leads.length } Leads</div>
                                        </div>
                                        <div style={{display:'inline-block', paddingLeft: '12px', marginBottom:'12px'}}>
                                            <input ref={this.searchCampaignRef} type="text" placeholder="Search here" style={{display: 'inline-block',border:'1px solid #1976D1',borderRadius:'8px', padding:'12px'}}/>&nbsp;
                                            <button className="blueBTN" data-lead-type="0" onClick={this.searchLeadsCampaign} id="campaign_search_btn">Search</button>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span className="dropdown">
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul>
                                                        <li style={{color:'#000', fontWeight:'normal'}} onClick={this.deletedSelectLeads2}>Delete</li>
                                                        </ul>
                                                    </label>
                                                    </span>
                                                </th>
                                                <th style={{maxWidth: '147px', width: '100%'}}>LEAD</th>
                                                <th style={{maxWidth: '100px', width: '100%'}}>ID</th>
                                                <th style={{maxWidth: '150px', width: '100%'}}>ACTIONS</th>
                                                <th style={{maxWidth: '150px', width: '100%'}}>Viewed</th>
                                                <th style={{maxWidth: '150px', width: '100%'}}>Creation Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td style={{maxWidth: '300px', width: '100%'}}>
                                                    <button className="blueBTN" data-lead-type="1" onClick={this.openNewLead2} id="campaign_lead_add">Add Lead</button>
                                                </td>
                                                <td style={{maxWidth: '100px', width: '100%'}}>

                                                </td>
                                                <td style={{maxWidth: '150px', width: '100%'}}>

                                                </td>
                                                <td></td>
                                                <td></td>
                                            </tr>                                            
                                            {
                                                this.state.marketing_leads.map((lead)=>{
                                                    return (
                                                        <tr key={lead.id}>                 
                                                            <td>
                                                                <input type="checkbox" lead-id={lead.id} className="marketing_campaign_lead" style={{transform: 'scale(1.5);'}} />
                                                            </td>
                                                            <td style={{maxWidth: '300px', width: '100%'}}>
                                                                <div className="candidateUserIcon"></div>
                                                                <div style={{display:'inline-block', verticalAlign:'top', paddingTop:'6px'}}>
                                                                    <strong style={{display:'block', fontSize: '10pt', paddingLeft:'16px'}}>{ lead.name }</strong>
                                                                    <span  style={{display:'block', fontSize: '10pt', paddingLeft:'16px', color:'#b8b8b8'}}>{ lead.email }</span>
                                                                </div>
                                                            </td>
                                                            <td style={{maxWidth: '135px', width: '100%'}}>
                                                                { lead.id }
                                                            </td>
                                                            <td style={{maxWidth: '166px', width: '100%'}}>
                                                                { /*<BsPencil className="candidateMiniButton" lead-id={lead.id} title="Edit Candidate" />&nbsp;*/ }
                                                                <ImBin2 className="candidateMiniButton" lead-id={lead.id} title="Delete Candidate" onClick={this.deleteLead}/>&nbsp;                                                      
                                                                { this.state.campaign.campaign_video_url && lead.processing_done ? <IoIosPaperPlane className="candidateMiniButton" lead-id={lead.id} title="Generate Marketing Link" onClick={this.generateBespokeMarketingLinkDialog2}/> : <></> }
                                                            </td>
                                                            <td style={{maxWidth: '166px', width: '100%'}}>
                                                                {                                                                    
                                                                    this.getProcessingTag2(lead)
                                                                }
                                                            </td>
                                                            <td style={{maxWidth: '150px', width: '100%'}}>
                                                                { this.getTime(lead.created_at) }
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>                        
                                </div>
                                <div ref={this.tab4} className="tab1TableContainer">  
                                    <div className="flowMenu">
                                        <div>
                                            <div>
                                                <span>Add a Social Video, Then you may share it via LinkedIn and/or Facebook</span>
                                            </div>                                            
                                            <div className="tab2ActionButtons">
                                                <button style={{backgroundColor: '#F07F14'}} onClick={this.startTab1Flow}>Start</button>
                                                <button onClick={this.showTab1Actions} id="social_vlog_edit">Edit</button>
                                            </div>
                                            <div className="socialPostContainer" ref={this.tab1Actions}>
                                                <div style={{verticalAlign:'top', display:'inline-block', marginRight: '8px'}}>
                                                    <h4>Social Video</h4>
                                                    <div>
                                                        <video  src={this.state.campaign.social_video_url} style={{display: "block",borderRadius: "8px",width: "300px",height: "250px",background: "#212121"}} autoStart={false} autoPlay={false}></video>
                                                        <button className="blueBTN" style={{transform:'translate(245px, -61px)'}}  onClick={()=>{
                                                            this.socialMarketingVideoDialogOptions.current.style.display = "flex";
                                                        }}><BsCameraVideoFill fontSize={"22pt"}/></button>
                                                    </div>
                                                </div>
                                                <div style={{verticalAlign:'top', display:'inline-block', marginLeft:'32px'}}>
                                                    <h4>Social Caption <span style={{fontWeight:400}}>(Optional for FB Share Only)</span></h4>
                                                    <input ref={this.socialMarketingCaptionRef}  style={{width:'400px', padding:"12px"}} defaultValue={this.state.campaign.social_caption} onChange={this.changeSocialMarketingCaption} />
                                                    <h4>Social Text</h4>
                                                    <textarea ref={this.socialMarketingTextRef} style={{width:'400px', height:"226px", padding:"12px"}} defaultValue={this.state.campaign.social_text} onChange={this.changeSocialMarketingText}></textarea>
                                                </div>
                                            </div>
                                            <div style={{margin: '12px auto', display:'block', textAlign:'center'}}>
                                                <button className="blueButton" style={{margin: '12px 8px', display:'inline-block', width:'48%'}} onClick={this.uploadMarketingVlogVideoToLinkedin} ref={this.specialLinkedInRef}>Share on LinkedIn</button>
                                                <button className="blueButton" style={{margin: '12px 8px', display:'inline-block', width:'48%'}}  onClick={this.showPages} ref={this.specialFacebookRef}>Share on Facebook</button>
                                            </div>
                                            <div ref={this.hasLinkedInAuthRef}>
                                                <span>In order to use Social Vlog with LinkedIn You need to sign and grant access to the ClipDrop App</span><br/><br/>
                                                <button className="linkedin_signin_button" onClick={this.authLinkedIn}><FaLinkedinIn style={{transform:'translateY(3px)'}}/>&nbsp;&nbsp;SIGN IN WITH LINKEDIN</button>
                                            </div>
                                            <div ref={this.hasFacebookAuthRef}>
                                                <span>In order to use Social Vlog with Facebook You need to sign and grant access to the ClipDrop App</span><br/><br/>
                                                <button className="linkedin_signin_button" onClick={this.loginFacebook}><FaFacebook style={{transform:'translateY(3px)'}}/>&nbsp;&nbsp;SIGN IN WITH FACEBOOK</button>
                                            </div>
                                        </div>                                        
                                    </div>                                                      
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>                           
                <div ref={this.createLead1DialogRef} className="dialogNew extend">        
                    <div>
                        <h4>Create Lead</h4>
                        <div className="inputForm">
                            <input ref={this.createLead1DialogNameRef} type="text" placeholder="Lead's Name"/>
                            <input ref={this.createLead1DialogEmailRef} type="text" placeholder="Lead's Email Address"/>
                            <input ref={this.createLead1DialogPhoneNumberRef} type="text" placeholder="Lead's Phone Number"/>
                            <input ref={this.createLead1DialogLinkedInRef} type="text" placeholder="Lead's LinkedIn Profile URL"/>
                        </div>
                        <div>
                            <button onClick={this.createNewLead}>Create</button>
                            <button onClick={()=>{this.createLead1DialogRef.current.style.display = "none";}}>Close</button>
                        </div>
                    </div>
                </div>                     
                <div ref={this.createLead1DialogRef2} className="dialogNew extend">        
                    <div>
                        <h4>Create Lead</h4>
                        <div className="inputForm">
                            <input ref={this.createLead1DialogNameRef2} type="text" placeholder="Lead's Name"/>
                            <input ref={this.createLead1DialogEmailRef2} type="text" placeholder="Lead's Email Address"/>
                            <input ref={this.createLead1DialogPhoneNumberRef2} type="text" placeholder="Lead's Phone Number"/>
                            <input ref={this.createLead1DialogLinkedInRef2} type="text" placeholder="Lead's LinkedIn Profile URL"/>
                        </div>
                        <div>
                            <button onClick={this.createNewLead2}>Create</button>
                            <button onClick={()=>{this.createLead1DialogRef2.current.style.display = "none";}}>Close</button>
                        </div>
                    </div>
                </div>  
                <div ref={this.customCandidateVideoPreviewRef} className="dialogNew extend">        
                    <div>
                        <h4>Preview Candidate's Custom Video</h4>
                        <div>
                        <div>
                            <video src="" ref={this.customCandidateVideoPreviewVideoRef} style={{width: '100%'}} playsInline={true} controls={true}></video>
                        </div>
                        </div>
                        <div>
                            <button onClick={this.openCustomVideoUploadRe}>Re-Record Video</button>
                            <button onClick={()=>{this.customCandidateVideoPreviewVideoRef.current.pause();this.customCandidateVideoPreviewRef.current.style.display = "none";}}>Close</button>
                        </div>
                    </div>
                </div>  
                
                <div ref={this.customLabelVideoPreviewDialogRef} className="dialogNew extend">        
                    <div>
                        <h4>Video Preview</h4>
                        <div>
                        <div>
                            <video src="" ref={this.customLabelVideoPreviewDialogVideoRef} style={{width: '100%'}} playsInline={true} controls={true}></video>
                        </div>
                        </div>
                        <div>
                            <button onClick={()=>{this.customLabelVideoPreviewDialogVideoRef.current.pause();this.customLabelVideoPreviewDialogRef.current.style.display = "none";}}>Close</button>
                        </div>
                    </div>
                </div>    
                        
                <div ref={this.customCandidateVideoRef} className="dialogNew extend">        
                    <div>
                        <h4>Custom Candidate Video</h4>
                        <div>
                        <div>
                            <VideoRecordComponent  ref={this.customCandidateVideoelementRef} onnopreview={()=>{
                                this.reloadAllTab();
                      this.customCandidateVideoRef.current.style.display = "none";
                    }}  nopreview={true} fixWidth={false} watermark_url={this.state.user.watermark_url} onclose={()=>{this.customCandidateVideoRef.current.style.display="none";}} oncompletion={this.setCandidateCustomVideo}></VideoRecordComponent>
                        </div>
                        </div>
                    </div>
                </div>
                <div ref={this.campaignMarketingVideoDialogOptions} className="dialogNew extend">        
                    <div>
                        <h4>Campaign Marketing Video</h4>
                        <div>
                            <button className="blueBTN" style={{width:'100%'}} onClick={this.openCampaignMarketingRecord}>Record</button>
                            { this.state.campaign.campaign_video_url ? <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.campaignMarketingVideoDialogOptions.current.style.display = "none";
                                this.campaignMarketingVideoDialogPreviewRef.current.style.display = "flex";
                            }}>Preview</button> : <></> }
                            <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.campaignMarketingVideoDialogOptions.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                
                <div ref={this.socialMarketingVideoDialogOptions} className="dialogNew extend">        
                    <div>
                        <h4>Social Marketing Video</h4>
                        <div>
                            <button className="blueBTN" style={{width:'100%'}} onClick={this.openSocialMarketingRecord}>Record</button>
                            <button className="blueBTN" style={{width:'100%'}} onClick={this.openSocialMarketingUpload}>Upload</button>
                            { this.state.campaign.social_video_url ? <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.socialMarketingVideoDialogOptions.current.style.display = "none";
                                this.socialMarketingVideoDialogPreviewRef.current.style.display = "flex";
                            }}>Preview</button> : <></> }
                            <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.socialMarketingVideoDialogOptions.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                
                <div ref={this.socialMarketingImageDialogOptions} className="dialogNew extend">        
                    <div>
                        <h4>Social Marketing Image</h4>
                        <div>
                            <button className="blueBTN" style={{width:'100%'}} onClick={this.openSocialMarketingImageCapture}>Capture</button>
                            { /*<button className="blueBTN" style={{width:'100%'}} onClick={this.openSocialMarketingImageUpload}>Upload</button> */ }
                            { this.state.campaign.social_image_url ? <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.socialMarketingImageDialogOptions.current.style.display = "none";
                                this.socialMarketingImageDialogPreviewRef.current.style.display = "flex";
                            }}>Preview</button> : <></> }
                            <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.socialMarketingImageDialogOptions.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                
                        
                <div ref={this.campaignMarketingVideoDialogRecordRef} className="dialogNew extend">        
                    <div>
                        <h4>Record Campaign Marketing Video</h4>
                        <div>
                        <div>
                            <VideoRecordComponent  ref={this.campaignMarketingVideoDialogRecordElementRef} fixWidth={false} watermark_url={this.state.user.watermark_url} onclose={()=>{this.campaignMarketingVideoDialogRecordRef.current.style.display="none";}} oncompletion={this.setCmapaignMarketingVideo}></VideoRecordComponent>
                        </div>
                        </div>
                    </div>
                </div>
                <div ref={this.socialMarketingVideoDialogRecordRef} className="dialogNew extend">        
                    <div>
                        <h4>Record Social Marketing Video</h4>
                        <div>
                        <div>
                            <VideoRecordComponent  ref={this.socialMarketingVideoDialogRecordElementRef2} fixWidth={false} watermark_url={this.state.user.watermark_url} onclose={()=>{this.socialMarketingVideoDialogRecordRef.current.style.display="none";}} oncompletion={this.setSocialMarketingVideo}></VideoRecordComponent>
                        </div>
                        </div>
                    </div>
                </div>
                <div ref={this.socialMarketingVideoDialogUploadRef} className="dialogNew extend">        
                    <div>
                        <h4>Upload Social Marketing Video</h4>
                        <div>
                            <VideoUploadComponent ref={this.socialVideoUploadRef} watermark_url={this.state.user.watermark_url}
                                onreject={()=>{
                                    this.socialMarketingVideoDialogUploadRef.current.style.display = "none";
                                }}

                                onclose={()=>{
                                    this.socialMarketingVideoDialogUploadRef.current.style.display = "none";
                                }}
                                
                                onaccept={this.acceptSoicalVideo}/>
                        </div>
                    </div>
                </div>
                
                <div ref={this.campaignMarketingVideoDialogPreviewRef} className="dialogNew extend">        
                    <div>
                        <h4>Campaign Marketing Video Preview</h4>
                        <div>
                            <video ref={this.campaignMarketingVideoDialogPreviewRefVideo} controls playsInline autoPlay style={{width:'100%'}} src={this.state.campaign.campaign_video_url} autoStart={false} autoPlay={false}></video>
                            <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.campaignMarketingVideoDialogPreviewRefVideo.current.pause();
                                this.campaignMarketingVideoDialogPreviewRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                <div ref={this.socialMarketingVideoDialogPreviewRef} className="dialogNew extend">        
                    <div>
                        <h4>Social Marketing Video Preview</h4>
                        <div>
                            <video ref={this.socialMarketingVideoDialogPreviewRefVideo} controls playsInline autoPlay style={{width:'100%'}} src={this.state.campaign.social_video_url} autoStart={false} autoPlay={false}></video>
                            <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.socialMarketingVideoDialogPreviewRefVideo.current.pause();
                                this.socialMarketingVideoDialogPreviewRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>   
                <div ref={this.socialMarketingImageDialogPreviewRef} className="dialogNew extend">        
                    <div>
                        <h4>Social Marketing Image Preview</h4>
                        <div>
                            <img ref={this.socialMarketingImageDialogPreviewRefImage} style={{width:'100%'}} src={this.state.campaign.social_image_url}/>
                            <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                                this.socialMarketingImageDialogPreviewRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>                
                <div ref={this.stepFlow1DialogRef} className="dialogNew extend">        
                    <div ref={this.stepFlow1DialogRefStep1} className="hasCloseX">
                        <h4>Bespoke Thumbnail Text - Step 1 of 2</h4><span className="closeButtonX"onClick={()=>{
                            this.stepFlow1DialogRef.current.style.display = "none";
                        }}>X</span><br/>
                        <select ref={this.bespokeThumbnailOptionRef} style={{margin:'0 auto',width:'calc(100% - 64px)', padding:"8px 12px"}} defaultValue={this.state.campaign.thumbnail_text_option} onChange={this.updateBespokeThumbnailOption}>
                            <option value={"Hi {lead}"}>{"Hi {lead}"}</option>
                            <option value={"Hello {lead}"}>{"Hello {lead}"}</option>
                            <option value="custom" selected={this.state.campaign.thumbnail_text_option == "custom"}>Custom message</option>
                        </select>
                        { 
                            this.state.campaign.thumbnail_text_option == "custom" ? <>
                            <h4 style={{fontSize:'8pt'}}>Custom Messsage</h4>
                            <input ref={this.bespokeThumbnailTextRef} onChange={this.updatebespokeThumbnailText} defaultValue={this.state.campaign.thumbnail_text_custom_text} type="text" placeholder="Welcome {Lead}" style={{display: 'inline-block', width:'calc(100% - 94px)', padding:"8px 12px"}}/>
                            </> : <></>
                        }
                        <br/>
                        <br/>
                        <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                            this.stepFlow1DialogRefStep1.current.style.display = "none";
                            this.stepFlow1DialogRefStep2.current.style.display = "block";
                        }}>Next</button>
                    </div>       
                    <div ref={this.stepFlow1DialogRefStep2} className="hasCloseX">
                        <h4>Bespoke Marketing Text - Step 2 of 2</h4><span className="closeButtonX"onClick={()=>{
                            this.stepFlow1DialogRef.current.style.display = "none";
                        }}>X</span><br/>
                        <textarea ref={this.bespokeMarketingTextRef} onChange={this.updateBespokeMarketingText} style={{width: '462px', padding: '8px 12px', margin: '0px', height: '105px'}} defaultValue={this.state.campaign.marketing_text}></textarea>                        
                        <br/>
                        <br/>
                        <button onClick={()=>{
                            this.stepFlow1DialogRefStep2.current.style.display = "none";
                            this.stepFlow1DialogRef.current.style.display = "none";
                        }} className="blueBTN" style={{width:'100%'}}>Done</button>
                    </div>
                </div>      
                <div ref={this.stepFlow2DialogRef} className="dialogNew extend">        
                    <div ref={this.stepFlow2DialogRefStep1} className="hasCloseX">
                        <h4>Marketing Video - Step 1 of 3</h4><span className="closeButtonX" onClick={()=>{
                            this.stepFlow2DialogRef.current.style.display = "none";
                        }}>X</span><br/>
                        <VideoRecordComponent  ref={this.campaignMarketingVideoDialogRecordElement2Ref} fixWidth={false} watermark_url={this.state.user.watermark_url} onclose={()=>{this.stepFlow2DialogRef.current.style.display="none";}} oncompletion={(video_url)=>{
                            this.setCmapaignMarketingVideo(video_url);
                            this.stepFlow2DialogRefStep1.current.style.display = "none";
                            this.stepFlow2DialogRefStep2.current.style.display = "block";
                        }}></VideoRecordComponent>
                    </div>       
                    <div ref={this.stepFlow2DialogRefStep2} className="hasCloseX">
                        <h4>Marketing Text - Step 2 of 3</h4><span className="closeButtonX" onClick={()=>{
                            this.stepFlow2DialogRef.current.style.display = "none";
                        }}>X</span><br/>
                        <textarea ref={this.marketingTextRef} style={{width:'400px', height:"226px", padding:"12px"}} defaultValue={this.state.campaign.campaign_text} onChange={this.changeMarketingText}></textarea>
                        <br/>
                        <br/>
                        <button onClick={()=>{
                            this.stepFlow2DialogRefStep2.current.style.display = "none";
                            this.stepFlow2DialogRefStep3.current.style.display = "block";
                        }} className="blueBTN" style={{width:'100%'}}>Next</button>
                    </div> 
                    <div ref={this.stepFlow2DialogRefStep3} className="hasCloseX">
                        <h4>Marketing Custom Gif Text - Step 3 of 3</h4><span className="closeButtonX" onClick={()=>{
                            this.stepFlow2DialogRef.current.style.display = "none";
                        }}>X</span><br/>
                        <select style={{width:'100%', padding:'12px'}} onChange={this.thumbnailTextOutreachOnChange} ref={this.thumbnailTextOutreachRef} defaultValue={this.state.campaign.custom_thumbnail_outreach_type}>
                            <option value='0'>Hi {"{lead}"}</option>
                            <option value='1'>Hello {"{lead}"}</option>
                            <option value='2'>Hello {"{lead}"}</option>
                            <option value='3'>Custom Message</option>
                        </select>
                        {
                            parseInt(this.state.campaign.custom_thumbnail_outreach_type) === 3 ? <div className="customMessageContainer">
                            <span>Enter a custom message:</span>
                            <input style={{width:'calc(100% - 27px) !important',padding:'12px'}} type="text" onChange={this.bulkTextOnChange} ref={this.bulkTextRef} defaultValue={this.state.campaign.bulk_marketing_text}/>
                            </div> : ""
                        }
                        <br/>
                        <br/>
                        <button onClick={()=>{
                            this.stepFlow2DialogRefStep3.current.style.display = "none";
                            this.stepFlow2DialogRef.current.style.display = "none";
                        }} className="blueBTN" style={{width:'100%'}}>Done</button>
                    </div>
                </div>             
                <div ref={this.baseThumbnailDialogCapture} className="dialogNew extend">        
                    <div className="hasCloseX">
                        <h4>Capture Social Thumbnail</h4><span className="closeButtonX">X</span><br />
                        <div>
                        <span>Click Capture</span><br /><br />
                        <div>
                            <img src="" ref={this.imgRef} style={{width: '100%'}}/>
                            { this.state.isVisible && <Webcam style={{width: '100%'}} ref={this.cameraRef} videoConstraints={{facingMode: "user"}}/>}               
                            <div className="cameraCounter" ref={this.cameraCounterRef}>3</div>                    
                        </div>
                        </div>
                        <div>
                            <button onClick={this.captureImage}>Capture Image</button>
                            <button onClick={this.savecaptureImage} ref={this.savecaptureImageRef}>Save</button>
                            <button onClick={()=>{this.baseThumbnailDialogCapture.current.style.display = "none";}}>Close</button>
                        </div>
                    </div>
                </div>                                
                <div ref={this.marketingLinkCopy} className="dialogNew extend">        
                    <div>
                        <h4>Marketing Text</h4>
                        <div>
                            <textarea style={{width:'475px', height:'86px'}} ref={this.marketingDescriptionCopyText}></textarea>
                        </div>
                        <h4>Copy Link</h4>
                        <div>
                            <textarea readonly={true} style={{width:'475px', height:'86px'}} ref={this.marketingLinkCopyText}></textarea>
                        </div>
                        <h4>Email Subject Line</h4>
                        <div>
                        <input type="text" style={{width:'calc(100% - 18px)', padding:'8px', marginBottom:'8px'}} ref={this.leadEmailSubjectLine} placeholder="enter subject line here"/>
                        </div>
                        <div>
                            <button onClick={()=>{
                                let txt = this.marketingLinkCopyText.current.value;
                                try { window.navigator.clipboard.writeText(txt);} catch(e){}
                                try { window.clipboardData.setData("Text", txt);} catch(e){}
                                toast('Link copied to your clipboard');
                            }}>Copy</button>
                            <button onClick={async ()=>{
                                this.setState({
                                    ...this.state,
                                    loading: true
                                });
                                let api_key = localStorage.getItem('api_key');
                                let subject = this.leadEmailSubjectLine.current.value;
                                let link = this.marketingLinkCopyText.current.value;
                                let text = this.marketingDescriptionCopyText.current.value;
                                await API.marketing.leads.email(api_key, this.lead_id, subject, text, link);
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                toast("Email sent");
                            }}>Send an email</button>
                            <button onClick={()=>{this.marketingLinkCopy.current.style.display = "none";}}>Close</button>
                        </div>
                    </div>
                </div>                              
                <div ref={this.facebookPagesRef} className="dialogNew extend">        
                    <div className="page_item_container">
                        <span>Select the pages which you would like to post on</span><br/><br/>
                        {
                            this.state.pages && this.state.pages.map(
                                (page)=>{
                                    return (
                                        <label className="page_item" key={"page_id_" + page.id}>                                        
                                            <input type="checkbox" page-id={page.id} page-access-token={page.access_token}/>
                                            <span>{page.name}</span>
                                        </label>
                                    );
                                }
                            )
                        }<br/><br/>
                        <button className="blueBTN" style={{display:'block', width:'100%'}} onClick={this.postToSelectedPages}>Post to Pages</button><br/>
                        <button className="blueBTN" style={{display:'block', width:'100%'}} onClick={()=>{
                            this.facebookPagesRef.current.style.display = "none";
                        }}>Cancel</button>
                    </div>
                </div>  
                <div ref={this.socialFlowDialogRef} className="dialogNew extend2">
                    <div style={{textAlign:'center'}} ref={this.socialDialogStep0}>
                        <h4>Social Video - Step 1 of 2</h4>
                        <span>Select an Option to add a video</span><br/><br/>
                        <div>
                            <button className="blueBTN" style={{width:'100%', display:'block', margin:"4px 0px"}} onClick={()=>{
                                this.socialDialogStep0.current.style.display = "none";
                                this.socialDialogStep0Record.current.style.display = "block";
                                this.socialDialogStep0RecordElementRef.current.resetUI();
                                this.socialDialogStep0RecordElementRef.current.openVideo();
                            }}>Record</button>
                            <button className="blueBTN" style={{width:'100%', display:'block', margin:"4px 0px"}} onClick={()=>{
                                this.socialDialogStep0.current.style.display = "none";
                                this.socialDialogStep0Upload.current.style.display = "block";
                                this.socialDialogStep0UploadElementRef.current.resetUI();
                            }}>Upload</button>
                            <button className="blueBTN" style={{width:'100%', display:'block', margin:"4px 0px"}} onClick={()=>{
                                this.socialFlowDialogRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                    <div style={{textAlign:'center', width:'480px'}} ref={this.socialDialogStep0Record}>
                        <h4>Social Video Record - Step 1 of 2</h4>
                        <span>Record a Video</span><br/><br/>
                        <div style={{width:'100%'}}>
                            <VideoRecordComponent
                                ref={this.socialDialogStep0RecordElementRef}
                                fixWidth={true}
                                watermark_url={this.state.user.watermark_url}
                                onclose={()=>{
                                    this.socialFlowDialogRef.current.style.display = "none";
                                    this.socialDialogStep0RecordElementRef.current.style.display="none";
                                }} oncompletion={
                                    (video_url)=>{
                                        this.acceptSoicalVideo(video_url);
                                        this.socialDialogStep0Record.current.style.display = "none";
                                        this.socialDialogStep1.current.style.display = "block";
                                    }
                                }></VideoRecordComponent>
                        </div>
                    </div>
                    <div style={{textAlign:'center'}} ref={this.socialDialogStep0Upload}>
                        <h4>Social Video Upload - Step 1 of 2</h4>
                        <span>Select a video to upload</span><br/><br/>
                        <div style={{width:'100%'}}>
                            <VideoUploadComponent ref={this.socialDialogStep0UploadElementRef} watermark_url={this.state.user.watermark_url}
                                onreject={()=>{
                                    this.socialDialogStep0UploadElementRef.current.resetUI();
                                }}

                                onclose={()=>{
                                    this.socialFlowDialogRef.current.style.display = "none";
                                }}
                                
                                onaccept={(video_url)=>{
                                    this.acceptSoicalVideo(video_url);
                                    this.socialDialogStep0Upload.current.style.display = "none";
                                    this.socialDialogStep1.current.style.display = "block";
                                }}/>
                        </div>
                    </div>
                    <div style={{textAlign:'center'}} ref={this.socialDialogStep1}>
                        <h4>Social Text - Step 2 of 2</h4>
                        <span>Enter text below</span><br/><br/>
                        <textarea style={{width:'100%'}} ref={this.socialDialogStep1Text} defaultValue={ this.state.campaign.social_text}>{ this.state.campaign.social_text}</textarea>
                        <div>
                            <button className="blueBTN" style={{width:'100%', display:'block', margin:"4px 0px"}} onClick={this.saveSocialFlowText}>Save</button>
                            <button className="blueBTN" style={{width:'100%', display:'block', margin:"4px 0px"}} onClick={()=>{
                                this.socialFlowDialogRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>                              
                <div ref={this.marketingOpenRate} className="dialogNew extend">        
                    <div>
                        <h4>New Opens</h4>
                        <span>You have the following new opens</span>
                        <div>
                            <ul style={{textAlign:'left', listStyle:'none'}}>
                                <li>Bespoke: { this.state.campaign?.openDiff?.bespoke }</li>
                                <li>Campaign: { this.state.campaign?.openDiff?.campaign }</li>
                                {/*<li>Vlog: { this.state.campaign?.openDiff?.social }</li>*/}
                            </ul>
                        </div>
                        <div>
                            <button onClick={async ()=>{
                                this.setState({
                                    ...this.state,
                                    loading: true
                                });
                                this.marketingOpenRate.current.style.display = "none";
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.marketing.campaign.addSetOpens(api_key, this.state.campaign.id);
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                <div ref={this.openLabelCreationDialogRef} className="dialogNew extend">        
                    <div>
                        <h4>Add Introduction Videos Video</h4>
                        <span>Select a Title form below or type a custom one</span>
                        <input style={{width:'calc(100% - 20px)',padding:'8px', border:'1px solid #1976D1', borderRadius:'8px', backgroundColor:'#e6e6e6'}} list="custom_labels_options" ref={this.customLabelInputRef}/>
                        <datalist id="custom_labels_options">
                            <option value="Company intro" />
                            <option value="Demo Video" />
                            <option value="Our Services" />
                            <option value="Meet the team" />
                            <option value="What we offer" />
                            <option value="How it works" />
                            <option value="Learn more" />
                        </datalist>
                        <div>
                            <button onClick={this.nextCustomLabelStep1}>Next</button>
                            <button onClick={()=>{
                                this.customLabelInputRef.current.value = "";
                                this.openLabelCreationDialogRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                <div ref={this.openLabelCreationDialogOptionRef} className="dialogNew extend">        
                    <div>
                        <h4>Choice an option</h4>
                        <div>
                            <button onClick={()=>{
                                this.openLabelCreationDialogOptionRef.current.style.display = "none";
                                this.openLabelCreationDialogUploadRef.current.style.display = "flex";
                            }}>Upload File</button>
                            <button onClick={()=>{
                                this.openLabelCreationDialogOptionRef.current.style.display = "none";                            
                                this.openLabelCreationDialogVideoRef.current.style.display = "flex";
                                this.openLabelCreationDialogVideoRecorderRef.current.resetUI();
                                this.openLabelCreationDialogVideoRecorderRef.current.openVideo();
                            }}>Record Video</button>
                            <button onClick={()=>{
                                this.openLabelCreationDialogOptionRef.current.style.display = "none";                            
                                this.openLabelCreationDialogLibraryRef.current.style.display = "flex";
                                this.loadCustomLabelLibraryVideos();
                            }}>Library</button>
                            <button onClick={()=>{
                                this.openLabelCreationDialogOptionRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>  
                
                <div ref={this.openLabelCreationDialogUploadRef} className="dialogNew extend">        
                    <div>
                        <h4>Upload File</h4>
                        <VideoUploadComponent ref={this.socialVideoUploadRef} watermark_url={this.state.user.watermark_url}
                            onreject={()=>{
                                this.openLabelCreationDialogUploadRef.current.style.display = "none";
                            }}

                            onclose={()=>{
                                this.openLabelCreationDialogUploadRef.current.style.display = "none";
                            }}
                            
                            onaccept={
                                async (video_url)=>{
                                    /*
                                        if user accepts video
                                    */
                                    let api_key = localStorage.getItem('api_key');
                                    let result = await API.labels.create(api_key, this.customLabelTitle, video_url, this.state.campaign.id);
                                    this.openLabelCreationDialogUploadRef.current.style.display = "none";                  
                                    this.addVideoToLibraryCustomDialogRef.current.style.display = "flex";                                   
                                    this.addVideoToLibraryCustomDialogRefVideo.current.src = video_url;
                                }
                            }/>
                    </div>
                </div>  
                <div ref={this.openLabelCreationDialogLibraryRef} className="dialogNew extend">        
                    <div>
                        <h4>Library</h4>
                        <div className="library_videos_container">
                            {
                                this.state.library_videos.map((item)=>{
                                    return (
                                        <div>
                                            <video src={item.video_url} controls></video>
                                            <span>{item.title}</span>
                                            <button data-video-link={item.video_url}
                                                onClick={
                                                    async (e)=>{
                                                        let video_link = e.target.getAttribute('data-video-link');
                                                        let api_key = localStorage.getItem('api_key');
                                                        let result = await API.labels.create(api_key, this.customLabelTitle, video_link, this.state.campaign.id);
                                                        this.openLabelCreationDialogLibraryRef.current.style.display = "none";
                                                        toast("Label Created");
                                                        this.reloadLabels();                                
                                                    }
                                                }
                                            >Select</button>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div>
                            <button onClick={()=>{
                                this.openLabelCreationDialogLibraryRef.current.style.display = "none";
                                this.openLabelCreationDialogOptionRef.current.style.display = "flex";  
                            }}>Close</button>
                        </div>
                    </div>
                </div>                  
                <div ref={this.addVideoToLibraryCustomDialogRef} className="dialogNew extend">        
                    <div>
                        <h4>Add To Library</h4>
                        <video ref={this.addVideoToLibraryCustomDialogRefVideo} style={{width:'100%'}}></video>
                        <div>
                            <button onClick={async ()=>{
                                this.addVideoToLibraryCustomDialogRef.current.style.display = "none";         
                                let api_key = localStorage.getItem('api_key');                               
                                let result = await API.labels.library.create(api_key, this.customLabelTitle, this.addVideoToLibraryCustomDialogRefVideo.current.getAttribute('src'));
                                toast("Label Created");
                                this.reloadLabels();
                            }}>Add</button>
                            <button onClick={async ()=>{
                                this.addVideoToLibraryCustomDialogRef.current.style.display = "none";                                
                                toast("Label Created");
                                this.reloadLabels();
                            }}>Close</button>
                        </div>
                    </div>
                </div>  
                <div ref={this.openLabelCreationDialogVideoRef} className="dialogNew extend">        
                    <div>
                        <h4>Record Video for {this.customLabelTitle}</h4>
                        <VideoRecordComponent
                            ref={this.openLabelCreationDialogVideoRecorderRef}
                            fixWidth={true}
                            /* watermark_url={this.state.user.watermark_url} */
                            onclose={()=>{
                                /*
                                    If user closes or rejects this video
                                */
                                    this.openLabelCreationDialogVideoRef.current.style.display = "none";
                            }} oncompletion={
                                async (video_url)=>{
                                    /*
                                        if user accepts video
                                    */
                                    let api_key = localStorage.getItem('api_key');
                                    let result = await API.labels.create(api_key, this.customLabelTitle, video_url, this.state.campaign.id);
                                    this.openLabelCreationDialogVideoRef.current.style.display = "none";                                    
                                    this.addVideoToLibraryCustomDialogRef.current.style.display = "flex";                                   
                                    this.addVideoToLibraryCustomDialogRefVideo.current.src = video_url;
                                }
                            }></VideoRecordComponent>
                    </div>
                </div>                   
                { 
                    this.state.loading ? (
                        <div className="loader">                        
                            <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                        </div>
                    ) : ""
                }
                {   
                    this.state.storageNotice && <div className="dialogNewCreate">
                        <div style={{minWidth:'720px'}}>
                            <h4>Your are running out of storage space</h4>
                            <p>You have a less than 1GB of storage left</p>
                            <div>
                                <button onClick={()=>{
                                    this.setState({
                                        ...this.state,
                                        storageNotice: false
                                    });
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                }            
                <TutorialComponent page_number="8"></TutorialComponent> 
            </>
        );
    }
}

export default withRouter(MarketingViewPage); 