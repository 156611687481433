import React from 'react';

interface MarketingHeaderComponentProps {
    apiKey:string;
    marketing:any;
    noWidth:boolean;
}

export default function MarketingHeaderComponent(props:MarketingHeaderComponentProps){
    if(props.noWidth){
        if(!props.marketing){
            return <div className="pageNameInfo page-header-component">
                <span>Marketing Campaign &gt; <span style={{color: "#1976D1"}}>Loading</span></span>
            </div>
        }
        return <div className="pageNameInfo page-header-component">
            <span>Marketing Campaign &gt; <span style={{color: "#1976D1"}}>{ props.marketing.title } <div style={{display: "inline-block", fontSize: "9pt", color:"rgb(255, 132, 0)"}}>({ props.marketing.id })</div></span></span>
        </div>
    } else {
        if(!props.marketing){
            return <div className="pageNameInfo page-header-component" style={{width:'calc(100vw - 280px)'}}>
                <span>Marketing Campaign &gt; <span style={{color: "#1976D1"}}>Loading</span></span>
            </div>
        }
        return <div className="pageNameInfo page-header-component" style={{width:'calc(100vw - 280px)'}}>
            <span>Marketing Campaign &gt; <span style={{color: "#1976D1"}}>{ props.marketing.title } <div style={{display: "inline-block", fontSize: "9pt", color:"rgb(255, 132, 0)"}}>({ props.marketing.id })</div></span></span>
        </div>
    }
}