import React, { createRef } from "react";

import API from "./../services/api";

import "./fileupload.css";

export default class FileUploadComponent extends React.Component {
  constructor(props) {
    super(props);
    this.videoUploadRef = props.ref;
    this.closeCallback = props.onclose;
    this.completionCallback = props.oncompletion; // returns video_url
    this.errorCallback = props.onerror;
    this.state = {
      progress: 0,
    };

    this.step1Ref = createRef();
    this.step2Ref = createRef();
    this.progressRef = createRef();
    this.fileRef = createRef();

    this.startUpload = this.startUpload.bind(this);
    this.getFileData = this.getFileData.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
  }

  async componentDidMount() {
    this.step1Ref.current.style.display = "block";
    this.step2Ref.current.style.display = "none";
  }

  async startUpload() {
    let files = this.fileRef.current.files;
    if (!files || files.length == 0) {
      alert("Please select a file");
      return;
    }
    this.step1Ref.current.style.display = "none";
    this.step2Ref.current.style.display = "block";
    let api_key = localStorage.getItem("api_key");
    let file = files[0];
    //let data = await this.getFileData(file);
    //let datastripped = data.split(";base64,")[1];
    let extension = file["name"];
    let filename = await API.file.uploadFileAsFileWithProgress(
      api_key,
      extension,
      file,
      this.updateProgress
    );    
    this.step1Ref.current.style.display = "block";
    this.step2Ref.current.style.display = "none";
    this.completionCallback(filename);
  }

  hide() {
    this.videoUploadRef.current.style.display = "none";
  }
  
  show() {
    this.videoUploadRef.current.style.display = "block";
  }

  async getFileData(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function (e) {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  updateProgress(percentage) {
    this.setState({
      ...this.state,
      progress: percentage,
    });
  }

  render() {
    // HTML
    return (
      <div className="filedialogNew" ref={this.videoUploadRef}>
        <div ref={this.step1Ref}>
          <h4>Upload a file</h4>
          <span>Select a file to upload</span>
          <input ref={this.fileRef} type="file" accept={[".mp4", ".avi"]} />
          <div>
            <button onClick={this.closeCallback}>Close</button>
            <button onClick={this.startUpload}>Upload</button>
          </div>
        </div>
        <div ref={this.step2Ref}>
          <h4>File Uploading - please wait</h4>
          <span>Upload Percentage: {this.state.progress.toFixed(2)}%</span>
          <progress
            className="uploadProgress"
            ref={this.progressRef}
            value={this.state.progress}
            max="100"
          ></progress>
        </div>
      </div>
    );
  }
}
