import React, { createRef } from "react";
import API from "../services/api";

import { toast } from 'react-toastify';

import { AiOutlineLoading } from 'react-icons/ai';

import "./customWatermarkContainer.css";

export default class CustomWatermarkComponent extends React.Component {

    constructor(props) {
        super(props);  
        this.state = {
            loading: false
        }
        this.hideAll = this.hideAll.bind(this);
        this.preview = this.preview.bind(this);
        this.upload = this.upload.bind(this);
        this.uploadWatermark = this.uploadWatermark.bind(this);
        this.deleteWatermark = this.deleteWatermark.bind(this);
        this.uploadDialogRef = createRef();
        this.previewDialogRef = createRef();
        this.watermarkImageRef = createRef();
        this.watermarkImageUploadFileRef = createRef();
    }

    componentDidMount(){
        this.hideAll();
    }

    hideAll(){
        this.uploadDialogRef.current.style.display = 'none';
        this.previewDialogRef.current.style.display = 'none';
    }

    upload(){        
        this.hideAll();
        this.uploadDialogRef.current.style.display = 'flex';
        
    }
    async preview(){
        this.setState({...this.state, loading: true});
        this.hideAll();
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUserWatermark(api_key);
        let watermark_url = result['watermark_url'];
        if(watermark_url){
            this.setState({...this.state, loading: false});
            this.previewDialogRef.current.style.display = 'flex';
            this.watermarkImageRef.current.src = watermark_url;
            this.previewDialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            this.setState({...this.state, loading: false});
            toast("No Watermark Set");
        }
    }

    async getFileData(file){
        return new Promise((resolve, reject)=>{
            let reader =  new FileReader();
            reader.onload = function(e) {
                resolve(e.target.result);
            };
            reader.readAsDataURL(file);
        });
    }

    async uploadWatermark(){
        let file = this.watermarkImageUploadFileRef.current.files[0];
        if(file){
            this.setState({...this.state, loading: true});
            let api_key = localStorage.getItem('api_key');
            //let filedata1 = await this.getFileData(file);
            //let filedata2 = filedata1.split('base64,')[1];
            let result1 = await API.file.uploadFileAsFile(api_key, file['name'], file);
            let uploadedFileName = result1['file_url'];
            let result2 = await API.user.setUserWatermark(api_key, uploadedFileName);
            if(result2['success']){
                this.setState({...this.state, loading: false});
                toast("Watermark Uploaded");
                window.location.reload();
            } else {
                toast("Error Uploading Watermark");
            }
        } else {
            toast("No file selected");
        }
    }

    async deleteWatermark(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.setUserWatermark(api_key, "");        
        window.location.reload();
    }

    render(){
        // HTML
        return ( 
            <>
            <div className="customWatermarkContainer">
                <h1>Custom Watermark</h1>
                <h4>set a custom watermark on all candidate video responses.</h4>
                <button onClick={this.upload}>Upload Image</button>
                <button onClick={this.preview}>Preview Image</button>
                <div ref={this.uploadDialogRef} className="dialogNew">        
                    <div>
                        <h4>Upload Watermark</h4>
                        <div>
                            <span>Select an image to upload to be used as a watermark</span><br /><br />
                            <input type="file" ref={this.watermarkImageUploadFileRef}  accept=".png, .jpeg, .jpg"/><br /><br />
                        </div>
                        <div>
                            <button onClick={this.uploadWatermark}>Upload</button>
                            <button onClick={this.hideAll}>Close</button>
                        </div>
                    </div>
                </div>
                <div ref={this.previewDialogRef} className="dialogNew">        
                    <div>
                        <h4>Watermark Preview</h4>
                        <div>
                            <span>This watermark will be used to watermark newly created video</span>
                            <span>
                                <img className="watermarkImageContainer" ref={this.watermarkImageRef} alt="Watermark Image" />
                            </span>
                        </div>
                        <div>
                            <button onClick={this.deleteWatermark}>Delete</button>
                            <button onClick={this.hideAll}>Close</button>
                        </div>
                    </div>
                </div>
                { 
                    this.state.loading ? (<div className="loader">
                        <span>☯</span>
                        <div>Loading, Please Wait <AiOutlineLoading className="loading-spinner"/></div>
                    </div>) : ""
                }
            </div>
            </>
        );
      }
}