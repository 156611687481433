import React, { createRef } from "react";

import { DateTime } from "luxon";

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { AiFillStar, AiFillCamera, AiOutlineLoading } from 'react-icons/ai';


import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import { Tooltip as ReactTooltip } from "react-tooltip";
import fuzzytime from 'fuzzy-time';

import "./vacancies.css";

import SideBarComponent from "../components/sidebar";
import UpperBarComponent from "../components/upperbar";
import API from "../services/api";


import { ToastContainer, toast } from 'react-toastify';

const PAGE_SIZE = 10;

export default class VacanciesArchivedPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        interviews: [],
        paged_interviews: [],
        page_id: 0,
        pages: [],
        notices: "",
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 

      this.createInterviewDialogRef = createRef();
      this.createInterviewDialogTitleRef = createRef();

      this.getDeg = this.getDeg.bind(this);
      //this.getApplicationCircle = this.getApplicationCircle.bind(this);
      
      this.openInterview = this.openInterview.bind(this);
      this.deleteInterview = this.deleteInterview.bind(this);
      this.openCreateInterview = this.openCreateInterview.bind(this);
      this.unarchiveOne = this.unarchiveOne.bind(this);

      this.closeDialog = this.closeDialog.bind(this);

      this.reloadPage = this.reloadPage.bind(this);
      this.getTime = this.getTime.bind();
      
      this.unarchiveSelected = this.unarchiveSelected.bind(this);
      this.gotoPage = this.gotoPage.bind(this);
    }

    getTime(dt){
        return DateTime.fromISO(dt).toFormat("dd-MM-yyyy HH:mm");
        //return fuzzytime(dt);
    }

    async componentDidMount(){
        function myFunction() {
            document.getElementById("myDropdown").classList.toggle("show");
        }
        
        // Close the dropdown menu if the user clicks outside of it
        window.onclick = function(event) {
            if (!event.target.matches('.dropbtn')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
                }
            }
            }
        }
        this.createInterviewDialogRef.current.style.display = "none";
        this.reloadPage();
    }

    async reloadPage(){
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');

        let result = await API.interview.listArchived(api_key);
        let interviews = result['interviews'];
        result = await API.interview.getNotice(api_key);
        let notices = result['notice'];
        let total_pages = Math.ceil(interviews.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        this.setState({
            ...this.state,
            interviews: interviews,
            paged_interviews: interviews.slice(0, PAGE_SIZE),
            notices: notices,
            loading: false,
            pages: pages,
        });
    }
    
    polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    };
    
    describeSlice = (x, y, radius, startAngle, endAngle) => {
    
        const start = this.polarToCartesian(x, y, radius, endAngle);
        const end = this.polarToCartesian(x, y, radius, startAngle);
    
        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    
        const d = [
            "M", 0, 0, start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
    
        return d;
    };
    
    path = (degrees = 90, radius = 10) => {
        return this.describeSlice(0, 0, radius, 0, degrees) + 'Z';
    };

    getDeg(u, t){
        let x = u/t;
        
        if(x === 1 || (this.state.used === 0&& this.state.total === 0)){
            return 359.9;
        }
        return x * 360;
    }

    /*getApplicationCircle(applications){
        let total = applications.total;
        let invited = applications.invited;
        let totalCstr = total + " Candidates";
        let invitedCstr = invited + " Candidates";
        let deg = this.getDeg(invited, total);
        return (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{width: "64px", height:"64px"}}>
                <circle cx="16" cy="16" r="8" fill="#32CD32">
                <title>{ totalCstr }</title>
                </circle>
                <path d={this.path(deg, 8)} fill="#1E90FF" style={{transform: "translateX(16px) translateY(16px) rotateZ(180deg)"}}>
                    <title>{ invitedCstr }</title>
                </path>
                <circle cx="16" cy="16" r="4" fill="#FFFFFF" />  
                <text x="14" y="18" style={{"font-size": "3pt"}}>{ total }</text>              
            </svg>
        );
    }*/

    openCreateInterview(){
        this.createInterviewDialogRef.current.style.display = "flex";
    }

    closeDialog(){
        this.createInterviewDialogRef.current.style.display = "none";
    }

    openInterview(e){
        let iid = e.target.getAttribute('data-interview-id');
        window.location.href = "/vacancy/" + iid;
    }

    async deleteInterview(e){
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let iid = e.target.getAttribute('data-interview-id');
        let result = await API.interview.delete(api_key, iid);
        this.reloadPage();
        this.setState({
            ...this.state,
            loading: false
        });
    }

    
    async unarchiveSelected(){        

        let vacancy_ids = Array.from(document.querySelectorAll('input[data-vacancy-checkbox=true]:checked')).map((input)=>{
            return parseInt(input.getAttribute('vacancy-id'));
        });
        if(vacancy_ids.length == 0){
            toast.error("No vacancies selected, select a vacancy first");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.unarchive(api_key, vacancy_ids);
        this.setState({
            ...this.state,
            loading: false
        });
        this.reloadPage();
    }
    
    async unarchiveOne(e){  
        let vacancy_ids = [parseInt(e.target.getAttribute('data-interview-id'))];
        if(vacancy_ids.length == 0){
            toast.error("No vacancies selected, select a vacancy first");
            return;
        }
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.unarchive(api_key, vacancy_ids);
        this.setState({
            ...this.state,
            loading: false
        });
        this.reloadPage();
    }
    
    gotoPage(e){
        let page_id = parseInt(e.target.getAttribute('data-page-id'));
        let paged_interviews = this.state.interviews.slice(page_id * PAGE_SIZE, (page_id * PAGE_SIZE) + PAGE_SIZE);
        this.setState({
            paged_interviews: paged_interviews,
            page_id: page_id
        });
        window.scrollTo(0,0);
    }

    render() {
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Vacancies</span>                        
                        <button className="blueBTN" style={{position:'relative'}} onClick={()=>{
                            window.location.href = "/vacancies/users";
                        }}>Users Vacancies</button>&nbsp;
                        <button className="blueBTN" style={{position:'relative'}} onClick={()=>{
                            window.location.href = "/vacancies";
                        }}>Normal Vacancies</button>&nbsp;
                        <button className="blueBTN" style={{position:'relative'}} onClick={()=>{
                            window.location.href = "/vacancies/seated";
                        }}>Seated Vacancies</button>
                    </div>
                    <div className="vacancy-table">
                        <table>
                            <tr className="noHover">
                                <th style={{minWidth:'150px'}}>                            
                                    <span class="dropdown" style={{display:'inline-block'}}>
                                        <button>Actions</button>
                                        <label>
                                            <input type="checkbox" />
                                            <ul>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={this.unarchiveSelected}>Unarchive</li>
                                            <li style={{fontWeight:400, color:'#000'}} onClick={this.deleteSelected}>Delete</li>
                                            </ul>
                                        </label>
                                    </span>
                                </th>
                                <th>Job Title</th>
                                <th>Open Rate</th>
                                <th>Created</th>
                            </tr>                     
                                { this.state.interviews.length == 0 &&
                                    <tr>
                                        <td style={{width:'calc(100vw / 6)'}}></td>
                                        <td style={{width:'calc(100vw / 6)'}}></td>
                                        <td style={{width:'calc(100vw / 6)'}}></td>
                                        <td style={{width:'calc(100vw / 6)'}}></td>
                                    </tr>
                                }
                                {
                                   this.state.interviews.map((interview)=>{
                                        let r_id = interview.id;
                                        let showData3Grey = interview.interview_count == 0 && interview.open_rate.interview == 0;
                                        let data3 = [
                                            { name: 'Total', value: interview.interview_count - interview.open_rate.interview},
                                            { name: 'Interview', value: interview.open_rate.interview >= 1 && interview.interview_count == 0 ? 1 : interview.open_rate.interview},
                                        ];
                                        let showDataGrey = interview.sent_count == 0 && interview.open_rate.bespoke == 0;
                                        let data = [
                                            { name: 'Total', value: interview.sent_count - interview.open_rate.bespoke},
                                            { name: 'Bespoke', value: interview.open_rate.bespoke >= 1 && interview.sent_count == 0 ? 1 : interview.open_rate.bespoke},
                                        ];
                                        let showData2Grey = interview.sent_count_marketing == 0 && interview.open_rate.campaign == 0;
                                        let data2 = [
                                            { name: 'Total', value: interview.sent_count_marketing - interview.open_rate.campaign } ,
                                            { name: 'Campaign', value: interview.open_rate.campaign }
                                        ];
                                
                                        let newOpens = interview.openDiff.bespoke ?? 0;
                                        if(newOpens >= 10){
                                            newOpens = "+";
                                        }
                                        
                                        let newOpens4 = interview.openDiff.interview ?? 0;
                                        if(newOpens4 >= 10){
                                            newOpens4 = "+";
                                        }
                                        
                                        let newOpens2 = interview.openDiff.campaign ?? 0;
                                        if(newOpens2 >= 10){
                                            newOpens2 = "+";
                                        }
                                        
                                        let newOpens3 = interview.openDiff.social ?? 0;
                                        if(newOpens3 >= 10){
                                            newOpens3 = "+";
                                        }
                                        return (
                                        <tr key={"interview-" + interview.id}  className="table-body">
                                            <td style={{minWidth:'150px'}}>
                                                <input type="checkbox" data-vacancy-checkbox={true} vacancy-id={interview.id} style={{display:'inline-block', transform:"scale(1.5)", marginRight:'8px'}}/>
                                                <span class="dropdown" style={{display:'inline-block'}}>
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul>
                                                        <li data-interview-id={interview.id} onClick={this.openInterview}>Open</li>
                                                        <li data-interview-id={interview.id} onClick={this.editInterview}>Edit</li>
                                                        <li data-interview-id={interview.id} onClick={this.deleteInterview}>Delete</li>
                                                        <li data-interview-id={interview.id} onClick={this.unarchiveOne}>Unarchive</li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </td>
                                            <td style={{textAlign:'center', maxWidth:'360px'}}>{ interview.title }</td>
                                            <td className="openRateVacancy2">
                                                <div data-tip data-for={`tooltip-${interview.id}-xx`} style={{display:'inline-block', marginRight:'8px'}} onClick={()=>{
                                                    window.location.href = `/vacancy/${interview.id}?#interview`;
                                                }}>
                                                    <PieChart width={72} height={72} style={{cursor:'pointer', transform:'translateX(14px)'}}>
                                                        <Pie
                                                            data={!showData3Grey ? data3 : [{name: 'Total', value: 1}]}
                                                            cx={72/2}
                                                            cy={72/2}
                                                            innerRadius={10}
                                                            outerRadius={32}
                                                            fill="red"
                                                            paddingAngle={0}
                                                            dataKey="value"
                                                            >
                                                            { !showData3Grey && data3.map((entry, index) => (
                                                                <Cell key={`campaign-${r_id}-cell-${index}`} fill={interview.open_rate.interview == 0 && interview.interview_count == 0 ? "#CCCCCC" : ["#0088FE","#00C49F","#FFBB28"][index % 3]} />
                                                            ))}
                                                            {
                                                                showData3Grey && <Cell key={`campaign-${r_id}-cell-${0}`} fill={"#CCCCCC"}/>
                                                            }
                                                        </Pie>
                                                        <text x={72/2 + 4} y={72/2 + 4} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={"12pt"} stroke="red">
                                                            {
                                                                Math.abs(newOpens4)
                                                            }
                                                        </text>
                                                    </PieChart>
                                                    <div style={{fontSize:'8pt', transform:'translateX(28px)'}}>Interview</div>
                                                </div>
                                                <ReactTooltip id={`tooltip-${interview.id}-xx`} aria-haspopup='true'>
                                                    <h4>New Opens</h4>
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Interview: { Math.abs(interview.openDiff.interview ?? 0) }</li>
                                                    </ul>
                                                    <h4>Total Opens</h4>
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Interview: { Math.abs(interview.open_rate.interview ?? 0) } / { interview.interview_count ?? 0 }</li>
                                                    </ul>
                                                </ReactTooltip>
                                                <div data-tip data-for={`tooltip-${interview.id}`} style={{display:'inline-block', marginRight:'8px'}} onClick={()=>{
                                                    window.location.href = `/vacancy/${interview.id}?#marketing`;
                                                }}>
                                                    <PieChart width={72} height={72} style={{cursor:'pointer', transform:'translateX(14px)'}}>
                                                        <Pie
                                                            data={!showDataGrey ? data : [{name: 'Total', value: 1}]}
                                                            cx={72/2}
                                                            cy={72/2}
                                                            innerRadius={10}
                                                            outerRadius={32}
                                                            fill="red"
                                                            paddingAngle={0}
                                                            dataKey="value"
                                                            >
                                                            {   !showDataGrey && data.map((entry, index) => (
                                                                <Cell key={`campaign-${r_id}-cell-${index}`} fill={interview.open_rate.bespoke == 0 && interview.sent_count == 0 ? "#CCCCCC" : ["#0088FE","#00C49F","#FFBB28"][index % 3]} />
                                                            ))}
                                                            {
                                                                showDataGrey && <Cell key={`campaign-${r_id}-cell-${0}`} fill={"#CCCCCC"}/>
                                                            }
                                                        </Pie>
                                                        <text x={72/2 + 4} y={72/2 + 4} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={"12pt"} stroke="red">
                                                            {
                                                                Math.abs(newOpens)
                                                            }
                                                        </text>
                                                    </PieChart>
                                                    <div style={{fontSize:'8pt', transform:'translateX(28px)'}}>Bespoke</div>
                                                </div>
                                                <ReactTooltip id={`tooltip-${interview.id}`} aria-haspopup='true'>
                                                    <h4>New Opens</h4>
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Bespoke: { Math.abs(interview.openDiff.bespoke ?? 0) }</li>
                                                    </ul>
                                                    <h4>Total Opens</h4>
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Bespoke: { Math.abs(interview.open_rate.bespoke ?? 0) } / { interview.sent_count ?? 0 }</li>
                                                    </ul>
                                                </ReactTooltip>
                                                <div data-tip data-for={`tooltip-${interview.id}-2`} style={{display:'inline-block'}} onClick={()=>{
                                                    window.location.href = `/vacancy/${interview.id}?#bulkmarketing`;
                                                }}>
                                                    <PieChart width={72} height={72} style={{cursor:'pointer', transform:'translateX(16px)'}}>
                                                        <Pie
                                                            data={!showData2Grey ? data2 : [{name: 'Total', value: 1}]}
                                                            cx={72/2}
                                                            cy={72/2}
                                                            innerRadius={10}
                                                            outerRadius={32}
                                                            fill="red"
                                                            paddingAngle={0}
                                                            dataKey="value"
                                                            >
                                                            {   !showData2Grey && data.map((entry, index) => (
                                                                <Cell key={`campaign-${r_id}-cell-${index}`} fill={interview.open_rate.campaign == 0 && interview.sent_count_marketing == 0 ? "#CCCCCC" : ["#0088FE","#00C49F","#FFBB28"][index % 3]} />
                                                            ))}
                                                            {
                                                                showData2Grey && <Cell key={`campaign-${r_id}-cell-${0}`} fill={"#CCCCCC"}/>
                                                            }
                                                        </Pie>
                                                        <text x={72/2 + 4} y={72/2 + 4} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={"12pt"} stroke="red">
                                                            {
                                                                Math.abs(newOpens2)
                                                            }
                                                        </text>
                                                    </PieChart>                                                       
                                                    <div style={{fontSize:'8pt', transform:'translateX(28px)'}}>Campaign</div>
                                                </div>
                                                <ReactTooltip id={`tooltip-${interview.id}-2`} aria-haspopup='true'>
                                                    <h4>New Opens</h4>
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Camapign: { Math.abs(interview.openDiff.campaign ?? 0) }</li>
                                                    </ul>
                                                    <h4>Total Opens</h4>
                                                    <ul style={{listStyle:'none'}}>
                                                        <li>Camapign: { Math.abs(interview.open_rate.campaign ?? 0) } / { interview.sent_count_marketing ?? 0}</li>
                                                    </ul>
                                                </ReactTooltip>
                                                    <div data-tip data-for={`tooltip-${interview.id}-3`} style={{display:'inline-block', cursor:'pointer', transform:"translate(31px, 0px)"}} onClick={()=>{
                                                        window.location.href = `/vacancy/${interview.id}?#social`;
                                                    }}>
                                                    <div /*data-tip data-for={`tooltip-${record.id}-2`}*/ style={{display:'inline-block', marginTop:'20px', transform:'translateX(-5px)'}} onClick={()=>{
                                                        window.location.href = `/vacancy/${interview.id}?#campaign`;
                                                    }}>
                                                        <div style={{transform:'translateY(-18px)', textAlign:'center'}}>{ interview.open_rate.social }</div>                                                        
                                                        <div style={{fontSize:'8pt'}}>Vlog Views</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{padding:'32px'}}>{ this.getTime(interview.created) }</td>
                                        </tr>
                                    )})
                                }
                        </table>
                        <div className="pagination_table">
                            <span>Pagination</span>
                            <div>
                                {
                                    this.state.pages.map((page)=>{
                                        return (
                                            this.state.page_id == page - 1 ? 
                                            <button data-page-id={page - 1} onClick={this.gotoPage} className="active">{page}</button> :
                                            <button data-page-id={page - 1} onClick={this.gotoPage}>{page}</button>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="dialogNewCreate" ref={this.createInterviewDialogRef}>        
                        <div>
                            <h4>Add a new vacancy</h4>
                            <div>
                                <input type="text"  ref={this.createInterviewDialogTitleRef} className="createVacancyInput" placeholder="Vacancy Name"/>
                            </div>
                            <div>
                                <button onClick={this.createInterview}>Create</button>
                                <button onClick={this.closeDialog}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
                { 
                    this.state.loading ? (
                        <div className="loader">                        
                            <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                        </div>
                    ) : ""
                }
                
                </>
        );
      }
}