import React, { createRef } from "react";
import { FaThumbsDown } from "react-icons/fa";
import { withRouter } from "react-router";
import { toast, ToastContainer } from "react-toastify";

import API from './../services/api';

import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';

import "./questions.css";
import VideoRecordComponent from "./videorecord";
import MultiOption from "./MultiOption";

class QuestionComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: props.user,
        interview: props.interview,
        questions: [],
        step: 0,
        newQType: 'text',
        recorderElement: <></>,
        allowInterview: null,
        candidatesNotCopied: null,
        multiChoiceData: { question:'', items: []}
      };
      this.wss = null;

      this.dropdownRef = createRef();
      this.hideShowBtnRef = createRef();
      
      this.createQuestionRef = createRef();
      
      this.doneBtnRef = createRef();
      this.saveAndDoneBtnRef = createRef();
      this.saveAndNextBtnRef = createRef();
      this.saveAndDone3BtnRef = createRef();
      this.saveAndNext3BtnRef = createRef();

      this.createQuestionStep1Ref = createRef();
      this.createQuestionStep2Ref = createRef();
      this.createQuestionStep2VideoRef = createRef();
      this.createQuestionStep2VideoElementRef = createRef();
      this.createQuestionStep2VideoDurationRef = createRef();
      this.createQuestionStep3Ref = createRef();
      this.createQuestionVTypeRef = createRef();
      this.createQuestionStep2TextRef = createRef();

      this.createQuestionRecommendTimeRef = createRef();
      this.createQuestionRecommendTimeRef2 = createRef();
      this.createQuestionRecommendTimeRef3 = createRef();
      
      this.restartRecording2Ref = createRef();
      this.startRecording2Ref = createRef();
      
      this.createQuestionRecommendTimeRef2Video = createRef();
      this.createQuestionRecommendTimeRef2Counter = createRef();

      this.videoPreivewRef = createRef();
      this.videoPreivewVideoRef = createRef();

      this.createQuestionOptionVideo = createRef();
      this.createQuestionOptionText = createRef();


      this.editQuestionRef = createRef();
      this.editQuestiontextAreaRef = createRef();
      
      this.complisionPopupDialog = createRef();
      
      this.reRecordQuestionDialogRef = createRef();
      this.reRecordQuestionDialogVideoRef = createRef();

      this.multiAnswerDialogRef = createRef();
      this.multiAnswerDialogQuestionRef = createRef();
      this.multiAnswerDialogAnswerRef = createRef();

      this.toggle = this.toggle.bind(this);
      this.openNewQuestion = this.openNewQuestion.bind(this);
      this.deleteQuestion = this.deleteQuestion.bind(this);
      this.editQuestion = this.editQuestion.bind(this);
      this.openPreviewVideoPopUp = this.openPreviewVideoPopUp.bind(this);
      this.reloadQuestions = this.reloadQuestions.bind(this);
      this.closeCreateQuestionDialog = this.closeCreateQuestionDialog.bind(this);
      this.closeCreateQuestionDialog2 = this.closeCreateQuestionDialog2.bind(this);
      
      this.openReRecordVideoPopUp = this.openReRecordVideoPopUp.bind(this);

      this.nextStep = this.nextStep.bind(this);
      this.saveAndDone = this.saveAndDone.bind(this);
      this.saveAndNext = this.saveAndNext.bind(this);
      
      this.restartRecording2 = this.restartRecording2.bind(this);
      this.startRecording2 = this.startRecording2.bind(this);
      this.saveAndDone2 = this.saveAndDone2.bind(this);
      this.saveAndNext2 = this.saveAndNext2.bind(this);
      this.saveAndDone3 = this.saveAndDone3.bind(this);
      this.saveAndNext3 = this.saveAndNext3.bind(this);

      this.completionCallback = this.completionCallback.bind(this);

      this.closeVideoPreview = this.closeVideoPreview.bind(this);
      this.setInterview = this.setInterview.bind(this);
      
      this.bespokeAllowInterview = this.bespokeAllowInterview.bind(this);
      this.candidatesNotCopied = this.candidatesNotCopied.bind(this);
      this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
    }
    

    async bespokeAllowInterview(){
        return this.state.allowInterview;
    }

    async candidatesNotCopied(){
        return this.state.candidatesNotCopied;
    }

    async componentDidMount(){
        this.multiAnswerDialogRef.current.style.display = "none"; 
        this.dropdownRef.current.style.display = "none";  
        this.editQuestionRef.current.style.display = "none";  
        this.createQuestionRef.current.style.display = "none";
        this.complisionPopupDialog.current.style.display = "none";
        this.reRecordQuestionDialogRef.current.style.display = 'none';
        this.reloadQuestions();  
        if(this.state.questions.length >= 0){        
            this.dropdownRef.current.style.display = "none";
            this.hideShowBtnRef.current.innerHTML = "Show";
        } else {
            this.dropdownRef.current.style.display = "block";
            this.hideShowBtnRef.current.innerHTML = "Hide";
        }
        this.videoPreivewRef.current.style.display = "none";

        this.question_pulling = setInterval(()=>{
            this.reloadQuestions();
        }, 20000);

        if(this.state.candidatesNotCopied == null){
            let api_key = localStorage.getItem('api_key');
            let id = parseInt(this.props.match.params.id);
            let result = await API.interview.getHasCopied(api_key, id);
            this.setState({
                ...this.state,
                candidatesNotCopied: result['hasCopied'] 
            });
        }
        if(this.state.allowInterview == null){
            let api_key = localStorage.getItem('api_key');
            let id = parseInt(this.props.match.params.id);
            let result = await API.interview.get(api_key, id);
            this.setState({
                ...this.state,
                allowInterview: result['interview']['marketing_interview'] 
            });
        }
        
    }
    
    toggle(){
        if(this.dropdownRef.current.style.display != "block"){
            this.hideShowBtnRef.current.innerHTML = "Hide";
            this.dropdownRef.current.style.display = "block";
        } else {            
            this.hideShowBtnRef.current.innerHTML = "Show";
            this.dropdownRef.current.style.display = "none";
        }
    }

    async reloadQuestions(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.get(api_key, this.state.interview.id);
        let questions  = result['questions'];        
        let ids = questions.filter(a=>a).map((question)=>{return question.id});
        let result2 = await API.interview.setQuestionOrder(api_key, this.state.interview.id, ids);
        this.setState({
            ...this.state,
            questions: questions
        });
    }

    closeVideoPreview(){
        this.videoPreivewVideoRef.current.pause();
        this.videoPreivewRef.current.style.display = "none";
    }

    async openNewQuestion(){
        this.createQuestionRef.current.style.display = "flex";
        this.createQuestionRef.current.scrollIntoView();
        this.createQuestionStep1Ref.current.style.display = "block"; // create question, select type
        this.createQuestionStep2Ref.current.style.display = "none";  // if text, display input, else display recording component
        this.createQuestionStep2TextRef.current.value = "";
        this.createQuestionStep2VideoRef.current.style.display = "none";
        this.createQuestionStep2VideoDurationRef.current.style.display = "none";
        this.createQuestionStep3Ref.current.style.display = "none";  // if text, save and allow option to make anotehr question, else if video, this will only load once the recording has been approved or rejected after the intial review, this also allows to create another question
        this.setState({...this.state, step: 0});
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.hasTextOrVideoQuestions(api_key, this.state.interview.id);
        let value = result['value'];
        if(value == "both"){
            this.createQuestionOptionVideo.current.disabled = false;
            this.createQuestionOptionText.current.disabled = false;
            this.createQuestionVTypeRef.current.value = "text";
        } else if(value == "text"){
            this.createQuestionOptionVideo.current.disabled = true;
            this.createQuestionOptionText.current.disabled = false;
            this.createQuestionVTypeRef.current.value = "text";
        } else if(value == "video"){
            this.createQuestionOptionVideo.current.disabled = false;
            this.createQuestionOptionText.current.disabled = true;
            this.createQuestionVTypeRef.current.value = "video";
        } else {
            this.createQuestionOptionVideo.current.disabled = false;
            this.createQuestionOptionText.current.disabled = false;
        }
    }

    async openReRecordVideoPopUp(e){
        let qid = parseInt(e.target.getAttribute('question-id'));  
        this.reRecordQuestionDialogRef.current.setAttribute('question-id', qid);
        this.reRecordQuestionDialogRef.current.style.display = 'flex';
        this.reRecordQuestionDialogVideoRef.current.resetUI();
        this.reRecordQuestionDialogVideoRef.current.openVideo();
    }

    async deleteQuestion(e){
        let qid = parseInt(e.target.getAttribute('question-id'));
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.delete(api_key, qid);
        let success = result['success'];
        if(success){
            this.reloadQuestions();
        } else {
            alert("Error deleting question");
        }
    }

    async editQuestion(e){
        let qid = parseInt(e.target.getAttribute('question-id'));
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.getByID(api_key, qid);
        let question = result['question'];
        this.editQuestionRef.current.style.display = "flex";
        this.editQuestionRef.current.setAttribute('q-id', qid);
        this.editQuestiontextAreaRef.current.value = question['text'];        
    }

    async openPreviewVideoPopUp(e){
        let video_url = e.target.getAttribute('video-url');
        this.videoPreivewRef.current.style.display = "flex";
        this.videoPreivewVideoRef.current.src = video_url;
        this.videoPreivewVideoRef.current.play();
    }

    closeCreateQuestionDialog(){
        this.createQuestionRef.current.style.display = "none";
    }

    closeCreateQuestionDialog2(){
        this.createQuestionRef.current.style.display = "none";
    }

    async nextStep(){
        let newQType = this.createQuestionVTypeRef.current.value;
        if(newQType == "text"){
            this.createQuestionStep1Ref.current.style.display = "none";
            this.createQuestionStep2Ref.current.style.display = "block";
            this.createQuestionStep2TextRef.current.value = "";
            this.doneBtnRef.current.style.display = "inline-block";
            this.saveAndDoneBtnRef.current.style.display = "inline-block";
            this.saveAndNextBtnRef.current.style.display = "inline-block";
            this.setState({...this.state, step: 1, newQType: newQType});
        } else if(newQType == "video"){
            this.createQuestionStep1Ref.current.style.display = "none";
            this.setState({
                ...this.state,
                showRecorder: true,
            })
            this.createQuestionStep2VideoRef.current.style.display = "block";
            this.doneBtnRef.current.style.display = "inline-block";
            this.saveAndDoneBtnRef.current.style.display = "inline-block";
            this.saveAndNextBtnRef.current.style.display = "inline-block";
            this.createQuestionStep2VideoElementRef.current.resetUI();
            this.createQuestionStep2VideoElementRef.current.openVideo();
            let api_key = localStorage.getItem('api_key');
            this.createQuestionStep2VideoElementRef.current.setPreviewData(0, 0, 0, api_key, this.state.interview.id);
            this.setState({...this.state, step: 1, newQType: newQType});
        } else if(newQType == "multi"){
            this.createQuestionStep1Ref.current.style.display = "none";
            this.createQuestionStep3Ref.current.style.display = "block";
            this.setState({
                ...this.state,
                showMultiChoice: true
            });
        }
    }
    
    nextStep2(){
        this.createQuestionStep2Ref.current.style.display = "none";
        this.createQuestionStep3Ref.current.style.display = "block";
        this.setState({...this.state, step: 2});
    }

    async saveAndDone(){
        this.doneBtnRef.current.style.display = "none";
        this.saveAndDoneBtnRef.current.style.display = "none";
        this.saveAndNextBtnRef.current.style.display = "none";
        let text = this.createQuestionStep2TextRef.current.value;
        if(text == "" || text == null){
            toast("There must be text to save a question");
            return;
        }
        let duration = parseInt(this.createQuestionRecommendTimeRef.current.value);
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.create(api_key, duration, "text", text, "", this.state.interview.id);
        let success = result['success'];
        if(success){
            this.createQuestionVTypeRef.current.value = "text";
            this.createQuestionStep1Ref.current.style.display = "none";
            this.createQuestionStep2Ref.current.style.display = "none";
            this.createQuestionStep3Ref.current.style.display = "none";
            this.createQuestionRef.current.style.display = "none";
            
            this.setState({...this.state, step: 1, newQType: "text"});
            this.reloadQuestions();
        } else {
            toast("Maxed Storage Reached");
            setTimeout(()=>{
                window.location.href = "/dashboard";
            }, 5000);
            return;
        }
    }

    async saveAndNext(){
        this.doneBtnRef.current.style.display = "none";
        this.saveAndDoneBtnRef.current.style.display = "none";
        this.saveAndNextBtnRef.current.style.display = "none";
        let text = this.createQuestionStep2TextRef.current.value;
        if(text == "" || text == null){
            toast("There must be text to save a question");
            return;
        }
        let duration = parseInt(this.createQuestionRecommendTimeRef.current.value);
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.create(api_key, duration, "text", text, "", this.state.interview.id);
        let success = result['success'];
        if(success){
            this.doneBtnRef.current.style.display = "inline-block";
            this.saveAndDoneBtnRef.current.style.display = "inline-block";
            this.saveAndNextBtnRef.current.style.display = "inline-block";
            this.createQuestionVTypeRef.current.value = "text";
            this.createQuestionStep1Ref.current.style.display = "none";
            this.createQuestionStep2Ref.current.style.display = "block";
            this.setState({...this.state, step: 1, newQType: "text"});
            this.createQuestionStep2TextRef.current.value = "";
            this.reloadQuestions(); 
        } else {
            this.doneBtnRef.current.style.display = "inline-block";
            this.saveAndDoneBtnRef.current.style.display = "inline-block";
            this.saveAndNextBtnRef.current.style.display = "inline-block";
            alert("Error creating question");
        }
    }

    startRecording2(){
        this.wss = "";
    }

    restartRecording2(){

    }

    
    saveAndDone2(){

    }

    saveAndNext2(){

    }
    
    async saveAndDone3(){
        this.saveAndDone3BtnRef.current.style.display = "none";
        this.saveAndNext3BtnRef.current.style.display = "none";
        let url = this.url;
        let time = parseInt(this.createQuestionRecommendTimeRef2.current.value);
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.create(api_key, time, 'video', '', url, this.state.interview.id);
        let success = result['success'];
        if(success){
            toast('Question Created');
            this.createQuestionRef.current.style.display = "none";
            this.reloadQuestions();
            this.complisionPopupDialog.current.style.display = "flex";
        } else {
            toast("error creating question");
        }
    }

    async saveAndNext3(){
        this.saveAndDone3BtnRef.current.style.display = "none";
        this.saveAndNext3BtnRef.current.style.display = "none";
        let url = this.url;
        let time = parseInt(this.createQuestionRecommendTimeRef2.current.value);
        let api_key = localStorage.getItem('api_key');
        let result = await API.questions.create(api_key, time, 'video', '', url, this.state.interview.id);
        let success = result['success'];
        if(success){
            toast('Question Created');
            this.createQuestionRef.current.style.display = "flex";
            this.createQuestionRef.current.scrollIntoView();
            this.createQuestionStep1Ref.current.style.display = "block"; // create question, select type
            this.createQuestionStep2Ref.current.style.display = "none";  // if text, display input, else display recording component
            this.createQuestionStep2VideoRef.current.style.display = "none";
            this.createQuestionStep2VideoDurationRef.current.style.display = "none";
            this.createQuestionStep3Ref.current.style.display = "none";  // if text, save and allow option to make anotehr question, else if video, this will only load once the recording has been approved or rejected after the intial review, this also allows to create another question
            this.setState({...this.state, step: 0});   
            this.reloadQuestions();         
        } else {
            toast("error creating question");
        }
    }

    closeCreateQuestionDialog(){
        this.createQuestionStep2VideoRef.current.style.display = "none";
        this.reloadQuestions();
    }


    completionCallback(url){
        this.url = url;
        this.createQuestionStep2VideoRef.current.style.display = "none";
        this.createQuestionStep2VideoDurationRef.current.style.display = "block";
        this.saveAndDone3BtnRef.current.style.display = "inline-block";
    }

    setInterview(interview){
        this.setState({
            ...this.state,
            interview: interview
        });
    }

    async handleOnDragEnd(result){
        if(this.question_pulling){
            clearInterval(this.question_pulling);
        }
        let items = this.state.questions;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        let ids = this.state.questions.map((question)=>{return question.id});
        let api_key = localStorage.getItem('api_key');
        let result2 = await API.interview.setQuestionOrder(api_key, this.state.interview.id, ids);
        this.reloadQuestions();
    }

    render() {
        // HTML
        return ( 
            <div className="questionContianer">
                <div className="questionContianerDropDown">
                    <span style={{display: 'inline-block', width: '93px'}}>Questions</span><button ref={this.hideShowBtnRef} onClick={this.toggle} id="questionsButtonX">Show</button> { this.state.interview.locked ? <span className="questionsLocked" title="Questions are locked due to one or more candidates been invited to the interview">Locked</span> : ""}
                </div>
                <div className="questionContianerTableContainer" ref={this.dropdownRef}>
                    <h4>Interview Question(s)</h4>
                    <table>
                        <thead>
                            <tr>
                                <th style={{textAlign:"left", width:"400px"}}>Title&nbsp;&nbsp;<span style={{paddingLeft: '106px'}}>ID</span></th>
                                <th style={{textAlign:"left", width:"200px"}}>Question Type</th>
                                <th style={{textAlign:"left", width:"400px"}}>Recommend Answer Duration</th>
                                <th style={{textAlign:"left", width:"250px"}}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{textAlign:"left", width:"400px"}}>
                                    {
                                        this.state.interview.locked ? <button className="questionsLocked"><span style={{display:'inline-block',fontSize: '16pt', transform: 'translateY(2px)'}}>+</span> Add Question</button> : <button className="createQuestion" onClick={this.openNewQuestion}><span style={{display:'inline-block',fontSize: '16pt', transform: 'translateY(2px)'}}>+</span> Add Question</button>
                                    }
                                </td>
                                <td style={{textAlign:"left", width:"200px"}}>-</td>
                                <td style={{textAlign:"left", width:"40px"}}>-</td>
                                <td style={{textAlign:"left", width:"250px"}}>-</td>
                            </tr>
                            <DragDropContext onDragEnd={this.handleOnDragEnd}>
                                <Droppable droppableId="questions">
                                    { (provided) => (
                                        <div className="questions" {...provided.droppableProps} ref={provided.innerRef}>
                                            {
                                                this.state.questions && this.state.questions.filter(a=>a).map((question, index) => {
                                                    return (
                                                        <Draggable key={question.id} draggableId={question.id} index={index}>
                                                            {(provided) => (
                                                            <tr key={question.id}  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                <td style={{texAlign:"left", width:"400px"}}>  
                                                                    {
                                                                        (question.type === "text") && question.text
                                                                    } 
                                                                    { (question.type === "video") &&
                                                                        <>
                                                                            <button video-url={question.video_url} className="previewQuestionVideo" onClick={this.openPreviewVideoPopUp} style={{padding:'8px'}}>Preview Video</button>
                                                                            <span style={{display:'inline-block', paddingLeft:'8px', width:'48px'}}>{question.id}</span>
                                                                        </>
                                                                    }
                                                                    { (question.type === "multi") &&
                                                                        <>
                                                                            <span style={{display:'inline-block', paddingLeft:'8px', width:'100%', maxWidth:'250px'}}>{JSON.parse(question.text)['question']}</span>
                                                                            <button className="previewQuestionVideo" onClick={()=>{
                                                                                let data = JSON.parse(question.text);
                                                                                this.multiAnswerDialogRef.current.style.display = "flex";
                                                                                this.multiAnswerDialogQuestionRef.current.innerHTML = data.question;
                                                                                this.multiAnswerDialogAnswerRef.current.innerHTML = "";
                                                                                for(let i=0;i<data.items.length;i++){
                                                                                    this.multiAnswerDialogAnswerRef.current.innerHTML += "<li>" + data.items[i] + "</li>";
                                                                                }
                                                                            }} style={{padding:'8px'}}>Preview Answers</button>
                                                                        </>
                                                                    }
                                                                    {
                                                                        ( question.type === "video" && !this.state.interview.locked && !this.state.allowInterview && this.candidatesNotCopied()) ? <button video-url={question.video_url} question-id={question.id} className="previewQuestionVideo2" onClick={this.openReRecordVideoPopUp}>Re-record Video</button>: <></>                                                                    }
                                                                </td>   
                                                                <td style={{textAlign:"left", width:"200px"}}>
                                                                    { question.type }
                                                                </td>
                                                                <td style={{textAlign:"left", width:"400px"}}>
                                                                    { question.recommend_answer_duration } Second(s)</td>
                                                                <td style={{textAlign:"left", width:"250px"}}>
                                                                    {
                                                                        question.type !== "video" && question.type !== "multi" && !this.state.interview.locked ? <button className="questionBtnEdit" question-id={question.id} onClick={this.editQuestion}>Edit</button> : <></>
                                                                    }
                                                                    { !this.state.interview.locked ? 
                                                                        <button className="questionBtnDelete" question-id={question.id} onClick={this.deleteQuestion}>Delete</button> :
                                                                        <button className="questionsLocked" question-id={question.id}>Locked</button>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>)
                                    }
                                </Droppable>
                            </DragDropContext>
                        </tbody>
                    </table>
                </div>
                <div className="createQuestiondialogNew" ref={this.createQuestionRef}>
                    <div ref={this.createQuestionStep1Ref} className="createQuestiondialogNewStep1">
                        <h4>Add Question</h4>
                        <span>Select Question Type</span>
                        <select ref={this.createQuestionVTypeRef} defaultValue="text">
                            <option value="text"  ref={this.createQuestionOptionText}>Text</option>
                            { this.state.user.package_number >= 1 ? <option value="video" ref={this.createQuestionOptionVideo}>Video</option> : <option ref={this.createQuestionOptionVideo} value="video" disabled={true}>Video (Upgrade Required)</option>}
                            { /* TODO: RE-ENABLE */}
                            {/* this.state.user.package_number >= 1 ? <option value="multi" ref={this.createQuestionOptionVideo}>Multi-Choice</option> : <option ref={this.createQuestionOptionVideo} value="multi" disabled={true}>Multi-Choice (Upgrade Required)</option> */}
                        </select>
                        <div>
                            <button onClick={()=>{
                                this.createQuestionRef.current.style.display = "none";
                                let qtype = this.createQuestionVTypeRef.current.value != "text";
                                if(qtype){
                                    this.complisionPopupDialog.current.style.display = "flex"
                                    this.reloadQuestions();
                                }
                                }}>Done</button>
                            <button onClick={this.nextStep}>Next</button>
                        </div>
                    </div>
                    <div ref={this.createQuestionStep2Ref} className="createQuestiondialogNewStep2">
                        <h4>Add Text Question</h4>
                        <span>Enter the question's text and select a recommend answer duration</span>
                        <input type="text" ref={this.createQuestionStep2TextRef} placeholder="Enter Text"/>
                        <select ref={this.createQuestionRecommendTimeRef} defaultValue="1">
                            <option value="30">30 Seconds</option>
                            <option value="60">1 Minute</option>
                            <option value="90">1.5 Minutes</option>
                        </select>
                        <div>
                            <button ref={this.doneBtnRef} onClick={()=>{
                                this.createQuestionRef.current.style.display = "none";
                                let qtype = this.createQuestionVTypeRef.current.value != "text";
                                if(qtype){
                                    this.complisionPopupDialog.current.style.display = "flex"
                                    this.reloadQuestions();
                                }
                                }}>Done</button>
                            <button onClick={this.saveAndDone} ref={this.saveAndDoneBtnRef}>Save and Done</button>
                            <button onClick={this.saveAndNext} ref={this.saveAndNextBtnRef}>Save and Next</button>
                        </div>
                    </div>                    
                    <div ref={this.createQuestionStep3Ref}>
                        <h4 style={{ textAlign:'center' }}>Add Question</h4>
                        { this.state.showMultiChoice && <MultiOption onChange={(data)=>{
                            this.setState({
                                ...this.state,
                                multiChoiceData: data
                            });
                        }}/> }
                        <div className="buttonFixBlue">
                            <button onClick={()=>{                                
                                this.setState({
                                    ...this.state,
                                    multiChoiceData: {question:'', items: []}
                                });
                                this.setState({
                                    ...this.state,
                                    showMultiChoice: false
                                });
                                // just close this
                                this.createQuestionRef.current.style.display = "none";
                                this.createQuestionStep3Ref.current.style.display = "none";
                                this.reloadQuestions();
                            }}>Done</button>
                            <button onClick={async ()=>{
                                // load saved data and filter
                                let multiChoiceData = this.state.multiChoiceData;
                                multiChoiceData.items = multiChoiceData.items.filter((value)=>{
                                    return value || value === 0;
                                });
                                if(multiChoiceData.length <= 0){
                                    toast("Please enter a question.");
                                    return;
                                }
                                if(multiChoiceData.items.length <= 0){
                                    toast("Please have at least 1 answer.");
                                    return;
                                }
                                // create question
                                let api_key = localStorage.getItem("api_key") ?? "";
                                let result = await API.questions.create(api_key, 0, "multi", JSON.stringify(multiChoiceData), "", this.state.interview.id);
                                // close this, clear data, and hide component
                                this.setState({
                                    ...this.state,
                                    multiChoiceData: {question:'', items: []},
                                    showMultiChoice: false
                                });
                                this.reloadQuestions();
                                this.createQuestionRef.current.style.display = "none";
                                this.createQuestionStep3Ref.current.style.display = "none";
                            }}>Save and Done</button>
                            <button onClick={async ()=>{
                                // load saved data and filter
                                let multiChoiceData = this.state.multiChoiceData;
                                if(multiChoiceData.length <= 0){
                                    toast("Please enter a question.");
                                    return;
                                }
                                if(multiChoiceData.items.length <= 0){
                                    toast("Please have at least 1 answer.");
                                    return;
                                }
                                // create question
                                let api_key = localStorage.getItem("api_key") ?? "";
                                let result = await API.questions.create(api_key, 0, "multi", JSON.stringify(multiChoiceData), "", this.state.interview.id);
                                // clear saved data and hide                               
                                this.setState({
                                    ...this.state,
                                    multiChoiceData: {question:'', items: []},
                                    showMultiChoice: false
                                });
                                // show component
                                setTimeout(()=>{
                                    this.setState({
                                        ...this.state,
                                        showMultiChoice: true
                                    });
                                }, 500);
                                // show data
                            }}>Save and Next</button>
                        </div>                       
                    </div>
                    <div ref={this.createQuestionStep2VideoRef} className="createQuestiondialogNewStep2">
                        <h4>Add Video Question</h4>
                        <span style={{fontSize: '10pt'}}>Select a recommend answer duration</span>
                        <select ref={this.createQuestionRecommendTimeRef3} defaultValue="1">
                            <option value="30">30 Seconds</option>
                            <option value="60">1 Minute</option>
                            <option value="90">1.5 Minutes</option>
                        </select>
                        <VideoRecordComponent nopreview2={true} ref={this.createQuestionStep2VideoElementRef} fixWidth={false} watermark_url={this.state.user.watermark_url} onclose={()=>{this.createQuestionRef.current.style.display = 'none'}} getDuration={()=>{ return this.createQuestionRecommendTimeRef3.current.value; }} onnopreview={()=>{                            
                            this.createQuestionStep2VideoRef.current.style.display = "none";
                            this.createQuestionStep1Ref.current.style.display = "block";
                        }}></VideoRecordComponent>                        
                    </div>
                    <div ref={this.createQuestionStep2VideoDurationRef} className="createQuestiondialogNewStep2">
                        <h4>Add Video Question</h4>
                        <span style={{fontSize: '10pt'}}>Select a recommend answer duration</span>
                        <select ref={this.createQuestionRecommendTimeRef2} defaultValue="1">
                            <option value="30">30 Seconds</option>
                            <option value="60">1 Minute</option>
                            <option value="90">1.5 Minutes</option>
                        </select>
                        <div>
                            <button onClick={this.saveAndDone3} ref={this.saveAndDone3BtnRef}>Save and Done</button>
                            <button onClick={this.saveAndNext3} ref={this.saveAndNext3BtnRef}>Save and Next</button>
                        </div>
                    </div>
                </div>                
                <div className="createQuestiondialogNew" ref={this.videoPreivewRef}>                
                    <div className="createQuestiondialogNewStep2">
                        <h4>Video Preview</h4>
                        <video src="" ref={this.videoPreivewVideoRef} style={{width:'480px'}} playsInline={true} controls={true}></video>
                        <div>
                            <button onClick={this.closeVideoPreview}>Close</button>
                        </div>
                    </div>
                </div>           
                <div className="createQuestiondialogNew" ref={this.editQuestionRef}>                
                    <div className="createQuestiondialogNewStep2">
                        <h4>Edit Question</h4>
                        <textarea style={{width:'100%', height:'72px'}} ref={this.editQuestiontextAreaRef}></textarea>
                        <div>
                            <button onClick={()=>{
                                this.editQuestiontextAreaRef.current.value = "";
                                this.editQuestionRef.current.style.display = "none";
                            }}>Close</button>
                            <button onClick={ async ()=>{
                                let text = this.editQuestiontextAreaRef.current.value;
                                let q_id = this.editQuestionRef.current.getAttribute('q-id');
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.questions.updateByID(api_key, q_id, text);
                                this.reloadQuestions();
                                this.editQuestiontextAreaRef.current.value = "";
                                this.editQuestionRef.current.style.display = "none";
                            }}>Update</button>
                        </div>
                    </div>
                </div>
                <div className="createQuestiondialogNew" ref={this.complisionPopupDialog}>                
                    <div className="createQuestiondialogNewStep2">
                        <h4>Notice</h4>
                        <p>Well done, you have completed your questions. We recommend that you review your questions by clicking on preview. If there are any issues with your recording, then please feel free to rerecord.</p>
                        <div>
                            <button onClick={()=>{
                                this.complisionPopupDialog.current.style.display = "none";
                                this.reloadQuestions();
                            }}>Close</button>
                        </div>
                    </div>
                </div>
                
                <div className="createQuestiondialogNew" ref={this.reRecordQuestionDialogRef}>                
                    <div className="createQuestiondialogNewStep2">
                        <h4>Re-Record Video Question</h4>
                        <VideoRecordComponent ref={this.reRecordQuestionDialogVideoRef} fixWidth={false} watermark_url={this.state.user.watermark_url} onclose={()=>{this.reRecordQuestionDialogRef.current.style.display = 'none';}} getDuration={()=>{return 0;}} oncompletion={
                            async (video_url)=>{
                                let qid = parseInt(this.reRecordQuestionDialogRef.current.getAttribute('question-id'));
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.questions.updateVideoByID(api_key, qid, video_url);
                                this.reRecordQuestionDialogRef.current.style.display = 'none';
                                this.reloadQuestions();
                            }
                        }></VideoRecordComponent>                        
                    </div>                    
                </div>

                
                <div className="createQuestiondialogNew" ref={this.multiAnswerDialogRef}>                
                    <div className="createQuestiondialogNewStep2 multiChoiceQuestionContainer">
                        <h4>Multi Choice Question<button onClick={()=>{
                            this.multiAnswerDialogRef.current.style.display = "none";
                        }}>X</button></h4>
                        <span ref={this.multiAnswerDialogQuestionRef}></span>
                        <ul ref={this.multiAnswerDialogAnswerRef}></ul>                       
                    </div>                    
                </div>
            </div>
        );
      }
}


export default withRouter(QuestionComponent); 