import React, { useState, useEffect, useRef } from 'react';
import SideBarComponent from '../components/sidebar';
import UpperBarComponent from '../components/upperbar';
import useAbortableAxios from '../hooks/useAbortableAxios';
import API from '../services/api';
import { IoIosCloudUpload } from "react-icons/io";

import { FcOrganization } from 'react-icons/fc';

import { toast } from 'react-toastify';

import './org-new.css';
import axios from 'axios';
import FileUploadComponent from '../components/fileupload';

export default function NewOrgPage(){
    const { loading, data, error, fetchData, setLoading} = useAbortableAxios();
    const { loading:loading2, data:data2, error:error2, fetchData:fetchData2, setLoading:setLoading2} = useAbortableAxios();
    const [ totalUsers, setTotalUsers ] = useState<any>("Loading");
    const [ orgData, setOrgData ] = useState<any>(false);
    const [ searchString, setSearchString ] = useState("");
    const [ orgViewInformation, setViewOrgInformation ] = useState(false);
    const [ orgInformation, setOrgInformation ] = useState<any>(false);
    // Dialog - create org - with user
    const createOrgDialogRef = useRef<any>();
    const [ createOrgTitle, setCreateOrgTitle ] = useState("");
    const [ createOrgMustPay, setCreateOrgMustPay ] = useState(0);
    const createOrgUserDialogRef = useRef<any>();
    const [ createOrgUserFullName, setCreateOrgUserFullName ] = useState("");
    const [ createOrgUserEmailAddress, setCreateOrgUserEmailAddress ] = useState("");
    const [ createOrgUserPassword, setCreateOrgUserPassword ] = useState("");
    const [ createOrgUserTrialAccount, setCreateOrgUserTrialAccount ] = useState(0);
    const [ createOrgUserTrialAccountDate, setCreateOrgUserTrialAccountDate ] = useState("");
    const [ createOrgUserPromocode, setCreateOrgUserPromocode ] = useState("");
    const [ createOrgUserPackageType, setCreateOrgUserPackageType ] = useState(0);
    const [ createOrgUserPackageDuration, setCreateOrgUserPackageDuration ] = useState(0);
    const [ createOrgUserAdminAccount, setCreateOrgUserAdminAccount ] = useState(0);
    const [ createOrgUserTesterAccount, setCreateOrgUserTesterAccount ] = useState(0);
    const [ createOrgUserSellerType, setCreateOrgUserSellerType ] = useState(0);
    const [ createOrgUserHasMarketing, setCreateOrgUserHasMarketing ] = useState(0);
    const [ createOrgUserHasMarketingNoPay, setCreateOrgUserHasMarketingNoPay ] = useState(0);
    const [ createOrgUserCanExtract, setCreateOrgUserCanExtract ] = useState(0);
    // Dialog - delete org confimation
    const deleteOrgDialogRef = useRef<any>();
    // Dialog - update user
    const updateUserDialogRef = useRef<any>();
    const [ userInformation, setUserInformation ] = useState<any>(false);
    const [ updateOrgUserFullName, setUpdateOrgUserFullName ] = useState("");
    const [ updateOrgUserPhoneNumber, setUpdateOrgUserPhoneNumber ] = useState("");
    const [ updateOrgUserTrialAccount, setUpdateOrgUserTrialAccount ] = useState(0);
    const [ updateOrgUserTrialAccountDate, setUpdateOrgUserTrialAccountDate ] = useState("");
    const [ updateOrgUserPackageType, setUpdateOrgUserPackageType ] = useState(0);
    const [ updateOrgUserPackageDuration, setUpdateOrgUserPackageDuration ] = useState(0);
    const [ updateOrgUserAdminAccount, setUpdateOrgUserAdminAccount ] = useState(0);
    const [ updateOrgUserTesterAccount, setUpdateOrgUserTesterAccount ] = useState(0);
    const [ updateOrgUserIsSeller, setUpdateOrgUserIsSeller ] = useState(0);
    const [ updateOrgUserSellerType, setUpdateOrgUserSellerType ] = useState(0);
    const [ updateOrgUserHasMarketing, setUpdateOrgUserHasMarketing ] = useState(0);
    const [ updateOrgUserHasMarketingNoPay, setUpdateOrgUserHasMarketingNoPay ] = useState(0);
    const [ updateOrgUserCanExtract, setUpdateOrgUserCanExtract ] = useState(0);
    /// Dialog - create user for org
    const createUserForOrgDialogRef = useRef<any>();
    const [ createNewOrgUserFullName, setCreateNewOrgUserFullName ] = useState("");
    const [ createNewOrgUserEmail, setCreateNewOrgUserEmail ] = useState("");
    const [ createNewOrgUserPassword, setCreateNewOrgUserPassword ] = useState("");
    const [ createNewOrgUserPhoneNumber, setCreateNewOrgUserPhoneNumber ] = useState("");
    const [ createNewOrgUserPromoCode, setCreateNewOrgUserPromoCode ] = useState("");
    const [ createNewOrgUserTrialAccount, setCreateNewOrgUserTrialAccount ] = useState(0);
    const [ createNewOrgUserTrialAccountDate, setCreateNewOrgUserTrialAccountDate ] = useState("");
    const [ createNewOrgUserPackageType, setCreateNewOrgUserPackageType ] = useState(0);
    const [ createNewOrgUserPackageDuration, setCreateNewOrgUserPackageDuration ] = useState(0);
    const [ createNewOrgUserAdminAccount, setCreateNewOrgUserAdminAccount ] = useState(0);
    const [ createNewOrgUserTesterAccount, setCreateNewOrgUserTesterAccount ] = useState(0);
    const [ createNewOrgUserIsSeller, setCreateNewOrgUserIsSeller ] = useState(0);
    const [ createNewOrgUserSellerType, setCreateNewOrgUserSellerType ] = useState(0);
    const [ createNewOrgUserHasMarketing, setCreateNewOrgUserHasMarketing ] = useState(0);
    const [ createNewOrgUserHasMarketingNoPay, setCreateNewOrgUserHasMarketingNoPay ] = useState(0);
    const [ createNewOrgUserCanExtract, setCreateNewOrgUserCanExtract ] = useState(0);
    // Custom Org Intro Video
    const [ showCustomIntroVideoURL,  setShowCustomIntroVideoURL] = useState<string | null>(null);
    const handleCustomOrgCIIntroVideoDialogRef = useRef<any>(null);
    const handleCustomOrgCIIntroVideoDialogRefComponent = useRef<any>(null);
    // Dialog - Delete user from org
    const deleteOrgUserDialogRef = useRef<any>(null);
    // Create User Dialog    
    const createuserForOrgRefName = useRef<any>();
    const createuserForOrgRefEmail = useRef<any>();
    const createuserForOrgRefPhoneNumber = useRef<any>();
    const createuserForOrgRefPackageType = useRef<any>();
    const createuserForOrgRefPackageDuration = useRef<any>();
    const createuserForOrgRefAdminAccount = useRef<any>();
    const createuserForOrgRefTestAccount = useRef<any>();
    const createuserForOrgRefTrialAccount = useRef<any>();
    const createuserForOrgRefTrialAccountExpiration = useRef<any>();
    const createuserForOrgRefIsSeller = useRef<any>();
    const createuserForOrgRefSellerType = useRef<any>();
    const createuserForOrgRefHasMarketing = useRef<any>();
    const createuserForOrgRefHasMarketingNoPay = useRef<any>();
    const createuserForOrgRefCanExtract = useRef<any>();
    // Edit User Dialog
    const editUserForOrgRefName = useRef<any>();
    const editUserForOrgRefEmail = useRef<any>();
    const editUserForOrgRefPhoneNumber = useRef<any>();
    const editUserForOrgRefPromoCode = useRef<any>();
    const editUserForOrgRefPackageType = useRef<any>();
    const editUserForOrgRefPackageDuration = useRef<any>();
    const editUserForOrgRefAdministratorAccount = useRef<any>();
    const editUserForOrgRefTestorAccount = useRef<any>();
    const editUserForOrgRefTrialAccount = useRef<any>();
    const editUserForOrgRefTrialExpirationDate = useRef<any>();
    const editUserForOrgRefSellerType = useRef<any>();
    const editUserForOrgRefHasMarketing = useRef<any>();
    const editUserForOrgRefHasMarketingNoPay = useRef<any>();
    const editUserForOrgRefCanExtract = useRef<any>();
    
    const loadOrgInfo = async () => {
        setViewOrgInformation(false);
        await fetchData( API.baseURL + "/api/v1/org/list/all", {}, {
            'api_key': localStorage.getItem("api_key") ?? "",
            'q': searchString
        }, "POST");
    }

    useEffect(()=>{
        (document as any).querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
    }, []);

    useEffect(()=>{
        if(!data) return;
        setOrgData((data as any).data.orgs);
    }, [data]);


    useEffect(()=>{
        loadOrgInfo();
    }, [searchString])

    useEffect(()=>{
        async function getUserTotal(){
            let url = API.baseURL + "/api/v1/org/list/all/users/total";
            let response = await fetch(url, {
                body: JSON.stringify({
                    "api_key": localStorage.getItem("api_key") ?? ""
                }),
                method: "POST",                
                headers: {
                    "content-type": "application/json"
                }
            })
            let info = await response.json();
            setTotalUsers((info as any).data.total);
        }
        getUserTotal();
    }, []);

    
    useEffect(()=>{
        if(!data2) return;
        setOrgInformation((data2 as any).data.info);
        setViewOrgInformation(true);
    }, [data2]);

    const loadOrg = (org_id) =>{
        async function loadOrgInfo2(){
            setViewOrgInformation(false);
            await fetchData2(API.baseURL + "/api/v1/org/get/info", {}, {
                "api_key": localStorage.getItem("api_key") ?? "",
                "org_id": org_id
            }, "POST")
        }
        loadOrgInfo2();
    }
    

    const makeOrgTitleAndChildren = (org:any) => {  
        if(!org) return <></>;
        if(org.orgs.length === 0){
            return <ul>
                <li onClick={ e => {
                    loadOrg(org.id);
                }} className='org-new-org-title'>{ org.title } ({ org.id })</li>
            </ul>
        } else {
            return <ul>
                <li onClick={ e => {
                    loadOrg(org.id);
                }} className='org-new-org-title'>{ org.title } ({ org.id })</li>
                { org.orgs.map((childOrg => {
                    return <li>{ makeOrgTitleAndChildren(childOrg) }</li>
                })) }
            </ul>
        }
    }

    useEffect(() => {
        if (orgViewInformation && orgInformation) {
          const element = document.querySelector('.org-new-view-org');
          element?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [orgViewInformation, orgInformation]);

    const genRStr = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      }


    return (
        <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Organisation</span>
                    </div>
                    <div className='org-new-container'>
                        <div className='org-new-container-list'>
                            <div className='org-new-container-list-search'>
                                <input type="text" placeholder='Search here' onChange={ e => setSearchString(e.target.value)}/>
                                <span className="dropdown">
                                    <button>Actions</button>
                                    <label>
                                        <input type="checkbox" />
                                        <ul>
                                            <li
                                            style={{ fontWeight: 400, color: "#000" }}
                                            onClick={async () => {
                                                createOrgDialogRef.current.showModal();
                                            }}
                                            >
                                                + Add Organisation
                                            </li>
                                            
                                            <li
                                            style={{ fontWeight: 400, color: "#000" }}
                                                onClick={async () => {
                                            }}
                                            >
                                                &gt; Export Data (Coming Soon)
                                            </li>
                                        </ul>
                                    </label>
                                </span>
                            </div>
                            <div className='org-new-container-org-list'>
                                <div className='org-new-container-org-list-user-count'>Total Users: { totalUsers }</div>
                                { loading && <div style={{ padding:'12px' }}>Loading</div>}
                                { (!loading && orgData) && <div className='org-new-container-org-list-uls'>
                                    <ul>
                                        <li>Clipdrop</li>
                                        <li>
                                            { orgData && orgData.map(otherOrg => makeOrgTitleAndChildren(otherOrg)) }
                                        </li>
                                    </ul>
                                </div> }
                            </div>
                        </div>
                    </div>
                    { orgViewInformation && <div className='org-new-view-org'>
                        <div className='org-new-view-org-data'>
                            <div>
                                <span><FcOrganization/>&nbsp;&nbsp;{ orgInformation.title }({ orgInformation.id })&nbsp;</span>
                                <button onClick={ async e => {
                                    toast("Saving, please wait");
                                    let title = (document.getElementById("org-view-title") as any).value;
                                    let must_pay = parseInt((document.getElementById("org-view-must_pay") as any).value);
                                    let org_id = orgInformation.id;
                                    let response = await fetch(API.baseURL + "/api/v1/org/update/info", {
                                        method: "POST",
                                        headers: {
                                            "content-type": "application/json"
                                        },
                                        body: JSON.stringify({
                                            api_key: localStorage.getItem("api_key") ?? "",
                                            org_id: org_id,
                                            title: title,
                                            must_pay: must_pay
                                        })
                                    })
                                    let data = await response.json();
                                    toast("Changes Saved!");
                                }}>Save Changes</button>
                                <button className='red' onClick={ e => {
                                    deleteOrgDialogRef.current.showModal();
                                }}>Delete Org</button>
                                <button onClick={ e => {
                                    setViewOrgInformation(false);
                                    setOrgInformation(false);
                                }}>Close</button>
                            </div>
                            <div>
                                <div>
                                    <span>Organisation Title</span>
                                    <input type="text" placeholder='Organisation Title' defaultValue={orgInformation.title} id="org-view-title"/><br/>
                                    <span>Custom Candidate CI Intro Video</span>
                                    <video className='special-video-ci' src={orgInformation.custom_candidate_intro_video_url ?? ""} playsInline autoPlay={false}></video>
                                    <button className='special-video-ci-button' onClick={ e => {
                                        setShowCustomIntroVideoURL(orgInformation.custom_candidate_intro_video_url ?? "");
                                        handleCustomOrgCIIntroVideoDialogRef.current.showModal();
                                    }}><IoIosCloudUpload/></button>
                                </div>
                                <div>
                                    <span>Must Pay</span>
                                    <select value={orgInformation.must_pay} id="org-view-must_pay">
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='org-new-view-org-users'>
                            <div>
                                <span className="dropdown">
                                    <button>Actions</button>
                                    <label>
                                        <input type="checkbox" />
                                        <ul>
                                            <li
                                            style={{ fontWeight: 400, color: "#000" }}
                                                onClick={async () => {
                                                    setCreateNewOrgUserPassword(genRStr(8));
                                                    setCreateNewOrgUserPromoCode(genRStr(8));
                                                    createUserForOrgDialogRef.current.showModal();
                                            }}
                                            >
                                                + Add User
                                            </li>
                                            <li
                                            style={{ fontWeight: 400, color: "#000" }}
                                                onClick={async () => {
                                            }}
                                            >
                                                - Delete Users (Coming Soon)
                                            </li>
                                            <li
                                            style={{ fontWeight: 400, color: "#000" }}
                                                onClick={async () => {
                                            }}
                                            >
                                                &gt; Export Data (Coming Soon)
                                            </li>
                                        </ul>
                                    </label>  
                                </span> 
                                { /* <input type="text" placeholder='Search Here (Coming Soon)'/>  */}                    
                            </div>
                            <div>
                                <div><input type="checkbox" onChange={ e => {
                                    let val = e.target.checked;
                                    const checkboxes = document.querySelectorAll("input.org-view-user-list-checkbox");
                                    for (let i = 0; i < checkboxes.length; i++) {
                                    const checkbox = checkboxes[i] as HTMLInputElement as any;
                                        checkbox.checked = val;
                                    }
                                }}/></div>
                                <div>Full Name</div>
                                <div>Email Address</div>
                                <div>Privillege Flags</div>
                                <div>Actions</div>
                            </div>
                            <div>
                                {
                                    orgInformation.users.map( user => {
                                        return <div>
                                            <div>{ ( user.id !== orgInformation.user_id) && <input className='org-view-user-list-checkbox' type="checkbox" data-user-id={user.id}/> }</div>
                                            <div>{ user.name }</div>
                                            <div>{ user.email }</div>
                                            <div>
                                                <span><input type="checkbox" checked={user.isAdmin}/>&nbsp;Admin</span>
                                                <span><input type="checkbox" checked={user.isTester}/>&nbsp;Tester</span>
                                                { ( user.id === orgInformation.user_id) && <div className='user-owner'>Owner</div>}
                                            </div>
                                            <div>
                                                <button onClick={ async e => {
                                                    toast("Loading");
                                                    let response = await fetch(API.baseURL + "/api/v1/user/getByID", {
                                                        method: "POST",
                                                        headers: {
                                                            'content-type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            api_key: localStorage.getItem("api_key") ?? "",
                                                            user_id: user.id,
                                                        })
                                                    });
                                                    let data = await response.json();                                     
                                                    setUpdateOrgUserFullName(data.user.name);
                                                    setUpdateOrgUserPhoneNumber(data.user.phone_number);
                                                    setUpdateOrgUserTrialAccount(data.user.trial_expiry_flag);
                                                    setUpdateOrgUserTrialAccountDate(data.user.trial_expiry);
                                                    setUpdateOrgUserPackageType(data.user.package_number);
                                                    setUpdateOrgUserPackageDuration(data.user.package_duration);
                                                    setUpdateOrgUserAdminAccount(data.user.isAdmin);
                                                    setUpdateOrgUserTesterAccount(data.user.isTester);
                                                    setUpdateOrgUserSellerType(data.user.seller_type);
                                                    setUpdateOrgUserHasMarketing(data.user.hasMarketing);
                                                    setUpdateOrgUserHasMarketingNoPay(data.user.hasMarketingNoPay);
                                                    setUpdateOrgUserCanExtract(data.user.canExtract);
                                                    setUserInformation(data.user);
                                                    editUserForOrgRefName.current.value = data.user.name;
                                                    editUserForOrgRefEmail.current.value = data.user.email;
                                                    editUserForOrgRefPhoneNumber.current.value = data.user.phone_number;
                                                    editUserForOrgRefPromoCode.current.value = data.user.promocode;
                                                    editUserForOrgRefPackageType.current.value = data.user.package_number;
                                                    editUserForOrgRefPackageDuration.current.value = data.user.package_duration;
                                                    editUserForOrgRefAdministratorAccount.current.value = data.user.isAdmin;
                                                    editUserForOrgRefTestorAccount.current.value = data.user.isTester;
                                                    editUserForOrgRefTrialAccount.current.value = data.user.trial_expiry_flag;
                                                    editUserForOrgRefTrialExpirationDate.current.value = data.user.trial_expiry;
                                                    editUserForOrgRefSellerType.current.value = data.user.seller_type;
                                                    editUserForOrgRefHasMarketing.current.value = data.user.hasMarketing;
                                                    editUserForOrgRefHasMarketingNoPay.current.value = data.user.hasMarketingNoPay;
                                                    editUserForOrgRefCanExtract.current.value = data.user.canExtract;
                                                    console.log(data.user);               
                                                    toast("Loaded");
                                                    updateUserDialogRef.current.showModal();
                                                }}>Edit</button>
                                                <button onClick={ async e => {
                                                    toast("Loading");
                                                    let response = await fetch(API.baseURL + "/api/v1/user/getByID", {
                                                        method: "POST",
                                                        headers: {
                                                            'content-type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            api_key: localStorage.getItem("api_key") ?? "",
                                                            user_id: user.id,
                                                        })
                                                    });
                                                    let data = await response.json(); 
                                                    let api_key = localStorage.getItem('api_key') ?? "";
                                                    localStorage.setItem('original_api_key', api_key);
                                                    localStorage.setItem('api_key', data.user.api_key);
                                                    window.location.assign('/dashboard');
                                                }}>Impersonate</button>
                                                <button onClick={ async e => {
                                                    // TODO: SEND PASSWORD
                                                    toast("Loading");
                                                    let response = await fetch(API.baseURL + "/api/v1/user/remind/password/email", {
                                                        method: "POST",
                                                        headers: {
                                                            'content-type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            api_key: localStorage.getItem("api_key") ?? "",
                                                            email: user.email,
                                                        })
                                                    });
                                                    let data = await response.json(); 
                                                    toast("Password Sent to user's email address");
                                                }}>Send Password</button>
                                                <button onClick={ async e => {
                                                    if(user.id === orgInformation.user_id){
                                                        toast("Cannot delete user as they are the organisation root/owner user");
                                                        return;
                                                    }
                                                    toast("Loading");
                                                    let response = await fetch(API.baseURL + "/api/v1/user/getByID", {
                                                        method: "POST",
                                                        headers: {
                                                            'content-type': 'application/json'
                                                        },
                                                        body: JSON.stringify({
                                                            api_key: localStorage.getItem("api_key") ?? "",
                                                            user_id: user.id,
                                                        })
                                                    });
                                                    let data = await response.json(); 
                                                    setUserInformation(data.user);
                                                    toast("Loaded");
                                                    deleteOrgUserDialogRef.current.showModal();
                                                }} className='red'>Delete</button>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div> }
                    {
                        loading2 && <div style={{ display:'inline-block' }}>Loading</div>
                    }
                </div>
            </div>
            <dialog ref={createOrgDialogRef} className='fullscreen-dialog'>
                <h4>Add Organisation</h4>
                <p>You will be adding a new organisation, please enter your details below</p>
                <div className='new-dialog-inputs'>
                    <span>Organisation Title</span>
                    <input type="text" placeholder='Organisation Title' onChange={ e => setCreateOrgTitle(e.target.value)}/>
                    <span>Must Pay&nbsp;<sub>if enabled the users will be prompted to pay when they login</sub></span>                    
                    <select value={0} onChange={ e => setCreateOrgMustPay(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <button onClick={ async e => {
                        if(!!!createOrgTitle){
                            toast("Organisation Title Invalid");
                            return;
                        }
                        createOrgDialogRef.current.close();
                        createOrgUserDialogRef.current.showModal();
                        setCreateOrgUserPassword(genRStr(8));
                        setCreateOrgUserPromocode(genRStr(8));
                    }}>Add Organisation</button>
                    <button className='red' onClick={ e => {
                        createOrgDialogRef.current.close();
                    }}>Cancel</button>
                </div>
            </dialog>
            <dialog ref={createOrgUserDialogRef} className='fullscreen-dialog'>
                <h4>Add Organisation's Root User</h4>
                <p>You will be adding a user to the newly created organisation, please enter your details below</p>
                <div className='new-dialog-inputs'>
                    <span>Full Name</span>
                    <input type="text" placeholder='Full Name' onChange={ e => setCreateOrgUserFullName(e.target.value)}/>
                    <span>Email Address</span>
                    <input type="text" placeholder='Email Address' onChange={ e => setCreateOrgUserEmailAddress(e.target.value)}/>
                    <span>Password</span>
                    <input type="text" placeholder='Password' onChange={ e => setCreateOrgUserPassword(e.target.value)} defaultValue={createOrgUserPassword} readOnly/> 
                    <span>Promo Code</span>
                    <input type="text" placeholder='PromoCode' onChange={ e => setCreateOrgUserPromocode(e.target.value)} defaultValue={createOrgUserPromocode} readOnly/> 
                    <span>Package Type</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserPackageType(parseInt(e.target.value))}>
                        <option value="0">Free</option>
                        <option value="1">Solo</option>
                        <option value="2">Lite</option>
                        <option value="3">Business Lite</option>
                        <option value="4">Business Pro</option>
                        <option value="5">Enterprise</option>
                    </select>
                    <span>Package Duration</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserPackageDuration(parseInt(e.target.value))}>
                        <option value="0">Monthly</option>
                        <option value="1">Yearly</option>
                    </select>
                    <span>Administartor Account</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserAdminAccount(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <span>Tester Account</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserTesterAccount(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <span>Trial Account</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserTrialAccount(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <span>Trial Account Expiration Date</span>
                    <input type="datetime-local" placeholder='Expiration Date' onChange={ e => setCreateOrgUserTrialAccountDate(e.target.value)}/> 
                    <span>Seller Type</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserSellerType(parseInt(e.target.value))}>
                        <option value="0">Affiliate</option>
                        <option value="1">Influencer</option>
                        <option value="2">Corporate</option>
                        <option value="3">Reseller</option>
                    </select>
                    <span>Has Marketing</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserHasMarketing(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <span>Has Marketing No Pay</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserHasMarketingNoPay(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <span>Can Extract</span>
                    <select defaultValue={0} onChange={ e => setCreateOrgUserCanExtract(parseInt(e.target.value))}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </select>
                    <button onClick={ async e => {
                        toast("Loading, please wait");
                        let response = await fetch(API.baseURL + "/api/v1/org/createwithuser", {
                            method: "POST",
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                api_key: localStorage.getItem("api_key") ?? "",
                                org_name: createOrgTitle,
                                org_must_pay: createOrgMustPay,
                                org_domain_name:  "",
                                org_tab_title:  "",
                                org_company_logo: "", 
                                org_fav_icon: "",
                                org_is_white_label: 0,
                                canUseMarketing: 0,
                                enabledRegistration: 0,
                                org_allowed_seat_users: 0,
                                user_fullname: createOrgUserFullName,
                                user_email: createOrgUserEmailAddress,
                                user_password: createOrgUserPassword,
                                user_trail_account: createOrgUserTrialAccount,
                                user_trial_expirey_date: createOrgUserTrialAccountDate,
                                user_package_number: createOrgUserPackageType,
                                user_package_duration: createOrgUserPackageDuration,
                                user_is_admin_account: createOrgUserAdminAccount, 
                                user_is_tester_account: createOrgUserTesterAccount,
                                user_is_facebook_account: 0,
                                user_seller_type: createOrgUserSellerType,
                                user_is_account_disabled: 0,
                                user_has_marketing: createOrgUserHasMarketing,
                                user_has_marketing_no_pay: createOrgUserHasMarketingNoPay,
                                user_can_extract: createOrgUserCanExtract,
                                user_parent_id: 0,
                                user_promocode: createOrgUserPromocode
                            })
                        });
                        let data = await response.json();
                        if(!data['success']){
                            toast("An error occurd");
                            return;
                        }
                        toast("Organization Created");
                        createOrgUserDialogRef.current.close();
                        // possible bug
                        //loadOrg(orgInformation.id);
                        await loadOrgInfo();
                    }}>Add User</button>
                    <button className='red' onClick={ e => {
                        createOrgUserDialogRef.current.close();
                    }}>Cancel</button>
                </div>
            </dialog>
            <dialog ref={deleteOrgDialogRef} className='fullscreen-dialog'>
                <h4 style={{ textAlign:'left', paddingLeft:'12px'}}><FcOrganization/>&nbsp;&nbsp;{orgInformation.title} ({orgInformation.id})</h4>
                <p>Confirm Deletion of this organisation</p>
                <p>Deletion of this organisation cannot be undone, are you sure you want to delete this organisation?</p>
                <p>it could lead to unexpected issues to occur, please consult with the technical team</p>
                <div className='new-dialog-inputs'>
                    <button onClick={ async e => {
                        toast("Deleting, please wait");
                        let response = await fetch(API.baseURL + "/api/v1/org/delete", {
                            method: "POST",
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                api_key: localStorage.getItem("api_key") ?? "",
                                org_id: orgInformation.id,
                            })
                        });
                        let data = await response.json();
                        if(!data['success']){
                            toast("An error occurd");
                            return;
                        }
                        toast("Deleted");
                        deleteOrgDialogRef.current.close();
                        setViewOrgInformation(false);
                        loadOrgInfo();
                    }}>Delete Organisation</button>
                    <button className='red' onClick={ e => {
                        deleteOrgDialogRef.current.close();
                    }}>Cancel</button>
                </div>
            </dialog>
            <dialog ref={deleteOrgUserDialogRef} className='fullscreen-dialog'>
                <h4 style={{ textAlign:'left', paddingLeft:'12px'}}><FcOrganization/>&nbsp;&nbsp;{userInformation.name} ({userInformation.id})</h4>
                <p>Confirm Deletion of this organisation's user</p>
                <p>Deletion of this organisation's user cannot be undone, are you sure you want to delete this organisation's user?</p>
                <p>it could lead to unexpected issues to occur, please consult with the technical team</p>
                <div className='new-dialog-inputs'>
                    <button onClick={ async e => {
                        toast("Deleting, please wait");
                        let response = await fetch(API.baseURL + "/api/v1/user/delete", {
                            method: "POST",
                            headers: {
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                api_key: localStorage.getItem("api_key") ?? "",
                                user_id: userInformation.id,
                            })
                        });
                        let data = await response.json();
                        if(!data['success']){
                            toast("An error occurd");
                            return;
                        }
                        toast("Deleted");
                        deleteOrgUserDialogRef.current.close();
                        loadOrg(orgInformation.id);
                    }}>Delete User</button>
                    <button className='red' onClick={ e => {
                        deleteOrgUserDialogRef.current.close();
                    }}>Cancel</button>
                </div>
            </dialog>
            <dialog ref={updateUserDialogRef} className='fullscreen-dialog'>
                <h4 style={{ textAlign:'left', paddingLeft:'12px'}}><FcOrganization/>&nbsp;&nbsp;{userInformation.email} ({userInformation.id})</h4>
                <div className='new-dialog-inputs'>
                    <div className='new-grid'>
                        <div>
                            <span>Name</span>
                            <input ref={editUserForOrgRefName} type="text"  defaultValue={userInformation.name} onChange={ e => setUpdateOrgUserFullName(e.target.value)}/>
                            <span>Email</span>
                            <input ref={editUserForOrgRefEmail} type="text"  defaultValue={userInformation.email} readOnly/>
                            <span>Phone Number</span>
                            <input ref={editUserForOrgRefPhoneNumber} type="text"  defaultValue={userInformation.phone_number} onChange={ e => setUpdateOrgUserPhoneNumber(e.target.value)}/>
                            <span>Promo Code</span>
                            <input ref={editUserForOrgRefPromoCode} type="text" placeholder='PromoCode' readOnly defaultValue={userInformation.promocode}/>
                            <span>Package Type</span>
                            <select ref={editUserForOrgRefPackageType} defaultValue={userInformation.package_number} onChange={ e => setUpdateOrgUserPackageType(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>Free</option>
                                <option value={1} defaultValue={1}>Solo</option>
                                <option value={2} defaultValue={2}>Lite</option>
                                <option value={3} defaultValue={3}>Business Lite</option>
                                <option value={4} defaultValue={4}>Business Pro</option>
                                <option value={5} defaultValue={5}>Enterprise</option>
                            </select> 
                            <span>Package Duration</span>
                            <select ref={editUserForOrgRefPackageDuration} defaultValue={userInformation.package_duration} onChange={ e => setUpdateOrgUserPackageDuration(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>Monthly</option>
                                <option value={1} defaultValue={1}>Yearly</option>
                            </select>
                            <span>Administartor Account</span>
                            <select ref={editUserForOrgRefAdministratorAccount} defaultValue={userInformation.isAdmin} onChange={ e => setUpdateOrgUserAdminAccount(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                        </div>
                        <div>
                            <span>Tester Account</span>
                            <select ref={editUserForOrgRefTestorAccount} defaultValue={userInformation.isTester} onChange={ e => setUpdateOrgUserTesterAccount(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                            <span>Trial Account</span>
                            <select ref={editUserForOrgRefTrialAccount} defaultValue={userInformation.trial_expiry_flag} onChange={ e => setUpdateOrgUserTrialAccount(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                            <span>Trial Account Expiration Date</span>
                            <input ref={editUserForOrgRefTrialExpirationDate} type="datetime-local" placeholder='Expiration Date' onChange={ e => setUpdateOrgUserTrialAccountDate(e.target.value)} defaultValue={userInformation.trial_expiry}/> 
                            <span>Is Seller</span>
                            <select defaultValue={userInformation.isSeller} onChange={ e => setUpdateOrgUserIsSeller(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                            <span>Seller Type</span>
                            <select ref={editUserForOrgRefSellerType} defaultValue={userInformation.seller_type} onChange={ e => setUpdateOrgUserSellerType(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>Affiliate</option>
                                <option value={1} defaultValue={1}>Influencer</option>
                                <option value={2} defaultValue={2}>Corporate</option>
                                <option value={3} defaultValue={3}>Reseller</option>
                            </select>
                            <span>Has Marketing</span>
                            <select ref={editUserForOrgRefHasMarketing} defaultValue={userInformation.hasMarketing} onChange={ e => setUpdateOrgUserHasMarketing(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                            <span>Has Marketing No Pay</span>
                            <select ref={editUserForOrgRefHasMarketingNoPay} defaultValue={userInformation.hasMarketingNoPay} onChange={ e => setUpdateOrgUserHasMarketingNoPay(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                            <span>Can Extract</span>
                            <select ref={editUserForOrgRefCanExtract} defaultValue={userInformation.canExtract} onChange={ e => setUpdateOrgUserCanExtract(parseInt(e.target.value))}>
                                <option value={0} defaultValue={0}>No</option>
                                <option value={1} defaultValue={1}>Yes</option>
                            </select>
                        </div>
                    </div>
                    <button onClick={ async e => {
                        toast("Saving Changes");
                        let response = await fetch(API.baseURL + "/api/v1/user/updateByID", {
                            method: "POST",
                            headers: {
                                "content-type": "application/json"
                            },
                            body: JSON.stringify({
                                api_key: localStorage.getItem("api_key") ?? "",
                                user_data: {
                                    id: userInformation.id,
                                    name: updateOrgUserFullName,
                                    package_number:updateOrgUserPackageType,
                                    package_duration:updateOrgUserPackageDuration,
                                    isAdmin:updateOrgUserAdminAccount,
                                    isTester:updateOrgUserTesterAccount,
                                    seller_type: updateOrgUserSellerType,    
                                    hasMarketing: updateOrgUserHasMarketing,    
                                    hasMarketingNoPay: updateOrgUserHasMarketingNoPay,    
                                    canExtract: updateOrgUserCanExtract,    
                                    isSeller: updateOrgUserIsSeller,    
                                    trial_expiry: updateOrgUserTrialAccountDate,
                                    trial_expiry_flag: updateOrgUserTrialAccount,
                                    phone_number: updateOrgUserPhoneNumber
                                }
                            })
                        })
                        let data = await response.json();
                        toast("Changes Saved");
                        updateUserDialogRef.current.close();
                        loadOrg(orgInformation.id);
                    }}>Save Changes</button>
                    <button className='red' onClick={ e => {
                        updateUserDialogRef.current.close();
                    }}>Cancel</button>
                </div>
            </dialog>
            <dialog ref={createUserForOrgDialogRef} className='fullscreen-dialog'>
                <h4 style={{ textAlign:'left', paddingLeft:'12px'}}><FcOrganization/>&nbsp;&nbsp;{orgInformation.title} ({orgInformation.id})</h4>
                <div className='new-dialog-inputs'>
                    <div className='new-grid'>
                        <div>
                            <span>Name</span>
                            <input ref={createuserForOrgRefName} type="text"  defaultValue={""} onChange={ e => setCreateNewOrgUserFullName(e.target.value)}/>
                            <span>Email</span>
                            <input ref={createuserForOrgRefEmail} type="text"  defaultValue={""} onChange={ e => setCreateNewOrgUserEmail(e.target.value)}/>
                            <span>Password</span>
                            <input type="text"  defaultValue={createNewOrgUserPassword} onChange={ e => setCreateNewOrgUserPassword(e.target.value)}/>
                            <span>Phone Number</span>
                            <input ref={createuserForOrgRefPhoneNumber} type="text"  defaultValue={""} onChange={ e => setCreateNewOrgUserPhoneNumber(e.target.value)}/>
                            <span>Promo Code</span>
                            <input type="text" placeholder='PromoCode' readOnly defaultValue={createNewOrgUserPromoCode}/>
                            <span>Package Type</span>
                            <select ref={createuserForOrgRefPackageType} value={createNewOrgUserPackageType} onChange={ e => setCreateNewOrgUserPackageType(parseInt(e.target.value))}>
                                <option value={0}>Free</option>
                                <option value={1}>Solo</option>
                                <option value={2}>Lite</option>
                                <option value={3}>Business Lite</option>
                                <option value={4}>Business Pro</option>
                                <option value={5}>Enterprise</option>
                            </select> 
                            <span>Package Duration</span>
                            <select ref={createuserForOrgRefPackageDuration} value={createNewOrgUserPackageDuration} onChange={ e => setCreateNewOrgUserPackageDuration(parseInt(e.target.value))}>
                                <option value={0}>Monthly</option>
                                <option value={1}>Yearly</option>
                            </select>
                            <span>Administartor Account</span>
                            <select ref={createuserForOrgRefAdminAccount} value={createNewOrgUserAdminAccount} onChange={ e => setCreateNewOrgUserAdminAccount(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                        </div>
                        <div>
                            <span>Tester Account</span>
                            <select ref={createuserForOrgRefTestAccount} value={createNewOrgUserTesterAccount} onChange={ e => setCreateNewOrgUserTesterAccount(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                            <span>Trial Account</span>
                            <select ref={createuserForOrgRefTrialAccount} value={createNewOrgUserTrialAccount} onChange={ e => setCreateNewOrgUserTrialAccount(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                            <span>Trial Account Expiration Date</span>
                            <input ref={createuserForOrgRefTrialAccountExpiration} type="datetime-local" placeholder='Expiration Date' onChange={ e => setCreateNewOrgUserTrialAccountDate(e.target.value)} defaultValue={(new Date()).toISOString()}/> 
                            <span>Is Seller</span>
                            <select ref={createuserForOrgRefIsSeller} value={createNewOrgUserIsSeller} onChange={ e => setCreateNewOrgUserIsSeller(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                            <span>Seller Type</span>
                            <select ref={createuserForOrgRefSellerType} value={createNewOrgUserSellerType} onChange={ e => setCreateNewOrgUserSellerType(parseInt(e.target.value))}>
                                <option value={0}>Affiliate</option>
                                <option value={1}>Influencer</option>
                                <option value={2}>Corporate</option>
                                <option value={3}>Reseller</option>
                            </select>
                            <span>Has Marketing</span>
                            <select ref={createuserForOrgRefHasMarketing} value={createNewOrgUserHasMarketing} onChange={ e => setCreateNewOrgUserHasMarketing(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                            <span>Has Marketing No Pay</span>
                            <select ref={createuserForOrgRefHasMarketingNoPay} value={createNewOrgUserHasMarketingNoPay} onChange={ e => setCreateNewOrgUserHasMarketingNoPay(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                            <span>Can Extract</span>
                            <select ref={createuserForOrgRefCanExtract} value={createNewOrgUserCanExtract} onChange={ e => setCreateNewOrgUserCanExtract(parseInt(e.target.value))}>
                                <option value={0}>No</option>
                                <option value={1}>Yes</option>
                            </select>
                        </div>
                    </div>
                    <button onClick={ async e => {
                        toast("Saving Changes");
                        let response = await fetch(API.baseURL + "/api/v1/org/createorguser", {
                            method: "POST",
                            headers: {
                                "content-type": "application/json"
                            },
                            body: JSON.stringify({
                                api_key: localStorage.getItem("api_key") ?? "",
                                user_data: {
                                    org_id: orgInformation.id,
                                    name: createNewOrgUserFullName,
                                    email: createNewOrgUserEmail,
                                    password: createNewOrgUserPassword, 
                                    promocode: createNewOrgUserPromoCode,
                                    package_number:createNewOrgUserPackageType,
                                    package_duration:createNewOrgUserPackageDuration,
                                    isAdmin:createNewOrgUserAdminAccount,
                                    isTester:createNewOrgUserTesterAccount,
                                    seller_type: createNewOrgUserSellerType,    
                                    hasMarketing: createNewOrgUserHasMarketing,    
                                    hasMarketingNoPay: createNewOrgUserHasMarketingNoPay,    
                                    canExtract: createNewOrgUserCanExtract,    
                                    isSeller: createNewOrgUserIsSeller,    
                                    trial_expiry: createNewOrgUserTrialAccountDate,
                                    trial_expiry_flag: createNewOrgUserTrialAccount,
                                    phone_number: createNewOrgUserPhoneNumber
                                }
                            })
                        })
                        let data = await response.json();
                        if(data['success'] === false){
                            toast("error: " + data['message']);
                            return;
                        }
                        toast("User Created");
                        createUserForOrgDialogRef.current.close();
                        createuserForOrgRefName.current.value = "";
                        createuserForOrgRefEmail.current.value = "";
                        setCreateNewOrgUserPassword("");
                        createuserForOrgRefPhoneNumber.current.value = "";
                        setCreateNewOrgUserPromoCode("");
                        createuserForOrgRefPackageType.current.value = 0;
                        createuserForOrgRefPackageDuration.current.value = 0;
                        createuserForOrgRefAdminAccount.current.value = 0;
                        createuserForOrgRefTestAccount.current.value = 0;
                        createuserForOrgRefTrialAccount.current.value = 0;
                        createuserForOrgRefTrialAccountExpiration.current.value = (new Date()).toISOString();
                        createuserForOrgRefIsSeller.current.value = 0;
                        createuserForOrgRefSellerType.current.value = 0;
                        createuserForOrgRefHasMarketing.current.value = 0;
                        createuserForOrgRefHasMarketingNoPay.current.value = 0;
                        createuserForOrgRefCanExtract.current.value = 0;
                        loadOrg(orgInformation.id);
                    }}>Save Changes</button>
                    <button className='red' onClick={ e => {
                        updateUserDialogRef.current.close();
                    }}>Cancel</button>
                </div>
            </dialog>
            <dialog ref={handleCustomOrgCIIntroVideoDialogRef} className='fullscreen-dialog'>
                <h4 style={{ textAlign:'left', paddingLeft:'12px'}}><FcOrganization/>&nbsp;&nbsp;{orgInformation.title} ({orgInformation.id}) - Custom Candidate CI Intro Video</h4>
                <div className='new-dialog-inputs'>
                    <div className='new-grid'>
                        <div><video src={showCustomIntroVideoURL ?? ""} width={"240px"} height={"240px"} style={{background:"#1E1E1E", borderRadius:'8px', display:"inline-block"}} controls playsInline autoPlay></video></div>
                        <div><button className='primary-button-blue' onClick={ e => {
                            handleCustomOrgCIIntroVideoDialogRefComponent.current.showModal();
                        }}>Upload Video</button>
                        <button className='primary-button-blue' onClick={ async e => {
                            toast.info("Loading");
                            let api_key = localStorage.getItem('api_key');
                            let result = await API.org.updateOrgCustomCandidateIntroVideo(api_key, orgInformation.id, "");
                            if(!result['success']){
                                toast.error("Failed to set custom intro video");
                                return;
                            }
                            toast.info("Updated Successfully")
                            handleCustomOrgCIIntroVideoDialogRefComponent.current.close();
                            toast.info("Loading")
                            loadOrg(orgInformation.id);
                            setShowCustomIntroVideoURL("");
                        }}>Remove Video</button>
                        <button className='primary-button-blue' onClick={ e => {
                            setShowCustomIntroVideoURL("");
                            handleCustomOrgCIIntroVideoDialogRef.current.close();
                        }}>Close</button></div>
                    </div>
                </div>
            </dialog>
            <dialog ref={handleCustomOrgCIIntroVideoDialogRefComponent} className='fullscreen-dialog'>                
                { (orgInformation && orgInformation.id) && <FileUploadComponent
                    onclose={()=>{
                        handleCustomOrgCIIntroVideoDialogRefComponent.current.close();
                    }}
                    oncompletion={async (video_url)=>{
                        let api_key = localStorage.getItem('api_key');
                        let result = await API.org.updateOrgCustomCandidateIntroVideo(api_key, orgInformation.id, video_url);
                        if(!result['success']){
                            toast.error("Failed to set custom intro video");
                            return;
                        }
                        toast.info("Updated Successfully")
                        handleCustomOrgCIIntroVideoDialogRefComponent.current.close();
                        toast.info("Loading")
                        loadOrg(orgInformation.id);
                    }
                }>
                </FileUploadComponent>}
            </dialog>
        </>
    );
}