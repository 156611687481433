import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';

// Customize the theme to force light mode for DataGrid
const theme = createTheme({
  palette: {
    mode: 'light', // Force light mode
  },
} as any);

export default function QuestionsDataGrid({ loading, columns, rows}) {
    return (
      <ThemeProvider theme={theme}>
        <DataGrid
          loading={loading}
          rows={rows}
          columns={columns}
          disableColumnMenu={true}
          disableRowSelectionOnClick
          onCellClick= {(param, event) => { event.defaultMuiPrevented = true; event.defaultPrevented = true; event.stopPropagation();}}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[5, 10, 25, 50]}
        />
      </ThemeProvider>
    );
  }