import React, { createRef } from "react";

import './videocontroller.css';

export default class VideoControllerComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          video_url: props.defaultValue
      };
      this.videoComponentRef = props.videoComponent;
      this.videoRecordJobBoardRefElement = props.videoComponentElement;
      this.showVideoPreview = props.showPreview;
    }

    async componentDidMount(){
        if(this.videoComponentRef.current)this.videoComponentRef.current.style.display = "none";
    }

    async setURL(value){
        this.videoComponentRef.current.style.display = "none";
        this.setState({
            ...this.state,
            video_url: value
        });
    }

    render() {
        // HTML
        return (
            <>
            <div className="videocontroller">
                {
                    this.state.video_url ? <div>
                        <button onClick={async()=>{                    
                            this.videoComponentRef.current.style.display = "flex";
                            this.videoRecordJobBoardRefElement.current.resetUI();
                            this.videoRecordJobBoardRefElement.current.openVideo();
                        }} className="redHover">Record Video</button>
                        <button onClick={()=>{
                            this.showVideoPreview(this.state.video_url);
                        }} style={{borderRadius:'0px'}}>Preview Video</button>
                        <input type="text" readOnly={true} placeholder="Video Link" value={this.state.video_url} style={{width:'390px'}}/>
                    </div> : <div>
                        <button onClick={async()=>{                    
                            this.videoComponentRef.current.style.display = "flex";
                            this.videoRecordJobBoardRefElement.current.resetUI();
                            this.videoRecordJobBoardRefElement.current.openVideo();
                        }}>Record Video</button>
                        <input type="text" readOnly={true} placeholder="Video Link" value={this.state.video_url}/>
                    </div>
                }
            </div>
            </>
        )
    }
}