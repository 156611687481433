import React, { useState, useEffect } from 'react';
import API from '../../services/api';
import { Area, AreaChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';

import './analytics-component.css';

interface AnalyticsComponentProps {
    apiKey:string;
    marketing:any;
}

export default function AnalyticsComponent(props:AnalyticsComponentProps){
    const [ analytics, setAnalytics ] = useState<any>(null);
    const [ analytics2, setAnalytics2 ] = useState<any>(null);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ toggle, setToggle ] = useState<boolean>(false);
    const loadAnalytics = async (x:number = 0) => {
        const response = await API.marketing.campaign.getAnalytics(props.apiKey, props.marketing.id, x);
        setAnalytics(response['data']);
    }
    const loadAnalytics2 = async (x:number = 0) => {
        const response = await API.marketing.campaign.getAnalytics2(props.apiKey, props.marketing.id, x);
        setAnalytics2(response['data']);
    }
    useEffect(()=>{
        if(!analytics && !analytics2) return;
        setLoading(false);
    }, [analytics, analytics2]);

    useEffect(()=>{
        if(!props.marketing) return;
        loadAnalytics();
        loadAnalytics2();
    }, [props.marketing]);

    if(loading){
        return <div className="question-component-container">
            <div className="question-toggle">
                <div>
                    <span>Loading Analytics</span>
                </div>
            </div>
        </div>;
    }
    return <div className="analytics-component-container">
        <div className="analytics-toggle">
            <div>
                <span>Analytics</span><button onClick={ e => { setToggle( x => !x)}}>{`${toggle ? "Hide" : "Show"}`}</button>
            </div>
        </div>
        { toggle && <div style={{transform:"translateY(-24px)", paddingLeft:'12px'}}>
            <h4 style={{borderBottom: "1px solid #1976D1", padding:"12px", width:'calc(100% - 12px)', transform:'translateX(-12px)'}}>Analytics</h4>
                <div style={{width:'100%', transform:"translateY(-24px)", paddingLeft:"16px"}}>
                <div style={{width:'29%', display:'inline-block', verticalAlign:'top'}}>
                    <h5>Bespoke</h5>
                    <ul>
                        <li>Total Clicks: { props.marketing.campaign?.open_rate?.bespoke }</li>
                        <li>{ props.marketing.sent_count >= 2 ? "Messages" : "Message"} Sent: { props.marketing.sent_count }</li>
                    </ul>
                </div>
                <div style={{width:'29%', display:'inline-block', verticalAlign:'top'}}>
                    <h5>Campaign</h5>
                    <ul>
                        <li>Total Clicks: { props.marketing?.open_rate?.campaign }</li>                                            
                        <li>{ props.marketing.sent_count_marketing >= 2 ? "Messages" : "Message"} Sent: { props.marketing.sent_count_marketing }</li>
                    </ul>
                </div>
                <div style={{width:'29%', display:'inline-block', verticalAlign:'top'}}>
                    <h5>VLog</h5>
                    <ul>
                        <li>{ props.marketing.sent_count_vlog >= 2 ? "Messages" : "Message"} Open: { props.marketing.sent_count_vlog }</li>
                    </ul>
                </div>
            </div>
            <div>
                <h4>Timeline (Additive)</h4>
                <span>Views: </span>&nbsp;&nbsp;
                <button className="blueBTN" onClick={async ()=>{
                    toast.info("Loading");
                    await loadAnalytics(0);
                    toast.info("Loaded");
                }}>Day</button>&nbsp;&nbsp;
                <button className="blueBTN" onClick={async ()=>{
                    toast.info("Loading");
                    await loadAnalytics(1);                
                    toast.info("Loaded");
                }}>Month</button>&nbsp;&nbsp;
                <button className="blueBTN" onClick={async ()=>{
                    toast.info("Loading");
                    await loadAnalytics(2);                
                    toast.info("Loaded");
                }}>Year</button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                width={100}
                height={300}
                data={analytics}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name"/>
                <YAxis allowDecimals={false}/>
                <Legend/>
                <Line name="Bespoke Clicks" type="monotone" dataKey="bespoke" stroke="#FFCEA9" strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                <Line name="Campaign Clicks" type="monotone" dataKey="campaign" stroke="#DDDDDD" strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                <Line name="Vlog Sends" type="monotone" dataKey="vlog" stroke="#3C97DA"  strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
            <div>
                <h4>Timeline (Counts)</h4>
                <span>Views: </span>&nbsp;&nbsp;
                <button className="blueBTN" onClick={async ()=>{                    
                    toast.info("Loading");
                    await loadAnalytics2(0);                
                    toast.info("Loaded");
                }}>Day</button>&nbsp;&nbsp;
                <button className="blueBTN" onClick={async ()=>{                    
                    toast.info("Loading");
                    await loadAnalytics2(1);                
                    toast.info("Loaded");
                }}>Month</button>&nbsp;&nbsp;
                <button className="blueBTN" onClick={async ()=>{                    
                    toast.info("Loading");
                    await loadAnalytics2(2);                
                    toast.info("Loaded");
                }}>Year</button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                width={100}
                height={300}
                data={analytics2}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis allowDecimals={false}/>
                <Area connectNulls name="Bespoke Views" type="monotone" dataKey="bespoke" stroke="#FFCEA9" strokeWidth={5} dot={{r:6}} activeDot={{ r: 8 }} />
                </AreaChart>
            </ResponsiveContainer>
        </div> }
    </div>;
}