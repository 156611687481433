import React, { createRef, useEffect, useState } from "react";
import axios from 'axios';


import { DateTime } from "luxon";

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { AiFillStar, AiFillCamera, AiOutlineLoading } from 'react-icons/ai';
import { FiFilter } from 'react-icons/fi';


import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import { Tooltip as ReactTooltip } from "react-tooltip";

import fuzzytime from 'fuzzy-time';

import "./vacancies.css";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";


import TutorialComponent from "../components/tutorial";

import { ToastContainer, toast } from 'react-toastify';
import { fixDateWithTime } from "../services/utils";
import { useParams } from "react-router-dom/cjs/react-router-dom";

import './surveyview.css';
import MultiChoiceOptions from "../components/MultiChoiceOptions";

const PAGE_SIZE = 10;

export default function SurveyViewPage(props:any){
    const [ survey, setSurvey] = useState<any>(null);
    const [ createSurveyOptionText, setCreateSurveyOptionText] = useState<any>(null);
    const [ surveyOptions, setSurveyOptions] = useState<any>(null);
    const [ showSurveyOptions, setShowSurveyOptions] = useState<any>(null);
    const [ showSurveyOptionsOptions, setShowSurveyOptionsOptions] = useState<any>(null);
    const [ surveyOptionsOptionsValue, setSurveyOptionsOptionsValue] = useState<any>([]);
    const [ assessmentQuestionType, setAssessmentQuestionType] = useState<number>(0);
    const [ selected, setSelected] = useState<any>(null);
    const { id } = useParams();

    const getSurvey = async () => {
        let response = await axios.post(API.baseURL + "/api/v1/surveys/get", {
            'api_key': localStorage.getItem("api_key"),
            'id': parseInt(id)
        });
        let data = response.data;
        setSurvey(data.result);
    }
    
    const getSurveyOptions = async () => {
        let response = await axios.post(API.baseURL + "/api/v1/surveys/get/options", {
            'api_key': localStorage.getItem("api_key"),
            'id': parseInt(id)
        });
        let data = response.data;
        setSurveyOptions(data.results);
    }

    useEffect(()=>{
        (document.querySelector('body') as any).style.backgroundColor = "#EEF0F8"; // quick hack 
        getSurvey();
        getSurveyOptions();
    }, []);

    return (
        <>
        <div className="dashboardContainer">
            <SideBarComponent></SideBarComponent>
            <div className="dashboarContent">
                <UpperBarComponent></UpperBarComponent>
                <div className="pageNameInfo">
                    <span>Assessment / { survey ? survey.title + "(" + survey.id + ")": "Loading"}</span>
                </div>
                <div className="addspace">
                    <h4>Assessment Questions</h4>
                    <table className="vacancy-table">
                        <tr>
                            <th style={{ width:'720px', textAlign:'left' }}>
                                Questions&nbsp;&nbsp;&nbsp;
                                <span className="dropdown" style={{display:'inline-block'}}>
                                    <button id="vacancies_actions">Actions</button>
                                    <label>
                                        <input type="checkbox" />
                                        <ul>
                                        <li style={{fontWeight:400, color:'#000'}} onClick={()=>{
                                            setCreateSurveyOptionText("");
                                            setShowSurveyOptions(true);
                                        }}>Add A Question</li>
                                        </ul>
                                    </label>
                                </span>
                            </th>
                            <th style={{ width:'720px', textAlign:'left' }}>Action</th>
                        </tr>
                        {
                            surveyOptions !== null && surveyOptions.map((option, index)=>{
                                return <tr>
                                    <td style={{ width:'720px', textAlign:'left' }}>{ option.description }</td>
                                    <td style={{ width:'720px', textAlign:'left' }}>
                                        <button className="blue" style={{ cursor:'pointer', borderRadius:'8px', padding:'12px 24px' }} onClick={ e => {
                                            setSelected(option);
                                            setShowSurveyOptionsOptions(true);
                                        }}>View Options</button>
                                        <button className="red" style={{ cursor:'pointer', borderRadius:'8px', padding:'12px 24px' }} onClick={ async e => {
                                            toast("Deleting");
                                            let response = await axios.post(API.baseURL + "/api/v1/surveys/delete/option", {
                                                api_key: localStorage.getItem("api_key") ?? "",
                                                id: option.id
                                            });
                                            toast("Deleted, Loading")
                                            await getSurveyOptions();
                                        }}>Delete</button>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            (surveyOptions !== null && surveyOptions.length === 0) && <div style={{ marginLeft:"12px" }}>No Results</div>
                        }
                    </table>
                </div>
            </div>
        </div>
        { showSurveyOptions && <div>
            <div className="dialogNewCreate">        
                <div>
                    <h4>Add a new assessment question</h4>
                    <div>
                        <h5 style={{textAlign:'left'}}>Question Text</h5>
                        <input style={{ background:"#E1E1E1" }} type="text" className="createVacancyInput" placeholder="Question Text" onChange={ e => setCreateSurveyOptionText( e.target.value)}/>
                        <h5 style={{textAlign:'left'}}>Answer Type</h5>
                        <select name="createAssessmentX" id="createAssessmentX">
                            <option value="0">Multi-Choice</option>
                            <option value="1">Yes / No</option>
                        </select>
                    </div>
                    <div>
                        <button onClick={async ()=>{
                            toast("Loading");
                            let z = parseInt((document.getElementById("createAssessmentX") as any).value);
                            let response = await axios.post(API.baseURL + "/api/v1/surveys/add/option", {
                                'api_key': localStorage.getItem("api_key"),
                                'id': parseInt(id),
                                'description': createSurveyOptionText,
                                "type": z
                            });
                            toast("Survey Option Added");
                            let x = response.data.result;
                            if(z === 1){
                                x.choices = '[{"id":0,"value":"Yes", "answer":0},{"id":1,"value":"No", "answer":0}]';
                            }
                            x.type = z;
                            setAssessmentQuestionType(z);
                            setSelected(x);
                            setShowSurveyOptionsOptions(true);
                            setShowSurveyOptions(false);
                        }}>Create</button>
                        <button onClick={()=>{
                            setShowSurveyOptions(false);
                        }}>Close</button>
                    </div>
                </div>
            </div>
        </div>}
        { showSurveyOptionsOptions && <div>
            <div className="dialogNewCreate">        
                <div style={{ width:'720px' }}>
                    <h4>Manage Assessment Options for this question</h4>
                    <div>
                        <MultiChoiceOptions selected={selected} defaultValue={selected?.choices ?? '[{"id":1, "value":"", "answer":0},{"id":2, "value":"", "answer":0},{"id":3, "value":"", "answer":0},{"id":4, "value":"", "answer":0}]'} onChange={async (x)=>{
                            toast("Loading");
                            // clear options
                            let response = await axios.post(API.baseURL + "/api/v1/surveys/add/option/choice", {
                                api_key: localStorage.getItem("api_key"),
                                id: selected.id,
                                options: JSON.stringify(x),
                                type: selected.type
                            });
                            setSurveyOptionsOptionsValue(x);
                            setShowSurveyOptionsOptions(false);
                            await getSurveyOptions();
                        }}/>
                    </div>
                    <div>
                        <button className="blue" onClick={()=>{
                            setShowSurveyOptionsOptions(false);
                        }} style={{ width:'97%', margin:"0 auto !important", transform:'translateX(-11px)' }}>Close</button>
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
}