import React, { useState, useEffect, useRef } from "react"

import "./customVideoComponentForITJ.css";
import VideoUploadOrLibraryOrRecord from "./videoUploadOrLibraryOrRecord";

export default function CustomVideoComponentForITJ({ apiKey, title, user, description, currentVideoURL, libraryVideos, onPreview, onChange}: { apiKey:string, user:any, title: string, description: string, currentVideoURL:string, libraryVideos:any[], onPreview: (video_url: any) => void, onChange: (video_url: any) => void}){
    const vuolorRef = useRef<any>(null);
    return <>
        <div className="custom-video-component-for-itj">
            <div>
                <h4>{ title }</h4>
                <span>{ description }</span>
            </div>
            <div>
                { currentVideoURL && <>
                            <button onClick={()=>{
                                if (vuolorRef.current) {
                                    vuolorRef.current.open();
                                }
                            }}>Edit Video</button>
                            <button onClick={()=>{
                                onPreview(currentVideoURL);
                            }}>Preview Video</button>
                            <button onClick={()=>{
                                onChange("");
                            }}>Remove Video</button>
                </>}
                { !currentVideoURL && <button onClick={()=>{
                    if (vuolorRef.current) {
                        vuolorRef.current.open();
                    }
                }}>Add Video</button>}
            </div>
        </div>
        <VideoUploadOrLibraryOrRecord user={user} apiKey={apiKey} ref={vuolorRef} onChange={(video_url)=>{
            onChange(video_url);
            if (vuolorRef.current) {
                vuolorRef.current.close();
            }
        }} libraryVideos={libraryVideos}/>
    </>
}