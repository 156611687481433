import React, { createRef } from "react";

import SideBarComponent from "../components/sidebar";
import UpperBarComponent from "../components/upperbar";

import API from '../services/api';

import './paypal.css';

import { AiOutlinePlusCircle } from 'react-icons/ai';

export default class PayPalPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          plans: [],
          subscriptions: []
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.paypal.plans.list(api_key);
        console.log(result);
    }

    render() {
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>PayPal Management</span> 
                    </div>     
                    <div className="paypal-container">
                        <div className="paypal-col">
                            <h4>Plans</h4>
                            <span>Manage paypal plans here</span>
                            <div className="plans_list_container">
                                <div className="item">
                                    <button>Open</button>
                                    <div className="infoxx">                                        
                                        <span>Business Lite</span>
                                        <span>Business Lite wihtout any discount</span>
                                    </div>
                                    <div className="status active" title="Active Plan"></div>
                                </div>
                            </div>
                            <div className="create_plan" title="Create a new plan">
                                <AiOutlinePlusCircle/>
                            </div>
                        </div>
                        <div className="paypal-col">
                            <h4>Subscriptions</h4>
                            <span>Manage paypal subscriptions here</span>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
      }
}