import React from "react";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";


import API from "../services/api";

import './profile.css';

export default class ProfilePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "loading",
        email: "loading",
        phone_number: "loading",
        promocode: "loading",
        api_key: "loading",
        isAdmin: "loading",
        isTester: "loading",
        tier: "loading",
        balance: 0,
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 

      this.makeWithdrawal = this.makeWithdrawal.bind(true);
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        this.setState({...this.state, ...user});
        
        result = await API.user.getUserBalance(api_key);
        let balance = result['balance'] / 100;
        this.setState({...this.state, balance: balance});
    }

    makeWithdrawal(){
        // TODO: display withdraw ui and redirect or cause payment. (speak with J about this)
        
    }

    render() {
        // HTML

        let account_type_value = "loading";
        let loading = (this.state.isAdmin == "loading" || this.state.isTester == "loading" );
        let account_types = "";
        if(this.state.isAdmin){
            account_types += "Administrator ";
        }
        if(this.state.isTester){
            account_types += "Tester ";
        }

        let package_name = [
            "Loading",
            "Free Subscription Package",
            "Lite Subscription Package",
            "Lite Business Subscription Package",
            "Business Subscription Package",
            "Enterprise Subscription Package",
        ][(this.state.package_number ?? 0) + 1 ?? 0];
        
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>My Profile</span>
                    </div>
                    <ul className="myprofile-userdetail">
                        <li><strong>Full Name:</strong>  { this.state.name } </li>
                        <li><strong>Email:</strong>  { this.state.email } </li>
                        <li><strong>Phone Number:</strong>  { this.state.phone_number } </li>
                        <li><strong>Promocode:</strong>  { this.state.promocode }</li>
                        <li><strong>API Key:</strong>  { this.state.api_key }</li>
                        <li><strong>Account Tier:</strong>  { package_name }</li>
                    </ul>
                </div>
            </div>
        );
      }
}