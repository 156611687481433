import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import './vacancy.css';
import { toast } from "react-toastify";
import IndexedDBFileStorage from "../components/inBrowserRecorderAndProcessingComponent/IndexedDBFileStorage";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { blobToBase64 } from "base64-blob";
import Permissions from "../components/inBrowserRecorderAndProcessingComponent/Permissions";

export default function ClearInternal(props) {
    const [ loading, setLoading] = useState<boolean>(false);
    const [ loadingStep, setLoadingStep] = useState<number>(0);
    const [ totalSteps, setTotalSteps] = useState<number>(0);
    const ffmpegRef = useRef(new FFmpeg());
    const fromBlobToURL = (blob:any) => {
        return URL.createObjectURL(blob);
    }
    const fromURLToBlob = async (url: string):Promise<any> => {
        try {
          const response = await fetch(url, {
          });  
          if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
          }  
          const blob = await response.blob();
          const base64String = await blobToBase64(blob);
          return base64String;
        } catch (error) {
          console.error('Download failed:', error);
          throw error;
        }
    }
    const DoStartClearing = async () => {
        // Setup Steps and Show Popup
        setLoadingStep(0);
        setTotalSteps(8);
        setLoading(true);
        // Perform Changes
        // => Clear Indexed Database        
        await Permissions.wait(1);
        setLoadingStep(1);
        try {
            const dbRef = useRef(new IndexedDBFileStorage("recorded_file_data", 1));
            dbRef.current.clearAllFiles();
        } catch (e){ /* DO NOTHING ON ERROR */ }
        // => Clear Local Storage (Keep a local Cache of the API Key)
        await Permissions.wait(1);
        setLoadingStep(2);
        try {
            const temp_api_key = localStorage.getItem("temp_api_key");
            const api_key = localStorage.getItem("api_key");
            localStorage.clear();        
            localStorage.setItem("temp_api_key", temp_api_key ?? "");
            localStorage.setItem("api_key", api_key ?? "");
        } catch (e){ /* DO NOTHING ON ERROR */ }
        // => Clear All FFMPEG Local Files    
        await Permissions.wait(1);
        setLoadingStep(3);
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd';
        const coreURL = `${baseURL}/ffmpeg-core.js`;
        const wasmURL = `${baseURL}/ffmpeg-core.wasm`;
        ///const coreData = await fromURLToBlob(coreURL);
        await Permissions.wait(1);
        setLoadingStep(4);
        //const wasmData = await fromURLToBlob(wasmURL);
        await Permissions.wait(1);
        setLoadingStep(5);
        //const coreBlob = new Blob([coreData], { type: "text/javascript"});
        //const wasmBlob = new Blob([wasmData], { type: "application/wasm"});
        await Permissions.wait(1);
        setLoadingStep(6);
        const ffmpeg = ffmpegRef.current;
        await ffmpeg.load({
            coreURL: coreURL, //fromBlobToURL(coreBlob),
            wasmURL: wasmURL, //fromBlobToURL(wasmBlob),
        });
        await Permissions.wait(1);
        setLoadingStep(7);
        try {
            const directory_list = await ffmpeg.listDir("/");
            const asPaths = directory_list.map( x => x.name);
            const noNoPaths = [".", "..", "tmp", "home", "dev", "proc"];
            for(const path of asPaths){
                if(!noNoPaths.includes(path)){
                    try {
                        await ffmpeg.deleteFile(path);
                    } catch (e){ /* DO NOTHING ON ERROR */ 
                        console.log(e);
                    }
                }
            }
        } catch (e){ /* DO NOTHING ON ERROR */ 
            console.log(e);
        }
        await Permissions.wait(1);
        setLoadingStep(8);
    }
    return <>
        <div className="dashboardContainer dashboardBlank">
            <div className="dashboardContent dashboard-page nice-grey">
                <h4><span style={{color:'red'}}>Caution</span> - Clear Internal Browser</h4>
                <span>Only click the "Clear Browser Data" button if instructed to do so, as this action cannot be undone</span><br/><br/>
                <div>
                    <button className="primary-button-blue" style={{display:'inline-block', width:'250px', marginRight:'8px'}} onClick={ e => {
                        window.location.href = "/dashboard";
                    }}>Go Back Dashboard</button>
                    <button className="primary-button-red" style={{display:'inline-block', width:'250px'}} onClick={ e => {
                        DoStartClearing();
                    }}>Clear Browser Data</button>
                </div>
            </div>
        </div>
        { loading && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Clearing Browser Data</h4>
                    </div>
                    <div className="content">                        
                        <p style={{textAlign:'left'}}>This may take several minutes to complete, please wait.</p>
                        <p style={{textAlign:'left'}}>On step { loadingStep} of { totalSteps }</p>
                        {
                            loadingStep === totalSteps && <button className="primary-button-blue" onClick={ e => setLoading(false)}>Done</button>
                        }                      
                    </div>
                </div>
            </div>
        }
    </>
}