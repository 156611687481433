import React, { useState, useEffect } from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import { ThemeProvider } from '@emotion/react';
import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
import { createTheme } from '@mui/material';
import { FaPlay } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import './intro-videos-component.css';
import { Button, MenuItem, MenuProps } from '@mui/base';
import { alpha } from '@material-ui/core';


interface IntroVideosComponentProps {
    apiKey:string;
    marketing:any;
    onPreview:(label_id:number, video_url:string)=>void;
    onRecord:(marketing_id:number, callback: () => Promise<void>)=>void;    
}

export default function IntroVideosComponent(props:IntroVideosComponentProps){
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ toggle, setToggle ] = useState<boolean>(false);
    const [ lables, setLabels ] = useState<any[]>([]);
    const getVideos = async () => {
        setLabels([]);
        const response = await API.labels.getAll(props.apiKey, props.marketing.id);
        setLabels(response['labels']);
    }

    const dataGridColumns = [        
        {field: 'title',  headerName: 'actions', width: 450, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>
            <span>Title</span>
            <button style={{width:'150px', display:'inline-block', marginLeft:'24px'}} className='primary-button-blue' onClick={async ()=>{
                props.onRecord(props.marketing.id, async ()=>{
                    await getVideos();
                });
            }}>Add Video</button>
            </span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return row.title;
        }},
        {field: 'actions',  headerName: 'Actions', width: 400, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Actions</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                <button className='inline-primary-button-blue' onClick={ e => {
                    toast.info("Loading Preview");
                    props.onPreview(row.id, row.video_link);
                }} title="Preview Video"><FaPlay style={{transform:"translate(1px, 3px)"}}/></button>
                <button className='inline-primary-button-red' onClick={ async e => {
                    toast.info("Deleting Label");
                    const result = await API.labels.delete(props.apiKey, row.id);
                    const success = result['success'];
                    if(!success){
                        toast.error("Unable to Delete Seletected Label");
                        return;
                    }
                    toast.success("Label Deleted");
                    getVideos();
                }} title="Delete Video"><MdDelete style={{transform:"translate(-1px, 3px)", fontSize:"16px"}}/></button>
            </>
        }},
    ];
    const theme = createTheme({
        palette: {
          mode: 'light', // Force light mode
        },
    } as any);
    useEffect(()=>{
        if(!props.marketing) return;
        getVideos();
    }, [props.marketing]);

    useEffect(()=>{
        if(lables){
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [lables]);
    if(loading){
        return <div className="question-component-container">
            <div className="question-toggle">
                <div>
                    <span>Loading Introduction Videos</span>
                </div>
            </div>
        </div>;
    }
    return <div className="question-component-container">
        <div className="question-toggle">
            <div>
                <span>Introduction Videos</span><button onClick={ e => { setToggle( x => !x)}}>{`${toggle ? "Hide" : "Show"}`}</button>
            </div>
        </div>
        { toggle && <div id="intro-video-component-container">
            <ThemeProvider theme={theme}>
                <DataGrid
                rows={lables}
                columns={dataGridColumns}
                disableColumnMenu={true}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                getRowHeight={() => 'auto'}
                />
            </ThemeProvider>
        </div> }
    </div>
}