import React, { useEffect, useState } from "react";

import './MultiChoiceOptions.css';
import { toast } from "react-toastify";

export default function MultiChoiceOptions(props:any){
    const { selected, defaultValue, onChange } = props;
    const [ options, setOptions] = useState<any[]>([]);

    const handleOptionChange = (key: any, value: string) => {
        setOptions((prevOptions) => {
          const updatedOptions = prevOptions;
          updatedOptions.forEach(element => {
                if(element.id === key){
                    element.value = value;
                }
          });
          return updatedOptions;
        });
      };
    
      const handleDeleteOption = (key: any) => {
        if(selected.type === 1){
            toast("You cannot delete yes/no options");
            return;
        }
        setOptions((prevOptions) => {
            let updatedOptions = [...prevOptions];
            updatedOptions = updatedOptions.filter((v, i, a)=>{
                return v.id !== key;
            });
            return updatedOptions;
        });
      };
      
      const handleToggleAnswer = (key: any, z:any) => {
        setOptions((prevOptions) => {
            let updatedOptions = [...prevOptions];
            updatedOptions = updatedOptions.map((v, i, a)=>{
                if(key === v.id){
                    v.answer = z ? 1 : 0;
                }
                return v;
            });
            return updatedOptions;
        });
      };
    
      const addOption = () => {
        setOptions((prevOptions) =>{
            let ln = prevOptions.length;
            return [...prevOptions, { id: ln, value: ""}];
        });
      };

      useEffect(()=>{
        if(!defaultValue) return;
        let vs:any[] = [];
        console.log(defaultValue);
        JSON.parse(defaultValue).forEach((element, index) => {
            vs.push({
                id: index,
                value: element.value,
                answer: element.answer ? 1 : 0
            });
        });
        setOptions(vs);
      }, [])

    return <div className="multi-choice-container">
        <div style={{ maxHeight: '640px', overflowY:'scroll' }}>
            {
                options && options.map((option)=>{
                    return <div id={"option-" + option.id} key={"option-" + option.id + "-div"}>
                        <input readOnly={selected.type === 1} key={"option-btn-" + option.id + "-input"} className={option.answer ? "selected-answer" : ""} type="text" placeholder="Enter choice text" defaultValue={option.value} onChange={(e) => handleOptionChange(option.id, e.target.value)}/>
                        <button key={"option-btn-" + option.id + "-set-answer"} className="blue" onClick={() => handleToggleAnswer(option.id, !option.answer)}>
                            { option.answer ? "Incorrect Answer" : "Correct Answer"}
                        </button>
                        <button key={"option-btn-" + option.id + "-delete"} className="blue" onClick={() => handleDeleteOption(option.id)}>Delete</button>
                    </div>
                })
            }
            { selected.type !== 1 && <button className="blue add-option" onClick={addOption}>Add Option</button>}<br/><br/>
        </div>
        <button className="blue add-option" style={{ width:'97%' }} onClick={()=>{
            let ln = Object.keys(options).length;
            if(ln <= 0){
                toast.error("You cannot have an empty list of choices");
                return;
            }
            let filteredOptions = options.filter((o, i, a)=>{
                return o.value.length >= 1;
            });
            onChange(filteredOptions);
        }}>Save Changes</button>
    </div>
}