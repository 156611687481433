import React, { useState, useEffect, useRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
import jQuery from "jquery";
import $ from "jquery";
import './interviewFolder.css';
import API from '../../../services/api';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateTime } from 'luxon';
import { IoIosPaperPlane } from 'react-icons/io';
import { BsArrowDownUp, BsPencil, BsPersonPlusFill } from 'react-icons/bs';
import { RiShareForward2Fill } from 'react-icons/ri';
import { AiFillStar } from 'react-icons/ai';
import { FaRegCommentDots } from 'react-icons/fa';
import { MdClear, MdOutlineAssessment } from 'react-icons/md';
import { ImBin2 } from 'react-icons/im';

import play_icon_grey from "./../../../media/play_icon_grey.png";
import play_icon_color from "./../../../media/play_icon_color.png";

import assessment_icon_color from "./../../../media/assessment-blue.png";
import assessment_icon_grey from "./../../../media/assessment-grey.png";
import useAbortableAxios from '../../../hooks/useAbortableAxios';
import { CANDIDATE_PULLING_SECONDS } from './CANDIDATE_PULLING_SECONDS';

interface InterviewFolderProps {
    apiKey: string;
    loggedInUser: any;
    interview: any;
    interviewId: any;
    reloadInterview: () => void;
    showAssessmentPopUp: (survey_data:any) => void;
    addNewCandidateCallback: (callback: (data:any) => Promise<null | {
      created_at: null | string | Date
      email: null | string
      id: number
      interview_id:  null | number
      linkedin_url: null | string
      marketing_type: null | number
      name: null | string
      phone: null | string
      processing_gif: null | number
      processing_video: null | number 
      updated_at: null | string | Date 
  }>) => void;
    executeShowAddNewCandidateCallback: () => void;
    addEditCandidateCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeShowEditCandidateCallback: (data:any) => void;
    handleCandidateReviewCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleCandidateReviewCallback: (data:any) => void;
    candidateInvitePopupCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleCandidateInvitePopupCallback: (data:any) => void;
    handleNameEmailCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleNameEmailCallback: (data:any) => void;
    executeHandleCandidateComments: (data:any) => void;
}

interface CandidateEditInterface {
  id: number;
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}
interface CandidateAddInterface {
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}

const theme = createTheme({
    palette: {
      mode: 'light', // Force light mode
    },
} as any);

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        '&:hover': {
          color: '#1976D1'
        },
      },
    },
  }));

function InterviewFolderCandidateGrid({ loading, columns, rows, onPageChange, totalPages, pageSize, totalCandidates}) {
    return (<ThemeProvider theme={theme}>
    <DataGrid
      //loading={loading}
      paginationMode="server"
      rows={rows}
      columns={columns}
      disableColumnMenu={true}
      disableRowSelectionOnClick
      //onCellClick= {(param, event) => { event.defaultMuiPrevented = true; event.defaultPrevented = true; event.stopPropagation();}}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize
          }
        },
      }}
      pageSizeOptions={[pageSize]}
      onPaginationModelChange={(paginationModel) => {
        onPageChange(paginationModel.page);
      }}
      getRowId={(row) => row.id}
      getRowHeight={() => 'auto'}
      pagination
      rowCount={totalCandidates} //pageSize * totalPages}
    />
  </ThemeProvider>)
}

function ActionDropDownMenuForList({handleEdit, handleDelete, handleExtractAnswers, handleBulkInvite, handleShareOnly, handleShareAndRate, canExtract}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
        <MenuItem onClick={()=>{handleEdit(); handleClose();}} disableRipple>
          Edit
        </MenuItem>
          <MenuItem onClick={()=>{handleDelete(); handleClose();}} disableRipple>
            Delete
          </MenuItem>
          { canExtract && <MenuItem onClick={()=>{handleExtractAnswers(); handleClose();}} disableRipple>
            Extract Answers
          </MenuItem>}
          <MenuItem onClick={()=>{handleBulkInvite(); handleClose();}} disableRipple>
            Bulk Invite
          </MenuItem>
          <MenuItem onClick={()=>{handleShareOnly(); handleClose();}} disableRipple>
            Bulk Share Only
          </MenuItem>
          <MenuItem onClick={()=>{handleShareAndRate(); handleClose();}} disableRipple>
            Bulk Share and Rate
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }




export default function InterviewFolder({apiKey, loggedInUser, interview, interviewId, reloadInterview, showAssessmentPopUp, addNewCandidateCallback, executeShowAddNewCandidateCallback, addEditCandidateCallback, executeShowEditCandidateCallback, handleCandidateReviewCallback, executeHandleCandidateReviewCallback, candidateInvitePopupCallback, executeHandleCandidateInvitePopupCallback, handleNameEmailCallback, executeHandleNameEmailCallback, executeHandleCandidateComments}:InterviewFolderProps){
    // States
    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    const [ assessments, setAssessment ] = useState<any[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ candidates, setCandidates ] = useState<any[]>([]);
    const [ searchQuery, setSearchQuery ] = useState<string>("");
    const [ newSearchQuery, setNewSearchQuery ] = useState<string>("");
    const [ pageNumber, setPageNumber ] = useState<number>(0);
    const [ totalPages, setTotalPages ] = useState<number>(0);
    const [ totalCandidates, setTotalCandidates ] = useState<number>(0);
    const [ filterHasRating, setFilterHasRating ] = useState<boolean>(false);
    const [ filterNotRated, setFilterNotRated ] = useState<boolean>(false);
    const [ filterHasRecordings, setFilterHasRecordings ] = useState<boolean>(false);
    const [ filterWithoutRecordings, setFilterWithoutRecordings ] = useState<boolean>(false);
    const [ filterCompletedAssessment, setFilterCompletedAssessment ] = useState<boolean>(false);
    const [ filterWithoutAssessment, setFilterWithoutAssessment ] = useState<boolean>(false);
    const [ filterSortByAssessmentScore, setFilterSortByAssessmentScore ] = useState<boolean>(false);
    const { loading:apiLoading, data:apiData, error:apiError, fetchData:fetchAPIData, setLoading:setAPILoading} = useAbortableAxios();
    // Pulling
    const pullCandidatesIntervalRef = useRef<any>(null);
    // Functions
    const loadAssessments = async (api_key:string) => {
        let results = await API.surveys.list(api_key);
        setAssessment(results['results']);
    };
    const loadCandidates = async (api_key:string, interview_id:string, 
      xfilterHasRating:boolean = false, 
      xfilterNotRated:boolean = false, 
      xfilterHasRecordings:boolean = false,
      xfilterWithoutRecordings:boolean = false,
      xfilterCompletedAssessment:boolean = false, 
      xfilterWithoutAssessment:boolean = false,
      xfilterSortByAssessmentScore:boolean = false,
      xsearchQuery:string = "",
      xpageNumber:number = 0) => {        
      if(pullCandidatesIntervalRef.current){
        clearTimeout(pullCandidatesIntervalRef.current);
      }
      await fetchAPIData(API.baseURL + "/api/v1/candidate/getsearch", {}, {
        api_key: api_key,
        interview_id: interview_id,
        q: xsearchQuery,
        page: xpageNumber,
        hasRating: xfilterHasRating,
        hasNoRating: xfilterNotRated,
        hasRecordedVideos: xfilterHasRecordings,
        hasNoRecording: xfilterWithoutRecordings,
        hasAssessment: xfilterCompletedAssessment,
        hasNoAssessment: xfilterWithoutAssessment,
        sortByAssessment: xfilterSortByAssessmentScore
      }, "POST");
      pullCandidatesIntervalRef.current = setTimeout(()=>{
        loadCandidates(api_key, interview_id);
      }, CANDIDATE_PULLING_SECONDS * 1000);
    };
    const getScoreColor = (percentage:number) => {
        if(percentage >= 75){
          return "green";
        } else if(percentage >= 50){
          return "orange";
        } else {
          return "red";
        }
    }
    const getStars = (count:number) => {    
      let styles = {
        "--w": `${((count / 5) * 100).toString()}%`,
      };
      return <div className="stars2" style={styles as any}></div>;
    }
    const handleAssessmentPreview = (candidate:any, details:any)=>{
      showAssessmentPopUp({candidate:candidate, details:details})
    }
    const handleReviewOnClick = async (candidate:any)=>{
      toast.info("Loading");
      const result = await API.review.get(apiKey, candidate.id);
      const review_data = result['review'];
      handleCandidateReviewCallback(async (status:boolean): Promise<boolean> => {
          if(status){      
            toast.info("Reloading Candidates");
            loadCandidates(apiKey, interview.id);
          }
          return status;
      });
      executeHandleCandidateReviewCallback({ info: candidate, review: review_data});
    }
    const openCandidateEdit = (candidate:any) => {
      addEditCandidateCallback(async (data:CandidateEditInterface): Promise<boolean> => {
        if(!data.name.trim()){
          toast.error("Invalid Name Entered");
          return false;
        }
        if(!data.email.trim()){
          toast.error("Invalid Email Address Entered");
          return false;
        }
        toast.info("Updating Candidate");
        let result = await API.candidate.update(apiKey, data.id, data.name, data.email, data.linkedin, data.phone_number);
        let success = result["success"];
        if(!success){
          toast.error(result["message"]);
          return false;
        }
        toast.success("Candidate Updated");
        toast.info("Reloading Candidates");
        loadCandidates(apiKey, interview.id);
        return true;
      });
      executeShowEditCandidateCallback(candidate);
    }
    const openComments = (candidate:any) => {
      toast.info("Loading");
      executeHandleCandidateComments({
        candidate:candidate,
        apiKey: apiKey
      });
    }
    const openShareAndRate = (candidate:any) => {
        handleNameEmailCallback(async (data:any): Promise<boolean> => {
          toast.info("Loading Share Link");
          const fullname = data.fullname;
          const email = data.email;          
          let result = await API.shareAndRate.get(apiKey, candidate.id, interview.id, fullname, email);
          let candidateShareLink = result["link"];
          if(!candidateShareLink){
            toast.error("Error getting share link");
            return false;
          }
          candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
            if(!data.email.trim()){
              toast.error("Invalid Email Address Entered");
              return false;
            }
            if(!data.subject.trim()){
              toast.error("Invalid Subject Entered");
              return false;
            }
            if(!data.description.trim()){
              toast.error("Invalid Description");
              return false;
            }
            toast.info("Sending Email");
            let result2 = await API.email(apiKey, data.email, data.subject, data.description);
            let success2 = result2["success"];
            if(!success2){
              toast.error(result2["message"]);
              return false;
            }
            toast.success("Email Sent");
            return true;
          })
          executeHandleCandidateInvitePopupCallback({
            email: email,
            subject: "Candidate Share And Rate Invitation Link",
            description: candidateShareLink,
          });
          return true;
      })
      executeHandleNameEmailCallback({
        title: "Enter Manager Details for Share And Rate Review"
      });
    } 
    const handleDelete = async (candidate:any) => {
      toast.info("Deleting Candidate");
      let result = await API.candidate.delete(apiKey, candidate.id);
      if(!result['success']){
        toast.error("Error deleting candidate");
        return;
      }
      toast.success("Candidate Deleted Successfully");
      loadCandidates(apiKey, interview.id);
      toast.info("Reloading Candidates");
    }
    const openShare = (candidate:any) => {
      handleNameEmailCallback(async (data:any): Promise<boolean> => {
          toast.info("Loading Share Link");
          const fullname = data.fullname;
          const email = data.email;          
          let result = await API.share.get(apiKey, candidate.id, interview.id, fullname, email);
          let candidateShareLink = result["link"];
          if(!candidateShareLink){
            toast.error("Error getting share link");
            return false;
          }
          candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
            if(!data.email.trim()){
              toast.error("Invalid Email Address Entered");
              return false;
            }
            if(!data.subject.trim()){
              toast.error("Invalid Subject Entered");
              return false;
            }
            if(!data.description.trim()){
              toast.error("Invalid Description");
              return false;
            }
            toast.info("Sending Email");
            let result2 = await API.email(apiKey, data.email, data.subject, data.description);
            let success2 = result2["success"];
            if(!success2){
              toast.error(result2["message"]);
              return false;
            }
            toast.success("Email Sent");
            return true;
          })
          executeHandleCandidateInvitePopupCallback({
            email: email,
            subject: "Candidate Share Invitation Link",
            description: candidateShareLink,
          });
          return true;
      })
      executeHandleNameEmailCallback({
        title: "Enter Manager Details for Share Review"
      });
    }
    const openCandidateAssessmentInvite = async (candidate:any) => {
      toast.info("Loading");
      const result1 = await API.candidate.getInviteLink(apiKey, candidate.id);
      const candidateInvitationLink = result1["link"];
      if(!candidateInvitationLink){
        toast.error("Error Creating Candidate Invitation Link");
        return false;
      }
      const searchParams = new URLSearchParams(new URL(candidateInvitationLink).search);
      const b = searchParams.get("b");
      const candidateAssessmentLink = "https://api.clipdrop.io/api/v1/assessment/get?b=" + b + "&t=" + (new Date()).getTime();
      candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
        if(!data.email.trim()){
          toast.error("Invalid Email Address Entered");
          return false;
        }
        if(!data.subject.trim()){
          toast.error("Invalid Subject Entered");
          return false;
        }
        if(!data.description.trim()){
          toast.error("Invalid Description");
          return false;
        }
        toast.info("Sending Email");
        let result = await API.email(apiKey, data.email, data.subject, data.description);
        let success = result["success"];
        if(!success){
          toast.error(result["message"]);
          return false;
        }
        toast.success("Email Sent");
        return true;
      })
      executeHandleCandidateInvitePopupCallback({
        email: candidate.email,
        subject: "Candidate Assessment Link",
        description: candidateAssessmentLink,
      });
    }
    const openCandidateInterviewInvite = async (candidate:any) => {
      const result1 = await API.candidate.getInviteLink(apiKey, candidate.id);
      const candidateInvitationLink = result1["link"];
      candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
        if(!data.email.trim()){
          toast.error("Invalid Email Address Entered");
          return false;
        }
        if(!data.subject.trim()){
          toast.error("Invalid Subject Entered");
          return false;
        }
        if(!data.description.trim()){
          toast.error("Invalid Description");
          return false;
        }
        toast.info("Sending Email");
        let result = await API.email(apiKey, data.email, data.subject, data.description);
        let success = result["success"];
        if(!success){
          toast.error(result["message"]);
          return false;
        }
        let addResult = await API.marketing.campaign.addSendRateVacancy(apiKey, interview.id, 0);
        toast.success("Email Sent");
        return true;
      })
      executeHandleCandidateInvitePopupCallback({
        email: candidate.email,
        subject: "Candidate Invitation Link",
        description: candidateInvitationLink,
      });
    }
    
    const openExtractCandidateInterviewAnswers = async (candidate_ids:number[]) => {
      toast.info("Loading");
      let all_links = [];
      for(let i=0;i<candidate_ids.length;i++){
      const result1 = await API.candidate.getAllAnswers(apiKey, candidate_ids[i]);
        const links = result1["links"];
        links.forEach((a) => all_links.push(a));
      }
      let answerLinks = all_links.join("\n");
      candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
        if(!data.email.trim()){
          toast.error("Invalid Email Address Entered");
          return false;
        }
        if(!data.subject.trim()){
          toast.error("Invalid Subject Entered");
          return false;
        }
        if(!data.description.trim()){
          toast.error("Invalid Description");
          return false;
        }
        toast.info("Sending Email");
        let result = await API.email(apiKey, data.email, data.subject, data.description);
        let success = result["success"];
        if(!success){
          toast.error(result["message"]);
          return false;
        }
        toast.success("Email Sent");
        return true;
      })
      executeHandleCandidateInvitePopupCallback({
        email: "",
        subject: "Candidate Answers Links",
        description: answerLinks,
      });
    }
    const openBulkCandidateInterviewInvite = async (candidate_ids:number[]) => {
      toast.info("Loading");
      let links = "";
      for(let i=0;i<candidate_ids.length;i++){
      const result1 = await API.candidate.getInviteLink(apiKey, candidate_ids[i]);
        const candidateInvitationLink = result1["link"];
        links += candidateInvitationLink + "\n";
      }
      candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
        if(!data.email.trim()){
          toast.error("Invalid Email Address Entered");
          return false;
        }
        if(!data.subject.trim()){
          toast.error("Invalid Subject Entered");
          return false;
        }
        if(!data.description.trim()){
          toast.error("Invalid Description");
          return false;
        }
        toast.info("Sending Email");
        let result = await API.email(apiKey, data.email, data.subject, data.description);
        let success = result["success"];
        if(!success){
          toast.error(result["message"]);
          return false;
        }
        for(let i=0;i<candidate_ids.length;i++){
          let addResult = await API.marketing.campaign.addSendRateVacancy(apiKey, interview.id, 0);
        }
        toast.success("Email Sent");
        return true;
      })
      executeHandleCandidateInvitePopupCallback({
        email: "",
        subject: "Candidate Invitation Link",
        description: links,
      });
    }
    const openBulkCandidateShare = async (candidate_ids:number[]) => {
      handleNameEmailCallback(async (data:any): Promise<boolean> => {
          toast.info("Loading");
          let links = "";
          for(let i=0;i<candidate_ids.length;i++){
            toast.info("Loading Share Link");
              const fullname = data.fullname;
              const email = data.email;          
              let result = await API.share.get(apiKey, candidate_ids[i], interview.id, fullname, email);
              let candidateShareLink = result["link"];
              if(!candidateShareLink){
                toast.error("Error getting share link");
                return false;
              }
              links += candidateShareLink + "\n";
          }
          candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
            if(!data.email.trim()){
              toast.error("Invalid Email Address Entered");
              return false;
            }
            if(!data.subject.trim()){
              toast.error("Invalid Subject Entered");
              return false;
            }
            if(!data.description.trim()){
              toast.error("Invalid Description");
              return false;
            }
            toast.info("Sending Email");
            let result = await API.email(apiKey, data.email, data.subject, data.description);
            let success = result["success"];
            if(!success){
              toast.error(result["message"]);
              return false;
            }
            for(let i=0;i<candidate_ids.length;i++){
              let addResult = await API.marketing.campaign.addSendRateVacancy(apiKey, interview.id, 0);
            }
            toast.success("Email Sent");
            return true;
          })
          executeHandleCandidateInvitePopupCallback({
            email: "",
            subject: "Candidate Invitation Link",
            description: links,
          });
          return true;
      })
      executeHandleNameEmailCallback({
        title: "Enter Manager Details for Share Review"
      });
    }
    const openBulkCandidateShareAndRate = async (candidate_ids:number[]) => {
      handleNameEmailCallback(async (data:any): Promise<boolean> => {
          toast.info("Loading");
          let links = "";
          for(let i=0;i<candidate_ids.length;i++){
            toast.info("Loading Share Link");
              const fullname = data.fullname;
              const email = data.email;          
              let result = await API.shareAndRate.get(apiKey, candidate_ids[i], interview.id, fullname, email);
              let candidateShareLink = result["link"];
              if(!candidateShareLink){
                toast.error("Error getting share link");
                return false;
              }
              links += candidateShareLink + "\n";
          }
          candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
            if(!data.email.trim()){
              toast.error("Invalid Email Address Entered");
              return false;
            }
            if(!data.subject.trim()){
              toast.error("Invalid Subject Entered");
              return false;
            }
            if(!data.description.trim()){
              toast.error("Invalid Description");
              return false;
            }
            toast.info("Sending Email");
            let result = await API.email(apiKey, data.email, data.subject, data.description);
            let success = result["success"];
            if(!success){
              toast.error(result["message"]);
              return false;
            }
            for(let i=0;i<candidate_ids.length;i++){
              let addResult = await API.marketing.campaign.addSendRateVacancy(apiKey, interview.id, 0);
            }
            toast.success("Email Sent");
            return true;
          })
          executeHandleCandidateInvitePopupCallback({
            email: "",
            subject: "Candidate Invitation Link",
            description: links,
          });
          return true;
      })
      executeHandleNameEmailCallback({
        title: "Enter Manager Details for Share Review"
      });
    }
    // Data Columns
    const dataGridColumns = [
        {field: 'candidate_selection', headerName: 'Candidates', width: 380, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return <>
                <ActionDropDownMenuForList 
                    handleEdit={async ()=>{
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates:checked');
                      if(candidatesSelected.length <= 0){
                        toast.info("You need to select 1 candidate");
                        return;
                      }
                      if(candidatesSelected.length > 1){
                        toast.info("You need to select only 1 candidate");
                        return;
                      }
                      toast.info("Loading");
                      const candidate_id = parseInt(candidatesSelected[0].getAttribute('candidate-id'));
                      const result = await API.candidate.get(apiKey, candidate_id);
                      openCandidateEdit(result['candidate']);
                    }}
                    handleDelete={async ()=>{
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates:checked');
                      if(candidatesSelected.length <= 0){
                        toast.info("You need to select at least 1 candidate");
                        return;
                      }
                      toast.info("Loading");
                      const candidate_ids = Array.from(candidatesSelected).map((x:any)=>{ return parseInt(x.getAttribute('candidate-id'))});
                      const result = await API.candidate.deleteAll(apiKey, candidate_ids);
                      toast.success("Candidates Deleted");
                    }}
                    handleExtractAnswers={async ()=>{
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates[data-candidate-review="true"]:checked');
                      if(candidatesSelected.length <= 0){
                        toast.info("You need to select at least 1 candidate that has completed the interview");
                        return;
                      }
                      toast.info("Loading");
                      const candidate_ids = Array.from(candidatesSelected).map((x:any)=>{ return parseInt(x.getAttribute('candidate-id'))});
                      openExtractCandidateInterviewAnswers(candidate_ids);
                    }}
                    handleBulkInvite={()=>{
                      toast.info("Loading");
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates:checked');                      
                      const candidate_ids = Array.from(candidatesSelected).map((x:any)=>{ return parseInt(x.getAttribute('candidate-id'))});
                      openBulkCandidateInterviewInvite(candidate_ids);
                    }}
                    handleShareOnly={()=>{
                      // Select All Candidates That can be reviewed
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates[data-candidate-review="true"]:checked');
                      const candidate_ids = Array.from(candidatesSelected).map((x:any)=>{ return parseInt(x.getAttribute('candidate-id'))});
                      openBulkCandidateShare(candidate_ids);
                    }}
                    handleShareAndRate={()=>{
                      // Select All Candidates That can be reviewed
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates[data-candidate-review="true"]:checked');
                      const candidate_ids = Array.from(candidatesSelected).map((x:any)=>{ return parseInt(x.getAttribute('candidate-id'))});
                      openBulkCandidateShareAndRate(candidate_ids);
                    }}
                    canExtract={!!loggedInUser?.canExtract}
                />
                <span style={{ color:'#E1E1E1 !important', marginLeft:'32px', fontSize:'12pt'}}>Candidates</span>
            </>;
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                <input
                    type="checkbox"
                    candidate-id={row.id}
                    data-candidate-review={row.has_review ? true : false}
                    className="interview_candidates"
                    style={{ transform: "scale(1.5);" }}
                />
                {row.profile_image &&
                    row.has_review ? (
                        <div
                        className="newCandidateUserIcon2 make-larger"
                        style={{
                            backgroundImage:
                            "url(" + row.profile_image + ")"
                        }}
                    ></div>
                ) : (
                    <div className="newCandidateUserIcon2"></div>
                )}
                <div className='new-candidate-info'>
                    <span><strong>{ row.name }</strong></span>
                    <span>{ row.email }</span>
                    <span>{ row.phone ?? "" }</span>
                </div>
            </>;
        }},
        {field: 'candidate_review',  headerName: 'Review', width: 82, sortable: false,
          renderHeader: (params: GridColumnHeaderParams) => {
              return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Review</span>
          },
         renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                {row.has_review ? (
                    row.rating ? (
                        <button
                            className="newActionPlayColor"
                            candidate-id={row.id}
                            onClick={(e:any)=>{handleReviewOnClick(row)}}
                            title="Review candidate (No rating option available)"
                        ></button>
                    ) : (
                        <button
                            className="newActionPlayColor"
                            candidate-id={row.id}
                            onClick={(e:any)=>{handleReviewOnClick(row)}}
                            title="Review candidate"
                        ></button>
                    )
                    ) : (
                    <button
                        className="newActionPlayGrey"
                        title="No review available"
                    ></button>
                    )}
                </>
        }},
        {field: 'candidate_assessment',  headerName: 'Assessment', width: 120, sortable: false,
          renderHeader: (params: GridColumnHeaderParams) => {
            return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Assessment</span>
          },
          renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                {
                row.hasAssessment ? (
                    <button
                    className="newActionAssessmentColor"
                    title="Assessment available"
                    onClick={ async e => {
                        // TODO: LOAD ASSESSMENT RESULTS
                        toast("Loading");
                        let response = await fetch(API.baseURL + "/api/v1/assessment/load", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            api_key: localStorage.getItem("api_key") ?? "",
                            candidate_id: row.id,
                        }),
                        });
                        let data = await response.json();
                        let details = {
                          "candidate": row,
                          "survey": data['survey'],
                          "score": parseInt(data['score'])
                        };  
                        handleAssessmentPreview(row, details);                                  
                }}>
                    <span style={{ borderBottom: `3px solid ${getScoreColor((row.assessmentScore / row.assessmentTotal) * 100)}`}}>{(row.assessmentScore / row.assessmentTotal) * 100}%</span>
                </button>
                ) : (
                    <button
                    className="newActionAssessmentGrey"
                    title="No assessment available"
                    ></button>
                )
                }
            </>;
        }},
        {field: 'candidate_id',  headerName: 'ID', width: 64, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>ID</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return `${row.id}`;
        }},
        {field: 'candidate_rating',  headerName: 'Rating', width: 146, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Rating</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return getStars(row.rating);
        }},
        {field: 'candidate_sign_up_date',  headerName: 'Sign Up Date', width: 150, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Sign Up Date</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            const dt = row.created_at;
            if (!dt) return "";
            let d = DateTime.fromISO(dt).toFormat("dd-MM-yyyy HH:mm");
            if (d == "Invalid DateTime") {
                return DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat(
                    "dd-MM-yyyy HH:mm"
                );
            } else {
                return d;
            }
        }},
        {field: 'candidate_actions',  headerName: 'Actions', width: 250, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Actions</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                <BsPencil
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    title="Edit Candidate"
                    onClick={()=>{openCandidateEdit(row)}}
                />
                <IoIosPaperPlane
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    candidate-email={row.email}
                    title="Invite Candidate For Interview"
                    onClick={()=>{openCandidateInterviewInvite(row);}}
                />
                { (interview.canSurvey === 1 && !!interview.canSurveyID) ? <MdOutlineAssessment
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    candidate-email={row.email}
                    title="Invite Candidate For Assessment"
                    onClick={()=>{openCandidateAssessmentInvite(row);}}
                /> : <></>}
                <ImBin2 className="candidateMiniButton withspace" title="Delete Candidate" onClick={()=>{handleDelete(row);}}/>
                {row.has_review ? <RiShareForward2Fill
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    onClick={()=>{openShare(row);}}
                    title="Share"
                /> : <></>}
                {row.has_review ? <AiFillStar
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    onClick={()=>{openShareAndRate(row);}}
                    title="Share and Rate"
                /> : <></>}
                {(row.has_review && row.had_comment) ? <FaRegCommentDots
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    onClick={()=>{openComments(row);}}
                    title="View Comments"
                /> : <></>}
                {(row.has_review && !row.had_comment) ? <FaRegCommentDots
                    className="candidateMiniButton greyOut withspace"
                    candidate-id={row.id}
                    onClick={()=>{toast.info("No Comments Available");}}
                    title="View Comments"
                /> : <></>}
            </>;
        }},
    ];
    // Effect
    useEffect(()=>{
        if(!apiKey) return;
        loadAssessments(apiKey);
    }, [apiKey]);

    useEffect(()=>{
      if(!apiKey) return;
      if(!interview) return;
      loadCandidates(
        apiKey,
        interviewId,
        filterHasRating, 
        filterNotRated, 
        filterHasRecordings,
        filterWithoutRecordings,
        filterCompletedAssessment, 
        filterWithoutAssessment,
        filterSortByAssessmentScore,
        searchQuery, 
        pageNumber);
    }, [
      apiKey,
      interviewId,
      filterHasRating, 
      filterNotRated, 
      filterHasRecordings,
      filterWithoutRecordings,
      filterCompletedAssessment, 
      filterWithoutAssessment,
      filterSortByAssessmentScore,
      searchQuery,
      pageNumber
    ]);

    useEffect(()=>{
      if(!apiData) return;
      setCandidates(apiData["candidates"]);
      setTotalPages(apiData["totalPages"]);
      setTotalCandidates(apiData["totalCandidates"]);
    }, [apiData])
    
    useEffect(() => {
      return () => {
        if(pullCandidatesIntervalRef.current){
          clearTimeout(pullCandidatesIntervalRef.current);
        }
      };
    }, []);
    
    // Renders
    if(!interview){
        return <div className='interview-folder-container'>Loading Folder Data</div>
    }
    return <><div className='interview-folder-container'>
        <div className='nice-form-2'>
            {/*<h4>Settings&nbsp;&nbsp;<button className='button-primary-blue' onClick={ e => setShowSettings(x => !x)}>{ showSettings ? "Hide" : "Show"}</button></h4>*/}
            {/*showSettings && <>*/}
            <div className='greyed full-width'>
                <label htmlFor="enable-self-signup">
                <span>Enable Self Signup</span>
                </label>
                <select name="enable-self-signup" id="enable-self-signup" defaultValue={interview.canSelfSignUp.toString()} onChange={ async e => {
                    toast.info("Updating Self Signup Access");
                    let stateValueAsNumber = e.target.value === "1" ? 1 : 0;
                    let result = await API.interview.setSelfSignUp(apiKey, interview.id, stateValueAsNumber);
                    toast.success("Updated");
                    reloadInterview();
                }}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                {
                    interview.canSelfSignUp === 1 && <>
                    
                    <label htmlFor="enable-self-signup">
                        <span>Self Signup Link</span>
                    </label>
                    <input
                    type="text"
                    value={
                    "https://api.clipdrop.io/v1/api/signup/" +
                    interview.id +
                    "/" +
                    interview.title.replaceAll(" ", "-")
                    }
                    readOnly
                />
                </>}
            </div>
            <div className='greyed full-width'>
                <label htmlFor="enable-assessment">
                    <span>Enable Assessment</span>
                </label>
                <select name="enable-assessment" id="enable-assessment" defaultValue={interview.canSurvey.toString()} onChange={ async e => {
                    toast.info("Updating Assessment Access");
                    let stateValueAsNumber = e.target.value === "1" ? 1 : 0;
                    let result = await API.interview.setSurveyEnabled(apiKey, interview.id, stateValueAsNumber);
                    toast.success("Updated");
                    reloadInterview();
                }}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                { interview.canSurvey === 1 && <><label htmlFor="select-assessment">
                    <span>Select An Assessment to assign</span>
                </label>
                <select name="select-assessment" id="select-assessment" placeholder='Select An Assessment' defaultValue={interview.canSurveyID.toString()} onChange={ async e => {
                    toast.info("Updating Assessment Access");
                    let stateValueAsNumber = parseInt(e.target.value);
                    if(stateValueAsNumber <= 0){
                        toast.error("Invalid Assessment Selected");
                        return;
                    }
                    let result = await API.interview.setSurveyValue(apiKey, interview.id, stateValueAsNumber);
                    toast.success("Updated");
                    reloadInterview();
                }}>
                    <option value="0" disabled={true} selected={true}>Select An Assessment</option>
                    {
                        (assessments && assessments.length >= 1) && assessments.map((assessment, index)=>{
                            return <option value={assessment.id.toString()}>{assessment.title}</option>
                        })
                    }
                </select></>}
            </div>
            {/*</>*/}
        </div>
        <div style={{ height: '100%', width: '100%'}}>
            <div className='candidate-header'>
                <div className='candidate-total'>
                    <h4>Candidates</h4>
                    <span>{`${totalCandidates} Candidate${totalCandidates > 1 ? "s" : ""}`}</span>                    
                </div>
                <div className='candidate-search-container'>
                    <input type="text" placeholder='Search' value={newSearchQuery} onChange={ e => { setNewSearchQuery(e.target.value)}}/>
                    <button className='button-primary-blue' onClick={ e => {
                        setSearchQuery(newSearchQuery);
                    }}>Search</button>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  <div
                    onClick={()=>{                      
                      setFilterHasRating(false);
                      setFilterNotRated(false);
                      setFilterHasRecordings(false);
                      setFilterWithoutRecordings(false);
                      setFilterCompletedAssessment(false);
                      setFilterWithoutAssessment(false);
                      setFilterSortByAssessmentScore(false);
                    }}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Clear Filters"
                  >
                    <MdClear style={{ transform: "translate(-4px, 2px)" }} />
                  </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterHasRecordings( x => !x);
                    }}
                    className={"filterbutton" + (filterHasRecordings ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Candidates with recorded videos"
                  >
                    <img
                      src={play_icon_color}
                      alt=""
                      style={{
                        transform: "translate(-3px, 2px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterHasRating( x => !x);
                    }}
                    className={"filterbutton" + (filterHasRating ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates with ratings"
                  >
                    <AiFillStar
                      color="#FFE136"
                      style={{
                        transform: "translate(-4px, 1px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterNotRated( x => !x);
                    }}
                    className={"filterbutton" + (filterNotRated ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates without a rating"
                  >
                    <AiFillStar
                      style={{
                        transform: "translate(-5px, 2px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;&nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterWithoutRecordings( x => !x);
                    }}
                    className={"filterbutton" + (filterWithoutRecordings ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates with no recorded videos"
                  >
                    <img
                      src={play_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;&nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                &nbsp;
                <div
                    onClick={()=>{
                      setFilterCompletedAssessment( x => !x);
                    }}
                    className={"filterbutton" + (filterCompletedAssessment ? " active-filter" : "")}
                  style={{ transform: "translateY(0px)" }}
                  title="Candidates with completed assessment"
                >
                  <img
                    src={assessment_icon_color}
                    alt=""
                    style={{
                      transform: "translate(-3px, 3px)",
                      width: "18px",
                      height: "16px",
                      pointerEvents: "none",
                    }}
                  />
                </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterWithoutAssessment( x => !x);
                    }}
                    className={"filterbutton" + (filterWithoutAssessment ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates who did not complete an assessment"
                  >
                    <img
                      src={assessment_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;<div
                    onClick={()=>{
                      setFilterSortByAssessmentScore( x => !x);
                    }}
                    className={"filterbutton" + (filterSortByAssessmentScore ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Order by assessment score (highest to lowest)"
                  >
                    <BsArrowDownUp
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
                <button className='button-primary-blue large' onClick={ e => {  
                      toast.info("Reloading Candidates");
                      loadCandidates(apiKey, interview.id);
                }}>Refresh Candidates</button>
                <button className='button-primary-blue large' style={{marginLeft:'0px'}} onClick={ e => {
                    addNewCandidateCallback(async (data:CandidateAddInterface): Promise<null | {
                      created_at: null | string | Date
                      email: null | string
                      id: number
                      interview_id:  null | number
                      linkedin_url: null | string
                      marketing_type: null | number
                      name: null | string
                      phone: null | string
                      processing_gif: null | number
                      processing_video: null | number 
                      updated_at: null | string | Date 
                  }> => {
                      if(!data.name.trim()){
                        toast.error("Invalid Name Entered");
                        return null;
                      }
                      /*if(!data.email.trim()){
                        toast.error("Invalid Email Address Entered");
                        return null;
                      }*/
                      toast.info("Creating Candidate");
                      let result = await API.candidate.create(apiKey, interview.id, data.name, data.email, data.linkedin, data.phone_number, 0, 0, 1);
                      let success = result["success"];
                      if(!success){
                        toast.error(result["message"]);
                        return null;
                      }
                      toast.success("Candidate Created Successfully");
                      toast.info("Reloading Candidates");
                      loadCandidates(apiKey, interview.id);
                      return result.candidate;
                    });
                    executeShowAddNewCandidateCallback();
                }}><BsPersonPlusFill />&nbsp;&nbsp;Add Candidate</button>                
            </div>
            <InterviewFolderCandidateGrid loading={apiLoading} columns={dataGridColumns} rows={candidates} onPageChange={ (p:number) => setPageNumber(p)} pageSize={25} totalPages={totalPages} totalCandidates={totalCandidates}/>
        </div>
    </div>
    </>;
}

/*

                        toast.success("Candidate Created Successfully");
                        toast.info("Reloading Candidates");
                        loadCandidates(apiKey, interview.id);
*/