import React, { useRef, useEffect, useState } from "react";
import API from "../../services/api";
import { toast } from "react-toastify";
export default function UserAllocationComponent({apiKey, interview}:{apiKey:string, interview:any}) {
    // State
    const [ showAllocation, setShowAllocation ] = useState<boolean>(false); 
    const [ allOrgUsers, setAllOrgUsers ] = useState<any[]>([]);
    const [ allSelectedOrgUsers, setAllSelectedOrgUsers ] = useState<any[]>([]);
    // Functions
    const loadUsers = async(api_key:string, interview_id:string) => {
        let result = await API.interview.getSeats(api_key, interview_id.toString());
        setAllOrgUsers(result['users']);
        setAllSelectedOrgUsers(result['selected']);
    }
    // Effect
    useEffect(()=>{
        if(!apiKey) return;
        if(!interview) return;
        loadUsers(apiKey, interview.id);
    }, [apiKey, interview]);
    // Render
    return <>
        <button onClick={ async e => {
            if(!showAllocation){   
                toast.info("Loading Users");
                await loadUsers(apiKey, interview.id);
            }
            setShowAllocation(x => !x);
        }} className="button-primary-blue">Allocate Users</button>
        {
            showAllocation && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>Select users to have access to this vacancy&nbsp;&nbsp;</h4>
                        <button onClick={ e => {
                            setShowAllocation(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <ul style={{ listStyle:'none', margin:'0', padding:'0'}}>
                            {
                                allOrgUsers.map((user:any, index:number)=>{
                                    return <li key={user.id}>
                                        <input key={`input_${user.id}`} style={{ cursor:'pointer' }} type="checkbox" defaultChecked={allSelectedOrgUsers.includes(user.id)} onChange={ e => {
                                            let checked = e.target.checked ? true : false;
                                            if(checked){
                                                setAllSelectedOrgUsers( x => {
                                                    x.push(user.id);
                                                    return x;
                                                });
                                            } else {
                                                setAllSelectedOrgUsers( x => {
                                                    return x.filter(y => y !== user.id);
                                                });
                                            }
                                        }}/>
                                        <span>{ user.name } - ({ user.id })</span>
                                    </li>
                                })
                            }
                        </ul>
                        <div className="actions2">                            
                            <button onClick={ async e => {
                                toast.info("Saving");
                                let result = await API.interview.setSeats(apiKey, interview.id, Array.from(new Set(allSelectedOrgUsers)));
                                toast.info("Saved");
                                setShowAllocation(false);
                            }} className="button-primary-blue">Save</button>
                            <button onClick={ e => {
                                setShowAllocation(false);
                            }} className="button-primary-blue">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}