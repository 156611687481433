import React, { useState, useEffect } from "react";

import API from '../services/api';

import "./mystorageContainer.css";

export default function MyStorageComponent() {    
    const [storage, setStorage] = useState({
        total: 0.00,
        used: 0.00
    });
    const [color, setColor] = useState("#1976D1");
    const [color2, setColor2] = useState("#1976D1");
    async function loadStorageData(){
        let api_key = localStorage.getItem('api_key');
        if(!api_key){ window.location.href = "/login"; return;}
        let result = await API.storage.getMyStorage(api_key);
        setStorage({
            total: result["total"],
            used: result["used"]
        })
        let deg = getDeg();
        if(deg <= 180){
            setColor("#32CD32"); // green
            setColor2("#ce0018"); // red
        } else if(deg <= 260){
            setColor("#e3622f"); // orange
            setColor2("#e3622f"); // orange
        } else {
            setColor("#32CD32"); // green
            setColor2("#ce0018"); // green
        }
    }

    

    useEffect(()=>{
        loadStorageData();
    }, []);

    function getPaddedNumber(n){
        let a = n.toFixed(2).split('.');
        return `0000${a[0]}`.slice(-4) + "." + a[1];
    }

    function getDeg(){
        let u = storage.used < storage.total ? storage.used : storage.total;
        let t = storage.total;
        let x = u/t;
        if(x === 1 || (u === 0 && t === 0)){return 359.9;}
        return x * 360;
    }

    function getDeg2(){
        let u = 0;       
        let t = storage.total - storage.used;
        t = t < 0 ? 0 : t;
        let x = u/t;
        if(x === 1 || (storage.used === 0 && storage.total === 0)){return 359.9;}
        let r = ((x + getDeg() / 360) * 360);
        return r;
    }

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    };

    const describeSlice = (x, y, radius, startAngle, endAngle) => {
        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);    
        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
        const d = ["M", 0, 0, start.x, start.y,"A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");    
        return d;
    };

    const path = (degrees = 90, radius = 10) => {
        return describeSlice(0, 0, radius, 0, degrees) + 'Z';
    };

    function getText(){
        let deg = getDeg();
        if(deg < 180){
            return "Storage Status: Healthy";
        } else if(deg < 260){
            return "Storage Status: Warning";
        } else {
            return "Storage Status: Bad";
        }
    }
    function getColor(){
        let deg = getDeg();
        if(deg < 180){
            return "#32CD32";
        } else if(deg < 260){
            return "#e3622f";
        } else {
            return "#ce0018";
        }
    }

    let avaliableStorage = storage.total - storage.used;
    avaliableStorage = avaliableStorage < 0 ? 0 : avaliableStorage;

    return (
        <div className="mystorageContainer">
            <h1>My Storage</h1>
            <h4>Available and used space.</h4>
            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                <title>My Storage</title>
                <circle cx="32" cy="32" r="24" fill="#32CD32" />
                <path d={path(getDeg2(), 24)} fill={'#FFA500'} className="repositionPath"/>
                <path d={path(getDeg(), 24)} fill={color2} className="repositionPath"/>
                <circle cx="32" cy="32" r="18" fill="#FFFFFF" />
                <text x="0" y="32" className="mystorageContainerText">{getPaddedNumber(avaliableStorage)}GB</text>
                <text x="0" y="32" className="mystorageContainerText2">Free Space</text>                    
            </svg>
            <span style={{fontSize:'6pt', marginBottom:'8px', display:'block', textAlign:'center'}}>
                <span title="Free Space is displayed in Green" style={{color:"#FFF", backgroundColor:"#32CD32", padding:'4px', margin:'0px 2px', borderRadius:'8px', display:'inline-block'}}>Free Space</span>
                <span title="Used Space is displayed in Red" style={{color:"#FFF", backgroundColor:"#ce0018", padding:'4px', margin:'0px 2px', borderRadius:'8px', display:'inline-block'}}>Used Space</span>
                <span title="Reserved Space is displayed in Orange" style={{color:"#FFF", backgroundColor:"#FFA500", padding:'4px', margin:'0px 2px', borderRadius:'8px', display:'inline-block'}}>Reserved Space</span>
            </span>
            <div className="storageStatus" style={{backgroundColor: getColor()}}>{getText()}</div>
        </div>
    );
}