import React from 'react';

import { IoMdAdd } from 'react-icons/io';

import './MultiOptionNewItem.css';


export interface MultiOptionNewItemProps {
    onClick: () => void
}

export default function MultiOptionNewItem(props:MultiOptionNewItemProps){
    const { onClick } = props;
    return (
        <div className='MultiOptionContainerNewItem'>
            <button className="add-multi-choice-button" onClick={()=>{
                onClick();
            }}>
                <span>Add Choice</span><IoMdAdd/>
            </button>
        </div>
    )       
}