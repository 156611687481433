import React, { useRef, useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import UpperBarComponent from "../components/upperbar";
import SideBarComponent from "../components/sidebar";
import './marketingviewpagev2.css';
import AnalyticsComponent from "./marketing-components/analytics-component";
import IntroVideosComponent from "./marketing-components/intro-videos-component";
import MarketingFolderComponent from "./marketing-components/marketing-folder-component";
import MarketingHeaderComponent from "./marketing-components/marketing-header-component";
import useMarketingHook from "./marketing-components/useMarketingHook";
import { toast } from 'react-toastify';
import InBrowserRecorderAndProcessingComponent from "../components/inBrowserRecorderAndProcessingComponent/InBrowserRecorderAndProcessingComponent";
import API from "../services/api";
import VideoUploadComponent from "../components/videoupload";
import InBrowserRecorderAndProcessingComponentV2 from "../components/inBrowserRecorderAndProcessingComponentV2/InBrowserRecorderAndProcessingComponentV2";
import InBrowserGifFromVideoComponent from "../components/InBrowserGifFromVideoComponent/InBrowserGifFromVideoComponent";
import useAbortableAxios from "../hooks/useAbortableAxios";
import InBrowserLinkedInVideoProcessor from "../components/inBrowserLinkedInVideoProcessor/inBrowserLinkedInVideoProcessor";
export default function MarketingViewPageV2(props) {
    // State
    const [ user, setUser ] = useState<any>(null);
    const apiKey:string = localStorage.getItem("api_key") ?? "";
    let { id:marketing_id } = useParams();
    const location = useLocation();
    // API Callers
    const { loading:apiMarketingCustomTextLoading, data:apiMarketingCustomTextData, error:apiMarketingCustomTextError, fetchData:fetchAPIMarketingCustomTextData, setLoading:setAPIMarketingCustomTextLoading} = useAbortableAxios();
    const { loading:apiWebsiteLoading, data:apiWebsiteData, error:apiWebsiteError, fetchData:fetchWebsite, setLoading:setAPIWebsiteLoading} = useAbortableAxios();
    const { loading:apiMarketingTextLoading, data:apiMarketingTextData, error:apiMarketingTextError, fetchData:fetchAPIMarketingTextData, setLoading:setAPIMarketingTextLoading} = useAbortableAxios();
    const { marketing, marketingID, reloadMarketing, updateMarketingTitle, updateMarketing } = useMarketingHook(apiKey, marketing_id);    
    const [ ourWebsite, setOurWebsite ] = useState<string>("");
    const [ showLabelPreview, setShowLabelPreview] = useState<boolean>(false);
    const [ showLabelPreviewID, setShowLabelPreviewID] = useState<number>(0);
    const [ showLabelPreviewVideoURL, setShowLabelPreviewVideoURL] = useState<string>("");
    const [ showLabelRecord, setShowLabelRecord] = useState<boolean>(false);
    const [ showLabelRecordCallback, setShowLabelRecordCallback] = useState<null | (() => Promise<void>)>(null);
    const [ showLabelRecordState, setShowLabelRecordState] = useState<string>("options");
    const [ showLabelRecordTitle, setShowLabelRecordTitle] = useState<string>("");
    const [ showLabelRecordMarketingID, setShowLabelRecordMarketingID] = useState<number>(0);
    const [ defaultFolder, setDefaultFolder ] = useState<string>("");
    // Facebook Share
    const [ showFacebookShare, setShowFacebookShare ] = useState<boolean>(false);
    const [ showFacebookSelectedPages, setShowFacebookSelectedPages ] = useState<any[]>([]);
    const [ showFacebookSharePages, setShowFacebookSharePages ] = useState<any[]>([]);
    const [ showFacebookShareCallback, setShowFacebookShareCallback ] = useState<null | ((pages_data:any[]) => Promise<boolean>)>(null);
    // LinkedIn Share
    const [ showLinkedInVideoShare, setShowLinkedInVideoShare] = useState<boolean>(false);
    const [ showLinkedInVideoShareVideoURL, setShowLinkedInVideoShareVideoURL ] = useState<string>("");
    const [ showLinkedInVideoShareUserAccessToken, setShowLinkedInVideoShareUserAccessToken ] = useState<string>("");
    const [ showLinkedInVideoShareUserID, setShowLinkedInVideoShareUserID ] = useState<string>("");
    const [ showLinkedInVideoShareCompleteFunction, setShowLinkedInVideoShareCompleteFunction ] = useState<null | (() => Promise<void>)>(null);
    
    // Create Lead
    const [ showCreateLead, setShowCreateLead ] = useState<boolean>(false);
    const [ showCreateLeadName, setShowCreateLeadName ] = useState<string>("");
    const [ showCreateLeadEmail, setShowCreateLeadEmail ] = useState<string>("");
    const [ showCreateLeadPhone, setShowCreateLeadPhone ] = useState<string>("");
    const [ showCreateLeadLinkedInURL, setShowCreateLeadLinkedInURL ] = useState<string>("");
    const [ showCreateLeadGifCreation, setShowCreateLeadGifCreation ] = useState<boolean>(false);
    const [ createLeadResult, setCreateLeadResult ] = useState<null | {
        closed:boolean;
        copied:number;
        created_at: null | string | Date
        email: null | string
        custom_video_url: string | null;
        hasViewed:boolean;
        viewTime:string;
        id: number
        marketing_id:  null | number
        linkedin_url: null | string
        marketing_type: null | number
        marketingType_id: null | number;
        marketing_thumbnail: null | string;
        name: null | string;
        phone: null | string;
        processing_gif: null | number;
        processing_video: null | number; 
        processing_done: null | number;
        updated_at: null | string | Date;
    }>(null);
    const [ createLeadCallback, setCreateLeadCallback ] = useState<null | ((data:any) => Promise<null | {
       closed:boolean;
       copied:number;
       created_at: null | string | Date
       email: null | string
       custom_video_url: string | null;
       hasViewed:boolean;
       viewTime:string;
       id: number
       marketing_id:  null | number
       linkedin_url: null | string
       marketing_type: null | number
       marketingType_id: null | number;
       marketing_thumbnail: null | string;
       name: null | string;
       phone: null | string;
       processing_gif: null | number;
       processing_video: null | number; 
       processing_done: null | number;
       updated_at: null | string | Date;
   }>)>(null);
    // END CREATE LEAD
    // Preview Lead Video
    const [ showLeadVideoPreview, setShowLeadVideoPreview ] = useState<boolean>(false);
    const [ showLeadVideoPreviewURL, setShowLeadVideoPreviewURL ] = useState<string>("");
    // Create Lead Video
    const [ showCreateLeadVideo, setShowCreateLeadVideo ] = useState<boolean>(false);
    const [ showCreateLeadVideoID, setShowCreateLeadVideoID ] = useState<number>(0);
    const [ showCreateLeadVideoName, setShowCreateLeadVideoName ] = useState<string>("");
    // Create Video Gif
    const [ showCreateLeadVideoGif, setShowCreateLeadVideoGif ] = useState<boolean>(false);
    const [ showCreateLeadVideoURL, setShowCreateLeadVideoURL ] = useState<string>("");
    const [ showCreateLeadVideoText, setShowCreateLeadVideoText ] = useState<string>("");
    // Reload Folder    
    const [ showCreateLeadVideoReload, setShowCreateLeadVideoReload ] = useState<null | (() => Promise<void>)>(null);
    // Generic Email Template
    const [ showSendEmail, setShowSendEmail ] = useState<boolean>(false);
    const [ showSendEmailSubject, setShowSendEmailSubject ] = useState<string>("");
    const [ showSendEmailTo, setShowSendEmailTo ] = useState<string>("");
    const [ showSendEmailBody, setShowSendEmailBody ] = useState<string>("");
    // Bespoke Flow
    const [ showBespokeFlow, setShowBespokeFlow ] = useState<boolean>(false);
    const [ showBespokeFlowStep, setShowBespokeFlowStep] = useState<number>(0);
    // Campaign Flow
    const [ showCampaignFlow, setShowCampaignFlow ] = useState<boolean>(false);
    const [ showCampaignFlowStep, setShowCampaignFlowStep] = useState<number>(0);
    const [ showCampaignFlowState, setShowCampaignFlowState] = useState<string>("options");
    // Social Flow
    const [ showSocialFlow, setShowSocialFlow ] = useState<boolean>(false);
    const [ showSocialFlowStep, setShowSocialFlowStep] = useState<number>(0);
    const [ showSocialFlowState, setShowSocialFlowState] = useState<string>("options");
    // Campaign video
    const [ showCampaignVideo, setShowCampaignVideo ] = useState<boolean>(false);
    const [ showCampaignVideoState, setShowCampaignVideoState ] = useState<string>("options");
    // Social video
    const [ showSocialVideo, setShowSocialVideo ] = useState<boolean>(false);
    const [ showSocialVideoState, setShowSocialVideoState ] = useState<string>("options");
    // Effects    
    useEffect(()=>{
        if(!apiKey) return;
        async function loadUser(){
            let result = await API.user.getUser(apiKey);
            setUser(result['user']);
        }
        loadUser(); 
    },[apiKey]);
    useEffect(() => {      
        if (location.hash === '#bespoke') {
            setDefaultFolder("bespoke");
        } else if (location.hash === '#campaign') {
            setDefaultFolder("campaign");
        } else if (location.hash === '#social') {
            setDefaultFolder("social");
        } else {
            setDefaultFolder("bespoke"); // Default value
        }
    }, []);
    return <>
        <div className="dashboardContainer dashboardBlank">
            <SideBarComponent/>
            <div className="dashboardContent dashboard-page nice-grey" >
                <UpperBarComponent/>
                <MarketingHeaderComponent apiKey={apiKey} noWidth={true} marketing={marketing}/>
                <AnalyticsComponent apiKey={apiKey} marketing={marketing}/>
                {/* TODO: Introduction Videos */}
                <IntroVideosComponent apiKey={apiKey} marketing={marketing}
                    onPreview={ (label_id:number, video_url:string) => {
                        setShowLabelPreviewID(label_id);
                        setShowLabelPreviewVideoURL(video_url);
                        setShowLabelPreview(true);
                    }} onRecord={ (marketing_id:number, callback: () => Promise<void>) => {
                        setShowLabelRecordTitle("");
                        setShowLabelRecordMarketingID(marketing_id);
                        setShowLabelRecordState("label");
                        setShowLabelRecordCallback(() => callback);
                        setShowLabelRecord(true);
                    }
                }/>
                <MarketingFolderComponent
                    user={user}
                    apiKey={apiKey}
                    defaultFolder={defaultFolder}
                    marketing={marketing}
                    createLeadCallback={ (callback: (data:any) => Promise<null | {
                    closed:boolean;
                    copied:number;
                    created_at: null | string | Date
                    email: null | string
                    custom_video_url: string | null;
                    hasViewed:boolean;
                    viewTime:string;
                    id: number
                    marketing_id:  null | number
                    linkedin_url: null | string
                    marketing_type: null | number
                    marketingType_id: null | number;
                    marketing_thumbnail: null | string;
                    name: null | string;
                    phone: null | string;
                    processing_gif: null | number;
                    processing_video: null | number; 
                    processing_done: null | number;
                    updated_at: null | string | Date;
                }>) => {
                    setCreateLeadCallback(() => callback);
                }}
                executeCreateLead={ () => {
                    setShowCreateLeadName("");
                    setShowCreateLeadEmail("");
                    setShowCreateLeadPhone("");
                    setShowCreateLeadLinkedInURL("");
                    setShowCreateLead(true);
                }}
                previewLeadCustomVideo={ (video_url:string) => {
                    setShowLeadVideoPreviewURL(video_url);
                    setShowLeadVideoPreview(true);
                }}
                executeSetLeadCustomVideo={ (lead_id:number, lead_name:string)=>{
                    setShowCreateLeadVideoID(lead_id);
                    setShowCreateLeadVideoName(lead_name);
                    setShowCreateLeadVideo(true);
                }}
                reloadBespokeFolder={callback => {
                    setShowCreateLeadVideoReload(() => callback);
                }}
                onShowSendEmail={(subject:string, to:string, body:string)=>{
                    setShowSendEmailSubject(subject);
                    setShowSendEmailTo(to);
                    setShowSendEmailBody(body);
                    setShowSendEmail(true);
                }}
                reloadMarketing={reloadMarketing}
                executeBespokeFlow={ () => {
                    setShowBespokeFlowStep(0);
                    setShowBespokeFlow(true);
                }}
                executeCampaignFlow={ () => {
                    setShowCampaignFlowStep(0);
                    setShowCampaignFlow(true);
                }}
                executeSocialFlow={ () => {
                    setShowSocialFlowStep(0);
                    setShowSocialFlowState("options");
                    setShowSocialFlow(true);
                }}
                reloadCampaignFolder={callback => {
                    setShowCreateLeadVideoReload(() => callback);
                }}
                executeHandleMarketingVideoOptions={ () =>{
                    setShowCampaignVideoState("options");
                    setShowCampaignVideo(true);
                }}
                handleFacebookPageSubmission={(callback: (pages_data: any[]) => Promise<boolean>) => {
                    setShowFacebookShareCallback(() => callback);
                }}
                executeHandleFacebookPageSubmission={(pages_data:any[])=>{
                    setShowFacebookSelectedPages([]);
                    setShowFacebookSharePages(pages_data);
                    setShowFacebookShare(true);
                }}
                executeHandleSocialVideoOptions={ () => {
                    setShowSocialVideoState("options");
                    setShowSocialVideo(true);
                }}
                handleLinkedInShareCallback={(callback: ()=>Promise<void>) => {
                    setShowLinkedInVideoShareCompleteFunction(callback);
                }}
                executeShowLinkedInShare={(video_url:string, access_token:string, user_id:string)=>{
                    setShowLinkedInVideoShareVideoURL(video_url);
                    setShowLinkedInVideoShareUserAccessToken(access_token);
                    setShowLinkedInVideoShareUserID(user_id);
                    setShowLinkedInVideoShare(true);
                }}
                />
            </div>
        </div>
        { showLabelPreview && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Preview</h4>
                        <button onClick={ async e => {       
                            setShowLabelPreviewVideoURL("");
                            setShowLabelPreview(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <video src={showLabelPreviewVideoURL} width={"420px"} style={{maxWidth:'420px', maxHeight:'420px'}} playsInline={true} autoPlay={true} controls={true}></video>
                    </div>
                </div>
            </div>
        }
        { /* Record A New Label Video here and also accept a title */}
        { showLabelRecord && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Create Intro Video</h4>
                        <button onClick={ async e => {       
                            setShowLabelRecord(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        {
                            showLabelRecordState === "label" && <>
                                <p>Select a Title from below or type a custom one</p>
                                <input style={{width:'calc(100% - 20px)',padding:'8px', border:'1px solid #1976D1', borderRadius:'8px', backgroundColor:'#e6e6e6'}} list="custom_labels_options" onChange={ e => {
                                    setShowLabelRecordTitle(e.target.value);
                                }} placeholder="Enter title or click here for more options"/>
                                <datalist id="custom_labels_options">
                                    <option value="Company intro" />
                                    <option value="Demo Video" />
                                    <option value="Our Services" />
                                    <option value="Meet the team" />
                                    <option value="What we offer" />
                                    <option value="How it works" />
                                    <option value="Learn more" />
                                </datalist>
                                <button className="primary-button-blue" onClick={ e => { 
                                    if(!showLabelRecordTitle.trim()){
                                        toast.error("A Title is required");
                                        return;
                                    }            
                                    setShowLabelRecordState("options");
                                }}>Next</button>
                            </>
                        }
                        {
                            showLabelRecordState === "options" && <>
                                <button className="primary-button-blue" onClick={ e => setShowLabelRecordState("record")}>Record</button>
                                <button className="primary-button-blue" onClick={ e => setShowLabelRecordState("upload")}>Upload</button>
                            </>
                        }
                        {
                            showLabelRecordState === "record" && <>
                                <InBrowserRecorderAndProcessingComponent apiKey={apiKey}
                                onClose={ () =>{                                 
                                    setShowLabelRecord(false);
                                }}
                                onCompletion={ async video_url => {
                                    toast.info("Saving Video to Label");
                                    const result = await API.labels.create(apiKey, showLabelRecordTitle.trim(), video_url, showLabelRecordMarketingID);
                                    toast.info("Label Created");                                                     
                                    setShowLabelRecord(false);
                                    if(!showLabelRecordCallback){
                                        toast.error("Callback Missing");
                                        return;
                                    }
                                    showLabelRecordCallback();
                                }}
                                />
                            </>
                        }
                        {
                            showLabelRecordState === "upload" && <>
                                <VideoUploadComponent onreject={() =>{                                 
                                    setShowLabelRecord(false);
                                }} onclose={() =>{                                 
                                    setShowLabelRecord(false);
                                }} onaccept={ async video_url => {
                                    toast.info("Saving Video to Label");
                                    const result = await API.labels.create(apiKey, showLabelRecordTitle.trim(), video_url, showLabelRecordMarketingID);
                                    toast.info("Label Created");                                                     
                                    setShowLabelRecord(false);
                                    if(!showLabelRecordCallback){
                                        toast.error("Callback Missing");
                                        return;
                                    }
                                    showLabelRecordCallback();
                                }} watermark_url={""}/>
                            </>
                        }
                        {
                            showLabelRecordState === "library" && <>
                            </>
                        }
                    </div>
                </div>
            </div>
        }
        { showCreateLead && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Create Lead</h4>
                        <button onClick={ async e => {      
                            setShowCreateLeadName("");
                            setShowCreateLeadEmail("");
                            setShowCreateLeadPhone("");
                            setShowCreateLeadLinkedInURL("");
                            setShowCreateLead(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="nice-form">
                            <label htmlFor="">
                                <span>Lead's Name</span>
                            </label>
                            <input type="text" placeholder="Lead's Name" value={showCreateLeadName} onChange={ e => setShowCreateLeadName(e.target.value)}/>
                            <label htmlFor="">
                                <span>Lead's Email</span>
                            </label>
                            <input type="text" placeholder="Lead's Email Address" value={showCreateLeadEmail} onChange={ e => setShowCreateLeadEmail(e.target.value)}/>
                            {/*<label htmlFor="">
                                <span>Lead's LinkedIn Profile URL</span>
                            </label>
                            <input type="text" placeholder="Lead's LinkedIn Profile URL" value={showCreateLeadLinkedInURL} onChange={ e => setShowCreateLeadLinkedInURL(e.target.value)}/>*/}
                            {/*<label htmlFor="">
                                <span>Lead's Phone Number</span>
                            </label>
                        <input type="text" placeholder="Lead's Phone Number" value={showCreateLeadPhone} onChange={ e => setShowCreateLeadPhone(e.target.value)}/>*/}
                            <div className="footer">
                                <button className="button-primary-blue blocked full" onClick={ async e => {
                                    if(createLeadCallback === null){
                                        toast.error("Function Is Missing");
                                        return;
                                    }
                                    if(!showCreateLeadName || !showCreateLeadName.trim() || showCreateLeadName === ""){
                                        toast.error("Lead name is required");
                                        return;
                                    }
                                    let result = await createLeadCallback({
                                        name: showCreateLeadName,
                                        email: showCreateLeadEmail,
                                        linkedin_url: "", //showCreateLeadLinkedInURL,
                                        phone: "", //showCreateLeadPhone,
                                    });
                                    if(!result){
                                        return; // Don't close dialog if action was unsuccessful
                                    }
                                    if(result.marketingType_id === 1){
                                        setCreateLeadResult(result);
                                        setShowCreateLead(false);
                                        setShowCreateLeadGifCreation(true);
                                    } else {                          
                                        setShowCreateLeadName("");
                                        setShowCreateLeadEmail("");
                                        setShowCreateLeadPhone("");
                                        setShowCreateLeadLinkedInURL("");
                                        setCreateLeadCallback(null);
                                        setShowCreateLead(false);
                                    }
                                }}>Create Lead</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        { showLeadVideoPreview && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Preview Lead Custom Video</h4>
                        <button onClick={ async e => {      
                            setShowLeadVideoPreviewURL("");
                            setShowLeadVideoPreview(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <video src={showLeadVideoPreviewURL} width={"420px"} style={{maxWidth:'420px', maxHeight:'420px'}} playsInline={true} autoPlay={true} controls={true}></video>
                    </div>
                </div>
            </div>
        }
        { showCreateLeadVideo && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Record Lead Custom Video</h4>
                        <button onClick={ async e => {      
                            setShowCreateLeadVideoID(0);
                            setShowCreateLeadVideoName("");
                            setShowCreateLeadVideo(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <InBrowserRecorderAndProcessingComponentV2
                            apiKey={localStorage.getItem("api_key") ?? ""}
                            clientWatermarkImage={(user) ? user.watermark_url : ""}
                            onClose={ () => {}}
                            onCompletion={ async video_url => {
                                toast.info("Saving video to lead");
                                const response = await API.marketing.leads.setCustomVideo2(apiKey, showCreateLeadVideoID, video_url);
                                if(!response['success']){
                                    toast.error("Unable to save video to lead");
                                    return;
                                }
                                toast.success("Video Saved");
                                let text = marketing.thumbnail_text_option;
                                if(text === "custom"){
                                    text = marketing.thumbnail_text_custom_text;
                                }
                                text = text.replace("{lead}", showCreateLeadVideoName);
                                setShowCreateLeadVideo(false);
                                setShowCreateLeadVideoURL(video_url);
                                setShowCreateLeadVideoText(text);
                                setShowCreateLeadVideoGif(true);
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        { showCreateLeadVideoGif && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Lead Custom Video Thumbnail</h4>
                    </div>
                    <div className="content">
                        <InBrowserGifFromVideoComponent
                            apiKey={apiKey}
                            onClose={ () => {}}
                            inputVideoURL={showCreateLeadVideoURL}
                            text={showCreateLeadVideoText}
                            onCompletion={ async image_url => {
                                const response = await API.marketing.leads.setCustomVideoThumbnail(apiKey, showCreateLeadVideoID, image_url);
                                if(!response['success']){
                                    toast.error("Unable to set Thumbnail");
                                    return;
                                }
                                if(!showCreateLeadVideoReload){
                                    toast.error("Reload Callback is empty");
                                    return;
                                }
                                showCreateLeadVideoReload();
                                setShowCreateLeadVideoGif(false);
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        { showSendEmail && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Send Email</h4>
                        <button onClick={ async e => {       
                            setShowSendEmail(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="inputForm">
                            <h4 style={{textAlign:'left', padding:'0px', margin:'0px'}}>Email Subject Line</h4>
                            <div style={{padding:'0px', margin:'0px'}}>
                                <input onChange={ e => {setShowSendEmailSubject(e.target.value)}} value={showSendEmailSubject} type="text" style={{width:'calc(100% - 18px)', padding:'8px', marginBottom:'8px'}} placeholder="Enter subject line here"/>
                            </div>
                            <h4 style={{textAlign:'left', padding:'0px', margin:'0px'}}>Email To Address</h4>
                            <div style={{padding:'0px', margin:'0px'}}>
                                <input onChange={ e => {setShowSendEmailTo(e.target.value)}} value={showSendEmailTo}  type="text" style={{width:'calc(100% - 18px)', padding:'8px', marginBottom:'8px'}} placeholder="Enter email address here"/>
                            </div>
                            <h4 style={{textAlign:'left', padding:'0px', margin:'0px', marginBottom:'4px'}}>Email Body&nbsp;&nbsp;<button onClick={ e => {
                                let copied = false;
                                try { window.navigator.clipboard.writeText(showSendEmailBody); copied = true;} catch(e){}
                                try { (window as any).clipboardData.setData("Text", showSendEmailBody); copied = true;} catch(e){}
                                if(!copied) return;
                                toast.success("Data Copied");
                            }} className="blueBTN" style={{
                                height: "25px",
                                lineHeight:" 0px",
                                verticalAlign:"middle"}}>Copy</button></h4>
                            <div style={{padding:'0px', margin:'0px'}}>
                                <textarea onChange={ e => {setShowSendEmailBody(e.target.value)}} value={showSendEmailBody}  style={{width:'695px', padding:'12px', height:"64px"}} placeholder="Enter email body here"></textarea>
                            </div>
                            <div style={{padding:'0px', margin:'0px', textAlign:'right'}}>
                                <button style={{width:"240px", marginRight:'0px'}} className="button-primary-blue" onClick={ async e => {
                                    if(!showSendEmailSubject.trim()){
                                        toast.error("No Subject");
                                        return;
                                    }
                                    if(!showSendEmailTo.trim()){
                                        toast.error("No Email Address");
                                        return;
                                    }
                                    if(!showSendEmailBody.trim()){
                                        toast.error("No Email Body");
                                        return;
                                    }
                                    toast.info("Sending Email");
                                    const response = await API.email(apiKey, showSendEmailTo.trim(), showSendEmailSubject.trim(), showSendEmailBody.trim());
                                    toast.success("Email Sent");
                                }}>Send Email</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        { showBespokeFlow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Bespoke Marketing - Settings</h4>
                        <button onClick={ async e => {       
                            setShowBespokeFlowStep(0);
                            setShowBespokeFlow(false);
                        }}>X</button>
                    </div>
                    <div className="content">                       
                        <div className="pretty-form">
                       {
                            showBespokeFlowStep === 0 && <>
                                <h4>Step 1 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Thumbnail Text</span>
                                    <select defaultValue={marketing.thumbnail_text_option} onChange={ async (e) => {
                                        toast.info("Updating");
                                        const result = await API.marketing.campaign.bespoke.setThubmanailOption(apiKey, marketing.id, e.target.value);
                                        reloadMarketing();
                                        toast.success("Updated");
                                    }}>
                                        <option value={"Hi {lead}"}>{"Hi {lead}"}</option>
                                        <option value={"Hello {lead}"}>{"Hello {lead}"}</option>
                                        <option value="custom" selected={marketing.thumbnail_text_option == "custom"}>Custom message</option>
                                    </select>
                                    {
                                        marketing.thumbnail_text_option == "custom" && <>
                                            <h4>Custom Message</h4>
                                            <input onChange={ async (e) => {
                                            await fetchAPIMarketingCustomTextData(`${API.baseURL}api/v1/marketing/campaign/bespoke/thumbnail/text`, {
                                                api_key: apiKey,
                                                id: marketing.id,
                                                text: e.target.value,
                                            }, {}, "POST");
                                            reloadMarketing();
                                            }} defaultValue={marketing.thumbnail_text_custom_text} type="text" placeholder="Welcome {Lead}"/>
                                        </>
                                    }
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowBespokeFlowStep(1)}}>Next</button>
                            </>
                       }
                       {
                            showBespokeFlowStep === 1 && <>
                                <h4>Step 2 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Marketing Text</span>
                                    <textarea onChange={ async e => {
                                    await fetchAPIMarketingTextData(`${API.baseURL}/api/v1/marketing/campaign/bespoke/marketing/text`, {
                                        api_key: apiKey,
                                        id: marketing.id,
                                        text: e.target.value,
                                    }, {}, "POST");
                                    reloadMarketing();
                                    }} style={{display:'block', width: 'calc(100% - 24px)', padding: '8px 12px', margin: '0px', height: '105px'}} defaultValue={marketing.marketing_text}></textarea>                                
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowBespokeFlowStep(2)}}>Next</button>
                            </>
                       }
                       {
                            showBespokeFlowStep === 2 && <>
                                <h4>Step 3 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Our Website</span>
                                    <div style={{display:'inline-block', fontSize:'8pt'}}>Example URL <strong><span style={{backgroundColor:'yellow'}}>https://</span>www.example.com/</strong></div>
                                    <input onChange={ async (e) => {
                                    setOurWebsite(e.target.value);
                                    await fetchWebsite(`${API.baseURL}/api/v1/marketing/campaign/bespoke/marketing/ourwebsite`, {
                                        api_key: apiKey,
                                        id: marketing.id,
                                        text: e.target.value,
                                    }, {}, "POST");
                                    reloadMarketing();
                                    }} defaultValue={marketing.our_website} type="text" placeholder="https://www.example.com"/>
                                    <div style={{display:'block'}}>{ API.validation.verifyURL(ourWebsite) ? <div style={{fontSize:'8pt', color:'green'}}>Valid URL</div> : <div style={{fontSize:'8pt',color:'red'}}>Invalid URL</div> }</div>
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowBespokeFlowStep(0); setShowBespokeFlow(false); toast.success("Workflow Completed");}}>Done</button>
                            </>
                       }
                    </div>
                    </div>
                </div>
            </div>
        }
        { showSocialFlow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Social Marketing - Settings</h4>
                        <button onClick={ async e => {       
                            setShowSocialFlowStep(0);
                            setShowSocialFlow(false);
                        }}>X</button>
                    </div>
                    <div className="content">                       
                        <div className="pretty-form">
                       {
                            showSocialFlowStep === 0 && <>
                                <h4>Step 1 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Social Caption</span>
                                    <input
                                        style={{ margin:'0px' }}
                                        defaultValue={marketing.social_caption}
                                        onChange={async (e:any)=>{
                                            const result = fetchAPIMarketingTextData(API.baseURL + "/api/v1/marketing/campaign/social/marketing/caption",
                                            {
                                                api_key: apiKey,
                                                id: marketing.id,
                                                text: e.target.value,
                                            }, {}, "POST");
                                        }}
                                        placeholder="Title"
                                    />
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowSocialFlowStep(1)}}>Next</button>
                            </>
                       }
                       {
                            showSocialFlowStep === 1 && <>
                                <h4>Step 2 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Social Post Text</span>
                                    <textarea
                                className="advertisemarketingText"
                                style={{ margin:'0px', width:'calc(100% - 31px)' }}
                                defaultValue={marketing.social_text}
                                onChange={async (e:any)=>{
                                    const result = fetchWebsite(API.baseURL + "/api/v1/marketing/campaign/social/marketing/text",
                                    {
                                        api_key: apiKey,
                                        id: marketing.id,
                                        text: e.target.value,
                                    }, {}, "POST");
                                }}
                            ></textarea>   
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowSocialFlowStep(2)}}>Next</button>
                            </>
                       }
                       {
                            showSocialFlowStep === 2 && <>
                                <h4>Step 3 of 3</h4>
                                {
                                    showSocialFlowState === "options" && <>                                    
                                        <button className="primary-button-blue" onClick={ e => {setShowSocialFlowState("record")}}>Record</button>                                    
                                        <button className="primary-button-blue" onClick={ e => {setShowSocialFlowState("upload")}}>Upload</button>
                                    </>
                                }
                                {
                                    showSocialFlowState === "upload" && <>
                                        <VideoUploadComponent onreject={()=>{
                                            setShowSocialFlowState("options");
                                            setShowSocialFlowStep(3);
                                        }} onclose={()=>{
                                            setShowSocialFlowState("options");
                                            setShowSocialFlowStep(3);
                                        }} onaccept={ async (video_url:string)=>{
                                            toast.info("Updating, please wait for popup to close");
                                            let result = await API.marketing.campaign.social.setVideo(apiKey, marketing.id, video_url);    
                                            toast.success("Completed");                                                 
                                            setShowSocialFlowStep(0);
                                            setShowSocialFlow(false);
                                            await reloadMarketing();
                                        }} watermark_url={""}                           
                                        />
                                    </>
                                }
                                {
                                    showSocialFlowState === "record" && <>
                                        <InBrowserRecorderAndProcessingComponent
                                            apiKey={apiKey}
                                            onClose={()=>{
                                                setShowSocialFlowState("options");
                                                setShowSocialFlowStep(3);
                                            }}
                                            onCompletion={ async (video_url:string)=>{
                                                toast.info("Updating, please wait for popup to close");
                                                let result = await API.marketing.campaign.social.setVideo(apiKey, marketing.id, video_url);               
                                                toast.success("Completed");                                                 
                                                setShowSocialFlowStep(0);
                                                setShowSocialFlow(false);
                                                await reloadMarketing();
                                            }}
                                        />
                                    </>
                                }
                            </>
                       }
                    </div>
                    </div>
                </div>
            </div>
        }
        { showCampaignFlow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Campaign Marketing - Settings</h4>
                        <button onClick={ async e => {       
                            setShowCampaignFlowStep(0);
                            setShowCampaignFlow(false);
                        }}>X</button>
                    </div>
                    <div className="content">                       
                        <div className="pretty-form">
                       {
                            showCampaignFlowStep === 0 && <>
                                <h4>Step 1 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Marketing Custom Gif Text</span>
                                    <select style={{width:'100%', padding:'12px'}} onChange={ async (e) => {
                                        if(e.target.value === "3"){
                                            toast.info("Loading, please waits");
                                        }
                                        await fetchWebsite(`${API.baseURL}/api/v1/marketing/campaign/set/thumbnailtype`, {
                                            api_key: apiKey,
                                            id: marketing.id,
                                            value: e.target.value,
                                        }, {}, "POST");
                                        reloadMarketing();
                                        }} defaultValue={marketing.custom_thumbnail_outreach_type}>
                                            <option value='0'>Hi {"{lead}"}</option>
                                            <option value='1'>Hello {"{lead}"}</option>
                                            <option value='2'>Hello {"{lead}"}</option>
                                            <option value='3'>Custom Message</option>
                                        </select>
                                        {
                                            marketing.custom_thumbnail_outreach_type === 3 && <div>
                                            <span>Enter a custom message:</span>
                                            <input style={{width:'calc(100% - 27px) !important',padding:'12px'}} type="text"  onChange={ async (e) => {
                                                await fetchAPIMarketingCustomTextData(`${API.baseURL}/api/v1/marketing/campaign/set/thumbnailtext`, {
                                                api_key: apiKey,
                                                id: marketing.id,
                                                text: e.target.value,
                                                }, {}, "POST");
                                                reloadMarketing();
                                            }} defaultValue={marketing.bulk_marketing_text}/>
                                            </div>
                                        }
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowCampaignFlowStep(1)}}>Next</button>
                            </>
                       }
                       {
                            showCampaignFlowStep === 1 && <>
                                <h4>Step 2 of 3</h4>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Marketing Caption Text</span>
                                    <textarea onChange={ async e => {
                                    await fetchAPIMarketingTextData(`${API.baseURL}/api/v1/marketing/campaign/campaign/marketing/text`, {
                                        api_key: apiKey,
                                        id: marketing.id,
                                        text: e.target.value,
                                    }, {}, "POST");
                                    reloadMarketing();
                                    }} style={{display:'block', width: 'calc(100% - 24px)', padding: '8px 12px', margin: '0px', height: '105px'}} defaultValue={marketing.campaign_text}></textarea>
                                </label>
                                <button className="primary-button-blue" onClick={ e => {setShowCampaignFlowStep(2); setShowCampaignFlowState("options")}}>Next</button>
                            </>
                       }
                       {
                            showCampaignFlowStep === 2 && <>
                                <h4>Step 3 of 3</h4>
                                {
                                    showCampaignFlowState === "options" && <>                                    
                                        <button className="primary-button-blue" onClick={ e => {setShowCampaignFlowState("record")}}>Record</button>                                    
                                        <button className="primary-button-blue" onClick={ e => {setShowCampaignFlowState("upload")}}>Upload</button>
                                    </>
                                }
                                {
                                    showCampaignFlowState === "upload" && <>
                                        <VideoUploadComponent onreject={()=>{
                                            setShowCampaignFlowState("options");
                                            setShowCampaignFlowStep(3);
                                        }} onclose={()=>{
                                            setShowCampaignFlowState("options");
                                            setShowCampaignFlowStep(3);
                                        }} onaccept={ async (video_url:string)=>{
                                            toast.info("Updating");
                                            const result = await API.marketing.campaign.campaign.setVideo2(apiKey, marketing.id, video_url);
                                            toast.success("Completed");                                                 
                                            setShowCampaignFlowStep(0);
                                            setShowCampaignFlow(false);
                                            await reloadMarketing();
                                        }} watermark_url={""}                           
                                        />
                                    </>
                                }
                                {
                                    showCampaignFlowState === "record" && <>
                                        <InBrowserRecorderAndProcessingComponent
                                            apiKey={apiKey}
                                            onClose={()=>{
                                                setShowCampaignFlowState("options");
                                                setShowCampaignFlowStep(3);
                                            }}
                                            onCompletion={ async (video_url:string)=>{
                                                toast.info("Updating");
                                                const result = await API.marketing.campaign.campaign.setVideo2(apiKey, marketing.id, video_url);                                            
                                                toast.success("Completed");                                                 
                                                setShowCampaignFlowStep(0);
                                                setShowCampaignFlow(false);
                                                await reloadMarketing();
                                            }}
                                        />
                                    </>
                                }
                            </>
                       }
                    </div>
                    </div>
                </div>
            </div>
        }
        { showCreateLeadGifCreation && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Lead Gif</h4>
                    </div>
                    <div className="content">
                        <InBrowserGifFromVideoComponent
                            apiKey={apiKey}
                            inputVideoURL={marketing.campaign_video_url ?? ""}
                            onClose={ () => {}}
                            text={(marketing.custom_thumbnail_outreach_type === 3 ? marketing.bulk_marketing_text : ["Hi {lead}", "Hello {lead}", "Hello {lead}"][marketing.custom_thumbnail_outreach_type] ?? "").replace("{lead}", createLeadResult?.name)}
                            onCompletion={ async image_url => {
                                toast.info("Updating Lead");
                                const result = await API.marketing.leads.setCustomVideoThumbnail(apiKey, createLeadResult?.id, image_url);
                                const success = result['success'];
                                if(!success){
                                    toast.error(result['message']);
                                    return;
                                }
                                toast.info("Lead Updated");
                                setShowCreateLeadGifCreation(false);
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        { showCampaignVideo && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Marketing Video</h4>
                        <button onClick={ async e => {       
                            if(showCampaignVideoState === "record" || showCampaignVideoState === "upload"){
                                setShowCampaignVideoState("options");
                            } else {
                                setShowCampaignVideoState("options");
                                setShowCampaignVideo(false);    
                            }
                        }}>X</button>
                    </div>
                    <div className="content">
                    {
                        showCampaignVideoState === "options" && <>                                    
                            <button className="primary-button-blue" onClick={ e => {setShowCampaignVideoState("record")}}>Record</button>                                    
                            <button className="primary-button-blue" onClick={ e => {setShowCampaignVideoState("upload")}}>Upload</button>
                        </>
                    }
                    {
                        showCampaignVideoState === "upload" && <>
                            <VideoUploadComponent onreject={()=>{
                                setShowCampaignVideoState("options");
                            }} onclose={()=>{
                                setShowCampaignVideoState("options")
                            }} onaccept={ async (video_url:string)=>{
                                toast.info("Updating");
                                const result = await API.marketing.campaign.campaign.setVideo2(apiKey, marketing.id, video_url);
                                toast.success("Completed");       
                                setShowCampaignVideoState("options");                                          
                                setShowCampaignVideo(false);
                                await reloadMarketing();
                            }} watermark_url={""}                           
                            />
                        </>
                    }
                    {
                        showCampaignVideoState === "record" && <>
                            <InBrowserRecorderAndProcessingComponent
                                apiKey={apiKey}
                                onClose={()=>{
                                    setShowCampaignVideoState("options")
                                }}
                                onCompletion={ async (video_url:string)=>{
                                    toast.info("Updating");
                                    const result = await API.marketing.campaign.campaign.setVideo2(apiKey, marketing.id, video_url);                                            
                                    toast.success("Completed");        
                                    setShowCampaignVideoState("options");                                          
                                    setShowCampaignVideo(false);
                                    await reloadMarketing();
                                }}
                            />
                        </>
                    }
                    </div>
                </div>
            </div>
        }
        { showSocialVideo && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Social Video</h4>
                        <button onClick={ async e => {       
                            if(showSocialVideoState === "record" || showSocialVideoState === "upload"){
                                setShowSocialVideoState("options");
                            } else {
                                setShowSocialVideoState("options");
                                setShowSocialVideo(false);    
                            }
                        }}>X</button>
                    </div>
                    <div className="content">
                    {
                        showSocialVideoState === "options" && <>                                    
                            <button className="primary-button-blue" onClick={ e => {setShowSocialVideoState("record")}}>Record</button>                                    
                            <button className="primary-button-blue" onClick={ e => {setShowSocialVideoState("upload")}}>Upload</button>
                        </>
                    }
                    {
                        showSocialVideoState === "upload" && <>
                            <VideoUploadComponent onreject={()=>{
                                setShowCampaignVideoState("options");
                            }} onclose={()=>{
                                setShowCampaignVideoState("options")
                            }} onaccept={ async (video_url:string)=>{
                                toast.info("Updating, please wait for popup to close");
                                let result = await API.marketing.campaign.social.setVideo(apiKey, marketing.id, video_url); 
                                toast.success("Completed");       
                                setShowSocialVideoState("options");                                          
                                setShowSocialVideo(false);
                                await reloadMarketing();
                            }} watermark_url={""}                           
                            />
                        </>
                    }
                    {
                        showSocialVideoState === "record" && <>
                            <InBrowserRecorderAndProcessingComponent
                                apiKey={apiKey}
                                onClose={()=>{
                                    setShowSocialVideoState("options")
                                }}
                                onCompletion={ async (video_url:string)=>{
                                    toast.info("Updating, please wait for popup to close");
                                    let result = await API.marketing.campaign.social.setVideo(apiKey, marketing.id, video_url); 
                                    toast.success("Completed");        
                                    setShowSocialVideoState("options");                                          
                                    setShowSocialVideo(false);
                                    await reloadMarketing();
                                }}
                            />
                        </>
                    }
                    </div>
                </div>
            </div>
        }
        { showFacebookShare && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Facebook Share</h4>
                        <button onClick={ e => {          
                            setShowFacebookSharePages([])
                            setShowFacebookSelectedPages([]);
                            setShowFacebookShareCallback(null);
                            setShowFacebookShare(false); 
                        }}>X</button>
                    </div>
                    <div className="content">
                        <p>Select which pages you would like to post to</p>
                        <ul>
                            {
                                showFacebookSharePages.map((sharePage:any, index:number)=>{
                                    let isInSelectedArray = showFacebookSelectedPages.some((x: { id: number }) => x.id === sharePage.id);
                                    return <li key={sharePage.id}>
                                        <label className="page_item" key={"page_id_" + sharePage.id}>
                                        <input type="checkbox" defaultChecked={isInSelectedArray} onChange={ e => {
                                            let isChecked = e.target.checked;
                                            setShowFacebookSelectedPages( x => {
                                                let newX = [...x];
                                                if(isChecked){
                                                    newX.push(sharePage);
                                                } else {
                                                    newX = newX.filter((x)=>{return x.id !== sharePage.id});
                                                }
                                                return newX;
                                            });
                                        }}/>
                                            <span>{sharePage.name}</span>
                                        </label>
                                    </li>;
                                })
                            }
                        </ul>
                        <button className="button-primary-blue blocked full" onClick={ async e => {
                            if(!showFacebookShareCallback){
                                toast.error("Missing Callback");
                                return;
                            }
                            if(showFacebookSelectedPages.length <= 0){
                                toast.error("You need to select at least one page to share to");
                                return;
                            }
                            await showFacebookShareCallback(showFacebookSelectedPages);                             
                            setShowFacebookSharePages([])
                            setShowFacebookSelectedPages([]);
                            setShowFacebookShareCallback(null);
                            setShowFacebookShare(false); 
                        }}>Post</button>
                    </div>
                </div>
            </div>
        }
        { showLinkedInVideoShare && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Sharing Video To Linked In</h4>
                        <button onClick={ async e => {          
                            setShowLinkedInVideoShare(false); 
                        }}>X</button>
                    </div>
                    <div className="content"> 
                        <InBrowserLinkedInVideoProcessor
                            text={marketing.social_text}
                            apiKey={apiKey}
                            videoUrl={showLinkedInVideoShareVideoURL}
                            USER_ACCESS_TOKEN={showLinkedInVideoShareUserAccessToken}
                            USER_URN_ID={showLinkedInVideoShareUserID}
                            onCompletion={ async success => {
                                setShowLinkedInVideoShare(false); 
                            }}
                        />
                    </div>
                </div>
            </div>
        }
    </>
}