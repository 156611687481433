import React, { createRef } from "react";

import { DateTime } from "luxon";

import "./vacancies.css";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";

import { AiOutlineLoading } from 'react-icons/ai';

import { ToastContainer, toast } from 'react-toastify';

export default class TemplatesPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          loading: false,
          templates: [],
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
      this.reload = this.reload.bind(this);
      this.getTime = this.getTime.bind(this);
      this.useTemplate = this.useTemplate.bind(this);
      this.deleteTemplate = this.deleteTemplate.bind(this);

      this.addToVacancyDialogRef = createRef();
      this.addToVacancyDialogTitleRef = createRef();
    }

    async componentDidMount(){
        this.addToVacancyDialogRef.current.style.display = "none";
        this.reload();
    }


    async reload(){
        
        let api_key = localStorage.getItem('api_key');
        let result = await API.templates.list(api_key);

        let templates = result['templates'];
        this.setState({
            ...this.state,
            templates: templates,
            loading: false
        });
    }
 
    getTime(dt){
        return DateTime.fromISO(dt).toFormat("dd-MM-yyyy");
        //return fuzzytime(dt);
    }

    async useTemplate(e){        
        let title = e.target.getAttribute('data-template-title');
        let id = e.target.getAttribute('data-template-id');
        this.addToVacancyDialogRef.current.style.display = "flex";
        this.addToVacancyDialogTitleRef.current.value = title;
        this.addToVacancyDialogTitleRef.current.setAttribute('data-id', id);
    }
    
    async deleteTemplate(e){        
        let id = e.target.getAttribute('data-template-id');
        let api_key = localStorage.getItem('api_key');
        let result = await API.templates.delete(api_key, id);
        this.reload();
    }

    render() {
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Templates</span>
                    </div>
                    <div className="vacancy-table">
                        <table>
                            <tr className="noHover">
                                <th style={{width:'150px'}}>Actions</th>
                                <th>Title</th>
                                <th>Date</th>
                            </tr>    
                            {
                                this.state.templates.map((template)=>{
                                    return (
                                        <tr>
                                            <td style={{width:'150px'}}>
                                            <span class="dropdown" style={{display:'inline-block'}}>
                                                    <button>Actions</button>
                                                    <label>
                                                        <input type="checkbox" />
                                                        <ul>
                                                            <li onClick={()=>{
                                                                window.location.href = '/template/' + template.id;
                                                            }}>View</li>
                                                            <li data-template-id={template.id}  data-template-title={template.title} onClick={this.useTemplate}>+ Vacancies</li>
                                                            <li data-template-id={template.id} onClick={this.deleteTemplate}>Delete</li>
                                                        </ul>
                                                    </label>
                                                </span>
                                            </td>
                                            <td>{ template.title } </td>
                                            <td>{ this.getTime(template.created_at) }</td>
                                        </tr>
                                    );
                                })
                            }                                  
                            { this.state.templates.length == 0 &&
                                <tr>
                                    <td style={{width:'150px'}}>No Templates</td>
                                    <td style={{width:'calc(100vw / 6)'}}></td>
                                    <td style={{width:'calc(100vw / 6)'}}></td>
                                </tr>
                            }                       
                        </table>                        
                    </div>
                </div>
                <div>
                    
                </div>
            </div>         
            <div>
                    <div className="dialogNewCreate" ref={this.addToVacancyDialogRef}>        
                        <div>
                            <h4>Use Template</h4>
                            <div>
                                <input type="text"  ref={this.addToVacancyDialogTitleRef} className="createVacancyInput" placeholder="Vacancy Name"/>
                            </div>
                            <div>
                                <button onClick={ async ()=>{
                                    let title = this.addToVacancyDialogTitleRef.current.value;
                                    if(!title){
                                        toast("Enter a valid title");
                                        return;
                                    }
                                    let id = parseInt(this.addToVacancyDialogTitleRef.current.getAttribute('data-id'));
                                    this.setState({
                                        ...this.state,
                                        loading: true
                                    });
                                    this.addToVacancyDialogRef.current.style.display = "none";
                                    let api_key = localStorage.getItem('api_key');
                                    let result = await API.templates.use(api_key, id, title);
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                    });
                                    this.reload();
                                }}>Create</button>
                                <button onClick={()=>{                                    
                                    this.addToVacancyDialogRef.current.style.display = "none";
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>     
            { 
                this.state.loading ? (
                    <div className="loader">                        
                        <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                    </div>
                ) : ""
            }
            
                </>
        );
      }
}