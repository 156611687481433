import React from "react";

import './metrics.css';

import prettyBytes from 'pretty-bytes';
import Chart from "react-apexcharts";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";

export default class MetricsPage extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        options: {
          colors: ["#86FF86", "#FF2B2B"],
          chart: {
            id: "clipdrop-metric-data"
          },
          xaxis: {
            categories: ["","","","","","","","","","","",]
          }
        },
        series: [
            {name: "Money In",data: [0,0,0,0,0,0,0,0,0,0,0,]},
            {name: "Money Out",data: [0,0,0,0,0,0,0,0,0,0,0,]}
        ],
        moneyIn: 0,
        moneyOut: 0,
        total: {
            users: 0,
            candidates: 0,
            videos: 0,
            data: 0,
        },
        currency: "$"
      };

      this.moneyBalance = this.moneyBalance.bind(this);
      this.getSignMoneyBalance = this.getSignMoneyBalance.bind(this);
      this.getCurrencySymbol = this.getCurrencySymbol.bind(this);

      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
    }

    async componentDidMount(){
      let api_key = localStorage.getItem('api_key');
      let result = await API.transaction.misc(api_key);
      let total_users = result['total_users'];
      let total_candidates = result['total_candidates'];
      let total_videos = result['total_videos'];
      let total_data_size = result['total_data_size'];

      let moneyIn = 0;
      let moneyOut = 0;
      
      result = await API.transaction.overview(api_key);
      moneyIn = result['money_in'];
      moneyOut = result['money_out'];
      
      result = await API.transaction.chart(api_key);
      let categories = result['categories']
      let series_money_in = result['series_1'];
      let series_money_out = result['series_2'];

      this.setState({
        ...this.state,
        total: {
          users: total_users,
          candidates: total_candidates,
          videos: total_videos,
          data: total_data_size
        },
        moneyIn: moneyIn,
        moneyOut: moneyOut,
        options: {
          colors: ["#86FF86", "#FF2B2B"],
          chart: {
            id: "clipdrop-metric-data"
          },
          xaxis: {
            categories:categories,
          }
        },
        series: [
            {name: "Money In", data: series_money_in},
            {name: "Money Out", data: series_money_out}
        ],
      });
    }

    getCurrencySymbol(){
        return this.state.currency;
    }
    
    moneyBalance(){
        let mIn = this.state.moneyIn;
        let mOut = this.state.moneyOut;
        return (mIn - mOut).toFixed(2).toString();
    }

    getSignMoneyBalance(){
        let balance =  this.getCurrencySymbol() + this.moneyBalance();

        let increased = <span className="balacneGreen"> { balance } ▲</span>;
        let decreased = <span className="balacneRed"> { balance } ▼</span>;

        let mIn = this.state.moneyIn;
        let mOut = this.state.moneyOut;

        return (mIn - mOut <= 0) ? increased : decreased;
    }

    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Metrics</span>
                    </div>
                    <div className="inlineBlock">
                        <div className="mixed-chart">
                            <Chart options={this.state.options} series={this.state.series} type="line" width="500"/>
                        </div>
                    </div>                    
                    <div className="dashBlock">
                        <h4>Transaction Overview</h4>
                        <div>
                            <ul className="transactionOverview">
                                <li><strong>Money In:</strong> { this.getCurrencySymbol() + this.state.moneyIn.toFixed(2).toString() }</li>
                                <li><strong>Money Out:</strong>  { this.getCurrencySymbol() + this.state.moneyOut.toFixed(2).toString() }</li>
                                <li><strong>Balance:</strong>  { this.getSignMoneyBalance() }</li>
                            </ul>
                        </div>
                    </div>
                    <div className="dashBlock">
                        <h4>Misc Overview</h4>
                        <div>
                            <ul className="miscOverview">
                                <li><strong>Total Users:</strong> { this.state.total.users.toString() }</li>
                                <li><strong>Total Candidates:</strong> { this.state.total.candidates.toString() }</li>
                                <li><strong>Total Candidate Anwsers:</strong> { this.state.total.videos.toString() }</li>
                                <li><strong>Total Videos Data Size:</strong> { prettyBytes(this.state.total.data) }</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}