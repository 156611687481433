import React, {useEffect, useState, useRef,useMemo } from 'react';
import './SimplePagination.css';
export default function SimplePagination(props:any){
    const { onChange, totalPages, currentPage} = props;
    const pages = useMemo(() => {
        const paginationRange = 10;
        let startIndex = currentPage <= Math.ceil(paginationRange / 2)
        ? 0
        : currentPage - Math.floor(paginationRange / 2);
        let endIndex = startIndex + paginationRange;
        if (endIndex > totalPages) {
        endIndex = totalPages;
        startIndex = Math.max(endIndex - paginationRange, 0);
        }
        const pages:number[] = [];
        for (let i = startIndex; i < endIndex; i++) {
            pages.push(i);
        }
        if(pages.length === 0){
            pages.push(0);
        }
        return pages;
    }, [currentPage, totalPages]);

    return (
        <div className='simple-pagination'>
            <div key={"page-backindex-back"} onClick={()=>{
                onChange(0);
            }}>&lt;&lt;</div>
            {
                pages.map((page, index)=>{
                    return <div key={"page-" + page + "index-" + index} onClick={()=>{
                        if(page === currentPage) return;
                        onChange(page);
                    }} className={page === currentPage ? "current" : ""}>{ page + 1 }</div>;
                })
            }
            <div key={"page-forwardindex-forward"} onClick={()=>{
                let nextPage = totalPages - 1;
                if(nextPage < 0) nextPage = 0;
                onChange(nextPage);
            }}>&gt;&gt;</div>
        </div>
    )
}