import React, { createRef, forwardRef } from "react";

import API from "./../services/api";

import axios from 'axios';

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";

export default class TFAPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {isError: false, errorMessage: ""}; // isError -> errorMessage

      this.codeRef = createRef();

      this.handleLogin = this.handleLogin.bind(this);
    }

    // handle login
    async handleLogin(){
        toast.info("Logging In");
        this.setState({...this.state, isError: true, errorMessage: ""});
        let temp_api = localStorage.getItem('temp_key');
        let code =  this.codeRef.current.value.trim();
        const url = API.baseURL + "/api/v1/user/2fa";
        const body = {
            temp_api: temp_api,
            code: code,
        };
        axios.post(url, body, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => {
            let result = response.data;
            let api_key = result['api_key'];
            if(api_key){
                toast.info("Logged In");
                localStorage.setItem('temp_key', "");
                localStorage.setItem('api_key', api_key);
                window.location.href = "/dashboard";
            } else {
                toast.info("invalid login details");
                this.setState({...this.state, isError: true, errorMessage: "invalid login details"});
            }
    
        })
        .catch(error => {
            toast.error(error.message);
        });
    }

    componentDidMount(){
    }

    render() {
        // HTML
        return (
            <div className="outer">
                <div className="middle">
                    <div className="container">
                        <div className="loginFormLogo"></div>
                        <div className="form">
                            <span>You will shortly receive an OTP Code via email to your primary email address.</span><br/><br/>
                            <span>please check your spam folder incase you dont see it in your inbox</span>
                            <input id="code" placeholder="OTP Code" defaultValue="" ref={this.codeRef}/>
                            {   
                                this.state.isError ? (
                                    <span className="error">{this.state.errorMessage}</span>
                                ) : ''
                            }
                            <button id="login" onClick={this.handleLogin}>LOGIN</button>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}