const Facebook = {
    // email, user_link, pages_show_list, pages_manage_posts, user_photos, publish_actions
    getLogin: async () => {
        let auth = await (new Promise((resolve)=>{            
            window.FB.getLoginStatus(function(response) {
                resolve(response);
            });
        }));
        if(auth['status'] == "connected"){
            return {
                access_token: auth['authResponse']['accessToken'],
                data_access_expiration_time: auth['authResponse']['data_access_expiration_time'],
                expires_in: auth['authResponse']['expiresIn'],
                user_id: auth['authResponse']['userID'],
                signed_request: auth['authResponse']['signedRequest'],
                status: auth['status'],
            };
        }
        let auth2 = await (new Promise((resolve)=>{
            window.FB.login(function(response) {
                if (response.authResponse) {
                 window.FB.api('/me', function(response2) {
                    console.log(response2);
                    return {
                        access_token: response['authResponse']['accessToken'],
                        data_access_expiration_time: response['authResponse']['data_access_expiration_time'],
                        expires_in: response['authResponse']['expiresIn'],
                        user_id: response['authResponse']['userID'],
                        signed_request: response['authResponse']['signedRequest'],
                        status: response['status'],
                    };
                 });
                } else {
                 console.log('User cancelled login or did not fully authorize.');
                }
            }, {scope: 'email, user_link, pages_read_engagement, pages_show_list, pages_manage_posts, publish_video', return_scopes: true});
        }));
    },
    reAuth: async () => {
        window.FB.login(function(response) {
            if (response.authResponse) {
             window.FB.api('/me', function(response2) {
                console.log(response2);
                return {
                    access_token: response['authResponse']['accessToken'],
                    data_access_expiration_time: response['authResponse']['data_access_expiration_time'],
                    expires_in: response['authResponse']['expiresIn'],
                    user_id: response['authResponse']['userID'],
                    signed_request: response['authResponse']['signedRequest'],
                    status: response['status'],
                };
             });
            } else {
             console.log('User cancelled login or did not fully authorize.');
            }
        }, { auth_type: 'reauthenticate' });
    },
    getPages: async (user_id, access_token) =>{
        let url = "https://graph.facebook.com/" + user_id + "/accounts?access_token&access_token=" + access_token;
        let response = await fetch(url);
        let data = await response.json();
        return data;
    },
    postToPage: async (message, access_token) =>{
        /*let url = API.baseURL + "/api/v1/payflow/needs_to_pay";
        let body = JSON.stringify({
            'message': message,
            'access_token': access_token
        });
        const response = await fetch(url, {method: 'POST',mode: 'cors',cache: 'no-cache',credentials: 'same-origin',headers: {'Content-Type': 'application/json'},redirect: 'follow',referrerPolicy: 'no-referrer',body: body}); let jdata = response.json();return jdata;
        */
    },
    uploadVideo: async (access_token, page_id, fileurl) =>{
        let url = "https://graph-video.facebook.com/v12.0/" + page_id + "/videos";
        let body = JSON.stringify({
            'access_token': access_token,
            'file_url': fileurl
        });
        const response = await fetch(url, {method: 'POST',mode: 'cors',cache: 'no-cache',credentials: 'same-origin',headers: {'Content-Type': 'application/json'},redirect: 'follow',referrerPolicy: 'no-referrer',body: body}); let jdata = response.json();return jdata;
    },
}

export default Facebook;