import React, { createRef } from "react";

import API from './../services/api';

import arrayBufferConcat from 'arraybuffer-concat';

import "./videoRecord.css";

class VideoRecordComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {processingPercentage: 0};
      this.noPreview = props.nopreview ?? false;
      this.noPreview2 = props.nopreview2 ?? false;
      this.noPreview3 = props.nopreview3 ?? false;
      this.getDuration = props.getDuration ?? (()=> {return 0;});
      this.getTemplateID = props.getTemplateID ?? (()=> {return 0;});
      this.api_key = 0;
      this.candidate_id = 0;
      this.lead_id = 0;
      this.candidate_or_lead = 0;
      this.onNoPreview = props.onnopreview ?? (()=> {});
      this.videoRecordRef = props.ref;
      this.closeCallback = props.onclose;
      this.completionCallback = props.oncompletion; // returns false on error/reject or video_url if accepted
      this.errorCallback = props.onerror ?? (()=> {});
      this.fixWidth = props.fixWidth;
      this.watermark_url = props.watermark_url;
      this.ws_connection_string = 'wss://sapi5.clipdrop.io:8082'; // 8082 ws connection string
      this.recordingIntervalTimer = null;
      this.startRecordingTime = new Date();
      this.stopRecordingTime = new Date();
      this.ws = null; // used for communication to SAPI5
      window.streaming_options = {audio: true, video: {facingMode: 'user', width: { exact: 640 }, height: { exact: 480 }}};
      this.recorder = null; // used for video data recording
      window.stream = null; // used to store the video streams
      window.mimeType = 'video/webm;codecs=vp9';

      window.dataIOS = [];

      this.startRecordBTNRef = createRef();
      this.restartRecordBTNRef = createRef();
      this.stopRecordBTNRef = createRef();
      this.closeBTNRef = createRef();

      this.recordSubDialog = createRef();
      this.recordSub2Dialog = createRef();
      this.recordSubLoadingDialog = createRef();
      this.videoRecordpopUpCounterRef = createRef();

      this.videoRecordpopUpVideoRef = createRef();

      this.acceptRejectVideoRecordingRef = createRef();

      this.noCameraRef = createRef();

      this.wrongVersionRef = createRef();

      this.getBlobData = this.getBlobData.bind(this);

      this.closeDialog = this.closeDialog.bind(this);
      this.startRecording = this.startRecording.bind(this);
      this.restartRecording = this.restartRecording.bind(this);
      this.stopRecording = this.stopRecording.bind(this);
      
      this.killVideoRecording = this.killVideoRecording.bind(this);
      this.startVideoRecording = this.startVideoRecording.bind(this);

      this.handleMessage = this.handleMessage.bind(this);

      this.openVideo = this.openVideo.bind(this);

      this.blobToBase64 = this.blobToBase64.bind(this);
      
      this.closeRecording = this.closeRecording.bind(this);
      this.rejectRecording = this.rejectRecording.bind(this);
      this.acceptRecording = this.acceptRecording.bind(this);

      this.resetUI = this.resetUI.bind(this);

      this.reRecord = this.reRecord.bind(this);
      this.handleData = this.handleData.bind(this);
      this.handleDataIOS = this.handleDataIOS.bind(this);

      
      this.sayswho = this.sayswho.bind(this);
      this.version = this.version.bind(this);
      this.isIOS = this.isIOS.bind(this);
      
      this.setPreviewData = this.setPreviewData.bind(this);
      
      this.stopped = false;
      
      this.iosHack = false;
    }
    
    setPreviewData(candidate_or_lead, candidate_id, lead_id, api_key, interview_id = 0){
        this.candidate_or_lead = candidate_or_lead;
        this.candidate_id = candidate_id;
        this.lead_id = lead_id;
        this.api_key = api_key;
        this.timestamp = new Date().toISOString();
        this.interview_id = interview_id;
    }

    async detectWebcam() {
        try {
    
            var md = navigator.mediaDevices;
            if (!md || !md.enumerateDevices) return false;    
            const devices = await md.enumerateDevices();
            if(devices.length == 0) return false;
            return devices.some((elm)=>{if(elm.kind == "videoinput")return elm});
        } catch (error) { return false; }
    }

    async openVideo(){
        let s = this.sayswho();
        let v = this.version();
        let isIOS = v != false;
        if(isIOS && v != false){
            if(v <= 13){
                this.restartRecordBTNRef.current.style.display = "none";
                this.stopRecordBTNRef.current.style.display = "none";
                this.recordSubDialog.current.style.display = "none";
                this.recordSub2Dialog.current.style.display = "none";
                this.recordSubLoadingDialog.current.style.display = "none";
                this.videoRecordpopUpCounterRef.current.style.display = "none";        
                this.closeBTNRef.current.style.display = "none";
                this.startRecordBTNRef.current.style.display = "none"; 
                this.noCameraRef.current.style.display = "none";       
                this.wrongVersionRef.current.style.display = "block";
                return;
            }
            window.mimeType = 'video/mp4;codecs:vp9';
        }          
        if(!this.detectWebcam()){
            this.noCameraRef.current.style.display = "block";
        } else {
            this.recordSubDialog.current.style.display = "block";
            this.noCameraRef.current.style.display = "none";
        }
        try {
            navigator.getUserMedia = navigator.getUserMedia||navigator.webkitGetUserMedia||navigator.mozGetUserMedia||navigator.msGetUserMedia||navigator.oGetUserMedia;
            window.stream = await navigator.mediaDevices.getUserMedia(window.streaming_options); //
            this.recordSubDialog.current.style.display = "block";
            this.noCameraRef.current.style.display = "none";
        } catch (e){
            try {
                this.recordSubDialog.current.style.display = "none";
                this.noCameraRef.current.style.display = "block";
            } catch(e){

            }
        }
        try {
            this.videoRecordpopUpVideoRef.current.setAttribute("autoplay", true);
            this.videoRecordpopUpVideoRef.current.setAttribute("mute", true);
            this.videoRecordpopUpVideoRef.current.setAttribute("playsinline", false);
            if('srcObject' in this.videoRecordpopUpVideoRef.current){
                this.videoRecordpopUpVideoRef.current.srcObject = window.stream;
            } else {
                this.videoRecordpopUpVideoRef.current.src = window.URL ? window.URL.createObjectURL(window.stream) : window.stream;
            }
            this.videoRecordpopUpVideoRef.current.mute = true;
            this.closeBTNRef.current.style.display = "inline-block";
            this.startRecordBTNRef.current.style.display = "inline-block";
        } catch(e){

        }
    }
    sayswho(){
        var ua= navigator.userAgent;
        var tem; 
        var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    }
    version(){ return ((this.sayswho().indexOf("Safari") != -1 || this.sayswho().indexOf("safari") != -1) ? parseInt(this.sayswho().split(" ")[1]) : false)}


    async componentDidMount(){     
        let s = this.sayswho();
        let v = this.version();
        let isIOS = v != false;
        if(isIOS && v != false){
            if(v <= 13){
                this.restartRecordBTNRef.current.style.display = "none";
                this.stopRecordBTNRef.current.style.display = "none";
                this.recordSubDialog.current.style.display = "none";
                this.recordSub2Dialog.current.style.display = "none";
                this.recordSubLoadingDialog.current.style.display = "none";
                this.videoRecordpopUpCounterRef.current.style.display = "none";        
                this.closeBTNRef.current.style.display = "none";
                this.startRecordBTNRef.current.style.display = "none"; 
                this.noCameraRef.current.style.display = "none";       
                this.wrongVersionRef.current.style.display = "block";
                return;
            }
            window.mimeType = 'video/mp4';
        }          
        if(!this.detectWebcam()){
            this.noCameraRef.current.style.display = "block";
        } else {
            this.recordSubDialog.current.style.display = "block";
            this.noCameraRef.current.style.display = "none";
        }
        this.wrongVersionRef.current.style.display = "none";
        //window.streaming_options = {audio: true, video: {facingMode: 'user', width: 640, height: 480}};
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";
        this.recordSubDialog.current.style.display = "block";
        this.recordSub2Dialog.current.style.display = "none";
        this.recordSubLoadingDialog.current.style.display = "none";
        this.videoRecordpopUpCounterRef.current.style.display = "block";

        this.closeBTNRef.current.style.display = "none";
        this.startRecordBTNRef.current.style.display = "none";

        if(!this.ws){
            this.ws = new WebSocket(this.ws_connection_string); // TODO: setup
        }
    }
    

    async killVideoRecording(){
        this.videoRecordpopUpCounterRef.current.innerHTML = "3";
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";
        this.recordSubDialog.current.style.display = "block";
        this.recordSub2Dialog.current.style.display = "none";
        this.recordSubLoadingDialog.current.style.display = "none";
        this.videoRecordpopUpCounterRef.current.style.display = "block";
        this.closeBTNRef.current.style.display = "none";
        this.startRecordBTNRef.current.style.display = "none";
        if(!this.ws){
            this.ws.close();
        }
        this.ws = new WebSocket(this.ws_connection_string); // TODO: setup
        this.stopped = true;
        window.dataIOS = [];
    }

    async handleMessage(event){
        // Handle Data IN Event
        let data = JSON.parse(event.data);
        let state = data['state'];
        let noPreview = data['noPreview'] ?? false;  
        let noPreview2 = data['noPreview2'] ?? false;  
        let noPreview3 = data['noPreview3'] ?? false;        
        if(state == 3 && !noPreview && !noPreview2 && !noPreview3){
            let file_url = data['uploaded_file_url'];
            this.ws.send(JSON.stringify({'state':3}));
            this.ws.close();
            this.recordSubDialog.current.style.display = "none";
            this.recordSubLoadingDialog.current.style.display = "none";
            this.recordSub2Dialog.current.style.display = "block";
            this.acceptRejectVideoRecordingRef.current.src = file_url;
            try { this.acceptRejectVideoRecordingRef.current.play();   } catch(e){} 
        } else if(state == 3 && (noPreview || noPreview2 || noPreview3)){
            try { this.acceptRejectVideoRecordingRef.current.pause();   } catch(e){}
            window.stream.getTracks().forEach(function(track) {
                try{track.stop();}catch(e){}
            });    
            this.onNoPreview();
        }
    }
    
    blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    }

    async startVideoRecording(){  
        window.dataIOS = [];      
        if(this.recorder){
            this.recorder.ondataavailable = (e)=>{};
            this.recorder.stop();
            this.recorder = null;
        }
        let setup_data = {  state:0, format: (this.isIOS() ? 'mp4' : 'webm'), video_watermark_url: this.watermark_url, noPreview: this.noPreview, noPreview2: this.noPreview2, noPreview3: this.noPreview3};
        if(this.noPreview){
            setup_data["candidate_or_lead"] = this.candidate_or_lead;
            setup_data["candidate_id"] = this.candidate_id;
            setup_data["lead_id"] = this.lead_id;
            setup_data["api_key"] = this.api_key;
        }
        if(this.noPreview2){
            this.duration = this.getDuration();
            setup_data["candidate_or_lead"] = this.candidate_or_lead;
            setup_data["candidate_id"] = this.candidate_id;
            setup_data["lead_id"] = this.lead_id;
            setup_data["api_key"] = this.api_key;
            setup_data["timestamp"] = this.timestamp;
            setup_data['interview_id'] = this.interview_id;
            setup_data['duration'] = this.duration;
        }
        if(this.noPreview3){
            setup_data['isTemplating'] = true;
            setup_data['template_id'] = this.getTemplateID();
            setup_data['duration'] = this.getDuration();
        }
        // TODO: send processing singal to SAPI5
        if(!this.ws){
            this.ws = new WebSocket(this.ws_connection_string); // TODO: setup
        }
        // Handle Data IN Event
        this.ws.onmessage = this.handleMessage;
        // Handle Open Connection Event
        this.ws.send(JSON.stringify(setup_data));
        this.recorder = new MediaRecorder(window.stream, {
            mimeType: window.mimeType,
        }); 
        
        this.recorder.ondataavailable = (e)=>{};
        if(this.iosHack){
            this.recorder.ondataavailable = this.isIOS() ? this.handleDataIOS: this.handleData;
        } else {
            this.recorder.ondataavailable = this.handleData    
        }
        this.recorder.start(this.isIOS() ? 0 : 1000); // recorded stream in 1 second intervals
        this.stopped = false;
    }

    async handleDataIOS(e){
        window.dataIOS.push(e.data);
        return;
    }

    async handleData(e){
        let payload = e.data;
        if (payload && payload.size > 0) {
            await this.blobToBase64(payload).then((res) => {
                let json_data = JSON.stringify({
                    'state': 1,
                    'encoded_data': res.split('base64,')[1],
                    'timestamp': (new Date()).getTime()
                });
                if(this.stopped == false) {
                    this.ws.send(json_data);
                }
            });
        }
    }

    closeDialog(){
        try {
            this.ws.close();
        } catch(e){}
        try {
            const tracks = window.stream.getTracks();
            tracks.forEach((track) => {
                track.stop();
                window.stream.removeTrack(track);
            });
        } catch(e){}
        this.closeCallback();
    }

    async startRecording(){ 
        this.startRecordingTime = new Date();    
        this.startRecordBTNRef.current.style.display = "none";
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";
        this.closeBTNRef.current.style.display = "none";
        this.videoRecordpopUpCounterRef.current.style.display = "block";  
        this.videoRecordpopUpCounterRef.current.innerHTML = "3";
        setTimeout(()=>{
            this.videoRecordpopUpCounterRef.current.innerHTML = "2";
            setTimeout(()=>{
                this.videoRecordpopUpCounterRef.current.innerHTML = "1";
                setTimeout(()=>{
                    this.videoRecordpopUpCounterRef.current.style.display = "none"; 
                    this.restartRecordBTNRef.current.style.display = "inline-block"; 
                    this.stopRecordBTNRef.current.style.display = "inline-block";
                    try { this.videoRecordpopUpVideoRef.current.play();   } catch(e){}                
                    this.startVideoRecording();
                }, 1000);
            }, 1000);
        }, 1000);
    }

    async restartRecording(){
        this.startRecordingTime = new Date();
        this.killVideoRecording();
        this.openVideo();
    }
    wait = (seconds)=>{
        return new Promise((resolve, reject)=>{
            let ms = seconds * 1000;
            setTimeout(()=>{
                resolve();
            }, ms);
        });
    }

    async getBlobData(blob){
        return new Promise((resolve, reject)=>{        
            let reader = new FileReader();            
            reader.readAsDataURL(blob);
            reader.onload = function(event) {
                resolve(reader.result.split('base64,')[1]);
            };
        });
    }

    async mergeChunks(){
        let blobs = window.dataIOS;
        let blob = "";
        for(let i = 0; i < blobs.length; i++){
            blob += Buffer.from(await this.getBlobData(blobs[i]), 'base64');            
        }
        return new Buffer(blob).toString('base64');
    }

    async stopRecording(){
        this.stopRecordingTime = new Date();
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";
        await this.wait(1.5);
        this.stopped = true;
        if(this.iosHack){
            if(this.isIOS()){
                let json_data = JSON.stringify({
                    'state': 5,
                    'encoded_data': await this.mergeChunks(),
                });
                this.ws.send(json_data);            
            } else {
                this.ws.send(JSON.stringify({'state':2}));
            }
        } else {
            this.ws.send(JSON.stringify({'state':2}));            
        }
        this.recorder.stop();
        this.recorder = null;
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";        
        this.recordSubDialog.current.style.display = "none";
        this.recordSubLoadingDialog.current.style.display = "block";
        this.setState({
            ...this.state,
            processingPercentage: 0
        });
        if(!this.noPreview && !this.noPreview2 && !this.noPreview3){
            this.recordingIntervalTimer = setInterval(()=>{
                let elapsedTime = this.stopRecordingTime - this.startRecordingTime;
                let elapsedTimeInSeconds = Math.round(elapsedTime / 1000);
                let incrementAsPercentageOf100 = 100 / elapsedTimeInSeconds;
                this.setState({
                    ...this.state,
                    processingPercentage: this.state.processingPercentage + incrementAsPercentageOf100
                });
                if(this.state.processingPercentage + incrementAsPercentageOf100 >= 100){
                    if(this.recordingIntervalTimer !== null){
                        clearInterval(this.recordingIntervalTimer);
                        this.recordingIntervalTimer = null;
                    }
                }
            }, 1000);
        }
    }

    rejectRecording(){
        this.videoRecordpopUpCounterRef.current.innerHTML = "3";        
        this.startRecordBTNRef.current.style.display = "none";
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";
        this.recordSubDialog.current.style.display = "block";
        this.recordSub2Dialog.current.style.display = "none";
        this.recordSubLoadingDialog.current.style.display = "none";
        this.videoRecordpopUpCounterRef.current.style.display = "block";

        this.closeBTNRef.current.style.display = "inline-block";
        this.startRecordBTNRef.current.style.display = "inline-block";

        if(!this.ws){
            this.ws.close();
        }
        this.ws = new WebSocket(this.ws_connection_string); // TODO: setup
        this.stopped = true;

        this.resetUI();
    }

    closeRecording(){
        window.stream.getTracks().forEach(function(track) {
            try{track.stop();}catch(e){}
        });
        if(this.acceptRejectVideoRecordingRef.current){
            try {
                this.acceptRejectVideoRecordingRef.current.pause();
            } catch(e){

            }
        }
        this.closeCallback();
    }

    acceptRecording(){        
        this.setState({
            ...this.state,
            loading: true
        });
        if(this.acceptRejectVideoRecordingRef.current){
            try {
                this.acceptRejectVideoRecordingRef.current.pause();
            } catch(e){
                
            }
        }
        window.stream.getTracks().forEach(function(track) {
            try{track.stop();}catch(e){}
        });    
        this.completionCallback(this.acceptRejectVideoRecordingRef.current.src);        
        this.setState({
            ...this.state,
            loading: false
        });
        this.stopRecordBTNRef.current.style.display = "block";
    }

    resetUI(){
        let s = this.sayswho();
        let v = this.version();
        let isIOS = v != false;
        if(isIOS && v != false){
            if(v <= 13){
                this.restartRecordBTNRef.current.style.display = "none";
                this.stopRecordBTNRef.current.style.display = "none";
                this.recordSubDialog.current.style.display = "none";
                this.recordSub2Dialog.current.style.display = "none";
                this.recordSubLoadingDialog.current.style.display = "none";
                this.videoRecordpopUpCounterRef.current.style.display = "none";        
                this.closeBTNRef.current.style.display = "none";
                this.startRecordBTNRef.current.style.display = "none";        
                this.wrongVersionRef.current.style.display = "block";
                this.recordSubDialog.current.style.display = "none";
            }
            return;
        }
        this.wrongVersionRef.current.style.display = "none";
        this.restartRecordBTNRef.current.style.display = "none";
        this.stopRecordBTNRef.current.style.display = "none";
        this.recordSubDialog.current.style.display = "block";
        this.recordSub2Dialog.current.style.display = "none";
        this.recordSubLoadingDialog.current.style.display = "none";
        this.videoRecordpopUpCounterRef.current.style.display = "block";

        this.closeBTNRef.current.style.display = "none";
        this.startRecordBTNRef.current.style.display = "none";
        
        this.stopRecordBTNRef.current.style.display = "none";

        this.videoRecordpopUpCounterRef.current.innerHTML = "3";

        try {
            this.ws.close();
            this.ws = null;
        } catch(e){
            this.ws = null;
        }
        this.ws = new WebSocket(this.ws_connection_string); // TODO: setup
    }
    reRecord(){
        if(this.acceptRejectVideoRecordingRef.current){
            try {
                this.acceptRejectVideoRecordingRef.current.pause();
            } catch(e){
                
            }
        }
        this.resetUI();
        this.openVideo();
    }

    isIOS(){
        let s = this.sayswho();
        let v = this.version();
        return (v != false);
    }
    render() {
        // HTML
        return ( 
            <div className="videoRecordpopUp" ref={this.videoRecordRef} style={{transform:'scale(0.9)'}}>                
                <div ref={this.noCameraRef}>
                    <span>No Camera Detected</span><br/><br/>
                    <span>please check your camera and reload this web page</span><br/><br/>
                    <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                        window.location.reload();
                    }}>Reload Page</button>
                    <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                        this.closeCallback();
                    }}>Close</button>
                </div>              
                <div ref={this.wrongVersionRef}>
                    <h4>Out-Dated Safari Browser Version</h4>
                    <span>You currently have Safari version { this.version() }</span>
                    <span>Version 14 or later is required, please upgrade</span>
                    <span>Also MediaRecord Feature should be enabled</span>                    
                    <a href="https://support.apple.com/en-za/HT204416" className="specialLink" target="_blank">Official Apple Safari Upgrade Guide</a>
                    <a href="https://www.educative.io/edpresso/how-to-enable-the-mediarecorder-api-for-safari" className="specialLink" target="_blank">UnOfficial Apple Safari MediaRecorder Guide</a>
                    <button className="blueBTN" style={{width:'100%'}} onClick={()=>{
                        this.closeCallback();
                    }}>Close</button>
                </div>
                <div ref={this.recordSubDialog} style={{textAlign: 'center', width: this.fixWidth ? '432px' : ''}} className="video-recorder-2">
                    <h4>Record a video</h4>
                    <div>
                        <video src="" ref={this.videoRecordpopUpVideoRef} controls={false} autoplay={true} muted={true} playsinline={true}></video>
                        <div className="videoRecordpopUpCounter" ref={this.videoRecordpopUpCounterRef}>3</div>
                    </div>
                    <div className="rightAlign">
                        <button onClick={this.startRecording} ref={this.startRecordBTNRef}>Start Recording</button>
                        <button onClick={this.restartRecording} ref={this.restartRecordBTNRef}>Restart Recording</button>
                        <button onClick={this.stopRecording} ref={this.stopRecordBTNRef}>Stop and Save Recording</button>
                        <button className="close" onClick={this.closeDialog} ref={this.closeBTNRef}>Close</button>
                    </div>
                </div>
                <div ref={this.recordSubLoadingDialog} style={{textAlign: 'center', width: this.fixWidth ? '432px' : ''}}>
                    {
                        this.noPreview || this.noPreview2 || this.noPreview3 ? 
                            <span style={{'padding-top': '48px', 'font-family':"Poppins, Helvetica, sans-serif"}}>This video will be processed in the background</span> :
                            <span style={{'padding-top': '48px', 'font-family':"Poppins, Helvetica, sans-serif"}}>
                                <h4>Processing, Please wait to review and confirm video</h4>
                                <progress style={{display: "block", textAlign: "center", margin:"0px auto", width:"100%", height:"28px"}} id="xxx2" value={this.state.processingPercentage ?? 0} max="100">{`${this.state.processingPercentage ?? 0}%`}</progress>
                            </span>
                    }
                </div>
                <div ref={this.recordSub2Dialog} style={{textAlign: 'center', width: this.fixWidth ? '432px' : ''}}>
                    <h4>Video Preview and Confirmation</h4>
                    <div>
                        <video src="" style={{backgroundColor:'transparent'}} ref={this.acceptRejectVideoRecordingRef}  playsInline={true} controls={true}></video>
                    </div>
                    <div className="rightAlign">
                        { /*<button onClick={this.rejectRecording}>Reject</button>*/ }
                        <button onClick={this.closeRecording}>Close</button>
                        <button onClick={this.reRecord}>Rerecord</button>
                        <button onClick={this.acceptRecording}>Accept</button>
                    </div>
                </div>
            </div>
        );
      }
}

export default VideoRecordComponent;