import React, { useState, useEffect, useRef } from 'react';
import OpenRate from './openrate';

export default function MultiOpenRate(props:any){
    const { interview } = props;
    const [ dataInterview, setDataInterview ] = useState({
        total: 0,
        value: 0,
        opens: 0,
    });
    const [ dataBespoke, setDataBespoke ] = useState({
        total: 0,
        value: 0,
        opens: 0,
    });
    const [ dataCampaign, setDataCampaign ] = useState({
        total: 0,
        value: 0,
        opens: 0,
    });

    useEffect(()=>{
        if(!interview) return;
        // Calculate Data
        let tmp = 0; // used for swapping
        // => Load Interview
        let iValue = Math.abs(interview.open_rate.interview >= 1 && interview.interview_count == 0 ? 1 : interview.open_rate.interview);
        let iTotal = Math.abs(interview.interview_count - interview.open_rate.interview)
        let iOpens = Math.abs(interview.openDiff.interview ?? 0);
        if(iTotal === 0 && iValue >= 1){
            iTotal = iValue;
        }
        if(iValue > iTotal){
            tmp = iTotal
            iTotal = iValue;
            iValue = tmp;
        }
        // => Load Bespoke
        let bValue = Math.abs(interview.open_rate.bespoke >= 1 && interview.sent_count == 0 ? 1 : interview.open_rate.bespoke);
        let bTotal = Math.abs(interview.sent_count - interview.open_rate.bespoke);
        let bOpens = Math.abs(interview.openDiff.bespoke ?? 0);
        if(bTotal === 0 && bValue >= 1){
            bTotal = bValue;
        }
        if(bValue > bTotal){
            tmp = bTotal
            bTotal = bValue;
            bValue = tmp;
        }
        // => Load Campaign
        let cValue = Math.abs(interview.open_rate.campaign);
        let cTotal = Math.abs(interview.sent_count_marketing - interview.open_rate.campaign);
        let cOpens = Math.abs(interview.openDiff.campaign ?? 0);
        if(cTotal === 0 && cValue >= 1){
            cTotal = cValue;
        }
        if(cValue > cTotal){
            tmp = cTotal
            cTotal = cValue;
            cValue = tmp;
        }
        // Load Data
        setDataInterview({
            total: iTotal,
            value: iValue,
            opens: iOpens
        });
        setDataBespoke({
            total: bTotal,
            value: bValue,
            opens: bOpens
        });
        setDataCampaign({
            total: cTotal,
            value: cValue,
            opens: cOpens
        });
    }, [interview]);

    return <>
        <OpenRate text={"Interview"} data={dataInterview} url={`/vacancy/${interview.id}?#interview`}/>
        <OpenRate text={"Bespoke"} data={dataBespoke} url={`/vacancy/${interview.id}?#marketing`}/>
        <OpenRate text={"Campaign"} data={dataCampaign} url={`/vacancy/${interview.id}?#bulkmarketing`}/>
    </>;
}