import React, { useState, useEffect, useRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
import jQuery from "jquery";
import $ from "jquery";
import './interviewFolder.css';
import API from '../../../services/api';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateTime } from 'luxon';
import { IoIosPaperPlane } from 'react-icons/io';
import { BsArrowDownUp, BsPencil, BsPersonPlusFill } from 'react-icons/bs';
import { RiShareForward2Fill } from 'react-icons/ri';
import { AiFillEye, AiFillStar } from 'react-icons/ai';
import { FaRegCommentDots } from 'react-icons/fa';
import { MdClear, MdOutlineAssessment } from 'react-icons/md';
import { ImBin2 } from 'react-icons/im';

import play_icon_grey from "./../../../media/play_icon_grey.png";
import play_icon_color from "./../../../media/play_icon_color.png";

import assessment_icon_color from "./../../../media/assessment-blue.png";
import assessment_icon_grey from "./../../../media/assessment-grey.png";
import useAbortableAxios from '../../../hooks/useAbortableAxios';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CANDIDATE_PULLING_SECONDS } from './CANDIDATE_PULLING_SECONDS';

interface BespokeFolderProps {
    apiKey: string;
    interview: any;
    interviewId: any;
    reloadInterview: () => void;
    showAssessmentPopUp: (survey_data:any) => void;
    addNewCandidateCallback: (callback: (data:any) => Promise<null | {
      created_at: null | string | Date
      email: null | string
      id: number
      interview_id:  null | number
      linkedin_url: null | string
      marketing_type: null | number
      name: null | string
      phone: null | string
      processing_gif: null | number
      processing_video: null | number 
      updated_at: null | string | Date 
  }>) => void;
    executeShowAddNewCandidateCallback: () => void;
    addEditCandidateCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeShowEditCandidateCallback: (data:any) => void;
    handleCandidateReviewCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleCandidateReviewCallback: (data:any) => void;
    candidateInvitePopupCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleCandidateInvitePopupCallback: (data:any) => void;
    handleNameEmailCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleNameEmailCallback: (data:any) => void;
    executeHandleCandidateComments: (data:any) => void;
    handleFlowCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleFlowCallback: (data:any) => void;
    handleCustomVideoCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleCustomVideoCallback: (data:any) => void;
    executeShowVideoPreview: (data:any) => void;
    handleCustomVideoSubtitleCallback: (callback: (video_url:string) => Promise<boolean>) => void;
}

interface CandidateEditInterface {
  id: number;
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}
interface CandidateAddInterface {
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}

const theme = createTheme({
    palette: {
      mode: 'light', // Force light mode
    },
} as any);

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        '&:hover': {
          color: '#1976D1'
        },
      },
    },
  }));

function BespokeFolderCandidateGrid({ loading, columns, rows, onPageChange, totalPages, pageSize, totalCandidates}) {
    return (<ThemeProvider theme={theme}>
    <DataGrid
      //loading={loading}
      paginationMode="server"
      rows={rows}
      columns={columns}
      disableColumnMenu={true}
      disableRowSelectionOnClick
      //onCellClick= {(param, event) => { event.defaultMuiPrevented = true; event.defaultPrevented = true; event.stopPropagation();}}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize
          }
        },
      }}
      pageSizeOptions={[pageSize]}
      onPaginationModelChange={(paginationModel) => {
        onPageChange(paginationModel.page);
      }}
      getRowId={(row) => row.id}
      getRowHeight={() => 'auto'}
      pagination
      rowCount={totalCandidates}
    />
  </ThemeProvider>)
}

function ActionDropDownMenuForList({handleEdit}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
        <MenuItem onClick={()=>{handleEdit(); handleClose();}} disableRipple>
          Edit
        </MenuItem>
        </StyledMenu>
      </div>
    );
  }




export default function BespokeFolder({apiKey, interview, interviewId, reloadInterview, showAssessmentPopUp, addNewCandidateCallback, executeShowAddNewCandidateCallback, addEditCandidateCallback, executeShowEditCandidateCallback, handleCandidateReviewCallback, executeHandleCandidateReviewCallback, candidateInvitePopupCallback, executeHandleCandidateInvitePopupCallback, handleNameEmailCallback, executeHandleNameEmailCallback, executeHandleCandidateComments, handleFlowCallback, executeHandleFlowCallback, handleCustomVideoCallback, executeHandleCustomVideoCallback, executeShowVideoPreview, handleCustomVideoSubtitleCallback}:BespokeFolderProps){
    // States
    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    const [ assessments, setAssessment ] = useState<any[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ candidates, setCandidates ] = useState<any[]>([]);
    const [ searchQuery, setSearchQuery ] = useState<string>("");
    const [ newSearchQuery, setNewSearchQuery ] = useState<string>("");
    const [ pageNumber, setPageNumber ] = useState<number>(0);
    const [ totalPages, setTotalPages ] = useState<number>(0);
    const [ totalCandidates, setTotalCandidates ] = useState<number>(0);
    const { loading:apiLoading, data:apiData, error:apiError, fetchData:fetchAPIData, setLoading:setAPILoading} = useAbortableAxios();
    // Pulling
    const pullCandidatesIntervalRef = useRef<any>(null);
    // Filters
    const [ filterCandidatesWithRecordedVideo, setFilterCandidatesWithRecordedVideo ] = useState<boolean>(false);
    const [ filterCandidatesWithNoRecordedVideo, setFilterCandidatesWithNoRecordedVideo ] = useState<boolean>(false);
    const [ filterCandidateReadyToSend, setFilterCandidateReadyToSend ] = useState<boolean>(false);
    const [ filterCandidatesHasBeenViewed, setFilterCandidatesHasBeenViewed ] = useState<boolean>(false);
    // Functions
    const loadCandidates = async (api_key:string, interview_id:string,
      xsearchQuery:string = "",
      xpageNumber:number = 0) => {
      if(pullCandidatesIntervalRef.current){
        clearInterval(pullCandidatesIntervalRef.current);
      }
      await fetchAPIData(API.baseURL + "/api/v1/candidate/getbespokesearch", {}, {
        api_key: api_key,
        interview_id: interview_id,
        q: xsearchQuery,
        page: xpageNumber
      }, "POST");
      pullCandidatesIntervalRef.current = setInterval(()=>{
        loadCandidates(api_key, interview_id);
      }, CANDIDATE_PULLING_SECONDS * 1000);
    };
    const openCandidateEdit = (candidate:any) => {
      addEditCandidateCallback(async (data:CandidateEditInterface): Promise<boolean> => {
        if(!data.name.trim()){
          toast.error("Invalid Name Entered");
          return false;
        }
        if(!data.email.trim()){
          toast.error("Invalid Email Address Entered");
          return false;
        }
        toast.info("Updating Candidate");
        let result = await API.candidate.update(apiKey, data.id, data.name, data.email, data.linkedin, data.phone_number);
        let success = result["success"];
        if(!success){
          toast.error(result["message"]);
          return false;
        }
        toast.success("Candidate Updated");
        toast.info("Reloading Candidates");
        loadCandidates(apiKey, interview.id, searchQuery, pageNumber);
        return true;
      });
      executeShowEditCandidateCallback(candidate);
    }
    const handleDelete = async (candidate:any) => {
      toast.info("Deleting Candidate");
      let result = await API.candidate.delete(apiKey, candidate.id);
      if(!result['success']){
        toast.error("Error deleting candidate");
        return;
      }
      toast.success("Candidate Deleted Successfully");
      loadCandidates(apiKey, interview.id, searchQuery, pageNumber);
      toast.info("Reloading Candidates");
    }
    const openCustomVideo = async (candidate:any) => {
      handleCustomVideoCallback(async (data:any): Promise<boolean> => {
          toast.info("Reloading Candidates");
          loadCandidates(apiKey, interview.id, searchQuery, pageNumber);
          return true;
      });
      executeHandleCustomVideoCallback({
        candidate_id: candidate.id,
        candidate_name: candidate.name
      });
    }
    const openCustomVideoPreview = async (candidate:any) => {
      handleCustomVideoSubtitleCallback(async (video_url:string)=>{
        if(!video_url) return true;
        const result = await API.candidate.setCustomVideo(apiKey, candidate.id, video_url);
        await loadCandidates(apiKey, interview.id, searchQuery, pageNumber);
        return true;
      })
      executeShowVideoPreview({
        video_url: candidate.custom_video_url
      });
    }
    const openMarketingLink = async (candidate:any) => {
        toast.info("Loading Marketing Link");
        let result = await API.candidate.getBespokeInvitation(apiKey, candidate.id);
        let candidateMarketingLink = result["text"];
        if(!candidateMarketingLink){
          toast.error("Error getting marketing link");
          return false;
        }
        candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
          if(!data.email.trim()){
            toast.error("Invalid Email Address Entered");
            return false;
          }
          if(!data.subject.trim()){
            toast.error("Invalid Subject Entered");
            return false;
          }
          if(!data.description.trim()){
            toast.error("Invalid Description");
            return false;
          }
          toast.info("Sending Email");
          let result2 = await API.email(apiKey, data.email, data.subject, data.description);
          let success2 = result["success"];
          if(!success2){
            toast.error(result2["message"]);
            return false;
          }
          toast.success("Email Sent");
          return true;
        })
        executeHandleCandidateInvitePopupCallback({
          email: candidate.email,
          subject: "Candidate Marketing Link",
          description: candidateMarketingLink,
        });
        return true;
    }
    const openShare = (candidate:any) => {
      handleNameEmailCallback(async (data:any): Promise<boolean> => {
          toast.info("Loading Share Link");
          const fullname = data.fullname;
          const email = data.email;          
          let result = await API.share.get(apiKey, candidate.id, interview.id, fullname, email);
          let candidateShareLink = result["link"];
          if(!candidateShareLink){
            toast.error("Error getting share link");
            return false;
          }
          candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
            if(!data.email.trim()){
              toast.error("Invalid Email Address Entered");
              return false;
            }
            if(!data.subject.trim()){
              toast.error("Invalid Subject Entered");
              return false;
            }
            if(!data.description.trim()){
              toast.error("Invalid Description");
              return false;
            }
            toast.info("Sending Email");
            let result2 = await API.email(apiKey, data.email, data.subject, data.description);
            let success2 = result["success"];
            if(!success2){
              toast.error(result2["message"]);
              return false;
            }
            toast.success("Email Sent");
            return true;
          })
          executeHandleCandidateInvitePopupCallback({
            email: email,
            subject: "Candidate Share Invitation Link",
            description: candidateShareLink,
          });
          return true;
      })
      executeHandleNameEmailCallback({
        title: "Enter Manager Details for Share Review"
      });
    }
    const openShareAndRate = (candidate:any) => {
      handleNameEmailCallback(async (data:any): Promise<boolean> => {
        toast.info("Loading Share Link");
        const fullname = data.fullname;
        const email = data.email;          
        let result = await API.shareAndRate.get(apiKey, candidate.id, interview.id, fullname, email);
        let candidateShareLink = result["link"];
        if(!candidateShareLink){
          toast.error("Error getting share link");
          return false;
        }
        candidateInvitePopupCallback(async (data:any): Promise<boolean> => {
          if(!data.email.trim()){
            toast.error("Invalid Email Address Entered");
            return false;
          }
          if(!data.subject.trim()){
            toast.error("Invalid Subject Entered");
            return false;
          }
          if(!data.description.trim()){
            toast.error("Invalid Description");
            return false;
          }
          toast.info("Sending Email");
          let result2 = await API.email(apiKey, data.email, data.subject, data.description);
          let success2 = result["success"];
          if(!success2){
            toast.error(result2["message"]);
            return false;
          }
          toast.success("Email Sent");
          return true;
        })
        executeHandleCandidateInvitePopupCallback({
          email: email,
          subject: "Candidate Share And Rate Invitation Link",
          description: candidateShareLink,
        });
        return true;
    })
    executeHandleNameEmailCallback({
      title: "Enter Manager Details for Share And Rate Review"
    });
    } 
    const getProcessingTag = (candidate:any) => {
      if (candidate.hasViewed && candidate.closed)
        return (
          <>
            <span
              data-tip
              data-for={`tooltip-${candidate.id}-bespoke`}
              className="tagBlue"
              style={{ marginLeft: '8px'}}
            >
              Viewed
            </span>
            <ReactTooltip
              id={`tooltip-${candidate.id}-bespoke`}
              aria-haspopup="true"
            >
              <span>
                <strong>Viewed At:</strong>&nbsp;
                {new Date(candidate.serverTime).toLocaleString()}
              </span>
            </ReactTooltip>
          </>
        );
      if (candidate.hasViewed && !candidate.closed)
        return (
          <>
            <span
              data-tip
              data-for={`tooltip-${candidate.id}-bespoke`}
              className="tagGreen"
              style={{ marginLeft: '8px'}}
            >
              New View
            </span>
            <ReactTooltip
              id={`tooltip-${candidate.id}-bespoke`}
              aria-haspopup="true"
            >
              <span>
                <strong>Viewed At:</strong>&nbsp;
                {new Date(candidate.serverTime).toLocaleString()}
              </span>
            </ReactTooltip>
          </>
        );
      if (candidate.emailed) return <div className="blueBTN7"
      style={{ marginLeft: '8px'}}>Emailed</div>;
      if (candidate.copied) return <div className="blueBTN7"
      style={{ marginLeft: '8px'}}>Copied</div>;
      if (candidate.custom_video_url && candidate.processing_done)
        return <div className="blueBTN7"
        style={{ marginLeft: '8px'}}>Ready to send</div>;
      if (candidate.processing_gif)
        return (
          <div className="blueBTN7"
          style={{ marginLeft: '8px'}}>
            <img
              className="spin"
              src={play_icon_color}
              alt=""
              style={{
                transform: "translate(-3px, 3px)",
                width: "18px",
                height: "16px",
                pointerEvents: "none",
              }}
            />
            <span
              style={{ transform: "translateY(-3px)", display: "inline-block" }}
            >
              Processing
            </span>
          </div>
        );
      if (candidate.processing_video)
        return (
          <div className="blueBTN7"
          style={{ marginLeft: '8px'}}>
            <img
              className="spin"
              src={play_icon_color}
              alt=""
              style={{
                transform: "translate(-3px, 3px)",
                width: "18px",
                height: "16px",
                pointerEvents: "none",
              }}
            />
            <span
              style={{ transform: "translateY(-3px)", display: "inline-block" }}
            >
              Processing
            </span>
          </div>
        );
    }
    // Data Columns
    const dataGridColumns = [
        {field: 'candidate_selection', headerName: 'Candidates', width: 380, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return <>
                <ActionDropDownMenuForList 
                    handleEdit={async ()=>{
                      let candidatesSelected = document.querySelectorAll('input[type="checkbox"].interview_candidates:checked');
                      if(candidatesSelected.length <= 0){
                        toast.info("You need to select 1 candidate");
                        return;
                      }
                      if(candidatesSelected.length > 1){
                        toast.info("You need to select only 1 candidate");
                        return;
                      }
                      toast.info("Loading");
                      const candidate_id = parseInt(candidatesSelected[0].getAttribute('candidate-id'));
                      const result = await API.candidate.get(apiKey, candidate_id);
                      openCandidateEdit(result['candidate']);
                    }}
                />
                <span style={{ color:'#E1E1E1 !important', marginLeft:'32px', fontSize:'12pt'}}>Candidates</span>
            </>;
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                <input
                    type="checkbox"
                    candidate-id={row.id}
                    data-candidate-review={row.has_review}
                    className="interview_candidates"
                    style={{ transform: "scale(1.5);" }}
                />
                <div className="newCandidateUserIcon2"></div>
                <div className='new-candidate-info'>
                    <span><strong>{ row.name }</strong></span>
                    <span>{ row.email }</span>
                    <span>{ row.phone ?? "" }</span>
                </div>
            </>;
        }},
        {field: 'candidate_id',  headerName: 'ID', width: 64, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>ID</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return `${row.id}`;
        }},
        {field: 'candidate_sign_up_date',  headerName: 'Sign Up Date', width: 150, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Sign Up Date</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            const dt = row.created_at;
            if (!dt) return "";
            let d = DateTime.fromISO(dt).toFormat("dd-MM-yyyy HH:mm");
            if (d == "Invalid DateTime") {
                return DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat(
                    "dd-MM-yyyy HH:mm"
                );
            } else {
                return d;
            }
        }},
        {field: 'candidate_actions',  headerName: 'Actions', width: 350, sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
          return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Actions</span>
        },
        renderCell: (params) => {
            const { value, row, rowIndex } = params;
            return <>
                <BsPencil
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    title="Edit Candidate"
                    onClick={()=>{openCandidateEdit(row)}}
                />
                { (row.custom_video_url && row.processing_done) ?
                  <div
                    className="candidateMiniButton special"
                    candidate-id={row.id}
                    candidate-video={
                      row.custom_video_url
                    }
                    title="Preview Custom Video"
                    onClick={()=>{openCustomVideoPreview(row)}}
                    style={{ transform:'none', marginRight:'4px' }}
                  >
                    <img
                      src={play_icon_color}
                      alt=""
                      style={{
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div> : <></>
                }
                { (!row.custom_video_url && !row.processing_done) ? 
                  <div
                    className="candidateMiniButton special"
                    candidate-id={row.id}
                    title="Upload/Record Custom Video"
                    onClick={()=>{openCustomVideo(row);}}
                    style={{ transform:'none', marginRight:'4px' }}
                  >
                    <img
                      src={play_icon_grey}
                      alt=""
                      style={{
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div> : <></>
                }
                {  (row.custom_video_url && row.processing_done) ? 
                  <IoIosPaperPlane
                    className="candidateMiniButton"
                    candidate-id={row.id}
                    title="Generate Marketing Link"
                    onClick={()=>{openMarketingLink(row);}}
                  /> : <></>
                }
                <ImBin2 className="candidateMiniButton" style={{marginLeft:'4px'}} title="Delete Candidate" onClick={()=>{handleDelete(row);}}/>
                {row.has_review && <RiShareForward2Fill
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    onClick={()=>{openShare(row);}}
                    title="Share"
                />}
                {row.has_review && <AiFillStar
                    className="candidateMiniButton withspace"
                    candidate-id={row.id}
                    onClick={()=>{openShareAndRate(row);}}
                    title="Share and Rate"
                />}
                {
                  getProcessingTag(row)
                }
            </>
        }},
    ];
    // Effect
    useEffect(()=>{
      if(!apiKey) return;
      if(!interview) return;
      loadCandidates(
        apiKey,
        interviewId,
        searchQuery, 
        pageNumber);
    }, [
      apiKey,
      interviewId,
      searchQuery,
      pageNumber
    ]);
    
    useEffect(() => {
      return () => {
        if(pullCandidatesIntervalRef.current){
          clearTimeout(pullCandidatesIntervalRef.current);
        }
      };
    }, []);

    useEffect(()=>{
      if(!apiData) return;
      // Filter Data
      let dataToFilter:any[] = apiData["candidates"];
      if(filterCandidatesWithRecordedVideo){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (!!c.custom_video_url);
        });
      }
      if(filterCandidateReadyToSend){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (c.processing_done === 1) && (c.emailed === 0) && (c.copied === 0);
        });
      }
      if(filterCandidatesHasBeenViewed){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (c.hasViewed === 1);
        });
      }
      if(filterCandidatesWithNoRecordedVideo){
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return (!c.custom_video_url);
        });
      }
      setCandidates(dataToFilter);
      setTotalPages(apiData["totalPages"]);
      setTotalCandidates(apiData["totalCandidates"]);
    }, [apiData, filterCandidatesWithRecordedVideo, filterCandidateReadyToSend, filterCandidatesHasBeenViewed, filterCandidatesWithNoRecordedVideo])
    
    // Renders
    if(!interview){
        return <div className='interview-folder-container'>Loading Folder Data</div>
    }
    return <><div className='interview-folder-container'>
        <div className='nice-form-2'>
            <div>
              <h4 style={{display:'block', marginRight:'8px'}}>Actions</h4>
              <button style={{display:'inline-block', fontSize:'16pt'}} className={interview.bespoke_start_interactive === 1 ? 'button-primary-locked-out' : "button-primary-orange"} onClick={ e => { 
                  if(interview.bespoke_start_interactive === 1){
                    toast.info("Flow already completed");
                    return;
                  }               
                  handleFlowCallback(async (data:any): Promise<boolean> => {
                    toast.info("Updating Folder");
                    let result = await API.interview.startInteractivity(apiKey,interview.id,0,1);
                    toast.info("Reloading Interview");
                    reloadInterview();
                    return true;
                  });
                  executeHandleFlowCallback({
                    interview_id: interview.id
                  });
              }}>Start</button>
              <button style={{display:'inline-block', fontSize:'16pt'}}className='button-primary-blue' onClick={ e => setShowSettings(x => !x)}>Edit</button>
            </div>
            { showSettings && <>            
            <h4>Settings</h4>
            <div className='greyed'>
                <label htmlFor="allow-candidate-participation">
                <span>Allow candidate to participate in the interview</span>
                </label>
                <select name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.marketing_interview.toString()} onChange={ async e => {
                    toast.info("Updating Candidate Interview Participation");
                    let stateValueAsNumber = parseInt(e.target.value);
                    let result = await API.interview.setBespokeAllowCandidateInterivew(apiKey, interview.id, stateValueAsNumber);
                    toast.success("Updated");
                    reloadInterview();
                }}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
                <h4>Custom Thumbnail Generation and Marketing Text</h4>
                <label htmlFor="bespoke-thumbnail-text">
                    <span>Thumbnail Text</span>
                </label>
                <select
                  id="bespoke-thumbnail-text"
                  name="bespoke-thumbnail-text"
                  defaultValue={interview.custom_thumbnail_type.toString()} onChange={ async e => {
                    toast.info("Updating Candidate Thumbnail Type");
                    let stateValueAsNumber = parseInt(e.target.value);
                    let result = await API.interview.setCustomThumbnailType(apiKey, interview.id, stateValueAsNumber);
                    toast.success("Updated");
                    reloadInterview();
                }}
                >
                  <option value="0">Hi {"{candidate}"}</option>
                  <option value="1">Hello {"{candidate}"}</option>
                  <option value="2">Hello {"{candidate}"}</option>
                  <option value="3">Custom Message</option>
                </select>
                {
                  (interview && interview.custom_thumbnail_type === 3) && <div className="customMessageContainer">
                    <label htmlFor="bespoke-thumbnail-text-data">
                        <span>Enter a custom message</span>
                    </label>
                    <input
                      type="text"
                      id="bespoke-thumbnail-text-data"
                      name="bespoke-thumbnail-text-data"
                      defaultValue={interview.custom_thumbnail_custom_message}
                      onChange={ async e => {
                        toast.info("Updating Candidate Interview Participation");
                        let value_data = e.target.value;
                        let result = await API.interview.setCustomThumbnailCustomText(apiKey, interview.id, value_data);
                        toast.success("Updated");
                        reloadInterview();
                    }}
                    />
                  </div>
                }
                <label htmlFor="bespoke-marketing-text-data">
                  <span>Marketing Text</span>
                </label>
                <textarea
                  className="marketingText"
                  style={{ width:'calc(100% - 35px)' }}
                  onBlur={ async e => {
                    toast.info("Updating Marketing Text");
                    let value_data = e.target.value;
                    let result = await API.interview.setCustomMarketingText(apiKey, interview.id, value_data);
                    toast.success("Updated");
                    reloadInterview();
                }}
                >
                  { interview.marketing_text }
                </textarea>
            </div>
            <div className='greyed' style={{minHeight: '490px'}}>
                <label htmlFor="allow-candidate-ats-link">
                    <span>Allow candidate to go to an ATS link</span>
                </label>
                <select
                  className="bulkySelect"
                  defaultValue={interview.ats_bespoke}
                  id="allow-candidate-ats-link"
                  name="allow-candidate-ats-link"
                  onChange={ async e => {
                    toast.info("Updating ATS Link");
                    let stateValueAsNumber = parseInt(e.target.value);
                    let result = await API.interview.setATSBespokeAllow(apiKey, interview.id, stateValueAsNumber);
                    toast.success("Updated");
                    reloadInterview();
                }}
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                {
                  (!!interview.ats_bespoke) && <>
                      <label htmlFor="ats-link-data">
                          <span>ATS link</span>
                      </label>
                      <div style={{ display: "inline-block", fontSize: "8pt" }}>
                        Example URL&nbsp;&nbsp;
                        <strong>
                          <span style={{ backgroundColor: "yellow" }}>
                            https://
                          </span>
                          www.example.com/
                        </strong>
                      </div>&nbsp;
                      <input
                        type="text"
                        id="ats-link-data"
                        name="ats-link-data"
                        placeholder="https://www.example.com/"
                        style={{ width: "calc(100% - 84px)", padding: "8px" }}
                        defaultValue={interview.ats_bespoke_url}
                        onBlur={ async e => {
                          toast.info("Updating ATS Link");
                          let val = e.target.value;
                          let result = await API.interview.setATSBespokeText(apiKey, interview.id, val);
                          toast.success("Updated");
                          reloadInterview();
                      }}
                      />
                  </>
                }
                
                <label htmlFor="allow-candidate-ats-link">
                    <span>Allow candidate to see Introduction Videos</span>
                </label>
                  <select
                    className="bulkySelect"
                    defaultValue={interview.itj_bespoke}
                    onChange={ async e => {
                      toast.info("Updating Candidate Can View Intro videos");
                      let stateValueAsNumber = parseInt(e.target.value);
                      let result = await API.interview.setITJBespokeAllow(apiKey, interview.id, stateValueAsNumber);
                      toast.success("Updated");
                      reloadInterview();
                  }}
                  >
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
            </div>
            </>}
        </div>
        <div style={{ height: '100%', width: '100%'}}>
            <div className='candidate-header'>
                <div className='candidate-total'>
                    <h4>Candidates</h4>
                    <span>{`${candidates.length} Candidate${candidates.length > 1 ? "s" : ""}`}</span>                    
                </div>
                <div className='candidate-search-container'>
                    <input type="text" placeholder='Search' value={newSearchQuery} onChange={ e => { setNewSearchQuery(e.target.value)}}/>
                    <button className='button-primary-blue' onClick={ e => {
                        setSearchQuery(newSearchQuery);
                    }}>Search</button>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  <div
                    onClick={()=>{                      
                      setFilterCandidatesWithRecordedVideo(false);
                      setFilterCandidateReadyToSend(false);
                      setFilterCandidatesHasBeenViewed(false);
                      setFilterCandidatesWithNoRecordedVideo(false);
                    }}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Clear Filters"
                  >
                    <MdClear style={{ transform: "translate(-4px, 2px)" }} />
                  </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterCandidatesWithRecordedVideo( x => {
                        if(x === false){
                          setFilterCandidatesWithNoRecordedVideo(false);
                        }
                        return !x;
                      });
                    }}
                    className={"filterbutton" + (filterCandidatesWithRecordedVideo ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Candidates with recorded videos"
                  >
                    <img
                      src={play_icon_color}
                      alt=""
                      style={{
                        transform: "translate(-3px, 2px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterCandidateReadyToSend(x => !x);
                    }}
                    className={"filterbutton" + (filterCandidateReadyToSend ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates ready to send"
                  >
                    <IoIosPaperPlane
                      style={{
                        transform: "translate(-4px, 1px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterCandidatesHasBeenViewed(x => !x);
                    }}
                    className={"filterbutton" + (filterCandidatesHasBeenViewed ? " active-filter" : "")}
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates that have been viewed"
                  >
                    <AiFillEye
                      style={{
                        transform: "translate(-5px, 2px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;&nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  &nbsp;
                  <div
                    onClick={()=>{
                      setFilterCandidatesWithNoRecordedVideo(x => {
                        if(x === false){
                          setFilterCandidatesWithRecordedVideo(false);
                        }
                        return !x;
                      });
                    }}
                    className={"filterbutton" + (filterCandidatesWithNoRecordedVideo ? " active-filter" : "")}
                    style={{ transform: "translate(-2px, 0px)" }}
                    title="Candidates with no recorded videos"
                  >
                    <img
                      src={play_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
                <button className='button-primary-blue large' onClick={ e => {  
                      toast.info("Reloading Candidates");
                      loadCandidates(apiKey, interview.id, searchQuery, pageNumber);
                }}>Refresh Candidates</button>
                <button className='button-primary-blue large' style={{marginLeft:'0px'}} onClick={ e => {
                    addNewCandidateCallback(async (data:CandidateAddInterface): Promise<null | {
                      created_at: null | string | Date
                      email: null | string
                      id: number
                      interview_id:  null | number
                      linkedin_url: null | string
                      marketing_type: null | number
                      name: null | string
                      phone: null | string
                      processing_gif: null | number
                      processing_video: null | number 
                      updated_at: null | string | Date 
                  }> => {
                      if(!data.name.trim()){
                        toast.error("Invalid Name Entered");
                        return null;
                      }
                      /*if(!data.email.trim()){
                        toast.error("Invalid Email Address Entered");
                        return null;
                      }*/
                      toast.info("Creating Candidate");
                      let result = await API.candidate.create(apiKey, interview.id, data.name, data.email, data.linkedin, data.phone_number, 1, 0, 1);
                      let success = result["success"];
                      if(!success){
                        toast.error(result["message"]);
                        return null;
                      }
                      toast.success("Candidate Created Successfully");
                      toast.info("Reloading Candidates");
                      loadCandidates(apiKey, interview.id, searchQuery, pageNumber);
                      return result.candidate;
                    });
                    executeShowAddNewCandidateCallback();
                }}><BsPersonPlusFill />&nbsp;&nbsp;Add Candidate</button>                
            </div>
            <BespokeFolderCandidateGrid loading={apiLoading} columns={dataGridColumns} rows={candidates} onPageChange={setPageNumber} pageSize={25} totalPages={totalPages} totalCandidates={totalCandidates}/>
        </div>
    </div>
    </>;
}