import React, { createRef } from "react";

import { ToastContainer, toast } from 'react-toastify';

import { AiOutlineLoading } from 'react-icons/ai';

import { DateTime } from "luxon";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

import { Tooltip as ReactTooltip } from "react-tooltip";

import { FiFilter } from 'react-icons/fi';

import './marketing.css';

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";

import OneClickComponent from '../components/oneclick';

import {Helmet} from "react-helmet";

const PAGE_SIZE = 10;

export default class MarketingPage extends React.Component {
    constructor(props) {
      super(props);
     
      this.state = {
        campaigns: [],
        paged_campaigns: [],
        page_id: 0,
        pages: [],
        loading: false,
        data: [],
        user: null,
      }

      this.createCampaignDialogRef = createRef();
      this.createCampaignDialogTitleRef = createRef();
      
      this.editCampaignDialogRef = createRef();
      this.editCampaignDialogIDRef = createRef();
      this.editCampaignDialogTitleRef = createRef();
      
      this.dateFilterDialogSearchRef = createRef()
      this.jobTitleFilterDialogSearchRef = createRef()

      
      this.jobTitleFilterDialogSearchRef = createRef();
      this.dateFilterDialogSearchRef = createRef();
      this.jobTitleSearchTextInputRef = createRef();
      this.jobTitleOrderInputRef = createRef();
      
      this.dateFromSearchTextInputRef = createRef();
      this.dateToSearchTextInputRef = createRef();
      
      this.createNewCampaign = this.createNewCampaign.bind(this);
      this.openCreateCampaign = this.openCreateCampaign.bind(this);
      this.openMarketing = this.openMarketing.bind(this);
      this.editMarketing = this.editMarketing.bind(this);
      this.deleteMarketing = this.deleteMarketing.bind(this);

      this.reloadCampaigns = this.reloadCampaigns.bind(this);
      
      this.updateCampaign = this.updateCampaign.bind(this);
      
      this.openJobSearch = this.openJobSearch.bind(this);
      this.openDateRange = this.openDateRange.bind(this);

      
      this.applyJobTitleSearch = this.applyJobTitleSearch.bind(this);
      this.applyJobTitleClear = this.applyJobTitleClear.bind(this);
      
      this.applyDateRangeSearch = this.applyDateRangeSearch.bind(this);
      this.applyDateRangeClear = this.applyDateRangeClear.bind(this);
      this.gotoPage = this.gotoPage.bind(this);
    }

    
    applyJobTitleClear(){
        this.jobTitleFilterDialogSearchRef.current.style.display = "none";
        this.jobTitleSearchTextInputRef.current.value = "";
        this.reloadCampaigns();
    }

    async applyJobTitleSearch(){        
        this.setState({
            ...this.state,
            loading: true
        });  
        let text = this.jobTitleSearchTextInputRef.current.value;
        let sort = this.jobTitleOrderInputRef.current.value;
        this.jobTitleFilterDialogSearchRef.current.style.display = "none";   
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.list(api_key);
        let campaigns = result['campaigns'];
        campaigns = text != "" && text != null ? campaigns.filter((campaign)=>{
            return  (new RegExp(text.toLowerCase())).test(campaign.title.toLowerCase());
        }) : campaigns;
        campaigns = campaigns.sort(function(a,b){
            let x = a.title.toLowerCase();
            let y = b.title.toLowerCase();
            if(sort == 'az'){                
                if(x > y) return 1;
                if(x < y) return -1;
                return 0;    
            } else if(sort == 'za'){
                if(x > y) return -1;
                if(x < y) return 1;
                return 0;    
            }
        });        
        let total_pages = Math.ceil(campaigns.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        this.setState({
            ...this.state,
            loading: false,
            campaigns: campaigns,
            paged_campaigns: campaigns.slice(0, PAGE_SIZE),
            pages: pages,
        });
        
    }
    
    applyDateRangeClear(){
        this.dateFilterDialogSearchRef.current.style.display = "none";
        this.dateFromSearchTextInputRef.current.value = "";
        this.dateToSearchTextInputRef.current.value = "";
        this.reloadCampaigns();
    }
    
    async applyDateRangeSearch(){        
        this.setState({
            ...this.state,
            loading: true
        });  
        let from = new Date(this.dateFromSearchTextInputRef.current.value);
        let to = new Date(this.dateToSearchTextInputRef.current.value);
        let temp = null;
        if(from > to){
            temp = from;
            from = to;
            to = from;
        }
        this.dateFilterDialogSearchRef.current.style.display = "none";   
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.list(api_key);
        let campaigns = result['campaigns'];
        campaigns = campaigns.filter((campaign)=>{
            let x = new Date(campaign.created_at);
            return x > from && x < to; 
        });
        let total_pages = Math.ceil(campaigns.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        this.setState({
            ...this.state,
            loading: false,
            campaigns: campaigns,
            paged_campaigns: campaigns.slice(0, PAGE_SIZE),
            pages: pages,
        });
    }



    async componentDidMount() {
        this.createCampaignDialogRef.current.style.display = "none";
        this.editCampaignDialogRef.current.style.display = "none";
        
        this.jobTitleFilterDialogSearchRef.current.style.display = "none";
        this.dateFilterDialogSearchRef.current.style.display = "none";

        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        if(user.package_number === 0 && user.trial_expiry_flag){
            // show trial expire - popup
            let expiry_date = new Date(user.trial_expiry);
            let current_date = new Date();
            let t = expiry_date.getTime() - current_date.getTime();
            let expired = t <= 0; 
            if(expired){
                window.location.href = "/dashboard";
            }
        }

        this.reloadCampaigns();
    }

    openCreateCampaign(){
        this.createCampaignDialogRef.current.style.display = "flex";
        this.createCampaignDialogTitleRef.current.value = "";
    }

    async createNewCampaign(){
        this.setState({
            ...this.state,
            loading: true
        });
        let title = this.createCampaignDialogTitleRef.current.value;
        if(!title){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Please input a valid Name/Title");
            return;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.create(api_key, title);
        let success = result['success'];
        if(!success){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Error creating campaign");
            return;
        }
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Campaign created");
        this.createCampaignDialogRef.current.style.display = "none";
        this.reloadCampaigns();
    }
    
    async updateCampaign(){
        this.setState({
            ...this.state,
            loading: true
        });
        let id = this.editCampaignDialogIDRef.current.value;
        let title = this.editCampaignDialogTitleRef.current.value;
        if(!title){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Please input a valid Name/Title");
            return;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.edit(api_key, id, title);
        let success = result['success'];
        if(!success){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Error Updating campaign");
            return;
        }
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Campaign Updated");
        this.editCampaignDialogRef.current.style.display = "none";
        this.reloadCampaigns();
    }

    async reloadCampaigns(){
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.list(api_key);
        let campaigns = result['campaigns'];
        let total_pages = Math.ceil(campaigns.length / PAGE_SIZE);
        let pages = [];
        for(let i=0;i<total_pages;i++){
            pages.push(i+1);
        }
        this.setState({
            ...this.state,
            loading: false,
            campaigns: campaigns,            
            paged_campaigns: campaigns.slice(0, PAGE_SIZE),
            pages: pages,
        });
    }

    gotoPage(e){
        let page_id = parseInt(e.target.getAttribute('data-page-id'));
        let paged_campaigns = this.state.campaigns.slice(page_id * PAGE_SIZE, (page_id * PAGE_SIZE) + PAGE_SIZE);
        this.setState({
            paged_campaigns: paged_campaigns,
            page_id: page_id
        });
        window.scrollTo(0,0);
    }

    openMarketing(e){
        let mid = e.target.getAttribute('data-marketing-id');
        window.location.href = "/marketing/" + mid;
    }

    async editMarketing(e){
        let mid = e.target.getAttribute('data-marketing-id');        
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.get(api_key, mid);
        let campaign = result['campaign'];
        this.editCampaignDialogIDRef.current.value = campaign.id;
        this.editCampaignDialogTitleRef.current.value = campaign.title;
        this.editCampaignDialogRef.current.style.display = "flex";
    }

    async deleteMarketing(e){
        let mid = e.target.getAttribute('data-marketing-id');        
        let api_key = localStorage.getItem('api_key');
        let result = await API.marketing.campaign.delete(api_key, mid);
        toast("Campaign Deleted");
        this.reloadCampaigns();
    }
    
    getTime(dt){
        if(!dt) return "";
        let d = DateTime.fromISO(dt).toFormat("dd-MM-yyyy");
        if(d == "Invalid DateTime"){
            return DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat("dd-MM-yyyy");
        } else {
            return d;
        }
    }

    

    openJobSearch(){
        if(this.jobTitleFilterDialogSearchRef.current.style.display == "block"){            
            this.jobTitleFilterDialogSearchRef.current.style.display = "none";
        } else {            
            this.jobTitleFilterDialogSearchRef.current.style.display = "block";
        }
    }
    
    openDateRange(){
        if(this.dateFilterDialogSearchRef.current.style.display == "block"){            
            this.dateFilterDialogSearchRef.current.style.display = "none";
        } else {            
            this.dateFilterDialogSearchRef.current.style.display = "block";
        }
    }

    render() {
        window.fbAsyncInit = function() {window.FB.init({appId:"940152470118793",cookie:true,xfbml:true,version:'v12.0'})};
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Marketing</span>
                    </div>             
                    <div className="vacancy-table">
                        <table>
                            <tr>
                                <th scope="col" style={{minWidth:'150px', textAlign:'left'}}>
                                    <span class="dropdown">
                                        <button>Action</button>
                                        <label>
                                            <input type="checkbox" />
                                            <ul>
                                            <li onClick={this.openCreateCampaign} style={{color:'#000', fontWeight:'normal'}}>Add Campaign</li>
                                            </ul>
                                        </label>
                                    </span>
                                </th>
                                <th scope="col"  style={{position:'relative'}}>Campaign Title <FiFilter onClick={this.openJobSearch} className="invert_hover" style={{position:'absolute', right:'12px', fontSize:'18pt', cursor:'pointer'}}/>
                                    <div className="jobTitleFilterDialog" ref={this.jobTitleFilterDialogSearchRef}>
                                        <span>Search by text</span><br/>
                                        <input type="text" style={{width:'calc(100% - 20px)', padding:'8px'}} placeholder="Type here" ref={this.jobTitleSearchTextInputRef}/><br/>
                                        <br/>
                                        <span>Sort By</span><br/>
                                        <select style={{width:'100%', padding:'8px'}} ref={this.jobTitleOrderInputRef}>
                                            <option value="az">A to Z</option>
                                            <option value="za">Z to A</option>
                                        </select><br/><br/>
                                        <button onClick={this.applyJobTitleSearch}>Apply</button>
                                        <button onClick={this.applyJobTitleClear} style={{marginTop:'8px'}}>Clear Filter</button>
                                    </div></th>
                                <th scope="col">Leads</th>
                                <th scope="col">Open Rate</th>
                                <th scope="col"  style={{position:'relative'}}>Date <FiFilter onClick={this.openDateRange} className="invert_hover" style={{position:'absolute', right:'12px', fontSize:'18pt', cursor:'pointer'}}/>
                                    <div className="jobTitleFilterDialog" ref={this.dateFilterDialogSearchRef}>
                                    <span style={{fontSize:'9pt'}}>From:</span><br/>
                                    <input type="date" style={{width:'calc(100% - 20px)', padding:'8px'}} ref={this.dateFromSearchTextInputRef}/><br/>                                        
                                    <span style={{fontSize:'9pt'}}>To:</span><br/>
                                    <input type="date" style={{width:'calc(100% - 20px)', padding:'8px'}} ref={this.dateToSearchTextInputRef}/><br/>                                        
                                    <button style={{marginTop:'8px'}} onClick={this.applyDateRangeSearch}>Apply</button>
                                    <button style={{marginTop:'8px'}} onClick={this.applyDateRangeClear}>Clear Filter</button>
                                </div></th>
                            </tr>
                            {
                                this.state.paged_campaigns.map((record, index)=>{
                                        let r_id = record.id;
                                        let data = [
                                            { name: 'Total', value: record.sent_count - record.open_rate.bespoke == 0 ? 1 : record.sent_count - record.open_rate.bespoke},
                                            { name: 'Bespoke', value: record.open_rate.bespoke},
                                        ];
                                        let data2 = [
                                            { name: 'Total', value: record.sent_count_marketing - record.open_rate.campaign == 0 ? 1 : record.sent_count_marketing - record.open_rate.campaign } ,
                                            { name: 'Campaign', value: record.open_rate.campaign }
                                        ];
                                
                                        let newOpens = record.openDiff.bespoke;
                                        if(newOpens >= 10){
                                            newOpens = "+";
                                        }
                                        
                                        let newOpens2 = record.openDiff.campaign;
                                        if(newOpens2 >= 10){
                                            newOpens2 = "+";
                                        }
                                        
                                        let newOpens3 = record.openDiff.social;
                                        if(newOpens3 >= 10){
                                            newOpens3 = "+";
                                        }
                                        return (                  
                                            <tr>
                                                <td>
                                                    <span class="dropdown">
                                                        <button>Action</button>
                                                        <label>
                                                            <input type="checkbox" />
                                                            <ul>
                                                            <li data-marketing-id={record.id} onClick={this.openMarketing}>Open</li>
                                                            <li data-marketing-id={record.id} onClick={this.editMarketing}>Edit</li>
                                                            <li data-marketing-id={record.id} onClick={this.deleteMarketing}>Delete</li>
                                                            </ul>
                                                        </label>
                                                    </span>
                                                </td>
                                                <td style={{wordBreak: 'break-all', textAlign:'center'}}>{ record.title }</td>
                                                <td style={{textAlign:'center'}}>{ record.total_leads }</td>
                                                <td style={{textAlign:'center'}}>
                                                    <div data-tip data-for={`tooltip-${record.id}`} style={{display:'inline-block', marginRight:'8px'}} onClick={()=>{
                                                        window.location.href = `/marketing/${record.id}?#marketing`;
                                                    }}>
                                                        <PieChart width={72} height={72} style={{cursor:'pointer', transform:'translateX(14px)'}}>
                                                            <Pie
                                                                data={data}
                                                                cx={72/2}
                                                                cy={72/2}
                                                                innerRadius={10}
                                                                outerRadius={32}
                                                                fill="red"
                                                                paddingAngle={0}
                                                                dataKey="value"
                                                                >
                                                                {data.map((entry, index) => (
                                                                    <Cell key={`campaign-${r_id}-cell-${index}`} fill={record.open_rate.bespoke == 0 && record.sent_count == 0 ? "#CCCCCC" : ["#0088FE","#00C49F","#FFBB28"][index % 3]} />
                                                                ))}
                                                            </Pie>
                                                            <text x={72/2 + 4} y={72/2 + 4} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={"12pt"} stroke="red">
                                                                {
                                                                    newOpens
                                                                }
                                                            </text>
                                                        </PieChart>
                                                        <div style={{fontSize:'8pt', transform:'translateX(12px)'}}>Bespoke Views</div>
                                                    </div>
                                                    <ReactTooltip id={`tooltip-${record.id}`} aria-haspopup='true'>
                                                        <h4>New Opens</h4>
                                                        <ul style={{listStyle:'none'}}>
                                                            <li>Bespoke: { record.openDiff.bespoke }</li>
                                                        </ul>
                                                        <h4>Total Opens</h4>
                                                        <ul style={{listStyle:'none'}}>
                                                            <li>Bespoke: { record.open_rate.bespoke } / { record.sent_count }</li>
                                                        </ul>
                                                    </ReactTooltip>
                                                    <div data-tip data-for={`tooltip-${record.id}-2`} style={{display:'inline-block'}} onClick={()=>{
                                                        window.location.href = `/marketing/${record.id}?#campaign`;
                                                    }}>
                                                        <PieChart width={72} height={72} style={{cursor:'pointer', transform:'translateX(16px)'}}>
                                                            <Pie
                                                                data={data2}
                                                                cx={72/2}
                                                                cy={72/2}
                                                                innerRadius={10}
                                                                outerRadius={32}
                                                                fill="red"
                                                                paddingAngle={0}
                                                                dataKey="value"
                                                                >
                                                                {data.map((entry, index) => (
                                                                    <Cell key={`campaign-${r_id}-cell-${index}`} fill={record.open_rate.campaign == 0 && record.sent_count_marketing == 0 ? "#CCCCCC" : ["#0088FE","#00C49F","#FFBB28"][index % 3]} />
                                                                ))}
                                                            </Pie>
                                                            <text x={72/2 + 4} y={72/2 + 4} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={"12pt"} stroke="red">
                                                                {
                                                                    newOpens2
                                                                }
                                                            </text>
                                                        </PieChart>                                                       
                                                        <div style={{fontSize:'8pt', transform:'translateX(12px)'}}>Campaign Views</div>
                                                    </div>
                                                    <ReactTooltip id={`tooltip-${record.id}-2`} aria-haspopup='true'>
                                                        <h4>New Opens</h4>
                                                        <ul style={{listStyle:'none'}}>
                                                            <li>Camapign: { record.openDiff.campaign }</li>
                                                        </ul>
                                                        <h4>Total Opens</h4>
                                                        <ul style={{listStyle:'none'}}>
                                                            <li>Camapign: { record.open_rate.campaign } / { record.sent_count_marketing }</li>
                                                        </ul>
                                                    </ReactTooltip>
                                                    { false && <>
                                                    <div data-tip data-for={`tooltip-${record.id}-3`} style={{display:'inline-block', cursor:'pointer', transform:"translate(31px, 0px)"}} onClick={()=>{
                                                        window.location.href = `/marketing/${record.id}?#social`;
                                                    }}>
                                                        <div /*data-tip data-for={`tooltip-${record.id}-2`}*/ style={{display:'inline-block', marginTop:'20px'}} onClick={()=>{
                                                            window.location.href = `/marketing/${record.id}?#campaign`;
                                                        }}>
                                                            <div style={{transform:'translateY(-18px)', textAlign:'center'}}>{ record.open_rate.social }</div>                                                        
                                                            <div style={{fontSize:'8pt'}}>Vlog Views</div>
                                                        </div>
                                                    </div>
                                                    </> }
                                                </td>
                                                <td style={{textAlign:'center'}}>{ this.getTime(record.created_at) }</td>
                                            </tr> 
                                        )
                                    })
                                }  
                        </table>
                        <div className="pagination_table">
                            <span>Pagination</span>
                            <div>
                                {
                                    this.state.pages.map((page)=>{
                                        return (
                                            this.state.page_id == page - 1 ? 
                                            <button data-page-id={page - 1} onClick={this.gotoPage} className="active">{page}</button> :
                                            <button data-page-id={page - 1} onClick={this.gotoPage}>{page}</button>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div ref={this.createCampaignDialogRef} className="dialogNew extend">        
                <div>
                    <h4>Add Campaign</h4>
                    <div className="inputForm">
                    <input ref={this.createCampaignDialogTitleRef} type="text" placeholder="Campaign Title"/>
                    </div>
                    <div>
                        <button onClick={this.createNewCampaign}>Create</button>
                        <button onClick={()=>{this.createCampaignDialogRef.current.style.display = "none";}}>Close</button>
                    </div>
                </div>
            </div>   
            <div ref={this.editCampaignDialogRef} className="dialogNew extend">        
                <div>
                    <h4>Add Campaign</h4>
                    <div className="inputForm">
                    <input ref={this.editCampaignDialogIDRef} type="hidden"/>
                    <input ref={this.editCampaignDialogTitleRef} type="text" placeholder="Campaign Title"/>
                    </div>
                    <div>
                        <button onClick={this.updateCampaign}>Update</button>
                        <button onClick={()=>{this.editCampaignDialogRef.current.style.display = "none";}}>Close</button>
                    </div>
                </div>
            </div>
            { 
                this.state.loading ? (
                    <div className="loader">                        
                        <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                    </div>
                ) : ""
            }
            <Helmet>
                <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" type = "text/javascript"/>
            </Helmet>
            </>
        );
    }
}