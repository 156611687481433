import React, { createRef } from "react";

import './seats.css';

import API from './../services/api';

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import { ToastContainer, toast } from 'react-toastify';

import { IoIosAddCircle } from 'react-icons/io';

import { AiOutlineLoading,AiOutlineSave } from 'react-icons/ai';

export default class SeatsPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          seats:{
              total: 0,
              used: 0
          },
          storage: {
              total: 0,
              used: 0
          },
          users: [],
          user: [],
          loading: false
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 

      this.createUserDialogRef= createRef();
      this.createUserNameRef= createRef();
      this.createUserEmailRef= createRef();
      this.createUserPhoneNumberRef= createRef();
      this.createUserPasswordRef= createRef();
      
      this.shareRefShared = createRef();
      this.shareRefPersonlized = createRef();

      this.messageDialogRef = createRef();
      this.messageRef = createRef();
      this.messageButtonRef = createRef();

      this.impersonate = this.impersonate.bind(this);
      this.delete = this.delete.bind(this);
      this.reloadUsers = this.reloadUsers.bind(this);

      this.openCreation = this.openCreation.bind(this);
      this.saveCandidate = this.saveCandidate.bind(this);

      this.closeDialog = this.closeDialog.bind(this);
      
      this.storageShareSpace = this.storageShareSpace.bind(this);
      this.storagePersonalizedSpace = this.storagePersonalizedSpace.bind(this);
    }

    componentDidMount(){
        this.reloadUsers();
        this.createUserDialogRef.current.style.display = "none";
        this.messageDialogRef.current.style.display = "none";
    }
    closeDialog(){
        this.createUserDialogRef.current.style.display = "none";
    }
    openCreation(){
        this.createUserDialogRef.current.style.display = "flex";
        this.createUserNameRef.current.value = "";
        this.createUserEmailRef.current.value = "";
        this.createUserPhoneNumberRef.current.value = "";
        this.createUserPasswordRef.current.value = (Math.random() + 1).toString(36).substring(4);
    }

    async saveCandidate(){
        this.setState({
            ...this.state,
            loading: true
        });
        let name = this.createUserNameRef.current.value;
        let email = this.createUserEmailRef.current.value;
        let phone = this.createUserPhoneNumberRef.current.value;
        let password = this.createUserPasswordRef.current.value;
        if(!name || !email || !password){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Enter required fields");            
            return;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.checkEmailExists(email);
        let exists = result['exists'];
        if(exists){
            this.setState({
                ...this.state,
                loading: false
            });
            toast("Email already in use");
            return;
        }
        result = await API.seats.create(api_key, name, email, phone, password);
        let success = result['success'];
        this.setState({
            ...this.state,
            loading: false
        });
        if(success){
            this.createUserDialogRef.current.style.display = "none";
            toast("User created");
            this.reloadUsers();
        } else {
            toast("Error creating user");
        }
    }

    async impersonate(e){
        let api_key = localStorage.getItem('api_key');
        let user_id = parseInt(e.target.getAttribute('user-id'));
        let result = await API.seats.impersonate(api_key, user_id);
        let new_api_key = result['api_key'];
        if(new_api_key !== false){
            toast('Login Successful');
            localStorage.setItem('api_key', new_api_key);
            localStorage.setItem('original_api_key', api_key);
            window.location.href = "/dashboard";
        } else {
            toast("Error getting user's api key");
        }
    }

    async reloadUsers(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        result = await API.seats.get(api_key);
        let users = result['users'];
        result = await API.seats.getMetrics(api_key);
        let seats = result['seats'];
        result = await API.storage.getMyStorage(api_key);
        let total = result['total'];
        let used = result['used'];
        let assigned = result['assigned'];
        this.setState({
            ...this.state,
            users: users,
            user: user,
            seats: {
                total: seats['total'],
                used: seats['used'],
            },
            storage: {
                total: total,
                used: used,
                assigned: assigned
            }
        });
    }

    async delete(e){
        let api_key = localStorage.getItem('api_key');
        let user_id = parseInt(e.target.getAttribute('user-id'));
        let result = await API.user.deleteUser(api_key, user_id);
        let success = result['success'];
        if(success){
            this.reloadUsers();
            toast("User Deleted");
        } else {
            toast("Error Deleting User");
        }
    }
    
    range(from, to){
        return [...Array(to - from)].map((_,i)=> i + from);
    }

    async storageShareSpace(){
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.updateStorageUsage(api_key, 0);
        await this.reloadUsers();
        this.setState({
            ...this.state,
            loading: false
        });
    }

    async storagePersonalizedSpace(){
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.updateStorageUsage(api_key, 1);
        await this.reloadUsers();
        this.setState({
            ...this.state,
            loading: false
        });
    }

    render() {
        
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Seats</span> 
                    </div>                  
                    <div className="blockedTop">
                        You have used { this.state.seats.used } out of { this.state.seats.total } total seats
                    </div>                
                    <div className="blockedTop">
                        You have used { this.state.storage.used.toFixed(2) }GBs out of { this.state.user.storage_info_shared == 1 ? (this.state.storage.total - this.state.storage.assigned).toFixed(2): this.state.storage.total.toFixed(2) }GBs total storage. { this.state.user.storage_info_shared == 1 ? 'you may assign' : ""} { this.state.user.storage_info_shared == 1 ? (this.state.storage.total - (this.state.storage.used + this.state.storage.assigned)).toFixed(2) + "GBs" : "" } { this.state.user.storage_info_shared == 1 ? ", You have assigned " + this.state.storage.assigned.toFixed(2) + "GBs" : <></>}<br/><br/>
                        <span>Storage Usage Type:</span>
                        <div className="toggleButtonsContainer">
                            { this.state.user.storage_info_shared == 0 ? <button ref={this.shareRefShared} className="active" onClick={this.storageShareSpace}>Shared</button> : <button ref={this.shareRefShared} onClick={this.storageShareSpace}>Shared</button> }
                            { this.state.user.storage_info_shared == 1 ?<button ref={this.shareRefPersonlized} className="active" onClick={this.storagePersonalizedSpace}>Personlized</button> : <button ref={this.shareRefPersonlized} onClick={this.storagePersonalizedSpace}>Personlized</button>}
                        </div>&nbsp;&nbsp;
                        { this.state.user.storage_info_shared == 0 ? <span>: All available storage data will she shared equally among all seats/users (recommended)</span>: <span>Each seat user will be limited by the storage that you have set for each seats/users</span>}
                    </div>
                    <div className="blockedTop">
                        <table className="seats-table">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone Number</th>
                                    { this.state.user.storage_info_shared == 1 ? <th>Custom Storage in GBs</th>: <></>}
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody> 
                                { this.state.users.map((user)=>{
                                    return (
                                        <tr className="seatUser">
                                            <td>{ user.name }</td>
                                            <td>{ user.email }</td>
                                            <td>{ user.phone }</td>
                                            { this.state.user.storage_info_shared == 1 ? <td><input className="setCustomStorage" id={`seat_user-${user.id}`} defaultValue={user.storage_assigned_space}/><button className="seat-create" onClick={ async (e)=>{
                                                let user_id = user.id;
                                                let space = parseFloat(document.querySelector(`#seat_user-${user.id}`).value);
                                                if(space < 1 && space != 0){
                                                    document.querySelector(`#seat_user-${user.id}`).value = 1;
                                                    return;
                                                }
                                                let api_key = localStorage.getItem('api_key');
                                                let result = await API.user.seats.setPersonolisedStorage(api_key, user_id, space);
                                                let success = result['success'];
                                                let message = !success ? result['message'] : "";
                                                let button = !success ? result['button'] : false;
                                                if(success == false && !button){
                                                    this.messageDialogRef.current.style.display = "flex";
                                                    this.messageRef.current.innerHTML = message;
                                                    this.messageButtonRef.current.style.display = "none";
                                                    return;
                                                } else if(success == false && button){
                                                    this.messageDialogRef.current.style.display = "flex";
                                                    this.messageRef.current.innerHTML = message;
                                                    this.messageButtonRef.current.style.display = "block";
                                                    return;
                                                }
                                                this.reloadUsers();
                                            }} style={{transform:'translateX(-50px)', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'}}>Save</button></td>: <></>}
                                            <td style={{width:'240px'}}>
                                                <button user-id={user.id} onClick={this.impersonate} className="impersonate">Impersonate</button>
                                                <button user-id={user.id} onClick={this.delete} className="delete">Delete</button>
                                            </td>
                                        </tr>
                                    );
                                })}
                                
                                { this.state.seats.total - this.state.seats.used >= 1 && this.range(0, this.state.seats.total - this.state.seats.used).map((index)=>{
                                    return (
                                        <tr className="seatUser">
                                            <td>
                                                <IoIosAddCircle onClick={this.openCreation} size={"32px"} display={'inline-block'} cursor={"pointer"} color="#1976D1"  style={{transform:'translateY(32px)'}}
                                                    onMouseOver={({target})=>target.style.color="#1E1E2D"}
                                                    onMouseOut={({target})=>target.style.color="#1976D1"}
                                                />&nbsp;<span style={{display:'inline-block',transform:'translateY(22px)'}}>Add Seat User</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{width:'240px'}}></td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                     
                <div className="createQuestiondialogNew" ref={this.createUserDialogRef}>                
                    <div className="xxx">
                        <h4>Create Seat User</h4>
                        <div>
                        <label for="">
                            <span>User First Name</span>
                            <input ref={this.createUserNameRef} type="text" id="createUserModel-name" />
                        </label>
                        <label for="">
                            <span>User Phone Number</span>
                            <input ref={this.createUserPhoneNumberRef} type="text" id="createUserModel-phone" />
                        </label>
                        <label for="">
                            <span>User Email</span>
                            <input ref={this.createUserEmailRef} type="text" id="createUserModel-email" />
                        </label>
                        <label for="">
                            <span>User Password</span>
                            <input ref={this.createUserPasswordRef} type="text" id="createUserModel-password" readonly="" />
                        </label>
                        <button onClick={this.saveCandidate}>Create</button>
                        <button onClick={this.closeDialog}>Close</button>
                        </div>
                    </div>
                </div>
                <div className="createQuestiondialogNew" ref={this.messageDialogRef}>                
                    <div className="xxx" style={{maxWidth:'480px'}}>
                        <div>
                            <span ref={this.messageRef}></span>
                            <button ref={this.messageButtonRef} onClick={()=>{
                                window.location.href = "/dashboard";
                            }}>Click Here</button>
                            <button onClick={()=>{
                                this.messageDialogRef.current.style.display = "none";
                            }}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            { 
                this.state.loading ? (<div className="loader">
                    <span>☯</span>
                    <div>Loading, Please Wait <AiOutlineLoading className="loading-spinner"/></div>
                </div>) : ""
            }
            </>
        );
      }
}