import React, { useEffect, useState } from "react";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import './fiveStarRating.css';
interface FiveStarRatingProps {
    value:number;
    onChange: (value:number) => void;
    readOnly: boolean;
    fontSize: string | null;
}
export default function FiveStarRating({value, onChange, readOnly = false, fontSize = null}:FiveStarRatingProps){
    const [ selectedValue, setSelectedValue] = useState<number>(value ?? 0);
    useEffect(()=>{
        if(readOnly) return;
        onChange(selectedValue);
    }, [selectedValue]);
    return <div className="five-star-rating-component-container">
        <div style={{ fontSize: fontSize ? fontSize : "28pt"}} onClick={ e => setSelectedValue(1)}>{ selectedValue >= 1 ? <FaStar fill={ readOnly ? "blue" : "yellow"} /> : <FaRegStar/>}</div>
        <div style={{ fontSize: fontSize ? fontSize : "28pt"}} onClick={ e => setSelectedValue(2)}>{ selectedValue >= 2 ? <FaStar fill={ readOnly ? "blue" : "yellow"} /> : <FaRegStar/>}</div>
        <div style={{ fontSize: fontSize ? fontSize : "28pt"}} onClick={ e => setSelectedValue(3)}>{ selectedValue >= 3 ? <FaStar fill={ readOnly ? "blue" : "yellow"} /> : <FaRegStar/>}</div>
        <div style={{ fontSize: fontSize ? fontSize : "28pt"}} onClick={ e => setSelectedValue(4)}>{ selectedValue >= 4 ? <FaStar fill={ readOnly ? "blue" : "yellow"} /> : <FaRegStar/>}</div>
        <div style={{ fontSize: fontSize ? fontSize : "28pt"}} onClick={ e => setSelectedValue(5)}>{ selectedValue >= 5 ? <FaStar fill={ readOnly ? "blue" : "yellow"} /> : <FaRegStar/>}</div>
    </div>
}