import React, { createRef } from "react";

import API from "./../services/api";

export default class Register2Page extends React.Component {
    constructor(props) {
      super(props);
      this.state = {isError: false, errorMessage: ""}; // isError -> errorMessage
      this.handleRegister2 = this.handleRegister2.bind(this);
      this.promocodeRef = createRef();
    }

    // handle login
    async handleRegister2(){
        let promocode = this.promocodeRef.current.value;
        let api_key = localStorage.getItem('api_key');
        localStorage.setItem('promocode', promocode);
        let result = await API.user.registerUserStep2(api_key, promocode);
        console.log(result);
        if(result['success']){
            window.location.href = '/register/step/4';
        } else {
            alert('error');
        }
    }

    countrySelected(e){
        console.log(e.target.value);
    }

    render() {
        // HTML
        return (
            <div className="outer">
                <div className="middle">
                    <div className="container">
                        <div className="loginFormLogo"></div>
                        <div className="form">
                            <h4>Enter a promocode</h4>
                            <input ref={this.promocodeRef} id="promocode" type="text" placeholder="Promocode (Optional)" defaultValue=""/>
                            <button onClick={this.handleRegister2} id="next">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}