import { useState, useEffect } from 'react';
import axios from 'axios';

const useAbortableAxios = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [cancelToken, setCancelToken] = useState(null);

  useEffect(() => {
    return () => {
      // Cleanup function to cancel the request if the component unmounts
      if (cancelToken) {
        cancelToken.cancel('Request canceled due to component unmount');
      }
    };
  }, [cancelToken]);

  const fetchData = async (url, params, dataX, method = "get") => {
    try {
      if (cancelToken) {
        cancelToken.cancel('Request canceled due to new request');
      }

      const source = axios.CancelToken.source();
      setCancelToken(source);

      setLoading(true);
      setData(null);
      setError(null);
      const options:any = {
        cancelToken: source.token,
        params: params,
      };
    
      //const response = await axios[method](url, options);
      const response = method === "get" ? await axios.get(url, options) : await axios.post(url, dataX, options);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message);
      } else {
        setError(err);
        setLoading(false);
      }
    }
  };

  return { loading, data, error, fetchData, setLoading};
};

export default useAbortableAxios;