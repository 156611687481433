import React, { createRef } from "react";

import API from "../services/api";

import VideoRecordComponent from "./videorecord";

import FileUploadComponnent from "./fileupload";

import LibraryComponent from "./library";

import './itj.css';
import { toast, ToastContainer } from "react-toastify";

import * as $ from 'jquery';

export default class ITJComponent extends React.Component {
    constructor(props) {
      super(props);
      this.reloadPage = props.reloadPage;
      this.state = {
          user: props.user,
          interview: props.interview,
          id: 0,
          canRecordDisplay: false,
          canUploadDisplay: false,
          library: []
      };
      
      this.hideShowBtnRef = createRef();
      this.dropdownRef = createRef();

      this.actionPopUpRef = createRef();
      this.actionsDeleteRef = createRef();
      this.videoRecordRef = createRef();
      this.videoRecordElementRef = createRef();
      this.videoUploadRef = createRef();

      this.previewVideoRef = createRef();
      this.previewVideoElementRef = createRef();

      this.libraryRef = createRef();
      
      this.librarySelectionRef = createRef();

      this.libraryVideoPreviewRef = createRef();
      this.libraryVideoPreviewVideoRef = createRef();

      this.toggle = this.toggle.bind(this);
      this.showUpgrade = this.showUpgrade.bind(this);      

      this.editVideo = this.editVideo.bind(this);
      this.previewVideo = this.previewVideo.bind(this);
      this.addVideo = this.addVideo.bind(this);
      this.closeDialog = this.closeDialog.bind(this);
      this.closeVideoUpload = this.closeVideoUpload.bind(this);

      this.openRecorder = this.openRecorder.bind(this);
      this.openUpload = this.openUpload.bind(this);
      this.openLibrary = this.openLibrary.bind(this);

      this.closeVideoRecorder = this.closeVideoRecorder.bind(this);

      this.completedFileUpload = this.completedFileUpload.bind(this);
      this.closeDialogPreview = this.closeDialogPreview.bind(this);

      this.deleteSelectITJVideo = this.deleteSelectITJVideo.bind(this);

      this.videoAccepted = this.videoAccepted.bind(this);

      this.libraryOnSave = this.libraryOnSave.bind(this);

      this.closeLibraryDialog = this.closeLibraryDialog.bind(this);

      this.openLibrarySelection = this.openLibrarySelection.bind(this);

      this.closeLibraryPreview = this.closeLibraryPreview.bind(this);

      this.librarySelect = this.librarySelect.bind(this);

      this.deleteLibrarySelection = this.deleteLibrarySelection.bind(this);

      this.getVType = this.getVType.bind(this);

      
      
    }

    toggle(){
        if(this.dropdownRef.current.style.display == "none"){
            this.hideShowBtnRef.current.innerHTML = "Hide";
            this.dropdownRef.current.style.display = "block";
        } else {            
            this.hideShowBtnRef.current.innerHTML = "Show";
            this.dropdownRef.current.style.display = "none";
        }
    }

    showUpgrade(){
        // TODO: SHOW UPGRADE POPUP
        window.location.href = "/upgrade";
    }

    editVideo(e){
        let id = parseInt(e.target.getAttribute('index-id'));
        this.setState({ ...this.state, id: id});
        this.actionPopUpRef.current.setAttribute('index-id', id);
        this.actionPopUpRef.current.style.display = "flex";
        this.actionsDeleteRef.current.style.display = "inline-block";
    }

    async deleteSelectITJVideo(){
        let sid = this.state.id;
        let company = this.state.interview.intro_video_url;
        let team = this.state.interview.meet_team_video_url;
        let job = this.state.interview.job_overview_video_url;
        let culture = this.state.interview.culture_video_url;
        let evp = this.state.interview.evp_video_url;
        if(sid == 0){
            company = "";
        } else if(sid == 1){
            team = "";
        } else if(sid == 2){
            job = "";
        } else if(sid == 3){
            culture = "";
        } else if(sid == 4){
            evp = "";
        }
        let interview_id = this.state.interview.id;
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.setITJVideo(api_key, interview_id, company, team, job, culture, evp);
        this.reload();
    }

    previewVideo(e){
        let sid = parseInt(e.target.getAttribute('index-id'));
        let video_url = "";
        if(sid == 0){
            video_url = this.state.interview.intro_video_url;
        } else if(sid == 1){
            video_url = this.state.interview.meet_team_video_url;
        } else if(sid == 2){
            video_url = this.state.interview.job_overview_video_url;
        } else if(sid == 3){
            video_url = this.state.interview.culture_video_url;
        } else if(sid == 4){
            video_url = this.state.interview.evp_video_url;
        }
        this.previewVideoRef.current.style.display = "flex";
        this.previewVideoElementRef.current.src = video_url;
        this.previewVideoElementRef.current.play();
    }

    closeDialogPreview(){
        this.previewVideoRef.current.style.display = "none";
        this.previewVideoElementRef.current.src = "";
    }

    addVideo(e){
        let id = parseInt(e.target.getAttribute('index-id'));
        this.setState({ ...this.state, id: id});
        this.actionPopUpRef.current.setAttribute('index-id', id);
        this.actionPopUpRef.current.style.display = "flex";
        this.actionsDeleteRef.current.style.display = "none";
    }
    
    componentDidMount(){        
        this.actionPopUpRef.current.style.display = "none";
        this.videoRecordRef.current.style.display = 'none';
        this.previewVideoRef.current.style.display = "none";
        $(this.libraryRef.current).hide();          
        this.librarySelectionRef.current.style.display = "none";
        this.libraryVideoPreviewRef.current.style.display = "none";
        this.dropdownRef.current.style.display = "none";
        this.hideShowBtnRef.current.innerHTML = "Show";
    }

    closeDialog(){
        this.setState({...this.state, canRecord: false});
        this.actionPopUpRef.current.style.display = "none";
        this.setState({...this.state, canRecordDisplay: false});
    }
    
    openRecorder(){
        this.setState({...this.state, canRecordDisplay: true});
        this.videoRecordRef.current.style.display = 'flex';
        this.videoRecordElementRef.current.resetUI();
        this.videoRecordElementRef.current.openVideo();
        this.actionPopUpRef.current.style.display = "none";
    }
    
    openUpload(){
        this.setState({...this.state, canUploadDisplay: true});
        if(this.state.canUploadDisplay){
            this.videoUploadRef.current.style.display = 'block';
        }
        this.actionPopUpRef.current.style.display = "none";
    }
    
    async openLibrary(){
        toast("Loading");
        let id_type = this.state.id;
        let api_key = localStorage.getItem('api_key');
        let result = await API.library.getAll(api_key, id_type);
        let success = result['success'];
        let library = [];
        if(success){
            toast("Loaded");
            switch(id_type){
                case 0:
                    library = result['company_intro_videos'];
                    break;
                case 1:
                    library = result['meet_the_team_videos'];
                    break;
                case 2:
                    library = result['job_overview_videos'];
                    break;                    
                case 3:
                    library = result['culture_videos'];
                    break;                    
                case 4:
                    library = result['evp_videos'];
                    break;
            }
            this.librarySelectionRef.current.style.display = "flex";
            this.setState({...this.state, library: library});
        } else {
            toast("Error Couldn't Road");
        }
    }

    closeLibraryDialog(){
        this.librarySelectionRef.current.style.display = "none";
    }

    async reload(){
        this.reloadPage();
    }
    
    closeVideoRecorder(){
        this.setState({...this.state, canRecordDisplay: false});
        this.videoRecordRef.current.style.display = 'none';
    }
    
    closeVideoUpload(){
        this.setState({...this.state, canUploadDisplay: false});
        this.videoUploadRef.current.style.display = 'none';
    }

    async videoAccepted(video_url){
        let iid = this.actionPopUpRef.current.getAttribute('index-id');
        if(iid == 0){
            this.state.interview.intro_video_url = video_url;
        } else if(iid == 1){
            this.state.interview.meet_team_video_url = video_url;
        } else if(iid == 2){
            this.state.interview.job_overview_video_url = video_url;
        } else if(iid == 3){
            this.state.interview.culture_video_url = video_url;
        } else if(iid == 4){
            this.state.interview.evp_video_url = video_url;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.interview.setITJVideo(
            api_key,
            this.state.interview.id,
            this.state.interview.intro_video_url,
            this.state.interview.meet_team_video_url,
            this.state.interview.job_overview_video_url,
            this.state.interview.culture_video_url,
            this.state.interview.evp_video_url
        )
        let success = result['success'];
        if(success){
            toast("Video saved");
        } else {
            toast("Maxed Storage Reached");
            setTimeout(()=>{
                window.location.href = "/dashboard";
            }, 5000);
            return;
        }
        this.videoRecordRef.current.style.display = 'none';
        
        result = await API.interview.get(api_key, this.state.interview.id);
        let interview = result['interview'];
        this.setState({...this.state, interview: interview, id: iid});

        // TODO: Open Library Addtion PopUp
        this.libraryRef.current.showDialog();
        this.libraryRef.current.setVideo(video_url);
        this.libraryRef.current.setStep(0);
    }

    async libraryOnSave(title, video_url){
        let api_key = localStorage.getItem('api_key');        
        let result = await API.library.add(
            api_key,
            parseInt(this.state.id),
            title, video_url
        );
        let success = result['success'];
        if(success){
            toast("Video Added To Library");
            setTimeout(()=>{this.reloadPage();}, 1500);
        } else {
            toast("Error Adding Video To Library");
        }
    }

    getVType(){
        return this.state.id;
    }

    async completedFileUpload(file_name){
        this.videoUploadRef.current.style.display = 'none';
        this.setState({...this.state, canUploadDisplay: false});
        if(file_name == false || file_name == ""){
            toast("error uploading file");
        } else {
            let api_key = localStorage.getItem('api_key');
            let company = this.state.interview.intro_video_url;
            let team = this.state.interview.meet_team_video_url;
            let job = this.state.interview.job_overview_video_url;
            let culture = this.state.interview.culture_video_url;
            let evp = this.state.interview.evp_video_url;
            let sid = this.state.id;
            if(sid == 0){
                company = file_name;
            } else if(sid == 1){
                team = file_name;
            } else if(sid == 2){
                job = file_name;
            } else if(sid == 3){
                culture = file_name;
            } else if(sid == 4){
                evp = file_name;
            }
            let interview_id = this.state.interview.id;
            let result = await API.interview.setITJVideo(api_key, interview_id, company, team, job, culture, evp);
            let success = result['success'];
            if(success){
                toast("Video saved");
            } else {
                toast("Maxed Storage Reached");
                setTimeout(()=>{
                    window.location.href = "/dashboard";
                }, 5000);
                return;
            }
            result = await API.interview.get(api_key, this.state.interview.id);
            let interview = result['interview'];
            this.setState({...this.state, interview: interview, id: sid});

            // TODO: Open Library Addtion PopUp
            this.libraryRef.current.showDialog();
            this.libraryRef.current.setVideo(file_name);
            this.libraryRef.current.setStep(0);
        }
    }

    async openLibrarySelection(e){
        let lib_id = parseInt(e.target.getAttribute('library-id'));        
        this.setState({
            ...this.state,
            lid: lib_id
        });
        let api_key = localStorage.getItem('api_key');
        let result = await API.library.get(api_key, lib_id);
        let success = result['success'];
        if(success){
            toast("Library Video Loaded");
            this.libraryVideoPreviewRef.current.style.display = "flex";
            this.libraryVideoPreviewVideoRef.current.src = result['video_url'];
        } else {
            toast("Error Loading Selected Library Video");
        }
    }

    async librarySelect(){     
        let api_key = localStorage.getItem('api_key');
        let video_url = this.libraryVideoPreviewVideoRef.current.src;
        let company = this.state.interview.intro_video_url;
        let team = this.state.interview.meet_team_video_url;
        let job = this.state.interview.job_overview_video_url;
        let culture = this.state.interview.culture_video_url;
        let evp = this.state.interview.evp_video_url;

        switch(this.state.id){
            case 0:
                company = video_url;
                break;                
            case 1:
                team = video_url;
                break;                
            case 2:
                job = video_url;
                break;                            
            case 3:
                culture = video_url;
                break;                            
            case 4:
                evp = video_url;
                break;
        }
        let result = await API.interview.setITJVideo(api_key, this.state.interview.id, company, team, job, culture, evp);
        let success = result['success'];
        if(success){
            toast("Video Selection Saved");
            this.libraryVideoPreviewVideoRef.current.pause();
            this.actionPopUpRef.current.style.display = "none";
            this.librarySelectionRef.current.style.display = "none";
            this.libraryVideoPreviewRef.current.style.display = "none";
            this.reload();            
        } else {
            toast("Error Selecting Video");
        }
    }

    async deleteLibrarySelection(){
        let api_key = localStorage.getItem('api_key');
        let id = this.state.lid;
        let result = await API.library.delete(api_key, id);
        let success = result['success'];
        if(success){
            toast("Video Deleted from Library");
            this.libraryVideoPreviewRef.current.style.display = "none";
            this.openLibrary();
        } else {
            toast("Error deleting video from library");
        }
    }
   
    closeLibraryPreview(){
        this.libraryVideoPreviewVideoRef.current.pause();
        this.libraryVideoPreviewRef.current.style.display = "none";
    }

    render() {
        return (
            <>
            <div className="itjContianer">
                <div className="itjContianerDropDown">
                    {
                        <><span>Intro Videos</span><button ref={this.hideShowBtnRef} onClick={this.toggle} id="introVideos">Show</button></>
                    }
                </div>
                <div className="itjContianerTabs" ref={this.dropdownRef}>
                    <div>
                        <div>
                            <h4>Company Intro</h4>
                            <span>Add a Company intro video to your candidate interview process</span>
                        </div>
                        <div>
                            {
                                this.state.interview ? 
                                (this.state.interview.intro_video_url && this.state.interview.intro_video_url != "" ? (
                                    <>
                                        <button className="buttonX" index-id="0" onClick={this.editVideo}>Edit Video</button>
                                        <button className="buttonX" index-id="0" onClick={this.previewVideo}>Preview Video</button>
                                    </>
                                ) : (
                                    <button className="buttonX" index-id="0" onClick={this.addVideo}>Add Video</button>
                                )) : ""
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4>Meet The Team</h4>
                            <span>Add a Team intro video to your candidate interview process</span>
                        </div>
                        <div>
                            {
                                this.state.interview ? 
                                (this.state.interview.meet_team_video_url && this.state.interview.meet_team_video_url != "" ? (
                                    <>
                                        <button className="buttonX" index-id="1" onClick={this.editVideo}>Edit Video</button>
                                        <button className="buttonX" index-id="1" onClick={this.previewVideo}>Preview Video</button>
                                    </>
                                ) : (
                                    <button className="buttonX" index-id="1" onClick={this.addVideo}>Add Video</button>
                                )) : ""
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4>Job Overview</h4>
                            <span>Add a Position overview video to your candidate interview process</span>
                        </div>
                        <div>
                            {
                                this.state.interview ? 
                                (this.state.interview.job_overview_video_url != null && this.state.interview.job_overview_video_url != "" ? (
                                    <>
                                        <button className="buttonX" index-id="2" onClick={this.editVideo}>Edit Video</button>
                                        <button className="buttonX" index-id="2" onClick={this.previewVideo}>Preview Video</button>
                                    </>
                                ) : (
                                    <button className="buttonX" index-id="2" onClick={this.addVideo}>Add Video</button>
                                )) : ""
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4>Culture</h4>
                            <span>Add a culture video to your candidate interview process</span>
                        </div>
                        <div>
                            {
                                this.state.interview ? 
                                (this.state.interview.culture_video_url != null && this.state.interview.culture_video_url != "" ? (
                                    <>
                                        <button className="buttonX" index-id="3" onClick={this.editVideo}>Edit Video</button>
                                        <button className="buttonX" index-id="3" onClick={this.previewVideo}>Preview Video</button>
                                    </>
                                ) : (
                                    <button className="buttonX" index-id="3" onClick={this.addVideo}>Add Video</button>
                                )) : ""
                            }
                        </div>
                    </div>
                    <div>
                        <div>
                            <h4>Employee Value Proposition</h4>
                            <span>Add a EVP video to your candidate interview process</span>
                        </div>
                        <div>
                            {
                                this.state.interview ? 
                                (this.state.interview.evp_video_url != null && this.state.interview.evp_video_url != "" ? (
                                    <>
                                        <button className="buttonX" index-id="4" onClick={this.editVideo}>Edit Video</button>
                                        <button className="buttonX" index-id="4" onClick={this.previewVideo}>Preview Video</button>
                                    </>
                                ) : (
                                    <button className="buttonX" index-id="4" onClick={this.addVideo}>Add Video</button>
                                )) : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div ref={this.actionPopUpRef} className="actionPopUp">
                <div>
                    <h4>How would you like add a video to this?</h4>
                    <span>Select an action</span>
                    <div>
                        <button onClick={this.openRecorder}>Record</button>
                        <button onClick={this.openUpload}>Upload</button>
                        <button onClick={this.openLibrary}>Select From Library</button>
                        <button ref={this.actionsDeleteRef} onClick={this.deleteSelectITJVideo}>Delete</button>
                        <button onClick={this.closeDialog} className="close">Close</button>
                    </div>
                </div>
            </div>
            <div ref={this.previewVideoRef} className="actionPopUp">
                <div>
                    <h4>Video Preview</h4>
                    <video className="videoSize" src="" ref={this.previewVideoElementRef} autoPlay={true} playsInline={true} controls={true}></video>
                    <div style={{textAlign: 'right', paddingTop:'12px'}}>
                        <button onClick={this.closeDialogPreview} className="close">Close</button>
                    </div>
                </div>
            </div>                
            <div ref={this.videoRecordRef} className="actionPopUp">
                <VideoRecordComponent ref={this.videoRecordElementRef} fixWidth={true} onclose={this.closeVideoRecorder} oncompletion={this.videoAccepted}></VideoRecordComponent>
            </div>
            { 
                this.state.canUploadDisplay == true ? <div ref={this.videoUploadRef}>
                <FileUploadComponnent vtype={this.getVType} onclose={this.closeVideoUpload} oncompletion={this.completedFileUpload}></FileUploadComponnent>
                </div> : ""
            }
            <LibraryComponent ref={this.libraryRef} OnSave={this.libraryOnSave}></LibraryComponent>
            <div ref={this.librarySelectionRef} className="actionPopUp">
                <div>
                    <h4>Select a video from this library list</h4>
                    <div ref={this.libraryItemsRef} className="libraryContainer">
                        {
                            Object.getPrototypeOf(this.state.library) === Array.prototype && this.state.library.map((library, index)=>{
                                return (
                                    <div onClick={this.openLibrarySelection} library-id={library.id}>
                                        <h4>{library.title}</h4>
                                        <video src={library.video_url}></video>
                                    </div>
                                );
                            })
                        }

                        {
                            (!this.state.library || this.state.library.length <= 0) ? <span style={{display:'block',padding:'12px',margin:'12px'}}>No Videos for this library type</span> : <></>
                        }
                    </div>
                    <div>
                        <button onClick={this.closeLibraryDialog} className="close">Close</button>
                    </div>
                </div>
            </div>
            <div ref={this.libraryVideoPreviewRef} className="actionPopUp">
                <div>
                    <h4>Library Video Preview</h4>
                    <video className="videoSize" src="" ref={this.libraryVideoPreviewVideoRef} autoPlay={true} playsInline={true} controls={true}></video>
                    <div style={{textAlign: 'right', paddingTop:'12px'}}>
                        <button onClick={this.closeLibraryPreview} className="close">Close</button>
                        <button onClick={this.deleteLibrarySelection} className="close">Delete</button>
                        <button onClick={this.librarySelect}>Select</button>
                    </div>
                </div>
            </div>    
            </>
        );
      }
}