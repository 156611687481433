import React from "react";
import { createRef } from 'react';

import { ToastContainer, toast } from 'react-toastify';

import API from './../services/api';

export default class RegisterPage extends React.Component {
  
    constructor(props) {
      super(props);
      this.state = {errorMessage: ""}; // isError -> errorMessage

      // bind
      this.handleRegister = this.handleRegister.bind(this);
      this.isValidPhone = this.isValidPhone.bind(this);
      this.validateEmail = this.validateEmail.bind(this);
      
      // setup refs
      
      this.companyRef = createRef();
      this.nameRef = createRef();
      this.emailRef = createRef();
      this.phoneNumberRef = createRef();
      this.passwordRef = createRef();
      this.passwordConfirmRef = createRef();
      this.promocodeRef = createRef();
      this.errorRef = createRef();
    }


    isValidPhone(phoneNumber) {
      var found = phoneNumber.search(/^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/);
      if(found > -1) {
          return true;
      }
      else {
          return false;
      }
  }
  
  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

    // handle login
    async handleRegister(){
      toast("Loading");
      this.errorRef.current.innerHTML = "";
      if(!this.companyRef.current.value){
        this.errorRef.current.innerHTML = "Enter a your company's name";
        return;
      } else if(!this.nameRef.current.value){
        this.errorRef.current.innerHTML = "Enter a your fullname";
        return;
      } else if(!this.emailRef.current.value){
        this.errorRef.current.innerHTML = "Enter a valid email address";
        return;
      } else if(!this.validateEmail(this.emailRef.current.value)){
        this.errorRef.current.innerHTML = "Enter a valid email address";
        return;
      } else if(!this.phoneNumberRef.current.value){
        this.errorRef.current.innerHTML = "Enter a valid phone number";
        return;
      } else if(!this.phoneNumberRef.current.value){
        this.errorRef.current.innerHTML = "Enter a valid phone number";
        return;
      } else if(!this.passwordRef.current.value){
        this.errorRef.current.innerHTML = "Enter a valid password";
        return;
      } else if(!this.passwordConfirmRef.current.value){
        this.errorRef.current.innerHTML = "Please confirm your password";
        return;
      } else if(this.passwordRef.current.value !== this.passwordConfirmRef.current.value){
        this.errorRef.current.innerHTML = "Your passwords don't match";
        return;
      }


      let company = this.companyRef.current.value;
      let fullname = this.nameRef.current.value;
      let email = this.emailRef.current.value;
      let phone_number = this.phoneNumberRef.current.value;
      let password = this.passwordRef.current.value;
      let result = await API.user.checkEmailExists(email);
      let exists = result['exists'];
      if(exists == true){
        this.errorRef.current.innerHTML = "Email already in use.";
        return;
      }
      result = await API.user.checkPhoneNumberExists(phone_number);
      exists = result['exists'];
      if(exists == true){
        this.errorRef.current.innerHTML = "Phone Number already in use.";
        return;
      }
      result = await API.user.registerUser(company, fullname, email, password);
      let api_key = result['api_key'];
      localStorage.setItem('api_key', api_key);
      if(api_key == false){
        this.errorRef.current.innerHTML = "Error Registrating";
        return;
      }
      
      let active = localStorage.getItem('package');
      let subDuration = localStorage.getItem('duraiton');
      let marketing = localStorage.getItem('marketing');
      let promocode = this.promocodeRef.current.value;
      result = await API.user.registerUserStep3(api_key, active, subDuration, marketing, promocode);
      if(!result['success']){
        toast("Error");
      }

      // TODO: Capture into local storage
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);

      window.location.href = '/register/step/3';
    }

    render() {
        // HTML
        return (
            <div className="outer">
                <div className="middle">
                    <div className="container">
                        <div className="loginFormLogo"></div>
                        <div className="form">
                            <h4>Registration Form</h4>
                            <input ref={this.companyRef} id="company" placeholder="Company Name" defaultValue=""/>
                            <input ref={this.nameRef} id="name" placeholder="Full Name" defaultValue=""/>
                            <input ref={this.emailRef} id="email" placeholder="Email Address" defaultValue="" onChange={this.checkEmail}/>
                            <input ref={this.phoneNumberRef} id="phonenumber" placeholder="Phone Number" defaultValue="" onChange={this.checkPhoneNumber}/>
                            <input ref={this.passwordRef} id="password" placeholder="Password" defaultValue=""/>
                            <input ref={this.passwordConfirmRef} id="passwordConfirm" placeholder="Confirm Password" defaultValue=""/>
                            <input ref={this.promocodeRef} id="promocode" placeholder="Promo Code (optional)" defaultValue=""/>
                            <span ref={this.errorRef} id="error">{ this.state.errorMessage}</span>
                            <button onClick={this.handleRegister} id="next">Register</button>
                            <a href="/login">Already a member? Login Here</a>
                            <a href="/forgot">Forgot Password?</a>
                        </div>
                    </div>
                </div>
            </div>
        );
      }
}