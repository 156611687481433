import React, { useRef, useEffect, useState } from "react";
import Question from "../../classes/QuestionClass";
import API from "../../services/api";
export default function useQuestionsHook(api_key:string, interview_id:number) {
    const [ apiKey, setAPIKey] = useState<string>(api_key);
    const [ interviewID, setInterviewID] = useState<number>(interview_id);
    const [ questions, setQuestions] = useState<Question[] | null>(null);
    const reloadQuestions = async () => {
        let result = await API.questions.get(apiKey, interviewID);
        setQuestions(result["questions"])
    }
    const addQuestion = async (duration:number, type:string, text:string | null, video_url:string | null) => {
        let result = await API.questions.create(apiKey, duration, type, text, video_url, interviewID);        
        await reloadQuestions();
    }
    const removeQuestion = async (question_id:number) => {
        let result = await API.questions.get(apiKey, interviewID);
        setQuestions(result["questions"])
    }
    useEffect(()=>{
        if(!interviewID) return;
        reloadQuestions();
    }, [interviewID]);
    return { questions, reloadQuestions, addQuestion, removeQuestion }
}