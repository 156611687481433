import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import API from '../../../services/api';
import { BsFillCameraVideoFill } from 'react-icons/bs';

import Facebook from "./../../../services/facebook";
import { FaFacebook, FaLinkedinIn } from 'react-icons/fa';

import './socialFolder.css';

interface SocialFolderProps {
    apiKey: string;
    interview: any;
    userInfo: any;
    questions: any[];
    interviewId: any;
    reloadInterview: () => void;
    handleFlowCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleFlowCallback: (data:any) => void;
    handleSocialVideoCallback: (callback: (data:any) => Promise<boolean>) => void;
    executeHandleSocialVideoCallback: (data:any) => void;
    executeShowLink: (data:any) => void;
    handleFacebookPageSubmission: (callback: (pages_data:any[]) => Promise<boolean>) => void;
    executeHandleFacebookPageSubmission: (pages_data:any[]) => void;
    executeLoading: (state:boolean) => void;
    executeShowLinkedInShare: (video_url:string, access_token:string, user_id:string) => void;
    handleLinkedInShareCallback: (callback: () => Promise<void>) => void;
}
export default function SocialFolder({apiKey, interview, userInfo, interviewId, executeLoading, questions, executeShowLink, reloadInterview, handleFlowCallback, executeHandleFlowCallback, handleSocialVideoCallback, executeHandleSocialVideoCallback, handleFacebookPageSubmission, executeHandleFacebookPageSubmission, executeShowLinkedInShare, handleLinkedInShareCallback}:SocialFolderProps){
    // State
    const [pages, setPages] = useState<any[]>([]);
    const [ fbLoggedIn, setFbLoggedIn] = useState<boolean>(false);
    const [ isLoggedInLinked, setIsLoggedInLinked] = useState<boolean>(false);
    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    if (!(window as any).FB) {
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    }
    // Functions
    const handleLinkedInAuth = () => {
        let loc = window.location.href;
        let client_id = "8663tuq8r4umo5"; // TODO: NOTE: Keep Updated
        let redirectURL = "https://api.clipdrop.io/api/v1/linkedin/redirect";
        let scope = "r_emailaddress w_member_social r_basicprofile r_liteprofile";
        let state = btoa(userInfo.id + "/./" + loc).replace("%3D", "");
        window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=${state}&scope=${scope}&client_id=${client_id}&redirect_uri=${encodeURI(redirectURL)}`;
    }
    // Effect
    useEffect(()=>{
        (window as any).FB.init({
            appId: "1037968913418686",
            cookie: true,
            xfbml: true,
            version: "v13.0",
        });
        (window as any).FB.AppEvents.logPageView();
        (window as any).FB.getLoginStatus(function (response) {
            console.log(response);
            let status = response["status"];
            if (status != "connected") {
                setFbLoggedIn(false);
            } else {
                setFbLoggedIn(true);
                console.log("LoggedIn");
            }
        });
    }, []);
    useEffect(()=>{
        if(!userInfo) return;
        let isNotExpired = new Date().getTime() - new Date(userInfo.linkedin_user_access_token_expiry_date).getTime() < 0;
        if(userInfo.linkedin_user_access_token && userInfo.linkedin_user_id && isNotExpired){
            setIsLoggedInLinked(true);
        } else {
            setIsLoggedInLinked(false);
        }
    }, [userInfo]);
    // Render
    if(!interview){
        return <div className='interview-folder-container'>Loading Folder Data</div>
    }
    return <>
        <div className='interview-folder-container'>
            <div className='nice-form-2'>
                <div>
                    <h4 style={{display:'block', marginRight:'8px'}}>Actions</h4>
                    <button style={{display:'inline-block', fontSize:'16pt'}} className={interview.social_start_interactive === 1 ? 'button-primary-locked-out' : "button-primary-orange"} onClick={ e => { 
                        if(interview.social_start_interactive === 1){
                            toast.info("Flow already completed");
                            return;
                        }               
                        handleFlowCallback(async (data:any): Promise<boolean> => {
                            toast.info("Updating Folder");
                            let result = await API.interview.startInteractivity(apiKey,interview.id,2,1);
                            toast.info("Reloading Interview");
                            reloadInterview();
                            return true;
                        });
                        executeHandleFlowCallback({
                            interview_id: interview.id
                        });
                    }}>Start</button>
                    <button style={{display:'inline-block', fontSize:'16pt'}}className='button-primary-blue' onClick={ e => setShowSettings(x => !x)}>Edit</button>
                </div>
                <p>Add a Social Video, Then you may share it via LinkedIn and/or Facebook</p>
                { showSettings && <>            
                    <h4>Settings - Social Post</h4>
                    <div className='greyed'>
                        <label htmlFor="outReach-campaign-video">
                            <span>Social Post Video</span>
                        </label>              
                        <div
                            className="tab2ActionContainerMaxWidthSplit"
                            style={{
                                minWidth: "299px",
                                width: "calc(23% - 8px)",
                                padding: "4px",
                                margin: "0px 4px",
                            }}
                        >
                            <video
                                className="tab2ActionContainerMaxWidthMiniImage"
                                src={interview.advertise_marketing_video_url}
                                poster={
                                    interview.advertise_marketing_video_url
                                    ? ""
                                    : "https://clipdrop.io/wp-content/assets/media/images/video.png"
                                }
                                playsInline={true}
                                controls={true}
                            />
                            <button
                                className="candidateMiniButton bigger"
                                onClick={()=>{
                                    handleSocialVideoCallback(async (data:any)=>{
                                        toast.info("Reloading Interview");
                                        reloadInterview();
                                        return data.success;
                                    })
                                    executeHandleSocialVideoCallback({
                                        interview_id: interview.id
                                    })
                                }}
                            >
                            <BsFillCameraVideoFill />
                            </button>
                        </div>
                        <label htmlFor="outReach-campaign-video">
                                <span style={{ fontWeight: 'bold' }}>Receive Candidates Into:</span>
                            </label> 
                            <select
                                style={{ margin:'0px', width:'calc(100% + 12px)' }}
                                defaultValue={interview.advertise_candidate_load}
                                onChange={async (e) => {
                                    toast("Updating");
                                    let value = parseInt(e.target.value);
                                    let result = await API.interview.AdvertiseCandidateLoad(
                                        apiKey,
                                        interview.id,
                                        value
                                    );
                                    toast("Updated");
                                }}
                            >
                                <option value="0">CRM/ATS</option>
                                <option value="1">Interview (self signup)</option>
                            </select>
                            <label htmlFor="outReach-campaign-video">
                                <span style={{ fontWeight: 'bold' }}>User Redirection Link:</span>
                            </label>
                            <div style={{ display: "inline-block", fontSize: "8pt" }}>
                                Example URL{" "}
                                <strong>
                                    <span style={{ backgroundColor: "yellow" }}>
                                    https://
                                    </span>
                                    www.example.com/
                                </strong>
                            </div>
                            <input
                                style={{ margin:'0px', width:'calc(100% - 16px)' }}
                                type="text"
                                placeholder="https://www.example.com/"
                                onBlur={async (e:any)=>{
                                    let result = await API.interview.updateRedirectionLink(
                                        apiKey,
                                        interview.id,
                                        e.target.value
                                    );
                                }}
                                defaultValue={interview.advertise_marketing_redirect_url}
                            />
                            <span style={{ fontSize: "8pt", color: "#B5B5C3" }}>
                                We will redirect the candidate to this url
                        </span>
                    </div>
                    <div className='greyed' style={{minHeight: '526px'}}>
                            <label htmlFor="outReach-campaign-video">
                                <span style={{ fontWeight: 400 }}>Social Post Title</span>
                            </label> 
                            <input
                                style={{ margin:'0px' }}
                                defaultValue={interview.social_caption}
                                onBlur={async (e:any)=>{
                                    toast("Updated");
                                    let result = await API.interview.setSocialMarketingSocial(
                                        apiKey,
                                        interview.id,
                                        e.target.value
                                    );
                                    toast("Updating");
                                }}
                                placeholder="Title"
                            />
                            <label htmlFor="outReach-campaign-video">
                                <span style={{ fontWeight: 400 }}>Social Post Text</span>
                            </label> 
                            <textarea
                                className="advertisemarketingText"
                                style={{ margin:'0px', width:'calc(100% - 31px)' }}
                                defaultValue={interview.advertise_marketing_text}
                                onBlur={async (e:any)=>{
                                    toast("Updating");
                                    let result = await API.interview.setSocialMarketingSocial(
                                        apiKey,
                                        interview.id,
                                        e.target.value
                                    );
                                    toast("Updated");
                                }}
                            ></textarea>                                                   
                    </div>
                </>}
                {
                    (questions && questions.length >= 1 && interview.advertise_marketing_thumbnail_url !== "" && interview.advertise_marketing_text != "") && <button className='button-primary-blue blocked2 full' style={{maxWidth:'1200px', margin:'0 auto'}} onClick={ async e => {
                        toast.info("Loading");
                        let result = await API.interview.getSocialLink(apiKey,interview.id);
                        let link = result["link"];
                        executeShowLink(link);
                    }}>Get Advertisement Link</button>
                }
                {
                    !(questions && questions.length >= 1 && interview.advertise_marketing_thumbnail_url !== "" && interview.advertise_marketing_text != "") && <button className='button-primary-locked-out blocked2 full' style={{maxWidth:'1200px', margin:'0 auto'}} onClick={ e => {
                    }}>Add Questions, a redirect link, a thumbnail and video for marketing to be able to get an advertisement link</button>
                }
                <div className='col2-buttons'>
                    {
                        !interview.advertise_marketing_video_url && <button className='button-primary-blue blocked2 full' onClick={ e => {
                            toast.warning(
                                "Warning! Before you can share, you must please click the start button and finish the recording process",
                            );
                            return;
                        }}><FaFacebook
                        style={{ transform: "translateY(3px)" }}
                        />&nbsp;&nbsp;Share on Facebook</button>
                    }
                    {
                        interview.advertise_marketing_video_url && <>
                            {!fbLoggedIn && (
                                <button className='button-primary-blue blocked2 full' onClick={()=>{
                                    (window as any).FB.login(
                                        function (response) {
                                        if (response.status === "connected") {
                                            setFbLoggedIn(true);
                                        } else {
                                            setFbLoggedIn(false);
                                        }
                                        },
                                        {
                                        scope:
                                            "public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts",
                                        }
                                    );
                                }}><FaFacebook
                                style={{ transform: "translateY(3px)" }}
                                />&nbsp;&nbsp;Sign In with Facebook</button>
                            )}
                            {fbLoggedIn && (
                                <button className='button-primary-blue blocked2 full' onClick={ e => {
                                    toast.info("Loading Pages, Please wait");
                                    (window as any).FB.api("/me/accounts", function (response) {
                                        let pagesx = response["data"];
                                        pagesx = pagesx.map((page) => {
                                        return {
                                            access_token: page.access_token,
                                            id: page.id,
                                            name: page.name,
                                        };
                                        });
                                        toast.info("Pages, Loaded");
                                        setPages(pagesx);
                                        handleFacebookPageSubmission(async (pages_data:any[]):Promise<boolean>=>{
                                            toast.info("Posting to pages, please wait");
                                            let result = await API.interview.getSocialLink(apiKey, interview.id);
                                            let link = result["link"];
                                            if (interview.advertise_candidate_load) {
                                                link = `https://api.clipdrop.io/v1/api/signup/${interview.id}/${interview.title.replace(" ", "")}`
                                            }
                                            for (let i = 0; i < pages_data.length; i++) {
                                                let activePage = pages_data[i];
                                                let title = interview.social_caption;
                                                let description = `${interview.advertise_marketing_text}\n\n${link}`;
                                                let page_id = activePage.id;
                                                let page_access_token = activePage.access_token;
                                                let file_url = interview.advertise_marketing_video_url;
                                                let response = await new Promise((resolve, reject) => {
                                                        (window as any).FB.api(`/${page_id}/videos`,"POST", {
                                                        title: title,
                                                        description: description,
                                                        file_url: file_url,
                                                        access_token: page_access_token,
                                                    },
                                                        function (response) {
                                                            resolve(response);
                                                        }
                                                    );
                                                });
                                                console.log(response);
                                            }
                                            toast.success("Content Posted");
                                            return true;
                                        })
                                        executeHandleFacebookPageSubmission(pagesx);
                                    });
                                }}><FaFacebook
                                style={{ transform: "translateY(3px)" }}
                                />&nbsp;&nbsp;Share on Facebook</button>
                            )}
                        </>
                    }
                    {
                        isLoggedInLinked && <button className='button-primary-blue blocked2 full' onClick={ async e => {
                            if (!interview.advertise_marketing_video_url) {
                                toast.warning(
                                "Warning! Before you can share, you must please click the start button and finish the recording process",
                                );
                                return;
                            }
                            // interview.advertise_marketing_text
                            // interview.advertise_marketing_video_url
                            toast.info("Loading");
                            handleLinkedInShareCallback(async ()=>{
                                // toast.success("Video Posted To LinkedIn");
                            });
                            executeShowLinkedInShare(interview.advertise_marketing_video_url, userInfo.linkedin_user_access_token, userInfo.linkedin_user_id);
                        }}><FaLinkedinIn
                        style={{ transform: "translateY(3px)" }}
                        />&nbsp;&nbsp;Share on LinkedIn</button>
                    }
                    {
                        !isLoggedInLinked && <button className='button-primary-blue blocked2 full' onClick={ e => {
                            handleLinkedInAuth();
                        }}>
                            <FaLinkedinIn
                            style={{ transform: "translateY(3px)" }}
                            />&nbsp;&nbsp;Sign In with LinkedIn
                        </button>
                    }
                </div>
            </div>
        </div>
    </>
}