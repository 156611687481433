import React from 'react';
import InBrowserRecorderAndProcessingComponentV2 from '../components/inBrowserRecorderAndProcessingComponentV2/InBrowserRecorderAndProcessingComponentV2';
import InBrowserGifFromVideoComponent from '../components/InBrowserGifFromVideoComponent/InBrowserGifFromVideoComponent';

export default function TestPage(props:any){
    return <InBrowserGifFromVideoComponent
        apiKey={localStorage.getItem("api_key") ?? ""}
        inputVideoURL={'https://storage.googleapis.com/clipdrop-prod/tfvIOGDNFJr8VU_03p09aM9Aw.mp4?avoidCache=1'}
        text={"Hi J"}
        onClose={ () => {}}
        onCompletion={ async video_url => { console.log(video_url) }}
    />
    return <InBrowserRecorderAndProcessingComponentV2
        apiKey={localStorage.getItem("api_key") ?? ""}
        clientWatermarkImage={"https://storage.googleapis.com/clipdrop-prod/secure/paypal.png"}
        onClose={ () => {}}
        onCompletion={ async video_url => { console.log(video_url) }}
    />
}