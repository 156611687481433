import React from "react";

import { ToastContainer, toast } from 'react-toastify';

import { createRef } from "react";
import API from "../services/api";


export default class ForgotPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {isError: false, errorMessage: ""}; // isError -> errorMessage

      // bind
      this.handleForgot = this.handleForgot.bind(this);

      this.part1Ref = createRef();
      this.part2Ref = createRef();
      this.resetEmailRef = createRef();
    }

    componentDidMount(){
        this.part1Ref.current.style.display = 'block';
        this.part2Ref.current.style.display = 'none';
    }

    // handle login
    async handleForgot(){
        toast("Loading");
        let email = this.resetEmailRef.current.value;
        if(!email){
            toast("Invalid Email Address");
            return;
        }
        let result = await API.user.sendUserPasswordReminderEmail(email);
        let success = result['success'];
        if(!success){
            toast("Unknown Error Occured");
            return;
        }
        this.part1Ref.current.style.display = 'none';
        this.part2Ref.current.style.display = 'block';
    }

    render() {
        // HTML
        return (
            <>
            <div className="outer">
                <div className="middle">
                    <div className="container">
                        <div className="loginFormLogo"></div>
                        <div className="form" ref={this.part1Ref}>
                            <input ref={this.resetEmailRef} id="email" placeholder="Email Address" defaultValue=""/>
                            {   // handle and display errors if they exist
                                this.state.isError ? (
                                    <span className="error">{this.state.errorMessage}</span>
                                ) : ''
                            }
                            <button id="resetPasswordLink" onClick={this.handleForgot}>Send Password Reset Link</button>
                            <a href="/login">Remember your password? login here</a>
                        </div>
                        <div className="form" ref={this.part2Ref}>
                            <span>You will receive your password via Email, please check your inbox or check your spam folder</span>
                            <a href="/login">click here to login</a>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
      }
}