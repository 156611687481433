import React, { useEffect, useState, useRef } from 'react';

export default function ClearManager(props:any){
    useEffect(()=>{
        localStorage.setItem("api_key", "");
        localStorage.setItem("temp_api_key", "");
        setTimeout(()=>{
            window.location.assign("/");
        }, 1000);
    }, []);
    return <>Clearing Data Please Wait</>
}