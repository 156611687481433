import React from "react";

import SideBarComponent from "../components/sidebar";
import UpperBarComponent from "../components/upperbar";
import API from "../services/api";

import { AiOutlineLoading } from 'react-icons/ai';

import './promocodeusage.css';

import { createRef } from "react";

export default class PromoCodeUsagePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
            loading: true,
            dataset: false,
            dataset2: false
        };

        this.resellerTextRef = createRef();
        this.affiliateTextRef = createRef();
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        if(user.isAdmin === false){
            window.location.href = "/dashboard";
        }

        result = await API.promoters.getResellers(api_key);
        let dataset = result['dataset1']; 
        let dataset2 = result['dataset2']; 
        let dataset3 = result['dataset3']; 
        let dataset4 = result['dataset4']; 
        this.setState({
            ...this.state,
            loading:false,
            dataset: dataset,
            dataset2: dataset2,
            dataset3: dataset3,
            dataset4: dataset4
        });
    }


    render() {
        // HTML
        return (
            <>
                <div className="dashboardContainer">
                    <SideBarComponent></SideBarComponent>
                    <div className="dashboarContent">
                        <UpperBarComponent></UpperBarComponent>
                        <div className="pageNameInfo">
                            <span>Promotion Code Usage</span>
                        </div>
                    </div>
                    <div className="vacancy-table prcing-table-2" style={{marginLeft:'250px'}}>
                        <span ><strong>Below you will find resellers and how much they have earned for each month</strong></span>
                        <div className="searchresellers"><span>Search by</span><input type="text" placeholder="Promocode" ref={this.resellerTextRef}/><button onClick={async ()=>{
                            this.setState({...this.state, loading: true});
                            let api_key = localStorage.getItem('api_key');
                            let result = await API.promoters.getResellersSearch(api_key, this.resellerTextRef.current.value);                            
                            let dataset = result['dataset1']; 
                            let dataset2 = result['dataset2']; 
                            this.setState({...this.state, loading: false, dataset: dataset, dataset2: dataset2});
                        }}>Search</button><button onClick={async ()=>{
                            this.setState({...this.state, loading: true});
                            let api_key = localStorage.getItem('api_key');
                            let result = await API.promoters.getResellersSearch(api_key, "");                            
                            let dataset = result['dataset1']; 
                            let dataset2 = result['dataset2']; 
                            this.setState({...this.state, loading: false, dataset: dataset, dataset2: dataset2});
                        }} className="red">Clear</button></div>
                        <div className="promoters_view" style={{paddingBottom:'64px'}}>                            
                            { this.state.dataset !== false && 
                            <table>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th style={{maxWidth:'500px'}}>Name (ID)</th>
                                        <th>Promo Code</th>
                                        <th>Money Earned</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.dataset.map((row)=>{
                                            return (
                                                <tr>
                                                    <td>{ row.month }</td>
                                                    <td style={{maxWidth:'500px'}}>{ row.name_and_id }</td>
                                                    <td>{ row.promocode }</td>
                                                    <td>R{ row.amount / 100}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            }
                        </div>
                        <span><strong>Below you will find affiliates and how much they have earned for each month</strong></span>
                        <div className="searchresellers"><span>Search by</span><input type="text" placeholder="Promocode" ref={this.affiliateTextRef}/><button onClick={async ()=>{
                            this.setState({...this.state, loading: true});
                            let api_key = localStorage.getItem('api_key');
                            let result = await API.promoters.getResellersSearch2(api_key, this.affiliateTextRef.current.value);                            
                            let dataset3 = result['dataset3']; 
                            let dataset4 = result['dataset4']; 
                            this.setState({...this.state, loading: false, dataset3: dataset3, dataset4: dataset4});
                        }}>Search</button><button onClick={async ()=>{
                            this.setState({...this.state, loading: true});
                            let api_key = localStorage.getItem('api_key');
                            let result = await API.promoters.getResellersSearch2(api_key, "");                            
                            let dataset3 = result['dataset3']; 
                            let dataset4 = result['dataset4']; 
                            this.setState({...this.state, loading: false, dataset3: dataset3, dataset4: dataset4});
                        }} className="red">Clear</button></div>
                            { this.state.dataset !== false && 
                            <table>
                                <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th style={{maxWidth:'500px'}}>Name (ID)</th>
                                        <th>Promo Code</th>
                                        <th>Months Subscribed</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.dataset3.map((row)=>{
                                            return (
                                                <tr>
                                                    <td>{ row.month }</td>
                                                    <td style={{maxWidth:'500px'}}>{ row.name_and_id }</td>
                                                    <td>{ row.promocode }</td>
                                                    <td>{ row.amount }</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            }
                    </div>
                </div>
                       
                { 
                    this.state.loading ? (
                        <div className="loader">                        
                            <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                        </div>
                    ) : ""
                }
            </>
        );
      }
}