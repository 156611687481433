import React, { createRef } from "react";

import jQuery from "jquery";
import $ from "jquery";

import { MdClear } from "react-icons/md";

import FileUploadComponnent from "./fileupload";

import { Tooltip as ReactTooltip } from "react-tooltip";

import { DateTime } from "luxon";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BsPersonPlusFill,
  BsPencil,
  BsCloudUpload,
  BsFillCameraVideoFill,
  BsArrowDownUp,
} from "react-icons/bs";
import { IoIosDocument, IoIosPaperPlane } from "react-icons/io";
import {
  FaArrowRight,
  FaLaptopHouse,
  FaRegCommentDots,
  FaSpinner,
} from "react-icons/fa";
import { ImBin2 } from "react-icons/im";
import { MdOutlineAssessment } from "react-icons/md";

import { RiShareForward2Fill } from "react-icons/ri";

import { AiFillStar, AiFillCamera, AiOutlineLoading } from "react-icons/ai";

import { AiFillFilter, AiFillEye } from "react-icons/ai";

import play_icon_grey from "./../media/play_icon_grey.png";
import play_icon_color from "./../media/play_icon_color.png";

import assessment_icon_color from "./../media/assessment-blue.png";
import assessment_icon_grey from "./../media/assessment-grey.png";

import API from "../services/api";

import VideoRecordComponent from "./videorecord";

import "./candidates.css";

import Webcam from "react-webcam";

import LibraryComponent from "./library";

import Facebook from "../services/facebook";

import { FaLinkedinIn, FaFacebook } from "react-icons/fa";
import ToggleComponent from "./toggle";
import VideoRecordComponent2 from "./videorecord2";
import FileUploadComponent from "./fileupload";
import SimplePagination from "./simple-pagination/SimplePagination";

if (!window.FB) {
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
}

const FB = window.FB;

var review = [];

const VideoProcessingInlineComponent = (
  <>
    <FaSpinner className="spinner" />
    <span>&nbsp;&nbsp;Processing Video</span>
  </>
);
const VideoProcessedInlineComponent = (
  <>
    <span>Video Ready</span>
  </>
);

export default class CandidatesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: false,
      current_candidate_id: 0,
      user: props.user,
      interview: props.interview,
      surveys: props.surveys,
      questions: [],
      isLoaded: false,
      loading: false,
      normal_candidates: [],
      normal_candidates_total_pages: 1,
      normal_candidates_current_page: 0,
      normal_candidate_search: "",
      bespoke_candidates: [],
      bespoke_candidates_total_pages: 1,
      bespoke_candidates_current_page: 0,
      outreach_candidates: [],
      outreach_candidates_total_pages: 1,
      outreach_candidates_current_page: 0,
      library: [],
      isVisible: false,
      canRecordVideo: false,
      canRecordVideoSocial: false,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][props.interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][props.interview.advertise_marketing_video_state],
      showCandidateAssessment: false,
    };
    this.candidateAssessment = createRef();
    this.campaignChecker = false;
    this.socialChecker = false;

    this.r_id = 0;
    this.filter_recording = false;
    this.filter_norecording = false;
    this.filter_ready_to_send = false;
    this.filter_viewed = false;

    this.filter_recording_candidate = false;
    this.filter_norecording_candidate = false;
    this.filter_assessment_candidate = false;
    this.filter_no_assessment_candidate = false;
    this.filter_order_by_assessment_score = false;
    this.filter_rated = false;
    this.filter_not_rated = false;

    this.sort_ids = false;
    this.sort_views = false;
    this.callSiblingUpdate = props.callSiblingUpdate;

    this.openLibraryDialog = this.openLibraryDialog.bind(this);

    this.showFacebookShareNoticeDialogRef = createRef();
    this.showAssessmentDialog = createRef();

    this.hideIfATS1 = createRef();
    this.hideIfATS2 = createRef();
    this.hideIfATS3 = createRef();

    this.bulkLinkShareDialogRef = createRef();
    this.bulkLinkShareDialogNameRef = createRef();
    this.bulkLinkShareDialogEmailRef = createRef();
    this.bulkLinkShareRateDialogRef = createRef();
    this.bulkLinkShareRateDialogNameRef = createRef();
    this.bulkLinkShareRateDialogEmailRef = createRef();

    this.tab1 = createRef();
    this.tab1SearchField = createRef();
    this.createCandidateDialogRef = createRef();
    this.createCandidateDialogNameRef = createRef();
    this.createCandidateDialogEmailRef = createRef();
    this.createCandidateDialogLinkedInRef = createRef();
    this.createCandidateDialogPhoneRef = createRef();

    this.customCandidateVideoelementRef = createRef();

    this.allAnwserLinks = createRef();
    this.allAnwserLinksText = createRef();

    this.bulkLinkEmailDialogRef = createRef();
    this.bulkLinkEmailDialogEmailAddressRef = createRef();
    this.bulkLinkEmailDialogSubjectRef = createRef();
    this.bulkLinkEmailDialogBodyRef = createRef();

    this.bulkVideoDialogElementRef = createRef();
    this.socialVideoDialogElementRef = createRef();

    this.candidateRefDialogQuestionTitle = createRef();
    this.candidateRefDialogAnswerTitle = createRef();

    this.tab2 = createRef();
    this.tab2SearchField = createRef();
    this.createCandidateDialogRef2 = createRef();
    this.createCandidateDialogNameRef2 = createRef();
    this.createCandidateDialogEmailRef2 = createRef();
    this.createCandidateDialogLinkedInRef2 = createRef();
    this.createCandidateDialogPhoneRef2 = createRef();
    this.tab3 = createRef();
    this.tab3SearchField = createRef();
    this.createCandidateDialogRef3 = createRef();
    this.createCandidateDialogNameRef3 = createRef();
    this.createCandidateDialogEmailRef3 = createRef();
    this.createCandidateDialogLinkedInRef3 = createRef();
    this.createCandidateDialogPhoneRef3 = createRef();
    this.tab4 = createRef();
    this.tabHolder = createRef();

    this.cvPreviewIFrameRef = createRef();

    this.updateSocialRedirectionLinkRef = createRef();

    this.updateCandidateDialogRef = createRef();
    this.updateCandidateDialogIDRef = createRef();
    this.updateCandidateDialogNameRef = createRef();
    this.updateCandidateDialogEmailRef = createRef();
    this.updateCandidateDialogLinkedInRef = createRef();
    this.updateCandidateDialogPhoneRef = createRef();

    this.updateCandidateDialogRef2 = createRef();
    this.updateCandidateDialogIDRef2 = createRef();
    this.updateCandidateDialogNameRef2 = createRef();
    this.updateCandidateDialogEmailRef2 = createRef();
    this.updateCandidateDialogLinkedInRef2 = createRef();
    this.updateCandidateDialogPhoneRef2 = createRef();

    this.updateCandidateDialogRef3 = createRef();
    this.updateCandidateDialogIDRef3 = createRef();
    this.updateCandidateDialogNameRef3 = createRef();
    this.updateCandidateDialogEmailRef3 = createRef();
    this.updateCandidateDialogLinkedInRef3 = createRef();
    this.updateCandidateDialogPhoneRef3 = createRef();

    this.cvDialogRef = createRef();
    this.cvPreviewDialogRef = createRef();

    this.cvUploadDialogRef = createRef();
    this.candidateInviteLinkDialogRef = createRef();
    this.candidateInviteLinkDialogRef2 = createRef();
    this.baseThumbnailDialog = createRef();
    this.bulkThumbnailDialog = createRef();
    this.candidateInvitationLinkRef = createRef();
    this.candidateInvitationLinkRef2 = createRef();

    this.cvImageUploadFileRef = createRef();
    this.imageUploadFileRef = createRef();
    this.imageUploadFileBulkRef = createRef();
    this.imageUploadFileSocialRef = createRef();

    this.baseThumbnailDialogUpload = createRef();
    this.baseThumbnailDialogCapture = createRef();
    this.bulkThumbnailDialogUpload = createRef();
    this.bulkThumbnailDialogCapture = createRef();
    this.socialThumbnailDialog = createRef();
    this.socialThumbnailDialogUpload = createRef();
    this.socialThumbnailDialogCapture = createRef();

    this.cameraRef = createRef();
    this.imgRef = createRef();
    this.cameraCounterRef = createRef();
    this.cameraCounterBulkRef = createRef();

    this.savecaptureImageRef = createRef();

    this.cameraBulkRef = createRef();
    this.imgBulkRef = createRef();
    this.cameraCounterBulkRef = createRef();
    this.savecaptureImageBulkRef = createRef();

    this.cameraSocialRef = createRef();
    this.imgSocialRef = createRef();
    this.cameraCounterSocialRef = createRef();
    this.savecaptureImageSocialRef = createRef();

    this.cameraBulkRef = createRef();
    this.imgBulkRef = createRef();
    this.cameraCounterBulkRef = createRef();
    this.savecaptureImageBulkRef = createRef();

    this.cvDialogPreviewRef = createRef();

    this.thumbnailTextRef = createRef();
    this.thumbnailTextOutreachRef = createRef();

    this.customMessageRef = createRef();

    this.marketingTextRef = createRef();

    this.tab2Actions = createRef();
    this.tab3Actions = createRef();
    this.tab4Actions = createRef();

    this.allowBulkInterviewRef = createRef();
    this.allowOutReachInterviewRef = createRef();

    this.tab1btn = createRef();
    this.tab2btn = createRef();
    this.tab3btn = createRef();
    this.tab4btn = createRef();

    this.bulkTextRef = createRef();
    this.bulkText2Ref = createRef();

    this.socialTextRef = createRef();
    this.socialCaptionRef = createRef();

    this.customCandidateVideoRef = createRef();
    this.customCandidateVideoPreviewRef = createRef();

    this.customCandidateVideo = createRef();

    this.customCandidateVideoPreviewVideoRef = createRef();

    this.candidateMarketingInviationLink = createRef();
    this.candidateMarketingInviationLinkText = createRef();
    this.candidateMarketingInviationEmailText = createRef();
    this.candidateMarketingInviationEmailText2 = createRef();
    this.candidateMarketingInviationEmailText22 = createRef();

    this.bulkVideoDialogRef = createRef();
    this.bulkVideoUploadDialogRef = createRef();

    this.socialVideoRef = createRef();

    this.candidateRefDialog = createRef();
    this.candidateRefDialogNotice = createRef();

    this.candidateRefDialogQuestion = createRef();
    this.candidateRefDialogQuestionText = createRef();
    this.candidateRefDialogQuestionVideo = createRef();
    this.candidateRefDialogQuestionVideoControls = createRef();

    this.candidateRefDialogAnswer = createRef();
    this.candidateRefDialogAnswerVideo = createRef();

    this.candidateRefDialogRating = createRef();

    this.candidateRefDialogComplete = createRef();

    this.shareDialogRef = createRef();
    this.shareDialogPart1Ref = createRef();
    this.shareDialogFullnameRef = createRef();
    this.shareDialogEmailAddressRef = createRef();
    this.shareDialogPart2Ref = createRef();
    this.shareDialogLinkRef = createRef();

    this.shareAndRateDialogRef = createRef();
    this.shareAndRateDialogPart1Ref = createRef();
    this.shareAndRateDialogFullnameRef = createRef();
    this.shareAndRateDialogEmailAddressRef = createRef();
    this.shareAndRateDialogPart2Ref = createRef();
    this.shareAndRateDialogLinkRef = createRef();
    this.advertisementDialogRefLink = createRef();

    this.candidateCommentsDialogRef = createRef();
    this.candidateCommentsDialogContentsRef = createRef();

    this.candidateCommentsPreviewDialogRef = createRef();
    this.candidateCommentsPreviewDialogVideoRef = createRef();

    this.bespokeFlowStep1 = createRef();
    this.bespokeFlowStep1Select = createRef();
    this.bespokeFlowStep3Select = createRef();
    this.bespokeFlowStep2 = createRef();
    this.bespokeFlowStep2SubStep1 = createRef();
    this.bespokeFlowStep2SubStepCamera = createRef();
    this.bespokeFlowStep2SubStepUpload = createRef();
    this.bespokeFlowStep2SubStepSelect = createRef();

    this.bespokeFlowStep4 = createRef();
    this.bespokeFlowStep5 = createRef();
    this.bespokeFlowStep6 = createRef();
    this.bespokeFlowStep7 = createRef();

    this.bespokeFlowDialogRef = createRef();

    this.bespokeCustomThumbnailStep1Ref = createRef();
    this.socialCustomThumbnailStep1Ref = createRef();

    this.bespokeCustomMarketingTextStep3Ref = createRef();

    this.bespokeThumbnailRef = createRef();

    this.saveCaptureBespokeButtonRef = createRef();

    this.bespokeFileUploadRef = createRef();
    this.outreachFileUploadRef = createRef();
    this.socialFileUploadRef = createRef();

    this.outreachVideoCapturedRef = createRef();

    this.outreachFlowDialogRef = createRef();
    this.outreachFlowStep1 = createRef();
    this.outreachFlowStep1Select = createRef();
    this.outreachFlowStep3 = createRef();
    this.outreachFlowStep3Option = createRef();
    this.outreachFlowStep3Upload = createRef();
    this.outreachFlowStep4 = createRef();
    this.outreachFlowStep5 = createRef();
    this.outreachFlowStep6 = createRef();
    this.outreachThumbnailRef = createRef();
    this.imgOutReachRef = createRef();
    this.cameraOutReachRef = createRef();

    this.socialFlowDialogRef = createRef();
    this.socialFlowStep2 = createRef();
    this.socialFlowStep2SubStep1 = createRef();
    this.socialFlowStep2SubStepCamera = createRef();
    this.socialFlowStep2SubStepUpload = createRef();
    this.socialFlowStep3 = createRef();
    this.socialFlowStep4 = createRef();
    this.socialFlowStep5 = createRef();
    this.socialThumbnailRef = createRef();
    this.imgSocialRef = createRef();
    this.cameraSocialRef = createRef();
    this.cameraSocialRef2 = createRef();

    this.saveCaptureOutreachButtonRef = createRef();
    this.saveCaptureSocialButtonRef = createRef();

    this.outreachCustomMarketingTextStep3Ref = createRef();
    this.socialCustomMarketingTextStep3Ref = createRef();

    this.advertisementDialogRef = createRef();

    this.candidateReviewRatingStars1Ref = createRef();
    this.candidateReviewRatingStars2Ref = createRef();
    this.candidateReviewRatingStars3Ref = createRef();
    this.candidateReviewRatingStars4Ref = createRef();
    this.candidateReviewRatingStars5Ref = createRef();
    this.libraryItemsRef = createRef();

    this.socialVideoOptionsRef = createRef();
    this.socialVideoUploadRef = createRef();
    this.socialVideoLibraryRef = createRef();
    this.bulkMarketingVideoLibraryRef = createRef();

    this.libraryRef = createRef();
    this.librarySelectionRef = createRef();
    this.libraryVideoPreviewRef = createRef();
    this.libraryVideoPreviewVideoRef = createRef();

    this.bulkVideoOptionsRef = createRef();

    this.customCandidateThumbnailRef = createRef();
    this.customCandidateThumbnailFileRef = createRef();
    this.socialVideoCapturedRef = createRef();

    this.candidateRefDialogAnswerText = createRef();
    this.candidateRefDialogAnswerVideoControls = createRef();

    this.handleState = this.handleState.bind(this);
    this.openTabDirect = this.openTabDirect.bind(this);
    this.getColor = this.getColor.bind(this);

    this.openCustomCandidateThumbnail =
      this.openCustomCandidateThumbnail.bind(this);
    this.uploadCustomCandidateThumbnail =
      this.uploadCustomCandidateThumbnail.bind(this);

    this.updateSocialRedirectionLink =
      this.updateSocialRedirectionLink.bind(this);
    this.updateFacebook = this.updateFacebook.bind(this);

    this.libraryOnSave = this.libraryOnSave.bind(this);
    this.closeLibraryDialog = this.closeLibraryDialog.bind(this);
    this.openLibrarySelection = this.openLibrarySelection.bind(this);
    this.closeLibraryPreview = this.closeLibraryPreview.bind(this);
    this.librarySelect = this.librarySelect.bind(this);
    this.deleteLibrarySelection = this.deleteLibrarySelection.bind(this);

    this.libraryRef2 = createRef();
    this.librarySelectionRef2 = createRef();
    this.libraryVideoPreviewRef2 = createRef();
    this.libraryVideoPreviewVideoRef2 = createRef();

    this.openLibraryDialog2 = this.openLibraryDialog2.bind(this);
    this.libraryOnSave2 = this.libraryOnSave2.bind(this);
    this.closeLibraryDialog2 = this.closeLibraryDialog2.bind(this);
    this.openLibrarySelection2 = this.openLibrarySelection2.bind(this);
    this.closeLibraryPreview2 = this.closeLibraryPreview2.bind(this);
    this.librarySelect2 = this.librarySelect2.bind(this);
    this.deleteLibrarySelection2 = this.deleteLibrarySelection2.bind(this);

    this.getAdvertimementLink = this.getAdvertimementLink.bind(this);

    this.socialFlowStep2SubStep1Capture =
      this.socialFlowStep2SubStep1Capture.bind(this);
    this.socialFlowStep2SubStep1Upload =
      this.socialFlowStep2SubStep1Upload.bind(this);

    this.doneCandidateReview = this.doneCandidateReview.bind(this);

    this.startCandidateReview = this.startCandidateReview.bind(this);

    this.playQuestionVideo = this.playQuestionVideo.bind(this);
    this.pauseQuestionVideo = this.pauseQuestionVideo.bind(this);
    this.restartQuestionVideo = this.restartQuestionVideo.bind(this);
    this.nextQuestionVideo = this.nextQuestionVideo.bind(this);

    this.playAnswerVideo = this.playAnswerVideo.bind(this);
    this.pauseAnswerVideo = this.pauseAnswerVideo.bind(this);
    this.restartAnswerVideo = this.restartAnswerVideo.bind(this);
    this.nextAnswerVideo = this.nextAnswerVideo.bind(this);

    this.rateCandidate = this.rateCandidate.bind(this);

    this.loadReviewQuestion = this.loadReviewQuestion.bind(this);
    this.loadReviewAnswer = this.loadReviewAnswer.bind(this);

    this.setBulkMarketingVideo = this.setBulkMarketingVideo.bind(this);

    this.startTab2Flow = this.startTab2Flow.bind(this);

    this.openBaseThumbnailDialog = this.openBaseThumbnailDialog.bind(this);

    this.createCandidate = this.createCandidate.bind(this);
    this.updateCandidate = this.updateCandidate.bind(this);
    this.createCandidate2 = this.createCandidate2.bind(this);
    this.updateCandidate2 = this.updateCandidate2.bind(this);
    this.createCandidate3 = this.createCandidate3.bind(this);
    this.updateCandidate3 = this.updateCandidate3.bind(this);
    this.reloadTab1 = this.reloadTab1.bind(this);
    this.reloadTab2 = this.reloadTab2.bind(this);
    this.reloadTab3 = this.reloadTab3.bind(this);
    this.reloadTab4 = this.reloadTab4.bind(this);
    this.reloadAllTab = this.reloadAllTab.bind(this);

    this.uploadCandidateCV = this.uploadCandidateCV.bind(this);
    this.previewCandidateCV = this.previewCandidateCV.bind(this);
    this.deleteCandidateCV = this.deleteCandidateCV.bind(this);

    this.getProcessingTag = this.getProcessingTag.bind(this);
    this.getProcessingTag2 = this.getProcessingTag2.bind(this);

    this.resetTabs = this.resetTabs.bind(this);
    this.openTab = this.openTab.bind(this);
    this.getStars = this.getStars.bind(this);

    this.tab1Search = this.tab1Search.bind(this);
    this.tab2Search = this.tab2Search.bind(this);
    this.tab3Search = this.tab3Search.bind(this);

    this.openCandidateEdit = this.openCandidateEdit.bind(this);
    this.openCandidateEdit2 = this.openCandidateEdit2.bind(this);
    this.openCandidateCVDialog = this.openCandidateCVDialog.bind(this);
    this.openCandidateInterviewInvite =
      this.openCandidateInterviewInvite.bind(this);
    this.openCandidateAssessmentInvite =
      this.openCandidateAssessmentInvite.bind(this);
    this.openCandidateInterviewInviteOut =
      this.openCandidateInterviewInviteOut.bind(this);
    this.openCandidateDelete = this.openCandidateDelete.bind(this);

    this.openCreateCandidateDialog = this.openCreateCandidateDialog.bind(this);
    this.openCreateCandidateDialog2 =
      this.openCreateCandidateDialog2.bind(this);
    this.hideCreateCandidateDialog2 =
      this.hideCreateCandidateDialog2.bind(this);
    this.hideCreateCandidateDialog = this.hideCreateCandidateDialog.bind(this);

    this.uploadCVFile = this.uploadCVFile.bind(this);
    this.copyInvitationLink = this.copyInvitationLink.bind(this);
    this.copyInvitationLink2 = this.copyInvitationLink2.bind(this);
    this.emailInvitationLink = this.emailInvitationLink.bind(this);
    this.emailInvitationLink2 = this.emailInvitationLink2.bind(this);

    this.showBaseImageUpload = this.showBaseImageUpload.bind(this);
    this.showBaseImageCapture = this.showBaseImageCapture.bind(this);

    this.showBulkImageUpload = this.showBulkImageUpload.bind(this);
    this.showBulkImageCapture = this.showBulkImageCapture.bind(this);

    this.showSocialImageUpload = this.showSocialImageUpload.bind(this);
    this.showSocialImageCapture = this.showSocialImageCapture.bind(this);

    this.captureImage = this.captureImage.bind(this);
    this.savecaptureImage = this.savecaptureImage.bind(this);
    this.captureImageBulk = this.captureImageBulk.bind(this);
    this.savecaptureImageBulk = this.savecaptureImageBulk.bind(this);
    this.captureImageSocial = this.captureImageSocial.bind(this);
    this.savecaptureImageSocial = this.savecaptureImageSocial.bind(this);

    this.customMessageOnChange = this.customMessageOnChange.bind(this);
    this.marketingTextOnChange = this.marketingTextOnChange.bind(this);

    this.allowBespokeITJOnChange = this.allowBespokeITJOnChange.bind(this);
    this.allowATSBespokeOnChange = this.allowATSBespokeOnChange.bind(this);
    this.setATSBespokeOnChange = this.setATSBespokeOnChange.bind(this);

    this.allowATSOutreachOnChange = this.allowATSOutreachOnChange.bind(this);
    this.setATSOutreachOnChange = this.setATSOutreachOnChange.bind(this);

    this.allowBulkInterviewOnChange =
      this.allowBulkInterviewOnChange.bind(this);
    this.allowOutReachInterviewOnChange =
      this.allowOutReachInterviewOnChange.bind(this);
    this.thumbnailTextOnChange = this.thumbnailTextOnChange.bind(this);
    this.thumbnailTextOutreachOnChange =
      this.thumbnailTextOutreachOnChange.bind(this);

    this.uploadBaseThumbnailFile = this.uploadBaseThumbnailFile.bind(this);
    this.uploadBulkThumbnailFile = this.uploadBulkThumbnailFile.bind(this);
    this.uploadSocialThumbnailFile = this.uploadSocialThumbnailFile.bind(this);
    this.showTab2Actions = this.showTab2Actions.bind(this);

    this.openBulkVideoDialog = this.openBulkVideoDialog.bind(this);
    this.openBulkThumbnailDialog = this.openBulkThumbnailDialog.bind(this);

    this.openBulkVideoUploadDialog = this.openBulkVideoUploadDialog.bind(this);

    this.openBulkVideoDialogOptions =
      this.openBulkVideoDialogOptions.bind(this);

    this.openSocialThumbnailDialog = this.openSocialThumbnailDialog.bind(this);
    this.socialTextOnChange = this.socialTextOnChange.bind(this);
    this.socialCaptionOnChange = this.socialCaptionOnChange.bind(this);

    this.bulkTextOnChange = this.bulkTextOnChange.bind(this);
    this.bulkTextOnChange2 = this.bulkTextOnChange2.bind(this);

    this.openCreateCandidateDialog3 =
      this.openCreateCandidateDialog3.bind(this);

    this.startTab3Flow = this.startTab3Flow.bind(this);
    this.showTab3Actions = this.showTab3Actions.bind(this);
    this.showTab4Actions = this.showTab4Actions.bind(this);

    this.startTab4Flow = this.startTab4Flow.bind(this);

    this.openCustomVideoUpload = this.openCustomVideoUpload.bind(this);
    this.openCustomVideoUploadRe = this.openCustomVideoUploadRe.bind(this);
    this.openCustomVideoPreview = this.openCustomVideoPreview.bind(this);
    this.setCandidateCustomVideo = this.setCandidateCustomVideo.bind(this);

    this.openBespokeInvitationLinkDialog =
      this.openBespokeInvitationLinkDialog.bind(this);

    this.openSocialVideoDialog = this.openSocialVideoDialog.bind(this);
    this.openSocialVideoUploadDialog =
      this.openSocialVideoUploadDialog.bind(this);
    this.openSocialVideoDialogOptions =
      this.openSocialVideoDialogOptions.bind(this);

    this.setSocialPostVideo = this.setSocialPostVideo.bind(this);

    this.shareToFacebook = this.shareToFacebook.bind(this);
    this.shareToLinkedIn = this.shareToLinkedIn.bind(this);

    this.reviewCandidate = this.reviewCandidate.bind(this);
    this.reviewCandidateNoRating = this.reviewCandidateNoRating.bind(this);

    this.openShare = this.openShare.bind(this);
    this.openShareAndRate = this.openShareAndRate.bind(this);
    this.openComments = this.openComments.bind(this);

    this.getShareLink = this.getShareLink.bind(this);
    this.getShareAndRateLink = this.getShareAndRateLink.bind(this);
    this.openShareAndRate = this.openShareAndRate.bind(this);

    this.copyShareLink = this.copyShareLink.bind(this);
    this.emailShareLink = this.emailShareLink.bind(this);
    this.emailShareAndRateLink = this.emailShareAndRateLink.bind(this);
    this.copyShareAndRateLink = this.copyShareAndRateLink.bind(this);
    this.copyAdvertisementLink = this.copyAdvertisementLink.bind(this);

    this.openPreviewDialog = this.openPreviewDialog.bind(this);

    this.bespokeFlowStep1Next = this.bespokeFlowStep1Next.bind(this);

    this.outreachFlowStep1Next = this.outreachFlowStep1Next.bind(this);
    this.bespokseSelectImage = this.bespokseSelectImage.bind(this);

    this.bespokeSelect1StepOnChange =
      this.bespokeSelect1StepOnChange.bind(this);

    this.bespokeFlowStep4Next = this.bespokeFlowStep4Next.bind(this);
    this.bespokeFlowStep5Next = this.bespokeFlowStep5Next.bind(this);
    this.bespokeFlowStep6Next = this.bespokeFlowStep6Next.bind(this);
    this.bespokeFlowStep7Next = this.bespokeFlowStep7Next.bind(this);

    this.socialFlowStepUpload = this.socialFlowStepUpload.bind(this);

    //this.outreachVideoCaptured = this.outreachVideoCaptured.bind(this);

    this.socialVideoCaptured = this.socialVideoCaptured.bind(this);
    this.socialFlowStepCameraCapture =
      this.socialFlowStepCameraCapture.bind(this);
    this.socialFlowStepCameraSave = this.socialFlowStepCameraSave.bind(this);

    this.outreachFlowStep4Next = this.outreachFlowStep4Next.bind(this);
    this.socialFlowStep4Next = this.socialFlowStep4Next.bind(this);

    this.resetTabs = this.resetTabs.bind(this);

    this.specialLinkedInRef = createRef();
    this.specialFacebookRef = createRef();
    this.hasLinkedInAuthRef = createRef();
    this.hasFacebookAuthRef = createRef();

    this.atsBespokeLinkRef = createRef();
    this.atsOutreachLinkRef = createRef();
    this.atsRefirectionLinkRef = createRef();

    this.authLinkedIn = this.authLinkedIn.bind(this);
    this.authFacebook = this.authFacebook.bind(this);
    this.uploadMarketingVlogVideoToLinkedin =
      this.uploadMarketingVlogVideoToLinkedin.bind(this);
    this.showPages = this.showPages.bind(this);
    this.postToSelectedPages = this.postToSelectedPages.bind(this);

    this.updateLinkedIn = this.updateLinkedIn.bind(this);
    this.updateFacebook = this.updateFacebook.bind(this);

    this.filterBespokeLeadsClear = this.filterBespokeLeadsClear.bind(this);
    this.filterBespokeLeadsRecording =
      this.filterBespokeLeadsRecording.bind(this);
    this.filterBespokeLeadsNoRecording =
      this.filterBespokeLeadsNoRecording.bind(this);
    this.filterBespokeLeadsReadyToSend =
      this.filterBespokeLeadsReadyToSend.bind(this);
    this.filterBespokeLeadsViewed = this.filterBespokeLeadsViewed.bind(this);
    this.filterBespokeLeads = this.filterBespokeLeads.bind(this);

    this.filterInterviewCandidatesClear =
      this.filterInterviewCandidatesClear.bind(this);
    this.filterInterviewCandidatesRecording =
      this.filterInterviewCandidatesRecording.bind(this);
      this.filterInterviewCandidatesNoRecording =
        this.filterInterviewCandidatesNoRecording.bind(this);
    this.filterInterviewCandidatesOrderByAssessmentScore =
      this.filterInterviewCandidatesOrderByAssessmentScore.bind(this);
    this.filterInterviewCandidatesRated =
      this.filterInterviewCandidatesRated.bind(this);
    this.filterInterviewCandidatesNotRated =
      this.filterInterviewCandidatesNotRated.bind(this);
      this.filterInterviewCandidatesAssessment =
        this.filterInterviewCandidatesAssessment.bind(this);
        this.filterInterviewCandidatesNoAssessment =
          this.filterInterviewCandidatesNoAssessment.bind(this);
    this.filterInterviewLeads = this.filterInterviewLeads.bind(this);

    this.leads1SortByDefault = this.leads1SortByDefault.bind(this);
    this.leads1SortByVideoCreationDate =
      this.leads1SortByVideoCreationDate.bind(this);

    this.facebookPagesRef = createRef();

    this.filter1Ref = createRef();
    this.filter2Ref = createRef();
    this.filter3Ref = createRef();
    this.filter4Ref = createRef();

    this.filter1RefCandidate = createRef();
    this.filter2RefCandidate = createRef();
    this.filter3RefCandidate = createRef();
    this.filter4RefCandidate = createRef();
    this.filter5RefCandidate = createRef();
    this.filter6RefCandidate = createRef();
    this.filter7RefCandidate = createRef();

    this.loginFacebook = this.loginFacebook.bind(this);
    this.deletedSelectCandidatesInterview =
      this.deletedSelectCandidatesInterview.bind(this);
    this.deletedSelectCandidatesInterview2 =
      this.deletedSelectCandidatesInterview2.bind(this);
    this.deletedSelectCandidatesInterview3 =
      this.deletedSelectCandidatesInterview3.bind(this);
  }

  async leads1SortByDefault() {
    this.setState({
      ...this.state,
      loading: true,
    });
    let leads = this.state.bespoke_candidates.sort((a, b) => {
      if (this.sort_ids == false) {
        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
        return 0;
      } else {
        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
      }
    });
    this.sort_ids = !this.sort_ids;
    this.setState({
      ...this.state,
      loading: false,
      bespoke_candidates: leads,
    });
  }

  async showPages() {
    let self = this;
    if (
      !this.state.interview.advertise_marketing_video_url ||
      this.state.interview.advertise_marketing_video_url == "" ||
      this.state.interview.advertise_marketing_video_url == null
    ) {
      toast.warning(
        "Warning! Before you can share, you must please click the start button and finish the recording process",
        "warning"
      );
      return;
    }

    if (!window.FB) {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }

    const FB = window.FB;
    FB.api("/me/accounts", function (response) {
      let pages = response["data"];
      pages = pages.map((page) => {
        return {
          access_token: page.access_token,
          id: page.id,
          name: page.name,
        };
      });
      self.setState({
        ...self.state,
        pages: pages,
      });
      if (pages.length === 0) {
        window.FB.login(
          function (response) {
            if (response.authResponse) {
              window.FB.api("/me", function (response2) {
                window.location.reload();
                console.log(response2);
                return {
                  access_token: response["authResponse"]["accessToken"],
                  data_access_expiration_time:
                    response["authResponse"]["data_access_expiration_time"],
                  expires_in: response["authResponse"]["expiresIn"],
                  user_id: response["authResponse"]["userID"],
                  signed_request: response["authResponse"]["signedRequest"],
                  status: response["status"],
                };
              });
            } else {
              console.log("User cancelled login or did not fully authorize.");
            }
          },
          {
            scope:
              "email, user_link, pages_read_engagement, pages_show_list, pages_manage_posts",
            auth_type: "reauthenticate",
          }
        );
      } else {
        self.facebookPagesRef.current.style.display = "flex";
      }
    });
  }

  updateFacebook() {
    let self = this;

    if (!window.FB) {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }

    const FB = window.FB;
    FB.getLoginStatus(function (response) {
      console.log(response);
      let status = response["status"];
      if (status != "connected") {
        self.hasFacebookAuthRef.current.style.display = "block";
        self.specialFacebookRef.current.style.display = "none";
        return;
      }
      self.hasFacebookAuthRef.current.style.display = "none";
      self.specialFacebookRef.current.style.display = "inline-block";
    });
  }

  async postToSelectedPages() {
    let elements = document.querySelectorAll(".page_item > input:checked");
    if (elements.length === 0) {
      toast("Please select at least 1 Page");
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.getSocialLink(
      api_key,
      this.state.interview.id
    );
    let link = result["link"];
    if (this.state.interview.advertise_candidate_load) {
      link =
        "https://api.clipdrop.io/v1/api/signup/" +
        this.state.interview.id +
        "/" +
        this.state.interview.title.replace(" ", "");
    }

    for (let i = 0; i < elements.length; i++) {
      let title = this.state.interview.social_caption;
      let description =
        this.state.interview.advertise_marketing_text + " \n\n " + link;
      let page_id = elements[i].getAttribute("page-id");
      let page_access_token = elements[i].getAttribute("page-access-token");
      let file_url = this.state.interview.advertise_marketing_video_url;
      let response = await new Promise((resolve, reject) => {
        if (!window.FB) {
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          })(document, "script", "facebook-jssdk");
        }

        const FB = window.FB;
        FB.api(
          "/" + page_id + "/videos",
          "POST",
          {
            title: title,
            description: description,
            file_url: file_url,
            access_token: page_access_token,
          },
          function (response) {
            resolve(response);
          }
        );
      });
      console.log(response);
    }
    this.facebookPagesRef.current.style.display = "none";
    this.setState({
      ...this.state,
      loading: false,
    });
    toast("Posted to Page(s) successfully");
    this.showFacebookShareNoticeDialogRef.current.style.display = "flex";
  }

  loginFacebook() {
    let self = this;

    if (!window.FB) {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }

    const FB = window.FB;
    FB.init({
      appId: "1037968913418686",
      cookie: true,
      xfbml: true,
      version: "v13.0",
    });
    FB.AppEvents.logPageView();
    // check login response.status
    FB.login(
      function (response) {
        if (response.status === "connected") {
          // Logged into your webpage and Facebook.
          console.log("LOGGED IN");
          self.hasFacebookAuthRef.current.style.display = "none";
          self.specialFacebookRef.current.style.display = "block";
          self.showPages();
        } else {
          console.log("COULD NOT LOGIN");
          self.hasFacebookAuthRef.current.style.display = "block";
          self.specialFacebookRef.current.style.display = "none";
        }
      },
      {
        scope:
          "public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts",
      }
    );
  }

  async leads1SortByVideoCreationDate() {
    this.setState({
      ...this.state,
      loading: true,
    });
    let leads = this.state.bespoke_candidates.sort((a, b) => {
      if (this.sort_ids == false) {
        if (a.created_at > b.created_at) return 1;
        if (a.created_at < b.created_at) return -1;
        return 0;
      } else {
        if (a.created_at > b.created_at) return -1;
        if (a.created_at < b.created_at) return 1;
        return 0;
      }
    });
    this.sort_ids = !this.sort_ids;
    this.setState({
      ...this.state,
      loading: false,
      bespoke_candidates: leads,
    });
  }

  async filterBespokeLeadsClear() {
    this.filter_recording = false;
    this.filter_norecording = false;
    this.filter_ready_to_send = false;
    this.filter1Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter1Ref.current.style.color = "#000000";
    this.filter3Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter3Ref.current.style.color = "#000000";
    this.filter4Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter4Ref.current.style.color = "#000000";
    this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter2Ref.current.style.color = "#000000";

    this.filterBespokeLeads();
  }

  async filterBespokeLeadsRecording(e) {
    this.filter_recording = !this.filter_recording;
    e.target.style.backgroundColor = this.filter_recording
      ? "#1976D1"
      : "#FFFFFF";
    e.target.style.color = this.filter_recording ? "#FFFFFF" : "#000000";
    if (this.filter_recording) {
      this.filter_norecording = false;
      this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
      this.filter2Ref.current.style.color = "#000000";
    }
    this.filterBespokeLeads();
  }

  async filterBespokeLeadsNoRecording(e) {
    this.filter_norecording = !this.filter_norecording;
    e.target.style.backgroundColor = this.filter_norecording
      ? "#1976D1"
      : "#FFFFFF";
    e.target.style.color = this.filter_norecording ? "#FFFFFF" : "#000000";

    this.filter_recording = false;
    this.filter_ready_to_send = false;
    this.filter_viewed = false;
    this.filter1Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter1Ref.current.style.color = "#000000";
    this.filter3Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter3Ref.current.style.color = "#000000";
    this.filter4Ref.current.style.backgroundColor = "#FFFFFF";
    this.filter4Ref.current.style.color = "#000000";
    this.filterBespokeLeads();
  }

  async filterBespokeLeadsReadyToSend(e) {
    this.filter_ready_to_send = !this.filter_ready_to_send;
    e.target.style.backgroundColor = this.filter_ready_to_send
      ? "#1976D1"
      : "#FFFFFF";
    e.target.style.color = this.filter_ready_to_send ? "#FFFFFF" : "#000000";
    if (this.filter_ready_to_send) {
      this.filter_norecording = false;
      this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
      this.filter2Ref.current.style.color = "#000000";
    }

    this.filterBespokeLeads();
  }

  async filterBespokeLeadsViewed(e) {
    this.filter_viewed = !this.filter_viewed;
    e.target.style.backgroundColor = this.filter_viewed ? "#1976D1" : "#FFFFFF";
    e.target.style.color = this.filter_viewed ? "#FFFFFF" : "#000000";

    if (this.filter_viewed) {
      this.filter_norecording = false;
      this.filter2Ref.current.style.backgroundColor = "#FFFFFF";
      this.filter2Ref.current.style.color = "#000000";
    }
    this.filterBespokeLeads();
  }

  async filterBespokeLeads() {
    this.setState({
      ...this.state,
      loading: true,
    });
    let a = this.filter_recording;
    let b = this.filter_ready_to_send;
    let c = this.filter_viewed;
    let d = this.filter_norecording;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getBespoke(
      api_key,
      this.state.interview.id
    );
    let candidates = result["candidates"];
    let filteredCandidates = candidates.filter((candidate) => {
      let has_video =
        candidate.custom_video_url != "" && candidate.custom_video_url != null;
      let ready_to_send =
        candidate.processing_done == 1 &&
        candidate.emailed == 0 &&
        candidate.copied == 0;
      let viewed = candidate.hasViewed;
      ///////////
      // singles
      ///////////
      // if no filters active
      if (a == false && b == false && c == false && d == false) return true;
      // is has video
      else if (a && has_video && b == false && c == false && d == false)
        return true;
      // is ready to send
      else if (a == false && b && ready_to_send && c == false && d == false)
        return true;
      // has been viewed
      else if (a == false && b == false && c && viewed && d == false)
        return true;
      // has no video
      else if (a == false && b == false && c == false && d && !has_video)
        return true;
      ///////////
      // multiple
      ///////////
      // has video and can be viewed
      else if (a && has_video && b == false && c && viewed && d == false)
        return true;
      // has video and can be viewed and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        c &&
        viewed &&
        d == false
      )
        return true;
      // has video and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        viewed == false &&
        c == false &&
        d == false
      )
        return true;
      // ready to send and viewed
      else if (a == false && b && ready_to_send && c && viewed && d == false)
        return true;
      // no matches
      return false;
    });
    console.log(filteredCandidates);
    this.setState({
      ...this.state,
      bespoke_candidates: filteredCandidates,
      loading: false,
    });
  }
  /////////////////////////
  /////////////////////////

  async filterInterviewCandidatesClear() {
    this.filter1RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter1RefCandidate.current.style.color = "#000000";
    this.filter3RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter3RefCandidate.current.style.color = "#000000";
    this.filter4RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter4RefCandidate.current.style.color = "#000000";
    this.filter5RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter5RefCandidate.current.style.color = "#000000";
    this.filter2RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter2RefCandidate.current.style.color = "#000000";
    this.filter5RefCandidate.current.style.color = "#000000";
    this.filter6RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter6RefCandidate.current.style.color = "#000000";
    this.filter7RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter7RefCandidate.current.style.color = "#000000";
    let api_key = localStorage.getItem("api_key") ?? "";
    this.filter_rated = false;
    this.filter_not_rated = false;
    this.filter_recording_candidate = false;
    this.filter_norecording_candidate = false;
    this.filter_assessment_candidate = false;
    this.filter_no_assessment_candidate = false;
    this.filter_order_by_assessment_score = false;
    let result = await API.candidate.getSearch(api_key, this.state.interview.id, "", 0, this.filter_rated, this.filter_not_rated, this.filter_recording_candidate, this.filter_norecording_candidate, this.filter_assessment_candidate, this.filter_no_assessment_candidate, this.filter_order_by_assessment_score);
    let candidates = result["candidates"];
    let totalPages = result["totalPages"];
    this.setState({
      ...this.state,
      normal_candidate_search: "",
      normal_candidates_current_page: 0,
      normal_candidates_total_pages: totalPages,
      normal_candidates: candidates
    });
  }

  async filterInterviewCandidatesRecording(e) {
    this.filter_recording_candidate = !this.filter_recording_candidate;
    this.filter_norecording_candidate = false;
    e.target.style.backgroundColor = this.filter_recording_candidate
      ? "#1976D1"
      : "#FFFFFF";
    e.target.style.color = this.filter_recording_candidate
      ? "#FFFFFF"
      : "#000000";

    this.filter2RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter2RefCandidate.current.style.color = "#000000";
    this.filterInterviewLeads();
  }

  async filterInterviewCandidatesNoRecording(e) {
    this.filter_norecording_candidate = !this.filter_norecording_candidate;
    e.target.style.backgroundColor = this.filter_norecording_candidate
      ? "#1976D1"
      : "#FFFFFF";
    e.target.style.color = this.filter_norecording_candidate
      ? "#FFFFFF"
      : "#000000";

    this.filter_rated = false;
    this.filter_not_rated = false;
    this.filter_recording_candidate = false;

    this.filter1RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter1RefCandidate.current.style.color = "#000000";
    this.filter3RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter3RefCandidate.current.style.color = "#000000";
    this.filter4RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter4RefCandidate.current.style.color = "#000000";
    this.filter5RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter5RefCandidate.current.style.color = "#000000";
    this.filter6RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter6RefCandidate.current.style.color = "#000000";

    this.filterInterviewLeads();
  }

  async filterInterviewCandidatesOrderByAssessmentScore(e) {
    this.filter_order_by_assessment_score = !this.filter_order_by_assessment_score;
    this.filter7RefCandidate.current.style.backgroundColor = this.filter_order_by_assessment_score ? "#1976D1" : "#FFFFFF";
    this.filter7RefCandidate.current.style.color = this.filter_order_by_assessment_score ? "#FFFFFF" : "#000000";
    this.filterInterviewLeads();
  }

  async filterInterviewCandidatesRated(e) {
    this.filter_norecording_candidate = false;
    this.filter_rated = !this.filter_rated;
    e.target.style.backgroundColor = this.filter_rated ? "#1976D1" : "#FFFFFF";
    e.target.style.color = this.filter_rated ? "#FFFFFF" : "#000000";
    if (this.filter_rated) {
      this.filter_not_rated = false;
      this.filter4RefCandidate.current.style.backgroundColor = "#FFFFFF";
      this.filter4RefCandidate.current.style.color = "#000000";
    }

    this.filterInterviewLeads();
  }

  async filterInterviewCandidatesNotRated(e) {
    this.filter_not_rated = !this.filter_not_rated;
    this.filter_norecording_candidate = false;
    e.target.style.backgroundColor = this.filter_not_rated
      ? "#1976D1"
      : "#FFFFFF";
    e.target.style.color = this.filter_not_rated ? "#FFFFFF" : "#000000";

    if (this.filter_not_rated) {
      this.filter_rated = false;
      this.filter3RefCandidate.current.style.backgroundColor = "#FFFFFF";
      this.filter3RefCandidate.current.style.color = "#000000";
    }

    this.filterInterviewLeads();
  }

  async filterInterviewCandidatesAssessment(e){
    this.filter_assessment_candidate = !this.filter_assessment_candidate;
    this.filter5RefCandidate.current.style.backgroundColor = this.filter_assessment_candidate
      ? "#1976D1"
      : "#FFFFFF";
      this.filter5RefCandidate.current.style.color = this.filter_assessment_candidate ? "#FFFFFF" : "#000000";
      if(this.filter_assessment_candidate){
        this.filter_no_assessment_candidate = false;
        this.filter6RefCandidate.current.style.backgroundColor = this.filter_no_assessment_candidate
          ? "#1976D1"
          : "#FFFFFF";
          this.filter6RefCandidate.current.style.color = this.filter_no_assessment_candidate ? "#FFFFFF" : "#000000";
      }
    this.filterInterviewLeads();
  }

  async filterInterviewCandidatesNoAssessment(e){
    this.filter_no_assessment_candidate = !this.filter_no_assessment_candidate;
    this.filter6RefCandidate.current.style.backgroundColor = this.filter_no_assessment_candidate
      ? "#1976D1"
      : "#FFFFFF";
      this.filter6RefCandidate.current.style.color = this.filter_no_assessment_candidate ? "#FFFFFF" : "#000000";
      if(this.filter_no_assessment_candidate){
        this.filter_assessment_candidate = false;
        this.filter5RefCandidate.current.style.backgroundColor = this.filter_assessment_candidate
          ? "#1976D1"
          : "#FFFFFF";
          this.filter5RefCandidate.current.style.color = this.filter_assessment_candidate ? "#FFFFFF" : "#000000";
      }
    this.filterInterviewLeads();
  }


  // FIX: Filtering Issue
  async filterInterviewLeads() {
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key") ?? "";
    let result = await API.candidate.getSearch(api_key, this.state.interview.id, this.state.normal_candidate_search,0, this.filter_rated, this.filter_not_rated, this.filter_recording_candidate, this.filter_norecording_candidate, this.filter_assessment_candidate, this.filter_no_assessment_candidate, this.filter_order_by_assessment_score);
    let candidates = result["candidates"];
    let totalPages = result["totalPages"];
    this.setState({
      ...this.state,
    });

    this.setState({
      ...this.state,
      loading: false,
      normal_candidates_current_page: 0,
      normal_candidates_total_pages: totalPages,
      normal_candidates: candidates
    });
  }

  ////////////////////////
  ///////////////////////

  async componentDidMount() {
    this.showFacebookShareNoticeDialogRef.current.style.display = "none";
    this.showAssessmentDialog.current.style.display = "none";
    this.librarySelectionRef.current.style.display = "none";
    this.libraryVideoPreviewRef.current.style.display = "none";
    this.librarySelectionRef2.current.style.display = "none";
    this.libraryVideoPreviewRef2.current.style.display = "none";
    this.socialVideoOptionsRef.current.style.display = "none";
    this.socialVideoUploadRef.current.style.display = "none";
    this.bulkVideoOptionsRef.current.style.display = "none";
    this.customCandidateThumbnailRef.current.style.display = "none";

    this.bulkLinkShareDialogRef.current.style.display = "none";
    this.bulkLinkShareRateDialogRef.current.style.display = "none";
    this.createCandidateDialogRef.current.style.display = "none";
    this.updateCandidateDialogRef.current.style.display = "none";
    this.createCandidateDialogRef2.current.style.display = "none";
    this.updateCandidateDialogRef2.current.style.display = "none";
    this.createCandidateDialogRef3.current.style.display = "none";
    this.updateCandidateDialogRef3.current.style.display = "none";
    this.cvDialogRef.current.style.display = "none";
    this.cvPreviewDialogRef.current.style.display = "none";
    this.cvUploadDialogRef.current.style.display = "none";
    this.candidateInviteLinkDialogRef.current.style.display = "none";
    this.candidateInviteLinkDialogRef2.current.style.display = "none";
    this.baseThumbnailDialog.current.style.display = "none";
    this.baseThumbnailDialogUpload.current.style.display = "none";
    this.baseThumbnailDialogCapture.current.style.display = "none";
    this.bulkThumbnailDialogUpload.current.style.display = "none";
    this.bulkThumbnailDialog.current.style.display = "none";
    this.bulkThumbnailDialogCapture.current.style.display = "none";
    this.socialThumbnailDialog.current.style.display = "none";
    this.socialThumbnailDialogUpload.current.style.display = "none";
    this.socialThumbnailDialogCapture.current.style.display = "none";
    this.customCandidateVideoRef.current.style.display = "none";
    this.customCandidateVideoPreviewRef.current.style.display = "none";
    this.candidateMarketingInviationLink.current.style.display = "none";
    this.bulkVideoDialogRef.current.style.display = "none";
    this.bulkVideoUploadDialogRef.current.style.display = "none";
    this.socialVideoRef.current.style.display = "none";

    this.candidateRefDialog.current.style.display = "none";
    this.advertisementDialogRef.current.style.display = "none";

    this.candidateRefDialogNotice.current.style.display = "none";
    this.candidateRefDialogQuestion.current.style.display = "none";
    this.candidateRefDialogAnswer.current.style.display = "none";
    this.candidateRefDialogRating.current.style.display = "none";
    this.candidateRefDialogComplete.current.style.display = "none";

    this.shareDialogRef.current.style.display = "none";
    this.shareAndRateDialogRef.current.style.display = "none";

    this.librarySelectionRef.current.style.display = "none";
    this.libraryVideoPreviewRef.current.style.display = "none";
    this.socialVideoOptionsRef.current.style.display = "none";
    this.socialVideoUploadRef.current.style.display = "none";

    this.bulkVideoOptionsRef.current.style.display = "none";
    this.librarySelectionRef2.current.style.display = "none";
    this.libraryVideoPreviewRef2.current.style.display = "none";
    this.bulkVideoOptionsRef.current.style.display = "none";

    this.facebookPagesRef.current.style.display = "none";

    this.candidateCommentsPreviewDialogRef.current.style.display = "none";
    this.tab2Actions.current.style.display = "none";
    this.tab3Actions.current.style.display = "none";
    this.candidateCommentsDialogRef.current.style.display = "none";
    this.bespokeFlowDialogRef.current.style.display = "none";

    this.outreachFlowDialogRef.current.style.display = "none";
    this.outreachFlowStep3.current.style.display = "none";
    this.outreachFlowStep3Option.current.style.display = "none";
    this.outreachFlowStep3Upload.current.style.display = "none";
    this.outreachFlowStep4.current.style.display = "none";
    this.outreachFlowStep5.current.style.display = "none";
    this.outreachFlowStep6.current.style.display = "none";

    this.socialFlowDialogRef.current.style.display = "none";
    this.socialFlowStep2.current.style.display = "none";
    this.socialFlowStep2SubStepCamera.current.style.display = "none";
    this.socialFlowStep2SubStepUpload.current.style.display = "none";
    this.socialFlowStep3.current.style.display = "none";
    this.socialFlowStep4.current.style.display = "none";
    this.socialFlowStep5.current.style.display = "none";

    this.allAnwserLinks.current.style.display = "none";
    this.bulkLinkEmailDialogRef.current.style.display = "none";

    this.candidate_pulling = setInterval(() => {
      this.reloadAllTab();
    }, 20000);

    this.resetTabs();
    this.tab1.current.style.display = "block";
    this.tab1btn.current.classList.add("active");
    this.tabHolder.current.style.display = "block";

    this.tab2Actions.current.style.display = "none";
    this.tab3Actions.current.style.display = "none";
    this.tab4Actions.current.style.display = "none";
    let self = this;
    $(document).on("click", ".playCandidateVideo", async (e) => {
      let c_id = $(e.target).data("candidate-id");
      let q_id = $(e.target).data("question-id");
      let api_key = localStorage.getItem("api_key");
      let result = await API.answer.get(api_key, c_id, q_id);
      let v_url = result["video_link"];
      console.log(v_url);
      self.openPreviewDialog(v_url);
    });

    $(this.libraryRef.current).hide();

    let api_key = localStorage.getItem("api_key");

    let result = await API.candidate.getSearch(api_key, this.state.interview.id, this.state.normal_candidate_search,this.state.normal_candidates_current_page, this.filter_rated, this.filter_not_rated, this.filter_recording_candidate, this.filter_norecording_candidate, this.filter_assessment_candidate, this.filter_no_assessment_candidate, this.filter_order_by_assessment_score);
    let normal_candidates = result["candidates"];
    let normal_totalPages = result['totalPages'];    

    result = await API.candidate.getBespoke(api_key, this.state.interview.id, this.state.bespoke_candidates_current_page);
    let bespoke_candidates = result["candidates"];
    let bespoke_totalPages = result['totalPages'];
    let filtered_bespoke_candidates = bespoke_candidates.filter((candidate) => {
      let a = this.filter_recording;
      let b = this.filter_ready_to_send;
      let c = this.filter_viewed;
      let d = this.filter_norecording;
      let has_video =
        candidate.custom_video_url != "" && candidate.custom_video_url != null;
      let ready_to_send =
        candidate.processing_done == 1 &&
        candidate.emailed == 0 &&
        candidate.copied == 0;
      let viewed = candidate.hasViewed;
      ///////////
      // singles
      ///////////
      // if no filters active
      if (a == false && b == false && c == false && d == false) return true;
      // is has video
      else if (a && has_video && b == false && c == false && d == false)
        return true;
      // is ready to send
      else if (a == false && b && ready_to_send && c == false && d == false)
        return true;
      // has been viewed
      else if (a == false && b == false && c && viewed && d == false)
        return true;
      // has no video
      else if (a == false && b == false && c == false && d && !has_video)
        return true;
      ///////////
      // multiple
      ///////////
      // has video and can be viewed
      else if (a && has_video && b == false && c && viewed && d == false)
        return true;
      // has video and can be viewed and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        c &&
        viewed &&
        d == false
      )
        return true;
      // has video and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        viewed == false &&
        c == false &&
        d == false
      )
        return true;
      // ready to send and viewed
      else if (a == false && b && ready_to_send && c && viewed && d == false)
        return true;
      // no matches
      return false;
    });
    console.log(filtered_bespoke_candidates);
    result = await API.candidate.getOutReach(api_key, this.state.interview.id, this.state.outreach_candidates_current_page);
    let outreach_candidates = result["candidates"];
    let outreach_totalPages = result['totalPages'];

    result = await API.questions.get(api_key, this.state.interview.id);
    let questions = result["questions"];

    this.setState({
      ...this.state,
      normal_candidates: normal_candidates,
      bespoke_candidates: filtered_bespoke_candidates,
      outreach_candidates: outreach_candidates,
      isLoaded: true,
      questions: questions,
      normal_candidates_current_page: 0,
      normal_candidates_total_pages:normal_totalPages,
      bespoke_candidates_total_pages: bespoke_totalPages,
      bespoke_candidates_current_page: 0,
      outreach_candidates_total_pages: outreach_totalPages,
      outreach_candidates_current_page: 0
    });

    this.updateLinkedIn();
    this.updateFacebook();

    // load state
    this.handleState();

    // at bespoke link verifier
    this.atsBespokeLinkRef.current.innerHTML = this.state.interview
      .ats_bespoke_url
      ? API.validation.verifyURL(this.state.interview.ats_bespoke_url)
        ? "<div style='font-size:9pt; color: green'>Valid URL</div>"
        : "<div style='font-size:9pt; color: red'>Invalid URL</div>"
      : "";
    this.atsOutreachLinkRef.current.innerHTML = this.state.interview
      .ats_outreach_url
      ? API.validation.verifyURL(this.state.interview.ats_outreach_url)
        ? "<div style='font-size:9pt; color: green'>Valid URL</div>"
        : "<div style='font-size:9pt; color: red'>Invalid URL</div>"
      : "";
    this.atsRefirectionLinkRef.current.innerHTML = this.state.interview
      .advertise_marketing_redirect_url
      ? API.validation.verifyURL(
          this.state.interview.advertise_marketing_redirect_url
        )
        ? "<div style='font-size:9pt; color: green'>Valid URL</div>"
        : "<div style='font-size:9pt; color: red'>Invalid URL</div>"
      : "";
  }

  updateLinkedIn() {
    let isNotExpired =
      new Date().getTime() -
        new Date(
          this.state.user.linkedin_user_access_token_expiry_date
        ).getTime() <
      0;
    if (
      this.state.user.linkedin_user_access_token &&
      this.state.user.linkedin_user_id &&
      isNotExpired
    ) {
      this.hasLinkedInAuthRef.current.style.display = "none";
      this.specialLinkedInRef.current.style.display = "inline-block";
    } else {
      this.hasLinkedInAuthRef.current.style.display = "block";
      this.specialLinkedInRef.current.style.display = "none";
    }
  }

  handleState() {
    let url = window.location.href;
    if (url.indexOf("#interview") !== -1) {
      this.openTabDirect(1);
    } else if (url.indexOf("#marketing") !== -1) {
      this.openTabDirect(2);
    } else if (url.indexOf("#bulkmarketing") !== -1) {
      this.openTabDirect(3);
    } else if (url.indexOf("#social") !== -1) {
      this.openTabDirect(4);
    }
  }

  openPreviewDialog(v_url) {
    this.candidateCommentsPreviewDialogRef.current.style.display = "flex";
    this.candidateCommentsPreviewDialogVideoRef.current.src = v_url;
    this.candidateCommentsPreviewDialogVideoRef.current.play();
  }
  resetTabs() {
    this.tab1.current.style.display = "none";
    this.tab2.current.style.display = "none";
    this.tab3.current.style.display = "none";
    this.tab4.current.style.display = "none";

    this.tab1btn.current.classList.remove("active");
    this.tab2btn.current.classList.remove("active");
    this.tab3btn.current.classList.remove("active");
    this.tab4btn.current.classList.remove("active");
  }

  async libraryOnSave(title, video_url) {
    let api_key = localStorage.getItem("api_key");
    let result = await API.library.add(api_key, 4, title, video_url);
    let success = result["success"];
    if (success) {
      toast("Video Added To Library");
      this.librarySelectionRef.current.style.display = "none";
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      toast("Error Adding Video To Library");
    }
  }
  async openLibrarySelection(e) {
    let lib_id = parseInt(e.target.getAttribute("library-id"));
    this.setState({
      ...this.state,
      lid: lib_id,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.library.get(api_key, lib_id);
    let success = result["success"];
    if (success) {
      toast("Library Video Loaded");
      this.libraryVideoPreviewRef.current.style.display = "flex";
      this.libraryVideoPreviewVideoRef.current.src = result["video_url"];
      this.librarySelectionRef2.current.style.display = "none";
      this.socialVideoOptionsRef.current.style.display = "none";
    } else {
      toast("Error Loading Selected Library Video");
    }
  }

  async librarySelect() {
    let api_key = localStorage.getItem("api_key");
    this.libraryVideoPreviewVideoRef.current.pause();
    let video_url = this.libraryVideoPreviewVideoRef.current.src;
    let result = await API.interview.setSocialMarketingVideo(
      api_key,
      this.state.interview.id,
      video_url
    );
    let success = result["success"];
    if (success) {
      toast("Video Selection Saved");
      this.librarySelectionRef.current.style.display = "none";
      this.libraryVideoPreviewRef.current.style.display = "none";
      this.reloadAllTab();
    } else {
      toast("Error Selecting Video");
    }
  }

  async deleteLibrarySelection() {
    let api_key = localStorage.getItem("api_key");
    let id = this.state.lid;
    let result = await API.library.delete(api_key, id);
    let success = result["success"];
    if (success) {
      toast("Video Deleted from Library");
      this.libraryVideoPreviewRef.current.style.display = "none";
      this.openLibraryDialog();
    } else {
      toast("Error deleting video from library");
    }
  }

  closeLibraryPreview() {
    this.libraryVideoPreviewVideoRef.current.pause();
    this.libraryVideoPreviewRef.current.style.display = "none";
  }

  ///////

  async libraryOnSave2(title, video_url) {
    let api_key = localStorage.getItem("api_key");
    let result = await API.library.add(api_key, 4, title, video_url);
    let success = result["success"];
    if (success) {
      toast("Video Added To Library");
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      toast("Error Adding Video To Library");
    }
  }
  async openLibrarySelection2(e) {
    let lib_id = parseInt(e.target.getAttribute("library-id"));
    this.setState({
      ...this.state,
      lid: lib_id,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.library.get(api_key, lib_id);
    let success = result["success"];
    if (success) {
      toast("Library Video Loaded");
      this.libraryVideoPreviewRef2.current.style.display = "flex";
      this.libraryVideoPreviewVideoRef2.current.src = result["video_url"];
      this.librarySelectionRef.current.style.display = "none";
      this.bulkVideoOptionsRef.current.style.display = "none";
    } else {
      toast("Error Loading Selected Library Video");
    }
  }

  async librarySelect2() {
    let api_key = localStorage.getItem("api_key");
    this.libraryVideoPreviewVideoRef2.current.pause();
    let video_url = this.libraryVideoPreviewVideoRef2.current.src;
    let result = await API.interview.setBulkMarketingVideo(
      api_key,
      this.state.interview.id,
      video_url
    );
    let success = result["success"];
    result = await API.interview.markVideo(0, 0, this.state.interview.id, 2);
    this.librarySelectionRef.current.style.display = "none";
    this.libraryVideoPreviewRef2.current.style.display = "none";
    toast("Video Selection Saved");
    this.reloadAllTab();
  }

  async deleteLibrarySelection2() {
    let api_key = localStorage.getItem("api_key");
    let id = this.state.lid;
    let result = await API.library.delete(api_key, id);
    let success = result["success"];
    toast("Video Deleted from Library");
    this.libraryVideoPreviewRef2.current.style.display = "none";
    this.openLibraryDialog();
  }

  closeLibraryPreview2() {
    this.libraryVideoPreviewVideoRef2.current.pause();
    this.libraryVideoPreviewRef2.current.style.display = "none";
  }

  addStateToURL(state) {
    window.history.replaceState(
      null,
      "",
      window.location.pathname + "?#" + state
    );
  }

  openTab(e) {
    this.resetTabs();
    let tabID = parseInt(e.target.getAttribute("tab-id"));
    if (tabID == 1) {
      this.tab1.current.style.display = "block";
      this.tab1btn.current.classList.add("active");
      this.addStateToURL("interview");
    } else if (tabID == 2) {
      this.tab2.current.style.display = "block";
      this.tab2btn.current.classList.add("active");
      this.addStateToURL("marketing");
    } else if (tabID == 3) {
      this.tab3.current.style.display = "block";
      this.tab3btn.current.classList.add("active");
      this.addStateToURL("bulkmarketing");
    } else if (tabID == 4) {
      this.tab4.current.style.display = "block";
      this.tab4btn.current.classList.add("active");
      this.addStateToURL("social");
    }
  }

  openTabDirect(tabID) {
    this.resetTabs();
    if (tabID == 1) {
      this.tab1.current.style.display = "block";
      this.tab1btn.current.classList.add("active");
      this.addStateToURL("interview");
    } else if (tabID == 2) {
      this.tab2.current.style.display = "block";
      this.tab2btn.current.classList.add("active");
      this.addStateToURL("marketing");
    } else if (tabID == 3) {
      this.tab3.current.style.display = "block";
      this.tab3btn.current.classList.add("active");
      this.addStateToURL("bulkmarketing");
    } else if (tabID == 4) {
      this.tab4.current.style.display = "block";
      this.tab4btn.current.classList.add("active");
      this.addStateToURL("social");
    }
  }

  getStars(count) {
    let styles = {
      "--w": ((count / 5) * 100).toString() + "%",
    };
    return <div className="stars2" style={styles}></div>;
    /*
        let grey_star = <span className="star grey_star">★</span>
        let yello_star = <span className="star yellow_star">★</span>;
        let ret = [];
        for(let i = 0; i < 5; i++){
          ret.push((i<count) ? yello_star : grey_star);
        }
        return ret;
      */
  }

  async tab1Search() {
    let q = this.tab1SearchField.current.value;
    if (q == "") {
      clearInterval(this.candidate_pulling);
      this.candidate_pulling = setInterval(() => {
        this.reloadAllTab();
      }, 20000);
    } else {
      clearInterval(this.candidate_pulling);
    }
    
    this.filter_recording_candidate = false;
    this.filter_norecording_candidate = false;
    this.filter_rated = false;
    this.filter_not_rated = false;
    this.filter1RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter1RefCandidate.current.style.color = "#000000";
    this.filter3RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter3RefCandidate.current.style.color = "#000000";
    this.filter4RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter4RefCandidate.current.style.color = "#000000";
    this.filter2RefCandidate.current.style.backgroundColor = "#FFFFFF";
    this.filter2RefCandidate.current.style.color = "#000000";
    
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getSearch(
      api_key,
      this.state.interview.id,
      q,
      0,
      this.state.normal_candidates_current_page,
      0,
      0,
      0,
      0,
      0
    );
    let normal_candidates = result["candidates"];
    this.setState({
      ...this.state,
      normal_candidates: normal_candidates,
      normal_candidates_current_page: 0,
      normal_candidate_search: q
    });
  }
  async tab2Search() {
    let q = this.tab2SearchField.current.value;

    if (q == "") {
      clearInterval(this.candidate_pulling);
      this.candidate_pulling = setInterval(() => {
        this.reloadAllTab();
      }, 20000);
    } else {
      clearInterval(this.candidate_pulling);
    }
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getBespokeSearch(
      api_key,
      this.state.interview.id,
      q,
      this.state.bespoke_candidates_current_page
    );
    let bespoke_candidates = result["candidates"];

    let filteredCandidates = bespoke_candidates.filter((candidate) => {
      let a = this.filter_recording;
      let b = this.filter_ready_to_send;
      let c = this.filter_viewed;
      let d = this.filter_norecording;
      let has_video =
        candidate.custom_video_url != "" && candidate.custom_video_url != null;
      let ready_to_send =
        candidate.processing_done == 1 &&
        candidate.emailed == 0 &&
        candidate.copied == 0;
      let viewed = candidate.hasViewed;
      ///////////
      // singles
      ///////////
      // if no filters active
      if (a == false && b == false && c == false && d == false) return true;
      // is has video
      else if (a && has_video && b == false && c == false && d == false)
        return true;
      // is ready to send
      else if (a == false && b && ready_to_send && c == false && d == false)
        return true;
      // has been viewed
      else if (a == false && b == false && c && viewed && d == false)
        return true;
      // has no video
      else if (a == false && b == false && c == false && d && !has_video)
        return true;
      ///////////
      // multiple
      ///////////
      // has video and can be viewed
      else if (a && has_video && b == false && c && viewed && d == false)
        return true;
      // has video and can be viewed and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        c &&
        viewed &&
        d == false
      )
        return true;
      // has video and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        viewed == false &&
        c == false &&
        d == false
      )
        return true;
      // ready to send and viewed
      else if (a == false && b && ready_to_send && c && viewed && d == false)
        return true;
      // no matches
      return false;
    });
    console.log(filteredCandidates);
    this.setState({
      ...this.state,
      bespoke_candidates: filteredCandidates,
    });
  }
  async tab3Search() {
    let q = this.tab3SearchField.current.value;

    if (q == "") {
      clearInterval(this.candidate_pulling);
      this.candidate_pulling = setInterval(() => {
        this.reloadAllTab();
      }, 20000);
    } else {
      clearInterval(this.candidate_pulling);
    }
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getOutReachSearch(
      api_key,
      this.state.interview.id,
      q,
      this.state.outreach_candidates_current_page
    );
    let outreach_candidates = result["candidates"];
    this.setState({
      ...this.state,
      outreach_candidates: outreach_candidates,
    });
  }

  async openCandidateEdit(e) {
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.get(api_key, cid);
    let candidate = result["candidate"];
    this.updateCandidateDialogRef.current.style.display = "flex";
    this.updateCandidateDialogRef.current.scrollIntoView();
    this.updateCandidateDialogIDRef.current.value = candidate["id"];
    this.updateCandidateDialogNameRef.current.value = candidate["name"];
    this.updateCandidateDialogEmailRef.current.value = candidate["email"];
    this.updateCandidateDialogLinkedInRef.current.value =
      candidate["linkedin_url"];
    this.updateCandidateDialogPhoneRef.current.value = candidate["phone"];
  }

  async openCandidateEdit2(e) {
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.get(api_key, cid);
    let candidate = result["candidate"];
    this.updateCandidateDialogRef2.current.style.display = "flex";
    this.updateCandidateDialogRef2.current.scrollIntoView();
    this.updateCandidateDialogIDRef2.current.value = candidate["id"];
    this.updateCandidateDialogNameRef2.current.value = candidate["name"];
    this.updateCandidateDialogEmailRef2.current.value = candidate["email"];
    this.updateCandidateDialogLinkedInRef2.current.value =
      candidate["linkedin_url"];
    this.updateCandidateDialogPhoneRef2.current.value = candidate["phone"];
  }

  async updateCandidate() {
    let cid = parseInt(this.updateCandidateDialogIDRef.current.value);
    let name = this.updateCandidateDialogNameRef.current.value;
    let email = this.updateCandidateDialogEmailRef.current.value;
    let linkedin = this.updateCandidateDialogLinkedInRef.current.value;
    let phone = this.updateCandidateDialogPhoneRef.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.update(
      api_key,
      cid,
      name,
      email,
      linkedin,
      phone
    );
    let success = result["success"];
    if (success) {
      this.reloadAllTab();
      this.updateCandidateDialogRef.current.style.display = "none";
      toast("Candidate has been updated");
    } else {
      toast("Error updating candidate");
    }
  }

  getColor(percentage){
      if(percentage >= 75){
        return "green";
      } else if(percentage >= 50){
        return "orange";
      } else {
        return "red";
      }
  }

  async updateCandidate2() {
    let cid = parseInt(this.updateCandidateDialogIDRef2.current.value);
    let name = this.updateCandidateDialogNameRef2.current.value;
    let email = this.updateCandidateDialogEmailRef2.current.value;
    let linkedin = this.updateCandidateDialogLinkedInRef2.current.value;
    let phone = this.updateCandidateDialogPhoneRef2.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.update(
      api_key,
      cid,
      name,
      email,
      linkedin,
      phone
    );
    let success = result["success"];
    if (success) {
      this.reloadAllTab();
      this.updateCandidateDialogRef2.current.style.display = "none";
      toast("Candidate has been updated");
    } else {
      toast("Error updating candidate");
    }
  }

  async updateCandidate3() {
    let cid = parseInt(this.updateCandidateDialogIDRef3.current.value);
    let name = this.updateCandidateDialogNameRef3.current.value;
    let email = this.updateCandidateDialogEmailRef3.current.value;
    let linkedin = this.updateCandidateDialogLinkedInRef3.current.value;
    let phone = this.updateCandidateDialogPhoneRef3.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.update(
      api_key,
      cid,
      name,
      email,
      linkedin,
      phone
    );
    let success = result["success"];
    if (success) {
      this.reloadAllTab();
      this.updateCandidateDialogRef3.current.style.display = "none";
      toast("Candidate has been updated");
    } else {
      toast("Error updating candidate");
    }
  }

  openCandidateCVDialog(cid, ccv) {
    if (ccv) {
      this.cvDialogPreviewRef.current.style.display = "block";
    } else {
      this.cvDialogPreviewRef.current.style.display = "none";
    }
    this.setState({
      ...this.state,
      current_candidate_id: cid,
    });
    this.cvDialogRef.current.style.display = "flex";
    this.cvDialogRef.current.scrollIntoView();
  }

  async openCandidateInterviewInvite(e) {
    toast("loading");
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let cemail = e.target.getAttribute("candidate-email");
    this.candidateInvitationLinkRef.current.setAttribute("candidate-id", cid);
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getInviteLink(api_key, cid);
    let link = result["link"];
    result = await API.marketing.campaign.addSendRateVacancy(
      api_key,
      this.state.interview.id,
      0
    );

    if (link) {
      this.bulkLinkEmailDialogRef.current.style.display = "flex";
      this.bulkLinkEmailDialogSubjectRef.current.value =
        "Candidate Invitation Link\n\n";
      this.bulkLinkEmailDialogBodyRef.current.value = link;
      this.bulkLinkEmailDialogEmailAddressRef.current.value = cemail;
    } else {
      toast("Error loading candidate's invitation link");
    }
  }
  
  async openCandidateAssessmentInvite(e) {
    toast("loading");
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let cemail = e.target.getAttribute("candidate-email");
    this.candidateInvitationLinkRef.current.setAttribute("candidate-id", cid);
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getInviteLink(api_key, cid);
    let link = result["link"];


    if (link) {
      const searchParams = new URLSearchParams(new URL(link).search);
      const b = searchParams.get("b");
      let xlink = "https://api.clipdrop.io/api/v1/assessment/get?b=" + b + "&t=" + (new Date()).getTime();
      this.bulkLinkEmailDialogRef.current.style.display = "flex";
      this.bulkLinkEmailDialogSubjectRef.current.value =
        "Candidate Assessment Link\n\n";
      this.bulkLinkEmailDialogBodyRef.current.value = xlink;
      this.bulkLinkEmailDialogEmailAddressRef.current.value = cemail;
    } else {
      toast("Error loading candidate's invitation link");
    }
  }

  async openCandidateInterviewInviteOut(e) {
    this.setState({
      ...this.state,
      loading: true,
    });
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    this.candidateInvitationLinkRef2.current.setAttribute("candidate-id", cid);
    let iid = this.state.interview.id;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getOutReachInviteLink(api_key, cid, iid);
    let success = result["success"];
    if (success === false) {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
    let link = result["link"];
    result = await API.marketing.campaign.addSendRateVacancy(
      api_key,
      this.state.interview.id,
      1
    );
    if (link) {
      this.candidateInviteLinkDialogRef2.current.style.display = "flex";
      this.candidateInvitationLinkRef2.current.value = link;
    } else {
      toast("Error loading candidate's invitation link");
    }
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  async openCandidateDelete(e) {
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.delete(api_key, cid);
    let success = result["success"];
    if (success) {
      this.reloadAllTab();
      toast("Candidate has been deleted");
    } else {
      toast("Error deleting candidate");
    }
  }

  async reloadAllTab() {
    this.reloadTab1();
    this.reloadTab2();
    this.reloadTab3();
    this.reloadTab4();
  }

  openCreateCandidateDialog() {
    this.createCandidateDialogRef.current.style.display = "flex";
    this.createCandidateDialogNameRef.current.value = "";
    this.createCandidateDialogEmailRef.current.value = "";
    this.createCandidateDialogLinkedInRef.current.value = "";
    this.createCandidateDialogPhoneRef.current.value = "";
    this.createCandidateDialogRef.current.scrollIntoView();
  }

  openCreateCandidateDialog2() {
    this.createCandidateDialogRef2.current.style.display = "flex";
    this.createCandidateDialogNameRef2.current.value = "";
    this.createCandidateDialogEmailRef2.current.value = "";
    this.createCandidateDialogLinkedInRef2.current.value = "";
    this.createCandidateDialogPhoneRef2.current.value = "";
    this.createCandidateDialogRef2.current.scrollIntoView();
  }

  openCreateCandidateDialog3() {
    if (
      !this.state.interview.bulk_marketing_video_url ||
      this.state.interview.bulk_marketing_video_url == "" ||
      this.state.interview.bulk_marketing_video_url == null
    ) {
      toast.warning(
        "Warning! Before you add a candidate you must please click the start button and finish the recording process",
        "warning"
      );
      return;
    }
    this.createCandidateDialogRef3.current.style.display = "flex";
    this.createCandidateDialogNameRef3.current.value = "";
    this.createCandidateDialogEmailRef3.current.value = "";
    this.createCandidateDialogLinkedInRef3.current.value = "";
    this.createCandidateDialogPhoneRef3.current.value = "";
    this.createCandidateDialogRef3.current.scrollIntoView();
  }

  hideCreateCandidateDialog() {
    this.createCandidateDialogRef.current.style.display = "none";
    this.createCandidateDialogNameRef.current.value = "";
    this.createCandidateDialogEmailRef.current.value = "";
    this.createCandidateDialogLinkedInRef.current.value = "";
    this.createCandidateDialogPhoneRef.current.value = "";
  }

  hideCreateCandidateDialog2() {
    this.createCandidateDialog2Ref.current.style.display = "none";
    this.createCandidateDialog2NameRef.current.value = "";
    this.createCandidateDialog2EmailRef.current.value = "";
    this.createCandidateDialog2LinkedInRef.current.value = "";
    this.createCandidateDialog2PhoneRef.current.value = "";
  }

  async createCandidate() {
    let name = this.createCandidateDialogNameRef.current.value;
    let email = this.createCandidateDialogEmailRef.current.value;
    if (!name || !email) {
      toast("Required fields not set");
      return;
    }
    this.setState({
      ...this.state,
      loading: true,
    });
    let linkedin = this.createCandidateDialogLinkedInRef.current.value;
    let phone = this.createCandidateDialogPhoneRef.current.value;
    this.createCandidateDialogRef.current.style.display = "none";

    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.create(
      api_key,
      this.state.interview.id,
      name,
      email,
      linkedin,
      phone,
      0
    );
    let success = result["success"];
    this.setState({
      ...this.state,
      loading: false,
    });
    if (success) {
      this.reloadTab1();
      toast("Candidate has been created");
    } else {
      toast("Error creating candidate");
    }
  }

  async createCandidate2() {
    let name = this.createCandidateDialogNameRef2.current.value;
    let email = this.createCandidateDialogEmailRef2.current.value;
    if (!name) {
      toast("Required fields not set");
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });
    let linkedin = this.createCandidateDialogLinkedInRef2.current.value;
    let phone = this.createCandidateDialogPhoneRef2.current.value;
    this.createCandidateDialogRef2.current.style.display = "none";

    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.create(
      api_key,
      this.state.interview.id,
      name,
      email,
      linkedin,
      phone,
      1,
      0
    );
    let success = result["success"];
    this.setState({
      ...this.state,
      loading: false,
    });
    if (success) {
      this.reloadTab2();
      toast("Candidate has been created");
    } else {
      toast("Error creating candidate");
    }
  }

  async createCandidate3() {
    let name = this.createCandidateDialogNameRef3.current.value;
    let email = this.createCandidateDialogEmailRef3.current.value;
    if (!name) {
      toast("Required fields not set");
      return;
    }
    this.setState({
      ...this.state,
      loading: true,
    });
    let linkedin = this.createCandidateDialogLinkedInRef3.current.value;
    let phone = this.createCandidateDialogPhoneRef3.current.value;
    this.createCandidateDialogRef3.current.style.display = "none";

    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.create(
      api_key,
      this.state.interview.id,
      name,
      email,
      linkedin,
      phone,
      2
    );
    let success = result["success"];
    this.setState({
      ...this.state,
      loading: false,
    });
    if (success) {
      this.reloadTab3();
      toast("Candidate has been created");
    } else {
      toast("Error creating candidate");
    }
  }

  async reloadTab1() {
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getSearch(api_key, this.state.interview.id, this.state.normal_candidate_search, this.state.normal_candidates_current_page, this.filter_rated, this.filter_not_rated, this.filter_recording_candidate, this.filter_norecording_candidate, this.filter_assessment_candidate, this.filter_no_assessment_candidate, this.filter_order_by_assessment_score);
    let normal_candidates = result["candidates"];
    let totalPages = result["totalPages"];
    this.setState({
      ...this.state,
      normal_candidates_current_page: this.state.normal_candidates_current_page,
      normal_candidates_total_pages: totalPages,
      normal_candidates: normal_candidates,
    });
  }

  async reloadTab2() {
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getBespoke(
      api_key,
      this.state.interview.id
    );
    let bespoke_candidates = result["candidates"];
    let filtered_bespoke_candidates = bespoke_candidates.filter((candidate) => {
      let a = this.filter_recording;
      let b = this.filter_ready_to_send;
      let c = this.filter_viewed;
      let d = this.filter_norecording;
      let has_video =
        candidate.custom_video_url != "" && candidate.custom_video_url != null;
      let ready_to_send =
        candidate.processing_done == 1 &&
        candidate.emailed == 0 &&
        candidate.copied == 0;
      let viewed = candidate.hasViewed;
      ///////////
      // singles
      ///////////
      // if no filters active
      if (a == false && b == false && c == false && d == false) return true;
      // is has video
      else if (a && has_video && b == false && c == false && d == false)
        return true;
      // is ready to send
      else if (a == false && b && ready_to_send && c == false && d == false)
        return true;
      // has been viewed
      else if (a == false && b == false && c && viewed && d == false)
        return true;
      // has no video
      else if (a == false && b == false && c == false && d && !has_video)
        return true;
      ///////////
      // multiple
      ///////////
      // has video and can be viewed
      else if (a && has_video && b == false && c && viewed && d == false)
        return true;
      // has video and can be viewed and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        c &&
        viewed &&
        d == false
      )
        return true;
      // has video and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        viewed == false &&
        c == false &&
        d == false
      )
        return true;
      // ready to send and viewed
      else if (a == false && b && ready_to_send && c && viewed && d == false)
        return true;
      // no matches
      return false;
    });
    this.setState({
      ...this.state,
      bespoke_candidates: filtered_bespoke_candidates,
    });
  }

  async reloadTab3() {
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.getOutReach(
      api_key,
      this.state.interview.id
    );
    let outreach_candidates = result["candidates"];
    this.setState({
      ...this.state,
      outreach_candidates: outreach_candidates,
    });
  }

  async reloadTab4() {
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    result = await API.questions.get(api_key, this.state.interview.id);
    let questions = result["questions"];
    this.setState({
      ...this.state,
      interview: interview,
      questions: questions,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async uploadCandidateCV() {
    this.cvDialogRef.current.style.display = "none";
    this.cvUploadDialogRef.current.style.display = "flex";
  }

  async previewCandidateCV() {
    this.cvPreviewDialogRef.current.style.display = "flex";
    this.cvDialogRef.current.style.display = "none";
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.get(
      api_key,
      this.state.current_candidate_id
    );
    let candidate = result["candidate"];
    let cv_url = candidate["cv_url"];
    this.cvPreviewIFrameRef.current.src = cv_url;
  }

  async deleteCandidateCV() {
    let cid = this.state.current_candidate_id;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.updateCV(api_key, cid);
    let success = result["success"];
    if (success) {
      this.cvDialogRef.current.style.display = "none";
      toast("Candidate's CV has been removed");
    } else {
      toast("Error removing candidate's CV");
    }
  }

  async uploadCVFile() {
    let cid = this.state.current_candidate_id;
    let file = this.cvImageUploadFileRef.current.files[0];
    if (file) {
      this.setState({ ...this.state, loading: true });
      let api_key = localStorage.getItem("api_key");
      //let filedata1 = await this.getFileData(file);
      //let filedata2 = filedata1.split("base64,")[1];
      let result1 = await API.file.uploadFileAsFile(api_key, file["name"], file);
      let uploadedFileName = result1["file_url"];
      let result2 = await API.candidate.updateCV(
        api_key,
        cid,
        uploadedFileName
      );
      if (result2["success"]) {
        this.setState({ ...this.state, loading: false });
        toast("Candidate's CV has been uploaded");
        this.cvUploadDialogRef.current.style.display = "none";
        this.reloadAllTab();
      } else {
        this.setState({ ...this.state, loading: false });
        toast("Maxed Storage Reached");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    } else {
      this.setState({ ...this.state, loading: false });
      toast("Please select a valid document file");
    }
  }

  async getFileData(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function (e) {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  copyInvitationLink() {
    let txt = this.candidateMarketingInviationEmailText2.current.value;
    try {
      window.navigator.clipboard.writeText(txt);
    } catch (e) {}
    try {
      window.clipboardData.setData("Text", txt);
    } catch (e) {}
    toast("Link copied to your clipboard");
  }

  copyInvitationLink2() {
    let txt = this.candidateMarketingInviationLinkText.current.value;
    try {
      window.navigator.clipboard.writeText(txt);
    } catch (e) {}
    try {
      window.clipboardData.setData("Text", txt);
    } catch (e) {}
    toast("Link copied to your clipboard");
  }

  async emailInvitationLink() {
    this.setState({ ...this.state, loading: true });
    let cid = parseInt(
      this.candidateInvitationLinkRef.current.getAttribute("candidate-id")
    );
    let subject = this.candidateMarketingInviationEmailText2.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.emailInvitationLink(api_key, cid, subject);
    this.candidateInviteLinkDialogRef.current.style.display = "none";
    this.setState({ ...this.state, loading: false });
    toast("Link sent to candidate via Email");
  }

  async emailInvitationLink2() {
    this.setState({ ...this.state, loading: true });
    let cid = parseInt(
      this.candidateInvitationLinkRef2.current.getAttribute("candidate-id")
    );
    let subject = this.candidateMarketingInviationEmailText22.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.emailInvitationLink2(
      api_key,
      cid,
      subject
    );
    this.candidateInvitationLinkRef2.current.style.display = "none";
    this.setState({ ...this.state, loading: false });
    this.reloadTab2();
    toast("Link sent to candidate via Email");
  }

  openBaseThumbnailDialog() {
    this.baseThumbnailDialog.current.style.display = "flex";
  }

  showBaseImageUpload() {
    this.baseThumbnailDialog.current.style.display = "none";
    this.baseThumbnailDialogUpload.current.style.display = "flex";
  }
  showBulkImageUpload() {
    this.bulkThumbnailDialog.current.style.display = "none";
    this.bulkThumbnailDialogUpload.current.style.display = "flex";
  }

  showSocialImageUpload() {
    this.socialThumbnailDialog.current.style.display = "none";
    this.socialThumbnailDialogUpload.current.style.display = "flex";
  }

  showBaseImageCapture() {
    this.setState({ ...this.state, isVisible: true });
    this.baseThumbnailDialog.current.style.display = "none";
    this.baseThumbnailDialogCapture.current.style.display = "flex";
    this.cameraCounterRef.current.style.display = "block";
    //this.cameraRef.current.video.style.display = "block";
    this.imgRef.current.style.display = "none";
    this.savecaptureImageRef.current.style.display = "none";
  }
  showBulkImageCapture() {
    this.setState({ ...this.state, isVisible: true });
    this.bulkThumbnailDialog.current.style.display = "none";
    this.bulkThumbnailDialogCapture.current.style.display = "flex";
    this.cameraCounterBulkRef.current.style.display = "block";
    this.imgBulkRef.current.style.display = "none";
    this.savecaptureImageBulkRef.current.style.display = "none";
  }

  async showSocialImageCapture() {
    this.setState({ ...this.state, isVisible: true });
    this.cameraSocialRef.current.stream =
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          facingMode: "user",
          width: { exact: 640 },
          height: { exact: 480 },
        },
      });
    this.socialThumbnailDialog.current.style.display = "none";
    this.socialThumbnailDialogCapture.current.style.display = "flex";
    this.cameraCounterSocialRef.current.style.display = "block";
    this.imgSocialRef.current.style.display = "none";
    this.savecaptureImageSocialRef.current.style.display = "none";
  }

  async captureImage() {
    this.cameraRef.current.video.style.display = "block";
    this.cameraCounterRef.current.style.display = "block";
    this.cameraCounterRef.current.innerHTML = "3";
    setTimeout(() => {
      this.cameraCounterRef.current.innerHTML = "2";
      setTimeout(() => {
        this.cameraCounterRef.current.innerHTML = "1";
        setTimeout(() => {
          this.cameraCounterRef.current.style.display = "none";
          let image_data = this.cameraRef.current.getScreenshot({
            width: 1920,
            height: 1080,
          });
          this.cameraRef.current.video.style.display = "none";
          this.imgRef.current.style.display = "block";
          this.imgRef.current.src = image_data;
          this.setState({
            image_data: image_data,
          });
          this.savecaptureImageRef.current.style.display = "block";
        }, 1000);
      }, 1000);
    }, 1000);
  }

  async captureImageBulk() {
    this.cameraBulkRef.current.video.style.display = "block";
    this.cameraCounterBulkRef.current.style.display = "block";
    this.cameraCounterBulkRef.current.innerHTML = "3";
    setTimeout(() => {
      this.cameraCounterBulkRef.current.innerHTML = "2";
      setTimeout(() => {
        this.cameraCounterBulkRef.current.innerHTML = "1";
        setTimeout(() => {
          this.cameraCounterBulkRef.current.style.display = "none";
          let image_data = this.cameraBulkRef.current.getScreenshot({
            width: 1920,
            height: 1080,
          });
          this.cameraBulkRef.current.video.style.display = "none";
          this.imgBulkRef.current.style.display = "block";
          this.imgBulkRef.current.src = image_data;
          this.setState({
            ...this.state,
            image_data: image_data,
          });
          this.savecaptureImageBulkRef.current.style.display = "block";
        }, 1000);
      }, 1000);
    }, 1000);
  }

  async captureImageSocial() {
    this.cameraSocialRef.current.video.style.display = "block";
    this.cameraCounterSocialRef.current.style.display = "block";
    this.cameraCounterSocialRef.current.innerHTML = "3";
    setTimeout(() => {
      this.cameraCounterSocialRef.current.innerHTML = "2";
      setTimeout(() => {
        this.cameraCounterSocialRef.current.innerHTML = "1";
        setTimeout(() => {
          this.cameraCounterSocialRef.current.style.display = "none";
          let image_data = this.cameraSocialRef.current.getScreenshot({
            width: 1920,
            height: 1080,
          });
          this.setState({
            ...this.state,
            image_data: image_data,
          });
          this.cameraSocialRef.current.video.style.display = "none";
          this.imgSocialRef.current.style.display = "block";
          this.imgSocialRef.current.src = image_data;
          this.savecaptureImageSocialRef.current.style.display = "block";
        }, 1000);
      }, 1000);
    }, 1000);
  }

  async savecaptureImage() {
    this.savecaptureImageRef.current.style.display = "none";
    let image_data = this.state.image_data;
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let filedata = image_data.split("base64,")[1];
    let result1 = await API.file.uploadFile(
      api_key,
      "image_file.webp",
      filedata
    );
    let uploadedFileName = result1["file_url"];
    let interview_id = this.state.interview.id;
    let result = await API.interview.setBespokeThumbnail(
      api_key,
      interview_id,
      uploadedFileName
    );
    let success = result["success"];
    if (success) {
      this.baseThumbnailDialogCapture.current.style.display = "none";
      toast("Bespoke Base thumbnail has been updated");
      api_key = localStorage.getItem("api_key");
      let result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
        loading: false,
        isVisible: false,
        campaignProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.bulk_marketing_video_state],
        socialProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.advertise_marketing_video_state],
      });
    } else {
      toast("Error updating bespoke base thumbnail");
    }
  }

  async savecaptureImageBulk() {
    this.savecaptureImageBulkRef.current.style.display = "none";
    let image_data = this.state.image_data;
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let filedata = image_data.split("base64,")[1];
    let result1 = await API.file.uploadFile(
      api_key,
      "image_file.webp",
      filedata
    );
    let uploadedFileName = result1["file_url"];
    let interview_id = this.state.interview.id;
    let result = await API.interview.setOutReachThumbnail(
      api_key,
      interview_id,
      uploadedFileName
    );
    let success = result["success"];
    if (success) {
      toast("Bulk Marketing thumbnail has been updated");
      api_key = localStorage.getItem("api_key");
      let result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
        loading: false,
        isVisible: false,
        campaignProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.bulk_marketing_video_state],
        socialProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.advertise_marketing_video_state],
      });
      this.bulkThumbnailDialogCapture.current.style.display = "none";
    } else {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
    }
  }

  async savecaptureImageSocial() {
    this.savecaptureImageSocialRef.current.style.display = "none";
    let image_data = this.state.image_data;
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let filedata = image_data.split("base64,")[1];
    let result1 = await API.file.uploadFile(
      api_key,
      "image_file.webp",
      filedata
    );
    let uploadedFileName = result1["file_url"];
    let result = await API.interview.setSocialThumbnail(
      api_key,
      this.state.interview.id,
      uploadedFileName
    );
    let success = result["success"];
    if (success) {
      toast("Bulk Marketing thumbnail has been updated");
      api_key = localStorage.getItem("api_key");
      let result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
        loading: false,
        isVisible: false,
        campaignProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.bulk_marketing_video_state],
        socialProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.advertise_marketing_video_state],
      });
      this.socialThumbnailDialogCapture.current.style.display = "none";
    } else {
      toast("Error updating bespoke base thumbnail");
    }
  }

  async customMessageOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let text = this.customMessageRef.current.value;
    let result = await API.interview.setCustomThumbnailCustomText(
      api_key,
      this.state.interview.id,
      text
    );
    toast("Custom Message Saved");
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async marketingTextOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let text = this.marketingTextRef.current.value;
    let result = await API.interview.setCustomMarketingText(
      api_key,
      this.state.interview.id,
      text
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async allowATSBespokeOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let v = parseInt(e.target.value);
    let result = await API.interview.setATSBespokeAllow(
      api_key,
      this.state.interview.id,
      v
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async allowBespokeITJOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let v = parseInt(e.target.value);
    let result = await API.interview.setITJBespokeAllow(
      api_key,
      this.state.interview.id,
      v
    );
    result = await API.interview.get(api_key, this.state.interview.id);
    if (result["success"] === false) {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
    }
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async setATSBespokeOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let text = e.target.value;
    if (text) {
      this.atsBespokeLinkRef.current.innerHTML = API.validation.verifyURL(text)
        ? "<div style='font-size:9pt; color: green'>Valid URL</div>"
        : "<div style='font-size:9pt; color: red'>Invalid URL</div>";
    } else {
      this.atsBespokeLinkRef.current.innerHTML = "";
    }
    let result = await API.interview.setATSBespokeText(
      api_key,
      this.state.interview.id,
      text
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
    this.atsBespokeLinkRef.current.innerHTML = API.validation.verifyURL(
      this.state.interview.ats_bespoke_url
    )
      ? "<div style='font-size:9pt; color: green'>Valid URL</div>"
      : "<div style='font-size:9pt; color: red'>Invalid URL</div>";
  }

  async allowATSOutreachOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let v = parseInt(e.target.value);
    let result = await API.interview.setATSOutreachAllow(
      api_key,
      this.state.interview.id,
      v
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async setATSOutreachOnChange(e) {
    let api_key = localStorage.getItem("api_key");
    let text = e.target.value;
    if (text) {
      this.atsOutreachLinkRef.current.innerHTML = API.validation.verifyURL(text)
        ? "<div style='fontSize:8pt;color:green'>Valid URL</div>"
        : "<div style='fontSize:8pt;color:red'>Invalid URL</div>";
    } else {
      this.atsOutreachLinkRef.current.innerHTML = "";
    }
    let result = await API.interview.setATSOutreachText(
      api_key,
      this.state.interview.id,
      text
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async allowBulkInterviewOnChange(e) {
    let type_value = parseInt(e.target.value);
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setBespokeAllowCandidateInterivew(
      api_key,
      this.state.interview.id,
      type_value
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
    this.callSiblingUpdate(interview);
  }
  async allowOutReachInterviewOnChange(e) {
    let type_value = parseInt(e.target.value);
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setOutReachAllowCandidateInterivew(
      api_key,
      this.state.interview.id,
      type_value
    );
    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
    this.callSiblingUpdate(interview);
  }
  async thumbnailTextOnChange(e) {
    let type_value = parseInt(this.thumbnailTextRef.current.value);
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setCustomThumbnailType(
      api_key,
      this.state.interview.id,
      type_value
    );

    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async thumbnailTextOutreachOnChange(e) {
    let type_value = parseInt(e.target.value);
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setCustomThumbnailOutreachType(
      api_key,
      this.state.interview.id,
      type_value
    );

    api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
  }

  async uploadBaseThumbnailFile() {
    let files = this.imageUploadFileRef.current.files;
    if (files.length) {
      let file = files[0];
      if (file) {
        this.setState({
          ...this.state,
          loading: true,
        });
        let api_key = localStorage.getItem("api_key");
        //let filedata1 = await this.getFileData(file);
        //let filedata2 = filedata1.split("base64,")[1];
        let result = await API.file.uploadFileAsFile(
          api_key,
          file["name"],
          file
        );
        let uploadedFileName = result["file_url"];
        result = await API.interview.setBespokeThumbnail(
          api_key,
          this.state.interview.id,
          uploadedFileName
        );
        result = await API.interview.get(api_key, this.state.interview.id);
        let interview = result["interview"];
        this.setState({
          ...this.state,
          interview: interview,
          loading: false,
          campaignProcessingVideoStatus: [
            "",
            VideoProcessingInlineComponent,
            VideoProcessedInlineComponent,
          ][interview.bulk_marketing_video_state],
          socialProcessingVideoStatus: [
            "",
            VideoProcessingInlineComponent,
            VideoProcessedInlineComponent,
          ][interview.advertise_marketing_video_state],
        });
        this.baseThumbnailDialogUpload.current.style.display = "none";
        toast("File uploaded");
      } else {
        toast("select a valid image file");
      }
    } else {
      toast("select a valid image file");
    }
  }

  async uploadBulkThumbnailFile() {
    let files = this.imageUploadFileBulkRef.current.files;
    if (files.length) {
      let file = files[0];
      if (file) {
        this.setState({
          ...this.state,
          loading: true,
        });
        let api_key = localStorage.getItem("api_key");
        //let filedata1 = await this.getFileData(file);
        //let filedata2 = filedata1.split("base64,")[1];
        let result = await API.file.uploadFileAsFile(
          api_key,
          file["name"],
          file
        );
        let uploadedFileName = result["file_url"];
        result = await API.interview.setOutReachThumbnail(
          api_key,
          this.state.interview.id,
          uploadedFileName
        );
        result = await API.interview.get(api_key, this.state.interview.id);
        let interview = result["interview"];
        this.setState({
          ...this.state,
          interview: interview,
          loading: false,
          campaignProcessingVideoStatus: [
            "",
            VideoProcessingInlineComponent,
            VideoProcessedInlineComponent,
          ][interview.bulk_marketing_video_state],
          socialProcessingVideoStatus: [
            "",
            VideoProcessingInlineComponent,
            VideoProcessedInlineComponent,
          ][interview.advertise_marketing_video_state],
        });
        this.bulkThumbnailDialogUpload.current.style.display = "none";
        toast("File uploaded");
      } else {
        toast("select a valid image file");
      }
    } else {
      toast("select a valid image file");
    }
  }

  async uploadSocialThumbnailFile() {
    let files = this.imageUploadFileSocialRef.current.files;
    if (files.length) {
      let file = files[0];
      if (file) {
        this.setState({
          ...this.state,
          loading: true,
        });
        let api_key = localStorage.getItem("api_key");
        //let filedata1 = await this.getFileData(file);
        //let filedata2 = filedata1.split("base64,")[1];
        let result = await API.file.uploadFileAsFile(
          api_key,
          file["name"],
          file
        );
        let uploadedFileName = result["file_url"];
        result = await API.interview.setSocialThumbnail(
          api_key,
          this.state.interview.id,
          uploadedFileName
        );
        result = await API.interview.get(api_key, this.state.interview.id);
        let interview = result["interview"];
        this.setState({
          ...this.state,
          interview: interview,
          loading: false,
          campaignProcessingVideoStatus: [
            "",
            VideoProcessingInlineComponent,
            VideoProcessedInlineComponent,
          ][interview.bulk_marketing_video_state],
          socialProcessingVideoStatus: [
            "",
            VideoProcessingInlineComponent,
            VideoProcessedInlineComponent,
          ][interview.advertise_marketing_video_state],
        });
        this.socialThumbnailDialogUpload.current.style.display = "none";
        toast("File uploaded");
      } else {
        toast("select a valid image file");
      }
    } else {
      toast("select a valid image file");
    }
  }

  showTab2Actions() {
    if (this.tab2Actions.current.style.display == "block") {
      this.tab2Actions.current.style.display = "none";
    } else {
      this.tab2Actions.current.style.display = "block";
    }
  }
  showTab3Actions() {
    if (this.tab3Actions.current.style.display == "block") {
      this.tab3Actions.current.style.display = "none";
    } else {
      this.tab3Actions.current.style.display = "block";
    }
  }
  showTab4Actions() {
    if (this.tab4Actions.current.style.display == "block") {
      this.tab4Actions.current.style.display = "none";
    } else {
      this.tab4Actions.current.style.display = "block";
    }
  }

  startTab2Flow() {
    this.bespokeFlowDialogRef.current.style.display = "flex";
    this.bespokeFlowStep1.current.style.display = "block";
    //this.bespokeFlowStep2.current.style.display = "none";
    //this.bespokeFlowStep2SubStepCamera.current.style.display = "none";
    //this.bespokeFlowStep2SubStepUpload.current.style.display = "none";
    //this.bespokeFlowStep2SubStepSelect.current.style.display = "none";
    this.bespokeFlowStep4.current.style.display = "none";
    this.bespokeFlowStep5.current.style.display = "none";
    this.bespokeFlowStep6.current.style.display = "none";
    this.bespokeFlowStep7.current.style.display = "none";
  }

  async startTab3Flow() {
    let result = await API.interview.markVideo(0, 0, this.state.interview.id);
    let api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      campaignProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.bulk_marketing_video_state],
    });
    this.outreachFlowDialogRef.current.style.display = "flex";
    this.outreachFlowStep1.current.style.display = "block";
    this.outreachFlowStep3.current.style.display = "none";
    this.outreachFlowStep3Option.current.style.display = "none";
    this.outreachFlowStep4.current.style.display = "none";
    this.outreachFlowStep5.current.style.display = "none";
    this.outreachFlowStep6.current.style.display = "none";
    this.setState({ ...this.state, canRecordVideo: false });
  }

  async startTab4Flow() {
    let result = await API.interview.markVideo(0, 1, this.state.interview.id);
    let api_key = localStorage.getItem("api_key");
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
      socialProcessingVideoStatus: [
        "",
        VideoProcessingInlineComponent,
        VideoProcessedInlineComponent,
      ][interview.advertise_marketing_video_state],
    });
    this.socialFlowDialogRef.current.style.display = "flex";
    this.socialFlowStep2.current.style.display = "none";
    this.socialFlowStep2SubStep1.current.style.display = "block";
    this.socialFlowStep2SubStepCamera.current.style.display = "none";
    this.socialFlowStep2SubStepUpload.current.style.display = "none";
    this.socialFlowStep3.current.style.display = "block";
    this.socialFlowStep4.current.style.display = "none";
    this.socialFlowStep5.current.style.display = "none";
    this.setState({ ...this.state, canRecordVideoSocial: true });
  }

  async bulkTextOnChange() {
    let text = this.bulkTextRef.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setBulkMarketingText(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
    if (success) {
      result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
        campaignProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.bulk_marketing_video_state],
        socialProcessingVideoStatus: [
          "",
          VideoProcessingInlineComponent,
          VideoProcessedInlineComponent,
        ][interview.advertise_marketing_video_state],
      });
    } else {
    }
  }

  async bulkTextOnChange2(e) {
    let text = e.target.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setBulkMarketingCaptionText(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
    if (success) {
      result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
      });
    } else {
    }
  }

  async socialTextOnChange(e) {
    let text = e.target.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setSocialMarketingText(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
  }

  async socialCaptionOnChange(e) {
    let text = e.target.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setSocialMarketingSocial(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
  }

  openBulkThumbnailDialog() {
    this.bulkThumbnailDialog.current.style.display = "flex";
  }

  openSocialThumbnailDialog() {
    this.socialThumbnailDialog.current.style.display = "flex";
  }

  openBulkVideoDialog() {
    this.bulkVideoOptionsRef.current.style.display = "none";
    this.bulkVideoDialogRef.current.style.display = "flex";
    this.bulkVideoDialogElementRef.current.resetUI();
    this.bulkVideoDialogElementRef.current.openVideo();
  }

  openBulkVideoUploadDialog() {
    this.bulkVideoOptionsRef.current.style.display = "none";
    this.bulkVideoUploadDialogRef.current.style.display = "flex";
  }

  async setBulkMarketingVideo(
    video_url,
    callback = () => {
      return;
    }
  ) {
    this.setState({
      ...this.state,
      loading: true,
    });
    this.bulkVideoDialogRef.current.style.display = "none";
    this.bulkVideoUploadDialogRef.current.style.display = "none";
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setBulkMarketingVideo(
      api_key,
      this.state.interview.id,
      video_url
    );
    let success = result["success"];
    this.setState({
      ...this.state,
      loading: false,
    });
    if (success) {
      result = await API.interview.markVideo(0, 0, this.state.interview.id, 2);
      result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
        campaignProcessingVideoStatus: VideoProcessedInlineComponent
      });
      toast("Video Saved");
      callback();
      toast("OutReach Campaign Video Updated");
    } else {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
    }
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  async openCustomVideoUpload(e) {
    let api_key = localStorage.getItem("api_key");
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    this.customCandidateVideoRef.current.style.display = "flex";
    this.customCandidateVideoRef.current.setAttribute("candidate-id", cid);
    this.customCandidateVideoelementRef.current.resetUI();
    this.customCandidateVideoelementRef.current.openVideo();
    this.customCandidateVideoelementRef.current.setPreviewData(
      1,
      cid,
      0,
      api_key
    );
    //await API.candidate.setProcessing(api_key, cid);
    //setTimeout(()=>{this.reloadAllTab();}, 2000);
  }

  async openCustomVideoUploadRe(e) {
    let api_key = localStorage.getItem("api_key");
    this.customCandidateVideoPreviewVideoRef.current.pause();
    let cid = parseInt(
      this.customCandidateVideoPreviewRef.current.getAttribute("candidate-id")
    );
    this.customCandidateVideoRef.current.style.display = "flex";
    this.customCandidateVideoRef.current.setAttribute("candidate-id", cid);
    this.customCandidateVideoPreviewRef.current.style.display = "none";
    this.customCandidateVideoelementRef.current.resetUI();
    this.customCandidateVideoelementRef.current.openVideo();
    this.customCandidateVideoelementRef.current.setPreviewData(
      1,
      cid,
      0,
      api_key
    );
    //await API.candidate.setProcessing(api_key, cid);
    //setTimeout(()=>{this.reloadAllTab();}, 2000);
  }

  openCustomVideoPreview(e) {
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let cvurl = e.target.getAttribute("candidate-video");
    this.customCandidateVideoPreviewRef.current.style.display = "flex";
    this.customCandidateVideoPreviewRef.current.setAttribute(
      "candidate-id",
      cid
    );
    this.customCandidateVideoPreviewVideoRef.current.src = cvurl;
    this.customCandidateVideoPreviewVideoRef.current.play();
  }

  async setCandidateCustomVideo(video_url) {
    this.setState({
      ...this.state,
      loading: true,
    });
    let cid = parseInt(
      this.customCandidateVideoRef.current.getAttribute("candidate-id")
    );
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.setCustomVideo(api_key, cid, video_url);
    let success = result["success"];
    if (success) {
      toast("Video Saved");
      this.customCandidateVideoRef.current.style.display = "none";
      this.reloadAllTab();
      this.setState({
        ...this.state,
        loading: false,
      });
    } else {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
    }
  }
  openSocialVideoDialogOptions() {
    this.socialVideoOptionsRef.current.style.display = "flex";
  }
  openSocialVideoDialog() {
    this.socialVideoOptionsRef.current.style.display = "none";
    this.socialVideoRef.current.style.display = "flex";
    this.socialVideoDialogElementRef.current.resetUI();
    this.socialVideoDialogElementRef.current.openVideo();
  }

  openSocialVideoUploadDialog() {
    this.socialVideoOptionsRef.current.style.display = "none";
    this.socialVideoUploadRef.current.style.display = "flex";
  }

  openBulkVideoDialogOptions() {
    this.bulkVideoOptionsRef.current.style.display = "flex";
  }
  async openLibraryDialog() {
    this.bulkVideoOptionsRef.current.style.display = "none";
    toast("Loading");
    let api_key = localStorage.getItem("api_key");
    let result = await API.library.getSocial(api_key);
    let success = result["success"];
    if (success) {
      let items = result["items"];
      this.setState({
        ...this.state,
        library: items,
      });
      toast("Library Loaded");
      this.socialVideoOptionsRef.current.style.display = "none";
      this.librarySelectionRef.current.style.display = "flex";
    } else {
    }
  }
  async openLibraryDialog2() {
    this.socialVideoOptionsRef.current.style.display = "none";
    toast("Loading");
    let api_key = localStorage.getItem("api_key");
    let result = await API.library.getSocial(api_key);
    let success = result["success"];
    if (success) {
      let items = result["items"];
      this.setState({
        ...this.state,
        library: items,
      });
      toast("Library Loaded");
      this.bulkVideoOptionsRef.current.style.display = "none";
      this.librarySelectionRef2.current.style.display = "flex";
    } else {
    }
  }
  async setSocialPostVideo(video_url) {
    this.socialVideoOptionsRef.current.style.display = "none";
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setSocialMarketingVideo(
      api_key,
      this.state.interview.id,
      video_url
    );
    let success = result["success"];
    if (success) {
      toast("Video Saved");
      this.socialVideoUploadRef.current.style.display = "none";
      this.socialVideoRef.current.style.display = "none";      
      this.setState({
        ...this.state,
        socialProcessingVideoStatus: VideoProcessedInlineComponent,
        loading: false,
      });
      this.reloadAllTab();
      this.socialVideoLibraryRef.current.showDialog();
      this.socialVideoLibraryRef.current.setVideo(video_url);
      this.socialVideoLibraryRef.current.setStep(0);
    } else {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  async openBespokeInvitationLinkDialog(e) {
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let result = await API.candidate.getBespokeInvitation(api_key, cid);
    let text = result["text"];
    this.candidateMarketingInviationLink.current.style.display = "flex";
    this.candidateMarketingInviationLinkText.current.value = text;
    this.candidateMarketingInviationLinkText.current.setAttribute(
      "candidate-id",
      cid
    );
    result = await API.candidate.getBespoke(api_key, this.state.interview.id);
    let bespoke_candidates = result["candidates"];

    let filteredCandidates = bespoke_candidates.filter((candidate) => {
      let a = this.filter_recording;
      let b = this.filter_ready_to_send;
      let c = this.filter_viewed;
      let d = this.filter_norecording;
      let has_video =
        candidate.custom_video_url != "" && candidate.custom_video_url != null;
      let ready_to_send =
        candidate.processing_done == 1 &&
        candidate.emailed == 0 &&
        candidate.copied == 0;
      let viewed = candidate.hasViewed;
      ///////////
      // singles
      ///////////
      // if no filters active
      if (a == false && b == false && c == false && d == false) return true;
      // is has video
      else if (a && has_video && b == false && c == false && d == false)
        return true;
      // is ready to send
      else if (a == false && b && ready_to_send && c == false && d == false)
        return true;
      // has been viewed
      else if (a == false && b == false && c && viewed && d == false)
        return true;
      // has no video
      else if (a == false && b == false && c == false && d && !has_video)
        return true;
      ///////////
      // multiple
      ///////////
      // has video and can be viewed
      else if (a && has_video && b == false && c && viewed && d == false)
        return true;
      // has video and can be viewed and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        c &&
        viewed &&
        d == false
      )
        return true;
      // has video and ready to send
      else if (
        a &&
        has_video &&
        b &&
        ready_to_send &&
        viewed == false &&
        c == false &&
        d == false
      )
        return true;
      // ready to send and viewed
      else if (a == false && b && ready_to_send && c && viewed && d == false)
        return true;
      // no matches
      return false;
    });
    console.log(filteredCandidates);
    result = await API.marketing.campaign.addSendRateVacancy(
      api_key,
      this.state.interview.id,
      1
    );
    this.setState({
      ...this.state,
      loading: false,
      bespoke_candidates: filteredCandidates,
    });
  }

  shareOnFacebook = (app_id, href, redirect_url, quote) => {
    let params =
      "width=600,height=600,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,";
    window.open(
      "https://www.facebook.com/dialog/share?app_id=" +
        app_id +
        "&display=popup&href=" +
        href +
        "&quote=" +
        quote,
      "popup",
      params
    );
  };
  shareOnLinkedIn = (href, quote) => {
    let params =
      "width=600,height=600,scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,";
    let w = window.open(
      "https://www.linkedin.com/sharing/share-offsite/?url=" + href,
      "popup",
      params
    );
  };

  shareToFacebook() {
    let url =
      "https://api.clipdrop.io/api/v1/special/pages/advertise/" +
      btoa(this.state.interview.id) +
      "?&t=" +
      Math.floor(Date.now() / 1000);
    let redirect = this.state.interview.advertise_marketing_redirect_url;
    let quote = "";
    this.shareOnFacebook("940152470118793", url, redirect, quote);
  }

  shareToLinkedIn() {
    let url =
      "https://api.clipdrop.io/api/v1/special/pages/advertise/" +
      btoa(this.state.interview.id) +
      "?&t=" +
      Math.floor(Date.now() / 1000);
    let quote = "";
    this.shareOnLinkedIn(url, quote);
  }

  async reviewCandidate(e) {
    this.r_id = 0;
    this.noRating = false;
    toast("Loading Candidate, Please wait");
    let api_key = localStorage.getItem("api_key");
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let result = await API.review.get(api_key, cid);
    review = result["review"];
    if (!review) {
      toast("Couldn't load Candidate's review data");
      return;
    }

    this.setState({
      ...this.state,
      r_id: 0,
      candidate_id: cid,
    });

    this.candidateRefDialog.current.style.display = "flex";
    this.candidateRefDialogNotice.current.style.display = "block";
    this.candidateRefDialogQuestion.current.style.display = "none";
    this.candidateRefDialogAnswer.current.style.display = "none";
    this.candidateRefDialogRating.current.style.display = "none";
    this.candidateRefDialogComplete.current.style.display = "none";
  }

  async reviewCandidateNoRating(e) {
    this.r_id = 0;
    this.noRating = true;
    toast("Loading Candidate, Please wait");
    let api_key = localStorage.getItem("api_key");
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    let result = await API.review.get(api_key, cid);
    review = result["review"];
    if (!review) {
      toast("Couldn't load Candidate's review data");
      return;
    }

    this.setState({
      ...this.state,
      r_id: 0,
      candidate_id: cid,
    });

    this.candidateRefDialog.current.style.display = "flex";
    this.candidateRefDialogNotice.current.style.display = "block";
    this.candidateRefDialogQuestion.current.style.display = "none";
    this.candidateRefDialogAnswer.current.style.display = "none";
    this.candidateRefDialogRating.current.style.display = "none";
    this.candidateRefDialogComplete.current.style.display = "none";
  }

  loadReviewQuestion() {
    try {
      this.candidateRefDialogAnswerVideo.current.pause();
    } catch (e2) {}
    this.candidateRefDialogQuestionText.current.style.display = "none";
    this.candidateRefDialogAnswer.current.style.display = "none";
    this.candidateRefDialogQuestionVideo.current.style.display = "none";
    this.candidateRefDialogQuestionVideoControls.current.style.display = "none";
    console.log(this.r_id);
    console.log(review);
    let question = review[this.r_id]["question"];
    if (question["type"] === "text") {
      this.candidateRefDialogQuestionText.current.style.display = "block";
      this.candidateRefDialogQuestionText.current.innerHTML = question["text"];
    } else if (question["type"] === "video") {
      this.candidateRefDialogQuestionVideo.current.style.display = "block";
      this.candidateRefDialogQuestionVideoControls.current.style.display =
        "block";
      this.candidateRefDialogQuestionVideo.current.src = question["video_url"];
      this.candidateRefDialogQuestionVideo.current.style.display = "block";
      this.candidateRefDialogQuestionVideoControls.current.style.display = "block";
    } else if (question["type"] === "multi") {
      this.candidateRefDialogQuestionText.current.style.display = "block";
      this.candidateRefDialogQuestionText.current.innerHTML = JSON.parse(question["text"])['question'];
    }
    this.candidateRefDialogQuestion.current.style.display = "block";
    let qtitle = `Question ${this.r_id + 1} of ${review.length}`;
    this.candidateRefDialogQuestionTitle.current.innerHTML = qtitle;
  }

  loadReviewAnswer() {
    let question = review[this.r_id]["question"];
    if (question["type"] == "video") {
      try {
        this.candidateRefDialogQuestionVideo.current.pause();
      } catch (e2) {}
    }
    let answer = review[this.r_id]["answer"];
    if (question["type"] === "multi"){
      this.candidateRefDialogAnswerVideo.current.style.display = "none";
      this.candidateRefDialogAnswerVideoControls.current.style.display = "none";
      this.candidateRefDialogAnswerText.current.innerHTML = answer["video_link"];
    } else {
      this.candidateRefDialogAnswerVideo.current.style.display = "block";
      this.candidateRefDialogAnswerVideoControls.current.style.display = "block";
      this.candidateRefDialogAnswerVideo.current.src = answer["video_link"];
      this.candidateRefDialogAnswerVideo.current.play();  
    }
    let aTitle = `Candidate's Answer for Question ${this.r_id + 1}`;
    this.candidateRefDialogAnswerTitle.current.innerHTML = aTitle;
  }

  startCandidateReview() {
    this.candidateRefDialogNotice.current.style.display = "none";
    this.candidateRefDialogQuestion.current.style.display = "none";
    this.loadReviewQuestion();
  }

  playQuestionVideo() {
    this.candidateRefDialogQuestionVideo.current.play();
  }
  pauseQuestionVideo() {
    this.candidateRefDialogQuestionVideo.current.pause();
  }
  restartQuestionVideo() {
    this.candidateRefDialogQuestionVideo.current.time = 0;
    this.candidateRefDialogQuestionVideo.current.play();
  }
  nextQuestionVideo() {
    try {
      this.candidateRefDialogQuestionVideo.current.pause();
    } catch (e2) {}
    this.candidateRefDialogQuestion.current.style.display = "none";
    this.candidateRefDialogAnswer.current.style.display = "block";
    this.loadReviewAnswer();
  }
  playAnswerVideo() {
    this.candidateRefDialogAnswerVideo.current.play();
  }
  pauseAnswerVideo() {
    this.candidateRefDialogAnswerVideo.current.pause();
  }
  restartAnswerVideo() {
    this.candidateRefDialogAnswerVideo.current.time = 0;
    this.candidateRefDialogAnswerVideo.current.play();
  }
  nextAnswerVideo() {
    try {
      this.candidateRefDialogAnswerVideo.current.pause();
    } catch (e4) {}
    if (!this.noRating) {
      this.candidateRefDialogQuestion.current.style.display = "none";
      this.candidateRefDialogAnswer.current.style.display = "none";
      this.candidateRefDialogRating.current.style.display = "block";
      this.candidateReviewRatingStars1Ref.current.style.color = "#bdbdbd";
      this.candidateReviewRatingStars2Ref.current.style.color = "#bdbdbd";
      this.candidateReviewRatingStars3Ref.current.style.color = "#bdbdbd";
      this.candidateReviewRatingStars4Ref.current.style.color = "#bdbdbd";
      this.candidateReviewRatingStars5Ref.current.style.color = "#bdbdbd";
    } else {
      this.candidateRefDialogQuestion.current.style.display = "none";
      this.candidateRefDialogAnswer.current.style.display = "none";
      if (this.r_id + 1 >= review.length) {
        this.candidateRefDialogAnswer.current.style.display = "none";
        this.candidateRefDialogComplete.current.style.display = "block";
        this.reloadAllTab();
      } else {
        this.r_id = this.r_id + 1;
        console.log("CHANGE STATE");
        console.log(this.r_id);
        this.candidateRefDialogAnswer.current.style.display = "none";
        this.loadReviewQuestion();
      }
    }
  }

  async rateCandidate(e) {
    let api_key = localStorage.getItem("api_key");
    let rank = parseInt(e.target.getAttribute("rank-id"));
    this.candidateReviewRatingStars1Ref.current.style.color = "#bdbdbd";
    this.candidateReviewRatingStars2Ref.current.style.color = "#bdbdbd";
    this.candidateReviewRatingStars3Ref.current.style.color = "#bdbdbd";
    this.candidateReviewRatingStars4Ref.current.style.color = "#bdbdbd";
    this.candidateReviewRatingStars5Ref.current.style.color = "#bdbdbd";
    switch (rank) {
      case 1:
        this.candidateReviewRatingStars1Ref.current.style.color = "#ffe136";
        break;
      case 2:
        this.candidateReviewRatingStars1Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars2Ref.current.style.color = "#ffe136";
        break;
      case 3:
        this.candidateReviewRatingStars1Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars2Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars3Ref.current.style.color = "#ffe136";
        break;
      case 4:
        this.candidateReviewRatingStars1Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars2Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars3Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars4Ref.current.style.color = "#ffe136";
        break;
      case 5:
        this.candidateReviewRatingStars1Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars2Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars3Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars4Ref.current.style.color = "#ffe136";
        this.candidateReviewRatingStars5Ref.current.style.color = "#ffe136";
        break;
    }
    let cid = this.state.candidate_id;
    let qid = review[this.r_id]["question"]["id"];
    let result = await API.review.createRating(api_key, qid, cid, rank);
    let success = result["success"];
    if (!success) {
      toast("Couldn't rank Candidate, try again");
      return;
    }

    if (this.r_id + 1 >= review.length) {
      this.candidateRefDialogRating.current.style.display = "none";
      this.candidateRefDialogComplete.current.style.display = "block";
      this.reloadAllTab();
    } else {
      this.r_id = this.r_id + 1;
      this.candidateRefDialogRating.current.style.display = "none";
      this.loadReviewQuestion();
    }
  }

  doneCandidateReview() {
    this.candidateRefDialogComplete.current.style.display = "none";
    this.candidateRefDialog.current.style.display = "none";
    this.candidateRefDialogQuestion.current.style.display = "none";
  }

  openShare(e) {
    let candidate_id = parseInt(e.target.getAttribute("candidate-id"));
    this.setState({
      ...this.state,
      share_cid: candidate_id,
    });
    this.shareDialogRef.current.style.display = "flex";
    this.shareDialogPart1Ref.current.style.display = "block";
    this.shareDialogPart2Ref.current.style.display = "none";
  }

  openCustomCandidateThumbnail(e) {
    this.customCandidateThumbnailRef.current.style.display = "flex";
    let cid = parseInt(e.target.getAttribute("candidate-id"));
    this.setState({
      ...this.state,
      candidate_id: cid,
    });
  }

  async uploadCustomCandidateThumbnail() {
    let file = this.customCandidateThumbnailFileRef.current.files[0];
    if (file) {
      this.setState({ ...this.state, loading: true });
      let api_key = localStorage.getItem("api_key");
      //let filedata1 = await this.getFileData(file);
      //let filedata2 = filedata1.split("base64,")[1];
      let result1 = await API.file.uploadFileAsFile(api_key, file["name"], file);
      let uploadedFileName = result1["file_url"];
      let result2 = await API.candidate.setCustomThumbnail(
        api_key,
        this.state.candidate_id,
        uploadedFileName
      );
      if (result2["success"]) {
        this.setState({ ...this.state, loading: false });
        toast("Custom Thumbnail Uploaded");
        this.reloadAllTab();
        this.customCandidateThumbnailFileRef.current.value = "";
        this.customCandidateThumbnailRef.current.style.display = "none";
      } else {
        toast("Maxed Storage Reached");
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 5000);
        return;
      }
    } else {
      toast("No file selected");
    }
  }

  async getShareLink() {
    let name = this.shareDialogFullnameRef.current.value;
    let email = this.shareDialogEmailAddressRef.current.value;
    if (!name || !email) {
      toast("Enter required details");
      return;
    }
    let api_key = localStorage.getItem("api_key");
    let cid = this.state.share_cid;
    let iid = this.state.interview.id;
    let result = await API.share.get(api_key, cid, iid, name, email);
    let link = result["link"];
    localStorage.setItem("token", result["token"]);
    this.shareDialogPart1Ref.current.style.display = "none";
    this.shareDialogPart2Ref.current.style.display = "block";
    this.shareDialogLinkRef.current.value = link;
  }

  async getShareAndRateLink() {
    let name = this.shareAndRateDialogFullnameRef.current.value;
    let email = this.shareAndRateDialogEmailAddressRef.current.value;
    if (!name || !email) {
      toast("Enter required details");
      return;
    }
    let api_key = localStorage.getItem("api_key");
    let cid = this.state.share_cid;
    let iid = this.state.interview.id;
    let result = await API.shareAndRate.get(api_key, cid, iid, name, email);
    let link = result["link"];
    localStorage.setItem("token", result["token"]);
    this.shareAndRateDialogPart1Ref.current.style.display = "none";
    this.shareAndRateDialogPart2Ref.current.style.display = "block";
    this.shareAndRateDialogLinkRef.current.value = link;
  }

  copyShareLink() {
    let txt = this.shareDialogLinkRef.current.value;
    navigator.clipboard.writeText(txt);
    toast("Linked Copied");
  }

  async emailShareLink() {
    toast("Emailing Link");
    let api_key = localStorage.getItem("api_key");
    let token = localStorage.getItem("token");
    let result = await API.share.email(api_key, token);
    toast("Link Emailed");
  }

  async emailShareAndRateLink() {
    toast("Emailing Link");
    let api_key = localStorage.getItem("api_key");
    let token = localStorage.getItem("token");
    let result = await API.share.email(api_key, token);
    toast("Link Emailed");
  }

  copyShareAndRateLink() {
    let txt = this.shareAndRateDialogLinkRef.current.value;
    navigator.clipboard.writeText(txt);
    toast("Linked Copied");
  }

  copyAdvertisementLink() {
    let txt = this.advertisementDialogRefLink.current.value;
    navigator.clipboard.writeText(txt);
    toast("Linked Copied");
  }

  openShareAndRate(e) {
    let candidate_id = parseInt(e.target.getAttribute("candidate-id"));
    this.setState({
      ...this.state,
      share_cid: candidate_id,
    });
    this.shareAndRateDialogRef.current.style.display = "flex";
    this.shareAndRateDialogPart1Ref.current.style.display = "block";
    this.shareAndRateDialogPart2Ref.current.style.display = "none";
  }
  async openComments(e) {
    toast("Loading Please wait");
    var units = {
      year: 24 * 60 * 60 * 1000 * 365,
      month: (24 * 60 * 60 * 1000 * 365) / 12,
      day: 24 * 60 * 60 * 1000,
      hour: 60 * 60 * 1000,
      minute: 60 * 1000,
      second: 1000,
    };
    var rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
    var getRelativeTime = (d1, d2 = new Date()) => {
      var elapsed = d1 - d2;
      for (var u in units) {
        if (Math.abs(elapsed) > units[u] || u == "second") {
          return rtf.format(Math.round(elapsed / units[u]), u);
        }
      }
    };

    function timeSince(timeStamp) {
      var now = new Date(),
        secondsPast = (now.getTime() - timeStamp) / 1000;
      if (secondsPast < 60) {
        return parseInt(secondsPast) + "s";
      }
      if (secondsPast < 3600) {
        return parseInt(secondsPast / 60) + "m";
      }
      if (secondsPast <= 86400) {
        return parseInt(secondsPast / 3600) + "h";
      }
      if (secondsPast > 86400) {
        let day = timeStamp.getDate();
        let month = timeStamp
          .toDateString()
          .match(/ [a-zA-Z]*/)[0]
          .replace(" ", "");
        let year =
          timeStamp.getFullYear() == now.getFullYear()
            ? ""
            : " " + timeStamp.getFullYear();
        return day + " " + month + year;
      }
    }
    let candidate_id = parseInt(e.target.getAttribute("candidate-id"));
    let api_key = localStorage.getItem("api_key");
    let result = await API.comments.get(api_key, candidate_id);
    let x = result["comments"];
    let comment_data = x;
    if (x.length <= 0) {
      toast("No Comments avaliable");
      return;
    }
    let comment_users = Object.keys(x);
    this.candidateCommentsDialogContentsRef.current.innerHTML = "";
    for (let i = 0; i < comment_users.length; i++) {
      let comments = comment_data[comment_users[i]];
      let fullusername = comment_users[i];
      let commentItem = $('<div class="commentItem"></div>');
      let commentItemUsername = $(
        '<div class="commentUserName">' + fullusername + "</div>"
      );
      commentItem.append(commentItemUsername);
      let q_counter = 1;
      for (let ii = 0; ii < comments.length; ii++) {
        let comment = comments[ii];
        let candidateID = comment["candidate_id"];
        let commentQuestionID = comment["question_id"];
        let comment_text = comment["comment"];
        let comment_timestamp = comment["updated_at"];
        let ratingStarts = comment["rank"];
        let comment_timestamp_relative_date = getRelativeTime(
          +new Date(comment_timestamp)
        );

        let commentIteminfoContianer = $('<div class="infoContianer"></div>');
        let commentItemQuestionNumber = $(
          '<div class="questionNumber">Question ' + q_counter + "</div>"
        );
        let daysago = $(
          '<div class="agoTime">' + comment_timestamp_relative_date + "</div>"
        );
        let commentRating = $('<div class="commentRating"></div>');
        let commentText = $(
          '<div class="commentText">' + comment_text + "</div>"
        );

        for (let i = 0; i < ratingStarts; i++) {
          commentRating.append(
            $(
              '<span class="svg-icon svg-icon-primary svg-icon-2x" style="display:inline-block;"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><polygon points="0 0 24 0 24 24 0 24"/><path d="M12,18 L7.91561963,20.1472858 C7.42677504,20.4042866 6.82214789,20.2163401 6.56514708,19.7274955 C6.46280801,19.5328351 6.42749334,19.309867 6.46467018,19.0931094 L7.24471742,14.545085 L3.94038429,11.3241562 C3.54490071,10.938655 3.5368084,10.3055417 3.92230962,9.91005817 C4.07581822,9.75257453 4.27696063,9.65008735 4.49459766,9.61846284 L9.06107374,8.95491503 L11.1032639,4.81698575 C11.3476862,4.32173209 11.9473121,4.11839309 12.4425657,4.36281539 C12.6397783,4.46014562 12.7994058,4.61977315 12.8967361,4.81698575 L14.9389263,8.95491503 L19.5054023,9.61846284 C20.0519472,9.69788046 20.4306287,10.2053233 20.351211,10.7518682 C20.3195865,10.9695052 20.2170993,11.1706476 20.0596157,11.3241562 L16.7552826,14.545085 L17.5353298,19.0931094 C17.6286908,19.6374458 17.263103,20.1544017 16.7187666,20.2477627 C16.5020089,20.2849396 16.2790408,20.2496249 16.0843804,20.1472858 L12,18 Z" fill="#1976D1"/></g></svg></span>'
            )
          );
        }

        commentIteminfoContianer.append(commentItemQuestionNumber);
        commentIteminfoContianer.append(
          $('<span style="display:inline-block;">&nbsp;&nbsp;</span>')
        );
        commentIteminfoContianer.append(daysago);
        commentIteminfoContianer.append(commentRating);
        commentIteminfoContianer.append(
          $(
            '<div style="display: inline-block;" class="playCandidateVideo" data-candidate-id="' +
              candidateID +
              '" data-question-id="' +
              commentQuestionID +
              '" title="Play Candidate Video"></div><br>'
          )
        );
        commentIteminfoContianer.append(commentText);

        commentItem.append(commentIteminfoContianer);

        q_counter += 1;
      }

      this.candidateCommentsDialogRef.current.style.display = "flex";
      $(this.candidateCommentsDialogContentsRef.current).append(commentItem);
    }
  }

  async bespokeFlowStep1Next() {
    let api_key = localStorage.getItem("api_key");
    let v = this.bespokeFlowStep1Select.current.value;
    let result = await API.interview.setBespokeAllowCandidateInterivew(
      api_key,
      this.state.interview.id,
      v == "yes" ? 1 : 0
    );
    let success = result["success"];
    if (success) {
      toast("Saved");
      this.bespokeFlowStep1.current.style.display = "none";
      this.bespokeFlowStep4.current.style.display = "block";
    } else {
      toast("Error setting bespoke interview allowance");
    }
  }

  async outreachFlowStep1Next() {
    let api_key = localStorage.getItem("api_key");
    let v = this.outreachFlowStep1Select.current.value;
    let result = await API.interview.setOutReachAllowCandidateInterivew(
      api_key,
      this.state.interview.id,
      v === "yes" ? 1 : 0
    );
    let success = result["success"];
    if (success) {
      toast("Saved");
      this.outreachFlowStep1.current.style.display = "none";
      this.outreachFlowStep3Option.current.style.display = "block";
    } else {
      toast("Error setting outreach interview allowance");
    }
  }

  closeLibraryDialog() {
    this.librarySelectionRef.current.style.display = "none";
  }
  closeLibraryDialog2() {
    this.librarySelectionRef2.current.style.display = "none";
  }

  async bespokeFlowStepCameraSave() {
    let api_key = localStorage.getItem("api_key");
    let data = $("#captureImageCanvas").attr("src");
    let data1 = data.split("base64,")[1];
    let result = await API.file.uploadFile(api_key, ".jpg", data1);
    let file_url = result["file_url"];
    result = await API.interview.setBespokeThumbnail(
      api_key,
      this.state.interview.id,
      file_url
    );
    if (result["success"] === false) {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
    this.bespokeFlowStep2.current.style.display = "none";
    this.bespokeFlowStep3.current.style.display = "block";
    this.bespokeCustomThumbnailStep1Ref.current.style.display = "none";
  }

  async bespokseSelectImage(e) {
    let iid = parseInt(e.target.getAttribute("data-item-id"));
    let url =
      "https://www.clipdrop.io/wp-content/assets/unsplash/" + iid + ".jpg";
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setBespokeThumbnail(
      api_key,
      this.state.interview.id,
      url
    );
    let success = result["success"];
    if (success) {
      toast("Thumbnail Set");
      this.bespokeFlowStep2.current.style.display = "none";
      this.bespokeFlowStep3.current.style.display = "block";
      this.bespokeCustomThumbnailStep1Ref.current.style.display = "none";
    } else {
      toast("Error setting thumbnail");
    }
  }

  bespokeSelect1StepOnChange(e) {
    let y = e.target.value;
    if (y == "3") {
      this.bespokeCustomThumbnailStep1Ref.current.style.display = "block";
    } else {
      this.bespokeCustomThumbnailStep1Ref.current.style.display = "none";
    }
  }

  async bespokeFlowStep4Next() {
    let text = this.bespokeCustomMarketingTextStep3Ref.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setCustomMarketingText(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
    if (success) {
      toast("Marketing Text Saved");
      this.bespokeFlowStep4.current.style.display = "none";
      this.bespokeFlowStep5.current.style.display = "block";
      this.reloadAllTab();
    } else {
      toast("Error setting thumbnail");
    }
  }

  async bespokeFlowStep5Next() {
    this.bespokeFlowStep5.current.style.display = "none";
    this.bespokeFlowStep6.current.style.display = "block";
  }

  async bespokeFlowStep6Next() {
    this.bespokeFlowStep6.current.style.display = "none";
    this.bespokeFlowStep7.current.style.display = "block";
  }

  async bespokeFlowStep7Next() {
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.startInteractivity(
      api_key,
      this.state.interview.id,
      0,
      1
    );
    result = await API.interview.get(api_key, this.state.interview.id);
    let interview = result["interview"];
    this.setState({
      ...this.state,
      interview: interview,
    });
    this.bespokeFlowDialogRef.current.style.display = "none";
    this.reloadAllTab();
  }

  async bespokeFlowStepUpload() {
    let files = this.bespokeFileUploadRef.current.files;
    if (files.length <= 0) {
      toast("Please select a valid image file");
      return;
    }
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let file = files[0];
    //let data = await this.getFileData(file);
    //let filedata = data.split("base64,")[1];
    let result = await API.file.uploadFileAsFile(api_key, file["name"], file);
    let uploadedFileName = result["file_url"];
    result = await API.interview.setBespokeThumbnail(
      api_key,
      this.state.interview.id,
      uploadedFileName
    );
    let success = result["success"];
    if (success) {
      this.setState({
        ...this.state,
        loading: false,
      });
      toast("File Uploaded");
      this.bespokeFlowStep2.current.style.display = "none";
      this.bespokeFlowStep3.current.style.display = "block";
      this.bespokeCustomThumbnailStep1Ref.current.style.display = "none";
    } else {
      toast("Error Uploading File");
    }
  }

  async socialFlowStepUpload() {
    let files = this.socialFileUploadRef.current.files;
    if (files.length <= 0) {
      toast("Please select a valid image file");
      return;
    }
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let file = files[0];
    //let data = await this.getFileData(file);
    //let filedata = data.split("base64,")[1];
    let result = await API.file.uploadFileAsFile(api_key, file["name"], file);
    let uploadedFileName = result["file_url"];
    result = await API.interview.setSocialThumbnail(
      api_key,
      this.state.interview.id,
      uploadedFileName
    );
    let success = result["success"];
    if (success) {
      this.setState({
        ...this.state,
        loading: false,
      });
      toast("File Uploaded");
      this.socialFlowStep2.current.style.display = "none";
      this.socialFlowStep3.current.style.display = "block";
      this.setState({ ...this.state, canRecordVideoSocial: true });
    } else {
      toast("Error Uploading File");
    }
  }

  async outreachFlowStep4Next() {
    let text = this.outreachCustomMarketingTextStep3Ref.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setBulkMarketingText(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
    if (success) {
      result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
      });
      this.outreachFlowStep4.current.style.display = "none";
      this.outreachFlowStep5.current.style.display = "block";
      toast("Outreach Campaign Marketing Text Set");
    } else {
      toast("Error setting Outreach Marketing Text");
    }
  }

  //////////////

  async socialVideoCaptured(video_url) {
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setSocialMarketingVideo(
      api_key,
      this.state.interview.id,
      video_url
    );
    let success = result["success"];
    if (success) {
      result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
      });
      this.socialFlowStep3.current.style.display = "none";
      this.socialFlowStep4.current.style.display = "block";
      toast("Video Saved");
    } else {
      toast("Error Saving Video");
    }
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  socialFlowStep2SubStep1Capture() {
    this.setState({
      ...this.state,
      isVisible: true,
      canRecordVideoSocial: true,
    });
    this.socialFlowStep2SubStep1.current.style.display = "none";
    this.socialFlowStep2SubStepCamera.current.style.display = "block";
    this.imgSocialRef.current.style.display = "none";
    this.saveCaptureSocialButtonRef.current.style.display = "none";
  }

  socialFlowStep2SubStep1Upload() {
    this.socialFlowStep2SubStep1.current.style.display = "none";
    this.socialFlowStep2SubStepUpload.current.style.display = "block";
  }

  socialFlowStepCameraCapture() {
    this.cameraSocialRef2.current.video.style.display = "block";
    this.imgSocialRef.current.style.display = "none";
    $("#captureSocialCounterThumbnail").show();
    $("#captureSocialCounterThumbnail").html("3");
    setTimeout(() => {
      $("#captureSocialCounterThumbnail").html("2");
      setTimeout(() => {
        $("#captureSocialCounterThumbnail").html("1");
        setTimeout(() => {
          $("#captureSocialCounterThumbnail").html("3");
          $("#captureSocialCounterThumbnail").hide();
          let image_data = this.cameraSocialRef2.current.getScreenshot({
            width: 1920,
            height: 1080,
          });
          this.cameraSocialRef2.current.video.style.display = "none";
          this.imgSocialRef.current.style.display = "block";
          $("#imgSocialRef").attr({ src: image_data });
          this.setState({
            image_data: image_data,
          });
          $("#imgSocialRef").show();
          this.savecaptureImageSocialRef.current.style.display = "block";
          this.saveCaptureSocialButtonRef.current.style.display = "block";
        }, 1000);
      }, 1000);
    }, 1000);
  }

  async socialFlowStepCameraSave() {
    console.log(this.state);
    this.saveCaptureSocialButtonRef.current.style.display = "none";
    let image_data = this.state.image_data;
    this.setState({
      ...this.state,
      loading: true,
      canRecordVideoSocial: true,
    });
    let api_key = localStorage.getItem("api_key");
    let filedata = image_data.split("base64,")[1];
    let result1 = await API.file.uploadFile(
      api_key,
      "image_file.webp",
      filedata
    );
    let uploadedFileName = result1["file_url"];
    let result = await API.interview.setSocialThumbnail(
      api_key,
      this.state.interview.id,
      uploadedFileName
    );
    let success = result["success"];
    if (success) {
      api_key = localStorage.getItem("api_key");
      let result = await API.interview.get(api_key, this.state.interview.id);
      let interview = result["interview"];
      this.setState({
        ...this.state,
        interview: interview,
        loading: false,
        isVisible: true,
        canRecordVideoSocial: true,
      });
      this.socialFlowStep2.current.style.display = "none";
      this.socialFlowStep3.current.style.display = "block";
      toast("Social Post thumbnail has been updated");
    } else {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
  }

  async socialFlowStep4Next() {
    let text = this.socialCustomMarketingTextStep3Ref.current.value;
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.setSocialMarketingText(
      api_key,
      this.state.interview.id,
      text
    );
    let success = result["success"];
    let value = this.state.interview.advertise_candidate_load ?? 0;
    if (value === 0) {
      this.hideIfATS1.current.style.display = "none";
      this.hideIfATS2.current.style.display = "none";
      this.hideIfATS3.current.style.display = "none";
    } else {
      this.hideIfATS1.current.style.display = "block";
      this.hideIfATS2.current.style.display = "block";
      this.hideIfATS3.current.style.display = "block";
    }
    this.socialFlowStep4.current.style.display = "none";
    this.socialFlowStep5.current.style.display = "block";
  }
  async getAdvertimementLink() {
    let api_key = localStorage.getItem("api_key");
    let result = await API.interview.getSocialLink(
      api_key,
      this.state.interview.id
    );
    let link = result["link"];
    this.advertisementDialogRef.current.style.display = "flex";
    this.advertisementDialogRefLink.current.value = link;
  }

  async updateSocialRedirectionLink() {
    let api_key = localStorage.getItem("api_key");
    let link = this.updateSocialRedirectionLinkRef.current.value;
    if (link) {
      this.atsRefirectionLinkRef.current.innerHTML = API.validation.verifyURL(
        link
      )
        ? "<div style='fontSize:8pt;color:green'>Valid URL</div>"
        : "<div style='fontSize:8pt;color:red'>Invalid URL</div>";
    } else {
      this.atsRefirectionLinkRef.current.innerHTML = "";
    }
    let result = await API.interview.updateRedirectionLink(
      api_key,
      this.state.interview.id,
      link
    );

    if (result["success"] === false) {
      toast("Maxed Storage Reached");
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 5000);
      return;
    }
  }

  authLinkedIn() {
    let loc = window.location.href;
    let client_id = "8663tuq8r4umo5"; // TODO: NOTE: Keep Updated
    let redirectURL = "https://api.clipdrop.io/api/v1/linkedin/redirect";
    let scope = "r_emailaddress w_member_social r_basicprofile r_liteprofile";
    let state = btoa(this.state.user.id + "/./" + loc).replace("%3D", "");
    console.log(state);
    window.location.href =
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=" +
      state +
      "&scope=" +
      scope +
      "&client_id=" +
      client_id +
      "&redirect_uri=" +
      encodeURI(redirectURL);
  }

  async authFacebook() {
    let auth = await Facebook.getLogin();
    let api_key = localStorage.getItem("api_key");
    let result = await API.user.setFacebookAuth(
      api_key,
      auth.user_id,
      auth.access_token,
      auth.data_access_expiration_time
    );
    window.location.reload();
  }

  async uploadMarketingVlogVideoToLinkedin() {
    if (
      !this.state.interview.advertise_marketing_video_url ||
      this.state.interview.advertise_marketing_video_url == "" ||
      this.state.interview.advertise_marketing_video_url == null
    ) {
      toast.warning(
        "Warning! Before you can share, you must please click the start button and finish the recording process",
        "warning"
      );
      return;
    }
    this.setState({
      ...this.state,
      loading: true,
    });
    let text = this.state.interview.advertise_marketing_text;
    let video_url = this.state.interview.advertise_marketing_video_url;
    let result = await API.linkedin.uploadVideo(
      localStorage.getItem("api_key"),
      video_url,
      this.state.user.linkedin_user_access_token,
      this.state.user.linkedin_user_id
    );
    let media_id = result["data"]["id"].replace(
      "urn:li:video:",
      "urn:li:digitalmediaAsset:"
    );
    result = await API.linkedin.makePost(
      localStorage.getItem("api_key"),
      text,
      media_id,
      this.state.user.linkedin_user_id,
      this.state.user.linkedin_user_access_token
    );
    let api_key = localStorage.getItem("api_key");
    //let result2 = await API.marketing.campaign.addSendRate(api_key, this.state.campaign.id, 2);
    this.setState({
      ...this.state,
      loading: false,
    });
    toast("Post Created and Published");
  }

  getTime(dt) {
    if (!dt) return "";
    let d = DateTime.fromISO(dt).toFormat("dd-MM-yyyy HH:mm");
    if (d == "Invalid DateTime") {
      return DateTime.fromFormat(dt, "yyyy-MM-dd HH:mm:ss").toFormat(
        "dd-MM-yyyy HH:mm"
      );
    } else {
      return d;
    }
  }

  getProcessingTag(candidate) {
    if (candidate.hasViewed && candidate.closed)
      return (
        <>
          <span
            data-tip
            data-for={`tooltip-${candidate.id}-bespoke`}
            className="tagBlue"
          >
            Viewed
          </span>
          <ReactTooltip
            id={`tooltip-${candidate.id}-bespoke`}
            aria-haspopup="true"
          >
            <span>
              <strong>Viewed At:</strong>&nbsp;
              {new Date(candidate.serverTime).toLocaleString()}
            </span>
          </ReactTooltip>
        </>
      );
    if (candidate.hasViewed && !candidate.closed)
      return (
        <>
          <span
            data-tip
            data-for={`tooltip-${candidate.id}-bespoke`}
            className="tagGreen"
          >
            New View
          </span>
          <ReactTooltip
            id={`tooltip-${candidate.id}-bespoke`}
            aria-haspopup="true"
          >
            <span>
              <strong>Viewed At:</strong>&nbsp;
              {new Date(candidate.serverTime).toLocaleString()}
            </span>
          </ReactTooltip>
        </>
      );
    if (candidate.emailed) return <div className="blueBTN7">Emailed</div>;
    if (candidate.copied) return <div className="blueBTN7">Copied</div>;
    if (candidate.custom_video_url && candidate.processing_done)
      return <div className="blueBTN7">Ready to send</div>;
    if (candidate.processing_gif)
      return (
        <div className="blueBTN7">
          <img
            className="spin"
            src={play_icon_color}
            alt=""
            style={{
              transform: "translate(-3px, 3px)",
              width: "18px",
              height: "16px",
              pointerEvents: "none",
            }}
          />
          <span
            style={{ transform: "translateY(-3px)", display: "inline-block" }}
          >
            Processing
          </span>
        </div>
      );
    if (candidate.processing_video)
      return (
        <div className="blueBTN7">
          <img
            className="spin"
            src={play_icon_color}
            alt=""
            style={{
              transform: "translate(-3px, 3px)",
              width: "18px",
              height: "16px",
              pointerEvents: "none",
            }}
          />
          <span
            style={{ transform: "translateY(-3px)", display: "inline-block" }}
          >
            Processing
          </span>
        </div>
      );
  }

  getProcessingTag2(candidate) {
    if (candidate.hasViewed && candidate.closed)
      return (
        <>
          <span
            data-tip
            data-for={`tooltip-${candidate.id}-bespoke`}
            className="tagBlue"
          >
            Viewed
          </span>
          <ReactTooltip
            id={`tooltip-${candidate.id}-bespoke`}
            aria-haspopup="true"
          >
            <span>
              <strong>Viewed At:</strong>&nbsp;
              {new Date(candidate.serverTime).toLocaleString()}
            </span>
          </ReactTooltip>
        </>
      );
    if (candidate.hasViewed && !candidate.closed)
      return (
        <>
          <span
            data-tip
            data-for={`tooltip-${candidate.id}-bespoke`}
            className="tagGreen"
          >
            New View
          </span>
          <ReactTooltip
            id={`tooltip-${candidate.id}-bespoke`}
            aria-haspopup="true"
          >
            <span>
              <strong>Viewed At:</strong>&nbsp;
              {new Date(candidate.serverTime).toLocaleString()}
            </span>
          </ReactTooltip>
        </>
      );
    if (candidate.emailed) return <div className="blueBTN7">Emailed</div>;
    if (candidate.copied) return <div className="blueBTN7">Copied</div>;
    if (candidate.processing_done)
      return <div className="blueBTN7">Ready to send</div>;
    if (candidate.processing_gif)
      return (
        <div className="blueBTN7">
          <img
            className="spin"
            src={play_icon_color}
            alt=""
            style={{
              transform: "translate(-3px, 3px)",
              width: "18px",
              height: "16px",
              pointerEvents: "none",
            }}
          />
          <span
            style={{ transform: "translateY(-3px)", display: "inline-block" }}
          >
            Processing
          </span>
        </div>
      );
    if (candidate.processing_video)
      return (
        <div className="blueBTN7">
          <img
            className="spin"
            src={play_icon_color}
            alt=""
            style={{
              transform: "translate(-3px, 3px)",
              width: "18px",
              height: "16px",
              pointerEvents: "none",
            }}
          />
          <span
            style={{ transform: "translateY(-3px)", display: "inline-block" }}
          >
            Processing
          </span>
        </div>
      );
  }

  async deletedSelectCandidatesInterview() {
    // get elements
    let ids = Array.from(
      document.querySelectorAll(".interview_candidates:checked")
    ).map((item) => {
      return parseInt(item.getAttribute("candidate-id"));
    });
    // check count -> display message else continue
    if (ids.length <= 0) {
      toast("You need to select one or more candidates inorder to delete them");
      return;
    }
    // make API call and show loading
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.deleteAll(api_key, ids);
    // reload tabs
    this.reloadTab1();
    // hide loading
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  async deletedSelectCandidatesInterview2() {
    // get elements
    let ids = Array.from(
      document.querySelectorAll(".bespoke_candidates:checked")
    ).map((item) => {
      return parseInt(item.getAttribute("candidate-id"));
    });
    // check count -> display message else continue
    if (ids.length <= 0) {
      toast("You need to select one or more candidates inorder to delete them");
      return;
    }
    // make API call and show loading
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.deleteAll(api_key, ids);
    // reload tabs
    this.reloadTab2();
    // hide loading
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  async deletedSelectCandidatesInterview3() {
    // get elements
    let ids = Array.from(
      document.querySelectorAll(".outreach_candidates:checked")
    ).map((item) => {
      return parseInt(item.getAttribute("candidate-id"));
    });
    // check count -> display message else continue
    if (ids.length <= 0) {
      toast("You need to select one or more candidates inorder to delete them");
      return;
    }
    // make API call and show loading
    this.setState({
      ...this.state,
      loading: true,
    });
    let api_key = localStorage.getItem("api_key");
    let result = await API.candidate.deleteAll(api_key, ids);
    // reload tabs
    this.reloadTab3();
    // hide loading
    this.setState({
      ...this.state,
      loading: false,
    });
  }

  render() {
    let isVisible = this.state.isVisible;
    let advertiseVideoURL = this.state.interview.advertise_marketing_video_url;
    let advertisePoster = advertiseVideoURL
      ? ""
      : "https://clipdrop.io/wp-content/assets/media/images/video.png";
    let canUseAdvertise =
      this.state.questions &&
      this.state.interview.advertise_marketing_thumbnail_url != "" &&
      this.state.interview.advertise_marketing_text != "";
    return (
      <>
        <div className="candidateContianer">
          <div className="candidateContianerDropDown">
            <button
              id="interviewTab"
              ref={this.tab1btn}
              tab-id="1"
              onClick={this.openTab}
              className="folderbutton"
              style={{
                zIndex: 15,
                position: "relative",
                transform: "translateY(39px)",
              }}
            >
              <svg width="200" height="32">
                <polygon
                  points="32,0 5,32 200,32, 168, 0"
                  style={{
                    fill: "#1976D1",
                    stroke: "#EEF0F8",
                    strokeWidth: "1px",
                  }}
                />
                <text x="72" y="20" fill="#FFF">
                  Interview
                </text>
              </svg>
            </button>
            <button
              id="bespokeTab"
              ref={this.tab2btn}
              tab-id="2"
              onClick={this.openTab}
              className="folderbutton"
              style={{
                zIndex: 12,
                position: "relative",
                transform: "translateX(-32px) translateY(39px)",
              }}
            >
              <svg width="200" height="32">
                <polygon
                  points="32,0 5,32 200,32, 168, 0"
                  style={{
                    fill: "#1976D1",
                    stroke: "#EEF0F8",
                    strokeWidth: "1px",
                  }}
                />
                <text x="36" y="20" fill="#FFF">
                  Bespoke OutReach
                </text>
              </svg>
            </button>
            <button
              id="outreachTab"
              ref={this.tab3btn}
              tab-id="3"
              onClick={this.openTab}
              className="folderbutton"
              style={{
                transform: "translateX(-69px) translateY(39px)",
                zIndex: "9",
                position: "relative",
              }}
            >
              <svg width="225" height="32">
                <polygon
                  points="32,0 5,32 225,32, 192, 0"
                  style={{
                    fill: "#1976D1",
                    stroke: "#EEF0F8",
                    strokeWidth: "1px",
                  }}
                />
                <text x="40" y="20" fill="#FFF">
                  OutReach Campaign
                </text>
              </svg>
            </button>
            <button
              id="socialTab"
              ref={this.tab4btn}
              tab-id="4"
              onClick={this.openTab}
              class="folderbutton"
              style={{
                transform: "translateX(-100px) translateY(39px)",
                zIndex: "7",
                position: "relative",
              }}
            >
              <svg width="200" height="32">
                <polygon
                  points="32,0 5,32 200,32, 168, 0"
                  style={{
                    fill: "#1976D1",
                    stroke: "#EEF0F8",
                    strokeWidth: "1px",
                  }}
                />
                <text x="64" y="20" fill="#FFF">
                  Social Post
                </text>
              </svg>
            </button>
          </div>
          <div
            ref={this.tabHolder}
            className="candidateContianerTabs"
            style={{ display: "none" }}
          >
            <div ref={this.tab1}>
              <div className="tab1Container">
                <div className="selfsignupdiv">
                  <h4>Enable Self Signup</h4>
                  {this.state.questions.length >= 1 ? (
                    <>
                      <select
                        className="selfsignupselect"
                        defaultValue={this.state.interview.canSelfSignUp}
                        onChange={async (e) => {
                          let api_key = localStorage.getItem("api_key");
                          let interview_id = this.state.interview.id;
                          let value = parseInt(e.target.value);
                          let result = await API.interview.setSelfSignUp(
                            api_key,
                            interview_id,
                            value
                          );
                          this.reloadTab4();
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                      {this.state.interview.canSelfSignUp ? (
                        <input
                          className="selfsignupinput"
                          type="text"
                          value={
                            "https://api.clipdrop.io/v1/api/signup/" +
                            this.state.interview.id +
                            "/" +
                            this.state.interview.title.replace(" ", "")
                          }
                          readOnly
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <span>
                      You need to add questions before this feature can be
                      enabled
                    </span>
                  )}
                  <h4>Enable Assessment</h4>
                   <select
                        className="selfsignupselect"
                        defaultValue={this.state.interview.canSurvey}
                        onChange={async (e) => {
                          toast("Assessment Status Updated");
                          let api_key = localStorage.getItem("api_key");
                          let interview_id = this.state.interview.id;
                          let value = parseInt(e.target.value);
                          // TODO: enable/disabled assessment
                          let result = await API.interview.setSurveyEnabled(
                            api_key,
                            interview_id,
                            value
                          );
                          this.reloadTab4();
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                </div>
                { this.state.interview.canSurvey === 1 ? <>
                  <h4>Select An Assessment to assign</h4>
                  <select
                      className="selfsignupselect"
                      defaultValue={this.state.interview.canSurveyID}
                      onChange={async (e) => {
                        toast("Assessment Set");
                        let api_key = localStorage.getItem("api_key");
                        let interview_id = this.state.interview.id;
                        let value = parseInt(e.target.value);
                        // TODO: assessment to vacancy
                        let result = await API.interview.setSurveyValue(
                          api_key,
                          interview_id,
                          value
                        );
                        this.reloadTab4();
                      }}
                    >
                      <option value="0">Select A Survey</option>
                      {
                        (this.state.surveys && this.state.surveys.length >= 1) && this.state.surveys.map((survey, index)=>{
                          return <option value={survey.id.toString()}>{survey.title}</option>
                        })
                      }
                    </select>
                </> : <></>}
                <div className="tab1ContainerTitle">
                  <h4>Candidates</h4>
                  <span>{this.state.normal_candidates.length} Candidates</span>
                </div>
                <div className="tab1ContainerSearch">
                  <input
                    ref={this.tab1SearchField}
                    type="text"
                    placeholder="Search"
                  />
                  <button onClick={this.tab1Search}>Search</button>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  <div
                    onClick={this.filterInterviewCandidatesClear}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Clear Filters"
                  >
                    <MdClear style={{ transform: "translate(-4px, 2px)" }} />
                  </div>
                  &nbsp;
                  <div
                    ref={this.filter1RefCandidate}
                    onClick={this.filterInterviewCandidatesRecording}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Candidates with recorded videos"
                  >
                    <img
                      src={play_icon_color}
                      alt=""
                      style={{
                        transform: "translate(-3px, 2px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    ref={this.filter3RefCandidate}
                    onClick={this.filterInterviewCandidatesRated}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates ready to send"
                  >
                    <AiFillStar
                      color="#FFE136"
                      style={{
                        transform: "translate(-4px, 1px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    ref={this.filter4RefCandidate}
                    onClick={this.filterInterviewCandidatesNotRated}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Leads that have been viewed"
                  >
                    <AiFillStar
                      style={{
                        transform: "translate(-5px, 2px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;&nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  &nbsp;
                  <div
                    ref={this.filter2RefCandidate}
                    onClick={this.filterInterviewCandidatesNoRecording}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates with no recorded videos"
                  >
                    <img
                      src={play_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;&nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                &nbsp;
                <div
                  ref={this.filter5RefCandidate}
                  onClick={this.filterInterviewCandidatesAssessment}
                  className="filterbutton"
                  style={{ transform: "translateY(0px)" }}
                  title="Candidates with completed assessment"
                >
                  <img
                    src={assessment_icon_color}
                    alt=""
                    style={{
                      transform: "translate(-3px, 3px)",
                      width: "18px",
                      height: "16px",
                      pointerEvents: "none",
                    }}
                  />
                </div>
                  &nbsp;
                  <div
                    ref={this.filter6RefCandidate}
                    onClick={this.filterInterviewCandidatesNoAssessment}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates who did not complete an assessment"
                  >
                    <img
                      src={assessment_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;<div
                    ref={this.filter7RefCandidate}
                    onClick={this.filterInterviewCandidatesOrderByAssessmentScore}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Order by assessment score (highest to lowest)"
                  >
                    <BsArrowDownUp
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                </div>
                &nbsp;&nbsp;
                <div className="tab1ContainerCreate">
                  <button
                    onClick={this.openCreateCandidateDialog}
                    id="interviewTabCreateCandidate"
                  >
                    <BsPersonPlusFill /> Add Candidate
                  </button>
                </div>
              </div>
              <div className="candidate-pagination">
                <h4 style={{marginRight: '12px'}}>Pagination</h4>
                <SimplePagination totalPages={this.state.normal_candidates_total_pages} currentPage={this.state.normal_candidates_current_page} onChange={ async page => {
                    let api_key = localStorage.getItem("api_key") ?? "";
                    // TODO: FIX
                    let result = await API.candidate.getSearch(api_key, this.state.interview.id, this.state.normal_candidate_search, page, this.filter_rated, this.filter_not_rated, this.filter_recording_candidate, this.filter_norecording_candidate, this.filter_assessment_candidate, this.filter_no_assessment_candidate, this.filter_order_by_assessment_score);
                    let candidates = result["candidates"];
                    let totalPages = result["totalPages"];
                    this.setState({
                      ...this.state,
                      normal_candidates_current_page: page,
                      normal_candidates_total_pages: totalPages,
                      normal_candidates: candidates
                    });
                }}/>
              </div>
              <div className="tab1TableContainer normal-candidate-after-and-before">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <span className="dropdown">
                          <button>Actions</button>
                          <label>
                            <input type="checkbox" />
                            <ul>
                              <li
                                style={{ fontWeight: 400, color: "#000" }}
                                onClick={async () => {
                                  let ids = Array.from(
                                    document.querySelectorAll(
                                      ".interview_candidates[data-candidate-review=true]:checked"
                                    )
                                  ).map((item) => {
                                    return parseInt(
                                      item.getAttribute("candidate-id")
                                    );
                                  });
                                  if (ids.length === 0) {
                                    toast.error(
                                      "No candidates selected, select a candidate first"
                                    );
                                    return;
                                  }
                                  if (ids.length === 0) {
                                    toast.error("Only select 1 candidate");
                                    return;
                                  }
                                  toast.error("loading");
                                  let api_key = localStorage.getItem("api_key");
                                  let result = await API.candidate.get(
                                    api_key,
                                    ids[0]
                                  );
                                  let candidate = result["candidate"];
                                  this.updateCandidateDialogRef2.current.style.display =
                                    "flex";
                                  this.updateCandidateDialogRef2.current.scrollIntoView();
                                  this.updateCandidateDialogIDRef2.current.value =
                                    candidate["id"];
                                  this.updateCandidateDialogNameRef2.current.value =
                                    candidate["name"];
                                  this.updateCandidateDialogEmailRef2.current.value =
                                    candidate["email"];
                                  this.updateCandidateDialogLinkedInRef2.current.value =
                                    candidate["linkedin_url"];
                                  this.updateCandidateDialogPhoneRef2.current.value =
                                    candidate["phone"];
                                }}
                              >
                                Edit
                              </li>
                              <li
                                style={{ color: "#000", fontWeight: "normal" }}
                                onClick={this.deletedSelectCandidatesInterview}
                              >
                                Delete
                              </li>
                              {this.state.user.canExtract ? (
                                <li
                                  style={{ fontWeight: 400, color: "#000" }}
                                  onClick={async () => {
                                    let ids = Array.from(
                                      document.querySelectorAll(
                                        ".interview_candidates:checked"
                                      )
                                    ).map((item) => {
                                      return parseInt(
                                        item.getAttribute("candidate-id")
                                      );
                                    });
                                    if (ids.length === 0) {
                                      toast.error(
                                        "No candidates selected, select a candidate first"
                                      );
                                      return;
                                    }
                                    this.setState({
                                      ...this.state,
                                      loading: true,
                                    });
                                    let api_key =
                                      localStorage.getItem("api_key");
                                    let all_links = [];
                                    for (let i = 0; i < ids.length; i++) {
                                      let result =
                                        await API.candidate.getAllAnswers(
                                          api_key,
                                          ids[i]
                                        );
                                      let links = result["links"];
                                      links.forEach((a) => all_links.push(a));
                                    }
                                    this.allAnwserLinks.current.style.display =
                                      "flex";
                                    this.allAnwserLinksText.current.value =
                                      all_links.join("\n");
                                    this.setState({
                                      ...this.state,
                                      loading: false,
                                    });
                                  }}
                                >
                                  Extract Answers
                                </li>
                              ) : (
                                <></>
                              )}
                              <li
                                style={{ fontWeight: 400, color: "#000" }}
                                onClick={async () => {
                                  toast("loading");
                                  let ids = Array.from(
                                    document.querySelectorAll(
                                      ".interview_candidates:checked"
                                    )
                                  ).map((item) => {
                                    return parseInt(
                                      item.getAttribute("candidate-id")
                                    );
                                  });
                                  if (ids.length == 0) {
                                    toast.error(
                                      "No candidates selected, select a candidate first"
                                    );
                                    return;
                                  }
                                  this.setState({
                                    ...this.state,
                                    loading: true,
                                  });
                                  let api_key = localStorage.getItem("api_key");
                                  let all_links = [];
                                  for (let i = 0; i < ids.length; i++) {
                                    let result =
                                      await API.candidate.sendInviteLink(
                                        api_key,
                                        ids[i]
                                      );
                                  }
                                  this.setState({
                                    ...this.state,
                                    loading: false,
                                  });
                                  toast("Emails Sent to " + ids.length + " Candidates");
                                }}
                              >
                                Bulk Invite
                              </li>

                              <li
                                style={{ fontWeight: 400, color: "#000" }}
                                onClick={async () => {
                                  let ids = Array.from(
                                    document.querySelectorAll(
                                      ".interview_candidates[data-candidate-review=true]:checked"
                                    )
                                  ).map((item) => {
                                    return parseInt(
                                      item.getAttribute("candidate-id")
                                    );
                                  });
                                  if (ids.length == 0) {
                                    toast.error(
                                      "No candidates selected, select a candidate first"
                                    );
                                    return;
                                  }
                                  this.bulkLinkShareDialogRef.current.style.display =
                                    "flex";
                                }}
                              >
                                Bulk Share
                              </li>
                              <li
                                style={{ fontWeight: 400, color: "#000" }}
                                onClick={async () => {
                                  let ids = Array.from(
                                    document.querySelectorAll(
                                      ".interview_candidates[data-candidate-review=true]:checked"
                                    )
                                  ).map((item) => {
                                    return parseInt(
                                      item.getAttribute("candidate-id")
                                    );
                                  });
                                  if (ids.length == 0) {
                                    toast.error(
                                      "No candidates selected, select a candidate first"
                                    );
                                    return;
                                  }

                                  this.bulkLinkShareRateDialogRef.current.style.display =
                                    "flex";
                                }}
                              >
                                Bulk Share and Rate
                              </li>
                            </ul>
                          </label>
                        </span>
                      </th>
                      <th className="candidate-normal-candidate-header">
                        <span>CANDIDATE</span>
                        <span>REVIEW</span>
                        <span>ASSESSMENT</span>
                      </th>
                      <th style={{ maxWidth: "100px", width: "100%" }}>ID</th>
                      <th style={{ maxWidth: "150px", width: "100%" }}>
                        RATING
                      </th>
                      <th style={{ maxWidth: "250px", width: "100%" }}>
                        ACTIONS
                      </th>
                      <th style={{ maxWidth: "150px", width: "100%" }}>
                        Sign Up Date
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ overflowY:'hidden' }}>
                    {this.state.normal_candidates
                      ? this.state.normal_candidates.map((candidate, index) => {
                          return (
                            <tr key={"candidate" + candidate.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  candidate-id={candidate.id}
                                  data-candidate-review={candidate.has_review}
                                  className="interview_candidates"
                                  style={{ transform: "scale(1.5);" }}
                                />
                              </td>
                              <td style={{ maxWidth: "528px", width: "100%", maxHeight:'137px'}}>
                                <div
                                  className="sendMessageToCandidate"
                                  candidate-id={candidate.id}
                                >
                                  Send Message
                                </div>
                                {candidate.profile_image &&
                                candidate.has_review ? (
                                  <div
                                    className="candidateUserIcon2"
                                    style={{
                                      backgroundImage:
                                        "url(" + candidate.profile_image + ")",
                                    }}
                                  ></div>
                                ) : (
                                  <div className="candidateUserIcon"></div>
                                )}
                                <div className="candidateDetails2">
                                  <h4>{candidate.name}</h4>
                                  <span>{candidate.email}</span>
                                </div>
                                <div className="candidateRatingBTNContainer2">
                                  {candidate.has_review ? (
                                    candidate.rating ? (
                                      <button
                                        className="actionPlayColor"
                                        candidate-id={candidate.id}
                                        onClick={this.reviewCandidateNoRating}
                                        title="Review candidate (No rating option avaliable)"
                                      ></button>
                                    ) : (
                                      <button
                                        className="actionPlayColor"
                                        candidate-id={candidate.id}
                                        onClick={this.reviewCandidate}
                                        title="Review candidate"
                                      ></button>
                                    )
                                  ) : (
                                    <button
                                      className="actionPlayGrey"
                                      title="No review avaliable"
                                    ></button>
                                  )}
                                  {
                                    candidate.hasAssessment ? (
                                      <button
                                        className="actionAssessmentColor"
                                        title="Assessment avaliable"
                                        onClick={ async e => {
                                        // TODO: DISPLAY CANDIDATE'S ASSESSMENT ANSWERS WITH SCORING
                                          toast("Loading");
                                          let response = await fetch(API.baseURL + "/api/v1/assessment/load", {
                                            method: "POST",
                                            headers: {
                                              "Content-Type": "application/json",
                                            },
                                            body: JSON.stringify({
                                              api_key: localStorage.getItem("api_key") ?? "",
                                              candidate_id: candidate.id,
                                            }),
                                          });
                                          let data = await response.json();
                                          let details = {
                                            "candidate": candidate,
                                            "survey": data['survey'],
                                            "score": parseInt(data['score'])
                                          };
                                          this.candidateAssessment.current = details;
                                          this.showAssessmentDialog.current.style.display = "flex";   
                                          this.setState({
                                            ...this.state,
                                            "showCandidateAssessment": true
                                            });                                     
                                    }}>
                                      <span style={{ borderBottom: `3px solid ${this.getColor((candidate.assessmentScore / candidate.assessmentTotal) * 100)}`}}>{(candidate.assessmentScore / candidate.assessmentTotal) * 100}%</span>
                                    </button>
                                    ) : (
                                      <button
                                        className="actionAssessmentGrey"
                                        title="No assessment avaliable"
                                      ></button>
                                    )
                                  }
                                </div>
                              </td>
                              <td
                                style={{
                                  maxWidth: "100px",
                                  width: "100%",
                                  textAlign: "center",
                                  maxHeight:'137px'
                                }}
                              >
                                {candidate.id}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                {this.getStars(candidate.rating)}
                              </td>
                              <td
                                className="candidateMiniButtonContainer"
                              >
                                <BsPencil
                                  className="candidateMiniButton"
                                  candidate-id={candidate.id}
                                  title="Edit Candidate"
                                  onClick={this.openCandidateEdit}
                                />
                                {/*<IoIosDocument className="candidateMiniButton" candidate-id={candidate.id} candidate-cv={candidate.cv_url} title="Open CV Dialog" onClick={()=>{this.openCandidateCVDialog(candidate.id, candidate.cv_url)}} />*/}
                                <IoIosPaperPlane
                                  className="candidateMiniButton"
                                  candidate-id={candidate.id}
                                  candidate-email={candidate.email}
                                  title="Invite Candidate For Interview"
                                  onClick={this.openCandidateInterviewInvite}
                                />
                                 { (this.state.interview.canSurvey === 1 && this.state.interview.canSurveyID !== 0) && <MdOutlineAssessment
                                  className="candidateMiniButton"
                                  candidate-id={candidate.id}
                                  candidate-email={candidate.email}
                                  title="Invite Candidate For Assessment"
                                  onClick={this.openCandidateAssessmentInvite}
                                />}
                                {/* <ImBin2 className="candidateMiniButton" candidate-id={candidate.id} title="Delete Candidate" onClick={this.openCandidateDelete} /> */}
                                {candidate.has_review ? (
                                  <RiShareForward2Fill
                                    className="candidateMiniButton"
                                    candidate-id={candidate.id}
                                    onClick={this.openShare}
                                    title="Share"
                                  />
                                ) : (
                                  <></>
                                )}
                                {candidate.has_review ? (
                                  <AiFillStar
                                    className="candidateMiniButton"
                                    candidate-id={candidate.id}
                                    onClick={this.openShareAndRate}
                                    title="Share and Rate"
                                  />
                                ) : (
                                  <></>
                                )}
                                {candidate.has_review ? (
                                  candidate.had_comment ? (
                                    <FaRegCommentDots
                                      className="candidateMiniButton"
                                      candidate-id={candidate.id}
                                      onClick={this.openComments}
                                      title="View Comments"
                                    />
                                  ) : (
                                    <FaRegCommentDots
                                      className="candidateMiniButton greyOut"
                                      candidate-id={candidate.id}
                                      onClick={this.openComments}
                                      title="View Comments"
                                    />
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                {this.getTime(candidate.created_at)}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                    {!this.state.isLoaded
                      ? [0, 1, 2, 3, 4, 5].map((n, i) => {
                          return (
                            <tr key={"temp" + n}>
                              <td style={{ maxWidth: "400px", width: "100%" }}>
                                <div className="candidateUserIconPlaceHolder"></div>
                                <div className="candidateDetailPlaceHolder">
                                  <h4 className="candidateNamePlaceHolder"></h4>
                                  <span className="candidateEmailPlaceHolder"></span>
                                </div>
                                <div className="candidateRatingBTNContainerPlaceHolder">
                                  <button></button>
                                </div>
                              </td>
                              <td
                                style={{
                                  maxWidth: "100px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                className="candidateIDPlaceHolder"
                              ></td>
                              <td
                                style={{
                                  maxWidth: "350px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                {this.getStars(0)}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  width: "100%",
                                  textAlign: "right",
                                }}
                              >
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
              <div className="candidate-pagination">
                <h4 style={{marginRight: '12px'}}>Pagination</h4>
                <SimplePagination totalPages={this.state.normal_candidates_total_pages} currentPage={this.state.normal_candidates_current_page} onChange={ async page => {
                    let api_key = localStorage.getItem("api_key") ?? "";
                    let result = await API.candidate.getSearch(api_key, this.state.interview.id, this.state.normal_candidate_search, page, this.filter_rated, this.filter_not_rated, this.filter_recording_candidate, this.filter_norecording_candidate, this.filter_assessment_candidate, this.filter_no_assessment_candidate, this.filter_order_by_assessment_score);
                    let candidates = result["candidates"];
                    let totalPages = result["totalPages"];
                    this.setState({
                      ...this.state,
                      normal_candidates_current_page: page,
                      normal_candidates_total_pages: totalPages,
                      normal_candidates: candidates
                    });
                }}/>
              </div>
            </div>
            <div ref={this.tab2}>
              <div className="tab1Container">
                <div className="tab2Actions">
                  <h4>Actions</h4>
                  <div className="tab2ActionButtons">
                    {this.state.interview.bespoke_start_interactive === 1 ? (
                      <button
                        style={{ backgroundColor: "#bbbbbb" }}
                        onClick={() => {
                          toast("Already completed");
                        }}
                        id="bespokeStartFlow"
                      >
                        Start
                      </button>
                    ) : (
                      <button
                        style={{ backgroundColor: "#F07F14" }}
                        onClick={this.startTab2Flow}
                        id="bespokeStartFlow"
                      >
                        Start
                      </button>
                    )}
                    <button onClick={this.showTab2Actions} id="bespokeEditFlow">
                      Edit
                    </button>
                  </div>
                  <div className="tab2ActionContainer" ref={this.tab2Actions}>
                    <h4>Settings</h4>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>Allow candidate to participate in the interview</h4>
                      <select
                        className="bulkySelect"
                        onChange={this.allowBulkInterviewOnChange}
                        ref={this.allowBulkInterviewRef}
                        defaultValue={this.state.interview.marketing_interview}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <div>
                        <h4 className="tab2ActionContainerMaxWidthH4">
                          Custom Thumbnail Generation and Marketing Text
                        </h4>
                      </div>
                      <div>
                        <div
                          className="tab2ActionContainerMaxWidthSplit"
                          style={{ width: "68%" }}
                        >
                          <div>
                            <h4 className="tab2ActionContainerMaxWidthMiniH4">
                              Thumbnail Text
                            </h4>
                            <select
                              onChange={this.thumbnailTextOnChange}
                              ref={this.thumbnailTextRef}
                              defaultValue={
                                this.state.interview.custom_thumbnail_type
                              }
                            >
                              <option value="0">Hi {"{candidate}"}</option>
                              <option value="1">Hello {"{candidate}"}</option>
                              <option value="2">Hello {"{candidate}"}</option>
                              <option value="3">Custom Message</option>
                            </select>
                            {parseInt(
                              this.state.interview.custom_thumbnail_type
                            ) == 3 ? (
                              <div className="customMessageContainer">
                                <span>Enter a custom message:</span>
                                <input
                                  type="text"
                                  onChange={this.customMessageOnChange}
                                  ref={this.customMessageRef}
                                  defaultValue={
                                    this.state.interview
                                      .custom_thumbnail_custom_message
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>
                            <h4 className="tab2ActionContainerMaxWidthMiniH4">
                              Marketing Text
                            </h4>
                            <textarea
                              className="marketingText"
                              onChange={this.marketingTextOnChange}
                              ref={this.marketingTextRef}
                            >
                              {this.state.interview.marketing_text}
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>Allow candidate to go to an ATS link</h4>
                      <select
                        className="bulkySelect"
                        onChange={this.allowATSBespokeOnChange}
                        defaultValue={this.state.interview.ats_bespoke}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>ATS link</h4>
                      <div style={{ display: "inline-block", fontSize: "8pt" }}>
                        Example URL{" "}
                        <strong>
                          <span style={{ backgroundColor: "yellow" }}>
                            https://
                          </span>
                          www.example.com/
                        </strong>
                      </div>{" "}
                      <input
                        type="text"
                        placeholder="https://www.example.com/"
                        onChange={this.setATSBespokeOnChange}
                        style={{ width: "calc(100% - 84px)", padding: "8px" }}
                        defaultValue={this.state.interview.ats_bespoke_url}
                      />
                      <div
                        ref={this.atsBespokeLinkRef}
                        style={{ display: "block" }}
                      ></div>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>Allow candidate to see Introduction Videos</h4>
                      <select
                        className="bulkySelect"
                        onChange={this.allowBespokeITJOnChange}
                        defaultValue={this.state.interview.itj_bespoke}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="tab1ContainerTitle">
                  <h4>Candidates</h4>
                  <span>{this.state.bespoke_candidates.length} Candidates</span>
                </div>
                <div className="tab1ContainerSearch">
                  <input
                    ref={this.tab2SearchField}
                    type="text"
                    placeholder="Search"
                    onChange={this.tab2Search}
                  />
                  <button onClick={this.tab2Search}>Search</button>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  <div
                    onClick={this.filterBespokeLeadsClear}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Clear Filters"
                  >
                    <MdClear style={{ transform: "translate(-4px, 2px)" }} />
                  </div>
                  &nbsp;
                  <div
                    ref={this.filter1Ref}
                    onClick={this.filterBespokeLeadsRecording}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)", marginLeft: "4px" }}
                    title="Candidates with recorded videos"
                  >
                    <img
                      src={play_icon_color}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "18px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    ref={this.filter3Ref}
                    onClick={this.filterBespokeLeadsReadyToSend}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates ready to send"
                  >
                    <IoIosPaperPlane
                      style={{
                        transform: "translate(-4px, 0px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                  <div
                    ref={this.filter4Ref}
                    onClick={this.filterBespokeLeadsViewed}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates that have been viewed"
                  >
                    <AiFillEye
                      style={{
                        transform: "translate(-5px, 2px)",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  &nbsp;
                  <div
                    ref={this.filter2Ref}
                    onClick={this.filterBespokeLeadsNoRecording}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates with no recorded videos"
                  >
                    <img
                      src={play_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                &nbsp;
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "right",
                    border: "1px solid rgb(74, 134, 232)",
                    borderRadius: "8px",
                    padding: "5px 0px",
                    transform: "translate(5px, 21px)",
                  }}
                >
                  &nbsp;
                  <div
                    ref={this.filter7Ref}
                    onClick={this.filterBespokeLeadsNoRecording}
                    className="filterbutton"
                    style={{ transform: "translateY(0px)" }}
                    title="Candidates with no recorded videos"
                  >
                    <img
                      src={play_icon_grey}
                      alt=""
                      style={{
                        transform: "translate(-3px, 3px)",
                        width: "18px",
                        height: "16px",
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                  &nbsp;
                </div>
                <div className="tab1ContainerCreate">
                  <button
                    onClick={this.openCreateCandidateDialog2}
                    id="bespokeAddCandidate"
                  >
                    <BsPersonPlusFill /> Add Candidate
                  </button>
                </div>
              </div>
              <div className="tab1TableContainer">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <span className="dropdown">
                          <button>Actions</button>
                          <label>
                            <input type="checkbox" />
                            <ul>
                              <li
                                style={{ fontWeight: 400, color: "#000" }}
                                onClick={async () => {
                                  let ids = Array.from(
                                    document.querySelectorAll(
                                      ".bespoke_candidates:checked"
                                    )
                                  ).map((item) => {
                                    return parseInt(
                                      item.getAttribute("candidate-id")
                                    );
                                  });
                                  if (ids.length === 0) {
                                    toast.error(
                                      "No candidates selected, select a candidate first"
                                    );
                                    return;
                                  }
                                  if (ids.length === 0) {
                                    toast.error("Only select 1 candidate");
                                    return;
                                  }
                                  toast("loading");
                                  let api_key = localStorage.getItem("api_key");
                                  let result = await API.candidate.get(
                                    api_key,
                                    ids[0]
                                  );
                                  let candidate = result["candidate"];
                                  this.updateCandidateDialogRef2.current.style.display =
                                    "flex";
                                  this.updateCandidateDialogRef2.current.scrollIntoView();
                                  this.updateCandidateDialogIDRef2.current.value =
                                    candidate["id"];
                                  this.updateCandidateDialogNameRef2.current.value =
                                    candidate["name"];
                                  this.updateCandidateDialogEmailRef2.current.value =
                                    candidate["email"];
                                  this.updateCandidateDialogLinkedInRef2.current.value =
                                    candidate["linkedin_url"];
                                  this.updateCandidateDialogPhoneRef2.current.value =
                                    candidate["phone"];
                                }}
                              >
                                Edit
                              </li>
                              <li
                                style={{ color: "#000", fontWeight: "normal" }}
                                onClick={this.deletedSelectCandidatesInterview2}
                              >
                                Delete
                              </li>
                            </ul>
                          </label>
                        </span>
                      </th>
                      <th style={{ maxWidth: "400px", width: "100%" }}>
                        CANDIDATE
                        <AiFillFilter
                          onClick={this.leads1SortByDefault}
                          style={{
                            display: "inline-block",
                            transform: "translate(347px, 2px)",
                            cursor: "pointer",
                          }}
                        />
                      </th>
                      <th
                        style={{
                          maxWidth: "100px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        ID
                      </th>
                      <th style={{ maxWidth: "225px", width: "100%" }}>
                        ACTIONS
                      </th>
                      <th style={{ maxWidth: "150px", width: "100%" }}>
                        Video Creation Date
                        <AiFillFilter
                          onClick={this.leads1SortByVideoCreationDate}
                          style={{
                            display: "inline-block",
                            transform: "translate(104px, 2px)",
                            cursor: "pointer",
                          }}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bespoke_candidates
                      ? this.state.bespoke_candidates.map(
                          (candidate, index) => {
                            return (
                              <tr key={"candidate_" + candidate.id}>
                                <td>
                                  <input
                                    type="checkbox"
                                    candidate-id={candidate.id}
                                    className="bespoke_candidates"
                                    style={{ transform: "scale(1.5);" }}
                                  />
                                </td>
                                <td
                                  style={{ maxWidth: "400px", width: "100%" }}
                                >
                                  <div
                                    className="sendMessageToCandidate"
                                    candidate-id={candidate.id}
                                  >
                                    Send Message
                                  </div>
                                  <div className="candidateUserIcon3"></div>
                                  <div className="candidateDetails">
                                    <h4>{candidate.name}</h4>
                                    <span>{candidate.email}</span>
                                  </div>
                                  {/*<div className="candidateRatingBTNContainer">
                                {
                                  candidate.has_review ? (
                                    candidate.rating ? 
                                    <button className="actionPlayColor" candidate-id={candidate.id} onClick={this.reviewCandidateWithoutRating} title="Review candidate (No rating option avaliable)"></button> :
                                    <button className="actionPlayColor" candidate-id={candidate.id} onClick={this.reviewCandidate} title="Review candidate"></button> ) : 
                                    <button className="actionPlayGrey" title="No review avaliable"></button>
                                }
                              </div> */}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "143px",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  {candidate.id}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "250px",
                                    width: "100%",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                  className="candidateMiniButtonContainer"
                                >
                                  <BsPencil
                                    className="candidateMiniButton"
                                    candidate-id={candidate.id}
                                    title="Edit Candidate"
                                    onClick={this.openCandidateEdit2}
                                  />
                                  {/*<IoIosDocument className="candidateMiniButton" candidate-id={candidate.id} candidate-cv={candidate.cv_url} title="Open CV Dialog" onClick={()=>{this.openCandidateCVDialog(candidate.id, candidate.cv_url)}} />*/}
                                  {/*<ImBin2 className="candidateMiniButton" candidate-id={candidate.id} title="Delete Candidate" onClick={this.openCandidateDelete} />*/}

                                  {candidate.has_review ? (
                                    <RiShareForward2Fill
                                      className="candidateMiniButton"
                                      candidate-id={candidate.id}
                                      onClick={this.openShare}
                                      title="Share"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  {candidate.has_review ? (
                                    <AiFillStar
                                      className="candidateMiniButton"
                                      candidate-id={candidate.id}
                                      onClick={this.openShareAndRate}
                                      title="Share and Rate"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  {candidate.has_review ? (
                                    candidate.had_comment ? (
                                      <FaRegCommentDots
                                        className="candidateMiniButton"
                                        candidate-id={candidate.id}
                                        onClick={this.openComments}
                                        title="View Comments"
                                      />
                                    ) : (
                                      <FaRegCommentDots
                                        className="candidateMiniButton greyOut"
                                        candidate-id={candidate.id}
                                        onClick={this.openComments}
                                        title="View Comments"
                                      />
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {candidate.custom_video_url &&
                                  candidate.processing_done ? (
                                    <div
                                      className="candidateMiniButton special"
                                      candidate-id={candidate.id}
                                      candidate-video={
                                        candidate.custom_video_url
                                      }
                                      title="Preview Custom Video"
                                      onClick={this.openCustomVideoPreview}
                                    >
                                      <img
                                        src={play_icon_color}
                                        alt=""
                                        style={{
                                          width: "18px",
                                          height: "16px",
                                          pointerEvents: "none",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <div
                                      className="candidateMiniButton special"
                                      candidate-id={candidate.id}
                                      title="Upload/Record Custom Video"
                                      onClick={this.openCustomVideoUpload}
                                    >
                                      <img
                                        src={play_icon_grey}
                                        alt=""
                                        style={{
                                          width: "18px",
                                          height: "16px",
                                          pointerEvents: "none",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {candidate.custom_video_url &&
                                  candidate.processing_done ? (
                                    <IoIosPaperPlane
                                      className="candidateMiniButton"
                                      candidate-id={candidate.id}
                                      title="Generate Marketing Link"
                                      onClick={
                                        this.openBespokeInvitationLinkDialog
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {this.getProcessingTag(candidate)}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "150px",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  {this.getTime(candidate.videoSet)}
                                </td>
                              </tr>
                            );
                          }
                        )
                      : ""}
                    {!this.state.isLoaded
                      ? [0, 1, 2, 3, 4, 5].map((n, i) => {
                          return (
                            <tr key={"temp" + n}>
                              <td style={{ maxWidth: "400px", width: "100%" }}>
                                <div className="candidateUserIconPlaceHolder"></div>
                                <div className="candidateDetailPlaceHolder">
                                  <h4 className="candidateNamePlaceHolder"></h4>
                                  <span className="candidateEmailPlaceHolder"></span>
                                </div>
                                {/*<div className="candidateRatingBTNContainerPlaceHolder">
                                <button></button>
                                </div> */}
                              </td>
                              <td
                                style={{
                                  maxWidth: "100px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                className="candidateIDPlaceHolder"
                              ></td>
                              <td
                                style={{
                                  maxWidth: "350px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                {this.getStars(0)}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  width: "100%",
                                  textAlign: "right",
                                }}
                              >
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
            <div ref={this.tab3}>
              <div className="tab1Container">
                <div className="tab2Actions">
                  <h4>Actions</h4>
                  <div className="tab2ActionButtons">
                    {this.state.interview.bulk_marketing_start_interactive ===
                    1 ? (
                      <button
                        style={{ backgroundColor: "#bbbbbb" }}
                        onClick={() => {
                          toast("Already completed");
                        }}
                      >
                        Start
                      </button>
                    ) : (
                      <button
                        style={{ backgroundColor: "#F07F14" }}
                        onClick={this.startTab3Flow}
                      >
                        Start
                      </button>
                    )}
                    <button onClick={this.showTab3Actions}>Edit</button>
                    <span
                      style={{
                        display: "inline-block",
                        backgroundColor: "#f3f6f9",
                        color: "#4a82e4",
                        padding: "12px",
                        borderRadius: "12px",
                      }}
                    >
                      {this.state.campaignProcessingVideoStatus}
                    </span>
                  </div>
                  <div className="tab2ActionContainer" ref={this.tab3Actions}>
                    <h4>Settings</h4>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>Allow candidate to participate in the interview</h4>
                      <select
                        className="bulkySelect"
                        onChange={this.allowOutReachInterviewOnChange}
                        ref={this.allowOutReachInterviewRef}
                        defaultValue={
                          this.state.interview.bulk_marketing_interview
                        }
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <div>
                        <h4 className="tab2ActionContainerMaxWidthH4">
                          OutReach Campaign
                        </h4>
                      </div>
                      <div className="mobileView">
                        <div
                          className="tab2ActionContainerMaxWidthSplit"
                          style={{
                            minWidth: "299px",
                            width: "calc(23% - 8px)",
                            padding: "4px",
                            margin: "0px 4px",
                          }}
                        >
                          <h4 className="tab3ActionContainerMaxWidthMiniH4">
                            OutReach Campaign Video
                          </h4>
                          <video
                            className="tab2ActionContainerMaxWidthMiniImage"
                            src={this.state.interview.bulk_marketing_video_url}
                            poster={
                              this.state.interview.bulk_marketing_video_url
                                ? ""
                                : "https://clipdrop.io/wp-content/assets/media/images/video.png"
                            }
                            playsInline={true}
                            controls={true}
                          />
                          <button
                            className="candidateMiniButton bigger"
                            onClick={this.openBulkVideoDialogOptions}
                          >
                            <BsFillCameraVideoFill />
                          </button>
                        </div>
                        <div
                          className="tab2ActionContainerMaxWidthSplit"
                          style={{
                            minWidth: "512px",
                            width: "40%",
                            margin: "0px 4px",
                          }}
                        >
                          <div>
                            <h4 className="tab3ActionContainerMaxWidthMiniH4">
                              OutReach Campaign Caption Text
                            </h4>
                            <textarea
                              style={{ width: "470px", height: "123px" }}
                              onChange={this.bulkTextOnChange2}
                              ref={this.bulkText2Ref}
                            >
                              {this.state.interview.bulk_marketing_caption_text}
                            </textarea>
                            <h4 className="tab3ActionContainerMaxWidthMiniH4">
                              OutReach Campaign Custom Gif Text
                            </h4>
                            <select
                              onChange={this.thumbnailTextOutreachOnChange}
                              ref={this.thumbnailTextOutreachRef}
                              defaultValue={
                                this.state.interview
                                  .custom_thumbnail_outreach_type
                              }
                            >
                              <option value="0">Hi {"{candidate}"}</option>
                              <option value="1">Hello {"{candidate}"}</option>
                              <option value="2">Hello {"{candidate}"}</option>
                              <option value="3">Custom Message</option>
                            </select>
                            {parseInt(
                              this.state.interview
                                .custom_thumbnail_outreach_type
                            ) === 3 ? (
                              <div className="customMessageContainer">
                                <span>Enter a custom message:</span>
                                <input
                                  type="text"
                                  onChange={this.bulkTextOnChange}
                                  ref={this.bulkTextRef}
                                  defaultValue={
                                    this.state.interview.bulk_marketing_text
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>Allow candidate to go to an ATS link</h4>
                      <select
                        className="bulkySelect"
                        onChange={this.allowATSOutreachOnChange}
                        defaultValue={this.state.interview.ats_outreach}
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    <div className="tab2ActionContainerMaxWidth">
                      <h4>ATS link</h4>
                      <div style={{ display: "inline-block", fontSize: "8pt" }}>
                        Example URL{" "}
                        <strong>
                          <span style={{ backgroundColor: "yellow" }}>
                            https://
                          </span>
                          www.example.com/
                        </strong>
                      </div>
                      <input
                        type="text"
                        placeholder="https://www.example.com/"
                        onChange={this.setATSOutreachOnChange}
                        style={{ width: "calc(100% - 84px)", padding: "8px" }}
                        defaultValue={this.state.interview.ats_outreach_url}
                      />
                      <div
                        ref={this.atsOutreachLinkRef}
                        style={{ display: "block" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="tab1ContainerTitle">
                  <h4>Candidates</h4>
                  <span>
                    {this.state.outreach_candidates.length} Candidates
                  </span>
                </div>
                <div className="tab1ContainerSearch">
                  <input
                    ref={this.tab3SearchField}
                    type="text"
                    placeholder="Search"
                    onChange={this.tab3Search}
                  />
                  <button onClick={this.tab3Search}>Search</button>
                </div>
                <div className="tab1ContainerCreate">
                  <button onClick={this.openCreateCandidateDialog3}>
                    <BsPersonPlusFill /> Add Candidate
                  </button>
                </div>
              </div>
              <div className="tab1TableContainer">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <span className="dropdown">
                          <button>Actions</button>
                          <label>
                            <input type="checkbox" />
                            <ul>
                              <li
                                style={{ fontWeight: 400, color: "#000" }}
                                onClick={async () => {
                                  let ids = Array.from(
                                    document.querySelectorAll(
                                      ".outreach_candidates:checked"
                                    )
                                  ).map((item) => {
                                    return parseInt(
                                      item.getAttribute("candidate-id")
                                    );
                                  });
                                  if (ids.length === 0) {
                                    toast.error(
                                      "No candidates selected, select a candidate first"
                                    );
                                    return;
                                  }
                                  if (ids.length === 0) {
                                    toast.error("Only select 1 candidate");
                                    return;
                                  }
                                  toast("loading");
                                  let api_key = localStorage.getItem("api_key");
                                  let result = await API.candidate.get(
                                    api_key,
                                    ids[0]
                                  );
                                  let candidate = result["candidate"];
                                  this.updateCandidateDialogRef.current.style.display =
                                    "flex";
                                  this.updateCandidateDialogRef.current.scrollIntoView();
                                  this.updateCandidateDialogIDRef.current.value =
                                    candidate["id"];
                                  this.updateCandidateDialogNameRef.current.value =
                                    candidate["name"];
                                  this.updateCandidateDialogEmailRef.current.value =
                                    candidate["email"];
                                  this.updateCandidateDialogLinkedInRef.current.value =
                                    candidate["linkedin_url"];
                                  this.updateCandidateDialogPhoneRef.current.value =
                                    candidate["phone"];
                                }}
                              >
                                Edit
                              </li>
                              <li
                                style={{ color: "#000", fontWeight: "normal" }}
                                onClick={this.deletedSelectCandidatesInterview3}
                              >
                                Delete
                              </li>
                            </ul>
                          </label>
                        </span>
                      </th>
                      <th style={{ maxWidth: "400px", width: "100%" }}>
                        CANDIDATE
                      </th>
                      <th
                        style={{
                          maxWidth: "100px",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        ID
                      </th>
                      <th style={{ maxWidth: "150px", width: "100%" }}>
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.outreach_candidates
                      ? this.state.outreach_candidates.map(
                          (candidate, index) => {
                            return (
                              <tr key={"candidate" + candidate.id}>
                                <td>
                                  <input
                                    type="checkbox"
                                    candidate-id={candidate.id}
                                    className="outreach_candidates"
                                    style={{ transform: "scale(1.5);" }}
                                  />
                                </td>
                                <td
                                  style={{ maxWidth: "400px", width: "100%" }}
                                >
                                  <div
                                    className="sendMessageToCandidate"
                                    candidate-id={candidate.id}
                                  >
                                    Send Message
                                  </div>
                                  <div className="candidateUserIcon3"></div>
                                  <div className="candidateDetails">
                                    <h4>{candidate.name}</h4>
                                    <span>{candidate.email}</span>
                                  </div>
                                  {/*<div className="candidateRatingBTNContainer">
                                {
                                  candidate.has_review ? (
                                    candidate.rating ? 
                                    <button className="actionPlayColor" candidate-id={candidate.id} onClick={this.reviewCandidateWithoutRating} title="Review candidate (No rating option avaliable)"></button> :
                                    <button className="actionPlayColor" candidate-id={candidate.id} onClick={this.reviewCandidate} title="Review candidate"></button> ) : 
                                    <button className="actionPlayGrey" title="No review avaliable"></button>
                                }
                              </div> */}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "143px",
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  {candidate.id}
                                </td>
                                <td
                                  style={{
                                    maxWidth: "320px",
                                    width: "100%",
                                    textAlign: "left",
                                    verticalAlign: "top",
                                  }}
                                  className="candidateMiniButtonContainer"
                                >
                                  <BsPencil
                                    className="candidateMiniButton"
                                    candidate-id={candidate.id}
                                    title="Edit Candidate"
                                    onClick={this.openCandidateEdit}
                                  />
                                  {/*<IoIosDocument className="candidateMiniButton" candidate-id={candidate.id} candidate-cv={candidate.cv_url} title="Open CV Dialog" onClick={()=>{this.openCandidateCVDialog(candidate.id, candidate.cv_url)}} />*/}
                                  {this.state.interview
                                    .bulk_marketing_video_url &&
                                  candidate.processing_done ? (
                                    <IoIosPaperPlane
                                      className="candidateMiniButton"
                                      candidate-id={candidate.id}
                                      candidate-email={candidate.email}
                                      title="Invite Candidate"
                                      onClick={
                                        this.openCandidateInterviewInviteOut
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  {/*<ImBin2 className="candidateMiniButton" candidate-id={candidate.id} title="Delete Candidate" onClick={this.openCandidateDelete} />*/}

                                  {this.getProcessingTag2(candidate)}
                                </td>
                              </tr>
                            );
                          }
                        )
                      : ""}
                    {!this.state.isLoaded
                      ? [0, 1, 2, 3, 4, 5].map((n, i) => {
                          return (
                            <tr key={"temp" + n}>
                              <td style={{ maxWidth: "400px", width: "100%" }}>
                                <div className="candidateUserIconPlaceHolder"></div>
                                <div className="candidateDetailPlaceHolder">
                                  <h4 className="candidateNamePlaceHolder"></h4>
                                  <span className="candidateEmailPlaceHolder"></span>
                                </div>
                                {/*<div className="candidateRatingBTNContainerPlaceHolder">
                                <button></button>
                              </div>*/}
                              </td>
                              <td
                                style={{
                                  maxWidth: "100px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                className="candidateIDPlaceHolder"
                              ></td>
                              <td
                                style={{
                                  maxWidth: "350px",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                              >
                                {this.getStars(0)}
                              </td>
                              <td
                                style={{
                                  maxWidth: "150px",
                                  width: "100%",
                                  textAlign: "right",
                                }}
                              >
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                                <button className="candidateMiniButtonPlaceHolder"></button>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
            <div ref={this.tab4}>
              <h4>Actions</h4>
              <div className="tab2ActionButtons">
                {this.state.interview.social_start_interactive === 1 ? (
                  <button
                    style={{ backgroundColor: "#bbbbbb" }}
                    onClick={() => {
                      toast("Already completed");
                    }}
                  >
                    Start
                  </button>
                ) : (
                  <button
                    style={{ backgroundColor: "#F07F14" }}
                    onClick={this.startTab4Flow}
                  >
                    Start
                  </button>
                )}
                <button onClick={this.showTab4Actions}>Edit</button>
                <span
                  style={{
                    display: "inline-block",
                    backgroundColor: "#f3f6f9",
                    color: "#4a82e4",
                    padding: "12px",
                    borderRadius: "12px",
                  }}
                >
                  {this.state.socialProcessingVideoStatus}
                </span>
              </div>
              <h4 style={{ fontWeight: "500" }}>Social Post</h4>
              <div>
                <span>
                  Add a Social Video, Then you may share it via LinkedIn and/or
                  Facebook
                </span>
              </div>
              <br />
              <br />
              <div className="socialPostContainer" ref={this.tab4Actions}>
                <div className="mobileView">
                  <div
                    className="tab2ActionContainerMaxWidthSplit"
                    style={{
                      minWidth: "299px",
                      width: "calc(23% - 8px)",
                      padding: "4px",
                      margin: "0px 4px",
                    }}
                  >
                    <h4 className="tab3ActionContainerMaxWidthMiniH4">
                      Post Video
                    </h4>
                    <video
                      className="tab2ActionContainerMaxWidthMiniImage"
                      poster={advertisePoster}
                      src={advertiseVideoURL}
                      playsInline={true}
                      controls={true}
                    />
                    <button
                      className="candidateMiniButton bigger"
                      onClick={this.openSocialVideoDialogOptions}
                    >
                      <BsFillCameraVideoFill />
                    </button>
                  </div>
                  <div
                    className="tab2ActionContainerMaxWidthSplit"
                    style={{
                      minWidth: "512px",
                      width: "40%",
                      margin: "0px 4px",
                    }}
                  >
                    <div>
                      <h4
                        className="tab3ActionContainerMaxWidthMiniH4"
                        style={{ marginLeft: "20px", fontWeight: "bold" }}
                      >
                        Post Title <span style={{ fontWeight: 400 }}></span>
                      </h4>
                      <input
                        ref={this.socialCaptionRef}
                        style={{
                          width: "442px",
                          padding: "12px",
                          marginLeft: "20px",
                        }}
                        defaultValue={this.state.interview.social_caption}
                        onChange={this.socialCaptionOnChange}
                        placeholder="Title"
                      />
                      <h4
                        className="tab3ActionContainerMaxWidthMiniH4"
                        style={{ marginLeft: "20px", fontWeight: "bold" }}
                      >
                        Post Text
                      </h4>
                      <textarea
                        className="advertisemarketingText"
                        onChange={this.socialTextOnChange}
                        ref={this.socialTextRef}
                        defaultValue={
                          this.state.interview.advertise_marketing_text
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="socialPostATSContainer">
                  <span>Receive Candidates Into:</span>
                  <select
                    defaultValue={this.state.interview.advertise_candidate_load}
                    onChange={async (e) => {
                      let value = parseInt(e.target.value);
                      let api_key = localStorage.getItem("api_key");
                      let result = await API.interview.AdvertiseCandidateLoad(
                        api_key,
                        this.state.interview.id,
                        value
                      );
                      toast("Updated");
                    }}
                  >
                    <option value="0">CRM/ATS</option>
                    <option value="1">Interview (self signup)</option>
                  </select>
                  <div>
                    <span>User Redirection Link</span>
                    <div style={{ display: "inline-block", fontSize: "8pt" }}>
                      Example URL{" "}
                      <strong>
                        <span style={{ backgroundColor: "yellow" }}>
                          https://
                        </span>
                        www.example.com/
                      </strong>
                    </div>
                    <input
                      type="text"
                      placeholder="https://www.example.com/"
                      ref={this.updateSocialRedirectionLinkRef}
                      onChange={this.updateSocialRedirectionLink}
                      defaultValue={
                        this.state.interview.advertise_marketing_redirect_url
                      }
                    />
                    <div
                      ref={this.atsRefirectionLinkRef}
                      style={{ display: "block" }}
                    ></div>
                    <span style={{ fontSize: "8pt", color: "#B5B5C3" }}>
                      We will redirect the candidate to this url
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ textAlign: "center" }}>
                  {canUseAdvertise ? (
                    <button
                      className="blueButton"
                      onClick={this.getAdvertimementLink}
                    >
                      Get Advertisement link
                    </button>
                  ) : (
                    <button className="greyButton">
                      Add Questions, a redirect link, a thumbnail and video for
                      marketing to be able to get a advertise link
                    </button>
                  )}
                  <span style={{ fontSize: "8pt", color: "#B5B5C3" }}>
                    click this button to generate a advertise link
                  </span>
                </div>
                <div style={{ textAlign: "center" }}>
                  <div style={{ textAlign: "center" }}>
                    <div
                      style={{
                        margin: "12px auto",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      <button
                        className="blueButton"
                        style={{
                          margin: "12px 8px",
                          display: "inline-block",
                          width: "48%",
                        }}
                        onClick={this.uploadMarketingVlogVideoToLinkedin}
                        ref={this.specialLinkedInRef}
                      >
                        Share on LinkedIn
                      </button>
                      <button
                        className="blueButton"
                        style={{
                          margin: "12px 8px",
                          display: "inline-block",
                          width: "48%",
                        }}
                        onClick={this.showPages}
                        ref={this.specialFacebookRef}
                      >
                        Share on Facebook
                      </button>
                    </div>
                    <div ref={this.hasLinkedInAuthRef}>
                      <span>
                        In order to use Social Vlog with LinkedIn You need to
                        sign and grant access to the ClipDrop App
                      </span>
                      <br />
                      <br />
                      <button
                        className="linkedin_signin_button"
                        onClick={this.authLinkedIn}
                      >
                        <FaLinkedinIn
                          style={{ transform: "translateY(3px)" }}
                        />
                        &nbsp;&nbsp;SIGN IN WITH LINKEDIN
                      </button>
                    </div>
                    <div ref={this.hasFacebookAuthRef}>
                      <span>
                        In order to use Social Vlog with Facebook You need to
                        sign and grant access to the ClipDrop App
                      </span>
                      <br />
                      <br />
                      <button
                        className="linkedin_signin_button"
                        onClick={this.loginFacebook}
                      >
                        <FaFacebook style={{ transform: "translateY(3px)" }} />
                        &nbsp;&nbsp;SIGN IN WITH FACEBOOK
                      </button>
                    </div>
                  </div>
                  <span style={{ fontSize: "8pt", color: "#B5B5C3" }}>
                    Share Buttons will be active once you have added Questions,
                    a thumbnail and video for marketing
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref={this.createCandidateDialogRef} className="dialogNew extend">
          <div>
            <h4>Add Candidate</h4>
            <div className="inputForm">
              <input
                ref={this.createCandidateDialogNameRef}
                type="text"
                placeholder="Candidate's Name (Required)"
              />
              <input
                ref={this.createCandidateDialogEmailRef}
                type="text"
                placeholder="Candidate's Email (Required)"
              />
              <input
                ref={this.createCandidateDialogLinkedInRef}
                type="text"
                placeholder="Candidate's LinkedIn Profile URL (Optional)"
              />
              <input
                ref={this.createCandidateDialogPhoneRef}
                type="text"
                placeholder="Candidate's Phone Number (Optional)"
              />
            </div>
            <div>
              <button onClick={this.createCandidate}>Create</button>
              <button
                onClick={() => {
                  this.createCandidateDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.createCandidateDialogRef2} className="dialogNew extend">
          <div>
            <h4>Add Candidate</h4>
            <div className="inputForm">
              <input
                ref={this.createCandidateDialogNameRef2}
                type="text"
                placeholder="Candidate's Name (Required)"
              />
              <input
                ref={this.createCandidateDialogEmailRef2}
                type="text"
                placeholder="Candidate's Email (Optional)"
              />
              <input
                ref={this.createCandidateDialogLinkedInRef2}
                type="text"
                placeholder="Candidate's LinkedIn Profile URL (Optional)"
              />
              <input
                ref={this.createCandidateDialogPhoneRef2}
                type="text"
                placeholder="Candidate's Phone Number (Optional)"
              />
            </div>
            <div>
              <button onClick={this.createCandidate2}>Create</button>
              <button
                onClick={() => {
                  this.createCandidateDialogRef2.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.createCandidateDialogRef3} className="dialogNew extend">
          <div>
            <h4>Add Candidate (OutReach campaign)</h4>
            <div className="inputForm">
              <input
                ref={this.createCandidateDialogNameRef3}
                type="text"
                placeholder="Candidate's Name (Required)"
              />
              <input
                ref={this.createCandidateDialogEmailRef3}
                type="text"
                placeholder="Candidate's Email (Optional)"
              />
              <input
                ref={this.createCandidateDialogLinkedInRef3}
                type="text"
                placeholder="Candidate's LinkedIn Profile URL (Optional)"
              />
              <input
                ref={this.createCandidateDialogPhoneRef3}
                type="text"
                placeholder="Candidate's Phone Number (Optional)"
              />
            </div>
            <div>
              <button onClick={this.createCandidate3}>Create</button>
              <button
                onClick={() => {
                  this.createCandidateDialogRef3.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.updateCandidateDialogRef} className="dialogNew extend">
          <div>
            <h4>Update Candidate</h4>
            <div className="inputForm">
              <input ref={this.updateCandidateDialogIDRef} type="hidden" />
              <input
                ref={this.updateCandidateDialogNameRef}
                type="text"
                placeholder="Candidate's Name (Required)"
              />
              <input
                ref={this.updateCandidateDialogEmailRef}
                type="text"
                placeholder="Candidate's Email (Required)"
              />
              <input
                ref={this.updateCandidateDialogLinkedInRef}
                type="text"
                placeholder="Candidate's LinkedIn Profile URL (Optional)"
              />
              <input
                ref={this.updateCandidateDialogPhoneRef}
                type="text"
                placeholder="Candidate's Phone Number (Optional)"
              />
            </div>
            <div>
              <button onClick={this.updateCandidate}>Update</button>
              <button
                onClick={() => {
                  this.updateCandidateDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.updateCandidateDialogRef2} className="dialogNew extend">
          <div>
            <h4>Update Candidate</h4>
            <div className="inputForm">
              <input ref={this.updateCandidateDialogIDRef2} type="hidden" />
              <input
                ref={this.updateCandidateDialogNameRef2}
                type="text"
                placeholder="Candidate's Name (Required)"
              />
              <input
                ref={this.updateCandidateDialogEmailRef2}
                type="text"
                placeholder="Candidate's Email (Required)"
              />
              <input
                ref={this.updateCandidateDialogLinkedInRef2}
                type="text"
                placeholder="Candidate's LinkedIn Profile URL (Optional)"
              />
              <input
                ref={this.updateCandidateDialogPhoneRef2}
                type="text"
                placeholder="Candidate's Phone Number (Optional)"
              />
            </div>
            <div>
              <button onClick={this.updateCandidate2}>Update</button>
              <button
                onClick={() => {
                  this.updateCandidateDialogRef2.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.updateCandidateDialogRef3} className="dialogNew extend">
          <div>
            <h4>Update Candidate</h4>
            <div className="inputForm">
              <input ref={this.updateCandidateDialogIDRef3} type="hidden" />
              <input
                ref={this.updateCandidateDialogNameRef3}
                type="text"
                placeholder="Candidate's Name (Required)"
              />
              <input
                ref={this.updateCandidateDialogEmailRef3}
                type="text"
                placeholder="Candidate's Email (Required)"
              />
              <input
                ref={this.updateCandidateDialogLinkedInRef3}
                type="text"
                placeholder="Candidate's LinkedIn Profile URL (Optional)"
              />
              <input
                ref={this.updateCandidateDialogPhoneRef3}
                type="text"
                placeholder="Candidate's Phone Number (Optional)"
              />
            </div>
            <div>
              <button onClick={this.updateCandidate3}>Update</button>
              <button
                onClick={() => {
                  this.updateCandidateDialogRef3.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.cvDialogRef} className="dialogNew extend">
          <div>
            <h4>Candidate's CV</h4>
            <div>
              <button onClick={this.uploadCandidateCV}>Upload</button>
              <button
                onClick={this.previewCandidateCV}
                ref={this.cvDialogPreviewRef}
              >
                Preview
              </button>
              <button onClick={this.deleteCandidateCV}>Delete</button>
              <button
                onClick={() => {
                  this.cvDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.cvPreviewDialogRef} className="dialogNew extend">
          <div>
            <h4>Candidate's CV Preview</h4>
            <div>
              <iframe
                className="fitFrame"
                src=""
                ref={this.cvPreviewIFrameRef}
                frameborder="0"
              ></iframe>
            </div>
            <div>
              <button
                onClick={() => {
                  this.cvPreviewDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.cvUploadDialogRef} className="dialogNew extend">
          <div>
            <h4>Upload a Candidate's CV</h4>
            <div>
              <span>Select a document to upload</span>
              <br />
              <br />
              <input
                type="file"
                ref={this.cvImageUploadFileRef}
                accept=".pdf, .docx"
              />
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.uploadCVFile}>Upload</button>
              <button
                onClick={() => {
                  this.cvUploadDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.candidateInviteLinkDialogRef}
          className="dialogNew extend"
        >
          <div>
            <h4>Candidate's Interview Invitation Link</h4>
            <div>
              <span>
                You may copy and send this link to your candidate, which will
                allow them to start the interview process
              </span>
              <br />
              <br />
              <input
                type="text"
                className="copyText"
                ref={this.candidateInvitationLinkRef}
                readOnly={true}
              />
            </div>
            <h4>Email Subject Line</h4>
            <div>
              <input
                type="text"
                style={{
                  width: "calc(100% - 18px)",
                  padding: "8px",
                  marginBottom: "8px",
                }}
                ref={this.candidateMarketingInviationEmailText2}
                placeholder="enter subject line here"
              />
            </div>
            <div>
              <button
                onClick={() => {
                  let txt = this.candidateInvitationLinkRef.current.value;
                  try {
                    window.navigator.clipboard.writeText(txt);
                  } catch (e) {
                    console.log(e);
                  }
                  try {
                    window.clipboardData.setData("Text", txt);
                  } catch (e) {
                    console.log(e);
                  }
                  toast("Link copied to your clipboard");
                }}
              >
                Copy link to clipboard
              </button>
              <button
                onClick={async () => {
                  this.setState({ ...this.state, loading: true });
                  let cid = parseInt(
                    this.candidateInvitationLinkRef.current.getAttribute(
                      "candidate-id"
                    )
                  );
                  let subject =
                    this.candidateMarketingInviationEmailText2.current.value;
                  let api_key = localStorage.getItem("api_key");
                  let result = await API.candidate.emailInvitationLink(
                    api_key,
                    cid,
                    subject
                  );
                  this.candidateInviteLinkDialogRef.current.style.display =
                    "none";
                  this.setState({ ...this.state, loading: false });
                  toast("Link sent to candidate via Email");
                }}
              >
                Email link to candidate
              </button>
              <button
                onClick={() => {
                  this.candidateInviteLinkDialogRef.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.candidateInviteLinkDialogRef2}
          className="dialogNew extend"
        >
          <div>
            <h4>Candidate's Interview Invitation Link</h4>
            <div>
              <span>
                You may copy and send this link to your candidate, which will
                allow them to start the interview process
              </span>
              <br />
              <br />
              <input
                type="text"
                className="copyText"
                ref={this.candidateInvitationLinkRef2}
                readOnly={true}
              />
            </div>
            <h4>Email Subject Line</h4>
            <div>
              <input
                type="text"
                style={{
                  width: "calc(100% - 18px)",
                  padding: "8px",
                  marginBottom: "8px",
                }}
                ref={this.candidateMarketingInviationEmailText22}
                placeholder="enter subject line here"
              />
            </div>
            <div>
              <button
                onClick={() => {
                  let txt = this.candidateInvitationLinkRef2.current.value;
                  try {
                    window.navigator.clipboard.writeText(txt);
                  } catch (e) {}
                  try {
                    window.clipboardData.setData("Text", txt);
                  } catch (e) {}
                  toast("Link copied to your clipboard");
                }}
              >
                Copy link to clipboard
              </button>
              <button
                onClick={async () => {
                  this.setState({ ...this.state, loading: true });
                  let cid = parseInt(
                    this.candidateInvitationLinkRef2.current.getAttribute(
                      "candidate-id"
                    )
                  );
                  let subject =
                    this.candidateMarketingInviationEmailText22.current.value;
                  let api_key = localStorage.getItem("api_key");
                  let result = await API.candidate.emailInvitationLink2(
                    api_key,
                    cid,
                    subject
                  );
                  this.candidateInvitationLinkRef2.current.style.display =
                    "none";
                  this.setState({ ...this.state, loading: false });
                  this.reloadTab2();
                  toast("Link sent to candidate via Email");
                }}
              >
                Email link to candidate
              </button>
              <button
                onClick={() => {
                  this.candidateInviteLinkDialogRef2.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.baseThumbnailDialog} className="dialogNew extend">
          <div>
            <h4>Base Thumbnail</h4>
            <div>
              <span>Select an option</span>
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.showBaseImageUpload}>Upload</button>
              <button onClick={this.showBaseImageCapture}>Capture</button>
              <button
                onClick={() => {
                  this.baseThumbnailDialog.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.baseThumbnailDialogUpload} className="dialogNew extend">
          <div>
            <h4>Base Thumbnail</h4>
            <div>
              <span>Select an option</span>
              <br />
              <br />
              <input
                type="file"
                ref={this.imageUploadFileRef}
                accept=".png, .jpg"
              />
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.uploadBaseThumbnailFile}>Upload</button>
              <button
                onClick={() => {
                  this.baseThumbnailDialogUpload.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.baseThumbnailDialogCapture} className="dialogNew extend">
          <div>
            <h4>Base Thumbnail</h4>
            <div>
              <span>Click Capture</span>
              <br />
              <br />
              <div>
                <img src="" ref={this.imgRef} style={{ width: "100%" }} />
                {isVisible && (
                  <Webcam
                    style={{ width: "100%" }}
                    ref={this.cameraRef}
                    videoConstraints={{ facingMode: "user" }}
                  />
                )}
                <div className="cameraCounter" ref={this.cameraCounterRef}>
                  3
                </div>
              </div>
            </div>
            <div>
              <button onClick={this.captureImage}>Capture Image</button>
              <button
                onClick={this.savecaptureImage}
                ref={this.savecaptureImageRef}
              >
                Save
              </button>
              <button
                onClick={() => {
                  this.baseThumbnailDialogCapture.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.bulkThumbnailDialog} className="dialogNew extend">
          <div>
            <h4>OutReach Campaign Thumbnail</h4>
            <div>
              <span>Select an option</span>
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.showBulkImageUpload}>Upload</button>
              <button onClick={this.showBulkImageCapture}>Capture</button>
              <button
                onClick={() => {
                  this.bulkThumbnailDialog.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.socialThumbnailDialog} className="dialogNew extend">
          <div>
            <h4>Social Post Thumbnail</h4>
            <div>
              <span>Select an option</span>
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.showSocialImageUpload}>Upload</button>
              <button onClick={this.showSocialImageCapture}>Capture</button>
              <button
                onClick={() => {
                  this.socialThumbnailDialog.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.socialThumbnailDialogUpload}
          className="dialogNew extend"
        >
          <div>
            <h4>Social Post Thumbnail</h4>
            <div>
              <span>Select an option</span>
              <br />
              <br />
              <input
                type="file"
                ref={this.imageUploadFileSocialRef}
                accept=".png, .jpg"
              />
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.uploadSocialThumbnailFile}>Upload</button>
              <button
                onClick={() => {
                  this.socialThumbnailDialogUpload.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.socialThumbnailDialogCapture}
          className="dialogNew extend"
        >
          <div>
            <h4>Social Post Thumbnail</h4>
            <div>
              <span>Click Capture</span>
              <br />
              <br />
              <div>
                <img src="" ref={this.imgSocialRef} style={{ width: "100%" }} />
                {isVisible && (
                  <Webcam
                    style={{ width: "100%" }}
                    ref={this.cameraSocialRef}
                    videoConstraints={{ facingMode: "user" }}
                  />
                )}
                <div
                  className="cameraCounter"
                  ref={this.cameraCounterSocialRef}
                >
                  3
                </div>
              </div>
            </div>
            <div>
              <button onClick={this.captureImageSocial}>Capture Image</button>
              <button
                onClick={this.savecaptureImageSocial}
                ref={this.savecaptureImageSocialRef}
              >
                Save
              </button>
              <button
                onClick={() => {
                  this.socialThumbnailDialogCapture.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.bulkThumbnailDialogUpload} className="dialogNew extend">
          <div>
            <h4>OutReach Campaign Thumbnail</h4>
            <div>
              <span>Select an option</span>
              <br />
              <br />
              <input
                type="file"
                ref={this.imageUploadFileBulkRef}
                accept=".png, .jpg"
              />
              <br />
              <br />
            </div>
            <div>
              <button onClick={this.uploadBulkThumbnailFile}>Upload</button>
              <button
                onClick={() => {
                  this.bulkThumbnailDialogUpload.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.bulkThumbnailDialogCapture} className="dialogNew extend">
          <div>
            <h4>OutReach Campaign Thumbnail</h4>
            <div>
              <span>Click Capture</span>
              <br />
              <br />
              <div>
                <img src="" ref={this.imgBulkRef} style={{ width: "100%" }} />
                {isVisible && (
                  <Webcam
                    style={{ width: "100%" }}
                    ref={this.cameraBulkRef}
                    videoConstraints={{ facingMode: "user" }}
                  />
                )}
                <div className="cameraCounter" ref={this.cameraCounterBulkRef}>
                  3
                </div>
              </div>
            </div>
            <div>
              <button onClick={this.captureImageBulk}>Capture Image</button>
              <button
                onClick={this.savecaptureImageBulk}
                ref={this.savecaptureImageBulkRef}
              >
                Save
              </button>
              <button
                onClick={() => {
                  this.bulkThumbnailDialogCapture.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.customCandidateVideoRef} className="dialogNew extend">
          <div>
            <h4>Custom Candidate Video</h4>
            <div>
              <div>
                <VideoRecordComponent
                  ref={this.customCandidateVideoelementRef}
                  nopreview={true}
                  onnopreview={async () => {
                    this.customCandidateVideoRef.current.style.display = "none";
                    let cid = parseInt(
                      this.customCandidateVideoRef.current.getAttribute(
                        "candidate-id"
                      )
                    );
                    let api_key = localStorage.getItem("api_key");
                    await API.candidate.setProcessing(api_key, cid);
                    this.reloadAllTab();
                    setTimeout(() => {
                      this.reloadAllTab();
                    }, 1000);
                    setTimeout(() => {
                      this.reloadAllTab();
                    }, 2000);
                    setTimeout(() => {
                      this.reloadAllTab();
                    }, 5000);
                    setTimeout(() => {
                      this.reloadAllTab();
                    }, 10000);
                  }}
                  fixWidth={false}
                  watermark_url={this.state.user.watermark_url}
                  onclose={() => {
                    this.customCandidateVideoRef.current.style.display = "none";
                  }}
                  oncompletion={this.setCandidateCustomVideo}
                ></VideoRecordComponent>
              </div>
            </div>
          </div>
        </div>
        <div ref={this.socialVideoRef} className="dialogNew extend">
          <div>
            <h4>Social Post Video</h4>
            <div>
              <div>
                <VideoRecordComponent
                  ref={this.socialVideoDialogElementRef}
                  fixWidth={false}
                  watermark_url={this.state.user.watermark_url}
                  onclose={() => {
                    this.socialVideoRef.current.style.display = "none";
                  }}
                  oncompletion={this.setSocialPostVideo}
                ></VideoRecordComponent>
              </div>
            </div>
          </div>
        </div>
        <div ref={this.socialVideoOptionsRef} className="dialogNew extend">
          <div>
            <h4>Select Social Post Video Option</h4>
            <div>
              <button onClick={this.openSocialVideoUploadDialog}>Upload</button>
              <button onClick={this.openSocialVideoDialog}>Record</button>
              <button onClick={this.openLibraryDialog2}>Library</button>
              <button
                onClick={() => {
                  this.socialVideoOptionsRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.socialVideoUploadRef} className="dialogNew extend">
          <div>
            <h4>Upload Social Post Video Option</h4>
            <div>
              <FileUploadComponnent
                vtype={this.getVType}
                onclose={() => {
                  this.socialVideoUploadRef.current.style.display = "none";
                }}
                oncompletion={async (video_url) => {
                  this.setSocialPostVideo(video_url);
                }}
              ></FileUploadComponnent>
              <button
                onClick={() => {
                  this.socialVideoUploadRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.bulkVideoOptionsRef} className="dialogNew extend">
          <div>
            <h4>Select Camapign Marketing Video Option</h4>
            <div>
              <button onClick={this.openBulkVideoUploadDialog}>Upload</button>
              <button onClick={this.openBulkVideoDialog}>Record</button>
              <button onClick={this.openLibraryDialog}>Library</button>
              <button
                onClick={() => {
                  this.bulkVideoOptionsRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.customCandidateVideoPreviewRef}
          className="dialogNew extend"
        >
          <div>
            <h4>Preview Candidate's Custom Video</h4>
            <div>
              <div>
                <video
                  src=""
                  ref={this.customCandidateVideoPreviewVideoRef}
                  style={{ width: "100%" }}
                  playsInline={true}
                  controls={true}
                ></video>
              </div>
            </div>
            <div>
              <button onClick={this.openCustomVideoUploadRe}>
                Re-Record Video
              </button>
              <button
                onClick={() => {
                  this.customCandidateVideoPreviewVideoRef.current.pause();
                  this.customCandidateVideoPreviewRef.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.candidateMarketingInviationLink}
          className="dialogNew extend"
        >
          <div>
            <h4>Candidate Bespoke Invitation Link</h4>
            <h4>Email Subject Line</h4>
            <div>
              <input
                type="text"
                style={{
                  width: "calc(100% - 18px)",
                  padding: "8px",
                  marginBottom: "8px",
                }}
                ref={this.candidateMarketingInviationEmailText}
                placeholder="enter subject line here"
              />
            </div>
            <h4>Email Body</h4>
            <div>
              <textarea
                className="MarketingTextInvition"
                ref={this.candidateMarketingInviationLinkText}
              ></textarea>
            </div>
            <div>
              <span style={{ display: "inline-block" }}>
                For Whatsapp Share
              </span>
              <ToggleComponent
                className="togglefixcandidate"
                defaultValue={1}
                onLabel="YES"
                offLabel="NO"
                onChange={async (value) => {
                  this.setState({
                    ...this.state,
                    loading: true,
                  });
                  let api_key = localStorage.getItem("api_key");
                  let cid = parseInt(
                    this.candidateMarketingInviationLinkText.current.getAttribute(
                      "candidate-id"
                    )
                  );
                  let result = "";
                  let text = "";
                  if (value == 0 || value == "0") {
                    result = await API.candidate.getBespokeInvitation(
                      api_key,
                      cid
                    );
                    text = result["text"];
                    this.candidateMarketingInviationLink.current.style.display =
                      "flex";
                    this.candidateMarketingInviationLinkText.current.value =
                      text;
                    this.candidateMarketingInviationLinkText.current.setAttribute(
                      "candidate-id",
                      cid
                    );
                  } else {
                    result = await API.candidate.getBespokeInvitation(
                      api_key,
                      cid,
                      1
                    );
                    text = result["text"];
                    this.candidateMarketingInviationLink.current.style.display =
                      "flex";
                    this.candidateMarketingInviationLinkText.current.value =
                      text;
                    this.candidateMarketingInviationLinkText.current.setAttribute(
                      "candidate-id",
                      cid
                    );
                  }
                  this.setState({
                    ...this.state,
                    loading: false,
                  });
                }}
              ></ToggleComponent>
            </div>
            <div>
              <button onClick={this.copyInvitationLink2}>
                Copy Text to Clipboard
              </button>
              <button
                onClick={async () => {
                  this.setState({ ...this.state, loading: true });
                  let cid = parseInt(
                    this.candidateMarketingInviationLinkText.current.getAttribute(
                      "candidate-id"
                    )
                  );
                  let subject =
                    this.candidateMarketingInviationEmailText.current.value;
                  let custom_text =
                    this.candidateMarketingInviationLinkText.current.value;
                  let api_key = localStorage.getItem("api_key");
                  let result = await API.candidate.emailInvitationLink3(
                    api_key,
                    cid,
                    subject,
                    custom_text
                  );
                  this.candidateInvitationLinkRef2.current.style.display =
                    "none";
                  this.setState({ ...this.state, loading: false });
                  this.reloadTab2();
                  toast("Link sent to candidate via Email");
                }}
              >
                Email link to candidate
              </button>
              <button
                onClick={() => {
                  this.candidateMarketingInviationLink.current.style.display =
                    "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.bulkVideoDialogRef} className="dialogNew extend">
          <div>
            <h4>OutReach Campaign Video</h4>
            <div>
              <div>
                <VideoRecordComponent
                  ref={this.bulkVideoDialogElementRef}
                  fixWidth={false}
                  watermark_url={this.state.user.watermark_url}
                  onclose={() => {
                    this.bulkVideoDialogRef.current.style.display = "none";
                  }}
                  oncompletion={(video_url) => {
                    this.setBulkMarketingVideo(video_url, () => {
                      this.reloadAllTab();
                      this.bulkMarketingVideoLibraryRef.current.showDialog();
                      this.bulkMarketingVideoLibraryRef.current.setVideo(
                        video_url
                      );
                      this.bulkMarketingVideoLibraryRef.current.setStep(0);
                    });
                  }}
                ></VideoRecordComponent>
              </div>
            </div>
          </div>
        </div>
        <div ref={this.bulkVideoUploadDialogRef} className="dialogNew extend">
          <div>
            <h4>OutReach Campaign Video</h4>
            <div>
              <FileUploadComponnent
                vtype={this.getVType}
                onclose={() => {
                  this.bulkVideoUploadDialogRef.current.style.display = "none";
                }}
                oncompletion={async (video_url) => {
                  this.setBulkMarketingVideo(video_url);
                }}
              ></FileUploadComponnent>
            </div>
          </div>
        </div>
        <div ref={this.candidateRefDialog} className="dialogNew extend">
          <div>
            <div ref={this.candidateRefDialogNotice}>
              <h4>Candidate Review</h4>
              <div
                className="closeRating"
                onClick={() => {
                  this.candidateRefDialog.current.style.display = "none";
                }}
              >
                X
              </div>
              <span>You will now be reviewing the selected candidate</span>
              &nbsp;
              <span>You will be shown the question-answer pair.</span>&nbsp;
              <span>click start when you're ready</span>&nbsp;
              <button onClick={this.startCandidateReview}>Start</button>
            </div>
            <div ref={this.candidateRefDialogQuestion}>
              <h4 ref={this.candidateRefDialogQuestionTitle}></h4>
              <div
                className="closeRating"
                onClick={() => {
                  this.candidateRefDialogQuestionVideo.current.pause();
                  this.candidateRefDialog.current.style.display = "none";
                }}
              >
                X
              </div>
              <span ref={this.candidateRefDialogQuestionText}>
                TEXT QUESTION HERE
              </span>
              <video
                ref={this.candidateRefDialogQuestionVideo}
                style={{
                  borderRadius: "8px",
                  width: "480px",
                  maxHeight: "360px",
                }}
                src=""
                autoPlay={true}
                playsInline={true}
                playsInline={true}
                controls={true}
              ></video>
              <div
                style={{ textAlign: "center", margin: "12px 0px" }}
                ref={this.candidateRefDialogQuestionVideoControls}
              >
                <button
                  onClick={this.playQuestionVideo}
                  className="miniBlueButton"
                >
                  Play
                </button>
                <button
                  onClick={this.pauseQuestionVideo}
                  className="miniBlueButton"
                >
                  Pause
                </button>
                <button
                  onClick={this.restartQuestionVideo}
                  className="miniBlueButton"
                >
                  Restart
                </button>
              </div>
              <button onClick={this.nextQuestionVideo}>Next</button>
            </div>
            <div ref={this.candidateRefDialogAnswer}>
              <h4 ref={this.candidateRefDialogAnswerTitle}></h4>
              <div
                className="closeRating"
                onClick={() => {
                  this.candidateRefDialogAnswerVideo.current.pause();
                  this.candidateRefDialog.current.style.display = "none";
                }}
              >
                X
              </div>
              <video
                src=""
                ref={this.candidateRefDialogAnswerVideo}
                style={{
                  borderRadius: "8px",
                  width: "480px",
                  maxHeight: "360px",
                }}
                autoPlay={true}
                playsInline={true}
                controls={true}
              ></video>
              <div ref={this.candidateRefDialogAnswerText}></div>
              <div ref={this.candidateRefDialogAnswerVideoControls}
                style={{
                  textAlign: "center",
                  margin: "12px 0px",
                  display: "block",
                }}
              >
                <button
                  onClick={this.playAnswerVideo}
                  className="miniBlueButton"
                >
                  Play
                </button>
                <button
                  onClick={this.pauseAnswerVideo}
                  className="miniBlueButton"
                >
                  Pause
                </button>
                <button
                  onClick={this.restartAnswerVideo}
                  className="miniBlueButton"
                >
                  Restart
                </button>
              </div>
              <button onClick={this.nextAnswerVideo}>Next</button>
            </div>
            <div ref={this.candidateRefDialogRating}>
              <span>
                Rate the candidate based on their response video out of 5 stars
              </span>
              <div className="miniContainer">
                <button
                  className="miniRankButton"
                  onClick={this.rateCandidate}
                  ref={this.candidateReviewRatingStars5Ref}
                  rank-id="5"
                >
                  ★
                </button>
                <button
                  className="miniRankButton"
                  onClick={this.rateCandidate}
                  ref={this.candidateReviewRatingStars4Ref}
                  rank-id="4"
                >
                  ★
                </button>
                <button
                  className="miniRankButton"
                  onClick={this.rateCandidate}
                  ref={this.candidateReviewRatingStars3Ref}
                  rank-id="3"
                >
                  ★
                </button>
                <button
                  className="miniRankButton"
                  onClick={this.rateCandidate}
                  ref={this.candidateReviewRatingStars2Ref}
                  rank-id="2"
                >
                  ★
                </button>
                <button
                  className="miniRankButton"
                  onClick={this.rateCandidate}
                  ref={this.candidateReviewRatingStars1Ref}
                  rank-id="1"
                >
                  ★
                </button>
              </div>
            </div>
            <div ref={this.candidateRefDialogComplete}>
              <h4>Candidate Review Completed</h4>
              <span>
                You have successfully completed the candidate rating process
              </span>
              <button onClick={this.doneCandidateReview}>Done</button>
            </div>
          </div>
        </div>
        <div ref={this.shareDialogRef} className="dialogNew extend">
          <div>
            <h4>Share Candidate</h4>
            <div ref={this.shareDialogPart1Ref}>
              <span>Enter sharee details</span>
              <input
                type="text"
                className="fixedInput"
                placeholder="Fullname"
                ref={this.shareDialogFullnameRef}
              />
              <input
                type="text"
                className="fixedInput"
                placeholder="Email Address"
                ref={this.shareDialogEmailAddressRef}
              />
              <button onClick={this.getShareLink}>Next</button>
              <button
                onClick={() => {
                  this.shareDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
            <div ref={this.shareDialogPart2Ref}>
              <span style={{ display: "block" }}>
                You can copy the link below (recommended)
              </span>
              <textarea
                style={{ width: "100%", height: "64px" }}
                ref={this.shareDialogLinkRef}
              ></textarea>
              <button onClick={this.emailShareLink}>Email Link</button>
              <button onClick={this.copyShareLink}>Copy Link</button>
              <button
                onClick={() => {
                  this.shareDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.shareAndRateDialogRef} className="dialogNew extend">
          <div>
            <h4>Share and Rate Candidate</h4>
            <div ref={this.shareAndRateDialogPart1Ref}>
              <span>Enter sharee details</span>
              <input
                type="text"
                className="fixedInput"
                placeholder="Fullname"
                ref={this.shareAndRateDialogFullnameRef}
              />
              <input
                type="text"
                className="fixedInput"
                placeholder="Email Address"
                ref={this.shareAndRateDialogEmailAddressRef}
              />
              <button onClick={this.getShareAndRateLink}>Next</button>
              <button
                onClick={() => {
                  this.shareAndRateDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
            <div ref={this.shareAndRateDialogPart2Ref}>
              <span style={{ display: "block" }}>
                You can copy the link below (recommended)
              </span>
              <textarea
                style={{ width: "100%", height: "64px" }}
                ref={this.shareAndRateDialogLinkRef}
              ></textarea>
              <button onClick={this.emailShareAndRateLink}>Email Link</button>
              <button onClick={this.copyShareAndRateLink}>Copy Link</button>
              <button
                onClick={() => {
                  this.shareAndRateDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.candidateCommentsDialogRef}
          className="dialogNew extend2"
        >
          <div>
            <h4>
              Comments
              <button
                className="btnx4"
                onClick={() => {
                  this.candidateCommentsDialogRef.current.style.display =
                    "none";
                }}
              >
                X
              </button>
            </h4>
            <div
              ref={this.candidateCommentsDialogContentsRef}
              className="commentItems"
              style={{ padding: "32px 0px" }}
            ></div>
          </div>
        </div>
        <div
          ref={this.candidateCommentsPreviewDialogRef}
          className="dialogNew extend2"
        >
          <div style={{ textAlign: "center" }}>
            <h4>Video Preview</h4>
            <video
              src=""
              controls
              playsInline={true}
              ref={this.candidateCommentsPreviewDialogVideoRef}
              style={{ margin: "0 auto", textAlign: "center", width: "480px" }}
              playsInline={true}
              controls={true}
            ></video>
            <button
              className="btnx"
              onClick={() => {
                this.candidateCommentsPreviewDialogVideoRef.current.pause();
                this.candidateCommentsPreviewDialogRef.current.style.display =
                  "none";
              }}
            >
              Close
            </button>
          </div>
        </div>
        <div ref={this.bespokeFlowDialogRef} className="dialogNew extend2">
          <div style={{ textAlign: "center" }} ref={this.bespokeFlowStep1}>
            <h4>Step 1 of 5 - Bespoke Settings</h4>
            <span>Allow candidate to go to the interview:</span>
            <select ref={this.bespokeFlowStep1Select}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
            <button
              className="btnx2"
              onClick={() => {
                this.bespokeFlowDialogRef.current.style.display = "none";
              }}
            >
              Close
            </button>
            <button className="btnx2" onClick={this.bespokeFlowStep1Next}>
              Next
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.bespokeFlowStep4}>
            <h4>Step 2 of 5 - Bespoke Settings</h4>
            <span>Marketing Text:</span>
            <textarea
              className="bespokeTextArea"
              ref={this.bespokeCustomMarketingTextStep3Ref}
            >
              {this.state.interview.marketing_text}
            </textarea>
            <button
              className="btnx2"
              onClick={() => {
                this.bespokeFlowDialogRef.current.style.display = "none";
              }}
            >
              Close
            </button>
            <button className="btnx2" onClick={this.bespokeFlowStep4Next}>
              Next
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.bespokeFlowStep5}>
            <h4>Step 3 of 5 - Bespoke Settings</h4>
            <br />
            <br />
            <span>Allow candidate to go to an ATS link</span>
            <select
              className="bulkySelect"
              onChange={this.allowATSBespokeOnChange}
              defaultValue={this.state.interview.ats_bespoke}
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <span>ATS link:</span>
            <br />
            <input
              type="text"
              placeholder="ATS Link"
              onChange={this.setATSBespokeOnChange}
              style={{ width: "calc(100% - 84px)", padding: "8px" }}
              defaultValue={this.state.interview.ats_bespoke_url}
            />
            <br />
            <br />
            <button className="btnx2" onClick={this.bespokeFlowStep5Next}>
              Next
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.bespokeFlowStep6}>
            <h4>Step 4 of 5 - Bespoke Settings</h4>
            <br />
            <br />
            <span>Allow candidate to see Introduction Videos</span>
            <select
              className="bulkySelect"
              onChange={this.allowBespokeITJOnChange}
              defaultValue={this.state.interview.itj_bespoke}
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <button className="btnx2" onClick={this.bespokeFlowStep6Next}>
              Done
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.bespokeFlowStep7}>
            <h4>Step 5 of 5 - Bespoke Settings</h4>
            <br />
            <br />
            <span>
              Now add a candidate and start recording your bespoke videos
            </span>
            <br />
            <br />
            <br />
            <button className="btnx2" onClick={this.bespokeFlowStep7Next}>
              Done
            </button>
          </div>
        </div>
        <div ref={this.outreachFlowDialogRef} className="dialogNew extend2">
          <div style={{ textAlign: "center" }} ref={this.outreachFlowStep1}>
            <h4>Step 1 of 5 - Outreach Campaign Settings</h4>
            <span>Allow candidate to go to the interview:</span>
            <select ref={this.outreachFlowStep1Select}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
            <button
              className="btnx2"
              onClick={async () => {
                let result = await API.interview.markVideo(0, 0, this.state.interview.id);
                this.outreachFlowDialogRef.current.style.display = "none";
                this.reloadAllTab();
              }}
            >
              Close
            </button>
            <button className="btnx2" onClick={this.outreachFlowStep1Next}>
              Next
            </button>
          </div>
          <div
            style={{ textAlign: "center", width: "500px" }}
            ref={this.outreachFlowStep3Option}
          >
            <h4>Step 2 of 5 - Outreach Campaign Settings</h4>
            <span>OutReach Campaign Video</span>
            <br />
            <div>
              <button
                className="btnx2"
                onClick={() => {
                  this.outreachFlowStep3Option.current.style.display = "none";
                  this.outreachFlowStep3Upload.current.style.display = "block";
                  this.setState({ ...this.state, canRecordVideo: true });
                }}
              >
                Upload
              </button>
              <button
                className="btnx2"
                onClick={() => {
                  this.outreachFlowStep3Option.current.style.display = "none";
                  this.outreachFlowStep3.current.style.display = "block";
                  this.setState({ ...this.state, canRecordVideo: true });
                }}
              >
                Record
              </button>
              <button
                className="btnx2"
                onClick={async () => {                  
                  let result = await API.interview.markVideo(0, 0, this.state.interview.id);
                  this.outreachFlowDialogRef.current.style.display = "none";
                  this.reloadAllTab();
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div
            style={{ textAlign: "center", width: "500px" }}
            ref={this.outreachFlowStep3Upload}
          >
            <h4>Step 2 of 5 - Outreach Campaign Settings</h4>
            <span>Upload OutReach Campaign Video:</span>
            <FileUploadComponnent
              onclose={async () => {                
                let result = await API.interview.markVideo(0, 0, this.state.interview.id);
                this.outreachFlowStep3Option.current.style.display = "block";
                this.outreachFlowStep3Upload.current.style.display = "none";
              }}
              oncompletion={async (video_url) => {
                this.setBulkMarketingVideo(video_url);
                this.outreachFlowStep3Upload.current.style.display = "none";
                this.outreachFlowStep4.current.style.display = "block";
              }}
            ></FileUploadComponnent>
          </div>
          <div
            style={{ textAlign: "center", width: "500px" }}
            ref={this.outreachFlowStep3}
          >
            <h4>Step 2 of 5 - Outreach Campaign Settings</h4>
            <span>OutReach Campaign Video:</span>
            {this.state.canRecordVideo && (
              <VideoRecordComponent2
                interviewOrMarketing={0}
                selectedFolder={0}
                selectedID={this.state.interview.id}
                watermarkURL={this.state.user.watermark_url}
                onClose={async () => {
                  this.outreachFlowDialogRef.current.style.display = "none";
                  let result = await API.interview.markVideo(
                    0,
                    0,
                    this.state.interview.id,
                    0
                  );
                  let api_key = localStorage.getItem("api_key");
                  result = await API.interview.get(
                    api_key,
                    this.state.interview.id
                  );
                  let interview = result["interview"];
                  this.setState({
                    ...this.state,
                    interview: interview,
                    campaignProcessingVideoStatus: [
                      "",
                      VideoProcessingInlineComponent,
                      VideoProcessedInlineComponent,
                    ][interview.bulk_marketing_video_state],
                    canRecordVideo: false,
                  });
                }}
                onNext={async () => {
                  let result = await API.interview.markVideo(
                    0,
                    0,
                    this.state.interview.id
                  );
                  this.outreachFlowStep3.current.style.display = "none";
                  this.outreachFlowStep4.current.style.display = "block";
                  this.setState({ ...this.state, canRecordVideo: false });
                  let api_key = localStorage.getItem("api_key");
                  result = await API.interview.get(
                    api_key,
                    this.state.interview.id
                  );
                  let interview = result["interview"];
                  this.setState({
                    ...this.state,
                    interview: interview,
                  });
                  this.campaignChecker = setInterval(async () => {
                    let api_key = localStorage.getItem("api_key");
                    let result = await API.interview.get(
                      api_key,
                      this.state.interview.id
                    );
                    let interview = result["interview"];
                    if (interview.bulk_marketing_video_state >= 2) {
                      clearInterval(this.campaignChecker);
                      this.setState({
                        ...this.state,
                        interview: {
                          ...this.state.interview,
                          bulk_marketing_video_state: 2,
                          campaignProcessingVideoStatus: [
                            "",
                            VideoProcessingInlineComponent,
                            VideoProcessedInlineComponent,
                          ][interview.bulk_marketing_video_state]
                        },
                        campaignProcessingVideoStatus:
                          VideoProcessedInlineComponent,
                      });
                    }
                  }, 5000);
                }}
              ></VideoRecordComponent2>
            )}
          </div>
          <div style={{ textAlign: "center" }} ref={this.outreachFlowStep4}>
            <h4>Step 3 of 5 - Outreach Campaign Settings</h4>
            <span>OutReach Campaign Text:</span>
            <textarea
              className="bespokeTextArea"
              ref={this.outreachCustomMarketingTextStep3Ref}
            ></textarea>
            <button
              className="btnx2"
              onClick={() => {
                this.outreachFlowDialogRef.current.style.display = "none";
                this.reloadAllTab();
              }}
            >
              Close
            </button>
            <button
              className="btnx2"
              onClick={async () => {
                let text =
                  this.outreachCustomMarketingTextStep3Ref.current.value;
                let api_key = localStorage.getItem("api_key");
                let result = await API.interview.setBulkMarketingText(
                  api_key,
                  this.state.interview.id,
                  text
                );
                let success = result["success"];
                if (success) {
                  toast("Outreach Campaign Marketing Text Set");
                } else {
                  toast("Error setting Outreach Marketing Text");
                }
                this.outreachFlowStep4.current.style.display = "none";
                this.outreachFlowStep5.current.style.display = "block";
                result = await API.interview.get(
                  api_key,
                  this.state.interview.id
                );
                let interview = result["interview"];
                this.setState({
                  ...this.state,
                  interview: interview,
                });
              }}
            >
              Next
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.outreachFlowStep5}>
            <h4>Step 4 of 5 - Outreach Campaign Settings</h4>
            <span>Allow candidate to go to an ATS link</span>
            <select
              className="bulkySelect"
              onChange={this.allowATSOutreachOnChange}
              defaultValue={this.state.interview.ats_outreach}
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <span>ATS link</span>
            <br />
            <div style={{ display: "inline-block", fontSize: "8pt" }}>
              Example URL{" "}
              <strong>
                <span style={{ backgroundColor: "yellow" }}>https://</span>
                www.example.com/
              </strong>
            </div>
            <input
              type="text"
              placeholder="ATS Link"
              onChange={this.setATSOutreachOnChange}
              style={{ width: "calc(100% - 84px)", padding: "8px" }}
              defaultValue={this.state.interview.ats_outreach_url}
            />
            <br />
            <br />
            <button
              className="btnx2"
              onClick={() => {
                this.outreachFlowDialogRef.current.style.display = "none";
                this.reloadAllTab();
              }}
            >
              Close
            </button>
            <button
              className="btnx2"
              onClick={() => {
                this.outreachFlowStep5.current.style.display = "none";
                this.outreachFlowStep6.current.style.display = "block";
                this.reloadAllTab();
              }}
            >
              Next
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.outreachFlowStep6}>
            <h4>Step 5 of 5 - Outreach Campaign Settings</h4>
            <span>OutReach Campaign Custom Gif Text</span>
            <select
              onChange={this.thumbnailTextOutreachOnChange}
              ref={this.thumbnailTextOutreachRef}
              defaultValue={this.state.interview.custom_thumbnail_outreach_type}
            >
              <option value="0">Hi {"{candidate}"}</option>
              <option value="1">Hello {"{candidate}"}</option>
              <option value="2">Hello {"{candidate}"}</option>
              <option value="3">Custom Message</option>
            </select>
            {parseInt(this.state.interview.custom_thumbnail_outreach_type) ===
            3 ? (
              <div className="customMessageContainer">
                <span>Enter a custom message:</span>
                <input
                  type="text"
                  onChange={this.bulkTextOnChange}
                  ref={this.bulkTextRef}
                  defaultValue={this.state.interview.bulk_marketing_text}
                />
              </div>
            ) : (
              ""
            )}
            <button
              className="btnx2"
              onClick={async () => {
                this.outreachFlowDialogRef.current.style.display = "none";
                let api_key = localStorage.getItem('api_key') ?? "";
                let result = await API.interview.startInteractivity(api_key, this.state.interview.id, 1, 1);
                this.reloadAllTab();
              }}
            >
              Close
            </button>
            <button
              className="btnx2"
              onClick={async () => {
                this.outreachFlowDialogRef.current.style.display = "none";
                let api_key = localStorage.getItem('api_key') ?? "";
                let result = await API.interview.startInteractivity(api_key, this.state.interview.id, 1, 1);
                this.reloadAllTab();
              }}
            >
              Done
            </button>
          </div>
        </div>
        <div ref={this.socialFlowDialogRef} className="dialogNew extend2">
          <div style={{ textAlign: "center" }} ref={this.socialFlowStep2}>
            <h4>Step 1 of 3 - Set Thumbnail</h4>
            <div ref={this.socialFlowStep2SubStep1}>
              <h4>Choose a method to set a thumbnail</h4>
              <div>
                <button
                  className="btnx2"
                  onClick={this.socialFlowStep2SubStep1Capture}
                >
                  Capture
                </button>
                <button
                  className="btnx2"
                  onClick={this.socialFlowStep2SubStep1Upload}
                >
                  Upload
                </button>
                <button
                  className="btnx2"
                  onClick={() => {
                    this.socialFlowDialogRef.current.style.display = "none";
                    this.reloadAllTab();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
            <div
              ref={this.socialFlowStep2SubStepCamera}
              style={{ width: "480px", margin: "0 auto" }}
            >
              <span>Capture A Thumbnail</span>
              <br />
              <br />
              {isVisible && (
                <Webcam
                  screenshotFormat="image/jpeg"
                  audio={false}
                  height={232}
                  width={412}
                  videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: "user",
                  }}
                  id="outreachThumbnailRef"
                  ref={this.cameraSocialRef2}
                ></Webcam>
              )}{" "}
              <br />
              <div
                className="captureCounterThumbnail"
                id="captureSocialCounterThumbnail"
              >
                3
              </div>
              <br />
              <img
                src=""
                alt=""
                id="imgSocialRef"
                className="outReachImage"
                ref={this.imgSocialRef}
              />
              <button
                className="btnx2"
                onClick={this.socialFlowStepCameraCapture}
              >
                Capture
              </button>
              <button
                className="btnx2"
                ref={this.saveCaptureSocialButtonRef}
                onClick={this.socialFlowStepCameraSave}
              >
                Save
              </button>
              <button
                className="btnx2"
                onClick={() => {
                  this.socialFlowDialogRef.current.style.display = "none";
                  this.reloadAllTab();
                }}
              >
                Close
              </button>
            </div>
            <div ref={this.socialFlowStep2SubStepUpload}>
              <span>Upload Thumbnail</span>
              <br />
              <br />
              <input
                type="file"
                accept=".jpg, .png"
                ref={this.socialFileUploadRef}
              />
              <button className="btnx2" onClick={this.socialFlowStepUpload}>
                Upload
              </button>
              <button
                className="btnx2"
                onClick={() => {
                  this.socialFlowDialogRef.current.style.display = "none";
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div
            style={{ textAlign: "center", width: "480px" }}
            ref={this.socialFlowStep3}
          >
            <h4>Step 1 of 3 - Social Post Settings</h4>
            <span>Post Video:</span>
            {this.state.canRecordVideoSocial && (
              <VideoRecordComponent2
                interviewOrMarketing={0}
                selectedFolder={1}
                selectedID={this.state.interview.id}
                watermarkURL={this.state.user.watermark_url}
                onClose={async () => {
                  this.socialFlowDialogRef.current.style.display = "none";
                  let result = await API.interview.markVideo(
                    0,
                    1,
                    this.state.interview.id,
                    0
                  );
                }}
                onNext={async () => {
                  let result = await API.interview.markVideo(
                    0,
                    1,
                    this.state.interview.id
                  );
                  this.socialFlowStep3.current.style.display = "none";
                  this.socialFlowStep4.current.style.display = "block";
                  this.setState({ ...this.state, canRecordVideoSocial: false });
                  let api_key = localStorage.getItem("api_key");
                  result = await API.interview.get(
                    api_key,
                    this.state.interview.id
                  );
                  let interview = result["interview"];
                  this.setState({
                    ...this.state,
                    interview: interview,
                  });

                  this.socialChecker = setInterval(async () => {
                    let api_key = localStorage.getItem("api_key");
                    let result = await API.interview.get(
                      api_key,
                      this.state.interview.id
                    );
                    let interview = result["interview"];
                    if (interview.advertise_marketing_video_state >= 2) {
                      clearInterval(this.socialChecker);
                      this.setState({
                        ...this.state,
                        interview: {
                          ...this.state.interview,
                          advertise_marketing_video_state: 2,
                        },
                        campaignProcessingVideoStatus:
                          VideoProcessedInlineComponent,
                        canRecordVideoSocial: false,
                      });
                    }
                  }, 5000);
                }}
              ></VideoRecordComponent2>
            )}
          </div>
          <div style={{ textAlign: "center" }} ref={this.socialFlowStep4}>
            <h4>Step 2 of 3 - Social Post Settings</h4>

            <h4
              className="tab3ActionContainerMaxWidthMiniH4"
              style={{ marginLeft: "20px", fontWeight: "bold" }}
            >
              Post Title <span style={{ fontWeight: 400 }}></span>
            </h4>
            <input
              style={{
                width: "864px",
                padding: "12px",
                marginLeft: "20px",
                transform: "translateX(-26px)",
              }}
              defaultValue={this.state.interview.social_caption}
              onChange={this.socialCaptionOnChange}
              placeholder="Title"
            />
            <h4
              className="tab3ActionContainerMaxWidthMiniH4"
              style={{ marginLeft: "20px", fontWeight: "bold" }}
            >
              Post Text
            </h4>
            <textarea
              className="advertisemarketingText"
              onChange={this.socialTextOnChange}
              defaultValue={this.state.interview.advertise_marketing_text}
            ></textarea>
            <br />
            <button
              className="btnx2"
              onClick={() => {
                this.socialFlowDialogRef.current.style.display = "none";
                this.reloadAllTab();
              }}
            >
              Close
            </button>
            <button
              className="btnx2"
              onClick={() => {
                this.socialFlowStep4.current.style.display = "none";
                this.socialFlowStep5.current.style.display = "block";
              }}
            >
              Next
            </button>
          </div>
          <div style={{ textAlign: "center" }} ref={this.socialFlowStep5}>
            <h4>Step 3 of 3 - Receive Candidates</h4>
            <span>Receive Candidates Into:</span>
            <select
              defaultValue={this.state.interview.advertise_candidate_load}
              onChange={async (e) => {
                let value = parseInt(e.target.value);
                if (value == 0) {
                  this.hideIfATS1.current.style.display = "none";
                  this.hideIfATS2.current.style.display = "none";
                  this.hideIfATS3.current.style.display = "none";
                } else {
                  this.hideIfATS1.current.style.display = "block";
                  this.hideIfATS2.current.style.display = "block";
                  this.hideIfATS3.current.style.display = "block";
                }
                let api_key = localStorage.getItem("api_key");
                let result = await API.interview.AdvertiseCandidateLoad(
                  api_key,
                  this.state.interview.id,
                  value
                );
                toast("Updated");
              }}
            >
              <option value="0">CRM/ATS</option>
              <option value="1">Interview (self signup)</option>
            </select>
            <span ref={this.hideIfATS1}>User Redirection Link</span>
            <div
              ref={this.hideIfATS2}
              style={{ display: "block", fontSize: "8pt", textAlign: "left" }}
            >
              Example URL{" "}
              <strong>
                <span style={{ backgroundColor: "yellow" }}>https://</span>
                www.example.com/
              </strong>
            </div>
            <input
              ref={this.hideIfATS3}
              style={{
                display: "block",
                padding: "12px",
                width: "calc(100% - 30px)",
              }}
              type="text"
              placeholder="https://www.example.com/"
              onChange={this.updateSocialRedirectionLink}
              defaultValue={
                this.state.interview.advertise_marketing_redirect_url
              }
            />
            <div
              ref={this.atsRefirectionLinkRef}
              style={{ display: "block" }}
            ></div>
            <span
              style={{
                fontSize: "8pt",
                color: "#B5B5C3",
                display: "block",
                textAlign: "left",
                marginBottom: "8px",
              }}
            >
              We will redirect the candidate to this url
            </span>
            <button
              className="btnx2"
              onClick={async () => {
                this.socialFlowDialogRef.current.style.display = "none";
                let api_key = localStorage.getItem('api_key') ?? "";
                let result = await API.interview.startInteractivity(api_key, this.state.interview.id, 2, 1);
                this.reloadAllTab();
              }}
            >
              Close
            </button>
            <button
              className="btnx2"
              onClick={async () => {
                let api_key = localStorage.getItem("api_key");
                let result = await API.interview.startInteractivity(api_key, this.state.interview.id, 2, 1);
                result = await API.interview.get(
                  api_key,
                  this.state.interview.id
                );
                let interview = result["interview"];
                this.setState({
                  ...this.state,
                  interview: interview,
                });
                this.socialFlowDialogRef.current.style.display = "none";
                toast("Social Post Marketing Text Set");
              }}
            >
              Done
            </button>
          </div>
        </div>
        <div ref={this.advertisementDialogRef} className="dialogNew extend2">
          <div style={{ textAlign: "center" }}>
            <h4>Social Post Marketing Link</h4>
            <span style={{ display: "block" }}>
              You can copy the link below (recommended)
            </span>
            <textarea
              style={{ width: "100%", height: "64px" }}
              ref={this.advertisementDialogRefLink}
            ></textarea>
            <button className="btnx3" onClick={this.copyAdvertisementLink}>
              Copy Link
            </button>
            <button
              className="btnx3"
              onClick={() => {
                this.advertisementDialogRef.current.style.display = "none";
              }}
            >
              Close
            </button>
          </div>
        </div>
        <LibraryComponent
          ref={this.socialVideoLibraryRef}
          OnSave={this.libraryOnSave}
        ></LibraryComponent>
        <LibraryComponent
          ref={this.bulkMarketingVideoLibraryRef}
          OnSave={this.libraryOnSave2}
        ></LibraryComponent>
        <div
          ref={this.librarySelectionRef2}
          className="actionPopUp"
          data-x="Social Lib"
        >
          <div>
            <h4>Select a video from this library list</h4>
            <div ref={this.libraryItemsRef2} className="libraryContainer">
              {this.state.library &&
                this.state.library.map((library, index) => {
                  return (
                    <div
                      onClick={this.openLibrarySelection}
                      library-id={library.id}
                    >
                      <h4>{library.title}</h4>
                      <video mute={true} src={library.video_url}></video>
                    </div>
                  );
                })}

              {!this.state.library || this.state.library.length <= 0 ? (
                <span
                  style={{ display: "block", padding: "12px", margin: "12px" }}
                >
                  No Videos for this library type
                </span>
              ) : (
                <></>
              )}
            </div>
            <div>
              <button onClick={this.closeLibraryDialog2} className="close">
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.librarySelectionRef} className="actionPopUp">
          <div>
            <h4>Select a video from this library list</h4>
            <div ref={this.libraryItemsRef} className="libraryContainer">
              {this.state.library &&
                this.state.library.map((library, index) => {
                  return (
                    <div
                      onClick={this.openLibrarySelection2}
                      library-id={library.id}
                    >
                      <h4>{library.title}</h4>
                      <video mute={true} src={library.video_url}></video>
                    </div>
                  );
                })}

              {!this.state.library || this.state.library.length <= 0 ? (
                <span
                  style={{ display: "block", padding: "12px", margin: "12px" }}
                >
                  No Videos for this library type
                </span>
              ) : (
                <></>
              )}
            </div>
            <div>
              <button onClick={this.closeLibraryDialog} className="close">
                Close
              </button>
            </div>
          </div>
        </div>
        <div
          ref={this.libraryVideoPreviewRef2}
          className="actionPopUp"
          data-x="Bulk Lib"
        >
          <div>
            <h4>Library Video Preview</h4>
            <video
              className="videoSize"
              src=""
              ref={this.libraryVideoPreviewVideoRef2}
              autoPlay={true}
              playsInline={true}
              controls={true}
            ></video>
            <div style={{ textAlign: "right", paddingTop: "12px" }}>
              <button onClick={this.closeLibraryPreview2} className="close">
                Close
              </button>
              <button onClick={this.deleteLibrarySelection2} className="close">
                Delete
              </button>
              <button onClick={this.librarySelect2}>Select</button>
            </div>
          </div>
        </div>
        <div ref={this.libraryVideoPreviewRef} className="actionPopUp">
          <div>
            <h4>Library Video Preview</h4>
            <video
              className="videoSize"
              src=""
              ref={this.libraryVideoPreviewVideoRef}
              autoPlay={true}
              playsInline={true}
              controls={true}
            ></video>
            <div style={{ textAlign: "right", paddingTop: "12px" }}>
              <button onClick={this.closeLibraryPreview} className="close">
                Close
              </button>
              <button onClick={this.deleteLibrarySelection} className="close">
                Delete
              </button>
              <button onClick={this.librarySelect}>Select</button>
            </div>
          </div>
        </div>
        <div ref={this.customCandidateThumbnailRef} className="actionPopUp">
          <div>
            <h4>Upload Custom Candidate Thumbnail</h4>
            <input
              type="file"
              ref={this.customCandidateThumbnailFileRef}
              accept=".png, .jpeg, .jpg"
            />
            <br />
            <br />
            <div style={{ textAlign: "right", paddingTop: "12px" }}>
              <button onClick={this.uploadCustomCandidateThumbnail}>
                Upload
              </button>
              <button
                onClick={() => {
                  this.customCandidateThumbnailRef.current.style.display =
                    "none";
                }}
                className="close"
              >
                Close
              </button>
            </div>
          </div>
        </div>
        <div ref={this.facebookPagesRef} className="dialogNew extend">
          <div className="page_item_container">
            <span>Select the pages which you would like to post on</span>
            <br />
            <br />
            {this.state.pages &&
              this.state.pages.map((page) => {
                return (
                  <label className="page_item" key={"page_id_" + page.id}>
                    <input
                      type="checkbox"
                      page-id={page.id}
                      page-access-token={page.access_token}
                    />
                    <span>{page.name}</span>
                  </label>
                );
              })}
            <br />
            <br />
            <button
              className="blueBTN"
              style={{ display: "block", width: "100%" }}
              onClick={this.postToSelectedPages}
            >
              Post to Pages
            </button>
            <br />
            <button
              className="blueBTN"
              style={{ display: "block", width: "100%" }}
              onClick={() => {
                this.facebookPagesRef.current.style.display = "none";
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div>
          <div className="dialogNewCreate" ref={this.allAnwserLinks}>
            <div style={{ width: "1000px" }}>
              <h4>All Answer Videos</h4>
              <div>
                <textarea
                  ref={this.allAnwserLinksText}
                  style={{ width: "1000px", height: "193px" }}
                ></textarea>
              </div>
              <div>
                <button
                  onClick={() => {
                    this.allAnwserLinks.current.style.display = "none";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="dialogNewCreate" ref={this.bulkLinkShareDialogRef}>
            <div style={{ width: "1000px" }}>
              <h4>Enter Share Details</h4>
              <div>
                <input
                  placeholder="Full Name"
                  type="text"
                  ref={this.bulkLinkShareDialogNameRef}
                  className="fillInput"
                />
                <input
                  placeholder="Email Address"
                  type="email"
                  ref={this.bulkLinkShareDialogEmailRef}
                  className="fillInput"
                />
              </div>
              <div>
                <button
                  onClick={async () => {
                    let fullname =
                      this.bulkLinkShareDialogNameRef.current.value;
                    let email = this.bulkLinkShareDialogEmailRef.current.value;
                    if (!fullname) {
                      toast("enter a valid full name");
                      return;
                    }
                    if (!email) {
                      toast("enter a valid email");
                      return;
                    }
                    this.bulkLinkShareDialogRef.current.style.display = "none";
                    this.setState({
                      ...this.state,
                      loading: true,
                    });
                    let ids = Array.from(
                      document.querySelectorAll(".interview_candidates:checked")
                    ).map((item) => {
                      return parseInt(item.getAttribute("candidate-id"));
                    });
                    if (ids.length == 0) {
                      toast.error(
                        "No candidates selected, select a candidate first"
                      );
                      return;
                    }
                    let api_key = localStorage.getItem("api_key");
                    let all_links = [];
                    for (let i = 0; i < ids.length; i++) {
                      let result = await API.share.get(
                        api_key,
                        ids[i],
                        this.state.interview.id,
                        fullname,
                        email
                      );
                      all_links.push(result["link"]);
                    }
                    this.bulkLinkEmailDialogRef.current.style.display = "flex";
                    this.bulkLinkEmailDialogBodyRef.current.value = "";
                    this.bulkLinkEmailDialogBodyRef.current.value +=
                      "Candidate Share Links\n\n";
                    this.bulkLinkEmailDialogBodyRef.current.value +=
                      all_links.join("\n\n");
                    this.setState({
                      ...this.state,
                      loading: false,
                    });
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    this.bulkLinkShareDialogRef.current.style.display = "none";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="dialogNewCreate"
            ref={this.bulkLinkShareRateDialogRef}
          >
            <div style={{ width: "1000px" }}>
              <h4>Enter Share Details</h4>
              <div>
                <input
                  placeholder="Full Name"
                  type="text"
                  ref={this.bulkLinkShareRateDialogNameRef}
                  className="fillInput"
                />
                <input
                  placeholder="Email Address"
                  type="email"
                  ref={this.bulkLinkShareRateDialogEmailRef}
                  className="fillInput"
                />
              </div>
              <div>
                <button
                  onClick={async () => {
                    toast("loading");
                    let fullname =
                      this.bulkLinkShareRateDialogNameRef.current.value;
                    let email =
                      this.bulkLinkShareRateDialogEmailRef.current.value;
                    if (!fullname) {
                      toast("enter a valid full name");
                      return;
                    }
                    if (!email) {
                      toast("enter a valid email");
                      return;
                    }
                    this.bulkLinkShareDialogRef.current.style.display = "none";
                    this.setState({
                      ...this.state,
                      loading: true,
                    });
                    let ids = Array.from(
                      document.querySelectorAll(".interview_candidates:checked")
                    ).map((item) => {
                      return parseInt(item.getAttribute("candidate-id"));
                    });
                    if (ids.length == 0) {
                      toast.error(
                        "No candidates selected, select a candidate first"
                      );
                      return;
                    }
                    let api_key = localStorage.getItem("api_key");
                    let all_links = [];
                    for (let i = 0; i < ids.length; i++) {
                      let result = await API.shareAndRate.get(
                        api_key,
                        ids[i],
                        this.state.interview.id,
                        fullname,
                        email
                      );
                      all_links.push(result["link"]);
                    }
                    this.bulkLinkShareRateDialogRef.current.style.display =
                      "none";
                    this.bulkLinkEmailDialogRef.current.style.display = "flex";
                    this.bulkLinkEmailDialogBodyRef.current.value = "";
                    this.bulkLinkEmailDialogBodyRef.current.value +=
                      "Candidate Share and Rate Links\n\n";
                    this.bulkLinkEmailDialogBodyRef.current.value +=
                      all_links.join("\n\n");
                    this.bulkLinkEmailDialogEmailAddressRef.current.value =
                      email;
                    this.setState({
                      ...this.state,
                      loading: false,
                    });
                  }}
                >
                  Submit
                </button>
                <button
                  onClick={() => {
                    this.bulkLinkShareRateDialogRef.current.style.display =
                      "none";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="dialogNewCreate" ref={this.bulkLinkEmailDialogRef}>
            <div style={{ width: "1000px" }}>
              <h4>Bulk Link Email</h4>
              <div>
                <input
                  placeholder="Email Address"
                  type="email"
                  ref={this.bulkLinkEmailDialogEmailAddressRef}
                  className="fillInput"
                  autoComplete={false}
                />
                <input
                  placeholder="Email Subject"
                  type="text"
                  ref={this.bulkLinkEmailDialogSubjectRef}
                  className="fillInput"
                  autoComplete={false}
                />
                <textarea
                  ref={this.bulkLinkEmailDialogBodyRef}
                  style={{ width: "1000px", height: "193px", padding: "4px" }}
                ></textarea>
              </div>
              <div>
                <button
                  onClick={async () => {
                    let email_address =
                      this.bulkLinkEmailDialogEmailAddressRef.current.value;
                    if (!email_address) {
                      toast("enter a valid email address");
                      return;
                    }
                    let subject =
                      this.bulkLinkEmailDialogSubjectRef.current.value;
                    if (!subject) {
                      toast("enter a valid subject");
                      return;
                    }
                    let body = this.bulkLinkEmailDialogBodyRef.current.value;
                    this.setState({
                      ...this.state,
                      laoding: true,
                    });
                    let api_key = localStorage.getItem("api_key");
                    let result = await API.email(
                      api_key,
                      email_address,
                      subject,
                      body
                    );
                    this.setState({
                      ...this.state,
                      laoding: false,
                    });
                    this.bulkLinkEmailDialogRef.current.style.display = "none";
                    toast("Email Sent");
                  }}
                >
                  Send Email
                </button>
                <button
                  onClick={() => {
                    this.bulkLinkEmailDialogRef.current.style.display = "none";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>{" "}
        <div>
          <div
            className="dialogNewCreate"
            ref={this.showFacebookShareNoticeDialogRef}
          >
            <div style={{ width: "1000px" }}>
              <h4>Facebook Share Notice</h4>
              <div>
                <span>
                  If you would like to share your post to a facebook group, you
                  will need to log into facebook, and share it from there to a
                  group which you are a member of.
                </span>
                <br />
                <span>
                  Note that the intial share may take a few minutes to reflect
                  on facebook.
                </span>
              </div>
              <div>
                <button
                  onClick={() => {
                    this.showFacebookShareNoticeDialogRef.current.style.display =
                      "none";
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="dialogNewCreate"
            ref={this.showAssessmentDialog}
          >
            <div className="candidate-assessment-container">
               <h4>Assessment Results for "{ this.state.showCandidateAssessment ? this.candidateAssessment.current['candidate']['name'] : "Loading" }" ({ this.state.showCandidateAssessment ? this.candidateAssessment.current['candidate']['email'] : "Loading" })&nbsp;<button onClick={()=>{                
                this.showAssessmentDialog.current.style.display = "none";   
               }}>X</button></h4>
               <div className="candidate-assessment-container-score">
                  <h4>Score</h4>
                  <div>
                    <div style={{backgroundColor: (this.state.showCandidateAssessment ? this.getColor(parseInt(this.candidateAssessment.current['survey']['score']) / parseInt(this.candidateAssessment.current['survey']['questions'].length) * 100) : "black"), width: this.state.showCandidateAssessment ? ( "calc(" + (parseInt(this.candidateAssessment.current['survey']['score']) / parseInt(this.candidateAssessment.current['survey']['questions'].length)) * 100).toString() + "% - 12px)" : "0%" }}>{ this.state.showCandidateAssessment ? parseInt(this.candidateAssessment.current['survey']['score']) + "pts" : "Loading" }&nbsp;({ this.state.showCandidateAssessment ? ( (parseInt(this.candidateAssessment.current['survey']['score']) / parseInt(this.candidateAssessment.current['survey']['questions'].length)) * 100).toString() + "%" : "Loading" })</div>
                  </div>
               </div>
               <div className="candidate-assessment-container-details">
                  <h4>Details</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Correct</th>
                      </tr>                      
                    </thead>
                    <tbody>
                      {
                        this.state.showCandidateAssessment && this.candidateAssessment.current['survey']['questions'].map((sq, index)=>{
                          return <tr>
                            <td>{ sq.question }</td>
                            <td>{ sq.answer }</td>
                            <td>{ sq.status === "Correct" ? "X" : "" }</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
               </div>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <div className="loader">
            <div>
              Loading, Please Wait{" "}
              <AiOutlineLoading className="loading-spinner" />
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
