import React, { createRef } from "react";
import { toast } from "react-toastify";
import API from "../services/api";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import './changePassword.css';

export default class ChangePasswordPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};

      this.pwRef = createRef();
      this.pw2Ref = createRef();

      this.updatePassword = this.updatePassword.bind(this);
    }

    async updatePassword(){
        let pw = this.pwRef.current.value;
        let pw2 = this.pw2Ref.current.value;
        if(!pw || !pw2){
            toast("Password is invalid");
            return;
        }
        if(pw != pw2){
            toast("Passwords is don't match");
            return;
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.setPassword(api_key, pw);
        let success = result['success'];
        if(success){
            toast("Password Updated");
            window.location.href = "/dashboard";
        } else {
            toast("Error Updating Password");
        }
    }

    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Change Your Password</span>
                    </div>                
                    <div className="password-reset-form">
                        <h4>Enter your new password</h4>
                        <input type="text" placeholder="Enter Password" ref={this.pwRef}/>
                        <input type="text" placeholder="Confirm Password" ref={this.pw2Ref}/>
                        <button onClick={this.updatePassword}>Save Changes</button>
                    </div>
                </div>
            </div>
        );
      }
}