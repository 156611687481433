import React from "react";

export default class DisabledPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
    }


    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <div className="disabledUser">Your account is disabled, You have been logged out</div>
            </div>
        );
      }
}