import React from "react";

import './upperbar.css';

import NotificationComponent from "./notifications";

import API from './../services/api';

export default class UpperBarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            laoding: true,
            profileDropDown: false,
            fullname: "Loading",
            accountInfo: [
                "Loading"
             ],
             orginal_api_key: false
        };
        this.toggleProfileDropDown = this.toggleProfileDropDown.bind(this);
        this.goBack = this.goBack.bind(this);
    }

    toggleProfileDropDown(){
        this.setState({...this.state, profileDropDown: !this.state.profileDropDown});
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        if(!api_key){
            window.location.href = "/login";
            return;
        }
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        let fullname = user['name'];
        let accountInfo = [];
        let pNum = user.package_number ?? 0;
        accountInfo.push([
            "Free Subscription Package",
            "Solo Subscription Package",
            "Lite Subscription Package",
            "Business Lite Subscription Package",
            "Business Pro Subscription Package",
            "Enterprise Subscription Package",
        ][pNum]);
        if(user.isAdmin){
            accountInfo.push("Administrator Account");
        }
        if(user.isTester){
            accountInfo.push("Tester Account");
        }
        this.setState({
            ...this.state, accountInfo: accountInfo, fullname: fullname, orginal_api_key: localStorage.getItem('original_api_key') ?? false
        });
    }

    async goBack(){
        let api_key = localStorage.getItem('original_api_key');
        if(api_key){
            localStorage.setItem('api_key', api_key);
            localStorage.setItem('original_api_key', "");
            window.location.href="/dashboard";
        }
    }

    render() {
        // HTML
        return (
            <div className="upperbar">
                <div className="notifications">
                    <NotificationComponent></NotificationComponent>
                </div>
                <div className="profileDropDown">
                    <button className="profileDropDownButton" onClick={this.toggleProfileDropDown}>Hi,&nbsp;&nbsp;<strong>{ this.state.fullname }</strong> <div className="profileDropDownLetter">{ this.state.fullname.substr(0, 1) }</div></button>
                    { 
                        this.state.profileDropDown ? (
                            <div className="profileDropDownContainer">
                                <div>
                                    <h4>User Profile</h4><button className="closeProfileDropDown" onClick={this.toggleProfileDropDown}>X</button>
                                    <div className="userDetails">
                                        <h4>{ this.state.fullname }</h4>
                                        <div className="accountInfo">
                                            {
                                                this.state.accountInfo.map((item, index)=>{
                                                    return (
                                                        <span key={index}>{ item }</span>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                      this.state.orginal_api_key && <a href="#" onClick={this.goBack}>Go Back To Your Profile</a>
                                    }
                                    <a href="/change">Change Password</a>
                                    <a href="/logout">Logout</a>                                    
                                </div>
                            </div>
                        ) : ("")
                    }
                </div>
            </div>
        );
      }
}