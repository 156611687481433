import React, { createRef } from "react";

import { DateTime } from "luxon";

import "./template.css";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import API from "../services/api";

import FileUploadComponent from './../components/fileupload';

import { AiOutlineLoading } from 'react-icons/ai';

import { ToastContainer, toast } from 'react-toastify';

import { withRouter } from "react-router";
import VideoRecordComponent from "../components/videorecord";

class TemplatePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          loading: true,
          template: false,
          questions: [],
          canUploadDisplay: false,
          questionTypeText: true,
          questionTypeVideo: true,
          user: false
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 
      this.useTemplate = this.useTemplate.bind(this);
      this.deleteTemplate = this.deleteTemplate.bind(this);
      this.getCount = this.getCount.bind(this);

      this.addToVacancyDialogRef = createRef();
      this.addToVacancyDialogTitleRef = createRef();
      this.videoPreviewRef = createRef();
      this.videoPreviewVideoRef = createRef();
      this.videoRecordRef = createRef();
      this.videoRecordElementRef = createRef();
      
      this.videoUploadRef = createRef();
      // question stuff
      this.questionCreationDialogRef = createRef();
      this.questionCreationDialogLoadingRef = createRef();
      this.questionCreationDialogStep1Ref = createRef();
      this.questionCreationDialogStep1TypeRef = createRef();
      this.questionCreationDialogStep2TextRef = createRef();
      this.questionCreationDialogStep2TextValueRef = createRef();
      this.questionCreationDialogStep2TextTimeRef = createRef();
      this.questionCreationDialogStep2VideoRef = createRef();
      this.questionCreationDialogStep2VideoTimeRef = createRef();
      this.questionCreationDialogStep2VideoElementRef = createRef();
      this.questionCreationDialogStep2VideoOptionsRef = createRef();
    }

    async componentDidMount(){
        this.addToVacancyDialogRef.current.style.display = "none";
        this.videoPreviewRef.current.style.display = "none";
        this.videoRecordRef.current.style.display = "none";
        // hide question stuff
        this.questionCreationDialogRef.current.style.display = "none";
        this.questionCreationDialogLoadingRef.current.style.display = "none";
        //this.questionCreationDialogStep1Ref.current.style.display = "none";
        this.questionCreationDialogStep2TextRef.current.style.display = "none";
        this.questionCreationDialogStep2VideoRef.current.style.display = "none";
        this.questionCreationDialogStep2VideoOptionsRef.current.style.display = "none";
        //////........
        let api_key = localStorage.getItem('api_key');
        let id = parseInt(this.props.match.params.id);
        let result = await API.templates.get(api_key, id);
        let template = result['template'];
        result = await API.user.getUser(api_key, id);
        let user = result['user'];
        template['questions'] = JSON.parse(template['questions'], true); 
        let questionTypeText = false;
        let questionTypeVideo = false;
        result = await API.templates.isTextOrVideo(api_key, template.id);
        let value = result['value'];
        if(value == "both"){
            questionTypeText = true;
            questionTypeVideo = true;
        } else if(value == "text"){
            questionTypeText = true;
        } else if(value == "video"){
            questionTypeVideo = true;
        }
        this.setState({
            ...this.state,
            template: template,
            user: user,
            loading: false,
            questionTypeText: questionTypeText,
            questionTypeVideo: questionTypeVideo,
        });

        this.pulling = setInterval(async ()=>{            
            let api_key = localStorage.getItem('api_key');
            let id = parseInt(this.props.match.params.id);
            let result = await API.templates.get(api_key, id);
            let template = result['template'];
            template['questions'] = JSON.parse(template['questions'], true);             
            this.setState({
                ...this.state,
                template: template,           
            });
        }, 20000);
    }

    async useTemplate(e){        
        let title = e.target.getAttribute('data-template-title');
        let id = e.target.getAttribute('data-template-id');
        this.addToVacancyDialogRef.current.style.display = "flex";
        this.addToVacancyDialogTitleRef.current.value = title;
        this.addToVacancyDialogTitleRef.current.setAttribute('data-id', id);
    }
    async deleteTemplate(e){        
    }
    getCount(){
        let sum = 0;
        if(this.state.template.intro_video_url) sum += 1;
        if(this.state.template.meet_team_video_url) sum += 1;
        if(this.state.template.job_overview_video_url) sum += 1;
        return sum; 
    }
        render() {
            
        
        // HTML
        return (
            <>
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Template &gt; { this.state.template?.title }</span>
                        <span class="dropdown" style={{display:'inline-block'}}>
                            <button>Actions</button>
                            <label>
                                <input type="checkbox" />
                                <ul>
                                    <li onClick={()=>{
                                        let id = parseInt(this.props.match.params.id);                                        
                                        this.addToVacancyDialogRef.current.style.display = "flex";
                                        this.addToVacancyDialogTitleRef.current.value = this.state.template.title;
                                        this.addToVacancyDialogTitleRef.current.setAttribute('data-id', id);
                                    }}>Use as Template</li>
                                    <li onClick={ async ()=>{
                                        let api_key = localStorage.getItem('api_key');
                                        let id = parseInt(this.props.match.params.id);
                                        let result = await API.templates.delete(api_key, id);
                                        window.location.href = "/templates";
                                    }}>Delete</li>
                                </ul>
                            </label>
                        </span>
                    </div>
                    <div className="vacancy-table" style={{width:'calc(100% - 128px)'}}>
                        <span style={{width:'100%', display:'block'}}>Introduction Videos ({this.getCount() } / 3)</span>        
                    </div>
                    
                    
                    <div className="vacancy-table" style={{width:'calc(100% - 128px)', background:'transparent'}}>
                    { this.state.template.intro_video_url && <div className="uiFix" style={{background:'#FFF', width:'clamp(30px, 30%, 250px)', textAlign:'center', borderRadius:'8px', padding:'8px', display:'inline-block', marginRight:'8px'}}>
                            <span style={{paddingBottom:'8px', display:'block'}}>Company Intro</span>
                            <button className="blueBTN" style={{width:'80%'}} onClick={async ()=>{
                                this.videoPreviewRef.current.style.display = "flex";
                                this.videoPreviewVideoRef.current.src = this.state.template.intro_video_url;
                                this.videoPreviewVideoRef.current.time = 0;
                                this.videoPreviewVideoRef.current.play();
                            }}>Watch</button>
                            <button className="blueBTN red" style={{width:'80%'}} onClick={async ()=>{
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.templates.setITJ(api_key, this.state.template.id, "", "intro_video_url");                                                                                        
                                result = await API.templates.get(api_key, this.state.template.id);
                                let template = result['template'];
                                template['questions'] = JSON.parse(template['questions'], true); 
                                this.setState({
                                    ...this.state,
                                    template: template,
                                    loading: false,
                                });
                            }}>Delete</button>
                            </div>  
                        }
                    { !this.state.template.intro_video_url && <div className="uiFix" style={{background:'#FFF', width:'clamp(30px, 30%, 250px)', textAlign:'center', borderRadius:'8px', padding:'8px', display:'inline-block', marginRight:'8px'}}>
                        <span style={{paddingBottom:'8px', display:'block'}}>Company Intro</span>
                        <button className="blueBTN" style={{width:'80%'}} onClick={async ()=>{
                                let api_key = localStorage.getItem('api_key');
                                this.videoRecordRef.current.style.display = "flex";
                                this.videoRecordElementRef.current.resetUI();
                                this.videoRecordElementRef.current.openVideo();
                                this.videoRecordElementRef.current.setPreviewData(2, 0, 0, api_key, 0, this.state.template.id);
                                this.itj_value = "intro_video_url";
                        }}>Record</button>
                        <button className="blueBTN" style={{width:'80%', marginTop:'4px'}} onClick={async ()=>{
                            this.itj_value = "intro_video_url";
                            this.setState({
                                ...this.state,
                                canUploadDisplay: true                            
                            });
                        }}>Upload</button>
                        </div>  
                    }      
                    { this.state.template.meet_team_video_url &&  <div className="uiFix" style={{background:'#FFF', width:'clamp(30px, 30%, 250px)', textAlign:'center', borderRadius:'8px', padding:'8px', display:'inline-block', marginRight:'8px'}}>
                        <span style={{paddingBottom:'8px', display:'block'}}>Meet The Team</span>
                        <button className="blueBTN" style={{width:'80%'}} onClick={async ()=>{
                            this.videoPreviewRef.current.style.display = "flex";
                            this.videoPreviewVideoRef.current.src = this.state.template.meet_team_video_url;
                            this.videoPreviewVideoRef.current.time = 0;
                            this.videoPreviewVideoRef.current.play();
                        }}>Watch</button>
                        <button className="blueBTN red" style={{width:'80%'}} onClick={async ()=>{
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.templates.setITJ(api_key, this.state.template.id, "", "meet_team_video_url");                                                                                        
                                result = await API.templates.get(api_key, this.state.template.id);
                                let template = result['template'];
                                template['questions'] = JSON.parse(template['questions'], true); 
                                this.setState({
                                    ...this.state,
                                    template: template,
                                    loading: false,
                                });
                        }}>Delete</button>
                    </div> }   
                    
                    { !this.state.template.meet_team_video_url &&  <div className="uiFix" style={{background:'#FFF', width:'clamp(30px, 30%, 250px)', textAlign:'center', borderRadius:'8px', padding:'8px', display:'inline-block', marginRight:'8px'}}>
                            <span style={{paddingBottom:'8px', display:'block'}}>Meet The Team</span>
                            <button className="blueBTN" style={{width:'80%'}} onClick={()=>{
                                let api_key = localStorage.getItem('api_key');
                                this.videoRecordRef.current.style.display = "flex";
                                this.videoRecordElementRef.current.resetUI();
                                this.videoRecordElementRef.current.openVideo();
                                this.videoRecordElementRef.current.setPreviewData(2, 0, 0, api_key, 0, this.state.template.id);
                                this.itj_value = "meet_team_video_url";
                            }}>Record</button>
                            <button className="blueBTN" style={{width:'80%', marginTop:'4px'}} onClick={async ()=>{
                                this.itj_value = "meet_team_video_url";
                                this.setState({
                                    ...this.state,
                                    canUploadDisplay: true                            
                                });
                            }}>Upload</button>
                        </div> }         
                        { this.state.template.job_overview_video_url && <div className="uiFix" style={{background:'#FFF', width:'clamp(30px, 30%, 250px)', textAlign:'center', borderRadius:'8px', padding:'8px', display:'inline-block', marginRight:'8px'}}>
                            <span style={{paddingBottom:'8px', display:'block'}}>Job Overview</span>
                            <button className="blueBTN" style={{width:'80%'}} onClick={async ()=>{
                                this.videoPreviewRef.current.style.display = "flex";
                                this.videoPreviewVideoRef.current.src = this.state.template.job_overview_video_url;
                                this.videoPreviewVideoRef.current.time = 0;
                                this.videoPreviewVideoRef.current.play();
                            }}>Watch</button>
                            <button className="blueBTN red" style={{width:'80%'}} onClick={async ()=>{
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.templates.setITJ(api_key, this.state.template.id, "", "job_overview_video_url");                                                                                          
                                result = await API.templates.get(api_key, this.state.template.id);
                                let template = result['template'];
                                template['questions'] = JSON.parse(template['questions'], true); 
                                this.setState({
                                    ...this.state,
                                    template: template,
                                    loading: false,
                                });
                            }}>Delete</button>
                        </div>  } 
                            
                        { !this.state.template.job_overview_video_url && <div className="uiFix" style={{background:'#FFF', width:'clamp(30px, 30%, 250px)', textAlign:'center', borderRadius:'8px', padding:'8px', display:'inline-block', marginRight:'8px'}}>
                            <span style={{paddingBottom:'8px', display:'block'}}>Job Overview</span>
                            <button className="blueBTN" style={{width:'80%'}} onClick={async ()=>{
                            // SET ITJ INDEX, 0,1,2
                            let api_key = localStorage.getItem('api_key');
                            this.videoRecordRef.current.style.display = "flex";
                            this.videoRecordElementRef.current.resetUI();
                            this.videoRecordElementRef.current.openVideo();
                            this.videoRecordElementRef.current.setPreviewData(2, 0, 0, api_key, 0, this.state.template.id);
                            this.itj_value = "job_overview_video_url";                      
                            }}>Record</button>
                            <button className="blueBTN" style={{width:'80%', marginTop:'4px'}} onClick={async ()=>{
                                this.itj_value = "job_overview_video_url";
                                this.setState({
                                    ...this.state,
                                    canUploadDisplay: true                            
                                });
                            }}>Upload</button>
                        </div>  }       
                    </div>
                    
                    <div className="vacancy-table" style={{width:'calc(100% - 128px)'}}>
                        <span style={{width:'100%', display:'block'}}>Questions ({ this.state.template?.questions?.length })</span>
                    </div>
                    <div className="vacancy-table" style={{width:'calc(100% - 128px)'}}>
                        <table>
                        <thead>
                            <tr>
                                <th style={{textAlign:"left", width:"400px"}}>Title&nbsp; { this.state.questions && this.state.questions.length >= 1 && this.state.questions[0].type == "video" && <span style={{paddingLeft: '106px'}}>ID</span>}</th>
                                <th style={{textAlign:"left", width:"200px"}}>Question Type</th>
                                <th style={{textAlign:"left", width:"400px"}}>Recommend Answer Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{textAlign:"left", width:"400px"}}>
                                    <button className="blueBTN" onClick={()=>{
                                        this.questionCreationDialogRef.current.style.display = "flex";
                                        this.questionCreationDialogLoadingRef.current.style.display = "block";
                                        this.questionCreationDialogStep1Ref.current.style.display = "none";
                                        this.questionCreationDialogStep1TypeRef.current.style.display = "block";            
                                        this.questionCreationDialogStep2TextRef.current.style.display = "none";
                                        this.questionCreationDialogStep2VideoRef.current.style.display = "none";
                                        


                                        this.questionCreationDialogLoadingRef.current.style.display = "none";
                                        this.questionCreationDialogStep1Ref.current.style.display = "block";
                                        
                                    }}>Add Question</button>
                                </td>
                                <td style={{textAlign:"left", width:"200px"}}>-</td>
                                <td style={{textAlign:"left", width:"40px"}}>-</td>
                            </tr>
                                {
                                    this.state.template.questions && this.state.template.questions.map((question, index) => {
                                        return (
                                            <tr key={question.id}>
                                                <td style={{texAlign:"left", width:"400px"}}>
                                                    { question.type == "video" ? (
                                                        <>
                                                        <button video-url={question.video_url} className="previewQuestionVideo" onClick={()=>{                                                                
                                                            this.videoPreviewRef.current.style.display = "flex";
                                                            this.videoPreviewVideoRef.current.src = question.video_url;
                                                            this.videoPreviewVideoRef.current.time = 0;
                                                            this.videoPreviewVideoRef.current.play();
                                                        }}>Preview Video</button>
                                                        <button video-url={question.video_url} className="previewQuestionVideo red" style={{marginLeft:'8px'}} onClick={async ()=>{                                                             
                                                             this.setState({
                                                                ...this.state,
                                                                loading: true,
                                                            });     
                                                            let api_key = localStorage.getItem('api_key');
                                                            let result = await API.templates.removeQuestion(api_key, this.state.template.id, index);                                                            
                                                            result = await API.templates.get(api_key, this.state.template.id);
                                                            let template = result['template'];
                                                            template['questions'] = JSON.parse(template['questions'], true); 
                                                            this.setState({
                                                                ...this.state,
                                                                template: template,
                                                                loading: false,
                                                            });
                                                        }}>Delete Video</button>
                                                            <span style={{display:'inline-block', paddingLeft:'16px'}}>{question.id}</span>
                                                        </>
                                                    ) : (
                                                        <>{ question.text } <button video-url={question.video_url} className="previewQuestionVideo red" style={{marginLeft:'8px'}} onClick={async ()=>{                                                             
                                                            this.setState({
                                                               ...this.state,
                                                               loading: true,
                                                           });     
                                                           let api_key = localStorage.getItem('api_key');
                                                           let result = await API.templates.removeQuestion(api_key, this.state.template.id, index);                                                            
                                                           result = await API.templates.get(api_key, this.state.template.id);
                                                           let template = result['template'];
                                                           template['questions'] = JSON.parse(template['questions'], true); 
                                                           this.setState({
                                                               ...this.state,
                                                               template: template,
                                                               loading: false,
                                                           });
                                                       }}>Delete Question</button></>
                                                    )}
                                                </td>
                                                <td style={{textAlign:"left", width:"200px"}}>
                                                    { question.type }
                                                </td>
                                                <td style={{textAlign:"left", width:"400px"}}>
                                                    { question.recommend_answer_duration } Second(s)</td>
                                            </tr>                                            
                                        )
                                    })
                                }
                        </tbody>
                    </table>    
                    </div>
                    
                    { this.state.template.users && <div className="vacancy-table" style={{width:'calc(100% - 128px)'}}>
                        <span style={{width:'100%', display:'block'}}>Allocated Users ({ this.state.template?.users?.length ?? 0 })</span>
                        </div>
                    }
                    
                    <div className="vacancy-table" style={{width:'calc(100% - 128px)'}}>
                    {
                        this.state.template.users && this.state.template.users.map((user, index) => {
                            return (
                                <div>
                                    <span>{ user.name } - { user.email }</span>
                                </div>
                            )
                        })
                    }
                    {
                        ( !this.state.template.users || this.state.template.users.length == 0 ) && <div>
                            No users have been allocated
                        </div>  
                    }
                    </div>
                </div>
            </div>   

                    
            <div>
                    <div className="dialogNewCreate" ref={this.addToVacancyDialogRef}>        
                        <div>
                            <h4>Use Template</h4>
                            <div>
                                <input type="text"  ref={this.addToVacancyDialogTitleRef} className="createVacancyInput" placeholder="Vacancy Name"/>
                            </div>
                            <div>
                                <button onClick={ async ()=>{
                                    let title = this.addToVacancyDialogTitleRef.current.value;
                                    if(!title){
                                        toast("Enter a valid title");
                                        return;
                                    }
                                    let id = parseInt(this.addToVacancyDialogTitleRef.current.getAttribute('data-id'));
                                    this.setState({
                                        ...this.state,
                                        loading: true
                                    });
                                    this.addToVacancyDialogRef.current.style.display = "none";
                                    let api_key = localStorage.getItem('api_key');
                                    let result = await API.templates.use(api_key, id, title);
                                    this.setState({
                                        ...this.state,
                                        loading: false
                                    });
                                    window.location.href = "/vacancies";
                                }}>Create</button>
                                <button onClick={()=>{                                    
                                    this.addToVacancyDialogRef.current.style.display = "none";
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div> 
                <div>
                    <div className="dialogNewCreate" ref={this.videoPreviewRef}>        
                        <div>
                            <h4>Video Preview</h4>
                            <div>
                                <video style={{width:'100%'}} autoPlay playsInline ref={this.videoPreviewVideoRef}></video>
                            </div>
                            <div>
                                <button onClick={()=>{                                    
                                    this.videoPreviewVideoRef.current.pause();
                                    this.videoPreviewRef.current.style.display = "none";
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>   
            { 
                this.state.loading ? (
                    <div className="loader">                        
                        <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                    </div>
                ) : ""
            }
                      
            { 
                this.state.canUploadDisplay == true ? <div ref={this.videoUploadRef}>
                <FileUploadComponent  onclose={()=>{
                    this.setState({
                        ...this.state,
                        canUploadDisplay: false
                    });
                    this.videoUploadRef.current.style.display = 'none';
                }} oncompletion={async (video_url)=>{
                    let api_key = localStorage.getItem('api_key');
                    let result = await API.templates.setITJ(api_key, this.state.template.id, video_url, this.itj_value)
                    this.setState({
                        ...this.state,
                        loading: true,
                        canUploadDisplay: false
                    });
                    result = await API.templates.get(api_key, this.state.template.id);
                    let template = result['template'];
                    template['questions'] = JSON.parse(template['questions'], true); 
                    this.setState({
                        ...this.state,
                        template: template,
                        loading: false,
                    });
                }}></FileUploadComponent>
                </div> : ""
            }    
            <div ref={this.videoRecordRef} className="actionPopUp">
                <VideoRecordComponent noPreview={false} noPreview2={false} ref={this.videoRecordElementRef} fixWidth={true} onclose={()=>{
                    this.videoRecordRef.current.style.display = "none";
                }} oncompletion={async (video_url)=>{              
                    this.setState({
                        ...this.state,
                        loading: true,
                    });      
                    let api_key = localStorage.getItem('api_key');
                    let result = await API.templates.setITJ(api_key, this.state.template.id, video_url, this.itj_value);                                                                                                              
                    result = await API.templates.get(api_key, this.state.template.id);
                    let template = result['template'];
                    template['questions'] = JSON.parse(template['questions'], true); 
                    this.setState({
                        ...this.state,
                        template: template,
                        loading: false,
                    });
                    this.videoRecordRef.current.style.display = "none";
                }}></VideoRecordComponent>
            </div>
            <div ref={this.questionCreationDialogRef} className="actionPopUp">
                <div ref={this.questionCreationDialogLoadingRef} style={{width:'480px'}}>
                    <span>Loading, Please Wait</span>
                </div>
                <div ref={this.questionCreationDialogStep1Ref} style={{width:'480px'}}>
                    <span>Select a Question type</span>
                    <select ref={this.questionCreationDialogStep1TypeRef} style={{width:'100%', padding:'8px'}}>
                        {
                            this.state.questionTypeText && <option value="text">Text</option>
                        }
                        {
                            this.state.user.package_number >= 2 && this.state.questionTypeVideo && <option value="video">Video</option>
                        }
                    </select><br/><br/>
                    <button className="templateQuestionBTN" onClick={()=>{
                        this.questionCreationDialogRef.current.style.display = "none";
                    }}>Cancel</button>
                    {
                        (this.state.questionTypeText || this.state.questionTypeVideo) && <button className="templateQuestionBTN" onClick={()=>{
                            // get type
                            let type = this.questionCreationDialogStep1TypeRef.current.value; // text | video                        
                            this.questionCreationDialogStep1Ref.current.style.display = "none";
                            switch(type){
                                case 'text':
                                    this.questionCreationDialogStep2TextRef.current.style.display = "block";
                                    break;
                                case 'video':
                                    this.questionCreationDialogStep2VideoRef.current.style.display = "block";
                                    this.questionCreationDialogStep2VideoElementRef.current.resetUI();
                                    this.questionCreationDialogStep2VideoElementRef.current.openVideo();
                                    break;
                            }
                        }}>Next</button>
                    }
                </div>
                <div ref={this.questionCreationDialogStep2TextRef} style={{width:'500px'}}>
                    <span>Create a new Text Quesiton</span>
                    <sub style={{textAlign:'left', display:'block'}}>Enter your question below</sub>
                    <input type="text" placeholder="Question goes here" style={{width:'calc(100% - 16px)', padding:'8px'}} ref={this.questionCreationDialogStep2TextValueRef}/>
                    <sub style={{textAlign:'left', display:'block'}}>Select a recommend answer duration</sub>
                    <select ref={this.questionCreationDialogStep2TextTimeRef} style={{width:'100%', padding:'8px'}}>
                        <option value="30">30 Seconds</option>
                        <option value="60">1 Minute</option>
                        <option value="90">1.5 Minutes</option>
                    </select><br/><br/>       
                    <button className="templateQuestionBTN2" onClick={async()=>{
                        this.questionCreationDialogRef.current.style.display = "none";
                        let api_key = localStorage.getItem('api_key');
                        let result = await API.templates.get(api_key, this.state.template.id);
                        let template = result['template'];
                        template['questions'] = JSON.parse(template['questions'], true); 
                        this.setState({
                            ...this.state,
                            template: template,
                            loading: false,
                        });
                    }}>Cancel</button>
                    <button className="templateQuestionBTN2" onClick={async ()=>{                        
                        let api_key = localStorage.getItem('api_key');
                        let text = this.questionCreationDialogStep2TextValueRef.current.value;
                        if(!text){
                            toast("Enter a valid question");
                            return;
                        }
                        this.questionCreationDialogLoadingRef.current.style.display = "block";
                        this.questionCreationDialogStep2TextRef.current.style.display = "none";
                        toast('Creating Question');
                        let duration = parseInt(this.questionCreationDialogStep2TextTimeRef.current.value);
                        let result = await API.templates.addQuestionText(api_key, this.state.template.id, text, duration);
                        this.questionCreationDialogStep2TextValueRef.current.value = "";
                        this.questionCreationDialogStep2TextTimeRef.current.value = "30";
                        toast('Question Created');           
                        this.questionCreationDialogLoadingRef.current.style.display = "none";
                        this.questionCreationDialogStep2TextRef.current.style.display = "block";
                        result = await API.templates.get(api_key, this.state.template.id);
                        let template = result['template'];
                        template['questions'] = JSON.parse(template['questions'], true); 
                        this.setState({
                            ...this.state,
                            template: template,
                            loading: false,
                        });
                    }}>Create and Next</button>
                    <button className="templateQuestionBTN2" onClick={async ()=>{                                         
                        let api_key = localStorage.getItem('api_key');
                        let text = this.questionCreationDialogStep2TextValueRef.current.value;
                        if(!text){
                            toast("Enter a valid question");
                            return;
                        }
                        toast('Creating Question');         
                        this.questionCreationDialogLoadingRef.current.style.display = "block";
                        this.questionCreationDialogStep2TextRef.current.style.display = "none";
                        let duration = parseInt(this.questionCreationDialogStep2TextTimeRef.current.value);
                        let result = await API.templates.addQuestionText(api_key, this.state.template.id, text, duration);
                        toast('Question Created');
                        this.questionCreationDialogLoadingRef.current.style.display = "none";
                        this.questionCreationDialogRef.current.style.display = "none";
                        result = await API.templates.get(api_key, this.state.template.id);
                        let template = result['template'];
                        template['questions'] = JSON.parse(template['questions'], true); 
                        this.setState({
                            ...this.state,
                            template: template,
                            loading: false,
                        });
                    }}>Create and Done</button>
                </div>
                <div ref={this.questionCreationDialogStep2VideoRef} style={{width:'480px'}}>
                    <span>Record a video below, and select a answer duration</span>
                    <sub style={{textAlign:'left', display:'block'}}>Select a recommend answer duration</sub>
                    <select ref={this.questionCreationDialogStep2VideoTimeRef} style={{width:'100%', padding:'8px'}}>
                        <option value="30">30 Seconds</option>
                        <option value="60">1 Minute</option>
                        <option value="90">1.5 Minutes</option>
                    </select><br/><br/>   
                    <VideoRecordComponent nopreview={false} nopreview2={false} nopreview3={true} getDuration={() => { return this.questionCreationDialogStep2VideoTimeRef.current.value; }} ref={this.questionCreationDialogStep2VideoElementRef} getTemplateID={()=>{ return this.state.template.id; }} fixWidth={true} onclose={()=>{
                        this.questionCreationDialogRef.current.style.display = "none";
                    }} onnopreview={()=>{
                        this.questionCreationDialogStep2VideoRef.current.style.display = "none";
                        this.questionCreationDialogStep2VideoOptionsRef.current.style.display = "block";
                    }}></VideoRecordComponent>
                </div>
                <div ref={this.questionCreationDialogStep2VideoOptionsRef} style={{width:'480px'}}>
                    <div>Your question will be processed and added to the template when it is done.</div>
                    <div>Would you like to create another question or are you done?</div><br/><br/>
                    <button className="templateQuestionBTN" onClick={async ()=>{                                         
                        this.questionCreationDialogStep2VideoTimeRef.current.value = "30";
                        this.questionCreationDialogStep2VideoRef.current.style.display = "block";
                        this.questionCreationDialogStep2VideoElementRef.current.resetUI();
                        this.questionCreationDialogStep2VideoElementRef.current.openVideo();
                        this.questionCreationDialogStep2VideoOptionsRef.current.style.display = "none";
                    }}>Create Another</button>
                    <button className="templateQuestionBTN" onClick={async ()=>{                                         
                        this.questionCreationDialogRef.current.style.display = "none";
                    }}>Done</button>
                </div>
            </div>
                </>
        );
      }
}


export default withRouter(TemplatePage); 