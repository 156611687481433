import React, { createRef } from "react";

import API from './../services/api';

import "./library.css";

import * as $ from 'jquery';

export default class LibraryComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          video_url: null,
      };

      // Parent Component Functions (PCFs)
      this.OnSave = props.OnSave;

      // REFs
      this.dialogRef = createRef();
      this.step0Ref = createRef();
      this.step0VideoRef = createRef();

      this.step1Ref = createRef();
      this.step1TitleRef = createRef();

      // BINDs
      this.yes = this.yes.bind(this);
      this.no = this.no.bind(this);
      this.close = this.close.bind(this);
      this.save = this.save.bind(this);
      this.showDialog = this.showDialog.bind(this);
      this.hideDialog = this.hideDialog.bind(this);
      this.setVideo = this.setVideo.bind(this);
      this.setStep = this.setStep.bind(this);
    }

    componentDidMount(){
        this.hideDialog();
    }

    setVideo(video_url){
        this.setState({
          ...this.state,
          video_url: video_url
        });
    }

    showDialog(){
      this.dialogRef.current.style.display = 'flex';
    }
    
    hideDialog(){
      this.dialogRef.current.style.display = 'none';
    }

    setStep(step){
      this.step0Ref.current.style.display = 'none';
      this.step1Ref.current.style.display = 'none';
      switch(step){
        case 0:
          this.step0Ref.current.style.display = 'block';
          break;        
        case 1:
          this.step1Ref.current.style.display = 'block';
          break;        
      }
    }

    yes(){
      this.setStep(1);
    }
    
    no(){
      this.setStep(0);
      this.hideDialog();
    }

    close(){
      this.hideDialog();
      this.setStep(0);
    }
    
    save(){
      this.OnSave(this.step1TitleRef.current.value, this.state.video_url); // TITLE, VIDEO_URL
      this.close();
    }

    render() {
        // HTML
        return ( 
            <div className="actionPopUp" ref={this.dialogRef}>
              <div ref={this.step0Ref}>
                <h4>Add this video the the library</h4>
                <video src={this.state.video_url} autoplay playsInline ref={this.step0VideoRef} style={{maxWidth:'480px', maxHeight:'480px'}} controls={true}></video>
                <div>
                  <button onClick={this.yes}>Yes</button>
                  <button onClick={this.no}>No</button>
                </div>
              </div>
              <div ref={this.step1Ref}>
                <h4>Add a title to the video</h4>
                <input type="text" placeholder="Title Here" ref={this.step1TitleRef} style={{width:'250px', padding:'12px', margin:'12px'}}/>
                <div>
                  <button onClick={this.save}>Save</button>
                  <button onClick={this.close}>Close</button>
                </div>
              </div>
            </div>
        );
      }
}