import React from "react";

import API from './../services/api';

import "./sidebar.css";

import HomeIcon from './icons/home.svg';
import MetricsIcon from './icons/metrics.svg';
import ProfileIcon from './icons/profile.svg';
import OrganisationIcon from './icons/organisation.svg';
import SeatsIcon from './icons/seats.svg';
import BriefIcon from './icons/brief.svg';
import UpgradeIcon from './icons/upgrade.svg';
import APIIcon from './icons/api.svg';
import TutorialIcon from './icons/tutorial.svg';
import SettingsIcon from './icons/settings.svg';
import LogIcon from './icons/log.svg';

import { AiFillDollarCircle, AiOutlineShareAlt, AiOutlineMenuFold } from 'react-icons/ai';

import { HiTemplate } from 'react-icons/hi';
import { MdOutlineResetTv } from "react-icons/md";



export default class SideBarComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          user: {
            package_number: 0,
            isAdmin: 0,
            isTester: 0
          },
          toggle:  localStorage.getItem("sidebar-toggle")
      };
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        if(!api_key){
            window.location.href = "/login";
            return;
        }
        let result = await API.user.getUser(api_key);
        let user = result['user'];
        this.setState({...this.state, user: user});
    }

    render() {
        // HTML
        return (
            <div className={"sidebar " +  (this.state.toggle == 1 ? " min" : "")}>
                <div className="sidebar-logo"></div>
                <div className="simpleHover" style={{display:'inline-block', cursor:'pointer'}} onClick={()=>{
                    localStorage.setItem("sidebar-toggle", 0);
                    this.setState({
                        ...this.state,
                        toggle: 0
                    });
                    document.querySelector('.sidebar').classList.toggle('min');
                }}><AiOutlineMenuFold className="minime2"/></div>
                <div className="simpleHover" style={{display:'inline-block', transform: "translate(232px, -44px)", cursor:'pointer'}} onClick={()=>{
                    localStorage.setItem("sidebar-toggle", 1);
                    this.setState({
                        ...this.state,
                        toggle: 1
                    });
                    document.querySelector('.sidebar').classList.toggle('min');
                }}><AiOutlineMenuFold className="minime"/></div>
                <div className="nav">
                    <a href="/dashboard">
                        <img src={HomeIcon} width="32px" height="32px" className="icon" alt="Dashboard"/>
                        <span>Dashboard</span>
                    </a>
                    {
                        this.state.user.isAdmin || this.state.user.isSeller ? (
                            <a href="/metrics">
                                <img src={MetricsIcon} width="32px" height="32px" className="icon" alt="Metrics"/>
                                <span>Metrics</span>
                            </a>
                        ) : ""
                    }
                    <a href="/profile">
                        <img src={ProfileIcon} width="32px" height="32px" className="icon" alt="My Profile"/>
                        <span>My Profile</span>
                    </a>
                    {
                        this.state.user.isAdmin ? (
                            <a href="/org">
                                <img src={OrganisationIcon} width="32px" height="32px" className="icon" alt="Organisation"/>
                                <span>Organisation</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.seat_user != 1 && this.state.user.package_number !== 1 &&  this.state.user.package_number !== 0 ? (
                            <a href="/seats">
                                <img src={SeatsIcon} width="32px" height="32px" className="icon" alt="Seats"/>
                                <span>Seats</span>
                            </a>
                        ) : ""
                    }
                    <a href="/vacancies">
                        <img src={BriefIcon} width="32px" height="32px" className="icon" alt="Vacancies"/>
                        <span>Vacancies</span>
                    </a>
                    <a href="/assessment">
                        <img src={BriefIcon} width="32px" height="32px" className="icon" alt="Surveys"/>
                        <span>Assessment</span>
                    </a>
                    <a href="/introvideomanager">
                        <img src={BriefIcon} width="32px" height="32px" className="icon" alt="Surveys"/>
                        <span>Intro Video Manager</span>
                    </a>
                    <a href="/templates">
                        <HiTemplate style={{transform:'scale(1.5)', color:'#FFF', display:'inline-block', marginRight:'18px'}}/>
                        <span style={{transform:'translateY(-3px)'}}>Templates</span>
                    </a>

                    { this.state.user.hasMarketing || this.state.user.hasMarketingNoPay || this.state.user.isAdmin ? (<a href="/marketing">
                        <AiOutlineShareAlt style={{transform:'scale(1.5)', color:'#FFF', display:'inline-block', marginRight:'18px'}}/>
                        <span style={{transform:'translateY(-3px)'}}>Marketing</span></a>) : ""
                    }
                    {
                        this.state.seat_user == 0 || this.state.seat_user == null ? (
                            <a href="/upgrade">
                                <img src={UpgradeIcon} width="32px" height="32px" className="icon" alt="Upgrade"/>
                                <span>Upgrade Account</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.package_number == 4 || this.state.user.package_number == 5 ? (
                            <a href="/integration">
                                <img src={APIIcon} width="32px" height="32px" className="icon" alt="Integration"/>
                                <span>Integration</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.isAdmin ? (
                            <a href="/tutorial">
                                <img src={TutorialIcon} width="32px" height="32px" className="icon" alt="Tutorial"/>
                                <span>Tutorial Settings</span>
                            </a>
                        ) : ""
                    }
                    {
                        false && this.state.user.isAdmin ? (
                            <a href="/admin/paypal">
                                <img src={TutorialIcon} width="32px" height="32px" className="icon" alt="Tutorial"/>
                                <span>PayPal Management</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.isAdmin ? (
                            <a href="/pricing">
                                <AiFillDollarCircle style={{width:'24px', height:'24px', lineHeight:'24px', display:'block', color:'#FFF', transform:'translateX(-2px)'}} className="icon" alt="Pricing"/>
                                <span style={{transform:'translateY(-21px) translateX(33px)'}}>Pricing</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.isAdmin ? (
                            <a href="/promocodeusage">
                                <AiFillDollarCircle style={{width:'24px', height:'24px', lineHeight:'24px', display:'block', color:'#FFF', transform:'translateX(-2px)'}} className="icon" alt="Pricing"/>
                                <span style={{transform:'translateY(-21px) translateX(33px)'}}>Promotion Code Usage</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.isAdmin ? (
                            <a href="/settings">
                                <img src={SettingsIcon} width="32px" height="32px" className="icon" alt="Settings"/>
                                <span>Settings (Global)</span>
                            </a>
                        ) : ""
                    }
                    {
                        this.state.user.isAdmin ? (
                            <a href="/logs">
                                <img src={LogIcon} width="32px" height="32px" className="icon" alt="Logs"/>
                                <span>Logs</span>
                            </a>
                        ) : ""
                    }
                    <a href="/clear-internal">
                        <MdOutlineResetTv style={{width:'24px', height:'24px', lineHeight:'24px', display:'block', color:'#FFF', transform:'translateX(-2px)'}} className="icon" alt="Clear Browser Data"/>
                        <span>Clear Browser Data</span>
                    </a>
                </div>
            </div>
        );
      }
}