import React, { useState, useEffect, useRef } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { DataGrid, GridColumnHeaderParams } from '@mui/x-data-grid';
import './bespokeFolder.css';
import API from '../../../services/api';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateTime } from 'luxon';
import { IoIosPaperPlane } from 'react-icons/io';
import { BsCameraVideoFill, BsPencil, BsPersonPlusFill } from 'react-icons/bs';
import { RiShareForward2Fill } from 'react-icons/ri';
import { AiFillEye, AiFillStar } from 'react-icons/ai';
import { MdClear, MdDelete, MdOutlineAssessment } from 'react-icons/md';
import { ImBin2 } from 'react-icons/im';
import useAbortableAxios from '../../../hooks/useAbortableAxios';
import { MARKETING_PULLING_SECONDS } from '../MARKETING_PULLING_SECONDS';


import play_icon_grey from "./../../../media/play_icon_grey.png";
import play_icon_color from "./../../../media/play_icon_color.png";

import { Tooltip as ReactTooltip } from "react-tooltip";
interface CampaignFolderProps {
    apiKey:string;
    marketing:any;
    createLeadCallback: (callback: (data:any) => Promise<null | {
      closed:boolean;
      copied:number;
      created_at: null | string | Date
      email: null | string
      custom_video_url: string | null;
      hasViewed:boolean;
      viewTime:string;
      id: number
      marketing_id:  null | number
      linkedin_url: null | string
      marketing_type: null | number
      marketingType_id: null | number;
      marketing_thumbnail: null | string;
      name: null | string;
      phone: null | string;
      processing_gif: null | number;
      processing_video: null | number; 
      processing_done: null | number;
      updated_at: null | string | Date;
  }>) => void;
  executeCreateLead: () => void;  
  reloadCampaignFolder: (callback: () => Promise<void>) => void;  
  onShowSendEmail: (subject:string, to:string, body:string) => void;
  reloadMarketing: () => void;  
  executeCampaignFlow: () => void;
  executeHandleMarketingVideoOptions: () => void;
}

interface CandidateEditInterface {
  id: number;
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}
interface CandidateAddInterface {
  name: string;
  email: string;
  linkedin: string;
  phone_number: string;
}

const theme = createTheme({
    palette: {
      mode: 'light', // Force light mode
    },
} as any);

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        '&:hover': {
          color: '#1976D1'
        },
      },
    },
  }));

function CampaignFolderCandidateGrid({ loading, columns, rows}) {
    return (<ThemeProvider theme={theme}>
    <DataGrid
      //loading={loading}
      rows={rows}
      columns={columns}
      disableColumnMenu={true}
      disableRowSelectionOnClick
      getRowId={(row) => row.id}
      getRowHeight={() => 'auto'}
    />
  </ThemeProvider>)
}

function ActionDropDownMenuForList({handleEdit}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
        <MenuItem onClick={()=>{handleEdit(); handleClose();}} disableRipple>
          Edit
        </MenuItem>
        </StyledMenu>
      </div>
    );
  }


export default function CampaignFolder(props:CampaignFolderProps){
    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    const { loading:apiLoading, data:apiData, error:apiError, fetchData:fetchAPIData, setLoading:setAPILoading} = useAbortableAxios();
    const { loading:apiWebsiteLoading, data:apiWebsiteData, error:apiWebsiteError, fetchData:fetchWebsite, setLoading:setAPIWebsiteLoading} = useAbortableAxios();
    const { loading:apiMarketingTextLoading, data:apiMarketingTextData, error:apiMarketingTextError, fetchData:fetchAPIMarketingTextData, setLoading:setAPIMarketingTextLoading} = useAbortableAxios();
    const { loading:apiMarketingCustomTextLoading, data:apiMarketingCustomTextData, error:apiMarketingCustomTextError, fetchData:fetchAPIMarketingCustomTextData, setLoading:setAPIMarketingCustomTextLoading} = useAbortableAxios();
    const [ leads, setLeads ] = useState<any[]>([]);
    // Filters
    const [ filterTextSearch, setFilterTextSearch ] = useState<string>("");
    const [ filterCandidatesWithRecordedVideo, setFilterCandidatesWithRecordedVideo ] = useState<boolean>(false);
    const [ filterCandidatesWithNoRecordedVideo, setFilterCandidatesWithNoRecordedVideo ] = useState<boolean>(false);
    const [ filterCandidateReadyToSend, setFilterCandidateReadyToSend ] = useState<boolean>(false);
    const [ filterCandidatesHasBeenViewed, setFilterCandidatesHasBeenViewed ] = useState<boolean>(false);
    // Pulling
    const pullLeadsIntervalRef = useRef<any>(null);
    // State
    const [ ourWebsite, setOurWebsite ] = useState<string>("");
    const getProcessingTag = (lead:any) => {
      if(lead.hasViewed && lead.closed) return <><span data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagBlue">Viewed</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
      if(lead.hasViewed && !lead.closed) return <><span data-tip data-for={`tooltip-${lead.id}-bespoke`} className="tagGreen">New View</span><ReactTooltip id={`tooltip-${lead.id}-bespoke`} aria-haspopup='true'><span><strong>Viewed At:</strong>&nbsp;{ new Date(lead.serverTime).toLocaleString()}</span></ReactTooltip></>;
      if(lead.copied) return <div className="blueBTN7">Copied</div>;
      if(lead.processing_done) return <div className="blueBTN7">Ready to send</div>;
      if(lead.processing_gif) return <div className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
      if(lead.processing_video) return <div className="blueBTN7"><img className="spin" src={play_icon_color} alt=""  style={{transform:'translate(-3px, 3px)',width:'18px', height:'18px', pointerEvents: 'none'}} /><span style={{transform: 'translateY(-3px)',display: 'inline-block'}}>Processing</span></div>;
    }
    const dataGridColumns = [
      {field: 'lead',  headerName: 'LEAD', width: 380, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>LEAD</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return <div>
            <div className='lead-picture'></div>
            <div className='lead-details'>
              <h4>{ row.name }</h4>
              <span>{ row.email }</span>
              <span>{ row.phone }</span>
            </div>
          </div>;
      }},
      {field: 'id',  headerName: 'ID', width: 180, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>ID</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return `${row.id}`;
      }},
      {field: 'actions',  headerName: 'ACTIONS', width: 420, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>ACTIONS</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return <div>
            <button title="Delete Lead" className='lead-action-button-red' onClick={ async e => {
              const response = await API.marketing.leads.delete(props.apiKey, row.id);
              if(!response['success']){
                toast.error("Unable to delete lead");
                return;
              } 
              loadLeads(props.apiKey, props.marketing.id);
              toast.error("Lead Deleted");
            }}><MdDelete /></button>
            { (!!props.marketing.campaign_video_url && row.processing_done === 1) && <button className='lead-action-button-blue' onClick={ async e => {
              toast.info("Loading");
              const response = await fetch("https://api.clipdrop.io/api/v1/marketing/lead/invite/get/marketing", {
                body: JSON.stringify({
                  api_key: props.apiKey,
                  campaign_id: props.marketing.id,
                  lead_id: row.id,
                }),
                method:'POST',
                headers: {"Content-Type":"application/json"}
              });
              const data = await response.json();
              const success = data['success'];
              if(!success){
                toast.error("Unable To Get Invite Link");
                return;
              }
              const link = data['link'];
              const to = row.email;
              const subject = "Campaign Marketing Invite";
              const body = props.marketing.campaign_text.replace("{lead}", row.name) + "\n\n" + link;
              props.onShowSendEmail(subject, to, body);
            }}><IoIosPaperPlane/></button>}<br/><br/>            
          </div>;
      }},
      {field: 'viewed_date',  headerName: 'Viewed', width: 180, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Viewed</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return getProcessingTag(row);
      }},
      {field: 'creation_date',  headerName: 'Creation Date', width: 180, sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        return <span style={{ color:'#E1E1E1 !important', fontSize:'12pt'}}>Creation Date</span>
      },
      renderCell: (params) => {
          const { value, row, rowIndex } = params;
          return `${(new Date(row.created_at)).toDateString()}`;
      }},
  ];
    // Functions
    const loadLeads = async (api_key:string, marketing_id:string,
        xsearchQuery:string = "",
        xpageNumber:number = 0) => {
        fetchAPIData(API.baseURL + "/api/v1/marketing/lead/list", {}, {
          api_key: api_key,
          marketing_id: marketing_id,
          type_id: 1,
        }, "POST");
        if(pullLeadsIntervalRef.current){
          clearInterval(pullLeadsIntervalRef.current);
        }
        pullLeadsIntervalRef.current = setInterval(()=>{
          loadLeads(api_key, marketing_id);
        }, MARKETING_PULLING_SECONDS * 1000);
      };
    // Effects    
    useEffect(()=>{
      if(!apiData) return;
      // Filter Data
      let dataToFilter:any[] = apiData["leads"];
      if(!!filterTextSearch){
        const regex = new RegExp(filterTextSearch)
        dataToFilter = dataToFilter.filter((c:any, index:number) => {
          return regex.test(c.name) || regex.test(c.email) || regex.test(c.id);
        });
      }
      setLeads(dataToFilter);
    }, [apiData, filterTextSearch]);

    useEffect(()=>{
      if(!props.marketing) return;
      loadLeads(props.apiKey, props.marketing.id);
    }, [ props.marketing]);

    // Renders
    if(!props.marketing){
        return <div className='interview-folder-container'>Loading Folder Data</div>
    }

    return <><div className='interview-folder-container'>
        <div className='nice-form-2'>
            <div>
              <h4 style={{display:'block', marginRight:'8px'}}>Actions</h4>
              <button style={{display:'inline-block', fontSize:'16pt'}} className={ false ? 'button-primary-locked-out' : "button-primary-orange"} onClick={ e => { 
                  // TODO: START FLOW
                  props.executeCampaignFlow();
              }}>Start</button>
              <button style={{display:'inline-block', fontSize:'16pt'}}className='button-primary-blue' onClick={ e => setShowSettings(x => !x)}>Edit</button>
            </div>
            { showSettings && <>            
            <h4>Settings</h4>
                <div className='greyed' style={{minHeight:'420px', minWidth:'360px'}}>
                    <label htmlFor="allow-candidate-participation">
                    <span>Marketing Video</span>
                    <div style={{position:'relative', minHeight:'400px'}}>
                      <video src={props.marketing.campaign_video_url ?? ""} style={{position:'absolute', width:'360px', maxWidth:'360px', height:'360px', maxHeight:'360px', borderRadius:'8px'}} playsInline={true} controls={true}></video>
                      <button className="blueBTN" style={{position:'absolute', transform:'translate(305px, 300px)'}}  onClick={()=>{
                          toast.info("Loading");
                          props.executeHandleMarketingVideoOptions();
                      }}><BsCameraVideoFill fontSize={"22pt"}/></button>
                    </div>
                    </label>
                </div>
                <div className='greyed' style={{minHeight:'424px', minWidth:'360px'}}>
                    <label htmlFor="allow-candidate-participation">
                        <span>Marketing Caption Text</span>
                        <textarea onChange={ async e => {
                          await fetchAPIMarketingTextData(`${API.baseURL}/api/v1/marketing/campaign/campaign/marketing/text`, {
                            api_key: props.apiKey,
                            id: props.marketing.id,
                            text: e.target.value,
                          }, {}, "POST");
                          props.reloadMarketing();
                        }} style={{display:'block', width: 'calc(100% - 24px)', padding: '8px 12px', margin: '0px', height: '105px'}} defaultValue={props.marketing.campaign_text}></textarea>
                    </label>
                    <label htmlFor="allow-candidate-participation">
                        <span>Marketing Custom Gif Text</span>
                        <select style={{width:'100%', padding:'12px'}} onChange={ async (e) => {
                          if(e.target.value === "3"){
                            toast.info("Loading, please waits");
                          }
                          await fetchWebsite(`${API.baseURL}/api/v1/marketing/campaign/set/thumbnailtype`, {
                            api_key: props.apiKey,
                            id: props.marketing.id,
                            value: e.target.value,
                          }, {}, "POST");
                          props.reloadMarketing();
                        }} defaultValue={props.marketing.custom_thumbnail_outreach_type}>
                            <option value='0'>Hi {"{lead}"}</option>
                            <option value='1'>Hello {"{lead}"}</option>
                            <option value='2'>Hello {"{lead}"}</option>
                            <option value='3'>Custom Message</option>
                        </select>
                        {
                            props.marketing.custom_thumbnail_outreach_type === 3 && <div>
                              <span>Enter a custom message:</span>
                              <input style={{width:'calc(100% - 27px) !important',padding:'12px'}} type="text"  onChange={ async (e) => {
                                await fetchAPIMarketingCustomTextData(`${API.baseURL}/api/v1/marketing/campaign/set/thumbnailtext`, {
                                  api_key: props.apiKey,
                                  id: props.marketing.id,
                                  text: e.target.value,
                                }, {}, "POST");
                                props.reloadMarketing();
                              }} defaultValue={props.marketing.bulk_marketing_text}/>
                            </div>
                        }
                    </label>
                </div>
            </>}
            </div><br/>
            <div style={{marginBottom:'8px'}}>
                <div className="marketing-bespoke-input-group" style={{marginRight:'12px'}}>
                    <h4 style={{transform:'translateY(22px)'}}>Leads</h4>
                    <span style={{fontSize:'9pt'}}>{ 1 > 1 ? `${1} leads` : `${1} lead`}</span>
                </div>
                <div className="marketing-bespoke-input-group">
                    <input placeholder='Search here' onChange={ e => { setFilterTextSearch(e.target.value.trim()) }}/>
                    <button className='blueBTN' style={{marginLeft:'4px'}}>Search</button>
                    <button className='blueBTN' style={{marginLeft:'4px', width:'200px', transform:'translateY(2px)'}} onClick={ e => {
                      if(!props.marketing.campaign_video_url || props.marketing.campaign_video_url == "" || props.marketing.campaign_video_url == null){
                        toast.warning("Warning! Before you add a lead you must please click the start button and finish the recording process");
                        return;
                      }
                      props.createLeadCallback(async (data:any)=>{
                        const response = await API.marketing.leads.create2(props.apiKey, data.name, data.email, data.phone, data.linkedin_url, props.marketing.id, 1);
                        if(!response['success']){
                          toast.error(`Unable to create lead - ${response['message']}`);
                          return null;
                        }
                        return response['lead'];
                      });
                      props.executeCreateLead();
                    }}>Add Lead</button>
                </div>
            </div>
            <CampaignFolderCandidateGrid loading={false} columns={dataGridColumns} rows={leads}/>
        </div>
    </>
}