import React, { createRef } from "react";
import API from "../services/api";
import FileUploadComponent from "./fileupload";

import './videocontroller.css';

export default class ImageControllerComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          image_url: props.defaultValue,
          progress: 0
      };

      this.onChange = props.onChange;

      this.step1Ref = createRef();
      this.step2Ref = createRef();
      this.progressRef = createRef();
      this.fileRef = createRef();
      this.previewUploadRef = createRef();

      this.startUpload = this.startUpload.bind(this);
      this.getFileData = this.getFileData.bind(this);
      this.updateProgress = this.updateProgress.bind(this);
      this.hide = this.hide.bind(this);

      this.showVideoPreview = props.showPreview;

      this.fileUploadRef = createRef();
      this.videoUploadRef = createRef();
      this.videocontrollerRef = createRef();
    }

    async componentDidMount(){
        this.videoUploadRef.current.style.display = "none";
        this.step1Ref.current.style.display = "none";
        this.step2Ref.current.style.display = "none";
        this.previewUploadRef.current.style.display = "none";
    }

    async setURL(value){
        this.uploadComponentRef.current.style.display = "none";
        this.setState({
            ...this.state,
            video_url: value
        });
    }

    
  updateProgress(percentage){
    this.setState({
      ...this.state,
      progress: percentage
    });
  }
  
    
  async startUpload(){
    let files = this.fileRef.current.files;
    if(!files || files.length == 0){
      alert("Please select a file");
      return;
    }
    this.step1Ref.current.style.display = "none";
    this.step2Ref.current.style.display = "block";
    let api_key = localStorage.getItem('api_key');
    let file = files[0];
    let data = await this.getFileData(file);
    let datastripped = data.split(';base64,')[1];
    let extension = file['name'];
    let filename = await API.file.uploadFileWithProgress(api_key, extension, datastripped, this.updateProgress);
    this.onChange(filename);
    this.hide();
    this.setState({
        ...this.state,
        image_url: filename
      });
      this.videocontrollerRef.current.scrollTo();
  }

  hide(){
    this.videoUploadRef.current.style.display = "none";
    this.step1Ref.current.style.display = "none";
    this.step2Ref.current.style.display = "none";
  }
  
  show(){
    this.videoUploadRef.current.style.display = "flex";
    this.step1Ref.current.style.display = "block";
    this.step1Ref.current.scrollTo();
    this.step2Ref.current.style.display = "none";
  }
  

  async getFileData(file){
    return new Promise((resolve, reject)=>{
        let reader =  new FileReader();
        reader.onload = function(e) {
            resolve(e.target.result);
        };
        reader.readAsDataURL(file);
    });
}

    render() {
        // HTML
        return (
            <>
            <div className="videocontroller" ref={this.videocontrollerRef}>
                {
                    this.state.image_url ? <div>
                        <button onClick={async()=>{                    
                            this.show();
                        }} className="redHover" >Add Image</button>
                        <button style={{borderRadius:'0px'}} onClick={()=>{
                            this.previewUploadRef.current.style.display = "flex";
                        }}>Preview Image</button>
                        <input type="text" readOnly={true} placeholder="Image Link" value={this.state.image_url} style={{width:'390px'}}/>
                    </div> : <div>
                        <button onClick={async()=>{                    
                            this.show();
                        }}>Add Image</button>
                        <input type="text" readOnly={true} placeholder="Image Link" value={this.state.image_url}/>
                    </div>
                }
            </div>
            <div className="filedialogNew" ref={this.videoUploadRef}>
              <div ref={this.step1Ref} style={{transform:'translateY(-400px)'}}>
                <h4>Upload a file</h4>
                <span>Select a file to upload</span>
                <input ref={this.fileRef} type="file" accept={[".png", ".jpg"]}/>
                <div>
                  <button onClick={this.hide}>Close</button>
                  <button onClick={this.startUpload}>Upload</button>
                </div>
              </div>
              <div ref={this.step2Ref} style={{transform:'translateY(-400px)'}}>
                <h4>File Uploading - please wait</h4>
                <span>Upload Percentage: { this.state.progress }%</span>
                <progress className="uploadProgress" ref={this.progressRef} value={this.state.progress} max="100"></progress>
              </div>
            </div>
            <div className="filedialogNew" ref={this.previewUploadRef}>
              <div style={{transform:'translateY(-400px)'}}>
                <h4>Preview</h4>
                <img src={this.state.image_url} alt="" style={{maxHeight:'150px'}}/><br/>
                <button className="blueBTN" onClick={()=>{
                    this.previewUploadRef.current.style.display = "none";
                }} style={{width:'100%'}}>Close</button>
              </div>
            </div>
            </>
        )
    }
}