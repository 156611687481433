import React, { useEffect, useState } from 'react';
import MultiOptionItem from './MultiOptionItem';
import MultiOptionNewItem from './MultiOptionNewItem';

import './MultiOption.css';


export interface MultiOptionProps {
    onChange: (data: any) => void
}

export default function MultiOption(props:MultiOptionProps){
    const { onChange } = props;
    const [question, setQuestion] = useState<string>("");
    const [items, setItems] = useState<any[]>([]);

    // save all changes to calling component
    useEffect(()=>{
        onChange({
            question: question,
            items: items
        });
    }, [question, items]);

    return (
        <div className='MultiOptionContainer'>            
            <h4>Question</h4>
            <input type="text" className='MultiOptionContainerQuestion' onChange={(event)=>{
                setQuestion(event.target.value);
            }}/>
            <h4>Answers</h4>
            {
                items.map((item, index)=>{
                    return <MultiOptionItem onChange={(text)=>{                        
                        let newItems = [...items];
                        newItems[index] = text;
                        setItems(newItems);
                    }} onDelete={()=>{
                        let newItems = [...items];
                        newItems.splice(index, 1);
                        setItems(newItems);
                    }} defaultText={item}/>
                })
            }
            <MultiOptionNewItem onClick={()=>{
                setItems([...items, '']);
            }}/>
        </div>
    )
}