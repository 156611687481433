import React, { createRef } from "react";

import API from './../services/api';

import fuzzytime from 'fuzzy-time';

import "./jobboard.css";
import ToggleComponent from "./toggle";
import VideoControllerComponent from "./videocontroller";

import VideoRecordComponent from "./videorecord";
import ImageControllerComponent from "./ImageController";

export default class JobBoardComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        user: props.user,
        interview: props.interview,
        changesToSave: {
            job_board_logo: props.interview.job_board_logo,
            job_board_title: props.interview.job_board_title,
            job_board_description: props.interview.job_board_description,
            job_board_company: props.interview.job_board_company,
            job_board_location: props.interview.job_board_location,
            job_board_currency: props.interview.job_board_currency,
            job_board_salary_min: props.interview.job_board_salary_min,
            job_board_salary_max: props.interview.job_board_salary_max,
            job_board_ats: props.interview.job_board_ats,
            job_board_interview: props.interview.job_board_interview,
            job_board_video_url: props.interview.job_board_video_url,
            job_board_enabled: props.interview.job_board_enabled
        }
      };
      
      this.hideShowBtnRef = createRef();
      this.dropdownRef = createRef();
      this.videoComponentRef = createRef();
      this.videoRecordJobBoardRef = createRef();
      this.videoRecordJobBoardRefElement = createRef();
      this.videoRecordJobBoardRefPreview = createRef();
      this.videoRecordJobBoardRefPreviewVideo = createRef();

      this.toggle = this.toggle.bind(this);
      this.showUpgrade = this.showUpgrade.bind(this);  
      
    }

    async componentDidMount(){
        this.hideShowBtnRef.current.innerHTML = "Show";
        this.dropdownRef.current.style.display = "none";
        this.videoRecordJobBoardRefPreview.current.style.display = "none";
        if(this.videoRecordJobBoardRef.current){
            this.videoRecordJobBoardRef.current.style.display = "none";
        }
    }
    

    toggle(){
        if(this.dropdownRef.current.style.display == "none"){
            this.hideShowBtnRef.current.innerHTML = "Hide";
            this.dropdownRef.current.style.display = "flex";
        } else {            
            this.hideShowBtnRef.current.innerHTML = "Show";
            this.dropdownRef.current.style.display = "none";
        }
    }
    
    showUpgrade(){
        // TODO: SHOW UPGRADE POPUP
        window.location.href = "/upgrade";
    }


    render() {
        // HTML
        return ( 
            <>            
                <div className="itjContianer">
                    <div className="itjContianerDropDown">
                        {
                            this.state.user.package_number >= 2 ? (
                                <><span>Job Board Listing</span><button ref={this.hideShowBtnRef} onClick={this.toggle} id="jobBoard">Show</button></>
                            ) : (
                                <><span>Job Board Listing</span><button ref={this.hideShowBtnRef} onClick={this.showUpgrade} id="jobBoard">Upgrade to access this feature</button></>
                            )
                        }
                    </div>
                    <div className="joblistingdata" ref={this.dropdownRef}>
                        <div>
                            <span>Company Logo (recommended 48 x 48 pixels or a square image with a ratio of 1:1)</span>
                            <ImageControllerComponent defaultValue={this.state.interview.job_board_logo} onChange={(image_url)=>{
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_logo: image_url
                                    }
                                });
                            }}/>
                            <span>Title</span>
                            <input type="text"  defaultValue={this.state.interview.job_board_title} placeholder="Job Title" onChange={(e)=>{
                                let data = e.target.value;
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_title: data
                                    }
                                });
                            }}/>
                            <span>Description</span>
                            <textarea cols="30" rows="10" placeholder="Job Description" onChange={(e)=>{
                                let data = e.target.value;
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_description: data
                                    }
                                });
                            }}>{this.state.interview.job_board_description}</textarea>
                            <span>Company</span>
                            <input type="text" defaultValue={this.state.interview.job_board_company} placeholder="Company Name" onChange={(e)=>{
                                let data = e.target.value;
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_company: data
                                    }
                                });
                            }}/>
                            <span>Location</span>
                            <input type="text" defaultValue={this.state.interview.job_board_location} placeholder="Location" onChange={(e)=>{
                                let data = e.target.value;
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_location: data
                                    }
                                });
                            }}/>
                            <span>Currency</span>
                            <select defaultValue={this.state.interview.job_board_currency} onChange={(e)=>{
                                let data = e.target.value;
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_currency: data
                                    }
                                });
                            }}>
                                <option value="R">R</option>
                                <option value="$">$</option>
                                <option value="€">€</option>
                                <option value="¥">¥</option>
                                <option value="£">£</option>
                            </select>
                            <span>ATS Link</span>
                            <input type="text" defaultValue={this.state.interview.job_board_ats} placeholder="ATS Link" onChange={(e)=>{
                                let data = e.target.value;
                                this.setState({
                                    ...this.state,
                                    changesToSave:{
                                        ...this.state.changesToSave,
                                        job_board_ats: data
                                    }
                                });
                            }}/>
                            <span>Salary Range</span>
                            <div>
                                <input type="text" placeholder="Min" defaultValue={this.state.interview.job_board_salary_min == 0 ? "" : this.state.interview.job_board_salary_min}  onChange={(e)=>{
                                    let value = e.target.value;
                                    this.setState({
                                        ...this.state,
                                        changesToSave: {
                                            ...this.state.changesToSave,
                                            job_board_salary_min: value
                                        }
                                    });
                                }}/>
                                <input type="text" placeholder="Max" defaultValue={this.state.interview.job_board_salary_max == 0 ? "" : this.state.interview.job_board_salary_max}  onChange={(e)=>{
                                    let value = e.target.value;
                                    this.setState({
                                        ...this.state,
                                        changesToSave: {
                                            ...this.state.changesToSave,
                                            job_board_salary_max: value
                                        }
                                    });
                                }}/>
                            </div>
                            <div>
                                <span>Can candidate do an interview <span style={{fontSize:'7pt', display:'block', fontWeight:'bold'}}>(This will lock the vacancy and prevent you from adding more questions if this is enabled, even after disabling the lock cannot be removed)</span></span>
                                <ToggleComponent onLabel="Yes" offLabel="No" onChange={(value)=>{
                                    this.setState({
                                        ...this.state,
                                        changesToSave: {
                                            ...this.state.changesToSave,
                                            job_board_interview: value
                                        }
                                    });
                                }} defaultValue={this.state.interview.job_board_interview}/>
                            </div>
                            <span>Promotional Video</span>
                            <VideoControllerComponent videoComponent={this.videoRecordJobBoardRef} videoComponentElement={this.videoRecordJobBoardRefElement} ref={this.videoComponentRef} showPreview={(video_url)=>{
                                this.videoRecordJobBoardRefPreview.current.style.display = "flex";
                                this.videoRecordJobBoardRefPreviewVideo.current.src = video_url;
                            }} defaultValue={this.state.interview.job_board_video_url}/>
                            <div>
                                <span>Job Board Listing</span>
                                <ToggleComponent onLabel="Enabled" offLabel="Disabled" onChange={(value)=>{
                                    this.setState({
                                        ...this.state,
                                        changesToSave: {
                                            ...this.state.changesToSave,
                                            job_board_enabled: value
                                        }
                                    });
                                }} defaultValue={this.state.interview.job_board_enabled}/>
                            </div>
                            <button className="blueBTN" style={{marginLeft:'auto', display:'block'}} onClick={async ()=>{
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.interview.setJobBoardDetails(
                                    api_key,
                                    this.state.interview.id,
                                    this.state.changesToSave.job_board_logo,
                                    this.state.changesToSave.job_board_title,
                                    this.state.changesToSave.job_board_description,
                                    this.state.changesToSave.job_board_company,
                                    this.state.changesToSave.job_board_location,
                                    this.state.changesToSave.job_board_currency,
                                    this.state.changesToSave.job_board_salary_min,
                                    this.state.changesToSave.job_board_salary_max,
                                    this.state.changesToSave.job_board_ats,
                                    this.state.changesToSave.job_board_interview,
                                    this.state.changesToSave.job_board_video_url,
                                    this.state.changesToSave.job_board_enabled
                                );
                                result = await API.interview.get(api_key, this.state.interview.id);
                                this.setState({
                                    ...this.state,
                                    interview: result['interview']
                                });
                            }}>Save Changes</button>
                            { this.state.interview.job_board_enabled && this.state.changesToSave.job_board_enabled ? 
                                <div>
                                    <h4>Job Board Listing Page</h4>
                                    <a target="_blank" href={"https://jobs.clipdrop.io/user/" + this.state.interview.user_id}>All Personal Listings</a>
                                    <a target="_blank" href="https://jobs.clipdrop.io/">All Listings</a>
                                </div> : <></>
                            }
                        </div>
                    </div>
                </div>    
                <div ref={this.videoRecordJobBoardRef} className="actionPopUp">
                    <VideoRecordComponent ref={this.videoRecordJobBoardRefElement} fixWidth={true} onclose={()=>{
                        this.videoRecordJobBoardRef.current.style.display = "none";
                    }} oncompletion={(video_url)=>{
                        this.videoRecordJobBoardRef.current.style.display = "none";
                        this.videoComponentRef.current.setURL(video_url);
                        this.setState({
                            ...this.state,
                            changesToSave: {
                                ...this.state.changesToSave,
                                job_board_video_url: video_url
                            }
                        });
                    }}></VideoRecordComponent>
                </div>
                <div ref={this.videoRecordJobBoardRefPreview} className="actionPopUp">
                <div>
                    <h4>Video Preview</h4>
                    <video className="videoSize" src="" ref={this.videoRecordJobBoardRefPreviewVideo} autoPlay={true} playsInline={true} controls={true}></video>
                    <div style={{textAlign: 'right', paddingTop:'12px'}}>
                        <button onClick={()=>{
                            this.videoRecordJobBoardRefPreviewVideo.current.pause();
                            this.videoRecordJobBoardRefPreview.current.style.display = "none";
                        }} className="close">Close</button>
                    </div>
                </div>
            </div> 
            </>
        );
      }
}