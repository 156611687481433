import React from "react";
import { createRef } from 'react';


import { ToastContainer, toast } from 'react-toastify';

import API from "./../services/api";

import { AiOutlineLoading } from 'react-icons/ai';

export default class Register3Page extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          loading: true,
          errorMessage: "",
          active: 3, // default to popular
          freePriceMonthly: "$0.00", // set default as dollar value
          litePriceMonthly: "$0.00", // set default as dollar value
          liteBusinessPriceMonthly: "$0.00", // set default as dollar value
          businessPriceMonthly: "$0.00", // set default as dollar value
          enterprisePriceMonthly: "$0.00", // set default as dollar value
        };
      // bind
      this.handleRegister3 = this.handleRegister3.bind(this);
      this.loadTab = this.loadTab.bind(this);
      this.loadTabProc = this.loadTabProc.bind(this);
      this.resetview = this.resetview.bind(this);
      
      this.setPricing = this.setPricing.bind(this);
      this.isLoad = this.isLoad.bind(this);

      // references
      this.durationRef = createRef();
      this.marketingRef = createRef();
      this.errorRef = createRef();
      // buttons
      this.freeTabBtnRef = createRef();
      this.soloTabBtnRef = createRef();
      this.liteTabBtnRef = createRef();
      this.liteBusinessTabBtnRef = createRef();
      this.businessTabBtnRef = createRef();
      this.enterpriseTabBtnRef = createRef();
      this.nextBtnRef = createRef();
      // tabs
      this.freeTabRef = createRef();
      this.soloTabRef = createRef();
      this.liteTabRef = createRef();
      this.liteBusinessTabRef = createRef();
      this.businessTabRef = createRef();
      this.enterpriseTabRef = createRef();
    }

    setPricing(){
        let duration =  parseInt(this.durationRef.current.value) ?? 1;
        let country = localStorage.getItem('country');
        let marketing = parseInt(this.marketingRef.current.value) ?? 1;
        if(country == "ZA"){ // South Africa in Rands
            if(duration == 1){ // monthly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>R { this.state.pricing.za_solo_monthly } /m</span>,
                        litePriceMonthly: <span>R { this.state.pricing.za_lite_monthly } /m</span>,
                        liteBusinessPriceMonthly: <span>R { this.state.pricing.za_business_lite_monthly } /m</span>,
                        businessPriceMonthly: <span>R { this.state.pricing.za_business_pro_monthly } /m</span>,
                        enterprisePriceMonthly: "R0/m",
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>R { this.state.pricing.za_solo_monthly_without_marketing } /m</span>,
                        litePriceMonthly: <span>R { this.state.pricing.za_lite_monthly_without_marketing } /m</span>,
                        liteBusinessPriceMonthly: <span>R { this.state.pricing.za_business_lite_monthly_without_marketing } /m</span>,
                        businessPriceMonthly: <span>R { this.state.pricing.za_business_pro_monthly_without_marketing } /m</span>,
                        enterprisePriceMonthly: "R0/m",
                    });
                }
            } else if(duration == 2){ // yearly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>R { (this.state.pricing.za_solo_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: R { (this.state.pricing.za_solo_yearly).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>R { (this.state.pricing.za_lite_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_lite_yearly).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>R { (this.state.pricing.za_business_lite_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_business_lite_yearly).toFixed(2) }</span></>,
                        businessPriceMonthly: <><span>R { (this.state.pricing.za_business_pro_yearly / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_business_pro_yearly).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "R0.00 p/m billed annually",
                    });
                } else {                    
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>R { (this.state.pricing.za_solo_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_solo_yearly_without_marketing).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>R { (this.state.pricing.za_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>R { (this.state.pricing.za_business_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_business_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        businessPriceMonthly: <><span>R { (this.state.pricing.za_business_pro_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><br/><span className="greysubheader">annual amount: R { (this.state.pricing.za_business_pro_yearly_without_marketing).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "R0.00 p/m billed annually",
                    });
                }
            }
        } else { // International in USD
            if(duration == 1){ // monthly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>$ { this.state.pricing.us_solo_monthly } /m</span>,
                        litePriceMonthly: <span>$ { this.state.pricing.us_lite_monthly } /m</span>,
                        liteBusinessPriceMonthly: <span>$ { this.state.pricing.us_business_lite_monthly } /m</span>,
                        businessPriceMonthly: <span>$ { this.state.pricing.us_business_pro_monthly } /m</span>,
                        enterprisePriceMonthly: "$0/m",
                    });
                } else {
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <span>$ { this.state.pricing.us_solo_monthly_without_marketing } /m</span>,
                        litePriceMonthly: <span>$ { this.state.pricing.us_lite_monthly_without_marketing } /m</span>,
                        liteBusinessPriceMonthly: <span>$ { this.state.pricing.us_business_lite_monthly_without_marketing } /m</span>,
                        businessPriceMonthly: <span>$ { this.state.pricing.us_business_pro_monthly_without_marketing } /m</span>,
                        enterprisePriceMonthly: "$0/m",
                    });
                }
            } else if(duration == 2){ // yearly
                if(marketing){
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>$ { (this.state.pricing.us_solo_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_solo_yearly).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>$ { (this.state.pricing.us_lite_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_lite_yearly).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>$ { (this.state.pricing.us_business_lite_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_business_lite_yearly).toFixed(2) }</span></>,
                        businessPriceMonthly: <><span>$ { (this.state.pricing.us_business_pro_yearly / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_business_pro_yearly).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "$0 p/m billed annually",
                    });
                } else {                    
                    this.setState({
                        ...this.state,
                        loading: false,
                        freePriceMonthly: "Free",
                        soloPriceMonthly: <><span>$ { (this.state.pricing.us_solo_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_solo_yearly_without_marketing).toFixed(2) }</span></>,
                        litePriceMonthly: <><span>$ { (this.state.pricing.us_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        liteBusinessPriceMonthly: <><span>$ { (this.state.pricing.us_business_lite_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_business_lite_yearly_without_marketing).toFixed(2) }</span></>,
                        businessPriceMonthly:<><span>$ { (this.state.pricing.us_business_pro_yearly_without_marketing / 12).toFixed(2) } p/m billed annually</span><span className="greysubheader">annual amount: $ { (this.state.pricing.us_business_pro_yearly_without_marketing).toFixed(2) }</span></>,
                        enterprisePriceMonthly: "$0 p/m billed annually",
                    });
                }
            }
        }
    }

    async componentDidMount(){
        let url = "https://api.ipify.org/?format=json";
        let response = await fetch(url);
        let json = await response.json();
        let ip = json['ip'];
        let result = await API.pricing.getLocation("", ip);
        let country_code2 = result['location'];
        localStorage.setItem('country', country_code2 == "ZA" ? "ZA" : "US");
        let keys = [
            'za_solo_monthly',
            'za_solo_monthly_without_marketing',
            'za_solo_yearly',
            'za_solo_yearly_without_marketing',            
            'za_lite_monthly',
            'za_lite_monthly_without_marketing',
            'za_lite_yearly',
            'za_lite_yearly_without_marketing',
            'za_business_lite_monthly',
            'za_business_lite_monthly_without_marketing',
            'za_business_lite_yearly',
            'za_business_lite_yearly_without_marketing',            
            'za_business_pro_monthly',
            'za_business_pro_monthly_without_marketing',
            'za_business_pro_yearly',
            'za_business_pro_yearly_without_marketing', 
            
            'us_solo_monthly',
            'us_solo_monthly_without_marketing',
            'us_solo_yearly',
            'us_solo_yearly_without_marketing',            
            'us_lite_monthly',
            'us_lite_monthly_without_marketing',
            'us_lite_yearly',
            'us_lite_yearly_without_marketing',
            'us_business_lite_monthly',
            'us_business_lite_monthly_without_marketing',
            'us_business_lite_yearly',
            'us_business_lite_yearly_without_marketing',            
            'us_business_pro_monthly',
            'us_business_pro_monthly_without_marketing',
            'us_business_pro_yearly',
            'us_business_pro_yearly_without_marketing',            
        ];
        result = await API.pricing.getBulk( keys, 0);
        let pairs = result['pairs'];
        let pricing = [];
        pairs.forEach((pair)=>{
            pricing[pair.key] = parseInt(pair.value ?? "0") / 100; // convert from cents to whole value
        });
        this.setState({
            ...this.state,
            pricing: pricing
        });
        this.resetview();
        this.setPricing();

        this.isLoad();
        
      window.onresize = () => {
        if(window.innerWidth <= 500){
          document.querySelector('#root').style.transform = "scale(0.63)";
        } else if(window.innerWidth <= 200){
          document.querySelector('#root').style.transform = "scale(0.40)";
        } else {
          document.querySelector('#root').style.transform = "scale(1)";         
        }
      };

      if(window.innerWidth <= 500){
        document.querySelector('#root').style.transform = "scale(0.63)";
      } else if(window.innerWidth <= 200){
        document.querySelector('#root').style.transform = "scale(0.40)";
      } else {
        document.querySelector('#root').style.transform = "scale(1)";         
      }  
    }
    
    loadTabProc(tabID){
        // de-select all tab buttons
        this.setState({...this.state, active: tabID});

        this.resetview();

        if(this.nextBtnRef.current) {this.nextBtnRef.current.style.display = "block";}
        
        let activeBorder1 = "1px solid #f9f9f9";
        let activeBackground = "#FFFFFF";
        let activeBorderBottom = "1px solid #FFFFFF";

        switch(tabID){
            case 0:
                //this.freeTabBtnRef.current.style.border = activeBorder1;
                //this.freeTabBtnRef.current.style.backgroundColor = activeBackground;
                //this.freeTabBtnRef.current.style.borderBottom = activeBorderBottom;
                //this.freeTabRef.current.style.display = "block";
            //break;
            // eslint-disable-next-line no-fallthrough
            case 1:
                this.soloTabBtnRef.current.style.border = activeBorder1;
                this.soloTabBtnRef.current.style.backgroundColor = activeBackground;
                this.soloTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.soloTabRef.current.style.display = "block";
            break;
            case 2:
                this.liteTabBtnRef.current.style.border = activeBorder1;
                this.liteTabBtnRef.current.style.backgroundColor = activeBackground;
                this.liteTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.liteTabRef.current.style.display = "block";
            break;
            case 3:
                this.liteBusinessTabBtnRef.current.style.border = activeBorder1;
                this.liteBusinessTabBtnRef.current.style.backgroundColor = activeBackground;
                this.liteBusinessTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.liteBusinessTabRef.current.style.display = "block";
            break;
            case 4:
                this.businessTabBtnRef.current.style.border = activeBorder1;
                this.businessTabBtnRef.current.style.backgroundColor = activeBackground;
                this.businessTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.businessTabRef.current.style.display = "block";
            break;
            case 5:
                this.enterpriseTabBtnRef.current.style.border = activeBorder1;
                this.enterpriseTabBtnRef.current.style.backgroundColor = activeBackground;
                this.enterpriseTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.enterpriseTabRef.current.style.display = "block";
            break;
            default:
                // do nothing
                break;
        }
    }


    isLoad(){
        let queryDict = {};
        window.location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]});
        console.log(queryDict);
        if('load' in queryDict){
            let load = parseInt(queryDict['load']);
            if(load === 1){
                if('duration' in queryDict){
                    let duration = parseInt(queryDict['duration']);
                    if(duration == 0 || duration == 1){
                        if('package' in queryDict){
                            let packagex = parseInt(queryDict['package']);
                            this.loadTabProc(packagex);
                            this.durationRef.current.value = (duration + 1).toString();
                            this.setPricing();
                        }
                    }
                }
            }
        } else {
            this.loadTabProc(1);
        }
    }

    // handle login
    handleRegister3 = async  (e) => {
        this.errorRef.current.innerHTML = "";
        // select package here
        let subDuration =  parseInt(this.durationRef.current.value);
        let marketing =  parseInt(this.marketingRef.current.value);
        if(subDuration !== 1 && subDuration !== 2){
            this.errorRef.current.innerHTML = "Select a valid billing period";
            return;
        }
        subDuration = subDuration - 1;
        localStorage.setItem('package', this.state.active);
        localStorage.setItem('duraiton', subDuration);
        localStorage.setItem('marketing', marketing);
        window.location.href = '/register/step/2';
    }

    resetview(){
        // presets
        let defaultBorder = "1px solid #f9f9f9";
        let defaultBackground = "#f9f9f9";

        let defaultDisplay = "none";

        this.freeTabBtnRef.current.style.border = this.soloTabBtnRef.current.style.border = this.liteTabBtnRef.current.style.border = this.liteBusinessTabBtnRef.current.style.border = this.businessTabBtnRef.current.style.border = this.enterpriseTabBtnRef.current.style.border = defaultBorder;
        this.freeTabBtnRef.current.style.backgroundColor = this.soloTabBtnRef.current.style.backgroundColor = this.liteTabBtnRef.current.style.backgroundColor = this.liteBusinessTabBtnRef.current.style.backgroundColor = this.businessTabBtnRef.current.style.backgroundColor = this.enterpriseTabBtnRef.current.style.backgroundColor = defaultBackground;
        this.freeTabBtnRef.current.style.borderBottom = this.soloTabBtnRef.current.style.borderBottom  = this.liteTabBtnRef.current.style.borderBottom = this.liteBusinessTabBtnRef.current.style.borderBottom = this.businessTabBtnRef.current.style.borderBottom = this.enterpriseTabBtnRef.current.style.borderBottom = defaultBorder;

        this.freeTabRef.current.style.display = this.soloTabRef.current.style.display = this.liteTabRef.current.style.display = this.liteBusinessTabRef.current.style.display = this.businessTabRef.current.style.display = this.enterpriseTabRef.current.style.display = defaultDisplay;

        if(this.nextBtnRef.current) {this.nextBtnRef.current.style.display = "none";}
    }

    loadTab(e){
        // de-select all tab buttons
        let tabID = parseInt(e.target.getAttribute('index-id'));
        this.setState({...this.state, active: tabID});

        this.resetview();

        if(this.nextBtnRef.current) {this.nextBtnRef.current.style.display = "block";}
        
        let activeBorder1 = "1px solid #f9f9f9";
        let activeBackground = "#FFFFFF";
        let activeBorderBottom = "1px solid #FFFFFF";

        switch(tabID){
            case 0:
                //this.freeTabBtnRef.current.style.border = activeBorder1;
                //this.freeTabBtnRef.current.style.backgroundColor = activeBackground;
                //this.freeTabBtnRef.current.style.borderBottom = activeBorderBottom;
                //this.freeTabRef.current.style.display = "block";
            //break;
            // eslint-disable-next-line no-fallthrough
            case 1:
                this.soloTabBtnRef.current.style.border = activeBorder1;
                this.soloTabBtnRef.current.style.backgroundColor = activeBackground;
                this.soloTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.soloTabRef.current.style.display = "block";
            break;
            case 2:
                this.liteTabBtnRef.current.style.border = activeBorder1;
                this.liteTabBtnRef.current.style.backgroundColor = activeBackground;
                this.liteTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.liteTabRef.current.style.display = "block";
            break;
            case 3:
                this.liteBusinessTabBtnRef.current.style.border = activeBorder1;
                this.liteBusinessTabBtnRef.current.style.backgroundColor = activeBackground;
                this.liteBusinessTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.liteBusinessTabRef.current.style.display = "block";
            break;
            case 4:
                this.businessTabBtnRef.current.style.border = activeBorder1;
                this.businessTabBtnRef.current.style.backgroundColor = activeBackground;
                this.businessTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.businessTabRef.current.style.display = "block";
            break;
            case 5:
                this.enterpriseTabBtnRef.current.style.border = activeBorder1;
                this.enterpriseTabBtnRef.current.style.backgroundColor = activeBackground;
                this.enterpriseTabBtnRef.current.style.borderBottom = activeBorderBottom;
                this.enterpriseTabRef.current.style.display = "block";
            break;
            default:
                // do nothing
                break;
        }
    }
    render() {
        // HTML
        return (<>
            <div className="outer">
                <div className="middle">
                    <div className="container" style={{width:'clamp(634px, 100%, 620px)'}}>
                        <div className="loginFormLogo"></div>
                        <div className="form">
                            <div className="containers">
                                <h4>Select a Subscription Package</h4>
                                <div className="tabBtnContainer">
                                    <button index-id="0" ref={this.freeTabBtnRef}  style={{display: "none"}} onClick={this.loadTab}>Free</button>
                                    <button index-id="1" ref={this.soloTabBtnRef} onClick={this.loadTab}>Solo</button>
                                    <button index-id="2" ref={this.liteTabBtnRef} onClick={this.loadTab}>Lite</button>
                                    <button index-id="3" ref={this.liteBusinessTabBtnRef} onClick={this.loadTab} style={{fontSize: '8pt'}}>Lite Business</button>
                                    <button index-id="4" ref={this.businessTabBtnRef} onClick={this.loadTab} style={{fontSize: '8pt'}}>Pro Business</button>
                                    <button index-id="5" ref={this.enterpriseTabBtnRef} onClick={this.loadTab} style={{display:'none', visibility:'hidden', opacity:'0'}}>Enterprise</button>
                                </div>
                                <div className="tabContainer">
                                    <div ref={this.freeTabRef} style={{display: "none"}}>
                                        <h4><span>Free package</span>{this.state.freePriceMonthly}</h4>
                                        { /*<span>6 Months Free Trial</span>
                                        <ul>
                                            <li><strong>Recording Time:</strong>  10 hours p/m</li>
                                            <li><strong>Vacancies:</strong> 1</li>
                                            <li><strong>Seats:</strong> 1</li>
                                            <li><strong>Text Questions:</strong> Yes</li>
                                            <li><strong>Video Sharing:</strong> Yes</li>
                                        </ul> */ }
                                    </div>
                                    <div ref={this.soloTabRef}>
                                        <h4><span>Solo package</span>{this.state.soloPriceMonthly}</h4>
                                        { /*<ul>
                                            <li><strong>Recording Time:</strong>  20 hours p/m</li>
                                            <li><strong>Vacancies:</strong> Unlimited</li>
                                            <li><strong>Seats:</strong> 1 - 2</li>
                                            <li><strong>Text Questions:</strong> Yes</li>
                                            <li><strong>Video Sharing:</strong> Yes</li>
                                            <li><strong>Data Management:</strong> Yes</li>
                                            <li><strong>API Access:</strong> Yes</li>
                                        </ul> */ }
                                    </div>
                                    <div ref={this.liteTabRef}>
                                        <h4><span>Lite package</span>{this.state.litePriceMonthly}</h4>
                                        { /*<ul>
                                            <li><strong>Recording Time:</strong>  20 hours p/m</li>
                                            <li><strong>Vacancies:</strong> Unlimited</li>
                                            <li><strong>Seats:</strong> 1 - 2</li>
                                            <li><strong>Text Questions:</strong> Yes</li>
                                            <li><strong>Video Sharing:</strong> Yes</li>
                                            <li><strong>Data Management:</strong> Yes</li>
                                            <li><strong>API Access:</strong> Yes</li>
                                        </ul> */ }
                                    </div>
                                    <div ref={this.liteBusinessTabRef}>
                                        <h4><span>Lite Business package</span>{this.state.liteBusinessPriceMonthly}</h4>
                                        { /*<ul>
                                            <li><strong>Recording Time:</strong>  10 hours p/m</li>
                                            <li><strong>Vacancies:</strong> 1</li>
                                            <li><strong>Seats:</strong> 1 - 5</li>
                                            <li><strong>Text Questions:</strong> Yes</li>
                                            <li><strong>Video Sharing:</strong> Yes</li>
                                            <li><strong>Data Management:</strong> Yes</li>
                                            <li><strong>API Access:</strong> Yes</li>
                                            <li><strong>Rating:</strong> Yes</li>
                                            <li><strong>Collaboration:</strong> Yes</li>
                                            <li><strong>LinkedIn Marketing:</strong> Yes</li>
                                            <li><strong>Bulk Campaign Marketing:</strong> Yes</li>
                                            <li><strong>Video on Application:</strong> Yes</li>
                                        </ul> */ }
                                    </div>
                                    <div ref={this.businessTabRef}>
                                        <h4><span>Business package</span>{this.state.businessPriceMonthly}</h4>
                                        { /*<ul>
                                            <li><strong>Recording Time:</strong>  200 hours p/m</li>
                                            <li><strong>Vacancies:</strong> Unlimited</li>
                                            <li><strong>Seats:</strong> 1 - 10</li>
                                            <li><strong>Text Questions:</strong> Yes</li>
                                            <li><strong>Video Sharing:</strong> Yes</li>
                                            <li><strong>Data Management:</strong> Yes</li>
                                            <li><strong>API Access:</strong> Yes</li>
                                            <li><strong>Rating:</strong> Yes</li>
                                            <li><strong>Collaboration:</strong> Yes</li>
                                            <li><strong>LinkedIn Marketing:</strong> Yes</li>
                                            <li><strong>Bulk Campaign Marketing:</strong> Yes</li>
                                            <li><strong>Video on Application:</strong> Yes</li>
                                        </ul> */ }
                                    </div>
                                    <div ref={this.enterpriseTabRef}>
                                        <h4><span>Enterprise package</span>{this.state.enterprisePriceMonthly}</h4>
                                        { /*<ul>
                                            <li><strong>Recording Time:</strong>  1000 hours p/m</li>
                                            <li><strong>Vacancies:</strong> Unlimited</li>
                                            <li><strong>Seats:</strong> Custom</li>
                                            <li><strong>Text Questions:</strong> Yes</li>
                                            <li><strong>Video Sharing:</strong> Yes</li>
                                            <li><strong>Data Management:</strong> Yes</li>
                                            <li><strong>API Access:</strong> Yes</li>
                                            <li><strong>Rating:</strong> Yes</li>
                                            <li><strong>Collaboration:</strong> Yes</li>
                                            <li><strong>LinkedIn Marketing:</strong> Yes</li>
                                            <li><strong>Bulk Campaign Marketing:</strong> Yes</li>
                                            <li><strong>Video on Application:</strong> Yes</li>
                                        </ul> */ }
                                    </div>
                                </div>
                            </div>
                            <span style={{textAlign:'left', width:'100%', display:'block', fontSize:'9pt', marginTop:'16px'}}>Include Campaign Marketing.</span>
                            <select ref={this.marketingRef} id="country" defaultValue={1} onChange={()=>{
                                this.setPricing();
                            }}>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                            <span style={{textAlign:'left', width:'100%', display:'block', fontSize:'9pt', marginTop:'16px'}}>Select A Subcription Billing Period.</span>
                            <select ref={this.durationRef} id="country" defaultValue={1} onChange={()=>{
                                this.setPricing();
                            }}>
                                <option value="1">Monthly</option>
                                <option value="2">Yearly</option>
                            </select>
                            <span className="error" ref={this.errorRef}></span>
                            <button onClick={this.handleRegister3} id="next">Next</button>
                        </div>
                    </div>
                </div>
            </div>           
            { 
                this.state.loading ? (
                    <div className="loader">                        
                        <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>Loading, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                    </div>
                ) : ""
            }
            </>
        );
      }
}