import React, { useRef, useEffect, useState } from "react";
import './questionComponent.css';
import QuestionsDataGrid from "./questionGridComponent";
import API from "../../services/api";
import { toast } from "react-toastify";
import VideoRecordComponent from "../../components/videorecord";
import FileUploadComponent from "../../components/fileupload";
import InBrowserRecorderAndProcessingComponent from "../../components/inBrowserRecorderAndProcessingComponent/InBrowserRecorderAndProcessingComponent";
import { text } from "stream/consumers";
export default function QuestionComponent({apiKey, user, interview, reloadInterview, questions, reloadQuestions:originalReloadQuestions}:{apiKey:string, user:any, interview:any, reloadInterview:()=>void, questions:any[] | null, reloadQuestions: () => void}) {
    // State and stuff
    const [ rows, setRows ] = useState<any>([]);
    const [ loading, setLoading ] = useState<boolean>(true);
    const [ showContainer, setShowContainer ] = useState<boolean>(false);
    const [ showPreviewPopUp, setShowPreviewPopUp ] = useState<boolean>(false);
    const [ showPreviewPopUpID, setShowPreviewPopUpID ] = useState<any>("");
    const [ showPreviewPopUpVideoURL, setShowPreviewPopUpVideoURL ] = useState<string>("");
    const [ showRerecordPopUp, setShowRerecordPopUp ] = useState<boolean>(false);
    const [ showRerecordPopUpID, setShowRerecordPopUpID ] = useState<any>("");
    const [ showEditPopUpText, setShowEditPopUpText ] = useState<string>("");
    const [ showEditPopUpTime, setShowEditPopUpTime ] = useState<number>(30);
    const [ showEditPopUpID, setShowEditPopUpID ] = useState<any>("");
    const [ showEditPopUp, setShowEditPopUp ] = useState<boolean>(false);
    const [ showAddQuestionPopUp, setShowAddQuestionPopUp ] = useState<boolean>(false);
    const [ showAddQuestionPopUpState, setShowAddQuestionPopUpState ] = useState<string>("");
    const [ showAddQuestionPopUpStateText, setShowAddQuestionPopUpStateText ] = useState<string>("");
    const [ showAddQuestionPopUpStateDuration, setShowAddQuestionPopUpStateDuration ] = useState<number>(30);
    const [ showAddQuestionPopUpStateVideoURL, setShowAddQuestionPopUpStateVideoURL ] = useState<string>("");
    const [ showAddQuestionPopUpStateVideoUploadOrPreview, setShowAddQuestionPopUpStateVideoUploadOrPreview ] = useState<boolean>(false);
    const reRecordQuestionDialogVideoRef = useRef<any>(null);
    const showAddQuestionPopUpStateVideoQuestionRecordRef = useRef<any>(null);
    const reloadQuestionPullingRef = useRef<any>(null);
    const showVideoPreview = (id:any, video_url:string):void => {
        setShowPreviewPopUpID(id);
        setShowPreviewPopUpVideoURL(video_url);
        setShowPreviewPopUp(true);
    }
    const rerecordVideo = (id:any, time:number):void => {
        setShowRerecordPopUpID(id);
        setShowEditPopUpTime(time);
        setShowRerecordPopUp(true);
        toast.info("Loading Video Recorder");
        setTimeout(()=>{
            if(!reRecordQuestionDialogVideoRef.current) return;
            try {
                reRecordQuestionDialogVideoRef.current.resetUI();
                reRecordQuestionDialogVideoRef.current.openVideo();
                toast.info("Video Recorder Loaded");
            } catch(e){
                toast.info("Video Recorder Unloaded");
            }
        }, 1000);
    }
    const showEditQuestion = (id:string, text:string, recommend_answer_duration:number):void =>{
        setShowEditPopUpID(id);
        setShowEditPopUpText(text);
        setShowEditPopUpTime(recommend_answer_duration);
        setShowEditPopUp(true);
    } 
    const openAddQuestion = () => {
        if(interview && interview.locked){
            toast.error("Interview has been locked and no more questions have be added");
            return;
        }
        setShowAddQuestionPopUpState("menu");
        setShowAddQuestionPopUp(true);
    }
    const dataGridColumns = [
        { key:"1", headerName: 'Title', width: 300, sortable: false, renderCell: (params) => {
            const { value, row, rowIndex } = params;
            if(row.isAddQuestionButton){
                if(interview.locked){
                    return <button className="button-primary-red" onClick={ e => {
                        openAddQuestion();
                    }}>+ Add Question (Locked)</button>;
                }
                return <button className="button-primary-blue" onClick={ e => {
                    openAddQuestion();
                }}>+ Add Question</button>;
            }
            if(row.type === "text"){
                return row.text;
            }
            if(row.type === "video"){
                return <>
                    <button className="button-primary-blue" onClick={ e => {
                        showVideoPreview(row.id, row.video_url);
                    }}>Preview Video</button>
                    <button className="button-primary-orange" onClick={ e => {
                        if(interview && interview.locked){
                            toast.error("Interview has been locked and no questions can be altered");
                            return;
                        }
                        rerecordVideo(row.id, row.recommend_answer_duration);
                    }}>Re-record Video</button>
                </>;
            }
            return "Unknown";
        }},
        { key:"2", field: 'id', headerName: 'ID', width: 64, sortable: false, renderCell: (params) => {
            const { value, row, rowIndex } = params;
            if(row.isAddQuestionButton){
                return "";
            }
            return value;
        }},
        { key:"3", field: 'type', headerName: 'Question Type', width: 250, sortable: false, renderCell: (params) => {
            const { value, row, rowIndex } = params;
            if(row.isAddQuestionButton){
                return <></>;
            }
            return value;
        }},
        { key:"4", field: 'recommend_answer_duration', headerName: 'Recommend Answer Duration', width: 235, sortable: false, renderCell: (params) => {
            const { value, row, rowIndex } = params;
            if(row.isAddQuestionButton){
                return <></>;
            }
            return `${value} Second${ value >= 2 ? "s" : ""}`;
        }},
        { key:"5", field: '', headerName: 'Actions', width: 300, sortable: false, renderCell: (params) => {
            const { value, row, rowIndex } = params;
            if(row.isAddQuestionButton){
                return <></>;
            }    
            if(row.type === "text"){
                return <>
                    <button className="button-primary-orange" onClick={ e => {
                        if(interview && interview.locked){
                            toast.error("Interview has been locked and no questions can be altered");
                            return;
                        }
                        showEditQuestion(row.id, row.text, row.recommend_answer_duration);
                    }}>{ interview.locked ? "Editing Locked" : "Edit"}</button>
                    <button className="button-primary-red" onClick={ async e => {
                        if(interview && interview.locked){
                            toast.error("Interview has been locked and no questions can be deleted");
                            return;
                        }
                        toast.info("Deleting Question");
                        let result = await API.questions.delete(apiKey, row.id);
                        let success = result['success'];
                        if(success){
                            toast.info("Question deleted");
                            reloadQuestions();
                        } else {
                            toast.error("Error deleting question");
                            reloadQuestions();
                        }
                    }}>{ interview.locked ? "Deletion Locked" : "Delete"}</button>
                </>;
            }        
            if(row.type === "video"){
                return <button className="button-primary-red" onClick={ async e => {
                    if(interview && interview.locked){
                        toast.error("Interview has been locked and no questions can be deleted");
                        return;
                    }
                    toast.info("Deleting Question");
                    let result = await API.questions.delete(apiKey, row.id);
                    let success = result['success'];
                    if(success){
                        toast.info("Question deleted");
                        reloadQuestions();
                    } else {
                        toast.error("Error deleting question");
                        reloadQuestions();
                    }
                }}>{ interview.locked ? "Deletion Locked" : "Delete"}</button>
            }
            return "Unknown";
        }}
      ]
      const reloadQuestions = () => {
        originalReloadQuestions();
        reloadQuestionPullingRef.current = setInterval(()=>{
            if(reloadQuestionPullingRef.current){
                try {
                    clearInterval(reloadQuestionPullingRef.current);
                } catch(e){}
            }
            originalReloadQuestions();
        }, 10 * 1000);
      };
    // Effects
    useEffect(()=>{
        if(!questions) return;
        setLoading(true);
        let newQuestionButtonAsRow = {id:0, title:'', type:'', recommend_answer_duration:'', isAddQuestionButton: true};
        let newRows = [newQuestionButtonAsRow, ...questions];
        setRows(newRows);
        if(questions.length <= 0){
            setShowContainer(true);
        }
        setLoading(false);
    }, [questions]);

    useEffect(()=>{
        if(!interview)return;
        if(interview.locked){
            setShowContainer(false);
        }
    }, [interview]);

    useEffect(()=>{
        reloadQuestionPullingRef.current = setInterval(()=>{
            if(reloadQuestionPullingRef.current){
                try {
                    clearInterval(reloadQuestionPullingRef.current);
                } catch(e){}
            }
            originalReloadQuestions();
        }, 10 * 1000);
    }, []);
    // Renders
    if(!interview || !questions){
        return <div className="question-component-container">
            <div className="question-toggle">
                <div>
                    <span>Loading Questions</span>
                </div>
            </div>
        </div>;
    }
    return <>
        <div className="question-component-container">
            <div className="question-toggle">
                <div>
                    <span>Questions</span>
                    <button onClick={ e => { setShowContainer( x => !x)}}>{ showContainer ? "Hide" : "Show" }</button>
                    { interview.locked && <span className="locked">Locked</span>}
                </div>
            </div>
            { showContainer && <div style={{ height: '100%', width: 'calc(100% - 0px)'}}>
                <QuestionsDataGrid loading={loading} columns={dataGridColumns} rows={rows}/>
            </div>}
        </div>
        { (showPreviewPopUp && showPreviewPopUpVideoURL) && <div className="custom-popup-dialog">
            <div>
                <div className="header">
                    <h4>Video Preview</h4>
                    <button onClick={ e => {
                        setShowPreviewPopUpID("");
                        setShowPreviewPopUpVideoURL("");
                        setShowPreviewPopUp(false);
                    }}>X</button>
                </div>
                <div className="content">
                    <video src={showPreviewPopUpVideoURL} autoPlay={true} loop={false} controls={true} width={"480px"} height={"480px"}></video>
                </div>
            </div>
        </div> }
        { (showRerecordPopUp && showRerecordPopUpID) && <div className="custom-popup-dialog">
            <div>
                <div className="header">
                    <h4>Rerecord Video</h4>
                    <button onClick={ e => {
                        setShowRerecordPopUpID("");
                        setShowRerecordPopUp(false);
                    }}>X</button>
                </div>
                <div className="content">
                    {/*<VideoRecordComponent ref={reRecordQuestionDialogVideoRef} fixWidth={true} watermark_url={user.watermark_url} onclose={()=>{
                        setShowRerecordPopUpID("");
                        setShowRerecordPopUp(false);
                        reloadQuestions();
                    }} getDuration={()=>{return 0;}} oncompletion={
                            async (video_url)=>{
                                toast.info("Updating Video Question");
                                let result = await API.questions.updateVideoByID(apiKey, showRerecordPopUpID, video_url);
                                if(!reRecordQuestionDialogVideoRef.current) return;
                                try {
                                    reRecordQuestionDialogVideoRef.current.closeDialog();
                                } catch(e){}
                                toast.info("Updated and Reloading Questions");
                                reloadQuestions();
                            }
                        } />
                    */} 
                    <label htmlFor="question-rad-2">
                        <span>Recommended Answer Duration</span>
                    </label>
                    <select id="question-rad-2" name="question-rad-2" onChange={ e => { setShowEditPopUpTime(parseInt(e.target.value)) }} value={showEditPopUpTime.toString()}>
                        <option value="30">30 Seconds</option>
                        <option value="60">1 Minute</option>
                        <option value="90">1.5 Minutes</option>
                    </select>
                    <InBrowserRecorderAndProcessingComponent
                        apiKey={apiKey}
                        onClose={ () => {
                            setShowRerecordPopUpID("");
                            setShowRerecordPopUp(false);
                            reloadQuestions();
                        }}
                        onCompletion={ async (video_url:string) => {
                            let results = await API.questions.updateVideoByID(apiKey, showRerecordPopUpID, video_url, showEditPopUpTime);
                            setShowRerecordPopUpID("");
                            setShowRerecordPopUp(false);
                            reloadQuestions();
                        }}
                    ></InBrowserRecorderAndProcessingComponent> 
                </div>
            </div>
        </div> }
        
        { (showEditPopUp && showEditPopUpID) && <div className="custom-popup-dialog">
            <div>
                <div className="header">
                    <h4>Edit Question Text</h4>
                    <button onClick={ e => {
                        setShowEditPopUpID("");
                        setShowEditPopUpText("");
                        setShowEditPopUpTime(30);
                        setShowEditPopUp(false);
                    }}>X</button>
                </div>
                <div className="content">
                    <div className="nice-form">
                        <label>
                            <span>Question Text</span>
                        </label>
                        <input type="text" placeholder="Question Text" defaultValue={showEditPopUpText} onChange={ e => setShowEditPopUpText(e.target.value)}/>
                        <label htmlFor="question-rad-2">
                            <span>Recommended Answer Duration</span>
                        </label>
                        <select id="question-rad-2" name="question-rad-2" onChange={ e => { setShowEditPopUpTime(parseInt(e.target.value)) }} value={showEditPopUpTime.toString()}>
                            <option value="30">30 Seconds</option>
                            <option value="60">1 Minute</option>
                            <option value="90">1.5 Minutes</option>
                        </select>
                        <button onClick={ async e => {
                            toast.info("Updating Question Text");
                            let result = await API.questions.updateByID(apiKey, showEditPopUpID, showEditPopUpText, showEditPopUpTime);
                            toast.success("Question Updated");                            
                            setShowEditPopUpID("");
                            setShowEditPopUpText("");
                            setShowEditPopUpTime(30);
                            setShowEditPopUp(false);
                            reloadQuestions();
                        }} className="button-primary-blue blocked">Save Changes</button>
                    </div>
                </div>
            </div>
        </div> }
        
        { showAddQuestionPopUp && <div className="custom-popup-dialog">
            <div>
                <div className="header">
                    <h4>Add Question</h4>
                    <button onClick={ e => {
                        if(
                            showAddQuestionPopUpState === "text-question" ||
                            showAddQuestionPopUpState === "video-question" ||
                            showAddQuestionPopUpState === "video-question-record" ||
                            showAddQuestionPopUpState === "video-question-upload"
                        ){
                            if(showAddQuestionPopUpState === "video-question-record"){
                                if(showAddQuestionPopUpStateVideoQuestionRecordRef.current){
                                    try {
                                        showAddQuestionPopUpStateVideoQuestionRecordRef.current.closeDialog();
                                    } catch(e){}
                                }
                            }
                            setShowAddQuestionPopUpState("menu");
                            setShowAddQuestionPopUpStateDuration(30);
                            setShowAddQuestionPopUpStateText("");
                            return;
                        }
                        setShowAddQuestionPopUpState("menu");
                        setShowAddQuestionPopUpStateDuration(30);
                        setShowAddQuestionPopUpStateText("");
                        setShowAddQuestionPopUp(false);
                        reloadQuestions();
                    }}>X</button>
                </div>
                <div className="content">
                    {
                        showAddQuestionPopUpState === "menu" && <div>
                            <h4>Select your question type</h4>
                            <button onClick={ async e => {
                                setShowAddQuestionPopUpState("text-question");
                            }} className="button-primary-blue full">Text Question</button><br/><br/>
                            { user && user.package_number >= 1 && <><button onClick={ async e => {
                                setShowAddQuestionPopUpState("video-question");
                            }} className="button-primary-blue full">Video Question</button><br/><br/></>}
                            <button onClick={ async e => {
                                setShowAddQuestionPopUpState("menu");
                                setShowAddQuestionPopUpStateDuration(30);
                                setShowAddQuestionPopUpStateText("")
                                setShowAddQuestionPopUp(false);
                                reloadQuestions();
                            }} className="button-primary-blue full">Close</button><br/><br/>
                        </div>
                    }
                    {
                        showAddQuestionPopUpState === "text-question" && <div className="nice-form">
                            <h4>Text Question</h4>
                            <label htmlFor="question-text">
                                <span>Question's Text</span>
                            </label>
                            <input id="question-text" name="question-text" type="text" placeholder="Your question goes here" value={ showAddQuestionPopUpStateText } onChange={ e => { setShowAddQuestionPopUpStateText(e.target.value)}}/>
                            <label htmlFor="question-rad">
                                <span>Recommended Answer Duration</span>
                            </label>
                            <select id="question-rad" name="question-rad" onChange={ e => { setShowAddQuestionPopUpStateDuration(parseInt(e.target.value)); }} value={showAddQuestionPopUpStateDuration.toString()}>
                                <option value="30">30 Seconds</option>
                                <option value="60">1 Minute</option>
                                <option value="90">1.5 Minutes</option>
                            </select>
                            <div className="footer right-aligned">
                                <button onClick={ async e => {
                                    setShowAddQuestionPopUpState("menu");
                                    setShowAddQuestionPopUpStateDuration(30);
                                    setShowAddQuestionPopUpStateText("");
                                    setShowAddQuestionPopUpStateVideoURL("");
                                }} className="button-primary-blue">Back</button>
                                <button onClick={ async e => {
                                    if(!showAddQuestionPopUpStateText){
                                        toast.error("A valid question is required");
                                        return;
                                    }
                                    if(showAddQuestionPopUpStateDuration !== 30 && showAddQuestionPopUpStateDuration !== 60 && showAddQuestionPopUpStateDuration !== 90){
                                        toast.error("A valid duration is required");
                                        return;
                                    }
                                    toast.info("Saving Question");
                                    let result = await API.questions.create(apiKey, showAddQuestionPopUpStateDuration, "text", showAddQuestionPopUpStateText, "", interview.id);
                                    if(!result['success']){
                                        toast.error("Unknown error creating text question");
                                        return;
                                    }
                                    toast.success("Question Saved");
                                    toast.info("You may enter your next Question");
                                    setShowAddQuestionPopUpStateDuration(30);
                                    setShowAddQuestionPopUpStateText("");
                                    setShowAddQuestionPopUpStateVideoURL("");
                                }} className="button-primary-blue">Save and Next</button>
                                <button onClick={ async e => {
                                    if(!showAddQuestionPopUpStateText){
                                        toast.error("A valid question is required");
                                        return;
                                    }
                                    if(showAddQuestionPopUpStateDuration !== 30 && showAddQuestionPopUpStateDuration !== 60 && showAddQuestionPopUpStateDuration !== 90){
                                        toast.error("A valid duration is required");
                                        return;
                                    }
                                    toast.info("Saving Question");
                                    let result = await API.questions.create(apiKey, showAddQuestionPopUpStateDuration, "text", showAddQuestionPopUpStateText, "", interview.id);
                                    if(!result['success']){
                                        toast.error("Unknown error creating text question");
                                        return;
                                    }
                                    toast.success("Question Saved");
                                    setShowAddQuestionPopUpState("menu");
                                    setShowAddQuestionPopUpStateDuration(30);
                                    setShowAddQuestionPopUpStateText("");
                                    setShowAddQuestionPopUp(false);
                                    toast.info("Reloading Questions");
                                    reloadQuestions();
                                }} className="button-primary-blue">Save and Done</button>
                                <button onClick={ async e => {
                                    setShowAddQuestionPopUpState("menu");
                                    setShowAddQuestionPopUpStateDuration(30);
                                    setShowAddQuestionPopUpStateText("");
                                    setShowAddQuestionPopUp(false);
                                    toast.info("Reloading Questions");
                                    reloadQuestions();
                                }} className="button-primary-blue">Done</button>
                            </div>
                        </div>
                    }
                    {
                        showAddQuestionPopUpState === "video-question" && <div>
                            <h4>Select your what video option you want</h4>
                            <button onClick={ async e => {
                                setShowAddQuestionPopUpState("video-question-record");
                                toast.info("Loading Video Recorder");                     
                            }} className="button-primary-blue full">Record A Video</button><br/><br/>
                            <button onClick={ async e => {
                                setShowAddQuestionPopUpState("video-question-upload");
                            }} className="button-primary-blue full">Upload A Video</button><br/><br/>
                            <button onClick={ async e => {
                                setShowAddQuestionPopUpState("menu");
                                setShowAddQuestionPopUpStateDuration(30);
                                setShowAddQuestionPopUpStateText("");
                                reloadQuestions();
                            }} className="button-primary-blue full">Back</button><br/><br/>
                        </div>
                    }
                    {
                        showAddQuestionPopUpState === "video-question-record" && <div className="nice-form">
                            <h4>Record your question</h4>
                            <label htmlFor="question-rad">
                                <span>Select a Recommended Answer Duration</span>
                            </label>
                            <select id="question-rad" name="question-rad" onChange={ e => { setShowAddQuestionPopUpStateDuration(parseInt(e.target.value)); }} value={showAddQuestionPopUpStateDuration.toString()}>
                                <option value="30">30 Seconds</option>
                                <option value="60">1 Minute</option>
                                <option value="90">1.5 Minutes</option>
                            </select>       
                            <label htmlFor="question-rad">
                                <span>Record your video</span>
                            </label>           
                            <div className="center">
                                {/*<VideoRecordComponent nopreview2={true} ref={showAddQuestionPopUpStateVideoQuestionRecordRef} fixWidth={true} watermark_url={user.watermark_url} onclose={()=>{
                                    if(showAddQuestionPopUpStateVideoQuestionRecordRef.current){
                                        try {
                                            showAddQuestionPopUpStateVideoQuestionRecordRef.current.closeDialog();
                                        } catch(e){}
                                    }
                                    setShowAddQuestionPopUpState("menu");
                                    setShowAddQuestionPopUpStateDuration(30);
                                    setShowAddQuestionPopUpStateText("");
                                }} getDuration={()=>{ return showAddQuestionPopUpStateDuration; }} onnopreview={()=>{
                                    toast.info("Your question video will be processed in the background, you may add another question");
                                    setShowAddQuestionPopUpState("menu");
                                    setShowAddQuestionPopUpStateDuration(30);
                                    setShowAddQuestionPopUpStateText("");
                                    reloadQuestions();
                                }}></VideoRecordComponent> */}
                                <InBrowserRecorderAndProcessingComponent
                                    apiKey={apiKey}
                                    onClose={ () => {
                                        setShowAddQuestionPopUpState("menu");
                                        setShowAddQuestionPopUpStateDuration(30);
                                        setShowAddQuestionPopUpStateText("");
                                    }}
                                    onCompletion={ async (video_url:string) => {
                                        let results = await API.questions.create(apiKey, showAddQuestionPopUpStateDuration, "video", "", video_url, interview.id);
                                        setShowAddQuestionPopUpState("menu");
                                        setShowAddQuestionPopUpStateDuration(30);
                                        setShowAddQuestionPopUpStateText("");
                                    }}
                                /> 
                            </div>
                        </div>
                    }
                    {
                        showAddQuestionPopUpState === "video-question-upload" && <div className="nice-form">
                            <h4>Upload your video</h4>
                            <label htmlFor="question-rad">
                                <span>Select a Recommended Answer Duration</span>
                            </label>
                            <select id="question-rad" name="question-rad" onChange={ e => { setShowAddQuestionPopUpStateDuration(parseInt(e.target.value)); }} value={showAddQuestionPopUpStateDuration.toString()}>
                                <option value="30">30 Seconds</option>
                                <option value="60">1 Minute</option>
                                <option value="90">1.5 Minutes</option>
                            </select>       
                            <label htmlFor="question-rad">
                                <span>Select your video file below</span>
                            </label>
                            { !showAddQuestionPopUpStateVideoURL && <><button className="button-primary-blue full" onClick={ e => {
                                    setShowAddQuestionPopUpStateVideoUploadOrPreview(true);
                                }}>
                                Select Video File  
                                </button><br/><br/></>
                            }
                            {
                                showAddQuestionPopUpStateVideoUploadOrPreview && <FileUploadComponent onclose={()=>{                  
                                    setShowAddQuestionPopUpStateVideoUploadOrPreview(false);                           
                                }} oncompletion={async (video_url)=>{    
                                    setShowAddQuestionPopUpStateVideoURL(video_url);                               
                                    setShowAddQuestionPopUpStateVideoUploadOrPreview(false);                                                                
                                }}></FileUploadComponent>
                            }
                            {
                                !!showAddQuestionPopUpStateVideoURL && <>
                                    <video controls={true} src={showAddQuestionPopUpStateVideoURL} style={{ display:'block', maxWidth:'320px', maxHeight:'320px', textAlign:'center', margin:'0px auto'}}></video>
                                    <button className="button-primary-blue full" onClick={ e => {
                                        setShowAddQuestionPopUpStateVideoUploadOrPreview(true);
                                    }}>
                                    Re-select Video File
                                    </button><br/><br/>
                                    <button className="button-primary-blue full" onClick={ async e => {
                                        toast.info("Saving Question");
                                        let result = await API.questions.create(apiKey, showAddQuestionPopUpStateDuration, "video", "", showAddQuestionPopUpStateVideoURL, interview.id);
                                        if(!result['success']){
                                            toast.error("Unknown error creating video question");
                                            return;
                                        }
                                        toast.success("Question Saved");
                                        toast.info("You may now create another Question");
                                        setShowAddQuestionPopUpState("menu");
                                        setShowAddQuestionPopUpStateDuration(30);
                                        setShowAddQuestionPopUpStateText("");
                                        setShowAddQuestionPopUpStateVideoURL("");
                                    }}>
                                    Create Question
                                    </button><br/><br/>
                                </>
                            }
                            <button onClick={ async e => {
                                setShowAddQuestionPopUpState("video-question");
                                setShowAddQuestionPopUpStateDuration(30);
                                setShowAddQuestionPopUpStateText("");
                            }} className="button-primary-blue full">Back</button><br/><br/>
                        </div>
                    }
                </div>
            </div>
        </div> }
    </>;
}