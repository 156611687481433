import React, { useRef, useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useLocation } from 'react-router-dom';
import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";
import './vacancy.css';
import './vacancypagev2.css';
import useInterviewHook from "./vacancy-components/useInterviewHook";
import ITJComponent from "./vacancy-components/itjComponent";
import HeaderComponent from "./vacancy-components/headerComponent";
import QuestionComponent from "./vacancy-components/questionComponent";
import CandidatesFolderComponent from "./vacancy-components/candidatesFolderComponent";
import useQuestionsHook from "./vacancy-components/useQuestionsHook";
import API from "../services/api";
import { toast } from "react-toastify";
import FiveStarRating from "./vacancy-components/fiveStarRating";
import VideoUploadComponent from "../components/videoupload";
import VideoRecordComponent from "../components/videorecord";
import InBrowserRecorderAndProcessingComponent from "../components/inBrowserRecorderAndProcessingComponent/InBrowserRecorderAndProcessingComponent";
import STTURLComponent from "../components/stt-component-url/STTURLComponent";
import InBrowserRecorderAndProcessingComponentV2 from "../components/inBrowserRecorderAndProcessingComponentV2/InBrowserRecorderAndProcessingComponentV2";
import InBrowserGifFromVideoComponent from "../components/InBrowserGifFromVideoComponent/InBrowserGifFromVideoComponent";
import InBrowserLinkedInVideoProcessor from "../components/inBrowserLinkedInVideoProcessor/inBrowserLinkedInVideoProcessor";
import SUNEDITOR from 'suneditor';
import { HTML_EDITOR_CONFIG } from "../HTMLEDITOR_CONFIG";

export default function VacancyPageV2(props) {
    // Email Editor    
    const editor = useRef<any>(null);
    // other
    const [ user, setUser ] = useState<any>(null);
    const apiKey:string = localStorage.getItem("api_key") ?? "";
    let { id:interview_id } = useParams();
    const { interview, interviewID, reloadInterview, updateInterviewTitle, updateInterview } = useInterviewHook(apiKey, interview_id);
    const { questions, reloadQuestions, addQuestion, removeQuestion } = useQuestionsHook(apiKey, interview_id);
    const location = useLocation();
    const [ defaultFolder, setDefaultFolder ] = useState<string>("");
    const [ showStorageLow, setShowStorageLow ] = useState<boolean>(false);
    const [ showStorageNone, setShowStorageNone ] = useState<boolean>(false);
    const [ showOpenRates, setShowOpenRates ] = useState<boolean>(false);

    // Assessment Preview State
    const [ showCandidateAssessmentAnswers, setShowCandidateAssessmentAnswers ] = useState<boolean>(false);
    const [ candidateAssessmentCandidateData, setCandidateAssessmentCandidateData ] = useState<any>(null);
    const [ candidateAssessmentAnswersData, setCandidateAssessmentAnswersData ] = useState<any>(null);
    // Create Candidate State
    const [ showCreateCandidateName, setShowCreateCandidateName ] = useState<string>("");
    const [ showCreateCandidateEmail, setShowCreateCandidateEmail ] = useState<string>("");
    const [ showCreateCandidateLinkedIn, setShowCreateCandidateLinkedIn ] = useState<string>("");
    const [ showCreateCandidatePhoneNumber, setShowCreateCandidatePhoneNumber ] = useState<string>("");
    const [ showCreateCandidate, setShowCreateCandidate ] = useState<boolean>(false);
    const [ showCreateCandidateCompleteFunction, setShowCreateCandidateCompleteFunction ] = useState<null | ((data:any) => Promise<null | {
        created_at: null | string | Date
        email: null | string
        id: number
        interview_id:  null | number
        linkedin_url: null | string
        marketing_type: null | number
        name: null | string
        phone: null | string
        processing_gif: null | number
        processing_video: null | number 
        updated_at: null | string | Date 
    }>)>(null);
    // Edit Candidate State
    const [ showEditCandidateID, setShowEditCandidateID ] = useState<number>(0);
    const [ showEditCandidateName, setShowEditCandidateName ] = useState<string>("");
    const [ showEditCandidateEmail, setShowEditCandidateEmail ] = useState<string>("");
    const [ showEditCandidateLinkedIn, setShowEditCandidateLinkedIn ] = useState<string>("");
    const [ showEditCandidatePhoneNumber, setShowEditCandidatePhoneNumber ] = useState<string>("");
    const [ showEditCandidate, setShowEditCandidate ] = useState<boolean>(false);
    const [ showEditCandidateCompleteFunction, setShowEditCandidateCompleteFunction ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    // Invite Candidate
    const [ showCandidateInvitePopupCompletionFunction, setShowCandidateInvitePopupCompletionFunction ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    const [ showCandidateInvitePopup, setShowCandidateInvitePopup ] = useState<boolean>(false);
    const [ showCandidateInvitePopupToEmail, setShowCandidateInvitePopupToEmail ] = useState<string>("");
    const [ showCandidateInvitePopupSubject, setShowCandidateInvitePopupSubject ] = useState<string>("");
    const [ showCandidateInvitePopupDescription, setShowCandidateInvitePopupDescription ] = useState<string>("");
    // Review Candidate (with rating sometimes)
    const [ showCandidateReviewPopUp, setShowCandidateReviewPopUp ] = useState<boolean>(false);
    const [ showCandidateReviewState, setShowCandidateReviewState ] = useState<string>("start");
    const [ showCandidateReviewHasRating, setShowCandidateReviewHasRating ] = useState<boolean>(false);
    const [ showCandidateReviewIndex, setShowCandidateReviewIndex ] = useState<number>(0);
    const [ showCandidateReviewRatingValue, setShowCandidateReviewRatingValue ] = useState<number>(0);
    const [ showCandidateReviewCompletionFunction, setShowCandidateReviewCompletionFunction ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    const [ showCandidateReviewCandidateInfo, setShowCandidateReviewCandidateInfo ] = useState<any>(null);
    const [ showCandidateReviewData, setShowCandidateReviewData ] = useState<any[]>([]);
    const showCandidateReviewVideoQuestionRef = useRef<HTMLVideoElement | null>(null);
    const showCandidateReviewVideoAnswerRef = useRef<HTMLVideoElement | null>(null);
    const [ showCandidateReviewVideoQuestionIsPlaying, setShowCandidateReviewVideoQuestionIsPlaying ] = useState<boolean>(false);
    const [ showCandidateReviewVideoAnswerIsPlaying, setShowCandidateReviewVideoAnswerIsPlaying ] = useState<boolean>(false);
    // Full Name and Email Address
    const [ showGetNameAndEmail, setShowGetNameAndEmail ] = useState<boolean>(false);
    const [ showGetNameAndEmailNameValue, setShowGetNameAndEmailNameValue ] = useState<string>("");
    const [ showGetNameAndEmailEmailValue, setShowGetNameAndEmailEmailValue ] = useState<string>("");
    const [ showGetNameAndEmailTitle, setShowGetNameAndEmailTitle ] = useState<string>("");
    const [ showGetNameAndEmailCompletionCallback, setShowGetNameAndEmailCompletionCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    // Candidate View Comments
    const [ showCandidateComments, setShowCandidateComments ] = useState<boolean>(false);
    const [ showCandidateCommentsUsers, setShowCandidateCommentsUsers ] = useState<any[]>([]);
    const [ showCandidateCommentsData, setShowCandidateCommentsData ] = useState<any>(null);
    // Bespoke Work Flow    
    const [ showBespokeWorkFlow, setShowBespokeWorkFlow ] = useState<boolean>(false);
    const [ showBespokeWorkFlowInterviewID, setShowBespokeWorkFlowInterviewID ] = useState<any>(0);
    const [ showBespokeWorkFlowState, setShowBespokeWorkFlowState ] = useState<string>("");
    const [ showBespokeWorkFlowSubState, setShowBespokeWorkFlowSubState ] = useState<string>("");
    const [ showBespokeWorkFlowStep, setShowBespokeWorkFlowStep ] = useState<number>(1);
    const [ showBespokeWorkFlowCallback, setShowBespokeWorkFlowCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    // OutReach Work Flow    
    const [ showOutReachWorkFlow, setShowOutReachWorkFlow ] = useState<boolean>(false);
    const [ showOutReachWorkFlowInterviewID, setShowOutReachWorkFlowInterviewID ] = useState<any>(0);
    const [ showOutReachWorkFlowState, setShowOutReachWorkFlowState ] = useState<string>("");
    const [ showOutReachWorkFlowSubState, setShowOutReachWorkFlowSubState ] = useState<string>("");
    const [ showOutReachWorkFlowStep, setShowOutReachWorkFlowStep ] = useState<number>(1);
    const showOutReachVideoWorkFlowRecorderRef = useRef<any>(null);
    const [ showOutReachWorkFlowCallback, setShowOutReachWorkFlowCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    // Social Work Flow
    const [ showSocialFlow, setShowSocialWorkFlow ] = useState<boolean>(false);
    const [ showSocialFlowInterviewID, setShowSocialWorkFlowInterviewID ] = useState<any>(0);
    const [ showSocialFlowState, setShowSocialWorkFlowState ] = useState<string>("");
    const [ showSocialFlowSubState, setShowSocialWorkFlowSubState ] = useState<string>("");
    const [ showSocialFlowStep, setShowSocialWorkFlowStep ] = useState<number>(1);
    const showSocialVideoWorkFlowRecorderRef = useRef<any>(null);
    const [ showSocialWorkFlowCallback, setShowSocialWorkFlowCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    // Custom Video Preview
    const [ showCustomVideoPreview, setShowCustomVideoPreview ] = useState<boolean>(false);
    const [ showCustomVideoPreviewURL, setShowCustomVideoPreviewURL ] = useState<string>("");
    const [ showCustomVideoPreviewIsPlaying, setShowCustomVideoPreviewIsPlaying ] = useState<boolean>(false);
    const showCustomVideoPreviewPlayerRef = useRef<HTMLVideoElement | null>(null);
    // Record Custom Video
    const [ showCustomVideoRecorder, setShowCustomVideoRecorder ] = useState<boolean>(false);
    const [ showCustomVideoRecorderVideoURL, setShowCustomVideoRecorderVideoURL ] = useState<string>("");
    const [ showCustomVideoRecorderGif, setShowCustomVideoRecorderGif ] = useState<boolean>(false);
    const [ showCustomVideoRecorderGifURL, setShowCustomVideoRecorderGifURL ] = useState<string>("");
    const [ showCustomVideoRecorderCandidateID, setShowCustomVideoRecorderCandidateID ] = useState<number>(0);
    const [ showCustomVideoRecorderCallback, setShowCustomVideoRecorderCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    const showCustomVideoRecorderRef = useRef<any>(null);
    // Record Custom Video 2
    const [ showCustomVideoRecorder2, setShowCustomVideoRecorder2 ] = useState<boolean>(false);
    const [ showCustomVideoRecorderCandidateID2, setShowCustomVideoRecorderCandidateID2 ] = useState<number>(0);
    // OutReach Video
    const [ showOutReachVideo, setShowOutReachVideo ] = useState<boolean>(false);
    const [ showOutReachVideoInterviewID, setShowOutReachVideoInterviewID ] = useState<number>(0);
    const [ showOutReachVideoState, setShowOutReachVideoState ] = useState<string>("");
    const [ showOutReachVideoSubState, setShowOutReachVideoSubState ] = useState<string>("");
    const [ showOutReachVideoCallback, setShowOutReachVideoCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    const showOuReachVideoRecorderRef = useRef<any>(null);
    // Social Video
    const [ showSocialVideo, setShowSocialVideo ] = useState<boolean>(false);
    const [ showSocialVideoInterviewID, setShowSocialVideoInterviewID ] = useState<number>(0);
    const [ showSocialVideoState, setShowSocialVideoState ] = useState<string>("");
    const [ showSocialVideoSubState, setShowSocialVideoSubState ] = useState<string>("");
    const [ showSocialVideoCallback, setShowSocialVideoCallback ] = useState<null | ((data:any) => Promise<boolean>)>(null);
    const showSocialVideoRecorderRef = useRef<any>(null);
    // Show Link
    const [ showLink, setShowLink ] = useState<boolean>(false);
    const [ showLinkData, setShowLinkData ] = useState<string>("");
    // Facebook Share Pages
    const [ showFacebookShare, setShowFacebookShare ] = useState<boolean>(false);
    const [ showFacebookSelectedPages, setShowFacebookSelectedPages ] = useState<any[]>([]);
    const [ showFacebookSharePages, setShowFacebookSharePages ] = useState<any[]>([]);
    const [ showFacebookShareCallback, setShowFacebookShareCallback ] = useState<null | ((pages_data:any[]) => Promise<boolean>)>(null);
    // Show Custom Video Options
    const [ showCustomVideoOptions, setShowCustomVideoOptions ] = useState<boolean>(false);
    const [ showCustomVideoOptionsData, setShowCustomVideoOptionsData ] = useState<any>(0);
    const [ showCustomVideoOptionsDataName, setShowCustomVideoOptionsDataName ] = useState<string>("");
    // Show Loading
    const [ showLoading, setShowLoading ] = useState<boolean>(false);
    const [ showLoading2, setShowLoading2 ] = useState<boolean>(false);
    // Subtitles
    const [ showSubtitle, setShowSubtitle ] = useState<boolean>(false);
    const [ showSubtitleCallback, setShowSubtitleCallback ] = useState<null | ((video_url:string) => Promise<boolean>)>(null);    
    const [ showAddSubtitlePopUpVideoURL, setShowAddSubtitlePopUpVideoURL ] = useState<string>("");
    const [ showAddSubtitlePopUpState, setShowAddSubtitlePopUpState ] = useState<string>("options");
    const showAddSubtitleHidePreviewFunc = useRef<(() => void) | null>(null);
    const previewVideoRef = useRef<HTMLVideoElement | null>(null);
    // Subtitles - After Direct Video Upload / Record (aka no waiting)
    const [ showAskDirectSubtitlePopUp, setShowAskDirectSubtitlePopUp ] = useState<boolean>(false);
    const [ showDirectSubtitlePopUp, setShowDirectSubtitlePopUp ] = useState<boolean>(false);
    const [ showDirectSubtitlePopUpURL, setShowDirectSubtitlePopUpURL ] = useState<string>("");
    const showDirectSubtitlePopUpFunc = useRef<null | ((video_url:string) => Promise<boolean>)>();
    // Create Gif From Video
    const [ showCreateCandidateAddGif, setShowCreateCandidateAddGif ] = useState<boolean>(false);
    const [ showCreateCandidateAddGifCandidateID, setShowCreateCandidateAddGifCandidateID ] = useState<number>(0);
    const [ showCreateCandidateAddGifCandidateName, setShowCreateCandidateAddGifCandidateName ] = useState<string>("");
    const [ showCreateCandidateAddGifCompleteFunction, setShowCreateCandidateAddGifCompleteFunction ] = useState<null | ((candidate_id:number, gif_url: string) => Promise<boolean>)>(null);
    // LinkedIn Share
    const [ showLinkedInVideoShare, setShowLinkedInVideoShare] = useState<boolean>(false);
    const [ showLinkedInVideoShareVideoURL, setShowLinkedInVideoShareVideoURL ] = useState<string>("");
    const [ showLinkedInVideoShareUserAccessToken, setShowLinkedInVideoShareUserAccessToken ] = useState<string>("");
    const [ showLinkedInVideoShareUserID, setShowLinkedInVideoShareUserID ] = useState<string>("");
    const [ showLinkedInVideoShareCompleteFunction, setShowLinkedInVideoShareCompleteFunction ] = useState<null | (() => Promise<void>)>(null);
    // Slate    
    const [ currentEditorDataLast, setCurrentEditorDataLast ] = useState<any[]>([]);
    const [ currentEditorData, setCurrentEditorData ] = useState<any[]>([]);
    // Functions
    const getRelativeTime = (d1: Date, d2: Date = new Date()): string => {
        const units: { [key: string]: number } = {
            year: 24 * 60 * 60 * 1000 * 365,
            month: (24 * 60 * 60 * 1000 * 365) / 12,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000,
            second: 1000,
        };
        const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
        const elapsed: number = d1.getTime() - d2.getTime();
        for (const u in units) {
            if (Math.abs(elapsed) > units[u] || u === "second") {
                return rtf.format(Math.round(elapsed / units[u]), u as Intl.RelativeTimeFormatUnit);
            }
        }
        return "Unknown Date";
    };
    const getScoreColor = (percentage:number) => {
        if(percentage >= 75){
          return "green";
        } else if(percentage >= 50){
          return "orange";
        } else {
          return "red";
        }
    }
    const loadStorage = async (api_key:string) => {
        let result = await API.storage.getMyStorage(api_key);
        const assigned = Number(result['assigned']);
        const total = Number(result['total']) + assigned;
        const used = Number(result['used']);
        const leftOver = total - used;
        const noSpace = leftOver <= 0;
        const lowSpace = leftOver <= 1;
        if(noSpace){
            setShowStorageNone(noSpace);
            setShowStorageLow(false);
        } else {
            setShowStorageNone(false);
            setShowStorageLow(lowSpace);
        }
    }
    // Effects
    useEffect(()=>{
        if(!apiKey) return;
        async function loadUser(){
            let result = await API.user.getUser(apiKey);
            setUser(result['user']);
        }
        loadUser(); 
    },[apiKey]);
    useEffect(() => {      
        if (location.hash === '#interview') {
            setDefaultFolder("interview");
        } else if (location.hash === '#marketing') {
            setDefaultFolder("marketing");
        } else if (location.hash === '#bulkmarketing') {
            setDefaultFolder("bulk");
        } else if (location.hash === '#social') {
            setDefaultFolder("social");
        } else {
            setDefaultFolder("interview"); // Default value
        }
    }, []);
    useEffect(()=>{
        loadStorage(apiKey);
    }, []);
    useEffect(()=>{
        if(!interview) return;
        let counts = interview.openDiff?.bespoke + interview.openDiff?.campaign + interview.openDiff?.social + interview.openDiff?.interview;
        if(counts > 0){
            setShowOpenRates(true);
        }
    }, [interview]);
    useEffect(()=>{
        if(!user) return;
        // check if trial account with a free package, if so then redirect to the dashboard
        if(user.package_number === 0 && user.trial_expiry_flag){
            let expiry_date = new Date(user.trial_expiry);
            let current_date = new Date();
            let t = expiry_date.getTime() - current_date.getTime();
            let expired = t <= 0; 
            if(expired){
                window.location.href = "/dashboard";
            }
        }
    }, [user]);
    // Renders
    return <>
        <div className="dashboardContainer dashboardBlank">
            <SideBarComponent/>
            <div className="dashboardContent dashboard-page nice-grey" >
                <UpperBarComponent />
                <HeaderComponent apiKey={apiKey} interview={interview} noWidth={true}/>
                <ITJComponent apiKey={apiKey} user={user} interview={interview} reloadInterview={reloadInterview}/>
                <QuestionComponent apiKey={apiKey} user={user} interview={interview} reloadInterview={reloadInterview} questions={questions} reloadQuestions={reloadQuestions}/>
                <CandidatesFolderComponent apiKey={apiKey} interview={interview} interviewId={interview?.id ?? 0} reloadInterview={reloadInterview} defaultFolder={defaultFolder} showAssessmentPopUp={
                    (survey_data:any)=>{
                        setCandidateAssessmentCandidateData(survey_data.details.candidate);
                        setCandidateAssessmentAnswersData(survey_data.details.survey);
                        setShowCandidateAssessmentAnswers(true);
                    }
                }
                questions={questions ?? []}
                loggedInUser={user}
                addNewCandidateCallback={(callback: (data: any) => Promise<null | {
                    created_at: null | string | Date
                    email: null | string
                    id: number
                    interview_id:  null | number
                    linkedin_url: null | string
                    marketing_type: null | number
                    name: null | string
                    phone: null | string
                    processing_gif: null | number
                    processing_video: null | number 
                    updated_at: null | string | Date 
                }>) => {
                    setShowCreateCandidateCompleteFunction(() => callback);
                }}
                executeShowAddNewCandidateCallback={()=>{
                    setShowCreateCandidate(true);
                }}
                addEditCandidateCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowEditCandidateCompleteFunction(() => callback);
                }}
                AddNewCandidateAddGifCallback={(callback: (candidate_id:number, video_url: string) => Promise<boolean>) => {
                    setShowCreateCandidateAddGifCompleteFunction(() => callback);
                }}
                executeShowEditCandidateCallback={(candidate_data:any)=>{
                    setShowEditCandidateID(candidate_data.id);
                    setShowEditCandidateName(candidate_data.name);
                    setShowEditCandidateEmail(candidate_data.email);
                    setShowEditCandidateLinkedIn(candidate_data.linkedin_url ?? "");
                    setShowEditCandidatePhoneNumber(candidate_data.phone ?? "");
                    setShowEditCandidate(true);
                }}
                candidateInvitePopupCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowCandidateInvitePopupCompletionFunction(() => callback);
                }}
                executeHandleCandidateInvitePopupCallback={(data:any)=>{                    
                    setShowCandidateInvitePopupToEmail(data.email);
                    setShowCandidateInvitePopupSubject(data.subject);
                    setShowCandidateInvitePopupDescription(data.description);
                    setShowCandidateInvitePopup(true);    
                    setTimeout(()=>{
                        editor.current = null;                
                        editor.current = SUNEDITOR.create((document.getElementById('send_email_container') || 'send_email_container'), HTML_EDITOR_CONFIG);              
                        if(!editor.current){
                            toast.error("Editor Invalid");
                            setShowCandidateInvitePopup(false);
                            return;
                        }
                        editor.current.setContents(data.description);
                    }, 2000);
                }}
                handleCandidateReviewCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowCandidateReviewCompletionFunction(() => callback);
                }}
                executeHandleCandidateReviewCallback={(data:any)=>{
                    setShowCandidateReviewCandidateInfo(data.info);
                    setShowCandidateReviewData(data.review);
                    setShowCandidateReviewState("start");
                    setShowCandidateReviewIndex(0);
                    setShowCandidateReviewRatingValue(0);
                    setShowCandidateReviewHasRating(data.info.rating !== 0);
                    setShowCandidateReviewPopUp(true);
                }}
                handleNameEmailCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowGetNameAndEmailCompletionCallback(() => callback);
                }}
                executeHandleNameEmailCallback={(data:any)=>{
                    setShowGetNameAndEmailNameValue("");
                    setShowGetNameAndEmailEmailValue("");
                    setShowGetNameAndEmailTitle(data.title);
                    setShowGetNameAndEmail(true);
                }}
                executeHandleCandidateComments={async (data:{candidate:any, apiKey:string})=>{
                    let result = await API.comments.get(data.apiKey, data.candidate.id);
                    let commentData = result["comments"];
                    setShowCandidateCommentsUsers(Object.keys(commentData));
                    setShowCandidateCommentsData(commentData);
                    setShowCandidateComments(true);
                }}
                handleFlowBespokeCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowBespokeWorkFlowCallback(() => callback);
                }}
                executeHandleFlowBespokeCallback={(data:any)=>{
                    setShowBespokeWorkFlowInterviewID(data.interview_id);
                    setShowBespokeWorkFlowState("step-1");
                    setShowBespokeWorkFlowSubState("");
                    setShowBespokeWorkFlowStep(1);
                    setShowBespokeWorkFlow(true);
                }}
                handleCustomVideoCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowCustomVideoRecorderCallback(() => callback);
                }}
                executeHandleCustomVideoCallback={(data:any)=>{
                    setShowCustomVideoRecorderCandidateID(data.candidate_id);
                    setShowCustomVideoRecorderCandidateID2(data.candidate_id);
                    setShowCustomVideoOptionsData(data.candidate_id);
                    setShowCustomVideoOptionsDataName(data.candidate_name);
                    setShowCustomVideoOptions(true);
                }}
                executeShowVideoPreview={(data:any)=>{
                    setShowCustomVideoPreviewURL(data.video_url);
                    setShowCustomVideoPreview(true);
                }}
                handleFlowOutReachCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowOutReachWorkFlowCallback(() => callback);
                }}
                executeHandleFlowOutReachCallback={(data:any)=>{
                    setShowOutReachWorkFlowInterviewID(data.interview_id);
                    setShowOutReachWorkFlowState("step-1");
                    setShowOutReachWorkFlowSubState("");
                    setShowOutReachWorkFlowStep(1);
                    setShowOutReachWorkFlow(true);
                }}
                handleOutReachVideoCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowOutReachVideoCallback(() => callback);
                }}
                executeHandleOutReachVideoCallback={(data:any)=>{
                    setShowOutReachVideoState("step-1");
                    setShowOutReachVideoSubState("options");
                    setShowOutReachVideoInterviewID(data.interview_id);
                    setShowOutReachVideo(true);
                }}
                handleFlowSocialCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowSocialWorkFlowCallback(() => callback);
                }}
                executeHandleFlowSocialCallback={(data:any)=>{
                    setShowSocialWorkFlowState("step-1");
                    setShowSocialWorkFlowSubState("options");
                    setShowSocialWorkFlowStep(1);
                    setShowSocialWorkFlowInterviewID(data.interview_id);
                    setShowSocialWorkFlow(true);
                }}
                handleSocialVideoCallback={(callback: (data: any) => Promise<boolean>) => {
                    setShowSocialVideoCallback(() => callback);
                }}
                executeHandleSocialVideoCallback={(data:any)=>{
                    setShowSocialVideoState("step-1");
                    setShowSocialVideoSubState("options");
                    setShowSocialVideoInterviewID(data.interview_id);
                    setShowSocialVideo(true);
                }}
                executeShowLink={(link:any)=>{
                    setShowLinkData(link);
                    setShowLink(true);
                }}
                handleFacebookPageSubmission={(callback: (pages_data: any[]) => Promise<boolean>) => {
                    setShowFacebookShareCallback(() => callback);
                }}
                executeHandleFacebookPageSubmission={(pages_data:any[])=>{
                    setShowFacebookSelectedPages([]);
                    setShowFacebookSharePages(pages_data);
                    setShowFacebookShare(true);
                }}
                executeLoading={(status:boolean)=>{
                    setShowLoading(status);
                }}
                handleCustomVideoSubtitleCallback={(callback: (video_url:string)=>Promise<boolean>) => {
                    setShowSubtitleCallback(callback);
                }}
                executeShowLinkedInShare={(video_url:string, access_token:string, user_id:string)=>{
                    setShowLinkedInVideoShareVideoURL(video_url);
                    setShowLinkedInVideoShareUserAccessToken(access_token);
                    setShowLinkedInVideoShareUserID(user_id);
                    setShowLinkedInVideoShare(true);
                }}
                handleLinkedInShareCallback={(callback: ()=>Promise<void>) => {
                    setShowLinkedInVideoShareCompleteFunction(callback);
                }}
                />
            </div>
        </div>
        { showCandidateAssessmentAnswers && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>Assessment Results for "{ candidateAssessmentCandidateData.name }" ({ candidateAssessmentCandidateData.email }) [{candidateAssessmentCandidateData.id}]</h4>
                        <button onClick={ e => {
                            setCandidateAssessmentAnswersData(null);
                            setShowCandidateAssessmentAnswers(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className='candidate-assessment-score-container'>
                            <h4>Score:&nbsp;<strong>{`${Number(candidateAssessmentAnswersData.score) / Number(candidateAssessmentAnswersData.questions.length) * 100}%`}</strong></h4>
                            <div className='score'>
                                <div style={{  backgroundColor: getScoreColor(Number(candidateAssessmentAnswersData.score) / Number(candidateAssessmentAnswersData.questions.length)), width: `calc(${(Number(candidateAssessmentAnswersData.score) / Number(candidateAssessmentAnswersData.questions.length)) * 100}% - 12px)`}}></div>
                            </div>
                            <div className="candidate-assessment-container-details">
                            <h4>Details</h4>
                            <table>
                                <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Correct</th>
                                </tr>                      
                                </thead>
                                <tbody>
                                {
                                    candidateAssessmentAnswersData.questions.map((sq, index)=>{
                                        return <tr>
                                            <td>{ sq.question }</td>
                                            <td>{ sq.answer }</td>
                                            <td>{ sq.status === "Correct" ? "X" : "" }</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        
        { showCreateCandidate && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>Create Candidate</h4>
                        <button onClick={ e => {
                            setShowCreateCandidateName("");
                            setShowCreateCandidateEmail("");
                            setShowCreateCandidateLinkedIn("");
                            setShowCreateCandidatePhoneNumber("");
                            setShowCreateCandidateCompleteFunction(null);
                            setShowCreateCandidate(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="nice-form">
                            <label htmlFor="">
                                <span>Candidate's Name</span>
                            </label>
                            <input type="text" placeholder="Candidate's Name" value={showCreateCandidateName} onChange={ e => setShowCreateCandidateName(e.target.value)}/>
                            <label htmlFor="">
                                <span>Candidate's Email</span>
                            </label>
                            <input type="text" placeholder="Candidate's Email Address" value={showCreateCandidateEmail} onChange={ e => setShowCreateCandidateEmail(e.target.value)}/>
                            {/*<label htmlFor="">
                                <span>Candidate's LinkedIn Profile URL</span>
                            </label>
                            <input type="text" placeholder="Candidate's LinkedIn Profile URL" value={showCreateCandidateLinkedIn} onChange={ e => setShowCreateCandidateLinkedIn(e.target.value)}/>*/}
                            {/*<label htmlFor="">
                                <span>Candidate's Phone Number</span>
                            </label>
                            <input type="text" placeholder="Candidate's Phone Number" value={showCreateCandidatePhoneNumber} onChange={ e => setShowCreateCandidatePhoneNumber(e.target.value)}/>*/}
                            <div className="footer">
                                <button className="button-primary-blue blocked full" onClick={ async e => {
                                    if(showCreateCandidateCompleteFunction === null){
                                        toast.error("Function Is Missing");
                                        return;
                                    }
                                    let result = await showCreateCandidateCompleteFunction({
                                        name: showCreateCandidateName,
                                        email: showCreateCandidateEmail,
                                        linkedin: "",
                                        phone_number: "",
                                    });
                                    if(!result){
                                        return; // Don't close dialog if action was unsuccessful
                                    }
                                    if(result.marketing_type === 2){
                                        setShowCreateCandidateAddGifCandidateID(result.id);
                                        setShowCreateCandidateAddGifCandidateName(result.name ?? "");
                                        setShowCreateCandidateAddGif(true);
                                    } else {                                                   
                                        setShowCreateCandidateName("");
                                        setShowCreateCandidateEmail("");
                                        setShowCreateCandidateLinkedIn("");
                                        setShowCreateCandidatePhoneNumber("");
                                        setShowCreateCandidateCompleteFunction(null);
                                        setShowCreateCandidate(false);
                                    }
                                }}>Create Candidate</button>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowCreateCandidateName("");
                                    setShowCreateCandidateEmail("");
                                    setShowCreateCandidateLinkedIn("");
                                    setShowCreateCandidatePhoneNumber("");
                                    setShowCreateCandidateCompleteFunction(null);
                                    setShowCreateCandidate(false);
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        { showCreateCandidateAddGif && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>Create Candidate - Custom Outreach Gif</h4>
                    </div>
                    <div className="content">
                        <InBrowserGifFromVideoComponent
                            apiKey={localStorage.getItem("api_key") ?? ""}
                            inputVideoURL={interview ? interview.bulk_marketing_video_url : ""}
                            text={`Hi ${showCreateCandidateAddGifCandidateName}`}
                            onClose={ () => {}}
                            onCompletion={ async gif_image_url => {
                                if(!showCreateCandidateAddGifCompleteFunction){
                                    toast.error("Candidate Gif Callback not set");
                                    return;
                                }
                                if(!gif_image_url){
                                    toast.error("Candidate Gif Is Invalid");
                                    return;
                                }
                                const result = await showCreateCandidateAddGifCompleteFunction(showCreateCandidateAddGifCandidateID, gif_image_url);
                                setShowCreateCandidateName("");
                                setShowCreateCandidateEmail("");
                                setShowCreateCandidateLinkedIn("");
                                setShowCreateCandidatePhoneNumber("");
                                setShowCreateCandidateCompleteFunction(null);
                                setShowCreateCandidate(false);
                                setShowCreateCandidateAddGifCandidateID(0);
                                setShowCreateCandidateAddGifCandidateName("");
                                setShowCreateCandidateAddGif(false);
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        { showEditCandidate && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>Edit Candidate</h4>
                        <button onClick={ e => {
                            setShowEditCandidateID(0);
                            setShowEditCandidateName("");
                            setShowEditCandidateEmail("");
                            setShowEditCandidateLinkedIn("");
                            setShowEditCandidatePhoneNumber("");
                            setShowEditCandidateCompleteFunction(null);
                            setShowEditCandidate(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="nice-form">
                            <label htmlFor="">
                                <span>Candidate's Name</span>
                            </label>
                            <input type="text" placeholder="Candidate's Name" value={showEditCandidateName} onChange={ e => setShowEditCandidateName(e.target.value)}/>                            
                            <label htmlFor="">
                                <span>Candidate's Email</span>
                            </label>
                            <input type="text" placeholder="Candidate's Email Address" value={showEditCandidateEmail} readOnly={true}/>
                            <label htmlFor="">
                                <span>Candidate's LinkedIn Profile URL</span>
                            </label>
                            <input type="text" placeholder="Candidate's LinkedIn Profile URL" value={showEditCandidateLinkedIn} onChange={ e => setShowEditCandidateLinkedIn(e.target.value)}/>
                            <label htmlFor="">
                                <span>Candidate's Phone Number</span>
                            </label>
                            <input type="text" placeholder="Candidate's Phone Number" value={showEditCandidatePhoneNumber} onChange={ e => setShowEditCandidatePhoneNumber(e.target.value)}/>
                            <div className="footer">
                                <button className="button-primary-blue blocked full" onClick={ async e => {
                                    if(showEditCandidateCompleteFunction === null){
                                        toast.error("Function Is Missing");
                                        return;
                                    }
                                    let result = await showEditCandidateCompleteFunction({
                                        id: showEditCandidateID,
                                        name: showEditCandidateName,
                                        email: showEditCandidateEmail,
                                        linkedin: showEditCandidateLinkedIn,
                                        phone_number: showEditCandidatePhoneNumber,
                                    });
                                    if(!result){
                                        return; // Don't close dialog if action was unsuccessful
                                    }                             
                                    setShowEditCandidateID(0);
                                    setShowEditCandidateName("");
                                    setShowEditCandidateEmail("");
                                    setShowEditCandidateLinkedIn("");
                                    setShowEditCandidatePhoneNumber("");
                                    setShowEditCandidateCompleteFunction(null);
                                    setShowEditCandidate(false);
                                }}>Save Changes</button>
                                <button className="button-primary-blue blocked full" onClick={ e => {                                    
                                    setShowEditCandidateID(0);
                                    setShowEditCandidateName("");
                                    setShowEditCandidateEmail("");
                                    setShowEditCandidateLinkedIn("");
                                    setShowEditCandidatePhoneNumber("");
                                    setShowEditCandidateCompleteFunction(null);
                                    setShowEditCandidate(false);
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        
        { showCandidateInvitePopup && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>Send an Email</h4>
                        <button onClick={ e => {          
                            setShowCandidateInvitePopupToEmail("");
                            setShowCandidateInvitePopupSubject("");
                            setShowCandidateInvitePopupDescription("");
                            setShowCandidateInvitePopupCompletionFunction(null);
                            setShowCandidateInvitePopup(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="nice-form">
                            <label htmlFor="">
                                <span>Email Address</span>
                            </label>
                            <input type="text" placeholder="Email Address" value={showCandidateInvitePopupToEmail} onChange={ e => setShowCandidateInvitePopupToEmail(e.target.value)}/>                            
                            <label htmlFor="">
                                <span>Email Subject</span>
                            </label>
                            <input type="text" placeholder="Email Subject" value={showCandidateInvitePopupSubject} onChange={ e => setShowCandidateInvitePopupSubject(e.target.value)}/>
                            <label htmlFor="">
                                <span>Email Description</span>
                            </label>
                            <textarea id="send_email_container"></textarea>
                            <div className="footer">
                                <button className="button-primary-blue blocked full" onClick={ async e => {
                                    if(showCandidateInvitePopupCompletionFunction === null){
                                        toast.error("Function Is Missing");
                                        return;
                                    }
                                    const description = editor.current.getContents();
                                    let result = await showCandidateInvitePopupCompletionFunction({
                                        email: showCandidateInvitePopupToEmail,
                                        subject: showCandidateInvitePopupSubject,
                                        description: description,
                                    });
                                    if(!result){
                                        return; // Don't close dialog if action was unsuccessful
                                    }                           
                                    setShowCandidateInvitePopupToEmail("");
                                    setShowCandidateInvitePopupSubject("");
                                    setShowCandidateInvitePopupDescription("");
                                    setShowCandidateInvitePopupCompletionFunction(null);
                                    setShowCandidateInvitePopup(false);
                                }}>Send Email</button>
                                <button className="button-primary-blue blocked full" onClick={ e => {           
                                    setShowCandidateInvitePopupToEmail("");
                                    setShowCandidateInvitePopupSubject("");
                                    setShowCandidateInvitePopupDescription("");
                                    setShowCandidateInvitePopupCompletionFunction(null);
                                    setShowCandidateInvitePopup(false);
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        
        { showCandidateReviewPopUp && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                            {showCandidateReviewState === "start"    && <h4>Review Candidate</h4>}
                            {showCandidateReviewState === "question" && <h4>Question { `${showCandidateReviewIndex + 1}` } of { showCandidateReviewData.length }</h4>}
                            {showCandidateReviewState === "answer"   && <h4>Answer { `${showCandidateReviewIndex + 1}` } of { showCandidateReviewData.length }</h4>}
                            {showCandidateReviewState === "rate"     && <h4>Rate Candidate for answer { `${showCandidateReviewIndex + 1}` } of { showCandidateReviewData.length }</h4>}
                            {showCandidateReviewState === "done"     && <h4>Candidate Review Complete</h4>}
                            <button onClick={ e => {                                
                                setShowCandidateReviewPopUp(false);
                                setShowCandidateReviewCandidateInfo(null);
                                setShowCandidateReviewData([]);
                                setShowCandidateReviewState("start");
                                setShowCandidateReviewIndex(0);
                                setShowCandidateReviewRatingValue(0);
                                setShowCandidateReviewHasRating(false);
                            }}>X</button>
                    </div>
                    <div className="content">
                        <div className="candidate-review-container">
                            {
                                showCandidateReviewState === "start" && <div>
                                    <p>You will now be reviewing the selected candidate</p>
                                    <p>You will be shown the question-answer pair.</p>
                                    <p>click start when you're ready </p>
                                    <div className="buttons">
                                        <button className="button-primary-blue blocked full" onClick={ e => {                                            
                                            setShowCandidateReviewIndex(0);
                                            setShowCandidateReviewRatingValue(0);
                                            setShowCandidateReviewState("question");
                                        }}>Start</button>
                                        <button className="button-primary-blue blocked full" onClick={ e => {
                                            setShowCandidateReviewCandidateInfo(null);
                                            setShowCandidateReviewData([]);
                                            setShowCandidateReviewIndex(0);
                                            setShowCandidateReviewState("start");
                                            setShowCandidateReviewPopUp(false);
                                        }}>Close</button>
                                    </div>
                                </div>
                            }
                            {
                                showCandidateReviewState === "question" && <div>
                                {
                                    (showCandidateReviewData[showCandidateReviewIndex]['question']['type'] === "text") && <>
                                        <div className="large-text-display">
                                            <p>{showCandidateReviewData[showCandidateReviewIndex]['question']['text']}</p>
                                        </div>
                                        <div className="buttons">
                                            { (showCandidateReviewData.length > 0 && showCandidateReviewIndex > 0) && <button className="button-primary-blue inline-full" onClick={ e => {                                                 
                                                setShowCandidateReviewIndex(showCandidateReviewIndex - 1);
                                                setShowCandidateReviewRatingValue(0);
                                                setShowCandidateReviewState("answer");
                                            }}>Back</button>}
                                            <button className={ (showCandidateReviewData.length > 0 && showCandidateReviewIndex > 0) ? "button-primary-blue inline-full" : "button-primary-blue blocked full" } onClick={ e => {                                                 
                                                setShowCandidateReviewState("answer");
                                            }}>Next</button>
                                        </div>
                                    </>
                                }
                                {
                                    (showCandidateReviewData[showCandidateReviewIndex]['question']['type'] === "video") && <>
                                        <div>
                                            <video className="candidate-review-container-video" ref={showCandidateReviewVideoQuestionRef} src={showCandidateReviewData[showCandidateReviewIndex]['question']['video_url']} autoPlay controls playsInline onPlay={ e => { setShowCandidateReviewVideoQuestionIsPlaying(true);}} onEnded={ e => { setShowCandidateReviewVideoQuestionIsPlaying(false);}} onLoad={ e => {
                                                try{e.currentTarget.play();} catch(e){}
                                            }}></video>
                                            <div className="video-button-controls-container">
                                                <button className="button-primary-blue" onClick={ e => {
                                                    if(!showCandidateReviewVideoQuestionRef) return;
                                                    if(!showCandidateReviewVideoQuestionRef.current) return;
                                                    if(showCandidateReviewVideoQuestionIsPlaying){
                                                        try{showCandidateReviewVideoQuestionRef.current.pause();} catch(e){}
                                                        setShowCandidateReviewVideoQuestionIsPlaying(false);
                                                    } else {
                                                        try{showCandidateReviewVideoQuestionRef.current.play();} catch(e){}
                                                        setShowCandidateReviewVideoQuestionIsPlaying(true);
                                                    }
                                                }}>{ showCandidateReviewVideoQuestionIsPlaying ? "Pause" : "Play"}</button>
                                                <button className="button-primary-blue" onClick={ e => {
                                                    if(!showCandidateReviewVideoQuestionRef) return;
                                                    if(!showCandidateReviewVideoQuestionRef.current) return;
                                                    try{showCandidateReviewVideoQuestionRef.current.pause();} catch(e){}
                                                    setShowCandidateReviewVideoQuestionIsPlaying(false);
                                                }}>Stop</button>
                                                <button className="button-primary-blue" onClick={ e => {
                                                    if(!showCandidateReviewVideoQuestionRef) return;
                                                    if(!showCandidateReviewVideoQuestionRef.current) return;
                                                    try{showCandidateReviewVideoQuestionRef.current.pause();} catch(e){}                                                    
                                                    try{showCandidateReviewVideoQuestionRef.current.currentTime = 0 } catch(e){}
                                                    try{showCandidateReviewVideoQuestionRef.current.play();} catch(e){}
                                                    setShowCandidateReviewVideoQuestionIsPlaying(true);
                                                }}>Restart</button>
                                            </div>
                                        </div>
                                        <div className="buttons">
                                            { (showCandidateReviewData.length > 0 && showCandidateReviewIndex > 0) && <button className="button-primary-blue inline-full" onClick={ e => {                                                 
                                                setShowCandidateReviewIndex(showCandidateReviewIndex - 1);
                                                setShowCandidateReviewRatingValue(0);
                                                setShowCandidateReviewState("answer");
                                            }}>Back</button>}
                                            <button className="button-primary-blue inline-full" onClick={ e => {                                                   
                                                setShowCandidateReviewState("answer");
                                            }}>Next</button>
                                        </div>
                                    </>
                                }
                                </div>
                            }
                            {
                                showCandidateReviewState === "answer" && <div>
                                    <video className="candidate-review-container-video" ref={showCandidateReviewVideoAnswerRef} src={showCandidateReviewData[showCandidateReviewIndex]['answer']['video_link']} autoPlay controls playsInline onPlay={ e => { setShowCandidateReviewVideoAnswerIsPlaying(true);}} onEnded={ e => { setShowCandidateReviewVideoAnswerIsPlaying(false);}} onLoad={ e => {
                                        try{e.currentTarget.play();} catch(e){}
                                    }}></video>
                                    <div className="video-button-controls-container">
                                        <button className="button-primary-blue" onClick={ e => {
                                            if(!showCandidateReviewVideoAnswerRef) return;
                                            if(!showCandidateReviewVideoAnswerRef.current) return;
                                            if(showCandidateReviewVideoAnswerIsPlaying){
                                                try{showCandidateReviewVideoAnswerRef.current.pause();} catch(e){}
                                                setShowCandidateReviewVideoAnswerIsPlaying(false);
                                            } else {
                                                try{showCandidateReviewVideoAnswerRef.current.play();} catch(e){}
                                                setShowCandidateReviewVideoAnswerIsPlaying(true);
                                            }
                                        }}>{ showCandidateReviewVideoAnswerIsPlaying ? "Pause" : "Play"}</button>
                                        <button className="button-primary-blue" onClick={ e => {
                                            if(!showCandidateReviewVideoAnswerRef) return;
                                            if(!showCandidateReviewVideoAnswerRef.current) return;
                                            try{showCandidateReviewVideoAnswerRef.current.pause();} catch(e){}
                                            setShowCandidateReviewVideoAnswerIsPlaying(false);
                                        }}>Stop</button>
                                        <button className="button-primary-blue" onClick={ e => {
                                            if(!showCandidateReviewVideoAnswerRef) return;
                                            if(!showCandidateReviewVideoAnswerRef.current) return;
                                            try{showCandidateReviewVideoAnswerRef.current.pause();} catch(e){}                                                    
                                            try{showCandidateReviewVideoAnswerRef.current.currentTime = 0 } catch(e){}
                                            try{showCandidateReviewVideoAnswerRef.current.play();} catch(e){}
                                            setShowCandidateReviewVideoAnswerIsPlaying(true);
                                        }}>Restart</button>
                                    </div>
                                    <div className="buttons">                                        
                                        <button className="button-primary-blue inline-full" onClick={ e => {                                                 
                                            setShowCandidateReviewState("question");
                                        }}>Back</button>
                                        <button className="button-primary-blue inline-full" onClick={ e => {                                                   
                                            if(showCandidateReviewHasRating){
                                                const isNextIndexWithinArrayLength = (showCandidateReviewIndex + 1) >= showCandidateReviewData.length;                                            
                                                if(isNextIndexWithinArrayLength){
                                                    setShowCandidateReviewState("done");
                                                    return;
                                                }
                                                setShowCandidateReviewState("question");
                                                setShowCandidateReviewIndex(showCandidateReviewIndex + 1);
                                            } else {
                                                setShowCandidateReviewRatingValue(0);
                                                setShowCandidateReviewState("rate");
                                            }
                                        }}>{ showCandidateReviewHasRating ? "Next" : "Rate"}</button>
                                    </div>
                                </div>
                            }
                            {
                                showCandidateReviewState === "rate" && <div>
                                    <div>
                                        <p>Select a rating, by clicking on the stars</p>
                                        <FiveStarRating value={showCandidateReviewRatingValue} onChange={setShowCandidateReviewRatingValue} fontSize={null} readOnly={false}/>
                                    </div>
                                    <div className="buttons">                                    
                                        <button className="button-primary-blue inline-full" onClick={ e => {                                                 
                                            setShowCandidateReviewState("answer");
                                        }}>Back</button>
                                        <button className="button-primary-blue inline-full" onClick={ async e => {
                                            if(showCandidateReviewRatingValue <= 0){
                                                toast.error("You need to select a rating");
                                                return;
                                            }
                                            toast.info("Submitting Candidate Rating");
                                            const candidate_id = showCandidateReviewCandidateInfo.id;
                                            const question_id = showCandidateReviewData[showCandidateReviewIndex]['question']['id'];
                                            const result = await API.review.createRating(apiKey, question_id, candidate_id, showCandidateReviewRatingValue);                                
                                            const success = result['success'];
                                            if(!success){
                                                toast.error("Error Submitting Rating");
                                                return;
                                            }
                                            toast.success("Candidate Rating Submitted");
                                            setShowCandidateReviewRatingValue(0);
                                            const isNextIndexWithinArrayLength = (showCandidateReviewIndex + 1) >= showCandidateReviewData.length;                                            
                                            if(isNextIndexWithinArrayLength){
                                                setShowCandidateReviewState("done");
                                                return;
                                            }
                                            setShowCandidateReviewState("question");
                                            setShowCandidateReviewIndex(showCandidateReviewIndex + 1);
                                        }}>Next</button>
                                    </div>
                                </div>
                            }
                            {
                                showCandidateReviewState === "done" && <div>
                                    <div>
                                        <p>You are now finished reviewing the candidate</p>
                                        <p>Thank you for reviewing this candidate.</p>
                                    </div>
                                    <div className="buttons">
                                        <button className="button-primary-blue blocked full" onClick={ e => {                                                                         
                                            setShowCandidateReviewCandidateInfo(null);
                                            setShowCandidateReviewData([]);
                                            setShowCandidateReviewIndex(0);
                                            setShowCandidateReviewState("start");
                                            setShowCandidateReviewPopUp(false);
                                            if(!showCandidateReviewCompletionFunction){
                                                toast.error("Callback is empty");
                                                return;
                                            }
                                            showCandidateReviewCompletionFunction(true);      
                                        }}>Done</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        
        { showGetNameAndEmail && <div className="custom-popup-dialog">
                <div>
                    <div className="header">
                        <h4>{ showGetNameAndEmailTitle }</h4>
                        <button onClick={ e => {          
                            setShowGetNameAndEmailCompletionCallback(null);                                       
                            setShowGetNameAndEmailNameValue("");
                            setShowGetNameAndEmailEmailValue("");
                            setShowGetNameAndEmail(false);     
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="nice-form">
                            <label htmlFor="">
                                <span>Full Name</span>
                            </label>
                            <input type="text" placeholder="Full Name" value={showGetNameAndEmailNameValue} onChange={ e => setShowGetNameAndEmailNameValue(e.target.value)}/>                            
                            <label htmlFor="">
                                <span>Email Address</span>
                            </label>
                            <input type="text" placeholder="Email Address" value={showGetNameAndEmailEmailValue} onChange={ e => setShowGetNameAndEmailEmailValue(e.target.value)}/>
                            <div className="footer">
                                <button className="button-primary-blue blocked full" onClick={ async e => {
                                    if(!showGetNameAndEmailNameValue){
                                        toast.error("A Full Name is required");
                                        return;
                                    }
                                    if(!showGetNameAndEmailEmailValue){
                                        toast.error("An Email Address is required");
                                        return;
                                    }
                                    if(!showGetNameAndEmailCompletionCallback){
                                        toast.error("Error, callback undefined");
                                        return;
                                    }
                                    const result = await showGetNameAndEmailCompletionCallback({
                                        fullname: showGetNameAndEmailNameValue,
                                        email: showGetNameAndEmailEmailValue
                                    })
                                    if(!result){
                                        toast.error("Error calling callback");
                                        return;
                                    }
                                    setShowGetNameAndEmailCompletionCallback(null);    
                                    setShowGetNameAndEmailNameValue("");
                                    setShowGetNameAndEmailEmailValue("");
                                    setShowGetNameAndEmail(false);
                                }}>Confirm</button>
                                <button className="button-primary-blue blocked full" onClick={ e => {   
                                    setShowGetNameAndEmailCompletionCallback(null);                                       
                                    setShowGetNameAndEmailNameValue("");
                                    setShowGetNameAndEmailEmailValue("");
                                    setShowGetNameAndEmail(false);     
                                }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        { showCandidateComments && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Comments</h4>
                        <button onClick={ e => {          
                            setShowCandidateCommentsUsers([]);
                            setShowCandidateCommentsData(null);
                            setShowCandidateComments(false);     
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div className="candidate-comment-container">
                            <ul>
                                {
                                    showCandidateCommentsUsers.map((commentor:any, index:number)=>{
                                        return <li key={`commentor-${index}`}>
                                            <h4>{ commentor }</h4>
                                            <ul>
                                                {
                                                    showCandidateCommentsData[commentor].map((comment:any, index2:number)=>{
                                                        return <li key={`comment-${commentor}-${index2}`}>
                                                            <div><strong>{ `Question ${index2 + 1}` }</strong>&nbsp;{ getRelativeTime(new Date(comment.updated_at)) }&nbsp;<FiveStarRating value={comment.rank} onChange={(value)=>{}} readOnly={true} fontSize={"12px"}/></div>
                                                            <div>{ comment.comment }</div>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }
        { showBespokeWorkFlow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Step { showBespokeWorkFlowStep } of 5 - Bespoke Settings</h4>
                        <button onClick={ e => {                                 
                            setShowBespokeWorkFlowInterviewID(0);
                            setShowBespokeWorkFlowState("none");
                            setShowBespokeWorkFlowStep(0);
                            setShowBespokeWorkFlow(false);        
                        }}>X</button>
                    </div>
                    <div className="content">
                        {
                            (showBespokeWorkFlowState === "step-1") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Allow candidate to go to the interview</span>
                                </label>
                                <select name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.marketing_interview ? interview.marketing_interview?.toString() : "0"} onChange={ async e => {
                                    toast.info("Updating Candidate Interview Participation");
                                    let stateValueAsNumber = parseInt(e.target.value);
                                    let result = await API.interview.setBespokeAllowCandidateInterivew(apiKey, interview.id, stateValueAsNumber);
                                    toast.success("Updated");
                                }}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowBespokeWorkFlowState("step-2");
                                    setShowBespokeWorkFlowStep(2);
                                }}>Next</button>
                            </div>
                        }
                        {
                            (showBespokeWorkFlowState === "step-2") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Marketing Text</span>
                                </label>
                                <textarea name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.marketing_text?.toString()} onChange={ async e => {
                                    let text = e.target.value;
                                    let result = await API.interview.setCustomMarketingText(apiKey, interview.id, text);
                                }} style={{width:'708px', height:'146px'}}>
                                </textarea>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowBespokeWorkFlowState("step-3"); 
                                    setShowBespokeWorkFlowStep(3);
                                }}>Next</button>
                            </div>
                        }
                        {
                            (showBespokeWorkFlowState === "step-3") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Allow candidate to go to an ATS link</span>
                                </label>
                                <select name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.ats_bespoke ? interview.ats_bespoke?.toString() : "0"} onChange={ async e => {
                                    toast.info("Updating Interview");
                                    let textAsNumber = parseInt(e.target.value);
                                    let result = await API.interview.setATSBespokeAllow(apiKey, interview.id, textAsNumber);
                                    toast.success("Updated");
                                    if(textAsNumber === 1){
                                        toast.info("Please wait to enter your ATS Link");
                                    }
                                    reloadInterview();
                                }}>
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </select>
                                {
                                    (interview.ats_bespoke === 1) && <>
                                        <label htmlFor="allow-candidate-participation">
                                            <span>Enter ATS link</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="ATS Link"
                                            onChange={ async e => {
                                                let text = e.target.value;
                                                let result = await API.interview.setATSBespokeText(apiKey, interview.id, text);
                                            }}
                                            style={{ width: "calc(100% - 84px)", padding: "8px" }}
                                            defaultValue={interview.ats_bespoke_url}
                                        />
                                    </>
                                }
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowBespokeWorkFlowStep(4);
                                    setShowBespokeWorkFlowState("step-4");
                                }}>Next</button>
                            </div>
                        }
                        
                        {
                            (showBespokeWorkFlowState === "step-4") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Allow candidate to see Introduction Videos</span>
                                </label>
                                <select name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.itj_bespoke ? interview.itj_bespoke?.toString() : "0"} onChange={ async e => {
                                    toast.info("Updating Interview");
                                    let textAsNumber = parseInt(e.target.value);
                                    let result = await API.interview.setITJBespokeAllow(apiKey, interview.id, textAsNumber);
                                    toast.success("Updated");
                                }}>
                                  <option value="0">No</option>
                                  <option value="1">Yes</option>
                                </select>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowBespokeWorkFlowStep(5);
                                    setShowBespokeWorkFlowState("step-5");
                                }}>Next</button>
                            </div>
                        }
                        {
                            (showBespokeWorkFlowState === "step-5") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Now add a candidate and start recording your bespoke videos</span>
                                </label>
                                <button className="button-primary-blue blocked full" onClick={ async e => {  
                                    if(!showBespokeWorkFlowCallback){
                                        toast.error("Callback Invalid");
                                        return;
                                    }                   
                                    setShowBespokeWorkFlowInterviewID(0);
                                    setShowBespokeWorkFlowState("none");
                                    setShowBespokeWorkFlowStep(0);
                                    setShowBespokeWorkFlow(false);
                                    await showBespokeWorkFlowCallback({ success: true});
                                    setShowBespokeWorkFlowCallback(null);
                                }}>Done</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        { showCustomVideoPreview && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Video Preview</h4>
                        <button onClick={ e => {          
                            setShowCustomVideoPreviewURL("");
                            setShowCustomVideoPreview(false);     
                        }}>X</button>
                    </div>
                    <div className="content">
                        <div>
                            <video className="candidate-review-container-video" ref={showCustomVideoPreviewPlayerRef} src={showCustomVideoPreviewURL} autoPlay controls playsInline onPlay={ e => { setShowCandidateReviewVideoAnswerIsPlaying(true);}} onEnded={ e => { setShowCustomVideoPreviewIsPlaying(false);}} onLoad={ e => {
                                try{e.currentTarget.play();} catch(e){}
                            }}></video>
                            <div className="video-button-controls-container">
                                <button className="button-primary-blue" onClick={ e => {
                                    if(!showCustomVideoPreviewPlayerRef) return;
                                    if(!showCustomVideoPreviewPlayerRef.current) return;
                                    if(showCustomVideoPreviewIsPlaying){
                                        try{showCustomVideoPreviewPlayerRef.current.pause();} catch(e){}
                                        setShowCustomVideoPreviewIsPlaying(false);
                                    } else {
                                        try{showCustomVideoPreviewPlayerRef.current.play();} catch(e){}
                                        setShowCustomVideoPreviewIsPlaying(true);
                                    }
                                }}>{ showCustomVideoPreviewIsPlaying ? "Pause" : "Play"}</button>
                                <button className="button-primary-blue" onClick={ e => {
                                    if(!showCustomVideoPreviewPlayerRef) return;
                                    if(!showCustomVideoPreviewPlayerRef.current) return;
                                    try{showCustomVideoPreviewPlayerRef.current.pause();} catch(e){}
                                    setShowCustomVideoPreviewIsPlaying(false);
                                }}>Stop</button>
                                <button className="button-primary-blue" onClick={ e => {
                                    if(!showCustomVideoPreviewPlayerRef) return;
                                    if(!showCustomVideoPreviewPlayerRef.current) return;
                                    try{showCustomVideoPreviewPlayerRef.current.pause();} catch(e){}                                                    
                                    try{showCustomVideoPreviewPlayerRef.current.currentTime = 0 } catch(e){}
                                    try{showCustomVideoPreviewPlayerRef.current.play();} catch(e){}
                                    setShowCustomVideoPreviewIsPlaying(true);
                                }}>Restart</button>
                            </div>
                        </div>
                        <div style={{marginTop:'8px', textAlign:"left"}}>
                            <button className="button-primary-blue" onClick={ e => {
                                if(!showCustomVideoPreviewPlayerRef) return;
                                if(!showCustomVideoPreviewPlayerRef.current) return;
                                try{showCustomVideoPreviewPlayerRef.current.pause();} catch(e){}
                                setShowCustomVideoPreviewIsPlaying(false);
                                setShowCustomVideoPreview(false);                             
                                setShowAddSubtitlePopUpVideoURL("");
                                setShowAddSubtitlePopUpState("show");
                                setShowSubtitle(true);
                            }}>Add Subtitles</button>
                        </div>    
                    </div>
                </div>
            </div>
        }
        { (showSubtitle && showCustomVideoPreviewURL) && <div className="custom-popup-dialog">
                <div style={{width:'720px'}}>
                    <div className="header">
                        <h4>Add Subtitles to your video?</h4>
                        <button onClick={ e => {
                            setShowAddSubtitlePopUpVideoURL("");
                            setShowAddSubtitlePopUpState("show");
                            setShowSubtitle(false);
                            setShowCustomVideoPreview(true);
                        }}>X</button>
                    </div>
                    <div className="content">
                        { showAddSubtitlePopUpState === "show" && <div>
                            <STTURLComponent
                                videoURL={showCustomVideoPreviewURL}
                                onCompletion={async (video_url:string)=>{
                                    if(!showSubtitleCallback){ toast.error("SUBTITLE FUNCTION MISSING"); return};
                                    toast.info("Updating");
                                    await showSubtitleCallback(video_url);                            
                                    setShowAddSubtitlePopUpVideoURL("options");
                                    setShowAddSubtitlePopUpVideoURL("");
                                    setShowSubtitle(false);
                                    setShowCustomVideoPreview(false);
                                    toast.info("Reloading Interview");
                                }}
                                onReject={()=>{
                                    setShowAddSubtitlePopUpVideoURL("options");
                                    setShowAddSubtitlePopUpVideoURL("");
                                    setShowSubtitle(false);
                                    toast.info("Reloading Interview");
                                    reloadInterview();
                                }}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        { showCustomVideoOptions && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>OutReach Campaign Video</h4>
                        <button onClick={ e => {          
                            setShowCustomVideoOptions(false); 
                        }}>X</button>
                    </div>
                    <div className="content">                    
                        <button className="button-primary-blue blocked full" onClick={ e => {
                            setShowCustomVideoOptions(false);
                            setShowCustomVideoRecorder(true);
                        }}>Record</button>
                        { /* DISABLED THE IN BROWSER RECORDING FOR THIS, TILL WE CAN DO A RECORD AND FORGET, THINK I SHOULD PERHAPS MAKE ANOTHER COMPONENT */}
                        { /* <button className="button-primary-blue blocked full" onClick={ e => {                            
                            setShowCustomVideoOptions(false);
                            setShowCustomVideoRecorder2(true);
                        }}>Record (Experimental)</button> */}
                    </div>
                </div>
            </div>
        }
        { showCustomVideoRecorder && <div className="custom-popup-dialog">
                <div style={{ width: "510px" }}>
                    <div className="header">
                        <h4>Record Custom Video</h4>
                        <button onClick={ e => {          
                            setShowCustomVideoRecorderCallback(null);
                            setShowCustomVideoRecorderCandidateID(0);
                            setShowCustomVideoRecorder(false);     
                        }}>X</button>
                    </div>
                    <div className="content">
                        <InBrowserRecorderAndProcessingComponentV2
                            apiKey={localStorage.getItem("api_key") ?? ""}
                            clientWatermarkImage={user ? user.watermark_url : ""}
                            onClose={ () => {}}
                            onCompletion={ async video_url => {
                                setShowCustomVideoRecorderVideoURL(video_url);
                                setShowCustomVideoRecorderGif(true);                                
                                setShowCustomVideoRecorder(false);
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        { showCustomVideoRecorderGif && <div className="custom-popup-dialog">
                <div style={{ width: "510px" }}>
                    <div className="content">
                        <InBrowserGifFromVideoComponent
                            apiKey={localStorage.getItem("api_key") ?? ""}
                            inputVideoURL={showCustomVideoRecorderVideoURL}
                            text={`Hi ${showCustomVideoOptionsDataName}`}
                            onClose={ () => {}}
                            onCompletion={ async gif_url => {
                                if(gif_url === null || gif_url === ""){
                                    toast.error("Gif Creation Failed");
                                    return;
                                }
                                setShowCustomVideoRecorderGifURL(gif_url);
                                toast.info("Loading");
                                await API.candidate.setCustomVideo2(apiKey, showCustomVideoRecorderCandidateID, showCustomVideoRecorderVideoURL, gif_url);
                                toast.success("Candidate Custom Video and Gif Set Successfully!");
                                if(!showCustomVideoRecorderCallback){
                                    toast.error("");
                                    return;
                                }
                                showCustomVideoRecorderCallback(null);
                                setShowCustomVideoRecorderGif(false);
                            }}
                            key={"my-gif-creator"}
                        />
                    </div>
                </div>
            </div>
        }
        { showCustomVideoRecorder2 && <div className="custom-popup-dialog">
                <div style={{ width: "510px" }}>
                    <div className="header">
                        <h4>Record Custom Video</h4>
                        <button onClick={ e => {          
                            setShowCustomVideoRecorderCallback(null);
                            setShowCustomVideoRecorderCandidateID2(0);
                            setShowCustomVideoRecorder2(false);     
                        }}>X</button>
                    </div>
                    <div className="content">
                        <InBrowserRecorderAndProcessingComponent
                            apiKey={apiKey}
                            onClose={()=>{
                                console.log("COMPONENT CLOSED");
                                setShowCustomVideoRecorderCallback(null);
                                setShowCustomVideoRecorderCandidateID2(0);
                                setShowCustomVideoRecorder2(false); 
                            }} onCompletion={async (video_url:string)=>{
                                console.log(video_url);
                                await API.candidate.setProcessing(apiKey, showCustomVideoRecorderCandidateID2);
                                setShowCustomVideoRecorderCallback(null);
                                setShowCustomVideoRecorderCandidateID2(0);
                                setShowCustomVideoRecorder2(false); 
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        
        { showOutReachVideo && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>OutReach Campaign Video</h4>
                        <button onClick={ e => {          
                            setShowOutReachVideoState("");
                            setShowOutReachVideoSubState("");
                            setShowOutReachVideoInterviewID(0);
                            setShowOutReachVideo(false); 
                        }}>X</button>
                    </div>
                    <div className="content">
                    {
                            (showOutReachVideoState === "step-1" && showOutReachVideoSubState === "options") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>Add an OutReach Campaign Video</span>
                                </label>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachVideoSubState("upload");
                                    setShowOutReachVideoState("step-2");
                                }}>Upload</button>
                                {/*<button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachVideoSubState("record");
                                    setShowOutReachVideoState("step-2");
                                    setTimeout(()=>{
                                        if(!showOuReachVideoRecorderRef) return;        
                                        if(!showOuReachVideoRecorderRef.current) return;                        
                                        showOuReachVideoRecorderRef.current.resetUI();
                                        showOuReachVideoRecorderRef.current.openVideo();
                                    }, 1500);
                                }}>Record</button>*/}
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachVideoSubState("record-2");
                                    setShowOutReachVideoState("step-2");
                                }}>Record</button>
                            </div>
                        }
                        {
                            (showOutReachVideoState === "step-2" && showOutReachVideoSubState === "upload") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>OutReach Campaign Video</span>
                                </label>
                                <VideoUploadComponent onreject={()=>{
                                    setShowOutReachVideoSubState("options");
                                    setShowOutReachVideoState("step-1");
                                }} onclose={()=>{  
                                    setShowOutReachVideoSubState("options");
                                    setShowOutReachVideoState("step-1");
                                }} onaccept={ async (video_url:string)=>{
                                    toast.info("Updating");
                                    if(!showOutReachVideoCallback){
                                        toast.error("Missing Callback");
                                        return;
                                    }
                                    setShowLoading2(true);
                                    let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, video_url);
                                    let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                    setShowLoading2(false);
                                    toast.info("Updated");
                                    showOutReachVideoCallback({ success: true});                                    
                                    setShowOutReachVideoState("");
                                    setShowOutReachVideoSubState("");
                                    setShowOutReachVideo(false);
                                    // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                    setShowDirectSubtitlePopUpURL(video_url);
                                    const callback = (new_video_url: string) => {
                                        return new Promise<boolean>((resolve, reject) => {
                                            try {
                                                const fetchData = async () => {
                                                    setShowLoading2(true);
                                                    let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, new_video_url);
                                                    let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                    toast.info("Updated");
                                                    setShowLoading2(false);
                                                    resolve(true); // Resolve the promise after all operations are completed
                                                };
                                    
                                                fetchData();
                                            } catch (error) {
                                                setShowLoading2(false);
                                                reject(error); // Reject the promise if any error occurs
                                            }
                                        });
                                    }
                                    showDirectSubtitlePopUpFunc.current = callback;
                                    setShowAskDirectSubtitlePopUp(true);
                                    setShowOutReachVideoInterviewID(0);
                                }} watermark_url={""}                           
                                />
                            </div>
                        }
                        {
                            (showOutReachVideoState === "step-2" && showOutReachVideoSubState === "record") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>OutReach Campaign Video</span>
                                    {/*<VideoRecordComponent
                                        ref={showOuReachVideoRecorderRef}
                                        nopreview={false}
                                        onnopreview={async () => {                                              
                                        }}
                                        watermark_url={user.watermark_url ?? ""}
                                        onclose={() => {    
                                            setShowCustomVideoRecorderCallback(null);
                                            setShowCustomVideoRecorderCandidateID(0);
                                            setShowCustomVideoRecorder(false);   
                                        }}
                                        oncompletion={async (video_url:string)=>{
                                            toast.info("Updating");
                                            if(!showOutReachVideoCallback){
                                                toast.error("Missing Callback");
                                                return;
                                            }
                                            setShowLoading2(true);
                                            let result = await API.interview.setBulkMarketingVideo(apiKey, showOutReachVideoInterviewID, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, showOutReachVideoInterviewID, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            showOutReachVideoCallback({ success: true});                                    
                                            setShowOutReachVideoState("");
                                            setShowOutReachVideoSubState("");
                                            setShowOutReachVideoInterviewID(0);
                                            if(showOuReachVideoRecorderRef){
                                                if(showOuReachVideoRecorderRef.current){
                                                    try{showOuReachVideoRecorderRef.current.closeDialog();}catch(e){}
                                                }
                                            }
                                            setShowOutReachVideo(false);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>((resolve, reject) => {
                                                    setShowLoading2(true);
                                                    API.interview.setBulkMarketingVideo(apiKey, showOutReachVideoInterviewID, new_video_url)
                                                        .then(result => {
                                                            return API.interview.markVideo(0, 0, showOutReachVideoInterviewID, 2);
                                                        })
                                                        .then(result2 => {
                                                            setShowLoading2(false);
                                                            toast.info("Updated");
                                                            resolve(true); // Resolve the promise after all operations are completed
                                                        })
                                                        .catch(error => {
                                                            setShowLoading2(false);
                                                            reject(error); // Reject the promise if any error occurs
                                                        });
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(true);
                                        }}  
                                        fixWidth={"510px"}   
                                        />*/}
                                        <InBrowserRecorderAndProcessingComponent
                            apiKey={apiKey}
                            onClose={()=>{
                                console.log("COMPONENT CLOSED");
                                setShowCustomVideoRecorderCallback(null);
                                setShowCustomVideoRecorderCandidateID(0);
                                setShowCustomVideoRecorder(false); 
                            }} onCompletion={async (video_url:string)=>{
                                console.log(video_url);toast.info("Updating");
                                if(!showOutReachVideoCallback){
                                    toast.error("Missing Callback");
                                    return;
                                }
                                setShowLoading2(true);
                                let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, video_url);
                                let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                toast.info("Updated");
                                setShowLoading2(false);
                                showOutReachVideoCallback({ success: true});                                    
                                setShowOutReachVideoState("");
                                setShowOutReachVideoSubState("");
                                setShowOutReachVideoInterviewID(0);
                                setShowOutReachVideo(false);
                                // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                setShowDirectSubtitlePopUpURL(video_url);
                                const callback = (new_video_url: string) => {
                                    return new Promise<boolean>((resolve, reject) => {
                                        setShowLoading2(true);
                                        API.interview.setBulkMarketingVideo(apiKey, interview.id, new_video_url)
                                            .then(result => {
                                                return API.interview.markVideo(0, 0, interview.id, 2);
                                            })
                                            .then(result2 => {
                                                setShowLoading2(false);
                                                toast.info("Updated");
                                                resolve(true); // Resolve the promise after all operations are completed
                                            })
                                            .catch(error => {
                                                setShowLoading2(false);
                                                reject(error); // Reject the promise if any error occurs
                                            });
                                    });
                                };
                                showDirectSubtitlePopUpFunc.current = callback;
                                if(showDirectSubtitlePopUpFunc.current) await showDirectSubtitlePopUpFunc.current(video_url);
                                setShowAskDirectSubtitlePopUp(true);
                            }}
                        />
                                </label>                    
                            </div>
                        }
                        {
                            (showOutReachVideoState === "step-2" && showOutReachVideoSubState === "record-2") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>OutReach Campaign Video</span>
                                    <InBrowserRecorderAndProcessingComponent
                                        apiKey={apiKey}
                                        onClose={()=>{
                                            console.log("COMPONENT CLOSED");
                                            setShowCustomVideoRecorderCallback(null);
                                            setShowCustomVideoRecorderCandidateID(0);
                                            setShowCustomVideoRecorder(false);  
                                        }} onCompletion={async (video_url:string)=>{
                                            toast.info("Updating");
                                            if(!showOutReachVideoCallback){
                                                toast.error("Missing Callback");
                                                return;
                                            }
                                            setShowLoading2(true);
                                            let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            showOutReachVideoCallback({ success: true});                                    
                                            setShowOutReachVideoState("");
                                            setShowOutReachVideoSubState("");
                                            setShowOutReachVideoInterviewID(0);
                                            setShowOutReachVideo(false);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = async (new_video_url: string) => {
                                                try {
                                                    setShowLoading2(true);
                                                    const [result1, result2] = await Promise.all([
                                                        API.interview.setBulkMarketingVideo(apiKey, interview.id, new_video_url),
                                                        API.interview.markVideo(0, 0, interview.id, 2)
                                                    ]);
                                                    setShowLoading2(false);
                                                    toast.info("Updated");
                                                    return true;
                                                } catch (error) {
                                                    setShowLoading2(false);
                                                    console.error("Error updating video:", error);
                                                    return false;
                                                }
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            if(showDirectSubtitlePopUpFunc.current) await showDirectSubtitlePopUpFunc.current(video_url);
                                            setShowAskDirectSubtitlePopUp(true);
                                        }}
                                    />
                                </label>                    
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        { showOutReachWorkFlow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Step { showOutReachWorkFlowStep } of 5 - Outreach Campaign Settings</h4>
                        <button onClick={ e => {                                 
                            setShowOutReachWorkFlowInterviewID(0);
                            setShowOutReachWorkFlowState("none");
                            setShowOutReachWorkFlowStep(0);
                            setShowOutReachWorkFlow(false);        
                        }}>X</button>
                    </div>
                    <div className="content">
                        {
                            (showOutReachWorkFlowState === "step-1") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Allow candidate to participate in the interview</span>
                                </label>
                                <select name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.marketing_interview ? interview.marketing_interview?.toString() : "0"} onChange={ async e => {
                                    toast.info("Updating Candidate Interview Participation");
                                    let stateValueAsNumber = parseInt(e.target.value);
                                    let result = await API.interview.setBespokeAllowCandidateInterivew(apiKey, interview.id, stateValueAsNumber);
                                    toast.success("Updated");
                                }}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachWorkFlowState("step-2");
                                    setShowOutReachWorkFlowSubState("options");
                                    setShowOutReachWorkFlowStep(2);
                                }}>Next</button>
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-2" && showOutReachWorkFlowSubState === "options") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>OutReach Campaign Video</span>
                                </label>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachWorkFlowState("step-2");
                                    setShowOutReachWorkFlowSubState("upload");
                                    setShowOutReachWorkFlowStep(2);
                                }}>Upload</button>
                                {/*<button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachWorkFlowState("step-2");
                                    setShowOutReachWorkFlowSubState("record");
                                    setShowOutReachWorkFlowStep(2);
                                    setTimeout(()=>{
                                        if(!showOutReachVideoWorkFlowRecorderRef) return;        
                                        if(!showOutReachVideoWorkFlowRecorderRef.current) return;                        
                                        showOutReachVideoWorkFlowRecorderRef.current.resetUI();
                                        showOutReachVideoWorkFlowRecorderRef.current.openVideo();
                                    }, 1500);
                                }}>Record</button>*/}
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachWorkFlowState("step-2");
                                    setShowOutReachWorkFlowSubState("record-2");
                                    setShowOutReachWorkFlowStep(2);
                                }}>Record</button>
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-2" && showOutReachWorkFlowSubState === "upload") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>OutReach Campaign Video - Upload</span>
                                    <VideoUploadComponent
                                        onreject={()=>{
                                            setShowOutReachWorkFlowState("step-2");
                                            setShowOutReachWorkFlowSubState("options");
                                            setShowOutReachWorkFlowStep(2);
                                        }} onclose={()=>{  
                                            setShowOutReachWorkFlowState("step-2");
                                            setShowOutReachWorkFlowSubState("options");
                                            setShowOutReachWorkFlowStep(2);
                                        }} onaccept={ async (video_url:string)=>{
                                            toast.info("Updating");
                                            toast.info("Updating, Please Wait");
                                            setShowLoading2(true);
                                            let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            setShowOutReachWorkFlowState("step-3");
                                            setShowOutReachWorkFlowSubState("");
                                            setShowOutReachWorkFlowStep(3);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, new_video_url);
                                                        let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(false);
                                        }} watermark_url={""} 
                                    />
                                </label>                                
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-2" && showOutReachWorkFlowSubState === "record") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>OutReach Campaign Video - Record</span>
                                    {/*<VideoRecordComponent
                                        ref={showOutReachVideoWorkFlowRecorderRef}
                                        nopreview={false}
                                        onnopreview={async () => {                                              
                                        }}
                                        watermark_url={user.watermark_url ?? ""}
                                        onclose={() => {    
                                            setShowOutReachWorkFlowState("step-2");
                                            setShowOutReachWorkFlowSubState("options");
                                            setShowOutReachWorkFlowStep(2); 
                                        }}
                                        oncompletion={async (video_url:string)=>{
                                            toast.info("Updating");
                                            setShowLoading2(true);
                                            let result = await API.interview.setBulkMarketingVideo(apiKey, showOutReachVideoInterviewID, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, showOutReachVideoInterviewID, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            if(showOutReachVideoWorkFlowRecorderRef){
                                                if(showOutReachVideoWorkFlowRecorderRef.current){
                                                    try{showOutReachVideoWorkFlowRecorderRef.current.closeDialog();}catch(e){}
                                                }
                                            }
                                            setShowOutReachWorkFlowState("step-3");
                                            setShowOutReachWorkFlowSubState("");
                                            setShowOutReachWorkFlowStep(3);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setBulkMarketingVideo(apiKey, showOutReachVideoInterviewID, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, showOutReachVideoInterviewID, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(true);
                                        }}  
                                        fixWidth={"510px"}   
                                    />*/}
                                    <InBrowserRecorderAndProcessingComponentV2
                                        apiKey={apiKey}
                                        clientWatermarkImage={user ? user.watermark_url : ""}
                                        onClose={()=>{
                                            console.log("COMPONENT CLOSED");
                                            setShowOutReachWorkFlowState("step-2");
                                            setShowOutReachWorkFlowSubState("options");
                                            setShowOutReachWorkFlowStep(2); 
                                        }} onCompletion={async (video_url:string)=>{
                                            toast.info("Updating");
                                            setShowLoading2(true);
                                            let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            setShowOutReachWorkFlowState("step-3");
                                            setShowOutReachWorkFlowSubState("");
                                            setShowOutReachWorkFlowStep(3);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setBulkMarketingVideo(apiKey, interview.id, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            if(showDirectSubtitlePopUpFunc.current) await showDirectSubtitlePopUpFunc.current(video_url);
                                            setShowAskDirectSubtitlePopUp(false);
                                        }}
                                    />
                                </label>                                
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-2" && showOutReachWorkFlowSubState === "record-2") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>OutReach Campaign Video - Record</span>
                                    <InBrowserRecorderAndProcessingComponentV2
                                        apiKey={apiKey}                                        
                                        clientWatermarkImage={user ? user.watermark_url : ""}
                                        onClose={()=>{
                                            console.log("COMPONENT CLOSED");
                                            setShowOutReachWorkFlowState("step-2");
                                            setShowOutReachWorkFlowSubState("options");
                                            setShowOutReachWorkFlowStep(2); 
                                        }} onCompletion={async (video_url:string)=>{
                                            toast.info("Updating");
                                            setShowLoading2(true);
                                            let result = await API.interview.setBulkMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            setShowOutReachWorkFlowState("step-3");
                                            setShowOutReachWorkFlowSubState("");
                                            setShowOutReachWorkFlowStep(3);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setBulkMarketingVideo(apiKey, interview.id, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(false);
                                        }}
                                    />
                                </label>                                
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-3") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <label htmlFor="outReach-campaign-caption-text">
                                        <span>OutReach Campaign Caption Text</span>
                                    </label>
                                    <textarea
                                        id="outReach-campaign-caption-text"
                                        name="outReach-campaign-caption-text"
                                        style={{ width: "100%", height: "123px", marginTop:'12px'}}
                                        onChange={async (e:any)=>{
                                        let type_value = e.target.value;
                                        let result = await API.interview.setCustomThumbnailOutreachType(
                                            apiKey,
                                            showOutReachVideoInterviewID,
                                            type_value
                                        );
                                        setShowOutReachWorkFlowState("step-3");
                                        setShowOutReachWorkFlowSubState("");
                                        setShowOutReachWorkFlowStep(3);
                                        }}
                                        defaultValue={interview?.bulk_marketing_caption_text}
                                    >
                                    </textarea>
                                </label>      
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachWorkFlowState("step-4");
                                    setShowOutReachWorkFlowSubState("");
                                    setShowOutReachWorkFlowStep(4);
                                }}>Next</button>                            
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-4") && <div className='nice-form-2'>
                                <label htmlFor="outReach-campaign-custom-gif-text">
                                    <span>OutReach Campaign Custom Gif Text</span>
                                </label>
                                <select
                                    defaultValue={interview.custom_thumbnail_outreach_type ? interview.custom_thumbnail_outreach_type?.toString() : "0"}
                                    onChange={async (e:any)=>{
                                    toast.info("Updating");
                                    let type_value = parseInt(e.target.value);
                                    let result = await API.interview.setCustomThumbnailOutreachType(
                                        apiKey,
                                        interview.id,
                                        type_value
                                    );
                                    toast.success("Updated");
                                    if(type_value === 3){
                                        toast.info("Enter a Custom Message Below");
                                    }
                                    reloadInterview();
                                }}
                                >
                                    <option value="0">Hi {"{candidate}"}</option>
                                    <option value="1">Hello {"{candidate}"}</option>
                                    <option value="2">Hello {"{candidate}"}</option>
                                    <option value="3">Custom Message</option>
                                </select>
                                {
                                    (interview.custom_thumbnail_outreach_type === 3) && <div className="customMessageContainer" style={{
                                        width: 'calc(100% - 21px)', margin: '0 auto', transform: 'translateX(-23px)'
                                    }}>
                                    <span>Enter a custom message:</span>
                                    <input
                                        type="text"
                                        defaultValue={
                                            interview.bulk_marketing_text
                                        }
                                        onChange={ async e => {
                                            let text = e.target.value;
                                            let result = await API.interview.setBulkMarketingText(
                                                apiKey,
                                                interview.id,
                                                text
                                            );
                                        }}
                                        style={{ display:'block', width:'100%' }}
                                    />
                                    </div>
                                }             
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowOutReachWorkFlowState("step-5");
                                    setShowOutReachWorkFlowSubState("");
                                    setShowOutReachWorkFlowStep(5);
                                }}>Next</button>                   
                            </div>
                        }
                        {
                            (showOutReachWorkFlowState === "step-5") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Allow candidate to participate in the interview</span>
                                    </label>
                                    <select name="allow-candidate-participation" id="allow-candidate-participation" defaultValue={interview.marketing_interview ? interview.marketing_interview?.toString() : "0"} onChange={ async e => {
                                        toast.info("Updating Candidate Interview Participation");
                                        let stateValueAsNumber = parseInt(e.target.value);
                                        let result = await API.interview.setBespokeAllowCandidateInterivew(apiKey, interview.id, stateValueAsNumber);
                                        toast.success("Updated");
                                        reloadInterview();
                                    }}>
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                    <label htmlFor="allow-candidate-ats-link">
                                        <span>Allow candidate to go to an ATS link</span>
                                    </label>
                                    <select
                                        className="bulkySelect"
                                        defaultValue={interview.ats_outreach ? interview.ats_outreach?.toString() : "0"}
                                        id="allow-candidate-ats-link"
                                        name="allow-candidate-ats-link"
                                        onChange={ async e => {
                                            toast.info("Updating ATS Link");
                                            let stateValueAsNumber = parseInt(e.target.value);
                                            let result = await API.interview.setATSOutreachAllow(apiKey, interview.id, stateValueAsNumber);
                                            toast.success("Updated");
                                            await reloadInterview();
                                        }}
                                        >
                                        <option value="0">No</option>
                                        <option value="1">Yes</option>
                                        </select>
                                    {
                                    (!!interview.ats_outreach) && <>
                                        <label htmlFor="ats-link-data">
                                            <span>ATS link</span>
                                        </label>
                                        <div style={{ display: "inline-block", fontSize: "8pt" }}>
                                            Example URL&nbsp;&nbsp;
                                            <strong>
                                            <span style={{ backgroundColor: "yellow" }}>
                                                https://
                                            </span>
                                            www.example.com/
                                            </strong>
                                        </div>&nbsp;
                                        <input
                                            type="text"
                                            id="ats-link-data"
                                            name="ats-link-data"
                                            placeholder="https://www.example.com/"
                                            style={{ width: "calc(100% - 84px)", padding: "8px" }}
                                            defaultValue={interview.ats_outreach_url}
                                            onChange={ async e => {
                                                let val = e.target.value;
                                                let result = await API.interview.setATSOutreachText(apiKey, interview.id, val);
                                                reloadInterview();
                                        }}
                                        />
                                    </>
                                    }   
                                    <button className="button-primary-blue blocked full" onClick={ async e => {                     
                                        setShowOutReachWorkFlowInterviewID(0);
                                        setShowOutReachWorkFlowState("none");
                                        setShowOutReachWorkFlowStep(0);
                                        setShowOutReachWorkFlow(false);
                                        if(!showOutReachWorkFlowCallback){
                                            toast.error("Callback Missing");
                                            return;
                                        }
                                        await showOutReachWorkFlowCallback({success:true});
                                        toast.info("Reloading Folder");
                                        reloadInterview();  
                                    }}>Done</button>                   
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        { showSocialFlow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Step { showSocialFlowStep } of 5 - Social Post Settings</h4>
                        <button onClick={ e => {                                 
                            setShowSocialWorkFlowInterviewID(0);
                            setShowSocialWorkFlowState("none");
                            setShowSocialWorkFlowSubState("options");
                            setShowSocialWorkFlowStep(1);
                            setShowSocialWorkFlow(false);        
                        }}>X</button>
                    </div>
                    <div className="content">
                        {
                            (showSocialFlowState === "step-1" && showSocialFlowSubState === "options") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Social Post Video</span>
                                </label>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialWorkFlowState("step-1");
                                    setShowSocialWorkFlowSubState("upload");
                                    setShowSocialWorkFlowStep(1);
                                }}>Upload</button>
                                {/*<button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialWorkFlowState("step-1");
                                    setShowSocialWorkFlowSubState("record");
                                    setShowSocialWorkFlowStep(1);
                                    setTimeout(()=>{
                                        if(!showSocialVideoWorkFlowRecorderRef) return;        
                                        if(!showSocialVideoWorkFlowRecorderRef.current) return;                        
                                        showSocialVideoWorkFlowRecorderRef.current.resetUI();
                                        showSocialVideoWorkFlowRecorderRef.current.openVideo();
                                    }, 1500);
                                }}>Record</button>*/}
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialWorkFlowState("step-1");
                                    setShowSocialWorkFlowSubState("record-2");
                                    setShowSocialWorkFlowStep(1);
                                }}>Record</button>
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-1" && showSocialFlowSubState === "upload") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation">
                                    <span>Social Post Video - Upload</span>
                                    <VideoUploadComponent
                                        onreject={()=>{
                                            setShowSocialWorkFlowState("step-1");
                                            setShowSocialWorkFlowSubState("options");
                                            setShowSocialWorkFlowStep(2);
                                        }} onclose={()=>{  
                                            setShowSocialWorkFlowState("step-1");
                                            setShowSocialWorkFlowSubState("options");
                                            setShowSocialWorkFlowStep(2);
                                        }} onaccept={ async (video_url:string)=>{
                                            toast.info("Updating");
                                            toast.info("Updating, Please Wait");
                                            setShowLoading2(true);
                                            let result = await API.interview.setSocialMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 3);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            setShowSocialWorkFlowState("step-2");
                                            setShowSocialWorkFlowSubState("");
                                            setShowSocialWorkFlowStep(2);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setSocialMarketingVideo(apiKey, interview.id, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(false);
                                        }} watermark_url={""} 
                                    />
                                </label>                                
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-1" && showSocialFlowSubState === "record") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>Social Post Video - Record</span>
                                    {/*<VideoRecordComponent
                                        ref={showSocialVideoWorkFlowRecorderRef}
                                        nopreview={false}
                                        onnopreview={async () => {                                              
                                        }}
                                        watermark_url={user.watermark_url ?? ""}
                                        onclose={() => {    
                                            setShowSocialWorkFlowState("step-1");
                                            setShowSocialWorkFlowSubState("options");
                                            setShowSocialWorkFlowStep(2); 
                                        }}
                                        oncompletion={async (video_url:string)=>{
                                            toast.info("Updating");
                                            setShowLoading2(true);
                                            let result = await API.interview.setSocialMarketingVideo(apiKey, showSocialFlowInterviewID, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, showSocialFlowInterviewID, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            if(showSocialVideoWorkFlowRecorderRef){
                                                if(showSocialVideoWorkFlowRecorderRef.current){
                                                    try{showSocialVideoWorkFlowRecorderRef.current.closeDialog();}catch(e){}
                                                }
                                            }
                                            setShowSocialWorkFlowState("step-2");
                                            setShowSocialWorkFlowSubState("");
                                            setShowSocialWorkFlowStep(2);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setSocialMarketingVideo(apiKey, showSocialFlowInterviewID, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, showSocialFlowInterviewID, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(true);
                                        }}  
                                        fixWidth={"510px"}   
                                    />*/}
                                    <InBrowserRecorderAndProcessingComponent
                                        apiKey={apiKey}
                                        onClose={()=>{
                                            console.log("COMPONENT CLOSED");
                                            setShowSocialWorkFlowState("step-1");
                                            setShowSocialWorkFlowSubState("options");
                                            setShowSocialWorkFlowStep(2); 
                                        }} onCompletion={async (video_url:string)=>{
                                            console.log(video_url);
                                            toast.info("Updating");
                                            setShowLoading2(true);
                                            let result = await API.interview.setSocialMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            setShowSocialWorkFlowState("step-2");
                                            setShowSocialWorkFlowSubState("");
                                            setShowSocialWorkFlowStep(2);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setSocialMarketingVideo(apiKey, interview.id, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(false); 
                                        }}
                                    />
                                </label>                                
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-1" && showSocialFlowSubState === "record-2") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>Social Post Video - Record</span>
                                    <InBrowserRecorderAndProcessingComponent
                                        apiKey={localStorage.getItem("api_key") ?? ""}
                                        onClose={()=>{
                                            console.log("COMPONENT CLOSED");
                                            setShowSocialWorkFlowState("step-1");
                                            setShowSocialWorkFlowSubState("options");
                                            setShowSocialWorkFlowStep(2); 
                                        }} onCompletion={async (video_url:string)=>{
                                            console.log(video_url);
                                            toast.info("Updating");
                                            setShowLoading2(true);
                                            let result = await API.interview.setSocialMarketingVideo(apiKey, interview.id, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            setShowSocialWorkFlowState("step-2");
                                            setShowSocialWorkFlowSubState("");
                                            setShowSocialWorkFlowStep(2);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setSocialMarketingVideo(apiKey, interview.id, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, interview.id, 2);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            if(showDirectSubtitlePopUpFunc.current) await showDirectSubtitlePopUpFunc.current(video_url);
                                            setShowAskDirectSubtitlePopUp(false);
                                        }}
                                    />
                                </label>                                
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-2") && <div className='nice-form-2'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <label htmlFor="outReach-campaign-caption-text">
                                        <span>OutReach Campaign Caption Text</span>
                                    </label>
                                    <textarea
                                        id="outReach-campaign-caption-text"
                                        name="outReach-campaign-caption-text"
                                        style={{ width: "100%", height: "123px", marginTop:'12px'}}
                                        onChange={async (e:any)=>{
                                        let type_value = e.target.value;
                                        let result = await API.interview.setCustomThumbnailOutreachType(
                                            apiKey,
                                            interview.id,
                                            type_value
                                        );
                                        }}
                                        defaultValue={interview?.bulk_marketing_caption_text}
                                    >
                                    </textarea>
                                </label>      
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialWorkFlowState("step-3");
                                    setShowSocialWorkFlowSubState("");
                                    setShowSocialWorkFlowStep(3);
                                }}>Next</button>                            
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-3") && <div className='nice-form-2'>
                                <label htmlFor="outReach-campaign-custom-gif-text">
                                    <span>Social Post Title</span>
                                </label>
                                <input
                                    style={{ margin:'0px', width:'calc(100% - 32px)' }}
                                    defaultValue={interview.social_caption}
                                    onChange={async (e:any)=>{
                                        let result = await API.interview.setSocialMarketingSocial(
                                            apiKey,
                                            interview.id,
                                            e.target.value
                                        );
                                    }}
                                    placeholder="Title"
                                />         
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialWorkFlowState("step-4");
                                    setShowSocialWorkFlowSubState("");
                                    setShowSocialWorkFlowStep(4);
                                }}>Next</button>                   
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-4") && <div className='nice-form-2'>
                                <label htmlFor="outReach-campaign-custom-gif-text">
                                    <span>Social Post Text</span>
                                </label>
                                <textarea
                                    className="advertisemarketingText"
                                    style={{ margin:'0px', width:'calc(100% - 36px)' }}
                                    defaultValue={interview.advertise_marketing_text}
                                    onChange={async (e:any)=>{
                                        let result = await API.interview.setSocialMarketingSocial(
                                            apiKey,
                                            interview.id,
                                            e.target.value
                                        );
                                    }}
                                ></textarea>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialWorkFlowState("step-5");
                                    setShowSocialWorkFlowSubState("");
                                    setShowSocialWorkFlowStep(5);
                                }}>Next</button>                   
                            </div>
                        }
                        {
                            (showSocialFlowState === "step-5") && <div className='nice-form-2'>
                                <label htmlFor="outReach-campaign-custom-gif-text">
                                    <span>Receive Candidates Into:</span>
                                </label>
                                <select
                                    style={{ margin:'0px', width:'calc(100% + 12px)' }}
                                    defaultValue={interview.advertise_candidate_load}
                                    onChange={async (e) => {
                                        let value = parseInt(e.target.value);
                                        let result = await API.interview.AdvertiseCandidateLoad(
                                            apiKey,
                                            interview.id,
                                            value
                                        );
                                    }}
                                >
                                    <option value="0">CRM/ATS</option>
                                    <option value="1">Interview (self signup)</option>
                                </select>
                                <label htmlFor="outReach-campaign-custom-gif-text">
                                    <span>User Redirection Link:</span>
                                </label>
                                <div style={{ display: "inline-block", fontSize: "8pt" }}>
                                    Example URL{" "}
                                    <strong>
                                        <span style={{ backgroundColor: "yellow" }}>
                                        https://
                                        </span>
                                        www.example.com/
                                    </strong>
                                </div>
                                <input
                                    style={{ margin:'0px', width:'calc(100% - 16px)' }}
                                    type="text"
                                    placeholder="https://www.example.com/"
                                    onChange={async (e:any)=>{
                                        let result = await API.interview.updateRedirectionLink(
                                            apiKey,
                                            interview.id,
                                            e.target.value
                                        );
                                    }}
                                    defaultValue={interview.advertise_marketing_redirect_url}
                                />
                                <span style={{ fontSize: "8pt", color: "#B5B5C3" }}>
                                    We will redirect the candidate to this url
                            </span>
                                <button className="button-primary-blue blocked full" onClick={ async e => {                    
                                    setShowSocialWorkFlowInterviewID(0);
                                    setShowSocialWorkFlowState("none");
                                    setShowSocialWorkFlowSubState("options");
                                    setShowSocialWorkFlowStep(1);
                                    setShowSocialWorkFlow(false); 
                                    if(!showSocialWorkFlowCallback){
                                        toast.error("Missing Callback");
                                        return;
                                    } 
                                    await showSocialWorkFlowCallback({success:true});
                                    setShowSocialWorkFlowCallback(null);
                                }}>Done</button>                   
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        
        { showSocialVideo && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Social Post Video</h4>
                        <button onClick={ e => {          
                            setShowSocialVideoState("");
                            setShowSocialVideoSubState("");
                            setShowSocialVideoInterviewID(0);
                            setShowSocialVideo(false); 
                        }}>X</button>
                    </div>
                    <div className="content">
                    {
                            (showSocialVideoState === "step-1" && showSocialVideoSubState === "options") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>Add a Social Post Video</span>
                                </label>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialVideoSubState("upload");
                                    setShowSocialVideoState("step-2");
                                }}>Upload</button>
                                <button className="button-primary-blue blocked full" onClick={ e => {
                                    setShowSocialVideoSubState("record");
                                    setShowSocialVideoState("step-2");
                                    setTimeout(()=>{
                                        if(!showSocialVideoRecorderRef) return;        
                                        if(!showSocialVideoRecorderRef.current) return;                        
                                        showSocialVideoRecorderRef.current.resetUI();
                                        showSocialVideoRecorderRef.current.openVideo();
                                    }, 1500);
                                }}>Record</button>
                            </div>
                        }
                        {
                            (showSocialVideoState === "step-2" && showSocialVideoSubState === "upload") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>Social Post Video</span>
                                </label>
                                <VideoUploadComponent onreject={()=>{
                                    setShowSocialVideoSubState("options");
                                    setShowSocialVideoState("step-1");
                                }} onclose={()=>{  
                                    setShowSocialVideoSubState("options");
                                    setShowSocialVideoState("step-1");
                                }} onaccept={ async (video_url:string)=>{
                                    toast.info("Updating, please wait");
                                    setShowLoading2(true);
                                    let result = await API.interview.setSocialMarketingVideo(apiKey, showSocialVideoInterviewID, video_url);
                                    let result2 = await API.interview.markVideo(0, 0, showSocialVideoInterviewID, 3);
                                    toast.info("Updated");
                                    setShowLoading2(false);
                                    if(!showSocialVideoCallback){
                                        toast.error("Missing Callback");
                                        return;
                                    }
                                    showSocialVideoCallback({ success: true});                                    
                                    setShowSocialVideoState("");
                                    setShowSocialVideoSubState("");
                                    setShowSocialVideoInterviewID(0);
                                    setShowSocialVideo(false); 
                                    // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO
                                    setShowDirectSubtitlePopUpURL(video_url);
                                    const callback = (new_video_url: string) => {
                                        return new Promise<boolean>(async (resolve, reject) => {
                                            try {
                                                setShowLoading2(true);
                                                const result1 = await API.interview.setSocialMarketingVideo(apiKey, interview.id, new_video_url);
                                                const result2 = await API.interview.markVideo(0, 0, interview.id, 3);
                                                toast.info("Updated");
                                                setShowLoading2(false);
                                                resolve(true); // Resolve the promise after all operations are completed
                                            } catch (error) {
                                                setShowLoading2(false);
                                                reject(error); // Reject the promise if any error occurs
                                            }
                                        });
                                    };
                                    showDirectSubtitlePopUpFunc.current = callback;
                                    setShowAskDirectSubtitlePopUp(false);
                                }} watermark_url={""}                           
                                />                            
                            </div>
                        }
                        {
                            (showSocialVideoState === "step-2" && showSocialVideoSubState === "record") && <div className='nice-form'>
                                <label htmlFor="allow-candidate-participation" style={{ textAlign:'center' }}>
                                    <span style={{ textAlign :'center' }}>Social Post Video</span>
                                    {/*<VideoRecordComponent
                                        ref={showSocialVideoRecorderRef}
                                        nopreview={false}
                                        onnopreview={async () => {                                              
                                        }}
                                        watermark_url={user.watermark_url ?? ""}
                                        onclose={() => {    
                                            setShowSocialVideoSubState("options");
                                            setShowSocialVideoState("step-1");  
                                        }}
                                        oncompletion={async (video_url:string)=>{
                                            toast.info("Updating, please wait");
                                            setShowLoading2(true);
                                            let result = await API.interview.setSocialMarketingVideo(apiKey, showSocialVideoInterviewID, video_url);
                                            let result2 = await API.interview.markVideo(0, 0, showSocialVideoInterviewID, 3);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            if(!showSocialVideoCallback){
                                                toast.error("Missing Callback");
                                                return;
                                            }
                                            showSocialVideoCallback({ success: true});                                    
                                            setShowSocialVideoState("");
                                            setShowSocialVideoSubState("");
                                            setShowSocialVideoInterviewID(0);
                                            if(showSocialVideoRecorderRef){
                                                if(showSocialVideoRecorderRef.current){
                                                    try{showSocialVideoRecorderRef.current.closeDialog();}catch(e){}
                                                }
                                            }
                                            setShowSocialVideo(false);
                                            // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO 
                                            setShowDirectSubtitlePopUpURL(video_url);
                                            const callback = (new_video_url: string) => {
                                                return new Promise<boolean>(async (resolve, reject) => {
                                                    try {
                                                        setShowLoading2(true);
                                                        const result1 = await API.interview.setSocialMarketingVideo(apiKey, showSocialFlowInterviewID, new_video_url);
                                                        const result2 = await API.interview.markVideo(0, 0, showSocialFlowInterviewID, 3);
                                                        toast.info("Updated");
                                                        setShowLoading2(false);
                                                        resolve(true); // Resolve the promise after all operations are completed
                                                    } catch (error) {
                                                        setShowLoading2(false);
                                                        reject(error); // Reject the promise if any error occurs
                                                    }
                                                });
                                            };
                                            showDirectSubtitlePopUpFunc.current = callback;
                                            setShowAskDirectSubtitlePopUp(true);
                                        }}  
                                        fixWidth={"510px"}   
                                        />*/}
                                        <InBrowserRecorderAndProcessingComponent
                            apiKey={apiKey}
                            onClose={()=>{
                                console.log("COMPONENT CLOSED");
                                setShowSocialVideoSubState("options");
                                setShowSocialVideoState("step-1"); 
                            }} onCompletion={async (video_url:string)=>{
                                console.log(video_url);
                                toast.info("Updating, please wait");
                                setShowLoading2(true);
                                let result = await API.interview.setSocialMarketingVideo(apiKey, showSocialVideoInterviewID, video_url);
                                let result2 = await API.interview.markVideo(0, 0, showSocialVideoInterviewID, 3);
                                toast.info("Updated");
                                setShowLoading2(false);
                                if(!showSocialVideoCallback){
                                    toast.error("Missing Callback");
                                    return;
                                }
                                showSocialVideoCallback({ success: true});                                    
                                setShowSocialVideoState("");
                                setShowSocialVideoSubState("");
                                setShowSocialVideoInterviewID(0);
                                if(showSocialVideoRecorderRef){
                                    if(showSocialVideoRecorderRef.current){
                                        try{showSocialVideoRecorderRef.current.closeDialog();}catch(e){}
                                    }
                                }
                                setShowSocialVideo(false);
                                // TODO: ASK IF THE USER WANTS TO ADD SUBTITLES TO THIS VIDEO 
                                setShowDirectSubtitlePopUpURL(video_url);
                                const callback = (new_video_url: string) => {
                                    return new Promise<boolean>(async (resolve, reject) => {
                                        try {
                                            setShowLoading2(true);
                                            const result1 = await API.interview.setSocialMarketingVideo(apiKey, interview.id, new_video_url);
                                            const result2 = await API.interview.markVideo(0, 0, interview.id, 3);
                                            toast.info("Updated");
                                            setShowLoading2(false);
                                            resolve(true); // Resolve the promise after all operations are completed
                                        } catch (error) {
                                            setShowLoading2(false);
                                            reject(error); // Reject the promise if any error occurs
                                        }
                                    });
                                };
                                showDirectSubtitlePopUpFunc.current = callback;
                                if(showDirectSubtitlePopUpFunc.current) await showDirectSubtitlePopUpFunc.current(video_url);
                                setShowAskDirectSubtitlePopUp(false);
                            }}
                        />
                                </label>                    
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
        
        { showLink && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Link</h4>
                        <button onClick={ e => {          
                            setShowLinkData("");
                            setShowLink(false); 
                        }}>X</button>
                    </div>
                    <div className="content">
                        <textarea                        
                            className="advertisemarketingText"
                            style={{ margin:'0px', width:'calc(100% - 36px)' }}
                            value={showLinkData}
                            readOnly={true}
                        ></textarea>
                        <button className="button-primary-blue blocked full" onClick={ e => {        
                            setShowLinkData("");
                            setShowLink(false); 
                        }}>Close</button>
                    </div>
                </div>
            </div>
        }
        { showAskDirectSubtitlePopUp && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Add Subtitles?</h4>
                        <button onClick={ e => {          
                            setShowAskDirectSubtitlePopUp(false);
                        }}>X</button>
                    </div>
                    <div className="content">
                        <button className="button-primary-blue blocked full" onClick={ e => {                                
                            setShowAskDirectSubtitlePopUp(false);
                            setShowDirectSubtitlePopUp(true);
                        }}>Yes</button>
                        <button className="button-primary-blue blocked full" onClick={ e => {                         
                            setShowAskDirectSubtitlePopUp(false);       
                        }}>No</button>
                    </div>
                </div>
            </div>
        }
        { showFacebookShare && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Facebook Share</h4>
                        <button onClick={ e => {          
                            setShowFacebookSharePages([])
                            setShowFacebookSelectedPages([]);
                            setShowFacebookShareCallback(null);
                            setShowFacebookShare(false); 
                        }}>X</button>
                    </div>
                    <div className="content">
                        <p>Select which pages you would like to post to</p>
                        <ul>
                            {
                                showFacebookSharePages.map((sharePage:any, index:number)=>{
                                    let isInSelectedArray = showFacebookSelectedPages.some((x: { id: number }) => x.id === sharePage.id);
                                    return <li key={sharePage.id}>
                                        <label className="page_item" key={"page_id_" + sharePage.id}>
                                        <input type="checkbox" defaultChecked={isInSelectedArray} onChange={ e => {
                                            let isChecked = e.target.checked;
                                            setShowFacebookSelectedPages( x => {
                                                let newX = [...x];
                                                if(isChecked){
                                                    newX.push(sharePage);
                                                } else {
                                                    newX = newX.filter((x)=>{return x.id !== sharePage.id});
                                                }
                                                return newX;
                                            });
                                        }}/>
                                            <span>{sharePage.name}</span>
                                        </label>
                                    </li>;
                                })
                            }
                        </ul>
                        <button className="button-primary-blue blocked full" onClick={ async e => {
                            if(!showFacebookShareCallback){
                                toast.error("Missing Callback");
                                return;
                            }
                            if(showFacebookSelectedPages.length <= 0){
                                toast.error("You need to select at least one page to share to");
                                return;
                            }
                            await showFacebookShareCallback(showFacebookSelectedPages);                             
                            setShowFacebookSharePages([])
                            setShowFacebookSelectedPages([]);
                            setShowFacebookShareCallback(null);
                            setShowFacebookShare(false); 
                        }}>Post</button>
                    </div>
                </div>
            </div>
        }
        { showLinkedInVideoShare && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Sharing Video To Linked In</h4>
                        <button onClick={ async e => {          
                            setShowLinkedInVideoShare(false); 
                        }}>X</button>
                    </div>
                    <div className="content"> 
                        <InBrowserLinkedInVideoProcessor
                            text={interview.advertise_marketing_text}
                            apiKey={apiKey}
                            videoUrl={showLinkedInVideoShareVideoURL}
                            USER_ACCESS_TOKEN={showLinkedInVideoShareUserAccessToken}
                            USER_URN_ID={showLinkedInVideoShareUserID}
                            onCompletion={ async success => {
                                setShowLinkedInVideoShare(false); 
                            }}
                        />
                    </div>
                </div>
            </div>
        }
        { showLoading && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Loading, Please wait</h4>
                    </div>
                    <div className="content">                        
                        <p>This may take some time to complete</p>
                    </div>
                </div>
            </div>
        }
        { showOpenRates && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>New Opens</h4>
                        <button onClick={ async e => {          
                            toast.info("Updating");
                            let result = await API.marketing.campaign.addSetOpensVacancy(apiKey, interviewID);
                            setShowOpenRates(false); 
                        }}>X</button>
                    </div>
                    <div className="content">                        
                        <p>You have the following new opens</p>
                        <div>
                            <ul style={{textAlign:'left', listStyle:'none'}}>
                                <li>Interview: { interview?.openDiff?.interview }</li>
                                <li>Bespoke: { interview?.openDiff?.bespoke }</li>
                                <li>Campaign: { interview?.openDiff?.campaign }</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        }
        { showStorageLow && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Your are running out of storage space</h4>
                        <button onClick={ e => {          
                            setShowStorageLow(false); 
                        }}>X</button>
                    </div>
                    <div className="content">                        
                        <p>You have a less than 1GB of storage left</p>
                    </div>
                </div>
            </div>
        }
        { showStorageNone && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Your are running out of storage space</h4>
                        <button onClick={ e => {          
                            setShowStorageNone(false); 
                        }}>X</button>
                    </div>
                    <div className="content">                        
                        <p>You have run out of storage space</p>
                    </div>
                </div>
            </div>
        }
        { showDirectSubtitlePopUp && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Add Subtitles</h4>
                        <button onClick={ async e => {       
                            setShowDirectSubtitlePopUp(false);
                        }}>X</button>
                    </div>
                    <div className="content">                        
                        <STTURLComponent
                        videoURL={showDirectSubtitlePopUpURL}
                        onCompletion={async (video_url:string)=>{
                            console.log("video_url", video_url);
                            if(!showDirectSubtitlePopUpFunc){
                                toast.error("missing function");
                                return;
                            }
                            if(!showDirectSubtitlePopUpFunc.current){
                                toast.error("missing function");
                                return;
                            }
                            const result = await showDirectSubtitlePopUpFunc.current(video_url);
                            toast.info("Video Has Subtitles now");
                            setShowDirectSubtitlePopUp(false);
                        }}
                        onReject={()=>{
                            setShowDirectSubtitlePopUp(false);
                        }}
                        />
                    </div>
                </div>
            </div>
        }
        { showLoading2 && <div className="custom-popup-dialog">
                <div style={{ width: "720px" }}>
                    <div className="header">
                        <h4>Loading, Please wait</h4>
                    </div>
                    <div className="content">                        
                        <p>This may take some time to complete</p>
                    </div>
                </div>
            </div>
        }
    </>
}