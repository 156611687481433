import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import './marketing-folder-component.css';
import BespokeFolder from "./marketing-folders/bespokeFolder";
import CampaignFolder from "./marketing-folders/campaignFolder";
import SocialFolder from "./marketing-folders/socialFolder";

interface MarketingFolderComponentProps {
    apiKey:string;
    user:any;
    defaultFolder: string;
    marketing:any;
    createLeadCallback: (callback: (data:any) => Promise<null | {
        closed:boolean;
        copied:number;
        created_at: null | string | Date
        email: null | string
        custom_video_url: string | null;
        hasViewed:boolean;
        viewTime:string;
        id: number
        marketing_id:  null | number
        linkedin_url: null | string
        marketing_type: null | number
        marketingType_id: null | number;
        marketing_thumbnail: null | string;
        name: null | string;
        phone: null | string;
        processing_gif: null | number;
        processing_video: null | number; 
        processing_done: null | number;
        updated_at: null | string | Date;
    }>) => void;
    executeCreateLead: () => void;
    executeSetLeadCustomVideo: (lead_id:number, lead_name:string) => void;
    previewLeadCustomVideo: (video_url:string) => void;
    reloadBespokeFolder: (callback: () => Promise<void>) => void;
    reloadCampaignFolder: (callback: () => Promise<void>) => void; 
    onShowSendEmail: (subject:string, to:string, body:string) => void;    
    reloadMarketing: () => void;
    executeBespokeFlow: () => void;
    executeCampaignFlow: () => void;
    executeSocialFlow: () => void;
    executeHandleMarketingVideoOptions: () => void;
    executeHandleSocialVideoOptions: () => void;
    handleFacebookPageSubmission: (callback: (pages_data:any[]) => Promise<boolean>) => void;
    executeHandleFacebookPageSubmission: (pages_data:any[]) => void;      
    executeShowLinkedInShare: (video_url:string, access_token:string, user_id:string) => void;
    handleLinkedInShareCallback: (callback: () => Promise<void>) => void;
}

/* Folders: {Bespoke Marketing:{Thumbnail Text, Marketing Text, Website URL, Lead List}, Campaign Marketing:{Video, Caption, Gif Text, Lead List}, Social Vlog:{Video, Caption (FB Only), Text}} */

export default function MarketingFolderComponent(props:MarketingFolderComponentProps){
     // Hooks
     const history = useHistory();
     // States
     const [ currentFolder, setCurrentFolder ] = useState<string>("");
     // Functions
     // Effects
     useEffect(()=>{
       if(!props.defaultFolder) return;
       setCurrentFolder(props.defaultFolder);
     }, [props.defaultFolder]);
     useEffect(()=>{
         if(!currentFolder) return;
         history.replace(`#${currentFolder}`);
     }, [currentFolder]);
    return <div className="folder-root-container2">
        <div className="folder-tabs2">
            <button className={currentFolder === "bespoke" ? "active" : ""} onClick={ e => setCurrentFolder("bespoke")}>
            <svg width="200" height="32">
                <polygon
                points="32,0 5,32 200,32, 168, 0"
                />
                <text x="38" y="20" fill="#FFF">
                Bespoke Marketing
                </text>
            </svg>
            </button>
            <button className={currentFolder === "campaign" ? "active" : ""} onClick={ e => setCurrentFolder("campaign")}>
                <svg width="220" height="32">
                <polygon
                points="32,0 5,32 220,32, 188, 0"
                />
                <text x="36" y="20" fill="#FFF">
                Campaign Marketing
                </text>
            </svg>
            </button>
            <button className={currentFolder === "social" ? "active" : ""} onClick={ e => setCurrentFolder("social")}>
                <svg width="200" height="32">
                    <polygon
                    points="32,0 5,32 200,32, 168, 0"
                    />
                    <text x="64" y="20" fill="#FFF">
                    Social Vlog
                    </text>
                </svg>
            </button>
        </div>
        <div className="folder-container">
            {
                currentFolder === "bespoke" && <BespokeFolder apiKey={props.apiKey} marketing={props.marketing} createLeadCallback={ (callback: (data:any) => Promise<null | {
                    closed:boolean;
                    copied:number;
                    created_at: null | string | Date
                    email: null | string
                    custom_video_url: string | null;
                    hasViewed:boolean;
                    viewTime:string;
                    id: number
                    marketing_id:  null | number
                    linkedin_url: null | string
                    marketing_type: null | number
                    marketingType_id: null | number;
                    marketing_thumbnail: null | string;
                    name: null | string;
                    phone: null | string;
                    processing_gif: null | number;
                    processing_video: null | number; 
                    processing_done: null | number;
                    updated_at: null | string | Date;
                }>) => {
                    props.createLeadCallback(callback);
                }}
                executeCreateLead={ () => {
                    props.executeCreateLead();
                }}
                executeSetLeadCustomVideo={ (lead_id:number, lead_name:string) => {
                    props.executeSetLeadCustomVideo(lead_id, lead_name);
                }}
                previewLeadCustomVideo={ (video_url:string) => {
                    props.previewLeadCustomVideo(video_url);
                }}
                reloadBespokeFolder={ (callback: () => Promise<void>) => {
                    props.reloadBespokeFolder(callback);
                }}
                onShowSendEmail={props.onShowSendEmail}
                reloadMarketing={props.reloadMarketing}
                executeBespokeFlow={props.executeBespokeFlow}
                />
            }
            {
                currentFolder === "campaign" && <CampaignFolder
                    apiKey={props.apiKey}
                    marketing={props.marketing}
                    createLeadCallback={ (callback: (data:any) => Promise<null | {
                        closed:boolean;
                        copied:number;
                        created_at: null | string | Date
                        email: null | string
                        custom_video_url: string | null;
                        hasViewed:boolean;
                        viewTime:string;
                        id: number
                        marketing_id:  null | number
                        linkedin_url: null | string
                        marketing_type: null | number
                        marketingType_id: null | number;
                        marketing_thumbnail: null | string;
                        name: null | string;
                        phone: null | string;
                        processing_gif: null | number;
                        processing_video: null | number; 
                        processing_done: null | number;
                        updated_at: null | string | Date;
                    }>) => {
                        props.createLeadCallback(callback);
                    }}
                    onShowSendEmail={props.onShowSendEmail}
                    reloadCampaignFolder={props.reloadCampaignFolder}
                    reloadMarketing={props.reloadMarketing}
                    executeCampaignFlow={props.executeCampaignFlow}
                    executeCreateLead={ () => {
                        props.executeCreateLead();
                    }}                    
                    executeHandleMarketingVideoOptions={props.executeHandleMarketingVideoOptions}
                />
            }
            {
                currentFolder === "social" && <SocialFolder
                    userInfo={props.user}
                    apiKey={props.apiKey}
                    marketing={props.marketing}
                    handleFacebookPageSubmission={props.handleFacebookPageSubmission}
                    executeHandleFacebookPageSubmission={props.executeHandleFacebookPageSubmission}
                    executeSocialFlow={props.executeSocialFlow}
                    executeHandleSocialVideoOptions={props.executeHandleSocialVideoOptions}
                    executeShowLinkedInShare={props.executeShowLinkedInShare}
                    handleLinkedInShareCallback={props.handleLinkedInShareCallback}
                />
            }
        </div>
    </div>
}