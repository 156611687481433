import React, { useState, useEffect } from "react";

import API from '../services/api';

import { toast } from 'react-toastify';

import { AiOutlineLoading } from 'react-icons/ai';

import { Tooltip as ReactTooltip } from "react-tooltip";

import "./automanage.css";

export default function AutoManageComponent(){
    const [loading, setLoading] = useState(false);
    
    const [vacancyManageDialogVisible, setManageDialogVisible] = useState(false);

    const [vacancyDialogVisible, setVacancyDialogVisible] = useState(false);
    
    const [vacancyTimeDialogVisible, setVacancyTimeDialogVisible] = useState(false);
    const [vacancyManageTime, setVacancyManageTime] = useState(1);
    const [vacancyManageTimeInterview, setVacancyManageTimeInterview] = useState(false);
    const [vacancyManageTimeBespoke, setVacancyManageTimeBespoke] = useState(false);
    const [vacancyManageTimeCampaign, setVacancyManageTimeCampaign] = useState(false);

    const [vacancyRatingDialogVisible, setVacancyRatingDialogVisible] = useState(false);
    const [vacancyRatingStars, setVacancyRatingStars] = useState(4);
    
    const [vacancyInvitationDialogVisible, setVacancyInvitationDialogVisible] = useState(false);
    const [vacancyInvitationTime, setVacancyInvitationTime] = useState(1);

    const [marketingDialogVisible, setMarketingDialogVisible] = useState(false);
    const [marketingTimeDialogVisible, setMarketingTimeDialogVisible] = useState(false);
    const [marketingTime, setMarketingTime] = useState(1);

    return (
        <>
            <div className="automanageContainer">
                <h1>Auto Manage</h1>
                <h4>This allows you to manage your storage for candidate interview data such as video responses.</h4>
                <button onClick={()=>setManageDialogVisible(true)}>Manage</button> 
                { vacancyManageDialogVisible && <div className="dialogNew">
                    <div>
                        <h4>Which section would you like to manage</h4>
                        <div>
                            <button onClick={()=>{
                                setManageDialogVisible(false);
                                setVacancyDialogVisible(true);
                            }}>Vacancies</button>
                            <button onClick={()=>{
                                setManageDialogVisible(false);
                                setMarketingDialogVisible(true);
                            }}>Marketing</button>
                            <button onClick={()=>setManageDialogVisible(false)}>Close</button>
                        </div>
                    </div>
                </div> }
            </div>
            {
                vacancyDialogVisible && <div className="dialogNew">        
                <div>
                    <h4>Select a way to manage your vacancies</h4>
                    <div>
                        <button onClick={()=>{
                                setVacancyDialogVisible(false);
                                setVacancyTimeDialogVisible(true);
                        }}>By Time</button>
                        <button onClick={()=>{
                                setVacancyDialogVisible(false);
                                setVacancyRatingDialogVisible(true);
                        }}>By Rating</button>
                        <button onClick={()=>{
                                setVacancyDialogVisible(false);
                                setVacancyInvitationDialogVisible(true);
                        }}>By Invitation</button>
                        <button onClick={()=>{
                                setManageDialogVisible(true);
                                setVacancyDialogVisible(false);
                        }}>Back</button>
                    </div>
                </div>
            </div>       
            }
            {
                vacancyTimeDialogVisible && <div className="dialogNew">        
                    <div>
                        <h4>Manage Vacancy By Time</h4>
                        <div>
                            <span>Select which folders you would like candidates removed from (can select all)</span>
                            <span>
                                <ul style={{listStyle:'none', transform:"translateX(-24px)"}}>
                                    <li><input onChange={e => setVacancyManageTimeInterview(e.target.checked)} checked={vacancyManageTimeInterview} type="checkbox" style={{transform:'scale(1.5)', marginRight:'8px'}} />Interview</li>
                                    <li><input onChange={e => setVacancyManageTimeBespoke(e.target.checked)} checked={vacancyManageTimeBespoke} type="checkbox" style={{transform:'scale(1.5)', marginRight:'8px'}} />Bespoke</li>
                                    <li><input onChange={e => setVacancyManageTimeCampaign(e.target.checked)} checked={vacancyManageTimeCampaign} type="checkbox" style={{transform:'scale(1.5)', marginRight:'8px'}} />Campaign</li>
                                </ul>
                            </span>
                            <span data-tip data-for="tooltip-vacancy-time-notice">Select a duration <strong style={{cursor:'pointer'}}>(hover for notice)</strong></span>
                            <ReactTooltip id='tooltip-vacancy-time-notice' aria-haspopup='true'>
                                <h4>Notice</h4>
                                <p>If newly received or recorded videos are younger than the selected delete date then the vacancy folder will not be deleted however the videos</p>
                                <p>that are older in the folder will be deleted.</p>
                                <p>If the videos and folder are all older then the delete date, then all videos and the folder will be deleted.</p>
                            </ReactTooltip>
                            <select style={{display:'block', width:'100%', padding:"12px"}} onChange={e => setVacancyManageTime(parseInt(e.target.value))} defaultValue={vacancyManageTime}>
                                <option value="8928">1 Year</option>
                                <option value="4464">6 Months</option>
                                <option value="2976">4 Months</option>
                                <option value="1488">2 Months</option>
                                <option value="774">1 Month</option>
                                <option value="504">3 Weeks</option>
                                <option value="336">2 Weeks</option>
                                <option value="168">1 Week</option>
                                <option value="48">2 Days</option>
                                <option value="24">1 Day</option>
                                <option value="12">12 Hours</option>
                                <option value="8">8 Hours</option>
                                <option value="4">5 Hours</option>
                                <option value="3">4 Hours</option>
                                <option value="2">2 Hours</option>
                                <option value="1">1 Hour</option>
                            </select>
                        </div>
                        <div>
                            <button onClick={ async ()=>{
                                setLoading(true);
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.automanage.vacancy.remove.byTime(api_key, vacancyManageTime, vacancyManageTimeInterview, vacancyManageTimeBespoke, vacancyManageTimeCampaign);
                                if(!result['success']){
                                    setLoading(false);
                                    toast.error(result['message']);
                                    return;
                                }
                                setLoading(false);
                                setVacancyTimeDialogVisible(false);
                                let items_removed = result['stats']['deletions']['vacancies'] + result['stats']['deletions']['questions'] + result['stats']['deletions']['candidates'];
                                toast("Successfully removed " + items_removed + " items");
                            }}>Submit</button>
                            <button onClick={()=>{
                                setVacancyTimeDialogVisible(false);
                                setVacancyDialogVisible(true);
                            }}>Back</button>
                        </div>
                    </div>
                </div>  
            }
            {
                vacancyRatingDialogVisible && <div className="dialogNew">        
                    <div style={{width:'350px'}}>
                        <h4>Manage Vacancy By Rating</h4>
                        <div>
                            <span data-tip data-for="tooltip-vacancy-rating-notice">Select a rating <strong>(hover for notice)</strong></span>
                            <ReactTooltip id='tooltip-vacancy-rating-notice' aria-haspopup='true'>
                                <h4>Notice</h4>
                                <p>Candidates with a rating of zero stars will not be removed</p>
                            </ReactTooltip>
                            <select style={{display:'block', width:'100%', padding:"12px"}} defaultValue={vacancyRatingStars} onChange={ e => setVacancyRatingStars(parseInt(e.target.value))}>
                                <option value="4">4 Stars or Less</option>
                                <option value="3">3 Stars or Less</option>
                                <option value="2">2 Stars or Less</option>
                                <option value="1">1 Star or Less</option>
                            </select>
                        </div>
                        <div>
                            <button onClick={async ()=>{
                                setLoading(true);
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.automanage.vacancy.remove.byRating(api_key, vacancyRatingStars);        
                                if(!result['success']){
                                    toast.error(result['message']);
                                    setLoading(false);
                                    return;
                                }
                                setLoading(false);
                                setVacancyRatingDialogVisible(false);
                                let items_removed = result['stats']['deletions']['candidates'];
                                toast("Successfully removed " + items_removed + " items");
                            }}>Submit</button>
                            <button onClick={()=>{
                                setVacancyRatingDialogVisible(false);
                                setVacancyDialogVisible(true);
                            }}>Back</button>
                        </div>
                    </div>
                </div>
            }
            {
                vacancyInvitationDialogVisible && <div className="dialogNew">      
                    <div style={{width:'350px'}}>
                        <h4>Manage Vacancy By Invitation</h4>
                        <div>
                            <span data-tip data-for="tooltip-vacancy-rating-notice">Select a duration</span>
                            <select style={{display:'block', width:'100%', padding:"12px"}} defaultValue={vacancyInvitationTime} onChange={ e => setVacancyInvitationTime(parseInt(e.target.value))}>
                                <option value="8928">1 Year</option>
                                <option value="4464">6 Months</option>
                                <option value="2976">4 Months</option>
                                <option value="1488">2 Months</option>
                                <option value="774">1 Month</option>
                                <option value="504">3 Weeks</option>
                                <option value="336">2 Weeks</option>
                                <option value="168">1 Week</option>
                                <option value="48">2 Days</option>
                                <option value="24">1 Day</option>
                                <option value="12">12 Hours</option>
                                <option value="8">8 Hours</option>
                                <option value="4">5 Hours</option>
                                <option value="3">4 Hours</option>
                                <option value="2">2 Hours</option>
                                <option value="1">1 Hour</option>
                            </select>
                        </div>
                        <div>
                            <button onClick={async ()=>{
                                setLoading(true);
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.automanage.vacancy.remove.byInvitation(api_key, vacancyInvitationTime);                                
                                if(!result['success']){
                                    toast.error(result['message']);
                                    setLoading(false);
                                    return;
                                }
                                setLoading(false);
                                setVacancyInvitationDialogVisible(false);
                                let items_removed = result['stats']['deletions']['sessions'] + result['stats']['deletions']['candidates'] + result['stats']['deletions']['answers'];
                                toast("Successfully removed " + items_removed + " items");
                            }}>Submit</button>
                            <button onClick={()=>{                                
                                setVacancyInvitationDialogVisible(false);
                                setVacancyDialogVisible(true);
                            }}>Back</button>
                        </div>
                    </div>
                </div>  
            }
            {
                marketingDialogVisible && <div className="dialogNew">        
                    <div>
                        <h4>Select a way to manage your Marketing Campaigns</h4>
                        <div>
                            <button onClick={()=>{
                                setMarketingDialogVisible(false);
                                setMarketingTimeDialogVisible(true);
                            }}>By Time</button>
                            <button onClick={()=>{
                                setMarketingDialogVisible(false);                                
                                setManageDialogVisible(true);
                            }}>Back</button>
                        </div>
                    </div>
                </div> 
            }
            {
                marketingTimeDialogVisible && <div className="dialogNew">        
                    <div>
                        <h4>Manage Marketing Campaigns By Time</h4>
                        <div>
                            <span data-tip data-for="tooltip-marketing-time-notice">Select a duration <strong style={{cursor:'pointer'}}>(hover for notice)</strong></span>
                            <ReactTooltip id='tooltip-marketing-time-notice' aria-haspopup='true'>
                                <h4>Notice</h4>                            
                                <p>TEXT_HERE</p>
                            </ReactTooltip>
                            <select style={{display:'block', width:'100%', padding:"12px"}} defaultValue={marketingTime} onChange={e => setMarketingTime(parseInt(e.target.value))}>
                                <option value="8928">1 Year</option>
                                <option value="4464">6 Months</option>
                                <option value="2976">4 Months</option>
                                <option value="1488">2 Months</option>
                                <option value="774">1 Month</option>
                                <option value="504">3 Weeks</option>
                                <option value="336">2 Weeks</option>
                                <option value="168">1 Week</option>
                                <option value="48">2 Days</option>
                                <option value="24">1 Day</option>
                                <option value="12">12 Hours</option>
                                <option value="8">8 Hours</option>
                                <option value="4">5 Hours</option>
                                <option value="3">4 Hours</option>
                                <option value="2">2 Hours</option>
                                <option value="1">1 Hour</option>
                            </select>
                        </div>
                        <div>
                            <button onClick={async ()=>{
                                setLoading(true);
                                let api_key = localStorage.getItem('api_key');
                                let result = await API.automanage.marketing.remove.byTime(api_key, marketingTime);                            
                                if(!result['success']){
                                    toast.error(result['message']);
                                    setLoading(false);
                                    return;
                                }
                                setLoading(false);
                                setMarketingTimeDialogVisible(false);
                                let items_removed = result['stats']['deletions']['campaigns'] + result['stats']['deletions']['leads'];
                                toast("Successfully removed " + items_removed + " items");
                            }}>Submit</button>
                            <button onClick={()=>{            
                                setMarketingTimeDialogVisible(false);
                                setMarketingDialogVisible(true);
                            }}>Back</button>
                        </div>
                    </div>
                </div>  
            }
            { 
                loading &&
                <div className="loader">                        
                    <div style={{lineHeight:'110px'}}><span style={{display:'block', fontSize:'11pt'}}>This will take a several minutes, please wait.</span> <AiOutlineLoading className="loading-spinner"/></div>
                </div>                
            }
        </>
    );
}