import React, { useState, useEffect, useRef } from "react";

import './FontSelector.css';

interface FontSelectorProps {
    isInline:boolean;
    selected:string;
    onChange: (fontName:string) => void;
}

export default function FontSelector(props:FontSelectorProps) {
    const [ selectedFontName, setSelectedFontName ] = useState<string>(props.selected ?? "");
    const [ fontNames, setFontNames ] = useState<string[]>([]);
    const currentOptionsRef = useRef<HTMLDivElement | null>(null);
    const selectionOptionsRef = useRef<HTMLDivElement | null>(null);
    const selectFont = (name:string) => {
        if(!props.onChange) return;
        setSelectedFontName(name);
        props.onChange(name);           
        if(!selectionOptionsRef) return;
        if(!selectionOptionsRef.current) return;
        selectionOptionsRef.current.style.display = "none";
    }
    const mouseClick = (e:any) => {
        if(!selectionOptionsRef) return;
        if(!selectionOptionsRef.current) return;
        selectionOptionsRef.current.style.display = "block";
        if(!currentOptionsRef) return;
        if(!currentOptionsRef.current) return;
        currentOptionsRef.current.style.borderBottomLeftRadius = "0px";
        currentOptionsRef.current.style.borderBottomRightRadius = "0px";
    }
    const mouseLeave = (e:any) => {            
        if(!selectionOptionsRef) return;
        if(!selectionOptionsRef.current) return;
        selectionOptionsRef.current.style.display = "none";
        if(!currentOptionsRef) return;
        if(!currentOptionsRef.current) return;
        currentOptionsRef.current.style.borderBottomLeftRadius = "8px";
        currentOptionsRef.current.style.borderBottomRightRadius = "8px";
    }
    useEffect(()=>{
        fetch("/fonts.txt")
       .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch fonts.txt');
            }
            return response.text();
        })
        .then(data => {
            const fontNamesArray = data.replace(/\r/g, "").split('\n').filter(name => name.trim() !== '');
            if(fontNamesArray.includes(props.selected)){
                setSelectedFontName(props.selected);
            } else {
                setSelectedFontName(fontNamesArray[0]);
            }
            setFontNames(fontNamesArray);
        })
        .catch(error => {
            console.error('Error fetching fonts.txt:', error);
        });
    }, []);
    if(fontNames === null || fontNames.length === 0){
        return <div>Loading</div>;
    }
    return <div className={!props.isInline ? "font-selector-component" : "font-selector-component inline"} onMouseLeave={mouseLeave}>
        <div className="current-font-selected" ref={currentOptionsRef} onClick={mouseClick} style={{fontFamily: selectedFontName}}>{ selectedFontName }</div>
        <div className="font-selection-options" ref={selectionOptionsRef} onMouseLeave={mouseLeave}>
            {
                fontNames.map((fontName:string, index:number)=>{
                    return <div style={{fontFamily: fontName}} onClick={ e => {
                        selectFont(fontName);
                    }}>{ fontName }</div>
                })
            }
        </div>
    </div>
}