import React, { createRef } from "react";
import { toast, ToastContainer } from "react-toastify";

import API from './../services/api';

import "./tutorial.css";

export default class TutorialComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          tutorials: []
      };

      this.page_number = props.page_number;
      this.items = [];
      this.itemIndex = 0;

      this.startTutorailBtnRef = createRef();
      this.tutorialTypeRef = createRef();
      this.tutorialItemsListRef = createRef();

      this.showTutorial = this.showTutorial.bind(this);
      this.closeTutorialTypeSelection = this.closeTutorialTypeSelection.bind(this);
      this.closeTutorialList = this.closeTutorialList.bind(this);
      this.loadTextTutorials = this.loadTextTutorials.bind(this);
      this.loadVideoTutorials = this.loadVideoTutorials.bind(this);
      this.openTutorial = this.openTutorial.bind(this);      

      
      this.hideAllItems = this.hideAllItems.bind(this);      
      this.loadItem = this.loadItem.bind(this);      
      this.nextItem = this.nextItem.bind(this);      
      this.previousItem = this.previousItem.bind(this);      
    }

    async componentDidMount(){
        let api_key = localStorage.getItem('api_key');
        if(!api_key){
            window.location.href = "/login";
            return;
        }
        
        this.startTutorailBtnRef.current.style.display = "block";
        this.tutorialTypeRef.current.style.display = "none";
        this.tutorialItemsListRef.current.style.display = "none";
    }
    
    async showTutorial(){
        // TODO: Load Tutorials From Database
        // Show Tutorail List
        // load text tutorials
        let api_key = localStorage.getItem('api_key');
        let result = await API.tutorial.getAllText(api_key, this.page_number);
        let success = result['success'];
        if(success){
            let tutorials = result['tutorials'];    
            this.setState({
                ...this.state,
                tutorials: tutorials
            });
        }
    }

    closeTutorialTypeSelection(){
        this.startTutorailBtnRef.current.style.display = "block";
        this.tutorialTypeRef.current.style.display = "none";
    }

    closeTutorialList(){
        this.tutorialTypeRef.current.style.display = "block";
        this.tutorialItemsListRef.current.style.display = "none";
    }

    async loadTextTutorials(){
        this.tutorialTypeRef.current.style.display = "none";
        this.tutorialItemsListRef.current.style.display = "block";
        
    }

    async loadVideoTutorials(){
        toast("Not implemented, Video tutorials being worked on.");
    }

    async openTutorial(e){
        this.startTutorailBtnRef.current.style.display = "block";
        this.tutorialTypeRef.current.style.display = "none";
        this.tutorialItemsListRef.current.style.display = "none";
        let tid = parseInt(e.target.getAttribute('tutorial-id'));
        let tindex = parseInt(e.target.getAttribute('tutorial-index'));
        let api_key = localStorage.getItem('api_key');
        let tutorial = await API.tutorial.getItems(api_key, tid);
        this.items = tutorial['items'];
        this.itemIndex = 0;
        this.loadItem();
    }

    hideAllItems(){
        // clear previous popups
        let popup = document.querySelector("#tut_popup");
        if(popup){
            popup.remove();
        }
    }

    loadItem(){
        this.hideAllItems();
        // load data
        let item = this.items[this.itemIndex];
        let elms = [];
        let element = "";
        let click = "";
        if(item.element_selector.indexOf(",") != -1){
            elms = item.element_selector.split(',');
            click = document.querySelector(elms[0]);
            element = document.querySelector(elms[1]);
        } else {
            element = document.querySelector(item.element_selector);
            click = element; 
        }               
        // if auto click
        // move to top to correct location
        if(item.auto_click != 0 && item.auto_click != null && item.auto_click != false){
            click.click();
        }
        // TODO: FIX POSITIONING
        let pos = element.getBoundingClientRect();
        let x = (pos.left + element.offsetWidth + 12) + "px";
        let y = (pos.top + window.scrollY) + "px";

        // create popup
        let popup = document.createElement("div");
        popup.id = 'tut_popup';
        popup.style.left = x;
        popup.style.top = y;
        let title = document.createElement("span");
        title.innerHTML = `Step ${this.itemIndex + 1} of ${this.items.length}`;
        let description = document.createElement("p");
        description.innerHTML = item.text;                
        let previous = document.createElement("button");
        previous.value = "Previous";
        previous.innerHTML = "Previous";
        previous.innerText = "Previous";
        previous.addEventListener('click', this.previousItem);
        let next = document.createElement("button");
        next.value = "Next";
        if(this.items.length >= 2){
            next.innerHTML = "Next";
            next.innerText = "Next";
        } else {
            next.innerHTML = "Done";
            next.innerText = "Done";
        }
        next.addEventListener('click', this.nextItem);
        popup.appendChild(title);
        popup.appendChild(description);
        if(item.image_url){
            if(item.tutorial_type === 1 || item.tutorial_type === "1"){
                let img = document.createElement("img");
                img.src = item.image_url;
                popup.appendChild(img);
            } else if(item.tutorial_type === 2 || item.tutorial_type === "2"){
                let vid = document.createElement("video");
                vid.controls = true;
                vid.autoplay = true;
                vid.playsInline = true;
                vid.id = item.id + "-video";
                vid.src = item.image_url;
                vid.style.width = "auto";
                vid.style.maxWidth = "620px";
                vid.style.height = "auto";
                vid.style.maxHeight = "620px";
                popup.appendChild(vid);
            }
        }
        popup.appendChild(document.createElement("br"));
        if(this.itemIndex >= 1){
            popup.appendChild(previous);
        }
        if(this.itemIndex <= this.items.length){
            popup.appendChild(next);
        }
        // append to body
        document.body.appendChild(popup);

        // scroll to popup
        document.querySelector('#tut_popup').scrollIntoView({
            behavior: 'smooth'
        });

    }

    nextItem(){        
        // check if items list is completed
        let item = this.items[this.itemIndex];
        if(item.tutorial_type === 2 || item.tutorial_type === "2"){
            let vid = document.getElementById( item.id + "-video");
            vid.pause();
        }
        if(this.itemIndex + 1 >= this.items.length){
            // cant go forward
            this.hideAllItems();
            return;
        }
        // go forward
        this.itemIndex += 1;
        this.loadItem();
    }
    
    previousItem(){        
        // check if items list is completed
        if(this.itemIndex <= 0){
            // cant go back
            return;
        }
        // go back
        this.itemIndex -= 1;
        this.loadItem();
    }

    render() {
        // HTML
        return ( 
            <>
            <div className="tutorialContainer">
                <button onClick={()=>{                
                    this.startTutorailBtnRef.current.style.display = "none";    
                    this.showTutorial();        
                    this.tutorialTypeRef.current.style.display = "none";
                    this.tutorialItemsListRef.current.style.display = "block";
                }} ref={this.startTutorailBtnRef}>Start Tutorial</button>
                <div ref={this.tutorialTypeRef} className="fixButtons">
                    <button onClick={this.loadTextTutorials}>Text Tutorial</button>
                    <button onClick={this.loadVideoTutorials}>Video Tutorial</button>
                    <button onClick={this.closeTutorialTypeSelection} className="blue">Close</button>
                </div>
                <div ref={this.tutorialItemsListRef} className="fixButtons" style={{width: '450px',maxWidth: '450px'}}>
                    {
                        this.state.tutorials.length ? this.state.tutorials.map((tut, index)=>{
                            return <button key={index} onClick={this.openTutorial} tutorial-index={index} tutorial-id={tut['info']['id']}>{tut['info']['title']}</button>
                        }) : <span style={{padding:'16px 0px', display: 'block'}}>There are no Tutorials for this page</span>
                    }
                    <button onClick={()=>{
                        this.startTutorailBtnRef.current.style.display = "block";    
                        this.tutorialTypeRef.current.style.display = "none";
                        this.tutorialItemsListRef.current.style.display = "none";
                    }} className="blue">Close</button>
                </div>
            </div>
            </>
        );
      }
}