import React, { createRef } from "react";
import { toast } from "react-toastify";
import API from "../services/api";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import './payPalReturnPage.css';

export default class PayPalReturnPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount(){
        setTimeout(()=>{
            window.location.href = "/dashboard";
        }, 5000);
    }

    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>PayPal Payment Returned</span>
                    </div>               
                    <div className="blockedTop">
                        You have Successfully completed payment via PayPal. This page will now redirect you to the dashbaord.                    
                    </div>           
                </div>
            </div>
        );
      }
}