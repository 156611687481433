import React, { useRef, useEffect, useState } from "react";
import Interview from "../../classes/InterviewClass";
import API from "../../services/api";
export default function useInterviewHook(api_key:string, interview_id:number) {
    const [ apiKey, setAPIKey] = useState<string>(api_key);
    const [ interviewID, setInterviewID] = useState<number>(interview_id);
    const [ interview, setInterview] = useState<any | Interview>(null);
    const reloadInterview = async () => {
        let result = await API.interview.get(apiKey, interviewID);
        setInterview(result["interview"]);
    }
    const updateInterviewTitle = async (title:string) => {
        let result = await API.interview.update(apiKey, interviewID, title)
    }
    const updateInterview = async (interview_data:any) => {
        // TODO: WRITE CUSTOM INTERVIEW UPDATE API
    }
    useEffect(()=>{
        reloadInterview();
    }, [interviewID]);
    return { interview, interviewID, reloadInterview, updateInterviewTitle, updateInterview }
}