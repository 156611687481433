import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import './candidatesFolderComponent.css';
import InterviewFolder from "./candidate-folders/interviewFolder";
import BespokeFolder from "./candidate-folders/bespokeFolder";
import OutReachFolder from "./candidate-folders/outReachFolder";
import SocialFolder from "./candidate-folders/socialFolder";

interface CandidatesFolderComponentProps{
  apiKey:string;
  interview:any;
  questions:any[];
  loggedInUser:any;
  interviewId:any;
  reloadInterview: () => void;
  defaultFolder: string;
  showAssessmentPopUp: (survey_data:any) => void;
  addNewCandidateCallback: (callback: (data:any) => Promise<null | {
    created_at: null | string | Date
    email: null | string
    id: number
    interview_id:  null | number
    linkedin_url: null | string
    marketing_type: null | number
    name: null | string
    phone: null | string
    processing_gif: null | number
    processing_video: null | number 
    updated_at: null | string | Date 
}>) => void;
  executeShowAddNewCandidateCallback: () => void;
  addEditCandidateCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeShowEditCandidateCallback: (data:any) => void;
  handleCandidateReviewCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleCandidateReviewCallback: (data:any) => void;
  candidateInvitePopupCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleCandidateInvitePopupCallback: (data:any) => void;  
  handleNameEmailCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleNameEmailCallback: (data:any) => void;
  executeHandleCandidateComments: (data:any) => void;
  handleFlowBespokeCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleFlowBespokeCallback: (data:any) => void;
  handleCustomVideoCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleCustomVideoCallback: (data:any) => void;
  executeShowVideoPreview: (data:any) => void;
  handleFlowOutReachCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleFlowOutReachCallback: (data:any) => void;
  handleOutReachVideoCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleOutReachVideoCallback: (data:any) => void;
  handleFlowSocialCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleFlowSocialCallback: (data:any) => void;
  handleSocialVideoCallback: (callback: (data:any) => Promise<boolean>) => void;
  executeHandleSocialVideoCallback: (data:any) => void;
  executeShowLink: (data:any) => void;
  handleFacebookPageSubmission: (callback: (pages_data:any[]) => Promise<boolean>) => void;
  executeHandleFacebookPageSubmission: (pages_data:any[]) => void;
  executeLoading: (state:boolean) => void;
  handleCustomVideoSubtitleCallback: (callback: (video_url:string) => Promise<boolean>) => void;
  AddNewCandidateAddGifCallback: (callback: (candidate_id:number, video_url: string) => Promise<boolean>) => void;
  executeShowLinkedInShare: (video_url:string, access_token:string, user_id:string) => void;
  handleLinkedInShareCallback: (callback: () => Promise<void>) => void;
}

export default function CandidatesFolderComponent({apiKey, executeLoading, loggedInUser, interview, interviewId, questions, executeShowLink, reloadInterview, defaultFolder, showAssessmentPopUp, addNewCandidateCallback, executeShowAddNewCandidateCallback, addEditCandidateCallback, executeShowEditCandidateCallback, handleCandidateReviewCallback, executeHandleCandidateReviewCallback, candidateInvitePopupCallback, executeHandleCandidateInvitePopupCallback, handleNameEmailCallback, executeHandleNameEmailCallback, executeHandleCandidateComments, handleFlowBespokeCallback, executeHandleFlowBespokeCallback, handleCustomVideoCallback, executeHandleCustomVideoCallback, executeShowVideoPreview, handleFlowOutReachCallback, executeHandleFlowOutReachCallback, handleOutReachVideoCallback, executeHandleOutReachVideoCallback, handleFlowSocialCallback, executeHandleFlowSocialCallback, handleSocialVideoCallback, executeHandleSocialVideoCallback, handleFacebookPageSubmission, executeHandleFacebookPageSubmission, handleCustomVideoSubtitleCallback, AddNewCandidateAddGifCallback, executeShowLinkedInShare, handleLinkedInShareCallback}:CandidatesFolderComponentProps) {
    // Hooks
    const history = useHistory();
    // States
    const [ currentFolder, setCurrentFolder ] = useState<string>("");
    // Functions
    // Effects
    useEffect(()=>{
      if(!defaultFolder) return;
      setCurrentFolder(defaultFolder);
    }, [defaultFolder]);
    useEffect(()=>{
        if(!currentFolder) return;
        history.replace(`#${currentFolder}`);
    }, [currentFolder]);
    // Renders
    return <div className="folder-root-container">
        <div className="folder-tabs">
            <button className={currentFolder === "interview" ? "active" : ""} onClick={ e => setCurrentFolder("interview")}>
              <svg width="200" height="32">
                <polygon
                  points="32,0 5,32 200,32, 168, 0"
                />
                <text x="72" y="20" fill="#FFF">
                  Interview
                </text>
              </svg>
            </button>
            <button className={currentFolder === "marketing" ? "active" : ""} onClick={ e => setCurrentFolder("marketing")}>
                <svg width="200" height="32">
                <polygon
                  points="32,0 5,32 200,32, 168, 0"
                />
                <text x="36" y="20" fill="#FFF">
                  Bespoke Outreach
                </text>
              </svg>
            </button>
            <button className={currentFolder === "bulk" ? "active" : ""} onClick={ e => setCurrentFolder("bulk")}>
                <svg width="225" height="32">
                <polygon
                  points="32,0 5,32 225,32, 192, 0"
                />
                <text x="40" y="20" fill="#FFF">
                  Outreach Campaign
                </text>
              </svg>
            </button>
            <button className={currentFolder === "social" ? "active" : ""} onClick={ e => setCurrentFolder("social")}>
                <svg width="200" height="32">
                    <polygon
                    points="32,0 5,32 200,32, 168, 0"
                    />
                    <text x="64" y="20" fill="#FFF">
                    Social Post
                    </text>
                </svg>
            </button>
        </div>
        <div className="folder-container">
            {
                currentFolder === "interview" && <InterviewFolder key="interview" loggedInUser={loggedInUser} apiKey={apiKey} interview={interview} interviewId={interview?.id ?? 0} reloadInterview={reloadInterview} showAssessmentPopUp={showAssessmentPopUp} addNewCandidateCallback={addNewCandidateCallback} executeShowAddNewCandidateCallback={executeShowAddNewCandidateCallback} addEditCandidateCallback={addEditCandidateCallback} executeShowEditCandidateCallback={executeShowEditCandidateCallback} handleCandidateReviewCallback={handleCandidateReviewCallback} executeHandleCandidateReviewCallback={executeHandleCandidateReviewCallback} candidateInvitePopupCallback={candidateInvitePopupCallback} executeHandleCandidateInvitePopupCallback={executeHandleCandidateInvitePopupCallback} handleNameEmailCallback={handleNameEmailCallback} executeHandleNameEmailCallback={executeHandleNameEmailCallback} executeHandleCandidateComments={executeHandleCandidateComments}/>
            }
            {
                currentFolder === "marketing" && <BespokeFolder key="marketing" apiKey={apiKey} interview={interview} interviewId={interview?.id ?? 0} reloadInterview={reloadInterview} showAssessmentPopUp={showAssessmentPopUp} addNewCandidateCallback={addNewCandidateCallback} executeShowAddNewCandidateCallback={executeShowAddNewCandidateCallback} addEditCandidateCallback={addEditCandidateCallback} executeShowEditCandidateCallback={executeShowEditCandidateCallback} handleCandidateReviewCallback={handleCandidateReviewCallback} executeHandleCandidateReviewCallback={executeHandleCandidateReviewCallback} candidateInvitePopupCallback={candidateInvitePopupCallback} executeHandleCandidateInvitePopupCallback={executeHandleCandidateInvitePopupCallback} handleNameEmailCallback={handleNameEmailCallback} executeHandleNameEmailCallback={executeHandleNameEmailCallback} executeHandleCandidateComments={executeHandleCandidateComments} handleFlowCallback={handleFlowBespokeCallback} executeHandleFlowCallback={executeHandleFlowBespokeCallback} handleCustomVideoCallback={handleCustomVideoCallback} executeHandleCustomVideoCallback={executeHandleCustomVideoCallback} executeShowVideoPreview={executeShowVideoPreview} handleCustomVideoSubtitleCallback={handleCustomVideoSubtitleCallback}/>
            }
            {
                currentFolder === "bulk" && <OutReachFolder key="bulk" apiKey={apiKey} AddNewCandidateAddGifCallback={AddNewCandidateAddGifCallback} interview={interview} interviewId={interview?.id ?? 0} reloadInterview={reloadInterview} addNewCandidateCallback={addNewCandidateCallback} executeShowAddNewCandidateCallback={executeShowAddNewCandidateCallback} addEditCandidateCallback={addEditCandidateCallback} executeShowEditCandidateCallback={executeShowEditCandidateCallback} candidateInvitePopupCallback={candidateInvitePopupCallback} executeHandleCandidateInvitePopupCallback={executeHandleCandidateInvitePopupCallback} handleNameEmailCallback={handleNameEmailCallback} executeHandleNameEmailCallback={executeHandleNameEmailCallback} handleFlowCallback={handleFlowOutReachCallback} executeHandleFlowCallback={executeHandleFlowOutReachCallback} handleOutReachVideoCallback={handleOutReachVideoCallback} executeHandleOutReachVideoCallback={executeHandleOutReachVideoCallback}/>
            }
            {
                currentFolder === "social" && <SocialFolder key="social" executeLoading={executeLoading} userInfo={loggedInUser} apiKey={apiKey} interview={interview} interviewId={interview?.id ?? 0} reloadInterview={reloadInterview} questions={questions} executeShowLink={executeShowLink} handleFlowCallback={handleFlowSocialCallback} executeHandleFlowCallback={executeHandleFlowSocialCallback} handleSocialVideoCallback={handleSocialVideoCallback} executeHandleSocialVideoCallback={executeHandleSocialVideoCallback} handleFacebookPageSubmission={handleFacebookPageSubmission} executeHandleFacebookPageSubmission={executeHandleFacebookPageSubmission} executeShowLinkedInShare={executeShowLinkedInShare} handleLinkedInShareCallback={handleLinkedInShareCallback}/>
            }
        </div>
    </div>
}