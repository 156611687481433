import React, { useEffect, useState } from 'react';
import ITJComponent from '../vacancy-components/itjComponent';
import useInterviewHook from '../vacancy-components/useInterviewHook';
import { useParams } from 'react-router-dom';
import API from '../../services/api';

export function IntegrationInterviewITJs(props:any){
    let { id:interview_id, api:api_key } = useParams();
    const { interview, interviewID, reloadInterview, updateInterviewTitle, updateInterview } = useInterviewHook(api_key, interview_id);
    const [ showLoading, setShowLoading ] = useState<boolean>(false);
    const [ user, setUser ] = useState<any>(null);
    const loadUser = async (api_key) => {
        let result = await API.user.getUser(api_key);
        setUser(result['user']);
        setShowLoading(false);
    }
    useEffect(()=>{
        loadUser(api_key); 
    },[]);
    if(showLoading){
        return <div>Loading, Please Wait</div>
    }
    return <ITJComponent apiKey={api_key} user={user} interview={interview} reloadInterview={reloadInterview}/>
}