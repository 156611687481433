import React, { createRef } from "react";

import './notification.css';

export default class NotificationComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        total: 0,
        notifications: []
      };

      this.openNotifications = this.openNotifications.bind(this);
      this.close = this.close.bind(this);

      this.notificationContainerRef = createRef();
    }

    componentDidMount(){
      this.notificationContainerRef.current.style.display = "none";
    }

    openNotifications(){
      this.notificationContainerRef.current.style.display = "block";
    }

    close(){
      this.notificationContainerRef.current.style.display = "none";
    }

    getIconColor(notification){
      let ret = "notificationContainerItemsItemIcon";
      if(notification.ntype == 0){
        ret += " redBG";
      } else if(notification.ntype == 1){
        ret += " orangeBG";
      } else if(notification.ntype == 2){
        ret += " greenBG";
      }
      return ret;
    }

    render() {
        // HTML
        return ( 
            <div>
              <div className="noitificationCounter">
                <div onClick={this.openNotifications}>{ this.state.total }</div>
              </div>
              <div ref={this.notificationContainerRef} className="notificationContainer">
                  <h4>Notifications</h4><button className="notificationClose" onClick={this.close}>X</button>
                  <div className="notificationContainerItems">
                    {
                      this.state.notifications.map(
                        (notification, index)=>{
                          <div className="notificationContainerItemsItem">
                            <span className={this.getIconColor(notification)}></span>
                            <span className="notificationContainerItemsItemTitle">{notification.title}</span>
                            <div className="notificationContainerItemsItemButtons">
                              <button data-notification-index={index} onClick={this.openNotification}>Open</button>
                              <button data-notification-index={index} onClick={this.closeNotification}>Close</button>                            </div>
                          </div>
                        }
                      )
                    }
                    {
                      this.state.notifications.length == 0 ? (
                         <div style={{padding: "32px"}}>
                           No Notificatinos
                         </div>
                      ) : ""
                    }
                  </div>
              </div>
            </div>
        );
      }
}