import React, { createRef } from "react";
import { ToastContainer, toast } from 'react-toastify';

import API from './../services/api';

import "./org.css";

import SideBarComponent from "./../components/sidebar";
import UpperBarComponent from "./../components/upperbar";

import { AiOutlineLoading } from 'react-icons/ai';

export default class OrgPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        totalusers: "Loading",
        org: null
      };
      document.querySelector('body').style.backgroundColor = "#EEF0F8"; // quick hack 

      
      this.loadingRef = createRef();

      this.search = this.search.bind(this);
      this.newOrg = this.newOrg.bind(this);
      this.getUsers = this.getUsers.bind(this);
      this.generateOrgStructure = this.generateOrgStructure.bind(this);
      this.openOrg = this.openOrg.bind(this);
      this.openUser = this.openUser.bind(this);
      this.displayLoading = this.displayLoading.bind(this);

      this.displayLoading = this.displayLoading.bind(this);

      this.createOrg = this.createOrg.bind(this);
      this.createNewAddOrg = this.createNewAddOrg.bind(this);
      this.reloadOrg = this.reloadOrg.bind(this);

      
      this.saveUserChanges = this.saveUserChanges.bind(this);
      this.impersonateUser = this.impersonateUser.bind(this);
      this.sendPasswordReminder = this.sendPasswordReminder.bind(this);
      this.deleteUser = this.deleteUser.bind(this);
      
      this.openAddUser = this.openAddUser.bind(this);
      this.openAddOrg = this.openAddOrg.bind(this);
      this.openAddSubOrg = this.openAddSubOrg.bind(this);
      this.openUpdateSubOrg = this.openUpdateSubOrg.bind(this);


      this.createUser = this.createUser.bind(this);
      this.hideAllDialogs = this.hideAllDialogs.bind(this);

      this.deleteThisOrg = this.deleteThisOrg.bind(this);
      
      this.deleteThisOrgConfirmed = this.deleteThisOrgConfirmed.bind(this);

      this.searchRef = createRef();
      this.orgDialogRef = createRef();
      this.addOrgNameRef = createRef();

      this.orgViewDialogRef = createRef();
      this.orgViewDialogInputNameRef = createRef();
      this.orgViewDialogMustPayNameRef = createRef();
      this.orgViewDialogInputIDRef = createRef();
      this.orgViewDialogInputParentIDRef = createRef();
      this.userViewDialogRef = createRef();
      this.userCreateDialogRef = createRef();
      
      this.orgAddDialogRef = createRef();
      this.orgAddDialogNameRef = createRef();
      this.orgAddDialogMustPayRef = createRef();
      this.orgAddDialogParentIDRef = createRef();

      this.orgViewUserInputIDRef = createRef();
      this.orgViewUserInputFullnameRef = createRef();
      this.orgViewUserInputEmailRef = createRef();
      this.orgViewUserInputPromoCodeRef = createRef();
      this.orgViewUserInputAccountTypeRef = createRef();
      this.orgViewUserInputAdministratorAccountRef = createRef();
      this.orgViewUserInputTestAccountRef = createRef();
      this.orgViewUserInputTrialAccountRef = createRef();
      this.orgViewUserInputTrialAccountDateRef = createRef();
      this.orgViewUserInputSellerTypeRef = createRef();
      this.orgViewUserInputDisabledAccountRef = createRef();
      this.orgViewUserInputHasMarketingAccountRef = createRef();
      this.orgViewUserInputHasMarketingNoPayAccountRef = createRef();
      this.orgViewUserInputCanExtractRef = createRef();
      this.orgViewUserInputFacebookAccountRef = createRef();
      this.orgViewUserInputOrgIDRef = createRef();
      this.orgViewUserInputOrgParentIDRef = createRef();

      
      this.orgCreateUserInputFullnameRef = createRef();
      this.orgCreateUserInputEmailRef = createRef();
      this.orgCreateUserInputPasswordRef = createRef();
      this.orgCreateUserInputPromoCodeRef = createRef();
      this.orgCreateUserInputAccountTypeRef = createRef();
      this.orgCreateUserInputAdministratorAccountRef = createRef();
      this.orgCreateUserInputTestAccountRef = createRef();
      this.orgCreateUserInputTrialAccountRef = createRef();
      this.orgCreateUserInputTrialAccountDateRef = createRef();
      this.orgCreateUserInputSellerTypeRef = createRef();
      this.orgCreateUserInputDisabledAccountRef = createRef();
      this.orgCreateUserInputHasMarketingAccountRef = createRef();
      this.orgCreateUserInputHasMarketingNoPayAccountRef = createRef();
      this.orgCreateUserInputCanExtractRef = createRef();
      this.orgCreateUserInputFacebookAccountRef = createRef();
      this.orgCreateUserInputOrgIDRef = createRef();
      this.orgCreateUserInputOrgParentIDRef = createRef();

      this.deleteOrgPopupDialogRef = createRef();

      this.totalusers = 0;
    }

    async componentDidMount(){
        this.displayLoading();
        this.hideAllDialogs();       
        this.reloadOrg();
        this.hideLoading();
    }

    displayLoading(){
        this.setState({...this.state, loading: true});
    }

    hideLoading(){
        this.setState({...this.state, loading: false});
    }

    async search(e){     
        let q = e.target.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.org.getAllOrgDataByQuery(api_key, q);
        let org_data = result['data'];
        this.setState({...this.state, org: org_data}); 
    }

    async reloadOrg(){
        this.hideAllDialogs();
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.getUser(api_key);
        if(!result['user'].isAdmin){
            window.location.href = '/dashboard';
            return;
        }
        let org_data = await API.org.getAllOrgData(api_key);
        result = await API.user.getTotalUsers(api_key);
        let totalUsers = result['total'];
        this.setState({...this.state, org: org_data['data'], totalusers: totalUsers});
    }

    newOrg(){
        this.hideAllDialogs();
        this.orgDialogRef.current.style.display = "inline-block";
        this.addOrgNameRef.current.value = "";        
        this.orgDialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    async createOrg(){
        let name = this.addOrgNameRef.current.value;
        if(!name){
            toast("Error enter a valid name");
            return;
        }
        let parent_id = this.orgAddDialogParentIDRef.current.value;
        let api_key = localStorage.getItem('api_key');
        await API.org.createOrg(api_key, name, parseInt(parent_id));
        this.reloadOrg();
    }

    async createNewAddOrg(){
        let name = this.orgAddDialogNameRef.current.value;
        if(!name){
            toast("Error enter a valid name");
            return;
        }
        let parent_id = this.orgAddDialogParentIDRef.current.value;
        let must_pay = this.orgAddDialogMustPayRef.current.checked ? 1 : 0;
        let api_key = localStorage.getItem('api_key');
        await API.org.createOrg(api_key, name, parseInt(parent_id), must_pay);
        this.reloadOrg();
    }

    hideAllDialogs(){        
        this.deleteOrgPopupDialogRef.current.style.display = "none";   
        this.orgDialogRef.current.style.display = "none";
        this.orgViewDialogRef.current.style.display = "none";
        this.userViewDialogRef.current.style.display = "none";
        this.orgAddDialogRef.current.style.display = "none";
        this.userCreateDialogRef.current.style.display = "none";
        this.orgAddDialogRef.current.style.display = "none";
    }

    async openOrg(e){        
        this.orgViewDialogRef.current.style.display = "inline-block";
        this.orgViewDialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.displayLoading();
        this.hideAllDialogs();
        let org_id = e.target.getAttribute('data-org-id');        
        if(org_id === 0 || org_id === "0" || !org_id){
            this.newOrg();
            this.hideLoading();
            return;
        }
        
        let api_key = localStorage.getItem('api_key');
        let result = await API.org.getOrg(api_key, org_id); 
        let org = result['org']; 
        if(org){      
            this.orgViewDialogRef.current.style.display = "inline-block";
            this.orgViewDialogInputNameRef.current.value = org['title'];
            this.orgViewDialogInputIDRef.current.value = org['id'];
            this.orgViewDialogInputParentIDRef.current.value = org['parent_id'];
            this.orgViewDialogMustPayNameRef.current.checked = org['must_pay'];
            this.hideLoading();
        } else {
            this.hideLoading();
            this.hideAllDialogs();
            toast("Error opening Organisation");
        }
    }

    deleteThisOrg(){
        this.org_id = this.orgViewDialogInputIDRef.current.value;  
        this.deleteOrgPopupDialogRef.current.style.display = "flex";     
    }

    async deleteThisOrgConfirmed(){
        this.displayLoading();
        let org_id = this.org_id;
        let api_key = localStorage.getItem('api_key');
        let x = await API.org.deleteOrg(api_key, parseInt(org_id));
        this.hideAllDialogs();
        this.hideLoading();
        this.reloadOrg();
    }

    async openUser(e){
        this.hideAllDialogs();
        this.displayLoading();
        let api_key = localStorage.getItem('api_key');
        let user_id = e.target.getAttribute('data-user-id');
        let result = await API.user.getUserByID(api_key, parseInt(user_id));
        let user = result['user'];
        if(user){
            this.userViewDialogRef.current.style.display = "inline-block";
            this.orgViewUserInputIDRef.current.value = user['id'];
            this.orgViewUserInputFullnameRef.current.value = user['name'];
            this.orgViewUserInputEmailRef.current.value = user['email'].toLowerCase();
            this.orgViewUserInputPromoCodeRef.current.value = user['promocode'];
            this.orgViewUserInputAccountTypeRef.current.value = (user['package_number'] ?? 0).toString();
            this.orgViewUserInputAdministratorAccountRef.current.checked = user['isAdmin'];
            this.orgViewUserInputTestAccountRef.current.checked = user['isTester'];
            this.orgViewUserInputTrialAccountRef.current.checked = user['trial_expiry_flag'];
            if(user['trial_expiry_flag']){
                let xxx = new Date(user['trial_expiry']);
                this.orgViewUserInputTrialAccountDateRef.current.valueAsDate = xxx;
            } else {
                this.orgViewUserInputTrialAccountDateRef.current.valueAsDate = new Date(new Date().toISOString().split('T')[0]);
            }
            this.orgViewUserInputSellerTypeRef.current.value = (user['seller_type'] ?? 0).toString();
            this.orgViewUserInputDisabledAccountRef.current.checked = user['isDisabled'];
            this.orgViewUserInputHasMarketingAccountRef.current.checked = user['hasMarketing'];
            this.orgViewUserInputHasMarketingNoPayAccountRef.current.checked = user['hasMarketingNoPay'];
            this.orgViewUserInputCanExtractRef.current.checked = user['canExtract'];
            this.orgViewUserInputFacebookAccountRef.current.checked = user['isFacebook'];
            this.orgViewUserInputOrgIDRef.current.value = user['org_id'];
            this.orgViewUserInputOrgParentIDRef.current.value = user['parent_id'] ?? 0;
            this.userViewDialogRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            this.hideAllDialogs();
            toast("Error opening User");
        }
        
        this.hideLoading();
    }

    // return users
    getUsers(users){
        return users.length >= 1 ? users.map(
            (user)=>{
                return (
                    <div key={"user" + user.id} data-user-id={user.id} className="orgUser" onClick={this.openUser}><span className="orangeText">♟</span> { user.name }</div>
                )
            }
        ) : "";
    }

    hideViewOrg(){
        this.orgViewDialogRef.current.style.display = "none";
    }

    generateOrgStructure(root_org){
        // gen root details (when click display org controls for this org)
        // get users for said org
        let org = root_org ? (
            <div key={"org" + root_org.id} data-org-id={ root_org.id } className="orgEntity" onClick={this.openOrg}><span className="blueText">♜</span> { root_org.title }</div>
        ) : "";
        let users = root_org ? ( root_org.users ? this.getUsers(root_org.users) : "") : "";
        let orgs = root_org ? (root_org.orgs ? root_org.orgs.map((x)=>{
            return this.generateOrgStructure(x)
        }) : "" ) : "";    
        return (
            <div className="orgContainerItem">
                {[org, users, orgs]}
            </div>
        );
     }

     async saveUserChanges(){
         let sasdad = this.orgViewUserInputTrialAccountDateRef.current.valueAsDate.toISOString();
         //let sasdad = new Date(new Date(new Date().setDate(xxx.getDate() - 1)).toISOString().split('T')[0]);
        let user_data = {
            'id': this.orgViewUserInputIDRef.current.value,
            'email': this.orgViewUserInputEmailRef.current.value.toLowerCase(),
            'name': this.orgViewUserInputFullnameRef.current.value,
            'promocode': this.orgViewUserInputPromoCodeRef.current.value,
            'package_number': this.orgViewUserInputAccountTypeRef.current.value,
            'isAdmin': this.orgViewUserInputAdministratorAccountRef.current.checked,
            'isTester': this.orgViewUserInputTestAccountRef.current.checked,
            'trial_expiry_flag': this.orgViewUserInputTrialAccountRef.current.checked,
            'trial_expiry': sasdad,
            'seller_type': this.orgViewUserInputSellerTypeRef.current.value,
            'isDisabled': this.orgViewUserInputDisabledAccountRef.current.checked,
            'hasMarketing': this.orgViewUserInputHasMarketingAccountRef.current.checked,
            'hasMarketingNoPay': this.orgViewUserInputHasMarketingNoPayAccountRef.current.checked,
            'canExtract': this.orgViewUserInputCanExtractRef.current.checked,
            'isFacebook': this.orgViewUserInputFacebookAccountRef.current.checked,
            'org_id': this.orgViewUserInputOrgIDRef.current.value,
            'parent_id': this.orgViewUserInputOrgParentIDRef.current.value,
        }
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.updateUserBasic(api_key, user_data);
        if(result){
            toast("User Updated");
        } else {
            toast("Error updating User");
        }
     }

     async impersonateUser(){
        let user_id = this.orgViewUserInputIDRef.current.value;
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.loginAsUser(api_key, user_id);
        console.log(result);
        let user_api_key = result['api_key'];
        if(user_api_key){
            localStorage.setItem('original_api_key', api_key);
            localStorage.setItem('api_key', user_api_key);
            window.location.href = "/dashboard";
        } else {
            toast("Error imperonating User");
        }
     }

     async sendPasswordReminder(){
        this.setState({
            ...this.state,
            loading: true
        });
        let user_id = this.orgViewUserInputIDRef.current.value;
        let api_key = localStorage.getItem('api_key');
        await API.user.sendUserPasswordReminder(api_key, user_id);
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Sent");
     }

     async deleteUser(){
        let user_id = this.orgViewUserInputIDRef.current.value;
        let api_key = localStorage.getItem('api_key');
        await API.user.deleteUser(api_key, user_id);
        toast("Deleted");
        this.reloadOrg();
     }

     async openAddOrg(){
         this.hideAllDialogs();
         this.orgAddDialogRef.current.style.display = "inline-block";
         this.orgAddDialogParentIDRef.current.value = this.orgViewDialogInputParentIDRef.current.value;         
     }

     async openAddSubOrg(){
        this.hideAllDialogs();
        this.orgAddDialogRef.current.style.display = "inline-block";
        this.orgAddDialogParentIDRef.current.value = this.orgViewDialogInputIDRef.current.value;         
     }

     async openUpdateSubOrg(){        
        this.setState({
            ...this.state,
            loading: true
        });
        let api_key = localStorage.getItem('api_key');
        let id = parseInt(this.orgViewDialogInputIDRef.current.value);
        let name = this.orgViewDialogInputNameRef.current.value;
        let must_pay = this.orgViewDialogMustPayNameRef.current.checked ? 1 : 0;
        let result = await API.org.updateOrg(api_key, id, name, must_pay);
        this.setState({
            ...this.state,
            loading: false
        });
        toast("Saved");
        this.reloadOrg();
     }

     async openAddUser(){
        this.hideAllDialogs();
         this.userCreateDialogRef.current.style.display = "inline-block";
         this.orgCreateUserInputPromoCodeRef.current.value = "a0" + btoa(Math.random()).substr(10, 5).toLowerCase();
         this.orgCreateUserInputPasswordRef.current.value = btoa(Math.random()).substr(10, 6).toLowerCase();
         this.orgCreateUserInputOrgIDRef.current.value = this.orgViewDialogInputIDRef.current.value;
         this.orgCreateUserInputOrgParentIDRef.current.value = 0;

         this.orgCreateUserInputFullnameRef.current.value = "";
         this.orgCreateUserInputEmailRef.current.value = "";
         this.orgCreateUserInputAccountTypeRef.current.value = "0";
         this.orgCreateUserInputAdministratorAccountRef.current.value = "off";
         this.orgCreateUserInputTestAccountRef.current.value = "off";
         this.orgCreateUserInputTrialAccountRef.current.value = "off";
         this.orgCreateUserInputTrialAccountDateRef.current.value = new Date().toISOString();
         this.orgCreateUserInputDisabledAccountRef.current.value = "off";
         this.orgCreateUserInputHasMarketingAccountRef.current.value = "off";
         this.orgCreateUserInputHasMarketingNoPayAccountRef.current.value = "off";
         this.orgCreateUserInputCanExtractRef.current.value = "off";
         this.orgCreateUserInputFacebookAccountRef.current.value = "off";
     }

     async createUser(){
         this.displayLoading();         
        let email = this.orgCreateUserInputEmailRef.current.value.toLowerCase();
        let api_key = localStorage.getItem('api_key');
        let result = await API.user.checkEmailExists(email);
        if(result['exists']){        
             this.hideLoading();
             toast("email already in use");
            this.orgCreateUserInputEmailRef.current.value = "";
            return;
        }
        let user_data = {
            'email': this.orgCreateUserInputEmailRef.current.value.toLowerCase(),
            'name': this.orgCreateUserInputFullnameRef.current.value,
            'password': this.orgCreateUserInputPasswordRef.current.value,
            'promocode': this.orgCreateUserInputPromoCodeRef.current.value,
            'package_number': parseInt(this.orgCreateUserInputAccountTypeRef.current.value),
            'isAdmin': this.orgCreateUserInputAdministratorAccountRef.current.checked,
            'isTester': this.orgCreateUserInputTestAccountRef.current.checked,
            'trial_expiry_flag': this.orgCreateUserInputTrialAccountRef.current.checked,
            'trial_expiry': this.orgCreateUserInputTrialAccountDateRef.current.value ? new Date(this.orgCreateUserInputTrialAccountDateRef.current.value).toISOString() : new Date().toISOString(),
            'seller_type': parseInt(this.orgCreateUserInputSellerTypeRef.current.value),
            'isDisabled': this.orgCreateUserInputDisabledAccountRef.current.checked,
            'hasMarketing': this.orgCreateUserInputHasMarketingAccountRef.current.checked,
            'hasMarketingNoPay': this.orgCreateUserInputHasMarketingNoPayAccountRef.current.checked,
            'canExtract': this.orgCreateUserInputCanExtractRef.current.checked,
            'isFacebook': this.orgCreateUserInputFacebookAccountRef.current.checked,
            'org_id': parseInt(this.orgCreateUserInputOrgIDRef.current.value),
            'parent_id': parseInt(this.orgCreateUserInputOrgParentIDRef.current.value),
        }
        await API.user.createUserFromOrg(api_key, user_data);
        this.hideLoading();
        this.hideAllDialogs();
        this.reloadOrg();
        toast("user created");
     }

    render() {
        // HTML
        return (
            <div className="dashboardContainer">
                <SideBarComponent></SideBarComponent>
                <div className="dashboarContent">
                    <UpperBarComponent></UpperBarComponent>
                    <div className="pageNameInfo">
                        <span>Organisation</span>
                    </div>
                    <div className="orgContainer">
                        <h4>Organisation Hierarchy</h4>
                        <div className="orgControls">
                            <span>Total User: { this.state.totalusers }</span>
                            <input ref={this.searchRef} type="text" onChange={this.search} placeholder="Search" />
                        </div>
                        <div className="orgContainerItems">
                            {
                                this.state.org ? this.generateOrgStructure(this.state.org) : <div style={{padding: '16px'}}>Loading</div>
                            }
                        </div>
                    </div>
                    <div ref={this.orgDialogRef} className="orgDialog">
                        <h4>New Organisation</h4>
                        <div>
                            <span>Organisation Name</span>
                            <input type="text" ref={this.addOrgNameRef}/>
                        </div>
                        <button id="addOrg" onClick={this.createOrg}>Add Organisation</button>
                        <button id="closeAddOrg" onClick={this.hideAddOrg}>Close</button>
                    </div>
                    
                    <div ref={this.orgViewDialogRef} className="orgViewDialog">
                        <h4>Organisation Data</h4>
                        <div>
                            <span>Name:</span><input ref={this.orgViewDialogInputNameRef} />
                        </div>
                        <div>
                            <span>Must Pay:</span><input ref={this.orgViewDialogMustPayNameRef} type="checkbox" style={{width:'fit-content'}}/>
                        </div>
                        <div>
                            <span>ID:</span><input ref={this.orgViewDialogInputIDRef} readOnly={true}/>
                            </div>
                        <div>
                            <span>Parent ID:</span><input ref={this.orgViewDialogInputParentIDRef}  readOnly={true}/>
                        </div>
                        <button id="viewOrgAddOrg" onClick={this.openAddSubOrg}>Add Organisation</button>
                        <button id="viewOrgAddOrg" onClick={this.openUpdateSubOrg}>Update Organisation</button>
                        <button id="viewOrgAddUser" onClick={this.openAddUser}>Add User</button>
                        <button id="viewOrgDeleteOrg" onClick={this.deleteThisOrg}>Delete Organisation</button>
                        <button id="closeAddOrg2" onClick={this.hideAllDialogs}>Close</button>
                    </div>     
                    <div ref={this.orgAddDialogRef} className="orgViewDialog">
                        <h4>New Organisation</h4>
                        <div>
                            <span>Name:</span><input ref={this.orgAddDialogNameRef} />
                        </div>                        
                        <div>
                            <span>Must Pay:</span><input style={{width:'fit-content'}} ref={this.orgAddDialogMustPayRef} type="checkbox" />
                        </div>
                        <div>
                            <span>Parent ID:</span><input ref={this.orgAddDialogParentIDRef}  readOnly={true}/>
                        </div>
                        <button id="viewOrgAddOrg" onClick={this.createNewAddOrg}>Add Organisation</button>
                        <button id="closeAddOrg2" onClick={this.hideAllDialogs}>Close</button>
                    </div>                 
                    <div ref={this.userViewDialogRef} className="userViewDialog">
                        <h4>User Data</h4>
                        <div>
                            <span>ID:</span><input ref={this.orgViewUserInputIDRef} disabled={true} readOnly={true}/>
                        </div>
                        <div>
                            <span>Fullname:</span><input ref={this.orgViewUserInputFullnameRef} />
                        </div>
                        <div>
                            <span>Email:</span><input ref={this.orgViewUserInputEmailRef}  disabled={true} readOnly={true}/>
                        </div>
                        <div>
                            <span>Promo Code:</span><input ref={this.orgViewUserInputPromoCodeRef} />
                        </div>
                        <div>
                            <span>Account Type:</span><select ref={this.orgViewUserInputAccountTypeRef}>
                            <option value="0">Free Subscription Package</option>
                            <option value="1">Solo Subscription Package</option>
                            <option value="2">Lite Subscription Package</option>
                            <option value="3">Lite Business Subscription Package</option>
                            <option value="4">Business Subscription Package</option>
                            <option value="5">Enterprise Subscription Package</option>
                            <option disabled="" value="6">Unknown Subscription</option>
                            </select>
                        </div>
                        <div>
                            <span>Administrator Account:</span><input type="checkbox" ref={this.orgViewUserInputAdministratorAccountRef} />
                        </div>
                        <div>
                            <span>Tester Account :</span><input type="checkbox" ref={this.orgViewUserInputTestAccountRef} />
                        </div>                        
                        <div>
                            <span>Trial Account :</span><input type="checkbox" ref={this.orgViewUserInputTrialAccountRef} />
                        </div>
                        <div>
                            <span>Trial Expiry Date:</span><input type="date" ref={this.orgViewUserInputTrialAccountDateRef}/>
                        </div>
                        <div>
                            <span>Seller Type :</span>
                            <select ref={this.orgViewUserInputSellerTypeRef} defaultValue={"DEFAULT"}>
                                <option value="0">Affiliate</option>
                                <option value="1">Influencer</option>
                                <option value="2">Corporate</option>
                                <option value="3">Reseller</option>
                            </select>
                        </div>
                        <div>
                            <span>Facebook Account:</span><input type="checkbox" ref={this.orgViewUserInputFacebookAccountRef} />
                        </div>
                        <div>
                            <span>Disabled Account:</span><input type="checkbox" ref={this.orgViewUserInputDisabledAccountRef} />
                        </div>
                        <div>
                            <span>Has Marketing:</span><input type="checkbox" ref={this.orgViewUserInputHasMarketingAccountRef} />
                        </div>
                        <div>
                            <span>Has Marketing No Pay:</span><input type="checkbox" ref={this.orgViewUserInputHasMarketingNoPayAccountRef} />
                        </div>
                        <div>
                            <span>Can Extract:</span><input type="checkbox" ref={this.orgViewUserInputCanExtractRef} />
                        </div>
                        <div>
                            <span>Organisation ID:</span><input ref={this.orgViewUserInputOrgIDRef}  readOnly={true}/>
                        </div>
                        <div>
                            <span>Parent ID:</span><input ref={this.orgViewUserInputOrgParentIDRef}  readOnly={true}/>
                        </div><br /><br />
                        <button id="userViewDialogDelete" onClick={this.deleteUser}>Delete User</button>
                        <button id="userViewDialogPassword" onClick={this.sendPasswordReminder}>Send Password Reminder</button>
                        <button id="userViewDialogImpersonate" onClick={this.impersonateUser}>Login As</button>
                        <button id="userViewDialogSave" onClick={this.saveUserChanges}>Save Changes</button>
                    </div>
                    <div ref={this.userCreateDialogRef} className="userViewDialog">
                        <h4>New User</h4>
                        <div>
                            <span>Fullname:</span><input ref={this.orgCreateUserInputFullnameRef} />
                        </div>
                        <div>
                            <span>Email:</span><input ref={this.orgCreateUserInputEmailRef} />
                        </div>
                        <div>
                            <span>Password:</span><input ref={this.orgCreateUserInputPasswordRef}  readOnly={true}/>
                        </div>
                        <div>
                            <span>Promo Code:</span><input ref={this.orgCreateUserInputPromoCodeRef}  readOnly={true}/>
                        </div>
                        <div>
                            <span>Account Type:</span><select ref={this.orgCreateUserInputAccountTypeRef}>
                            <option value="0">Free Subscription Package</option>
                            <option value="1">Solo Subscription Package</option>
                            <option value="2">Lite Subscription Package</option>
                            <option value="3">Lite Business Subscription Package</option>
                            <option value="4">Business Subscription Package</option>
                            <option value="5">Enterprise Subscription Package</option>
                            <option disabled="" value="6">Unknown Subscription</option>
                            </select>
                        </div>
                        <div>
                            <span>Administrator Account:</span><input type="checkbox" ref={this.orgCreateUserInputAdministratorAccountRef} />
                        </div>
                        <div>
                            <span>Tester Account :</span><input type="checkbox" ref={this.orgCreateUserInputTestAccountRef} />
                        </div>
                        <div>
                            <span>Trial Account :</span><input type="checkbox" ref={this.orgCreateUserInputTrialAccountRef} />
                        </div>
                        <div>
                            <span>Trial Expiry Date:</span><input type="date" ref={this.orgCreateUserInputTrialAccountDateRef}/>
                        </div>
                        <div>
                            <span>Seller Type :</span>
                            <select ref={this.orgCreateUserInputSellerTypeRef} defaultValue={"DEFAULT"} onChange={ (e)=>{
                                let x = parseInt(e.target.value);
                                let promocode = "";
                                let gen = btoa(Math.random()).substr(10, 5).toLowerCase();
                                switch(x){
                                    case 0:
                                        promocode = "a0" + gen;
                                        break;
                                    case 1:
                                        promocode = "i1" + gen;
                                        break;
                                    case 2:
                                        promocode = "c2" + gen;
                                        break;
                                    case 3:
                                        promocode = "r3" + gen;
                                        break;
                                }
                                this.orgCreateUserInputPromoCodeRef.current.value = promocode;
                            }}>
                                <option value="0">Affiliate</option>
                                <option value="1">Influencer</option>
                                <option value="2">Corporate</option>
                                <option value="3">Reseller</option>
                            </select>
                        </div>
                        <div>
                            <span>Facebook Account:</span><input type="checkbox" ref={this.orgCreateUserInputFacebookAccountRef} />
                        </div>
                        <div>
                            <span>Disabled Account:</span><input type="checkbox" ref={this.orgCreateUserInputDisabledAccountRef} />
                        </div>
                        <div>
                            <span>Has Marketing:</span><input type="checkbox" ref={this.orgCreateUserInputHasMarketingAccountRef} />
                        </div>
                        <div>
                            <span>Has Marketing No Pay:</span><input type="checkbox" ref={this.orgCreateUserInputHasMarketingNoPayAccountRef} />
                        </div>
                        <div>
                            <span>Can Extract:</span><input type="checkbox" ref={this.orgCreateUserInputCanExtractRef} />
                        </div>
                        <div>
                            <span>Organisation ID:</span><input ref={this.orgCreateUserInputOrgIDRef}  readOnly={true}/>
                        </div>
                        <div>
                            <span>Parent ID:</span><input ref={this.orgCreateUserInputOrgParentIDRef}  readOnly={true}/>
                        </div><br /><br />
                        <button id="userViewDialogCreate" onClick={this.createUser}>Add User</button>
                        <button id="userViewDialogClose" onClick={this.hideAllDialogs}>Close</button>
                    </div>
                    <div ref={this.deleteOrgPopupDialogRef} className="dialogNew extended">
                        <div>
                            <h4>Are you sure you want to delete this orginization</h4>
                            <button id="userViewDialogCreate" className="blueBTN" style={{display:'block', width:'100%'}}  onClick={this.deleteThisOrgConfirmed}>Yes</button><br/>
                            <button id="userViewDialogClose" className="blueBTN" style={{display:'block', width:'100%'}}  onClick={()=>{
                                this.deleteOrgPopupDialogRef.current.style.display = 'none';
                            }}>No</button>
                        </div>
                    </div>
                </div>
                { 
                    this.state.loading ? (<div className="loader">
                        <span>☯</span>
                        <div>Loading, Please Wait <AiOutlineLoading className="loading-spinner"/></div>
                    </div>) : ""
                }
            </div>
        );
      }
}

