import React from 'react';

import { AiFillDelete } from 'react-icons/ai';

import './MultiOptionItem.css';

export interface MultiOptionItemProps {
    onChange: (text: any) => void
    onDelete: () => void
    defaultText:string
}

export default function MultiOptionItem(props:MultiOptionItemProps){
    const { onChange, onDelete, defaultText } = props;
    return (
        <div className='MultiOptionContainerItem'>
            <input type="text" onChange={(event)=>{
                onChange(event.target.value);
            }} value={defaultText}/>
            <button className="delete-multi-choice-button" onClick={()=>{
                onDelete();
            }}>
                <AiFillDelete/>
            </button>
        </div>
    )       
}