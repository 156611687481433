import React, { useRef, useEffect, useState } from "react";
import UserAllocationComponent from "./userAllocationComponent";
import './headerComponent.css';
export default function HeaderComponent({apiKey, interview, noWidth}:{apiKey:string, interview:any, noWidth:boolean}) {
    if(noWidth){
        if(!interview){
            return <div className="pageNameInfo page-header-component">
                <span>Vacancy &gt; <span style={{color: "#1976D1"}}>Loading</span></span>
            </div>
        }
        return <div className="pageNameInfo page-header-component">
            <span>Vacancy &gt; <span style={{color: "#1976D1"}}>{ interview.title } <div style={{display: "inline-block", fontSize: "9pt", color:"rgb(255, 132, 0)"}}>({ interview.id })</div></span></span><UserAllocationComponent apiKey={apiKey} interview={interview}/>
        </div>
    } else {
        if(!interview){
            return <div className="pageNameInfo page-header-component" style={{width:'calc(100vw - 280px)'}}>
                <span>Vacancy &gt; <span style={{color: "#1976D1"}}>Loading</span></span>
            </div>
        }
        return <div className="pageNameInfo page-header-component" style={{width:'calc(100vw - 280px)'}}>
            <span>Vacancy &gt; <span style={{color: "#1976D1"}}>{ interview.title } <div style={{display: "inline-block", fontSize: "9pt", color:"rgb(255, 132, 0)"}}>({ interview.id })</div></span></span><UserAllocationComponent apiKey={apiKey} interview={interview}/>
        </div>
    }
}