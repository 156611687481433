import React, { createRef } from "react";

import { AiOutlineLoading } from 'react-icons/ai';

import "./extraSpaceContainer.css";

export default class ExtraSpaceComponent extends React.Component {
    constructor(props) {
        super(props);  
        this.state = {
            loading: false
        }
        this.buyMoreSpace = this.buyMoreSpace.bind(this);
    }
    
    buyMoreSpace(){
        alert("Purchasing of Extra Storage is not avaliable at this moment");
    }
 
    // 10GBs  = $  50.0
    // 25GBs  = $  112.50
    // 50GBs  = $  200.00
    // 100GBs = $  350.00
    // 250GBs = $  750.00
    // 1TBs   = $ 2560.00
    // 5TBs   = $10240.00

    render(){
        // HTML
        return ( 
            <div className="extraSpaceContainer">
                <h1>Need Extra Space?</h1>
                <h4>Are you running out of storage space?</h4>
                <button onClick={this.buyMoreSpace}>Buy More Space</button>
                { 
                    this.state.loading ? (<div className="loader">
                        <span>☯</span>
                        <div>Loading, Please Wait <AiOutlineLoading className="loading-spinner"/></div>
                    </div>) : ""
                }
            </div>
        );
      }
}